import React, { Fragment } from "react";
import {IOwner} from "../../../../../../store/owners/models/owner.interface";
import {Form, FormType, InputType} from "../../../../../ui/components/forms/formBuilder/form";
import { HomeIcon, InfoIcon } from "src/components/ui/icons";
import { FormHolder } from "src/components/ui/components/forms/form-holder";

interface IProps {
    owner: IOwner;
}

export function OwnerContactDetails(props: IProps) {
    const {owner} = props;

    return <Fragment>
        <FormHolder
            title="Address"
            withDivider
            icon={<HomeIcon />}>
            <Form<Partial<IOwner>>
                type={FormType.DEFAULT}
                data={owner}
                readOnly
                fields={{
                    address: {
                        index: 0,
                        label: 'Address',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    addressCont: {
                        index: 1,
                        label: 'Address Cont.',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    city: {
                        index: 2,
                        label: 'City',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    state: {
                        index: 3,
                        label: 'State',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    zip: {
                        index: 4,
                        label: 'Zip Code',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING
                    }
                }}
            />
        </FormHolder>
        <FormHolder
            title="Contact Details"
            icon={<InfoIcon />}>
            <Form<Partial<IOwner>>
                type={FormType.DEFAULT}
                data={owner}
                readOnly
                fields={{
                    email: {
                        index: 0,
                        label: 'Email',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    mobilePhone: {
                        index: 1,
                        label: 'Mobile Number',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                }}
            />
        </FormHolder>
    </Fragment>;
}
