import {
    IPropertiesSelectedCriteria, searchPropertiesInitialState,
    searchPropertiesStore,
} from "./search-properties.store";
import {INamedEntity} from "../models/named-entity.interface";
import {KeyedNamedEntity} from "./search-properties.query";

const readyToLeaseMap = new Map();
readyToLeaseMap.set('Only Actively Renting', 'YES');
readyToLeaseMap.set('Only Idle', 'NO');

export function removeAllSelection() {
    searchPropertiesStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...searchPropertiesInitialState.selectedCriteria
        }
    }))
}

export function updateSelectionCriteria(data: Partial<IPropertiesSelectedCriteria>) {
    searchPropertiesStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...state.selectedCriteria,
            ...data,
        }
    }))
}

export function getCriteria(): IPropertiesSelectedCriteria {
    return searchPropertiesStore.getValue().selectedCriteria;
}

export function deleteItem(item: KeyedNamedEntity<any>) {
    const predicate = (i: INamedEntity) => !(i.id === item.id && i.name === item.name);

    searchPropertiesStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...state.selectedCriteria,
            readyToLease: item.key === 'readyToLease' 
                ? state.selectedCriteria.readyToLease.filter(leaseItem => leaseItem !== readyToLeaseMap.get(item.name))
                : state.selectedCriteria.readyToLease,
            // amenities: state.selectedCriteria.amenities.filter(predicate),
            // neighborhood: state.selectedCriteria.neighborhood.filter(predicate),
            city: state.selectedCriteria.city.filter(predicate),
            numberOfBedrooms: state.selectedCriteria.numberOfBedrooms.id === item.id ?
                searchPropertiesInitialState.selectedCriteria.numberOfBedrooms :
                state.selectedCriteria.numberOfBedrooms,
            numberOfBathrooms: state.selectedCriteria.numberOfBathrooms.id === item.id ?
                searchPropertiesInitialState.selectedCriteria.numberOfBathrooms :
                state.selectedCriteria.numberOfBathrooms,
            targetRent: state.selectedCriteria.targetRent.id === item.id ?
                searchPropertiesInitialState.selectedCriteria.targetRent :
                state.selectedCriteria.targetRent,
        }
    }))
}

// public setSorting(sorting: ISort<keyof IProperty>) {
//     this.searchPropertiesStore.update(state => ({
//         ...state,
//         sort: sorting,
//     }));
// }

export function getSorting() {
    return searchPropertiesStore.getValue().sort;
}

export function applyFilters() {
    searchPropertiesStore.update(state => ({
        ...state,
        appliedFilters: state.selectedCriteria,
    }));
}

export const searchPropertiesService = {
    removeAllSelection,
    updateSelectionCriteria,
    getCriteria,
    deleteItem,
    getSorting,
    applyFilters,
};