import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import CssClasses from './input.module.scss';
import classnames from 'classnames';
import {EyeClose, EyeOpen} from "../../../icons";
import {IInputComponent} from "./input-component.interface";

export enum CurrencyType {
    USD = '$'
}
interface IInput extends IInputComponent {
    id?: string;
    name?: string;
    type?: 'password' | 'text' | 'number';
    valid?: boolean;
    rules?: any;
    disabled?: boolean;
    value?: any;
    wrapperStyle?: React.CSSProperties;
    onFocus?: (e: ChangeEvent<any>) => void;
    endAdornment?: React.ReactNode;
    styles?: React.CSSProperties;
    inputStyles?: React.CSSProperties;
}

enum InputState {
    PASSWORD_HIDDEN,
    PASSWORD_SHOWN,
    DEFAULT,
}

export const Input: React.FC<IInput> = ({ id, name, type, disabled, valid, rules, wrapperStyle, onFocus, endAdornment, styles, inputStyles, ...rest }) => {
    const [realType, setRealType] = useState('text');
    const [error, setError] = useState('');
    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT);

    const inputClasses = classnames({
        [CssClasses.input]: true,
        [CssClasses.input_password]: realType === 'password',
        // [CssClasses.input_invalid]: !valid,
    })

    useEffect(() => {
        if (!valid && rules) {
            setError(rules.message);
        } else {
            setError('');
        }
    }, [valid, rules]);

    useEffect(() => {
        setRealType(type || 'text');

        if(type === 'password') {
            setInputState(InputState.PASSWORD_HIDDEN);
        }
    }, [type]);

    const toggleState = useCallback(() => {
        if (inputState === InputState.PASSWORD_HIDDEN) {
            setInputState(InputState.PASSWORD_SHOWN);
        }
        
        if (inputState === InputState.PASSWORD_SHOWN) {
            setInputState(InputState.PASSWORD_HIDDEN);
        }
    }, [inputState, setInputState]);

    const handleFocus = (e: ChangeEvent<any>) => {
        if (typeof onFocus === 'function') {
            onFocus(e);
        }
    }

    return <div className={CssClasses.input_wrapper} style={wrapperStyle}>
        {
            endAdornment
                ? (
                    <div className={inputClasses} style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0',
                        ...styles,
                    }}>
                        <input
                            id={id}
                            name={name}
                            readOnly={disabled}
                            disabled={disabled}
                            type={realType === 'password' && inputState === InputState.PASSWORD_SHOWN ? 'text' : realType}
                            {...rest}
                            onFocus={handleFocus}
                            className={inputClasses}
                            style={inputStyles}
                        />
                        { endAdornment }
                    </div>
                ): (
                    <input
                        id={id}
                        name={name}
                        readOnly={disabled}
                        disabled={disabled}
                        type={realType === 'password' && inputState === InputState.PASSWORD_SHOWN ? 'text' : realType}
                        {...rest}
                        onFocus={handleFocus}
                        className={inputClasses}
                    />
                )
        }
        <span onClick={toggleState} className={CssClasses.password_icon}>
            {inputState === InputState.PASSWORD_HIDDEN && <EyeOpen />}
            {inputState === InputState.PASSWORD_SHOWN && <EyeClose />}
        </span>
        { error ? <div className={CssClasses.errorMsg}>{error}</div> : null }
    </div>
}
