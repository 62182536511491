import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { RoutesEnum } from "../../routing/routes";
import StaffInfo from './pages/staff-info/staff-info';
import { Staff } from './pages/staff-list/staff';
import { StaffEditor } from './pages/staff-editor/staff-editor';

export function StaffContainer () {
    return (
        <Switch>
            <Route exact path={RoutesEnum.STAFF_LIST} component={Staff} />
            <Route exact path={RoutesEnum.STAFF_CREATE} component={StaffEditor} />
            <Route exact path={RoutesEnum.STAFF_EDIT} component={StaffEditor} />
            <Route path={RoutesEnum.STAFF_DETAILS} component={StaffInfo} />
            <Redirect to="/profiles/staff" />
        </Switch>
    );
}