import React, { useMemo } from 'react';
import moment from 'moment';
import { EventHoledr, Eventlabel, EventTitle } from './styled';
import { IProperty } from 'src/store/properties/models/property.interface';
import { IProspect } from 'src/store/prospects/models/prospect.interface';

export const CustomDayEvent = ({ event }: any) => {
    const startLabel = moment(event.event.start).format('hh:mm');
    const endLabel = moment(event.event.end).format('hh:mm A');

    const fontSize = useMemo(() => {
        switch(moment(event.event.end).diff(moment(event.event.start), 'minutes')) {
            case 0:
            case 15:
                return '8px';
            case 30:
                return '9px';
            default:
                return '10px';
        } 
    }, [event]);

    const titleMargin = useMemo(() => {
        switch(moment(event.event.end).diff(moment(event.event.start), 'minutes')) {
            case 0:
            case 15:
                return 'right';
            case 30:
                return 'right';
            default:
                return 'bottom';
        } 
    }, [event]);

    const flexDirection = useMemo(() => {
        switch(moment(event.event.end).diff(moment(event.event.start), 'minutes')) {
            case 0:
            case 15:
                return 'row';
            case 30:
                return 'row';
            default:
                return 'column';
        } 
    }, [event]);

    const { event: { properties, prospects } } = event;

    return (
        <EventHoledr flexDirection={flexDirection}>
            <EventTitle 
                titleMargin={titleMargin} 
                fontSize={fontSize}>
                    { event.title }
                    {
                        prospects && prospects.length 
                            ? prospects.map((prospect: IProspect) => ` / 
                                ${prospect?.bedrN ? `${prospect.bedrN}` : ''}
                                ${prospect?.firstName ? `, ${prospect.firstName}` : ''} 
                                ${prospect?.lastName ? `${prospect.lastName}` : ''}
                                ${prospect?.approxBudgetFrom && prospect?.approxBudgetTo ? `, $${prospect.approxBudgetFrom}-${prospect.approxBudgetTo}`: ''} 
                                ${prospect?.pickupLocation ? `, ${prospect?.pickupLocation}` : ''}
                                ${prospect?.phoneNumber ? `, ${prospect?.phoneNumber}` : ''}
                                ${prospect?.notes ? `, ${prospect.notes}` : ''} 
                            `) 
                            : null
                    }
                </EventTitle>
                <EventTitle 
                    titleMargin={titleMargin} 
                    fontSize={fontSize}>
                        { (properties && properties.length) 
                            ? properties.map((property: IProperty, idx: number) => {
                                if (idx === properties.length - 1) {
                                    return property.unitAddress;
                                }

                                return `${property.unitAddress}, `;
                        }) : null }
                </EventTitle>
            <Eventlabel overflowText fontSize={fontSize}>{ `${startLabel} - ${endLabel}` }</Eventlabel>
        </EventHoledr>
    );
}