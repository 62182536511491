import React from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import Typography from '@material-ui/core/Typography';
import { Button, ButtonVariant } from '../buttons/button';
import styled from 'styled-components';
import { Close } from '@material-ui/icons';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: '37px 0 0',
            textAlign: 'center',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

// const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
//     const { children, classes, onClose, ...other } = props;
//     return (
//         <MuiDialogTitle disableTypography className={classes.root} {...other}>
//             <Typography variant="h6">{children}</Typography>
//             { onClose ? (
//                 <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//                     <CloseIcon />
//                 </IconButton>
//             ) : null }
//         </MuiDialogTitle>
//     );
// });

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: '0 0 37px',
    },
}))(MuiDialogActions);

const DialogTitleStyled = styled.div`
    padding: 37px 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: 'ManropeBold';
`;

const DialogSubtitle = styled.div`
    margin: -28px 0 0;
    padding: 14px 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: 'ManropeThin';
    color: #AAB3C8;
`;

interface IModal {
    open: boolean;
    title?: string;
    subtitle?: string | React.ReactNode;
    content: React.ReactNode | string;
    saveButtonText?: string;
    cancelButtonText?: string;
    onClose?: () => void;
    onSave: () => void;
    titleStyles?: React.CSSProperties;
    subtitleStyles?: React.CSSProperties;
    wrapperStyles?: React.CSSProperties;
}

export function Modal ({ 
    open, 
    title, 
    subtitle, 
    content, 
    wrapperStyles, 
    titleStyles,
    subtitleStyles,
    saveButtonText = "Save", 
    cancelButtonText = "Cancel", 
    onClose, 
    onSave 
}: IModal) {
    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    return (
        <div id="dialog-holder" style={{
            ...wrapperStyles
        }}>
            <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="modal" open={open}>
                {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    { title }
                </DialogTitle> */}
                <div onClick={handleClose} style={{
                    position: "absolute",
                    top: "12px",
                    right: "12px",
                    cursor: "pointer",
                }}>
                    <Close />
                </div>
                <DialogTitleStyled style={titleStyles}>
                    { title }
                </DialogTitleStyled>
                <DialogSubtitle style={subtitleStyles}>{ subtitle }</DialogSubtitle>
                <DialogContent style={wrapperStyles}>
                    { content }
                </DialogContent>
                <DialogActions>
                    <Button text={cancelButtonText} onClick={handleClose} btnVariant={ButtonVariant.OUTLINE} />
                    {
                        saveButtonText
                            ? <Button text={saveButtonText} onClick={onSave} btnVariant={ButtonVariant.PRIMARY} />
                            : null
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}