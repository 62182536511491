import React, { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Panel, PanelType } from "../../../../../ui/components/panel";
import CssClasses from "../tenants.module.scss";
import { SearchInput, SearchInputType } from "../../../../../ui/components/forms/inputs/search";
import { FavButton } from "../../../../../ui/components/buttons/fav-button";
import { ImportIcon, MessageIcon, SettingsIcon, SettingsOutlinedIcon } from "../../../../../ui/icons";
import { Button, ButtonVariant } from "../../../../../ui/components/buttons/button";
import { PropertiesImportingState } from "../../../../../../store/properties/properties.store";
import { Add, ChevronLeft, Delete, Email, Phone } from "@material-ui/icons";
import { ChipViewTypes, FiltersList } from "../../../../../ui/components/filters-list";
import { Table } from "../../../../../ui/components/table";
import { ITenant } from "../../../../../../store/tenants/models/tenant.interface";
import { Sidenav } from "../../../../../ui/components/sidenav";
import { ITenantSearchCriteria, NotificationType } from "../../../../../../store/tenants/search/search.store";
import { Form, FormFieldDividerType, FormType, InputType } from "../../../../../ui/components/forms/formBuilder/form";
import { searchTenantsService } from "../../../../../../store/tenants/search/search.service";
import { INamedEntity } from "../../../../../../store/properties/models/named-entity.interface";
import { tenantsService } from "../../../../../../store/tenants/tenants.service";
import { KeyedNamedEntity } from "../../../../../../store/properties/search/search-properties.query";
import { useHistory, useLocation } from 'react-router-dom';
import { INumerated } from "../../../../../../store/properties/models/numerated.interface";
// import { propertiesQuery  } from "../../../../../../store/properties/properties.query";
import { tenantsSearchQuery } from "../../../../../../store/tenants/search/search.query";
import { useObservable } from "@libreact/use-observable";
import { COLORS } from "../../../../../ui/theme/variables/colors";
// import { propertiesService } from "../../../../../../store/properties/properties.service";
import { ISelectedData } from "src/store/utils/common.interface";
import { tenantGroupsService } from "src/store/tenants/groups/tenant-groups.service";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { messageService, RecieverType } from "src/store/message/message.service";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { TitleHolder } from "src/components/containers/properties/pages/list";
import { useTenants } from "src/store/tenants/hooks/use-tenants";
// import { messageQuery } from "src/store/message/message.query";
import { searchUrlMap } from "src/store/autocomplete/autocomplete.store";
import { SearchType } from "src/store/autocomplete/models/autocomplete.interface";
import { SearchableList } from "src/components/ui/components/searchable-list/searchable-list";
import { usePaging } from "src/store/common/hooks/use-paging";
import { PagingType } from "src/store/common/models/common.interface";
import { tenantsQuery } from "src/store/tenants/tenants.query";
import { get, uniq } from "lodash";

const conditionalRowStyles = [
    {
        // actively renting & i % 2 === 0
        when: (row: INumerated & ITenant) => row.i % 2 === 0,
        style: {
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    }
];


const columns: any = (clickHandler: (e: ChangeEvent<any>) => void) => [
    {
        name: 'First Name',
        selector: 'firstName',
        sortable: true,
        minWidth: '150px',
        cell: (row: ITenant) => <TitleHolder onClick={clickHandler} id={String(row.id)}>{row.firstName}</TitleHolder>
    },
    {
        name: 'Last Name',
        selector: 'lastName',
        sortable: true,
        minWidth: '150px',
        maxWidth: '150px',
    },
    {
        name: 'Company',
        selector: 'company',
        sortable: true,
        minWidth: '60px',
        maxWidth: '150px',
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
        minWidth: '150px',
        maxWidth: '150px',
    },
    {
        name: 'Phone Number',
        selector: 'mobilePhone',
        sortable: true,
        minWidth: '160px',
        maxWidth: '160px',
    },
    {
        name: 'Property',
        selector: 'building',
        sortable: true,
        minWidth: '120px',
        cell: (row: ITenant) => {
            return <div onClick={clickHandler} id={String(row.id)} className={CssClasses.notification_method}>
                { row?.property?.unitAddress }
            </div>
        }
    },
    {
        name: 'Notification Method',
        selector: 'sms',
        sortable: false,
        minWidth: '160px',
        maxWidth: '160px',
        cell: (row: ITenant) => {
            return <div className={CssClasses.notification_method}>
                {row.email && <div onClick={clickHandler} id={String(row.id)} className={CssClasses.icon_email}>
                    <Email style={{fontSize: 14}} />
                </div>}
                {row.sms !== 'NO' && <div onClick={clickHandler} id={String(row.id)} className={CssClasses.icon_phone}>
                    <Phone style={{fontSize: 14}} />
                </div>}
            </div>
        }
    },
    {
        name: 'Tenant Groups',
        selector: 'groups',
        sortable: false,
        minWidth: '120px',
        cell: (row: ITenant) => {
            return <div>
                {uniq([
                    ...row.groups,
                    ...get(row, 'property.groups', []),
                ]).map((g, id) => {
                    return <div onClick={clickHandler} id={String(row.id)} key={id} style={{ width: '100%' }}>
                        {g}
                    </div>
                })}
            </div>
        },
    },
];

interface ITenantsListProps {
    isFilterable?: boolean;
    useSelectedUnitId?: string;
    omitFields?: string[];
    backLink?: string;
    onSubmit?: (selectedTenants: ITenant[]) => void;
}

interface ITenantsLocation {
    from: string;
    selectType: string;
    backLink: string;
    category: string;
}

export const TenantsList: React.FC<ITenantsListProps> = ({
    isFilterable = true,
    useSelectedUnitId,
    // omitFields = [],
    onSubmit,
    backLink
}) => {
    const history = useHistory();
    const location = useLocation<ITenantsLocation>();
    const [selectMode] = useState(location.state?.selectType ? true : false);

    // const [recordsNumber] = useObservable(tenantsQuery.tenantsCount$);
    // const [selectedTenants] = useObservable(tenantsQuery.selectedTenants$);

    const [selectedItemsCount, setSelectedItemsCount] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState<any>([]);

    const [search, setSearch] = useState<string>('');

    const [paging, setPaging, pushLocation] = usePaging(PagingType.TENANTS);
    const [tenants, isLoading] = useTenants(useSelectedUnitId);

    const [importState] = useObservable(tenantsQuery.selectImportState$);

    const [searchCriteria] = useObservable(tenantsSearchQuery.filterCriteria$);

    const [isFilterOpened, setIsFilterOpened] = useState(false);
    const [allSelectedFilters] = useObservable(tenantsSearchQuery.allSelectedFilters$);

    const [isMobile] = useObservable(uiQuery.isMobile$);
    const [isTab, setIsTab] = useState(false);
    const [filterQueries, setFilterQueries] = useState({
        propertyQ: '',
    });

    useEffect(() => {
        return () => {
            if (!history.location.pathname.includes('tenants')) {
                removeAllSelection();
                tenantsService.setTenantsTotal(tenants.length);
            }

            if (tenants.length) {
                tenantsService.setTenantsTotal(tenants.length);
            }
        };
    }, [tenants]);

    // const preparedColumns = useMemo(() => {
    //     return columns.filter((col: any) => {
    //         return !omitFields.includes(col.selector as string);
    //     });
    // }, [omitFields]);

    useEffect(() => {
        if (location.search.includes('tab=tenants')) {
            setIsTab(true);
        }
    }, [location.search]);

    const importData = useCallback(() => {
        tenantsService.importTenants()
    }, []);

    const sidenavWidth = useMemo(() => {
        const DESKTOP_SIDENAV_WIDTH = 542;

        if(isMobile) {
            return '100%';
        } else {
            return DESKTOP_SIDENAV_WIDTH;
        }
    }, [isMobile]);

    const filteredTenants = useMemo(() => {
        if (search) {
            const searchQ = search.toLowerCase().trim();

            return tenants.filter((tenant: ITenant) => {
                const fullName = `${tenant.firstName} ${tenant.lastName}`;

                if (
                    fullName.toLowerCase().includes(searchQ) ||
                    tenant.email.toLowerCase().includes(searchQ) ||
                    tenant.mobilePhone.toLowerCase().includes(searchQ) ||
                    tenant?.property?.unitAddress.toLowerCase().includes(searchQ)
                ) {
                    return tenant;
                }
            });
        }

        return tenants;
    }, [tenants, search]);

    useEffect(() => {
        tenantsService.setTenantsTotal(filteredTenants.length);
    }, [filteredTenants]);

    const handleSelectionChange = (data: ISelectedData<ITenant>) => {
        // tenantsService.setSelectedTenants({
        //     data: data ? data?.selectedRows : [],
        // });
        setSelectedItemsCount(() => data.selectedCount);
        setSelectedItems(() => data.selectedRows);
    };

    const onClose = (cachedData?: ITenantSearchCriteria) => {
        // if (cachedData) {
        //     searchTenantsService.updateSelectionCriteria(cachedData);
        // }

        setIsFilterOpened(false);
    }

    const onOpen = () => {
        setIsFilterOpened(true);
    }

    const changeCriteria = (criteriaName: keyof ITenantSearchCriteria) => (item: INamedEntity) => {
        let data = (searchCriteria[criteriaName] as Array<any>);
        if (data.find(i => i.id === item.id)) {
            data = data.filter(i => i.id !== item.id);
        } else {
            data = [
                ...data,
                {
                    ...item,
                    isSelected: true
                }
            ]
        }
        searchTenantsService.updateSelectionCriteria({
            ...searchCriteria,
            [criteriaName]: data
        })
    }

    const smallMargin: React.CSSProperties = {
        marginLeft: 10,
    };

    const changePage = (page: number) => {
        // table component numerating start from 1, BE expects 0 as the first page
        setPaging(page, paging.rowsPerPage);
        // tenantsService.fetchTenants(page - 1, paging.rowsPerPage);
    }

    const changeRowsNumber = (rowPerPage: number, page: number) => {
        setPaging(page, rowPerPage);
        // tenantsService.fetchTenants(page - 1, rowPerPage);
    };


    const onRowClicked = (e: any) => {
        pushLocation(`/profiles/tenants/${e.id}`, PagingType.TENANTS);
    };

    const onCustomCellClick = useCallback((e: ChangeEvent<any>) => {
        pushLocation(`/profiles/tenants/${e.currentTarget.id}`, PagingType.TENANTS);
    }, [history, paging])

    const onFilterDelete = (shouldResetTenants: boolean) => (e: KeyedNamedEntity<keyof ITenantSearchCriteria>) => {
        searchTenantsService.deleteItem(e);
        searchTenantsService.applyFilters();

        // if (shouldResetTenants) {
        //     tenantsService.fetchTenants(0, paging.rowsPerPage);
        // }
    }

    // const onSort = useCallback((column: IDataTableColumn<ITenant & INumerated>, sortDirection: "desc" | "asc", rows: number) => {
    //     searchTenantsService.setSorting({
    //         type: sortDirection,
    //         field: column.selector as keyof ITenant
    //     });
    //     tenantsService.fetchTenants(0, rows);
    // }, []);

    const removeAllSelection = () => {
        searchTenantsService.removeAllSelection();

        // tenantsService.fetchTenants(0, paging.rowsPerPage);
        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const onApply = () => {
        // tenantsService.fetchTenants(0, paging.rowsPerPage);
        searchTenantsService.applyFilters();
        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const changeSearch = (q: string) => {
        // searchTenantsService.changeSearchQ(q);
        setSearch(q);
    }

    const addToTheList = (type?: string) => {
        switch(type || location.state?.from) {
            case '/message':
                messageService.selectRecievers<ITenant>(RecieverType.TENANTS, selectedItems);
                history.push('/message', { from: 'tenants', selectType: 'tenants', category: 'profiles', });
                break;
            default:
                tenantGroupsService.updateSelectedData(selectedItems, 'tenants');
                history.push(location.state?.backLink, { selectType: 'tenants', category: 'profiles', from: 'tenants?tab=property_groups', });
        }

        tenantsService.resetSelectedTenants();
    }

    const handleSendMessage = () => {
        if (selectedItems?.length) {
            messageService.selectRecievers(RecieverType.TENANTS, selectedItems);
            // tenantsService.resetSelectedTenants();
            history.push('/message', { from: 'tenants', backLink: 'tenants', category: 'profiles' });
        }
    }

    return <Fragment>
        {(backLink || location.state?.from) && <div className="link_wrapper" style={{ cursor: 'pointer' }} onClick={() => history.push(location.state.from || `${backLink}?mode=EDITING`, {
            from: backLink || location.state?.selectType,
            selectType: location.state?.selectType,
            category: 'profiles',
        })}>
            <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
        </div>}
        <Panel type={PanelType.EMPTY}>
            {
                isMobile
                    ? (
                        <div className={CssClasses.list_action_bar_right}>
                            <Fragment>
                                {
                                    !isTab && !selectMode
                                        ? <Button
                                            icon={<MessageIcon />}
                                            style={smallMargin}
                                            btnVariant={ButtonVariant.OUTLINE}
                                            text=""
                                            onClick={() => history.push('/message', { 
                                                selectType: 'tenants', 
                                                from: 'tenants',
                                                category: 'profiles',
                                            })}
                                        />
                                        : null
                                }
                                {(!onSubmit && !isTab && !selectMode) && <Button
                                    isLoading={importState === PropertiesImportingState.IN_PROGRESS}
                                    onClick={importData}
                                    icon={<ImportIcon />}
                                    style={smallMargin}
                                    loadingText="Importing Tenants Data..."
                                    btnVariant={ButtonVariant.PRIMARY}
                                    text="Import"
                                />}
                            </Fragment>

                            {(onSubmit || selectMode) && <Button
                                onClick={() => addToTheList()}
                                icon={<Add style={{fontSize: 14}}/>}
                                style={smallMargin}
                                btnVariant={ButtonVariant.PRIMARY}
                                text={`Add to the list ${selectedItems.length ?`: ${selectedItems.length}` : ""}`}
                            />}
                        </div>
                ): null
            }
            <div className={CssClasses.list_action_bar}>
                <div className={CssClasses.property_action_bar_left}>
                    <SearchInput
                        onChange={changeSearch}
                        inputType={SearchInputType.PRIMARY}
                        placeholderText="Search..."
                    />
                    {isFilterable && <FavButton
                        style={{marginLeft: 15}}
                        icon={<SettingsIcon/>}
                        onClick={onOpen}
                        badge={allSelectedFilters.length > 0 ? allSelectedFilters.length.toString() : undefined}
                    />}
                </div>
                    {
                        !isMobile
                            ? (
                                <div className={CssClasses.list_action_bar_right}>
                                    {
                                        !isTab && !selectMode
                                            ? <Button
                                                icon={<MessageIcon />}
                                                style={smallMargin}
                                                btnVariant={ButtonVariant.OUTLINE}
                                                text="Message"
                                                onClick={() => history.push('/message', { 
                                                    selectType: 'tenants', 
                                                    from: 'tenants',
                                                    category: 'profiles',
                                                })}
                                            />
                                            : null
                                    }
                                    {(!onSubmit && !isTab && !selectMode) && <Button
                                        isLoading={importState === PropertiesImportingState.IN_PROGRESS}
                                        onClick={importData}
                                        icon={<ImportIcon />}
                                        style={smallMargin}
                                        loadingText="Importing Tenants Data..."
                                        btnVariant={ButtonVariant.PRIMARY}
                                        text="Import"
                                    />}

                                    {(onSubmit || selectMode) && <Button
                                        onClick={() => addToTheList()}
                                        icon={<Add style={{fontSize: 14}}/>}
                                        style={smallMargin}
                                        btnVariant={ButtonVariant.PRIMARY}
                                        text={`Add to the list ${selectedItems.length ?`: ${selectedItems.length}` : ''}`}
                                    />}
                                </div>
                            )
                            : null
                    }
            </div>
        </Panel>
        {allSelectedFilters.length > 0 && <Panel additionalStyles={
            isMobile ? { 
                margin: '0 0 15px',
                padding: '8px 0',
                width: '97.5%',
            } : {}
        }>
            <FiltersList filtersTitleName="" onDelete={onFilterDelete(true)} items={allSelectedFilters}/>
        </Panel>}
        {(selectedItemsCount > 0 && !selectMode) && <Panel additionalStyles={
            isMobile ? { 
                margin: '0 0 15px',
                width: '97.5%',
            } : {}
        }>
            <div className={CssClasses.list_action_props_selected}>
                <div className={CssClasses.list_action_bar_left}>
                    {`${selectedItemsCount} Selected`}
                </div>
                <div className={CssClasses.selected_action_bar_right}>
                    {
                        !isTab && !selectMode
                            ? <Button
                                icon={<MessageIcon />}
                                btnVariant={ButtonVariant.OUTLINE}
                                text={isMobile ? '' : 'Message'}
                                onClick={handleSendMessage}
                            />
                            : null
                    }
                </div>
            </div>
        </Panel>}
        <Panel noPadding sideBorder>
            <Table<ITenant>
                contextActions={[
                    <div className={CssClasses.delete_properties}>
                        <Delete/> Delete Selected
                    </div>
                ]}
                pagination={!useSelectedUnitId}
                paginationServer={false}
                paginationServerOptions={{
                    persistSelectedOnPageChange: false, 
                    persistSelectedOnSort: false,
                }}
                onSelectionChange={handleSelectionChange}
                onChangePage={changePage}
                totalRecordsNumber={filteredTenants.length}
                onRowClicked={!selectMode ? onRowClicked : () => {}}
                paginationPerPage={paging.rowsPerPage}
                onChangeRowsNumber={changeRowsNumber}
                columns={columns(onCustomCellClick)}
                conditionalRowStyles={conditionalRowStyles}
                // onSort={onSort}
                loading={isLoading}
                data={filteredTenants}
                highlightRow={['firstName', 'lastName']}
                activePage={paging.activePage}
                noDataText={importState === PropertiesImportingState.IN_PROGRESS 
                    ? `The data will be displayed after importing` 
                    : ''}
            />
        </Panel>
        <Sidenav<ITenantSearchCriteria>
            header={<>
                <SettingsOutlinedIcon />
                <span>
                    Filters
                </span>
            </>}
            withIcon
            footer={<>
                <Button
                    btnVariant={ButtonVariant.OUTLINE}
                    onClick={removeAllSelection}
                    text="Clear All"
                />
                <Button
                    style={{marginLeft: 12}}
                    onClick={onApply}
                    btnVariant={ButtonVariant.PRIMARY}
                    text="Apply"
                />
            </>}
            width={sidenavWidth}
            isOpen={isFilterOpened}
            cachedData={searchCriteria}
            onClose={onClose}
        >
            <Fragment>
                <div className={CssClasses.filters_wrapper}>
                    <FiltersList
                        filtersTitleName="Choose From The List Below"
                        type={ChipViewTypes.BLOCK}
                        items={allSelectedFilters}
                        onDelete={onFilterDelete(false)}
                    />
                </div>
                <FormHolder>
                    <Form<ITenantSearchCriteria>
                        type={FormType.DEFAULT}
                        data={searchCriteria}
                        wrapperStyles={{
                            margin: '0 0 0 20px'
                        }}
                        onChange={(e) => {
                            searchTenantsService.updateSelectionCriteria(e);
                        }}
                        fields={{
                            property: {
                                index: 0,
                                label: 'Property',
                                labelDisplay: 'block',
                                labelStyles: {
                                    padding: 0,
                                },
                                height: 'auto',
                                withDividers: FormFieldDividerType.BOTTOM,
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <Fragment>
                                    <SearchableList
                                        id="property"
                                        url={searchUrlMap.get(SearchType.PROPERTIES)}
                                        searchType={SearchType.PROPERTIES}
                                        selectedCriteria={searchCriteria.property}
                                        onSearchChange={(q: string) => setFilterQueries({
                                            ...filterQueries,
                                            propertyQ: q
                                        })}
                                        onSelect={changeCriteria('property')}
                                    />
                                </Fragment>
                            },
                            notificationMethod: {
                                index: 1,
                                label: 'Notification Method',
                                labelDisplay: 'block',
                                labelStyles: {
                                    padding: 0,
                                },
                                groupStyles: {
                                    margin: isMobile ? '7px 0 0 32px' : '7px 0 0 54px',
                                },
                                inputStyles: {
                                    flexDirection: 'column',
                                },
                                withDividers: FormFieldDividerType.BOTTOM,
                                fieldType: InputType.SELECT,
                                height: 'auto',
                                selectableItems: [
                                    {
                                        label: 'SMS',
                                        value: NotificationType.sms,
                                    },
                                    {
                                        label: 'Email',
                                        value: NotificationType.email,
                                    }
                                ]

                            }
                        }}
                    />
                </FormHolder>
            </Fragment>
        </Sidenav>
    </Fragment>
}