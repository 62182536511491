import {QueryEntity} from "@datorama/akita";
import {ITenantGroupsState, tenantGroupsStore, TenantGroupsStore} from "./tenant-groups.store";
import {ITenantInGroup} from "./models/tenant-in-group.interface";
import {ITenant} from "../models/tenant.interface";
import {Observable} from "rxjs";
import {IGroup} from "./models/group.interface";
import { EMPTY_STRING_PLACEHOLDER } from "../tenants.config";

export class TenantGroupsQuery extends QueryEntity<ITenantGroupsState> {

    private tenantsDtoToTableView(tenant:ITenant): ITenantInGroup {
        return {
            name: `${tenant.firstName} ${tenant.lastName}`,
            email: tenant.email,
            phone: tenant.mobilePhone || tenant.homePhone,
            notificationMethod: ['email']
        }
    }

    groups$: Observable<IGroup[]> = this.select(state => {
        const groups: IGroup[] = [];

        if (Array.isArray(state.groups) && state.groups.length) {
            state.groups.forEach(group => {
                const mergedTenantsList: ITenantInGroup[] = [
                    ...group?.tenants?.map(this.tenantsDtoToTableView) || [],
                    ...group?.properties?.map(p => p?.tenants).flat().map(this.tenantsDtoToTableView) || []
                ] as ITenantInGroup[];
    
                const updatedGroup: IGroup = {
                    ...group,
                    emails: [],
                    tenantNames: [],
                    phoneNumbers: [],
                };
    
                mergedTenantsList.forEach((t, i) => {
                    updatedGroup.tenantNames[i] = t.name || EMPTY_STRING_PLACEHOLDER;
                    updatedGroup.emails[i] = t.email || EMPTY_STRING_PLACEHOLDER;
                    updatedGroup.phoneNumbers[i] = t.phone || EMPTY_STRING_PLACEHOLDER;
                });
    
                groups.push(updatedGroup);
            });
        }

        return groups;
    });

    recordsCount$ = this.select(state => state.recordsCount);
    isLoading$ = this.selectLoading();
    currentGroup$ = this.select(state => state.selectedGroup);
    mergedTenants$ = this.select(state => state.mergedTenantsList.map(this.tenantsDtoToTableView));
    currentGroupTenants$ = this.select(({selectedGroup}) => {
        if(!selectedGroup) {
            return []
        } else {
            const tenantsInGroup: ITenantInGroup[] = [];
            selectedGroup.tenants?.forEach((tenant, i) => {
                tenantsInGroup.push({
                    name: selectedGroup.tenantNames[i],
                    email: selectedGroup.emails[i],
                    phone: selectedGroup.phoneNumbers[i],
                    notificationMethod: ['email']
                })
            });
            return tenantsInGroup;
            // selectedGroup.
        }
    });

    searchItems$ = this.select(state => state.searchData);
    selectedItems$ = this.select(state => state.selectedData);
    loadingSearchItems$ = this.select(state => state.searchData.loading);

    groupsTotal$ = this.select(state => state.groupsTotal);

    constructor(protected tenantGroupStore: TenantGroupsStore) {
        super(tenantGroupStore);
    }
}

export const tenantGroupsQuery = new TenantGroupsQuery(tenantGroupsStore);