import React, {ChangeEvent, FormEvent, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {InputTypeSelector} from './inputTypeSelector';
import {FormControl, FormLabel, makeStyles} from '@material-ui/core';
import {HintInfo} from './hintInfo';
import {Button, ButtonVariant} from "../../buttons/button";
import {COLORS} from "../../../theme/variables/colors";
import {IIntegerInputProps} from "../inputs/int-input";
import CssClasses from './form.module.scss';

export enum FormType {
    DEFAULT = 'DEFAULT',
    LARGE = 'LARGE',
}

export enum InputType {
    DROPDOWN,
    STRING,
    NUMBER,
    INT_INPUT,
    SELECT,
    SINGLE_VALUE_SELECT,
    RADIO,
    DATE,
    NUMBER_BETWEEN,
    CUSTOM_ELEMENT,
    DATE_RANGE,
}

export enum FormFieldDividerType {
    TOP,
    BOTTOM,
    BOTH,
    NONE,
}

export interface IFormField {
    format?: string;
    label: string;
    labelHint?: string;
    labelInfo?: ReactNode;
    labelDisplay?: string;
    placeholder?: string;
    height?: string;
    labelStyles?: React.CSSProperties;
    wrapperStyles?: React.CSSProperties;
    groupStyles?: React.CSSProperties;
    inputStyles?: React.CSSProperties;
    withDividers?: FormFieldDividerType;
    selector?: string;
    fieldType: InputType;
    unit?: string;
    validators?: Array<(value: any) => boolean>;
    rules?: any;
    validate?: boolean;
    readonly?: boolean;
    index?: number;
    intType?: 'circle' | 'square';
    mobileDirection?: "row" | "column";
    shortRange?: boolean;
    disabled?: boolean;
    disabledStyles?: React.CSSProperties;

    hidden?: boolean;

    customElement?: React.ReactNode;

    selectableItems?: {
        label: string | React.ReactNode;
        value: any;
        disabled?: boolean;
    }[];

    intInputConfig?: Partial<IIntegerInputProps>;
}

export interface IRenderableFormField extends IFormField {
    dataField: string;
    value: any;
    valid: boolean;
    mobileDirection?: "row" | "column";
    isMobile?: boolean;
}

export interface IFormProps<T> {
    type?: FormType;
    data: T;
    fields: Partial<Record<keyof T, IFormField>>;
    onChange?: (data: T) => void;
    onBlur?: (field: string) => (e: ChangeEvent<any>) => void;
    onSubmit?: (data: T) => void;
    submitBtnText?: string;
    onCancel?: (data: T) => void;
    cancelBtnText?: string;
    readOnly?: boolean;
    wrapperStyles?: React.CSSProperties;
}

const createStyles = makeStyles({
    largeForm: {
        width: '80%',
        margin: '0 auto',
    },
    defaultForm: {
        width: '100%',
        margin: '0 auto',
        padding: '0 15px',
    },
    formControl: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        padding: '5px 0'
    },
    topDivider: {
        padding: '20px 0',
        borderTop: `1px solid rgba(209, 217, 229, 0.35)`,
    },
    bottomDivider: {
        margin: '0 0 20px',
        padding: '0 0 20px',
        borderBottom: `1px solid rgba(209, 217, 229, 0.35)`,
    },
    bothDividers: {
        padding: '20px 0',
        borderTop: `1px solid rgba(209, 217, 229, 0.35)`,
        borderBottom: `1px solid rgba(209, 217, 229, 0.35)`,
    },
    formControlLarge: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    labelContentWrapper: {
        display: 'flex',
    },
    inputWrapperLarge: {
        width: '100%',
    },
});

const FormLabelStyled = styled(FormLabel)`
    width: 238px;
    display: flex;
    padding: 0 0 0 26px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    font-size: 14px !important;
    /* min-width: 200px; */
    /* font-family: 'ManropeThin'; */
    color: ${COLORS.DARK_GRAY};
    font-family: 'ManropeThin' !important;


    &.Mui-focused {
        color: ${COLORS.DARK_GRAY} !important;
    }

    &:not(:last-child) {
        margin: 0 0 20px;
    }

    @media all and (max-width: 1200px) {
        font-size: 13px !important;

        &:not(:last-child) {
            margin: 0 0 6px;
        }
    }
`;

const ButtonsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    text-align: center;
    align-items: center;
`;

const RightContentWrapper = styled.div`
    display: flex;
    position: absolute;
    right: 35px;
`;

export function Form<T>(props: IFormProps<T>) {
    const {
        data,
        submitBtnText = 'Save',
        cancelBtnText = 'Cancel',
        onCancel,
        onSubmit,
        onChange,
        onBlur,
        fields,
        wrapperStyles = {},
        type = FormType.DEFAULT,
        readOnly = false,
    } = props;

    const [initialState, setInitialState] = useState<Partial<T>>({});

    const classes = createStyles();
    const fieldsArray: IRenderableFormField[] = useMemo(() => {
        if (!data) {
            return [];
        }

        return Object.entries(data)
            // show NOT all the fields of the passed data but only the ones passed in fields
            .filter(([key]) => fields[key as keyof T])
            .map(([key, dataFieldValue]) => {
                const isInInitialState = initialState[key as keyof T] === dataFieldValue;
                const formFieldData = fields[key as keyof T] as IFormField;
                const isValid: boolean = isInInitialState
                    || !formFieldData.validators
                    || !formFieldData.validators.length
                    || !formFieldData.validators.some(validator => !validator(dataFieldValue));
                const {selector} = formFieldData;

                return {
                    ...fields[key as keyof T],
                    dataField: key,
                    value: (selector && dataFieldValue) ? dataFieldValue[selector] : dataFieldValue,
                    valid: isValid,
                };
            })
            .sort((r1, r2) => (r1.index || 0) - (r2.index || 0))
    }, [data, initialState, fields]);

    useEffect(() => {
        setInitialState(data);
        // eslint-disable-next-line
    }, []);

    const isDefaultFormType = useMemo(() => type === FormType.DEFAULT, [type]);

    const isFieldValid = (field: any) => {
        if (field.rules) {
            if (field.rules.required) {
                return !!field.value;
            }
        }

        return true;
    }

    // since untouched inputs should not be marked as invalid initially, we need to re-run validation
    // in order to disable the form
    const isFormValid = useMemo(() => {
            return fieldsArray
                .map(field => {
                    return !field.validators
                        || !field.validators.length
                        || !field.validators.some(validator => !validator(field.value))
                        || isFieldValid(field);
                })
                .some(field => !field);
        }, [fieldsArray],
    );

    const submitHandler = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (onSubmit && !isFormValid) {
            onSubmit(data);
        }
    }, [onSubmit, isFormValid, data]);

    return (
        <form
            onSubmit={submitHandler}
            className={isDefaultFormType ? CssClasses.default_form : classes.largeForm}
            style={wrapperStyles}
        >
            {fieldsArray.map(field => {
                const {
                    height = '50px', 
                    wrapperStyles = {}, 
                    groupStyles = {}, 
                    inputStyles = {},
                    hidden = false,
                    // withDividers = FormFieldDividerType.NONE
                } = field;

                // const formControlClass = classNames({
                //     [classes.formControl]: true,
                //     [classes.topDivider]: withDividers === FormFieldDividerType.TOP,
                //     [classes.bothDividers]: withDividers === FormFieldDividerType.BOTH,
                //     [classes.bottomDivider]: withDividers === FormFieldDividerType.BOTTOM,
                // })

                return !hidden ? <FormControl
                    className={CssClasses.formControl}
                    style={{
                        height,
                        ...wrapperStyles
                    }}
                    key={field.dataField}
                >
                    {field.labelDisplay === 'block' 
                        ? <FormLabelStyled style={field.labelStyles}>
                            {field.label ? field.label : null}
                        </FormLabelStyled>
                        : null
                    }

                    <div
                        className={isDefaultFormType 
                                ? CssClasses.input_wrapper 
                                : CssClasses.inputWrapperLarge}
                        style={{
                            ...groupStyles,
                            // maxWidth: 539,
                            height: field.height ? field.height : '40px'}}
                    >
                        {InputTypeSelector({
                            fieldData: field,
                            readonly: readOnly,
                            type: field.fieldType,
                            isValid: isFieldValid(field),
                            rules: field.rules,
                            inputStyles,
                            onBlur: (field: string) => (e: ChangeEvent<any>) => {
                                if (typeof onBlur === 'function') {
                                    onBlur(field)(e);
                                }
                            },
                            onChange: (e: ChangeEvent<any>) => {
                                if (onChange) {
                                    let {target: {value}}: any = e;
                                    // value should be an array
                                    // if(field.fieldType === InputType.SINGLE_VALUE_SELECT) {
                                    //     // value = !value;
                                    //    value = !value;
                                    // }
                                    if (field.fieldType === InputType.SELECT) {
                                        const initialValue = [...field.value];
                                        if (initialValue.includes(value)) {
                                            value = initialValue.filter(v => v !== value);
                                        } else {
                                            value = [
                                                ...initialValue,
                                                value
                                            ];
                                        }
                                    }
                                    if (field.fieldType === InputType.NUMBER_BETWEEN) {
                                        if (!e.target.name) {
                                            value = field.value;
                                        } else {
                                            const fieldSubtype = e.target.name.replace(`${field.dataField}-`, '');
                                            value = {
                                                ...field.value,
                                                [fieldSubtype]: value < 0
                                                    ? 0
                                                    : parseInt(value),
                                            }
                                        }
                                    }

                                    onChange({
                                        ...data,
                                        [field.dataField]: value,
                                    });
                                }
                            },
                            CustomElement: field.customElement,
                        })}
                        {(field.unit || field.labelInfo) && <RightContentWrapper>
                            {field.unit && <>{field.unit}</>}
                            {field.labelInfo && isDefaultFormType && <HintInfo>
                                {field.labelInfo}
                            </HintInfo>}
                        </RightContentWrapper>}
                    </div>
                </FormControl>
                : null;
            })}
            {(!!onSubmit || !!onCancel) && <ButtonsSection>
                {onSubmit &&
                <Button
                    disabled={isFormValid}
                    style={{margin: '10px auto'}}
                    btnVariant={ButtonVariant.PRIMARY}
                    text={submitBtnText}
                />}
                {onCancel && <Button
                    style={{width: '30%'}}
                    onClick={() => {
                        onCancel && onCancel(data);
                    }}
                    btnVariant={ButtonVariant.OUTLINE}
                    text={cancelBtnText}
                />}
            </ButtonsSection>}
        </form>
    );
}
