import React, { Fragment } from 'react';
import { useFetcher } from 'src/components/fetcher/hooks/use-fetcher';
import { FetchStatus } from 'src/store/data-fetcher/data-fetcher.store';
import { Button, ButtonVariant } from '../ui/components/buttons/button';
import { BlockingLoader } from '../ui/components/loaders/blocking-loader';
import { PanelLoader, TabLoader } from './loaders';
import { IDataFetcher, IErrorComponent, IRenderData, LoaderType } from './model/fetcher.interface';

const ErrorComponent: React.FC<IErrorComponent> = ({ onClick }) => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'cnter',
            flexDirection: 'column',
            padding: '40px',
            width: '100%',
        }}>
            <div>Error!</div>
            <div style={{
                margin: '0 0 24px',
            }}>Something went wrong...</div>
            <Button 
                btnVariant={ButtonVariant.PRIMARY}
                text="Retry"
                onClick={onClick} />
        </div>
    );
}

export interface IFetchParams {
    contractId?: number | string;
    page?: number;
    size?: number;
    search?: string;
}



const RenderData = <T extends {}, P extends unknown[]>({ 
    data,
    request,
    changePage,
    changeSearch,
    changeRowsNumber,
    sortColumn,
    applyFilters,
    componentFn,
    refetch,
    paging,
}: IRenderData<T, P>) => {
    if (React.isValidElement(componentFn({
        data,
        changePage,
        changeRowsNumber,
        changeSearch,
        sortColumn,
        applyFilters,
        request,
        refetch,
        paging,
    }))) {
        return componentFn({
            data,
            changePage,
            changeRowsNumber,
            changeSearch,
            sortColumn,
            applyFilters,
            request,
            refetch,
            paging,
        });
    } else {
        return <ErrorComponent onClick={() => {}} />
    }
}

export const Fetcher = <T extends {}, P extends unknown[]>({ 
    children,
    action,
    // cache = false,
    pagingType,
    loaderType = LoaderType.BLOCKING,
    initParams,
    inititalState,
    emptyDataText,
    noDataFallback,
    fallbackData,
}: IDataFetcher<T, P>) => {
    const {
        data,
        fetchStatus,
        error,
        changePage,
        changeRowsNumber,
        changeSearch,
        sortColumn,
        applyFilters,
        request,
        refetch,
        paging,
    } = useFetcher<T, P>({
        action,
        pagingType,
        initParams,
        inititalState,
    });

    // console.log("data -> ", data);

    if (fetchStatus === FetchStatus.PENDING) {
        switch(loaderType) {
            case LoaderType.BLOCKING:
                return <BlockingLoader />;
            case LoaderType.TAB:
                return <TabLoader />;
            default: 
                return <PanelLoader />;
        }
    }

    if (!data && typeof fallbackData !== 'undefined') {
        return <RenderData 
            data={fallbackData}
            request={request}
            componentFn={children}
            changePage={changePage}
            changeRowsNumber={changeRowsNumber}
            sortColumn={sortColumn}
            changeSearch={changeSearch}
            applyFilters={applyFilters}
            refetch={refetch}
            paging={paging} />
    }

    if (!data || (Array.isArray(data) && !data.length)) {
        if (emptyDataText) {
            return <Fragment>{emptyDataText}</Fragment>;
        }

        if (typeof noDataFallback === 'function') {
            return noDataFallback(request);
        }

        return null;
    }

    if (fetchStatus === FetchStatus.FAILED) {
        console.log('Error: ', error);

        return <ErrorComponent onClick={() => {}} />
    }

    return <RenderData 
        data={data}
        request={request}
        componentFn={children}
        changePage={changePage}
        changeRowsNumber={changeRowsNumber}
        sortColumn={sortColumn}
        changeSearch={changeSearch}
        applyFilters={applyFilters}
        refetch={refetch}
        paging={paging} />
}