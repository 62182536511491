import React, { useEffect } from 'react';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { ITab, Tabs } from 'src/components/ui/components/tabs/tabs';
import { EmailSettings } from './tabs/email-settings';
import { Header3 } from 'src/components/ui/components/headers';
import { RouteComponentProps } from 'react-router-dom';
import { SMSSettings } from './tabs/sms-settings';
import { NotificationSettings } from './tabs/notification-settings';
import { AppointmentsSettings } from './tabs/appointment-settings';
import { FollowUpsSettings } from './tabs/follow-ups-settings';
import { adminSettingsService } from 'src/store/admin-settings/admin-settings.service';

import { useTemplate } from 'src/store/admin-settings/hooks/use-template';
import { useNotification } from 'src/store/admin-settings/hooks/use-notification';

import styles from '../../admin.module.scss';
import { ContentHolder } from 'src/components/ui/main-layout/layout';
import { useObservable } from '@libreact/use-observable';
import { uiQuery } from 'src/store/ui-storage/ui.query';

interface IAdminSettingsProps extends RouteComponentProps<{id: string}> {}

export const AdminSettings: React.FC<IAdminSettingsProps> = ({ location }) => {
    const [emailTemplates, smsTemplates, loading] = useTemplate();
    const [emailNotification, smsNotification] = useNotification();
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        adminSettingsService.init();
    }, []);

    const tabs: ITab[] = [
        {
            basePath: location.pathname,
            specificPath: '',
            children: <EmailSettings 
                emailNotification={emailNotification}
                templates={emailTemplates}
                isMobile={isMobile}
                loading={loading} />,
            tabName: 'Email Settings',
            panelType: PanelType.EMPTY,
            tabStyles: {
                padding: '20px',
            },
        },
        {
            basePath: location.pathname,
            specificPath: 'sms_settings',
            children: <SMSSettings 
                templates={smsTemplates} 
                smsNotification={smsNotification}
                isMobile={isMobile} />,
            tabName: 'SMS Settings',
            panelType: PanelType.EMPTY,
            tabStyles: {
                padding: '20px',
            },
        },
        {
            basePath: location.pathname,
            specificPath: 'appointment_calendar',
            children: <AppointmentsSettings isMobile={isMobile} />,
            tabName: 'Appointment & Calendar',
            panelType: PanelType.EMPTY,
            tabStyles: {
                padding: '20px',
            },
        },
        // {
        //     basePath: location.pathname,
        //     specificPath: 'import_export',
        //     children: <ImportExportSettings notificationList={[]} />,
        //     tabName: 'Import & Export',
        //     panelType: PanelType.EMPTY,
        //     tabStyles: {
        //         padding: '20px',
        //     },
        // },
        {
            basePath: location.pathname,
            specificPath: 'auto_notifications',
            children: <NotificationSettings isMobile={isMobile} />,
            tabName: 'Auto Notifications',
            panelType: PanelType.EMPTY,
            tabStyles: {
                padding: '20px',
            },
        },
        {
            basePath: location.pathname,
            specificPath: 'auto_follow_ups',
            children: <FollowUpsSettings isMobile={isMobile} />,
            tabName: 'Auto Follow Ups',
            panelType: PanelType.EMPTY,
            tabStyles: {
                padding: '20px',
            },
        },
        // {
        //     basePath: location.pathname,
        //     specificPath: 'edit_clauses_forms',
        //     children: <EditFormSettings notificationList={[]} />,
        //     tabName: 'Edit Clauses & Forms',
        //     panelType: PanelType.EMPTY,
        //     tabStyles: {
        //         padding: '20px',
        //     },
        // }
    ];

    return (
        <ContentHolder noPadding smallPadding>
            <Panel additionalStyles={
                isMobile ? {
                    margin: '-6px 0 0',
                    borderRadius: '0 0 0 10px',
                } : { marginTop: 7 }
            } type={PanelType.EMPTY}>
                <div className={styles.top_wrapper}>
                    <Header3 style={{margin: '10px 0'}}>
                        Settings
                    </Header3>
                </div>
            </Panel>
            <Tabs
                    tabs={tabs}
                />
        </ContentHolder>
    );
}