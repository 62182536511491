import { createEntityStore, EntityState } from "@datorama/akita";
import { PropertiesImportingState } from "../properties/properties.store";
import { IOwner } from "./models/owner.interface"; 

export interface IOwnersState extends EntityState {
    owners: IOwner[];
    ownersCount: number;
    currentOwner: IOwner | null;
    lastImported?: string | number | Date;
    selectedOwners: IOwner[];
    messageHistory: any[];
    importingState: PropertiesImportingState;
}

const initialState: IOwnersState = {
    owners: [],
    selectedOwners: [],
    currentOwner: null,
    ownersCount: 0,
    messageHistory: [],
    importingState: PropertiesImportingState.NOT_STARTED,
}

export const ownersStore = createEntityStore<IOwnersState>(initialState, {
    name: 'owners'
});