import { useObservable } from '@libreact/use-observable';
import React, { useEffect, useState } from 'react';
import { Avatar, AvatarSize, FallbackType } from 'src/components/ui/components/avatar/avatar';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { CalendarIcon } from 'src/components/ui/icons';
import { IStaff } from 'src/store/staff/models/staff.inreface';
import { uiQuery } from 'src/store/ui-storage/ui.query';
import { IUser } from 'src/store/user/models/user.interface';

import CalendarClasses from './calendar.module.scss';

interface ICalendarAgent {
    staff: IStaff[];
    colorsMap: Map<number, any>;
    onAgentSelect: (data: Set<any>) => void;
    user: IUser | null
}

export const CalendarAgents: React.FC<ICalendarAgent> = ({ staff, colorsMap, onAgentSelect, user }) => {
    const [selectedAgents, setSelectedAgents] = useState(new Set<number>());
    const [isAllSelected, setIsAllSelected] = useState(true);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        if (isAllSelected) {
            user !== null && setSelectedAgents(new Set(staff.map(s => user.id)))
            user !== null &&  onAgentSelect(new Set(staff.map(s => user.id)))
        }
    }, [staff]);

    return (
        <div id="calendar-view">
            <Panel type={PanelType.WITH_SHADOW} additionalStyles={ isMobile ? {
                margin: '0 0 28px',
                padding: '0 20px 15px',
            } : { 
                margin: '0 20px 28px',
                padding: '0 20px 15px',
            }}>
                <FormHolder
                    icon={<CalendarIcon />}
                    title="Calendar View"
                >
                <div className={CalendarClasses.agents_wrapper}>
                    <div className={CalendarClasses.agent_container}>
                        <input
                            checked={selectedAgents.size === staff.length}
                            onChange={() => {
                                if (selectedAgents.size === staff.length) {
                                    setSelectedAgents(new Set());
                                    onAgentSelect(new Set());
                                    setIsAllSelected(false);
                                } else {
                                    setSelectedAgents(new Set(staff.map(s => s.id)));
                                    onAgentSelect(new Set(staff.map(s => s.id)));
                                    setIsAllSelected(true);
                                }
                            }}
                            type="checkbox"
                        />
                        <span style={{marginLeft: 10}}>
                            Select All
                        </span>
                    </div>
                    {staff
                        .map(agent => {
                            const id = `agent_${agent.id}`;
                            const isSelected = selectedAgents.has(agent.id);
                            return <div className={CalendarClasses.agent_container} key={agent.id}>
                                <input
                                    checked={isSelected}
                                    onChange={() => {
                                        if (isSelected) {
                                            const sa = Array.from(selectedAgents).filter(a => a !== agent.id);
                                            setSelectedAgents(new Set(sa));
                                            onAgentSelect(new Set(sa));
                                            setIsAllSelected(sa.length === staff.length);
                                        } else {
                                            const sa = [
                                                ...Array.from(selectedAgents),
                                                agent.id
                                            ];
                                            setSelectedAgents(new Set(sa))
                                            onAgentSelect(new Set(sa));
                                            setIsAllSelected(sa.length === staff.length);
                                        }
                                    }}
                                    id={id}
                                    type="checkbox"
                                />
                                <Avatar
                                    src={agent.photo}
                                    size={AvatarSize.tiny}
                                    fallbackType={FallbackType.ABBR}
                                    fallback={agent.name || '- -'}
                                    style={{
                                        margin: '0 10px 0 10px'
                                    }}/>
                                <label
                                    htmlFor={id}
                                    style={{
                                        color: agent.color ?
                                            agent.color :
                                            colorsMap.get(agent.id) ? colorsMap.get(agent.id)!.color : '#a2a2a2'
                                    }}
                                >
                                    {agent.name}
                                </label>
                            </div>
                        })}
                </div>
                </FormHolder>
            </Panel>
        </div>
    );
}

// export const CalendarAgents = React.memo(CalendarAgentsComponent, (prevProps, nextProps) => {
//     console.log('differenceWith -> ', differenceWith(prevProps.staff, nextProps.staff, isEqual));

//     // return !!differenceWith(prevProps.staff, nextProps.staff, isEqual).length;

//     return false;
// })