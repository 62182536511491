import { createEntityStore } from "@datorama/akita";
import { Map } from "immutable";

export enum FetchStatus {
    IDLE = 'IDLE',
    PENDING = 'PENDING',
    FETCHING = 'FETCHING',
    SUCCESS = 'SUCCEEDED',
    FAILED = 'FAILED',
    EMPTY = 'EMPTY',
}

export interface IFetchRequest<TData> {
    id: string;
    requestScope: string;
    fetchStatus: FetchStatus,
    error?: unknown,
    cache?: boolean;
    cacheMap?: Map<string, TData>;
}

export interface IDataFetcherState<TData> {
    fetchStatus: FetchStatus;
    error: unknown;
    requestMap: Map<string, IFetchRequest<TData>>;
}

const inititalState: IDataFetcherState<unknown> = {
    fetchStatus: FetchStatus.IDLE,
    error: null,
    requestMap: Map({}),
};

export const dataFetcherStore = createEntityStore(inititalState, {
    name: 'dataFetcher',
});