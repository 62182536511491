import { createEntityStore, EntityState } from "@datorama/akita";
import { ISelectableItem } from "../../properties/models/named-entity.interface";
import { ISort } from "../../properties/search/search-properties.store";
import { IOwner } from "../models/owner.interface";

export interface IOwnerCriteria {
    search: string;
    portfolio: Array<{ownerId: number; name: string; id: number;} & ISelectableItem>;
    erManaging: string;
}

export interface ISearchOwnersState extends EntityState {
    searchData: {
        portfolioList: Array<ISelectableItem & { unitId: number }>;
        portfolioTotal: number;
    };
    selectedCriteria: IOwnerCriteria;
    appliedFilters: IOwnerCriteria;
    sort: ISort<keyof IOwner>;
}

export const searchOwnersState: ISearchOwnersState = {
    searchData: {
        portfolioList: [],
        portfolioTotal: 0,
    },
    selectedCriteria: {
        search: '',
        erManaging: 'ANY',
        portfolio: [],
    },
    appliedFilters: {
        search: '',
        erManaging: 'ANY',
        portfolio: [],
    },
    sort: {
        type: 'asc',
        field: 'fullname'
    }
}

export const searchOwnersStore = createEntityStore<ISearchOwnersState>(searchOwnersState, {
    name: 'owners_search',
});