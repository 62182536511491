import React, { Fragment } from "react";
import {IProperty} from "../../../../../../store/properties/models/property.interface";
// import {Title} from "../../../../../ui/components/forms/title";
// import {HouseOutlined, VpnKey} from "@material-ui/icons";
// import CssClasses from "../../tenant-view/tenant-view.module.scss";
import {Form, FormType, InputType} from "../../../../../ui/components/forms/formBuilder/form";
import {Table} from "../../../../../ui/components/table";
import {ITenant} from "../../../../../../store/tenants/models/tenant.interface";
import {IDataTableColumn} from "react-data-table-component";
import {INumerated} from "../../../../../../store/properties/models/numerated.interface";
import {IOwner} from "../../../../../../store/owners/models/owner.interface";
import {COLORS} from "../../../../../ui/theme/variables/colors";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { HomeIcon, KeyIcon } from "src/components/ui/icons";
import { Panel } from "src/components/ui/components/panel";
// import { panelMobileStyles } from "src/components/ui/main-layout/layout";

interface IProps {
    group: IProperty;
    isMobile?: boolean;
}

const conditionalRowStyles = [
    {
        // actively renting & i % 2 === 0
        when: (row: INumerated & IOwner) => row.i % 2 === 0,
        style: {
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    }
];

export function GroupDetails(props: IProps) {
    const {group} = props;
    const columns: IDataTableColumn<ITenant>[] = [
        {
            name: 'Name',
            selector: 'fullName'
        },
        {
            name: 'Email',
            selector: 'email'
        },
        {
            name: 'Phone Number',
            selector: 'mobilePhone'
        },
        {
            name: 'Notification Method',
            cell: (row) => <div>
                {row.sms ? 'Phone, ' : ''}
                Email
            </div>
        }
    ];
    return <Fragment>
        <Panel additionalStyles={
            props.isMobile ? {
                // ...panelMobileStyles,
                margin: '-20px 0',
                borderRadius: '0 0 0 10px',
            } : {
                margin: '-6px 0',
            }
        }>
            <FormHolder
                title="Property"
                withDivider
                icon={<HomeIcon />}>
                <Form<Partial<IProperty>>
                    type={FormType.DEFAULT}
                    data={group}
                    readOnly
                    fields={{
                        unitAddress: {
                            index: 0,
                            label: 'Address',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        targetRent: {
                            index: 1,
                            label: 'Target Rent',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        bedrooms: {
                            index: 2,
                            label: 'N. Of Bedrooms',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING
                        },
                        bathrooms: {
                            index: 3,
                            label: 'N. Of Bathrooms',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING
                        },
                        keyCode: {
                            index: 4,
                            label: 'Keycode Number',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        }
                    }}
                />
            </FormHolder>
            <FormHolder
                title="Tenant List"
                icon={<KeyIcon />}>
            </FormHolder>
            <Table<ITenant>
                data={group.tenants}
                totalRecordsNumber={group.tenants?.length}
                columns={columns}
                pagination={false}
                conditionalRowStyles={conditionalRowStyles}
            />
        </Panel>
        {/* <Panel noPadding sideBorder additionalStyles={
            props.isMobile ? {
                
            } : { margin: 0 }
        }>
            <Table<ITenant>
                data={group.tenants}
                totalRecordsNumber={group.tenants.length}
                columns={columns}
                pagination={false}
                conditionalRowStyles={conditionalRowStyles}
            />
        </Panel> */}
    </Fragment>
}
