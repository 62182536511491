export enum StaffRole {
    AGENT = 'ROLE_AGENT',
    ADMINISTRATOR = 'ROLE_ADMIN',
    STAFF = 'ROLE_STAFF'
}

export interface IStaff {
    id: number;
    firstName: string;
    lastName: string;
    color: string;
    name: string;
    email: string;
    phoneNumber: string;
    alternNumber: string;
    prospectsLeading: number;
    role: StaffRole,
    photo: string;
    photoId: number | string;
    isBusy: boolean;
    isDeleted: boolean;
    hstN: number | string | null;
}