import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { CircularProgress, InputBase, withStyles } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { InfiniteList } from './infinite-list';
import { List } from 'immutable';
import { IndexRange } from 'react-virtualized';
import { SearchIcon } from '../../icons';
import { autocompleteService } from 'src/store/autocomplete/autocomplete.service';
import { IGetISODate, SearchType } from 'src/store/autocomplete/models/autocomplete.interface';
import { useAutoComplete } from 'src/store/autocomplete/hooks/use-autocomplete';
// import { useSearchData } from 'src/store/autocomplete/hooks/use-search-data';
import { useDebounce } from 'src/store/autocomplete/hooks/use-debounce';

let START_PAGE = 0;
const PROGRESS_SIZE = 20;
const DEBOUNCE = 400;

const AutocomleteInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '4px 10px',
            border: '1px solid #BEC4D3',
            borderRadius: '4px',
            maxWidth: 340,
            width: '100%',
            '& > svg': {
                margin: '0 14px 0 0',
                color: ' #BEC4D3',
            },

            '& div[role="progressbar"] svg': {
                color: 'orange',
            },

            [theme.breakpoints.down("sm")]: {
                maxWidth: '100%',
            }
        },
        focused: {
            border: '1px solid #789EFF',
            '& > svg': {
                margin: '0 14px 0 0',
                color: ' #789EFF',
            },
        },
        // underline: {
        //     '&:after': {
        //         borderBottom: 'none !important',
        //     }
        // },
        // endAdornment: {
        //     position: 'absolute',
        //     top: '15px',
        //     left: 10,
        // },
        // popupIndicator: {
        //     fontSize: '14px !important',
        //     color: '#BEC4D3',
        // },
        // popupIndicatorOpen: {
        //     transform: "rotate(0)",
        //     color: '#789EFF',
        // },
        '@global': {
            '.MuiInput-underline:before': {
                borderBottom: 'none !important'
            },
            '.MuiInput-underline:after': {
                borderBottom: 'none !important'
            }
        }
    })
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            // border: '1px solid #BEC4D3',
            // borderRadius: '4px',
            maxWidth: 340,
            width: '100%',

            [theme.breakpoints.down("sm")]: {
                maxWidth: '100%',
            }
        },
        label: {
            display: 'block',
        },
        listbox: {
            width: '100%',
            // height: '50px',
            margin: 0,
            padding: '4px 4px 4px 10px',
            zIndex: 9999,
            position: 'absolute',
            top: 50,
            listStyle: 'none',
            backgroundColor: theme.palette.background.paper,
            overflow: 'hidden',
            // maxHeight: 200,
            // border: '1px solid rgba(0,0,0,.25)',
            borderRadius: '4px',
            boxShadow: '15px 15px 25px #f8f9fc',
            '& li[data-focus="true"]': {
                backgroundColor: '#4a8df6',
                color: 'white',
                cursor: 'pointer',
            },
            '& li:active': {
                backgroundColor: '#2977f5',
                color: 'white',
            },
        },
    }),
);

interface ICustomAutocomplete {
    id: string;
    url: string;
    labelField?: "name" | "fullname";
    changeHandler?: any;
    placeholder?: string;
    customRows?: any;
    width?: number | string;
    size?: number;
    searchType: SearchType;
    dateProps?: IGetISODate;
}

export const CustomAutocomplete: React.FC<ICustomAutocomplete> = ({
    id,
    url,
    labelField,
    changeHandler,
    placeholder,
    customRows,
    width,
    size = 10,
    searchType = SearchType.TENANTS,
    dateProps,
}) => {
    const classes = useStyles();
    const [searchList, searchTotal, searchStatus, resetInput] = useAutoComplete();
    // const [searchData, searchStatu] = useSearchData();
    const [inputValue, setInputValue] = useState<string>('');

    const debouncedValue = useDebounce(inputValue, DEBOUNCE);

    const {
        getRootProps,
        // getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id,
        options: searchList,
        getOptionLabel: (option) => {
            return option[`${labelField}`]
        },
        getOptionSelected: (option, value) => option?.name === value?.name,
    });

    useEffect(() => {
        if (resetInput) {
            setInputValue('');
        }
    }, [resetInput]);

    useEffect(() => {
        START_PAGE = 0;
    }, [searchType]);

    useEffect(() => {
        START_PAGE = 0;

        autocompleteService.fetchSearchData({
            id,
            url,
            page: START_PAGE,
            size,
            search: debouncedValue,
            searchType,
            dateProps,
        })
    }, [debouncedValue]);

    const immutableOptions = useMemo(() => {
        return List(searchList);
    }, [searchList]);

    const handleChange = (e: ChangeEvent<any>) => {
        const inputProps: any = getInputProps();

        inputProps.onChange(e);

        setInputValue(e.target.value);
    }

    const handleMouseDown = (e: any) => {
        START_PAGE = 0;

        const rootProps: any = getRootProps();

        setInputValue('');

        rootProps.onMouseDown(e);

        // const found = searchList.find(item => item.name === e.target.textContent);
        const found = labelField
            ? searchList.find(item => item[labelField] === e.target.textContent)
            : searchList.find(item => item.name === e.target.textContent);

        if (typeof changeHandler === 'function') {
            if (found) {
                changeHandler(found);
            }
        }

        autocompleteService.fetchSearchData({
            id,
            url,
            page: START_PAGE,
            size,
            search: inputValue,
            searchType,
            dateProps,
        });
    }

    const handleBlur = (e: ChangeEvent<any>) => {
        const inputProps: any = getInputProps();

        inputProps.onBlur(e);

        autocompleteService.resetSearchData();
    }

    const handleFocus = async (e: ChangeEvent<any>) => {
        autocompleteService.resetSearchData();
        START_PAGE = 0;

        await autocompleteService.fetchSearchData({
            id,
            url,
            page: START_PAGE,
            size,
            search: inputValue,
            searchType,
            dateProps,
        });
        
        const inputProps: any = getInputProps();

        inputProps.onFocus(e);
    }

    const loadNextPage = async (params: IndexRange) => {
        autocompleteService.fetchSearchData({
            id,
            url,
            page: ++START_PAGE,
            size,
            search: inputValue,
            searchType,
            dateProps,
            isNextPage: true,
        });
    }

    const {onChange, onBlur, onFocus, value, ...restInputProps}: any = getInputProps();
    const {onMouseDown, ...restListBoxProps}: any = getListboxProps();

    return (
        <div style={{
            position: 'relative',
            maxWidth: '340px',
            width: width ? width : '100%',
        }}>
            <div className={classes.root} {...getRootProps()}>
                <AutocomleteInput {...restInputProps} 
                    placeholder={placeholder}
                    onChange={handleChange} 
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={inputValue}
                    startAdornment={<SearchIcon />}
                    endAdornment={
                        <React.Fragment>
                            {searchStatus[id]?.loading ?
                                <CircularProgress color="inherit" size={PROGRESS_SIZE}/> : null}
                        </React.Fragment>
                    } />
            </div>
                {groupedOptions.length > 0 ? (
                    <ul className={classes.listbox} {...restListBoxProps} onMouseDown={handleMouseDown}>
                        <InfiniteList 
                            customRows={customRows}
                            hasNextPage={searchList.length < searchTotal} 
                            isNextPageLoading={searchStatus[id]?.loading} 
                            list={immutableOptions}
                            getOptionProps={getOptionProps}
                            labelField={labelField}
                            loadNextPage={loadNextPage} />
                        {/* {groupedOptions.map((option, index) => (
                            <li {...getOptionProps({ option, index })}>{option.name}</li>
                            
                        ))} */}
                    </ul>
                    ) : null}
        </div>
    );
}