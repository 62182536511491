import { useObservable } from "@libreact/use-observable";
// import { get } from "lodash";
import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { ROWS_PER_PAGE, START_PAGE } from "src/store/common/hooks/use-paging";
// import { ILocationState } from "src/store/common/models/common.interface";
import { IProspect } from "../models/prospect.interface";
import { searchProspectsService } from "../prospects-search/prospects-search.service";
import { prospectsQuery } from "../prospects.query";
import { prospectsService } from "../prospects.service";

export type ProspectsHookTuple = [IProspect[], boolean];

export function useProspects (): ProspectsHookTuple {
    // const location = useLocation<ILocationState>();
    const [prospects] = useObservable(prospectsQuery.prospects$);
    const [fp] = useObservable(prospectsQuery.selectFilteredProspects$);
    const [loading] = useObservable(prospectsQuery.isLoading$);

    useEffect(() => {
        if (!prospects.length) {
            prospectsService.fetchProspects(0, 9999, 'GENERAL');
        } else {
            // prospectsService.fetchProspects(
            //     get(location, `state[${PagingType.PROSPECTS}].activePage`, START_PAGE),
            //     get(location, `state[${PagingType.PROSPECTS}].rowsPerPage`, ROWS_PER_PAGE),
            // );
        }

        searchProspectsService.fetchNotificationMethods();
    }, [prospects.length]);

    return [fp, loading];
}