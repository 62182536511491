import { uniqBy } from "lodash";
import { IMessageFormState } from "src/components/containers/send-message/send-message";
import { ITemplate } from "../admin-settings/models/template.interface";
import { IOwner } from "../owners/models/owner.interface";
import { IInitializable } from "../portfolio/models/on-init.interface";
import { IProspect } from "../prospects/models/prospect.interface";
import { snackbarService } from "../snackbar/snackbar.service";
import { SnackbarType } from "../snackbar/snackbar.store";
import { IStaff } from "../staff/models/staff.inreface";
import { IGroup } from "../tenants/groups/models/group.interface";
import { ITenant } from "../tenants/models/tenant.interface";
import { IPropertyGroup } from "../tenants/property-groups/models/property-group.interface";
import { SYSTEM_MESSAGES, SNACK_TIMEOUT } from "./message.config";
import { messageStore, MessageStore } from "./message.store";
import { IMessageDto } from "./models/message.interface";
import { extractContent } from 'src/store/utils/extract-content';
import { api } from "src/api/api.service";
import { guid } from "@datorama/akita";

export enum MessageType {
    EMAIL = 'email',
    SMS = 'sms',
}

export enum RecieverType {
    STAFF = 'STAFF',
    TENANTS = 'TENANTS',
    LEASE_TENANTS = 'LEASE_TENANTS',
    PROSPECTS = 'PROSPECTS',
    OWNERS = 'OWNERS',
    CUSTOM_GROUPS = 'CUSTOM_GROUPS',
    PROPERTY_GROUPS = 'PROPERTY_GROUPS',
}
export class MessageService implements IInitializable {
    isInitialized = false;

    constructor(
        private messageStore: MessageStore,
        private snackbarService: any,
    ) {}

    async selectRecievers<T>(type: RecieverType, data: T[], isRemove?: boolean) {
        switch (type) {
            case RecieverType.STAFF:
                this.messageStore.update(state => ({
                    ...state,
                    selectedStaff: !isRemove 
                        ? uniqBy([
                            ...state.selectedStaff,
                            ...data,
                        ], 'id') 
                        : data,
                }));
                break;
            case RecieverType.PROSPECTS:
                this.messageStore.update(state => ({
                    ...state,
                    selectedProspects: !isRemove 
                        ? uniqBy([
                            ...state.selectedProspects,
                            ...data,
                        ], 'id') 
                        : data,
                }));
                break;
            case RecieverType.TENANTS:
                this.messageStore.update(state => ({
                    ...state,
                    selectedTenants: !isRemove 
                        ? uniqBy([
                            ...state.selectedTenants,
                            ...data,
                        ], 'id') 
                        : data,
                }));
                break;
            case RecieverType.LEASE_TENANTS:
                this.messageStore.update(state => ({
                    ...state,
                    selectedTenants: !isRemove 
                        ? uniqBy([
                            ...state.selectedTenants,
                            ...data.map(item => ({
                                ...item,
                                id: guid(),
                            })),
                        ], 'applicantId') 
                        : data,
                }));
                break;
            case RecieverType.OWNERS:
                this.messageStore.update(state => ({
                    ...state,
                    selectedOwners: !isRemove 
                        ? uniqBy([
                            ...state.selectedOwners,
                            ...data,
                        ], 'id')
                        : data,
                }));
                break;
            case RecieverType.CUSTOM_GROUPS:
                this.messageStore.update(state => ({
                    ...state,
                    selectedCustomTenantGroups: !isRemove 
                        ? uniqBy([
                            ...state.selectedCustomTenantGroups,
                            ...data,
                        ], 'id')
                        : data,
                }));
                break;
            case RecieverType.PROPERTY_GROUPS:
                this.messageStore.update(state => ({
                    ...state,
                    selectedPropertyGroups: !isRemove 
                        ? uniqBy([
                            ...state.selectedPropertyGroups,
                            ...data,
                        ], 'id')
                        : data,
                }));
                break;
            default:
                console.warn('Type not found...');
        }
    }

    async fetchSelectedTemplate(templateId: number) {
        if (!templateId) {
            this.messageStore.update(state => ({
                ...state,
                selectedTemplate: {},
            }));

            return null;
        }
        
        const response = await api.get<ITemplate>(`/templates/${templateId}`);

        if (response.ok) {
            this.messageStore.update(state => ({
                ...state,
                selectedTemplate: response.data,
            }));
        }
    }

    async sendMessage(messageDto: IMessageDto, type: string) {
        this.messageStore.setLoading(true);

        if (type === 'EMAIL' && messageDto.subject === '') {
            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.EMPTY_SUBJECT,
                type: SnackbarType.ERROR,
            }, SNACK_TIMEOUT);

            return;
        }

        const response = await api.post<IMessageDto>(`/message?type=${type}`, {
            ...messageDto,
            body: (type === 'SMS' && !messageDto.templateId) ? extractContent(messageDto.body) : messageDto.body,
        });

        if (response.ok) {
            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.SENT,
                type: SnackbarType.SUCCESS,
            }, SNACK_TIMEOUT);
        }

        this.messageStore.setLoading(false);
    }

    createMessageDto(
        formState: IMessageFormState, 
        selectedStaff: IStaff[],
        selectedOwners: IOwner[], 
        selectedProspects: IProspect[],
        selectedTenants: ITenant[],
        selectedCustomTenantGroups: IGroup[],
        selectedPropertyGroups: IPropertyGroup[],
    ) {
        const messageDto: IMessageDto = {
            body: formState.body,
            emailFrom: formState.emailFrom,
            date: new Date(),
            staffMessages: selectedStaff.map(staff => ({
                email: formState.type === MessageType.EMAIL ? staff.email : null,
                phoneNumber: formState.type === MessageType.SMS ? staff.phoneNumber : null,
                staffId: staff.id
            })),
            ownerMessages: selectedOwners.map(owner => ({
                email: formState.type === MessageType.EMAIL ? owner.email : null,
                phoneNumber: formState.type === MessageType.SMS ? owner.mobilePhone : null,
                ownerId: owner.id
            })),
            prospectMessages: selectedProspects.map(prospect => ({
                email: formState.type === MessageType.EMAIL ? prospect.email : null,
                prospectId: prospect.id,
                phoneNumber: formState.type === MessageType.SMS ? prospect.phoneNumber : null,
            })),
            subject: formState.subject,
            templateId: !formState.templateId ? null : formState.templateId,
            tenantGroupMessages: selectedCustomTenantGroups.flatMap(ctg => {
                return [
                    ...ctg.tenants.map(tenant => ({
                        email: formState.type === MessageType.EMAIL ? tenant.email : null,
                        phoneNumber: formState.type === MessageType.SMS ? tenant.mobilePhone : null,
                        groupId: ctg.id as number,
                        tenantId: tenant.id,
                    })),
                    ...ctg.properties.flatMap(proeprty => proeprty.tenants.flatMap(tenant => ({
                        email: formState.type === MessageType.EMAIL ? tenant.email : null,
                        phoneNumber: formState.type === MessageType.SMS ? tenant.mobilePhone : null,
                        groupId: ctg.id as number,
                        tenantId: tenant.id,
                    })))
                ];
            }),
            propertyMessages: selectedPropertyGroups.flatMap((pg, index) => {
                return pg.emails.map((email, idx) => ({
                    email: formState.type === MessageType.EMAIL ? email : null,
                    phoneNumber: formState.type === MessageType.SMS ? (selectedPropertyGroups[index].phoneNumbers[idx] as string) : null,
                    propertyId: pg.id,
                    tenantId: pg.tenantIds[idx],
                }))
            }),
            tenantMessages: selectedTenants.map(tenant => ({
                email: formState.type === MessageType.EMAIL ? tenant.email : null,
                phoneNumber: formState.type === MessageType.SMS ? tenant.mobilePhone : null,
                tenantId: tenant.id,
            })),
            withBcc: formState.withBcc.some(item => !!item),
        }

        return messageDto;
    }

    resetMessageState() {
        this.messageStore.update(state => ({
            ...state,
            selectedCustomTenantGroups: [],
            selectedProspects: [],
            selectedTenants: [],
            selectedOwners: [],
            selectedPropertyGroups: [],
            selectedStaff: [],
        }));
    }

    init(): void {
        this.isInitialized = true;
    }

    async fetchMessageHistory(id: number, context: string, field: string) {
        this.messageStore.setLoading(true);

        const response = await api.get<any>(`/${context}/message-history/${id}`);

        if (response.ok) {
            this.messageStore.update(state => ({
                ...state,
                messageHistory: {
                    ...state.messageHistory,
                    [field]: response.data,
                },
            }));
        }

        this.messageStore.setLoading(false);
    }

    updateMessageType(type: MessageType) {
        this.messageStore.update(state => ({
            ...state,
            messageType: type,
        }));
    }
}

export const messageService = new MessageService(messageStore, snackbarService);