import {IUiComponent} from "../../types/ui-component.interface";
import React from "react";
import {Chip} from "./chip";
import Classes from './filters-list.module.scss';
import classNames from "classnames";
import {INamedEntity} from "../../../../store/properties/models/named-entity.interface";

export enum ChipViewTypes {
    INLINE,
    BLOCK
}

interface IProps extends IUiComponent {
    items: INamedEntity[];
    filtersTitleName?: string;
    type?: ChipViewTypes;
    onDelete?(id: INamedEntity): void;
}

export const FiltersList: React.FC<IProps> = (props) => {
    const {items, filtersTitleName = 'Filters', onDelete, type = ChipViewTypes.INLINE, ...restProps} = props;

    const wrapperClass = classNames({
        [Classes.filter_list]: type === ChipViewTypes.INLINE,
        [Classes.filter_list_block]: type === ChipViewTypes.BLOCK,
    })

    const titleClass = classNames({
        [Classes.filter_list_title]: type === ChipViewTypes.INLINE,
        [Classes.filter_list_title_block]: type === ChipViewTypes.BLOCK,
    });

    const blockClass = classNames({
        [Classes.filter_list_chips]: type === ChipViewTypes.INLINE,
        [Classes.filter_list_chips_block]: type === ChipViewTypes.BLOCK
    })

    return <div {...restProps} className={wrapperClass}>
        <div className={titleClass}>
            {filtersTitleName}
        </div>
        <div className={blockClass}>
            {items.map(item =>
                <Chip
                    key={`${item.name}_${item.id}`}
                    item={item}
                    onDelete={() => {
                        onDelete && onDelete(item);
                    }}
                />
            )}
        </div>
    </div>
}
