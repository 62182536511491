import {INumerated} from "../../../store/properties/models/numerated.interface";

export function numerate<T>(arr: Array<T>): Array<T & INumerated> {
    if (!arr || !Array.isArray(arr)) {
        console.warn(`Passed ${arr} to numerate method, expected array`);
        return [];
    }
    return arr.map((item, i) => ({
        ...item,
        i
    }))
}
