import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {IAppointmentEvent} from "./calendar.service";
import {IStaff} from "../staff/models/staff.inreface";
import { IAppointment } from "../appointment/models/appointment.interface";

export interface ICalendarState extends EntityState {
    events: IAppointmentEvent[];
    staffToColorMap: Map<number, {background: string, color: string}>;
    staffList: Array<IStaff>;
    appointments: IAppointment[];
}

const initialState: ICalendarState = {
    staffToColorMap: new Map(),
    events: [],
    staffList: [],
    appointments: [],
};

@StoreConfig({ name: 'calendar' })
export class CalendarStore extends EntityStore<ICalendarState> {
    constructor() {
        super(initialState);
    }
}

export const calendarStore = new CalendarStore();
