import React, {ReactNode, useMemo} from "react";
import {SIZES} from "../../theme/variables/sizes";
import Classes from './sidenav.module.scss';
import {Link, NavLink, useLocation} from "react-router-dom";
import {RoutesEnum} from "../../../routing/routes";
import classNames from "classnames";
import {uiService} from "../../../../store/ui-storage/ui.service";
import {useObservable} from "@libreact/use-observable/dist";
import {uiQuery} from "../../../../store/ui-storage/ui.query";
import {DeviceType} from "../../../../store/ui-storage/ui.store";
import { CalendarIcon, HomeIcon, PersonIcon, CrmIcon, SettingsOutlinedIcon, Logo } from "../../icons";
import { IUser } from "src/store/user/models/user.interface";
import { StaffRole } from "src/store/staff/models/staff.inreface";
import { authQuery } from "src/store/auth/auth.query";

interface IMenuLink {
    routeGroupName: string;
    icon?: ReactNode;
    exact?: boolean; // false by default
    routeGroupLink: string;
    children: Array<{ name: string, link: string, exact?: boolean; }>
}

const DASHBOARD = 'Dashboard';
const REQUESTS = 'Requests';
const LEASE_CONTRACTS = 'Lease Contracts';
const DOC_LIB = 'Document Library';
const REPORTS = 'Reports';
const IMPORT_STATUS = "Import Status";

export const routeGroupNameToUrlMap: {url: string, name: string}[] = [
    {url: '/dashboard/contracts', name: DASHBOARD},
    {url: '/dashboard/requests', name: REQUESTS},
    {url: '/dashboard/contracts', name: LEASE_CONTRACTS},
    {url: '/dashboard/import-status', name: IMPORT_STATUS},
    {url: '/dashboard/doc-lib', name: DOC_LIB},
    {url: '/dashboard/reports', name: REPORTS},

    {url: '/calendar/calendar-view', name: 'Calendar'},
    {url: '/calendar/appointments', name: 'Appointments'},
    {url: '/properties', name: 'Properties'},
    {url: '/profiles/staff', name: 'Staff'},
    {url: '/profiles/tenants', name: 'Tenants'},
    {url: '/profiles/prospects', name: 'Prospects'},
    {url: '/profiles/owners', name: 'Owners'},
    {url: '/admin', name: 'Admin'}
];

const getDashboardRoutesByRole = (user: IUser) => 
    user.role === StaffRole.ADMINISTRATOR ||
    user.role === StaffRole.STAFF
        ? [
            {
                name: REQUESTS,
                link:  RoutesEnum.LEASE_REQUESTS,
            },
            {
                name: LEASE_CONTRACTS,
                link: RoutesEnum.LEASE_CONTRACTS,
            },
            {
                name: IMPORT_STATUS,
                link: RoutesEnum.IMPORT_STATUS,
            },
            {
                name: DOC_LIB,
                link: RoutesEnum.DOC_LIBRARY,
            },
            {
                name: REPORTS,
                link:  RoutesEnum.REPORTS,
            },
        ] : [
            {
                name: LEASE_CONTRACTS,
                link: RoutesEnum.LEASE_CONTRACTS,
            },
            {
                name: IMPORT_STATUS,
                link: RoutesEnum.IMPORT_STATUS,
            },
            {
                name: DOC_LIB,
                link: RoutesEnum.DOC_LIBRARY,
            },
        ]

const menuLinks = (user: IUser): IMenuLink[] => [
    // {
    //     routeGroupName: DASHBOARD,
    //     routeGroupLink: '/dashboard/contracts',
    //     exact: true,
    //     icon: <CrmIcon />,
    //     children: []
    //     // children: [
    //     //     ...getDashboardRoutesByRole(user),
    //     // ]
    // },
    {
        routeGroupName: 'Calendar',
        routeGroupLink: '/calendar/calendar-view',
        icon: <CalendarIcon />,
        children: [
            {
                name: 'Calendar',
                link: RoutesEnum.CALENDAR_VIEW,
            },
            {
                name: 'Appointments',
                link: RoutesEnum.APPOINTMENTS_LIST,
            }
        ]
    },
    {
        routeGroupName: 'Properties',
        routeGroupLink: '/properties',
        icon: <HomeIcon />,
        children: []
    },
    {
        routeGroupName: 'Profiles',
        routeGroupLink: '/profiles/staff',
        icon: <PersonIcon />,
        children: [
            {
                name: 'Staff',
                link: '/profiles/staff'
            },
            {
                name: 'Tenants',
                link: '/profiles/tenants'
            },
            {
                name: 'Prospects',
                link: '/profiles/prospects'
            },
            {
                name: 'Owners',
                link: '/profiles/owners'
            }
        ]
    },
    {
        routeGroupName: 'Admin',
        routeGroupLink: '/admin/settings',
        icon: <SettingsOutlinedIcon />,
        children: [
            {
                name: 'Settings',
                link: '/admin/settings'
            },
        ]
    },
];

interface IProps {
    isSidenavOpen: boolean;
}

export function Sidenav(props: IProps) {
    const [user] = useObservable(authQuery.selectUser$);
    const location = useLocation();
    const [deviceType] = useObservable(uiQuery.deviceType$);

    const isMobile = useMemo(() => deviceType === DeviceType.MOBILE, [deviceType]);

    const containerStyle: React.CSSProperties = {
        position: deviceType === DeviceType.MOBILE ? 'fixed' : 'relative',
        width: deviceType === DeviceType.MOBILE ? '100%' : `${SIZES.SIDENAV_WIDTH}px`,
        top: deviceType === DeviceType.MOBILE ? SIZES.HEADER_HEIGHT : 'auto',
        left: props.isSidenavOpen ? 0 : '-100%',
        height: deviceType === DeviceType.MOBILE ? '100%' : 'auto',
    }

    return <div
        style={containerStyle}
        className={Classes.sidenav}
    >
        {deviceType !== DeviceType.MOBILE && <div className={Classes.sidenav_logo}>
            <Link to="/">
                <Logo />
            </Link>
        </div>}
        <div className={Classes.sidenav_routes}>
            {menuLinks(user as IUser).map(routeGroup => {
                const {routeGroupLink, routeGroupName, icon, exact = false} = routeGroup;
                const {pathname} = location;
                const IS_HOME_URL = pathname === '' || pathname === '/';
                let isActive = (IS_HOME_URL && routeGroupLink === '/')
                    || pathname.includes(routeGroupName.toLowerCase());

                const iconClass = classNames({
                    [Classes.sidenav_icon_side]: true,
                    [Classes.sidenav_icon_side_active]: isActive,
                });

                const groupClass = classNames({
                    [Classes.sidenav_link]: true,
                    [Classes.sidenav_active_link_main]: isActive,
                });

                return (
                    <div className={Classes.sidenav_route_group} key={routeGroupLink}>
                        <div className={iconClass}>
                            <div className={Classes.sidenav_icon}>
                                {icon}
                            </div>
                        </div>
                        <div className={Classes.sidenav_links_side}>
                            <div>
                                <NavLink
                                    exact={exact}
                                    // activeClassName={Classes.sidenav_active_link_main}
                                    to={routeGroupLink}
                                    className={groupClass}
                                    onClick={() => isMobile && uiService.toggleSidenav()}
                                >
                                    {routeGroupName}
                                </NavLink>
                            </div>
                            <div>
                                {routeGroup.children.length ? routeGroup.children.map(subRoute => {
                                    const {exact = false, name, link} = subRoute;
                                    return <div key={name} className={`${Classes.sidenav_sublink} ${Classes.sidenav_sublink_children}`}>
                                        <NavLink
                                            className={Classes.sidenav_link}
                                            exact={exact}
                                            activeClassName={Classes.sidenav_active_link}
                                            to={link}
                                            onClick={() => isMobile && uiService.toggleSidenav()}
                                        >
                                            {subRoute.name}
                                        </NavLink>
                                    </div>
                                }) : null}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
}