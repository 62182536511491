import {AppointmentType} from "./appointment.interface";
import {INamedEntityWithType} from "../../properties/models/named-entity.interface";
import {IDateRange} from "../../../components/ui/components/forms/formBuilder/inputTypeSelector";

export enum GuestsDropdownValue {
    Agents = 'agents',
    Tenants = 'tenants',
    Owners = 'owners',
    Prospects = 'prospects',
    Staff = 'staffs',
    Properties = 'properties'
}

export interface ICreateAppointment {
    id?: number;
    title: string;
    dateRange: IDateRange;
    mode: 'edit' | 'create';
    type: AppointmentType | null;
    notes: string;
    properties: INamedEntityWithType<GuestsDropdownValue>[];
    guestsDropdownValue: GuestsDropdownValue | null;
    guests: Partial<Record<GuestsDropdownValue, INamedEntityWithType<GuestsDropdownValue>[]>>;
    sendEmail: boolean;
}
