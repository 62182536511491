import { Map } from "immutable";

export interface ILocationState {
    [id: string]: {
        activePage?: number;
        rowsPerPage?: number;
        from?: string;
        backLink?: string;
        selectType?: string;
    }
}

export enum PagingType {
    OWNERS = 'owners',
    STAFF = 'staff',
    TENANTS = 'tenants',
    PROSPECTS = 'prospects',
    GROUPS = 'groups',
    PROPERTY_GROUPS = 'property-groups',
    PROPERTIES = 'properties',
    APPOINTMENTS = 'appointments',
    CONTRACTS = 'contracts',
    REQUESTS = 'requests',
}

export interface ISelectedData<T> {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: T[];
}

export interface IPaginateComponent {
    changePage: (page: number) => void;
    changeRowsNumber: (rowPerPage: number, page: number) => void;
    changeSearch: (searchQ: string) => void;
    applyFilters?: () => void;
}

//** Immutable */
export type MapOf<T> = Map<keyof T, T[keyof T]>;
export const createImmutableMap = <T,>(obj?: T): MapOf<T> => Map<keyof T, T[keyof T]>(obj as T);

export const immutableSetIn = <T extends {}>(state: MapOf<T>, keyPath: (keyof T)[], value: any): Map<keyof T, T[keyof T]> => {
    return state.setIn(keyPath as any, value);
}

export const immutableGetIn = <T extends {}>(state: MapOf<T>, keyPath: (keyof T)[], value: any): any => {
    return state.setIn(keyPath as any, value);
}