import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { ISort } from "src/store/properties/search/search-properties.store";
// import { ISort } from "../../properties/search/search-properties.store";

export interface IAdminSearchCriteria {
    search: string;
    sort?: any;
}

export interface IAdminSearchState extends EntityState {
    selectedCriteria: IAdminSearchCriteria;
    sort: ISort<keyof any>;
}

export const adminSearchInitialState: IAdminSearchState = {
    selectedCriteria: {
        search: '',
    },
    sort: {
        field: 'email',
        type: 'desc'
    }
}

@StoreConfig({name: 'admin_search'})
export class AdminSearchStore extends EntityStore<IAdminSearchState> {
    constructor() {
        super(adminSearchInitialState);
    }
}

export const adminSearchStore = new AdminSearchStore();
