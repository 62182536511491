import React from 'react';
import Classes from './fav-button.module.scss';
import {IUiComponent} from "../../types/ui-component.interface";

interface IProps extends IUiComponent {
    icon: React.ReactNode;
    badge?: string;
}

export function FavButton(props: IProps) {
    const {
        icon,
        children,
        badge,
        ...rest
    } = props;
    return <div {...rest} className={Classes.fav_button}>
        {props.icon}
        {badge && <div className={Classes.fav_button_badge}>
            {badge}
        </div>}
    </div>
}
