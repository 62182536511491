import {Button, ButtonVariant} from "../../../../ui/components/buttons/button";
import {Form, FormType, InputType} from "../../../../ui/components/forms/formBuilder/form";
import {
    GuestsDropdownValue,
    ICreateAppointment
} from "../../../../../store/appointment/models/appointment-create.interface";
import {AppointmentType} from "../../../../../store/appointment/models/appointment.interface";
import {useHistory, useLocation} from "react-router-dom";
import React, {ChangeEvent, Fragment, useCallback, useMemo} from "react";
import {Sidenav} from "../../../../ui/components/sidenav";
import {appointmentService} from "../../../../../store/appointment/appointment.service";
import {useObservable} from "@libreact/use-observable/dist";
// import {appointmentQuery} from "../../../../../store/appointment/appointment.query";
import {DeviceType} from "../../../../../store/ui-storage/ui.store";
import {uiQuery} from "../../../../../store/ui-storage/ui.query";
import { ArchiveIcon, AttentionIcon, CalendarIcon, HomeIcon, NoteIcon, OwnerIcon, PersonIcon, ProspectIcon, TenantIcon } from "src/components/ui/icons";
import moment from 'moment';
// import { SearchInput } from "src/components/ui/components/autocomplete/autocomplete";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { RenderSelected } from "src/components/containers/send-message/render-selected";
import { get, isEmpty, uniqBy } from "lodash";

import styles from "../appointments.module.scss";
import { CustomAutocomplete } from "src/components/ui/components/autocomplete/custom-autocomplete";
import { IGetISODate, SearchType } from "src/store/autocomplete/models/autocomplete.interface";
import { searchUrlMap } from "src/store/autocomplete/autocomplete.store";

// const TIME_REGEXP = /\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))/;
const INIT_FROM = 13;
const INIT_TO = 14;

interface IProps {
    isEditing: boolean;
    isCreateModalOpened: boolean;
    onCloseCreateModal: () => void;
    newAppointment: ICreateAppointment;

    setIsCreateModalOpened(opened: boolean): void;
}

export const CreateAppointment: React.FC<IProps> = ({
    isCreateModalOpened,
    isEditing,
    onCloseCreateModal,
    newAppointment,
    setIsCreateModalOpened,
}) => {
    const history = useHistory();
    const location = useLocation();
    // const [isGuestDropdownOpened, setIsGuestDropdownOpened] = useState(false);
    // const [isPropertiesDropdownOpened, setIsPropertiesDropdownOpened] = useState(false);
    // const [searchData] = useObservable(appointmentQuery.searchData$);
    // const [searchList] = useObservable(appointmentQuery.searchList$);
    // const [searchTotal] = useObservable(appointmentQuery.searchTotal$);
    // const [searchLoading] = useObservable(appointmentQuery.searchLoading$);

    // const [propertyOptions] = useObservable(appointmentQuery.properties$);
    // const [propertiesTotal] = useObservable(appointmentQuery.propertiesTotal$);
    // const [propertiesLoading] = useObservable(appointmentQuery.propertiesLoading$);

    // const [loadingSearchItems] = useObservable(appointmentQuery.loadingSearchItems$);

    const [deviceType] = useObservable(uiQuery.deviceType$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    // const [searchValue, setSearchValue] = useState<string>('');
    // const [searchPropertyValue, setSearchPropertyValue] = useState<string>('');

    // const options: INamedEntity[] = newAppointment.guestsDropdownValue ? searchData[newAppointment.guestsDropdownValue] : [];

    const sidenavWidth = useMemo(() => {
        const DESKTOP_SIDENAV_WIDTH = 685;
        if (deviceType === DeviceType.MOBILE) {
            return '100%';
        } else {
            return DESKTOP_SIDENAV_WIDTH;
        }
    }, [deviceType]);

    const formState = useMemo(() => {
        return {
            ...newAppointment,
            selected: 0,
            selectedProperties: 0,
        };
    }, [newAppointment]);

    // const isAppointmentInvalid = useMemo(() => {
    //     return !isTimeValid(newAppointment.dateRange.timeFrom) || !isTimeValid(newAppointment.dateRange.timeTo);
    // }, [newAppointment]);

    const selectedGuests = useMemo(() => {
        const allGuests = uniqBy([
            ...newAppointment?.guests?.agents as [],
            ...newAppointment?.guests?.tenants as [],
            ...newAppointment?.guests?.prospects as [],
            ...newAppointment?.guests?.owners as [],
            ...newAppointment?.guests?.staffs as [],
        ], 'email');

        return allGuests.length;
    }, [newAppointment]);

    const selectedProperties = useMemo(() => {
        const allProperties = Array.isArray(newAppointment?.properties) ? uniqBy([
            ...newAppointment?.properties as [],
        ], 'id') : [];

        return allProperties.length;
    }, [newAppointment]);


    const onChangeCreateForm = useCallback((data: Partial<ICreateAppointment>) => {
        appointmentService.changeCreateAppointmentData(data);
    }, []);

    const handleBlur = (field: string) => (e: ChangeEvent<any>) => {
        if (!e.target.value) {
            appointmentService.changeCreateAppointmentData({
                dateRange: { [field]: field === 'timeFrom' 
                    ?  moment(INIT_FROM, 'hh:mm A').format('hh:mm A')
                    : moment(INIT_TO, 'hh:mm A').format('hh:mm A')
                }
            });
        } else {
            const value = moment(e.target.value, 'hh:mm A').format('hh:mm A');

            appointmentService.changeCreateAppointmentData({
                dateRange: { [field]: value }
            });
        }
    }

    const onSelect = (item: any) => {
        if (newAppointment.guestsDropdownValue === 'prospects') {
            appointmentService.changeCreateAppointmentData({
                ...newAppointment,
                guests: {
                    ...newAppointment.guests,
                    [newAppointment.guestsDropdownValue!]: [
                        ...newAppointment.guests[newAppointment.guestsDropdownValue!]!,
                        item,
                    ],
                    agents: !isEmpty(item.agentAttached)
                        ? [
                            ...get(newAppointment, 'guests.agents', []),
                            {
                                ...item.agentAttached,
                                isAttached: true,
                            },
                        ]
                        : [
                            ...get(newAppointment, 'guests.agents', []),
                        ],
                }
            });
        } else {
            appointmentService.changeCreateAppointmentData({
                ...newAppointment,
                guests: {
                    ...newAppointment.guests,
                    [newAppointment.guestsDropdownValue!]: [
                        ...newAppointment.guests[newAppointment.guestsDropdownValue!]!,
                        item,
                    ]
                }
            });
        }
    }

    const onSelectProperty = (item: any) => {
        appointmentService.changeCreateAppointmentData({
            ...newAppointment,
            properties: [
                ...newAppointment.properties,
                item
            ]
        });
    }

    const onSaveAppointment = async () => {
        await appointmentService.createOrUpdateAppointment(newAppointment);
        setIsCreateModalOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const onRemoveGuest = (id: number, type: GuestsDropdownValue) => {
        if (type === GuestsDropdownValue.Prospects) {
            appointmentService.changeCreateAppointmentData({
                ...newAppointment,
                guests: {
                    ...newAppointment.guests,
                    [type]: newAppointment.guests[type]!.filter(item => item.id !== id),
                    agents: get(newAppointment, 'guests.agents', []).filter((agent: any, idx: number) => {
                        const propsectsWithSameAgent = newAppointment.guests.prospects?.filter((propsect: any) => propsect.agentAttachedId === agent.id);

                        if (!newAppointment.guests.prospects?.some((prospect: any) => 
                            prospect.id === id && 
                            prospect.agentAttachedId === agent.id &&
                            propsectsWithSameAgent?.length === 1
                        )) {
                            return agent;
                        }

                        return null;
                    }),
                }
            })
        } else {
            appointmentService.changeCreateAppointmentData({
                ...newAppointment,
                guests: {
                    ...newAppointment.guests,
                    [type]: newAppointment.guests[type]!.filter(item => item.id !== id)
                }
            })
        }
    }

    const onRemoveProperty = (id: number) => {
        appointmentService.changeCreateAppointmentData({
            ...newAppointment,
            properties: newAppointment.properties.filter(p => p.id !== id)
        })
    }

    const deleteAppointment = async () => {
        await appointmentService.deleteAppointment();
        setIsCreateModalOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const wrapperStyles: React.CSSProperties =
        deviceType === DeviceType.DESKTOP ? {
            minWidth: '400px',
            width: '90%',
            // padding: '0 25px'
        } : {
            minWidth: '280px',
            width: '100%',
        };

    return <Sidenav<any>
        header={<Fragment>
            <div className={styles.iconHolder}>
                <CalendarIcon />
            </div>
            <span>
                { isEditing ? 'Edit Appointment' : 'Schedule Appointment' }
            </span>
        </Fragment>}
        footer={<Fragment>
            {isEditing && <Button
                btnVariant={ButtonVariant.OUTLINE}
                icon={<ArchiveIcon />}
                text="Delete Appointment"
                onClick={deleteAppointment}
            />}
            <Button
                style={{marginLeft: 12}}
                onClick={onSaveAppointment}
                btnVariant={ButtonVariant.PRIMARY}
                text={isEditing ? 'Update Appointment' : 'Save'}
            />
        </Fragment>}
        width={sidenavWidth}
        isOpen={isCreateModalOpened}
        onClose={onCloseCreateModal}
    >
        <FormHolder
            icon={<CalendarIcon />}
            title="Appointment Details"
            overflow="none"
            withDivider
        >
            <Form<Partial<ICreateAppointment>>
                data={newAppointment}
                wrapperStyles={wrapperStyles}
                type={FormType.LARGE}
                onChange={onChangeCreateForm}
                onBlur={handleBlur}
                fields={{
                    title: {
                        index: 0,
                        label: 'Title',
                        labelDisplay: 'block',
                        placeholder: 'Title',
                        labelStyles: {
                            padding: isMobile ? '0' : '0 0 0 12px',
                        },
                        fieldType: InputType.STRING
                    },
                    dateRange: {
                        index: 0,
                        label: 'Date & Time',
                        labelDisplay: 'block',
                        labelStyles: {
                            padding: isMobile ? '0' : '0 0 0 12px',
                        },
                        mobileDirection: "row",
                        fieldType: InputType.DATE_RANGE,
                        shortRange: true,
                    },
                    type: {
                        index: 3,
                        label: 'Type',
                        labelDisplay: 'block',
                        labelStyles: {
                            padding: isMobile ? '0' : '0 0 0 12px',
                        },
                        fieldType: InputType.DROPDOWN,
                        selectableItems: [
                            {value: AppointmentType.Showing, label: 'Showing'},
                            {value: AppointmentType.Meeting, label: 'Meeting'},
                            {value: AppointmentType.Other, label: 'Other'},
                        ],
                    }
                }}
            />
        </FormHolder>
        <FormHolder
            dirrection="row"
            withDivider
            overflow="none"
            icon={<PersonIcon />}
            title="Guests">
                <div style={{ position: 'relative', display: 'flex', alignItems: 'flex-start' }}>
                    <Form<Partial<any>>
                        data={formState}
                        wrapperStyles={wrapperStyles}
                        type={FormType.LARGE}
                        onChange={onChangeCreateForm}
                        fields={{
                            guestsDropdownValue: {
                                index: 0,
                                label: '',
                                fieldType: InputType.DROPDOWN,
                                selectableItems: [
                                    {value: SearchType.AGENTS, label: <div className={styles.optionHolder}>
                                        <div className={styles.optionIcon}>
                                            <PersonIcon />
                                        </div>
                                        <span>Agents</span>
                                    </div>},
                                    {value: SearchType.STAFFS, label: <div className={styles.optionHolder}>
                                        <div className={styles.optionIcon}>
                                            <PersonIcon />
                                        </div>
                                        <span>Staff</span>
                                    </div>},
                                    {value: SearchType.TENANTS, label: <div className={styles.optionHolder}>
                                        <div className={styles.optionIcon}>
                                            <TenantIcon />
                                        </div>
                                        <span>Tenants</span>
                                    </div>},
                                    {value: SearchType.OWNERS, label: <div className={styles.optionHolder}>
                                        <div className={styles.optionIcon}>
                                            <OwnerIcon />
                                        </div>
                                        <span>Owner</span>
                                    </div>},
                                    {value: SearchType.PROSPECTS, label: <div className={styles.optionHolder}>
                                        <div className={styles.optionIcon}>
                                            <ProspectIcon />
                                        </div>
                                        <span>Prospect</span>
                                    </div>},
                                ]
                            },
                            guests: {
                                index: 1,
                                label: '',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <CustomAutocomplete
                                    id="guests-list"
                                    url={searchUrlMap.get(formState.guestsDropdownValue)}
                                    searchType={formState.guestsDropdownValue as unknown as SearchType}
                                    labelField="name"
                                    changeHandler={onSelect}
                                    placeholder="Search"
                                    dateProps={formState.dateRange as unknown as IGetISODate}
                                />
                            },
                            selected: {
                                index: 2,
                                label: '',
                                height: 'auto',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <div className={styles.selectedHolder}>
                                    <div className={styles.selectedCount}>{`Selected (${selectedGuests})`}</div>
                                    <RenderSelected<any>
                                        data={newAppointment.guests[GuestsDropdownValue.Agents]!}
                                        checkAttached={newAppointment.guests[GuestsDropdownValue.Prospects]!}
                                        title="Agents"
                                        icon={<PersonIcon />}
                                        uniqField="name"
                                        withDivider
                                        renderItem={(item) => (
                                            <Fragment>
                                                <div>{item.name}</div>
                                                { item.isBusy ? (
                                                    <div className={styles.busy_icon}>
                                                        <AttentionIcon />
                                                        <span>busy</span>
                                                    </div>
                                                ) : null }
                                            </Fragment>
                                        )}
                                        onRemove={(arg) => onRemoveGuest(arg.id, GuestsDropdownValue.Agents)} />
                                    <RenderSelected<any>
                                        data={newAppointment.guests[GuestsDropdownValue.Staff]!} 
                                        title="Staff"
                                        icon={<PersonIcon />}
                                        uniqField="name"
                                        withDivider
                                        renderItem={(item) => (
                                            <Fragment>
                                                <div>{item.name}</div>
                                                { item.isBusy ? (
                                                    <div className={styles.busy_icon}>
                                                        <AttentionIcon />
                                                        <span>busy</span>
                                                    </div>
                                                ) : null }
                                            </Fragment>
                                        )}
                                        onRemove={(arg) => onRemoveGuest(arg.id, GuestsDropdownValue.Staff)} />
                                    <RenderSelected<any>
                                        data={newAppointment.guests[GuestsDropdownValue.Tenants]!} 
                                        title="Tenants"
                                        withDivider
                                        uniqField="name"
                                        icon={<TenantIcon />}
                                        renderItem={(item) => (
                                            <Fragment>
                                                <div>{item.name}</div>
                                                { item.isBusy ? (
                                                    <div className={styles.busy_icon}>
                                                        <AttentionIcon />
                                                        <span>busy</span>
                                                    </div>
                                                ) : null }
                                            </Fragment>
                                        )}
                                        onRemove={(arg) => onRemoveGuest(arg.id, GuestsDropdownValue.Tenants)} />
                                    <RenderSelected<any>
                                        data={newAppointment.guests[GuestsDropdownValue.Prospects]!} 
                                        title="Prospects"
                                        withDivider
                                        uniqField="name"
                                        icon={<ProspectIcon />}
                                        renderItem={(item) => (
                                            <Fragment>
                                                <div>{item.name}</div>
                                            </Fragment>
                                        )}
                                        onRemove={(arg) => onRemoveGuest(arg.id, GuestsDropdownValue.Prospects)} />

                                    <RenderSelected<any>
                                        data={newAppointment.guests[GuestsDropdownValue.Owners!]!} 
                                        title="Owners"
                                        uniqField="name"
                                        icon={<OwnerIcon />}
                                        renderItem={(item) => (
                                            <Fragment>
                                                <div>{item.name}</div>
                                            </Fragment>
                                        )}
                                        onRemove={(arg) => onRemoveGuest(arg.id, GuestsDropdownValue.Owners)} />
                                </div>
                            },
                            // sendEmail: {
                            //     index: 3,
                            //     label: '',
                            //     height: 'auto',
                            //     fieldType: InputType.SINGLE_VALUE_SELECT,
                            //     selectableItems: [{
                            //         value: false,
                            //         label: `Don't send emails`
                            //     }],
                            //     groupStyles: {
                            //         margin: '18px 0 0 4px',
                            //     },
                            // },
                        }}
                    />
                    {newAppointment.guestsDropdownValue === GuestsDropdownValue.Prospects && <div style={{
                        position: 'absolute',
                        right: '60px',
                        top: '14px',
                    
                    }}>
                        <div className={styles.prospects_redirect_link} onClick={() => 
                            history.push(`/profiles/prospects/new?redirectUrl=/calendar/appointments?open=${newAppointment.id || 'new'}`, {
                                from: `${location.pathname}?open=${newAppointment.id || 'new'}`
                        })}>
                            <ProspectIcon /> Add Prospect
                        </div>
                    </div>}
                </div>
        </FormHolder>
        <FormHolder
            dirrection="row"
            icon={<HomeIcon />}
            withDivider
            overflow="none"
            title="Properties">
            <div className={styles.propertiesHolder}>
                <Form<Partial<any>>
                    data={formState}
                    type={FormType.LARGE}
                    wrapperStyles={wrapperStyles}
                    fields={{
                        guests: {
                            index: 0,
                            label: '',
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <div>
                                <CustomAutocomplete
                                    id="properties-list"
                                    url="/properties"
                                    searchType={SearchType.PROPERTIES}
                                    width={deviceType === DeviceType.MOBILE
                                            ? '100%'
                                            :  240
                                    }
                                    labelField="name"
                                    changeHandler={onSelectProperty}
                                    placeholder="Search"
                                />

                                    <div className={styles.allProperties}>
                                        <div className={styles.prospects_redirect_link} onClick={() => history.push(`/properties?redirectUrl=/calendar/appointments?open=${newAppointment.id || 'new'}`, {
                                            from: `${location.pathname}?open=${newAppointment.id || 'new'}`
                                        })}>
                                            <HomeIcon /> See All Properties
                                        </div>
                                    </div>
                            </div>
                        },
                        selectedProperties: {
                            index: 1,
                            label: '',
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <div className={styles.selectedHolder}>
                                <div className={styles.selectedCount}>{`Selected (${selectedProperties})`}</div>
                                <RenderSelected<any>
                                    data={newAppointment.properties} 
                                    uniqField="name"
                                    renderItem={(item) => (
                                        <Fragment>
                                            <div>{item.name}</div>
                                        </Fragment>
                                    )}
                                    onRemove={(arg) => onRemoveProperty(arg.id)} />
                            </div>
                        },
                    }}
                />
            </div>
        </FormHolder>
        <FormHolder
            title="Notes"
            iconStyle="circle"
            dirrection="row"
            icon={<NoteIcon />}>
            <Form<Partial<ICreateAppointment>>
                data={newAppointment}
                type={FormType.LARGE}
                wrapperStyles={wrapperStyles}
                onChange={onChangeCreateForm}
                fields={{
                    notes: {
                        index: 0,
                        label: '',
                        placeholder: "Notes",
                        fieldType: InputType.STRING
                    }
                }}
            />
        </FormHolder>
    </Sidenav>
}