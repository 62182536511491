import { flow, get } from 'lodash';
import { IOwner } from './models/owner.interface';
import { IOwnerCriteria } from './search/search-owners.store';

export interface IOwnerFilter {
    owners: IOwner[];
    searchCriteria: IOwnerCriteria;
}

export function erManagingYesFilter({ searchCriteria, owners }: IOwnerFilter) {
    if (searchCriteria?.erManaging !== 'YES') {
        return {
            searchCriteria,
            owners,
        };
    }

    const fo = owners.filter((owner: IOwner) => owner.doWeManage);

    return {
        searchCriteria,
        owners: fo,
    };
}

export function erManagingNoFilter({ searchCriteria, owners }: IOwnerFilter) {
    if (searchCriteria?.erManaging !== 'NO') {
        return {
            searchCriteria,
            owners,
        };
    }

    const fo = owners.filter((owner: IOwner) => !owner.doWeManage);

    return {
        searchCriteria,
        owners: fo,
    };
}

export function portfolioFiler({ searchCriteria, owners }: IOwnerFilter) {
    if (!get(searchCriteria, 'portfolio', []).length) {
        return owners;
    }

    const fo = owners.filter((owner: IOwner) => owner.portfolios.some((p: any) => {
        return searchCriteria.portfolio.some(port => port.name.includes(p.name));
    }));

    return fo;
}

export const getFilteredOwners = flow([
    erManagingYesFilter,
    erManagingNoFilter,
    portfolioFiler,
]);