export function ParseQueryParams<T>(query: string): T {
    const obj: T = {} as T;
    const [first, ...rest] = query.split('&');

    const [firstKey, firstValue] = first.replace('?', '').split('=');
    // @ts-ignore
    obj[firstKey] = firstValue;

    rest.forEach(pair => {
       const [key, value] = pair.split('=');
       // @ts-ignore
        obj[key] = value;
    });
    return obj;
}
