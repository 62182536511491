import {calendarStore, CalendarStore} from "./calendar.store";
// import {ApiServiceInstance} from "../api/api-service";
import {IPagedResponse} from "../properties/models/paged-response.interface";
import {IAppointment} from "../appointment/models/appointment.interface";
import {Event, stringOrDate} from 'react-big-calendar';
import moment from "moment";
import {IStaff} from "../staff/models/staff.inreface";
import {arrayUpdate, transaction} from "@datorama/akita";
import { SnackbarType } from "../snackbar/snackbar.store";
import { snackbarService } from "../snackbar/snackbar.service";
import { api } from "src/api/api.service";

export interface IAppointmentEvent extends Event {
    id: number;
    agent?: IStaff;
}

const colors: { background: string, color: string }[] = [
    {
        color: '#F58765',
        background: '#FFEAE3'
    },
    {
        color: '#498BE2',
        background: '#C3DDFF'
    },
    {
        color: '#53BE42',
        background: '#F3FFE8'
    },
    {
        color: '#8B65F5',
        background: '#E5DCFF'
    },
    {
        color: '#DF49E2',
        background: '#FEDFFF'
    },
    {
        color: '#42A8BE',
        background: '#E4FAFF'
    },
];

export class CalendarService {
    constructor(
        private calendarStore: CalendarStore,
        private snackbarService: any,
    ) {
    }

    private getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    private appointmentsToEvents(appointments: IAppointment[]): IAppointmentEvent[] {
        const events = appointments.flatMap(item => {
            if (
                (Array.isArray(item.agents) && item.agents.length) ||
                (Array.isArray(item.staffs) && item.staffs.length)
            ) {
                return [
                    ...(item.agents || []).flatMap(agent => ({
                        title: item.title,
                        start: moment(item.datetimeFrom).toDate(),
                        end: moment(item.datetimeTo).toDate(),
                        id: item.id,
                        agent: agent,
                        prospects: item.prospects,
                        properties: item.properties,
                    })),
                    ...(item.staffs || []).flatMap((staff: any) => ({
                        title: item.title,
                        start: moment(item.datetimeFrom).toDate(),
                        end: moment(item.datetimeTo).toDate(),
                        id: item.id,
                        agent: staff,
                        prospects: item.prospects,
                        properties: item.properties,
                    })),
                ];
            }

            return {
                title: item.title,
                start: moment(item.datetimeFrom).toDate(),
                end: moment(item.datetimeTo).toDate(),
                id: item.id,
                agent: item.agent,
                prospects: item.prospects,
                properties: item.properties,
            }
        });

        return events;
    }

    async fetchCalendarEvents() {
        this.calendarStore.setLoading(true);
        
        const [{ data }, staffList] = await Promise.all([
            api.get<IPagedResponse<IAppointment>>('/appointments', {
                params: {
                    size: 9999,
                    page: 0,
                }
            }),
            api.get<IPagedResponse<IStaff>>('/user', {
                params: {
                    size: 9999,
                    page: 0,
                }
            })
        ]);

        const events: IAppointment[] = (data.result || []).map((a) => {
                return {
                    ...a,
                    agent: {
                        ...a.agent,
                    }
                }
            }
        ) as any;

        const uniqueStaff: IStaff[] = staffList.data.result || [];

        const colorsMap = new Map();

        uniqueStaff.forEach(s => {
            colorsMap.set(s.id, colors[this.getRandomInt(0, colors.length - 1)])
        })

        this.calendarStore.update(state => ({
            ...state,
            events: this.appointmentsToEvents(events),
            appointments: events,
            staffList: uniqueStaff,
            staffToColorMap: colorsMap,
        }));

        this.calendarStore.setLoading(false);
    }

    @transaction()
    async changeAppointmentTime(appointmentId: number, start: stringOrDate, end: stringOrDate) {
        try {
            // this.calendarStore.setLoading(true);
            this.snackbarService.createSnackbar({
                text: 'Saving...',
                type: SnackbarType.INFO,
            }, 2000)

            const response = await api.patch<IAppointment>(`/appointments/${appointmentId}`, {
                datetimeFrom: moment(start).toISOString(),
                datetimeTo: moment(end).toISOString(),
            });

            if (response.ok) {
                this.calendarStore.update(state => ({
                    ...state,
                    events: this.appointmentsToEvents(arrayUpdate(state.appointments, response.data.id, response.data)),
                }));
            }

            // await this.fetchCalendarEvents();

            this.snackbarService.createSnackbar({
                text: 'Appointment Saved',
                type: SnackbarType.SUCCESS,
            }, 2000)
        } catch (err) {
            this.snackbarService.createSnackbar({
                text: 'Failed to reschedule an appointment',
                type: SnackbarType.ERROR,
            })
        } finally {
            this.calendarStore.setLoading(false);
        }
    }
}

export const calendarService = new CalendarService(calendarStore, snackbarService);