import React, { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronLeft } from '@material-ui/icons';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { RoutesEnum } from 'src/components/routing/routes';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { Dropdown } from 'src/components/ui/components/dropdown/dropdown';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { useObservable } from '@libreact/use-observable';
import { RenderSelected } from './render-selected';
import { ITenant } from 'src/store/tenants/models/tenant.interface';
import { IProspect } from 'src/store/prospects/models/prospect.interface';
import { IOwner } from 'src/store/owners/models/owner.interface';
import { IGroup } from 'src/store/tenants/groups/models/group.interface';
import { messageQuery } from 'src/store/message/message.query';
import { messageService, RecieverType, MessageType } from 'src/store/message/message.service';
import { Tooltip } from "@material-ui/core";

import { convertToRaw } from 'draft-js';

import { TenantIcon, PropertyIcon, GroupIcon, ProspectIcon, OwnerIcon, HomeIcon, MessageIcon, PersonIcon } from 'src/components/ui/icons';
import { MessageEditor } from './message-editor';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { BlockingLoader } from 'src/components/ui/components/loaders/blocking-loader';
import { ContentHolder, headMobileStyles, panelMobileStyles } from 'src/components/ui/main-layout/layout';
import { uiQuery } from 'src/store/ui-storage/ui.query';
import { CustomAutocomplete } from 'src/components/ui/components/autocomplete/custom-autocomplete';
import { SearchType } from 'src/store/autocomplete/models/autocomplete.interface';
import { searchUrlMap } from 'src/store/autocomplete/autocomplete.store';
import { RadioButton } from 'src/components/ui/components/forms/inputs/radio-button';
import { useTemplate } from 'src/store/admin-settings/hooks/use-template';
import { useNotification } from 'src/store/admin-settings/hooks/use-notification';
import { IEmailNotification } from 'src/store/admin-settings/models/email-notification.interface';
import { IStaff } from 'src/store/staff/models/staff.inreface';
import { convertToHTML } from 'draft-convert';
import { uniqBy } from 'lodash';
import { snackbarService } from "src/store/snackbar/snackbar.service";
import { SnackbarType } from "src/store/snackbar/snackbar.store";

import styles from './send-message.module.scss';
import baseStyles from 'src/styles/base.module.scss';
import 'draft-js/dist/Draft.css';
import styled from 'styled-components';

const NON_TEMPLATE_ID = 0;
const EMAIL_BY_DEFAULT = 'showing@calxpress.ca';
const EMPTY_TAG = 7;

const EMPTY_STRING_PLACEHOLDER = '';

interface SendMessageProps extends RouteComponentProps<{ id: string }> {}

const sendToMap = new Map();

sendToMap.set('prospects', SearchType.PROSPECTS);
sendToMap.set('tenants', SearchType.TENANTS);
sendToMap.set('owners', SearchType.OWNERS);
sendToMap.set('propertyGroups', SearchType.PROPERTY_GROUPS);
sendToMap.set('customGroups', SearchType.TENANTS_GROUPS);
sendToMap.set('staff', SearchType.STAFF);

const RadioButtonsHolder = styled.div`
    display: flex;
    align-items: center;

    & > div:first-child {
        margin: 0 50px 0 0;
    }
`;

const ViewAllHolder = styled.div`
    color: #FF6F43;
    display: flex;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    svg {
        font-size: 18px;
    }

    & > a:hover {
        color:  #FF6F43;
    }

    & > a:visited {
        color:  #FF6F43;
    }
`;

const ViewAllLink = styled.span`
    margin: 0 0 0 4px;
    color: #FF6F43;
    display: flex;
    font-family: 'ManropeSemibold';
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &:hover {
        color:  #FF6F43;
    }

    &:visited {
        color:  #FF6F43;
    }
`;

interface IMessageLocationState {
    from?: string;
    id?: string | number;
    backLink?: string;
    category?: string;
}

export interface IMessageFormState {
    type: MessageType;
    emailFrom: string;
    to: string;
    search: any;
    withBcc: string[];
    selected: number;
    templateId: number;
    subject: string;
    body: string;
    options: string | null;
}

const inititalFormState: IMessageFormState = {
    type: MessageType.EMAIL,
    emailFrom: EMAIL_BY_DEFAULT,
    to: '',
    search: {},
    withBcc: ["true"],
    selected: 0,
    templateId: 0,
    subject: '',
    body: '',
    options: '',
};

export const SendMessage: React.FC<SendMessageProps> = ({ history }) => {
    const location = useLocation<IMessageLocationState>();
    const [receiver, setReceiver] = useState<SearchType>(SearchType.TENANTS);
    const [template, setTemplate] = useState(1);

    const [messageType] = useObservable(messageQuery.messageType$);

    const [formState, setFormState] = useState({
        ...inititalFormState,
        type: messageType,
    });

    // const [emailFrom] = useObservable(messageQuery.emailFrom$);
    // const [isLoading] = useObservable(messageQuery.isLoading$);
    
    const [isMobile] = useObservable(uiQuery.isMobile$);

    const [selectedStaff] = useObservable(messageQuery.selectedStaff$);
    const [selectedProspects] = useObservable(messageQuery.selectedProspects$);
    const [selectedTenants] = useObservable(messageQuery.selectedTenants$);
    const [selectedOwners] = useObservable(messageQuery.selectedOwners$);
    const [selectedCustomTenantGroups] = useObservable(messageQuery.selectedCustomTenantGroups$);
    const [selectedPropertyGroups] = useObservable(messageQuery.selectedPropertyGroups$);

    // const [templates] = useObservable(messageQuery.templates$);
    const [selectedTemplate] = useObservable(messageQuery.selectedTemplate$);

    const [emailNotification, smsNotification] = useNotification();
    const [emailTemapltes, smsTemapltes] = useTemplate();

    useEffect(() => {
        messageService.init();

        if (location.state && location.state.from) {
            switch(location.state.from) {
                case 'staff':
                    setReceiver(SearchType.STAFF);
                    break;
                case SearchType.OWNERS:
                    setReceiver(SearchType.OWNERS);
                    break;
                case SearchType.PROSPECTS:
                    setReceiver(SearchType.PROSPECTS);
                    break;
                case SearchType.TENANTS_GROUPS:
                    setReceiver(SearchType.TENANTS_GROUPS);
                    break;
                case SearchType.PROPERTY_GROUPS:
                    setReceiver(SearchType.PROPERTY_GROUPS);
                    break;
                // case SearchType.TENANTS:
                //     setReceiver(SearchType.TENANTS);
                //     break;
                default:
                    setReceiver(SearchType.TENANTS);
            }
        }
    }, [location.state.from]);

    useEffect(() => {
        if (emailTemapltes.length || smsTemapltes.length) {
            messageService.fetchSelectedTemplate(template);
        }
    }, [template, emailTemapltes, smsTemapltes]);

    useEffect(() => {
        if (emailNotification.fromEmails) {
            setFormState(prevState => ({
                ...prevState,
                emailFrom: formState.type === MessageType.EMAIL
                    ? EMAIL_BY_DEFAULT
                    : smsNotification.fromPhoneNumber as string,
            }));
        }
    }, [emailNotification.fromEmails, formState.type]);

    useEffect(() => {
        if (selectedTemplate) {
            setFormState(prevState => ({
                ...prevState,
                subject: selectedTemplate.subject || '',
                body: selectedTemplate.notice || '',
                templateId: selectedTemplate.id || 0,
                options: selectedTemplate.options || null,
                systemData: selectedTemplate.systemData || null,
            }));
        }
    }, [selectedTemplate]);

    const allSelected = useMemo(() => {
        return selectedOwners.length + 
            selectedProspects.length + 
            selectedTenants.length +
            selectedPropertyGroups.length + 
            selectedCustomTenantGroups.length + 
            selectedStaff.length;
    }, [selectedOwners, selectedProspects, selectedTenants, selectedCustomTenantGroups, selectedPropertyGroups, selectedStaff.length]);

    const seeAllLink = useMemo(() => {
        switch(receiver) {
            case SearchType.TENANTS:
                return `${RoutesEnum.TENANTS_LIST}`;
            case SearchType.PROSPECTS:
                return `${RoutesEnum.PROSPECTS_LIST}`;
            case SearchType.OWNERS:
                return `${RoutesEnum.OWNERS_LIST}`;
            case SearchType.PROPERTY_GROUPS:
                return `${RoutesEnum.TENANTS_LIST}?tab=property_groups`;
            case SearchType.TENANTS_GROUPS:
                return `${RoutesEnum.TENANTS_LIST}?tab=custom_tenant_groups`;
            default:
                return `${RoutesEnum.TENANTS_LIST}`;
        }
    }, [receiver]);

    const templatesOptions = useMemo(() => {
        if (formState.type === "email") {
            if (emailTemapltes.length) {
                const filteredEmailTemplates = emailTemapltes.filter(template => !template.systemData || template.systemData === null);

                setTemplate(filteredEmailTemplates[0].id as number);
    
                return [
                    {
                        value: NON_TEMPLATE_ID,
                        content: 'No template selected',
                    },
                    ...filteredEmailTemplates.map(t => ({
                        value: t.id,
                        content: <div>{ t.name }</div>
                    }))
                ];
            }
        } else if (formState.type === "sms") {
            if (smsTemapltes.length) {
                const filteredSmsTemplates = smsTemapltes.filter(template => !template.systemData || template.systemData === null);

                setTemplate(filteredSmsTemplates[0].id as number);
    
                return [
                    {
                        value: NON_TEMPLATE_ID,
                        content: 'No template selected',
                    },
                    ...filteredSmsTemplates.map(t => ({
                        value: t.id,
                        content: <div>{ t.name }</div>
                    }))
                ];
            }
        }

        setTemplate(NON_TEMPLATE_ID);
        
        return [
            {
                value: NON_TEMPLATE_ID,
                content: 'No template selected',
            },
        ];
        
    }, [emailTemapltes, smsTemapltes, formState.type]);

    const onSelect = (item: any) => {
        switch(receiver) {
            case SearchType.STAFF:
                messageService.selectRecievers(RecieverType.STAFF, [
                    ...selectedStaff,
                    item,
                ]);

                break;
            case SearchType.TENANTS:
                messageService.selectRecievers(RecieverType.TENANTS, [
                    ...selectedTenants,
                    item,
                ]);

                break;
            case SearchType.PROSPECTS:
                messageService.selectRecievers(RecieverType.PROSPECTS, [
                    ...selectedProspects,
                    item,
                ]);

                break;

            case SearchType.OWNERS:
                messageService.selectRecievers(RecieverType.OWNERS, [
                    ...selectedOwners,
                    item,
                ]);

                break;

            case SearchType.PROPERTY_GROUPS:
                messageService.selectRecievers(RecieverType.PROPERTY_GROUPS, [
                    ...selectedPropertyGroups,
                    item,
                ]);

                break;

            case SearchType.TENANTS_GROUPS:
                messageService.selectRecievers(RecieverType.CUSTOM_GROUPS, [
                    ...selectedCustomTenantGroups,
                    item,
                ]);

                break;

            default:
                return null;
        }
    }

    const seeAll = useMemo(() => {
        switch (receiver) {
            case SearchType.TENANTS:
                return (
                    <ViewAllHolder>
                        <TenantIcon />
                        <ViewAllLink onClick={() => history.push(seeAllLink, { from: '/message', selectType: 'tenants' })}>
                            See All Tenants
                        </ViewAllLink>
                    </ViewAllHolder>
                );
            case SearchType.PROSPECTS:
                return (
                    <ViewAllHolder>
                        <ProspectIcon />
                        <ViewAllLink onClick={() => history.push(seeAllLink, { from: '/message', selectType: 'prospects' })}>
                            See All Prospects
                        </ViewAllLink>
                    </ViewAllHolder>
                );
            case SearchType.PROPERTY_GROUPS:
                return (
                    <ViewAllHolder>
                        <PropertyIcon style={{ fontSize: 20 }} />
                        <ViewAllLink onClick={() => history.push(seeAllLink, { from: '/message', selectType: 'propertyGroups' })}>
                            See All Property Groups
                        </ViewAllLink>
                    </ViewAllHolder>
                );
            case SearchType.OWNERS:
                return (
                    <ViewAllHolder>
                        <HomeIcon />
                        <ViewAllLink onClick={() => history.push(seeAllLink, { from: '/message', selectType: 'owners' })}>
                            See All Owners
                        </ViewAllLink>
                    </ViewAllHolder>
                );
            case SearchType.TENANTS_GROUPS: 
                return (
                    <ViewAllHolder>
                        <GroupIcon style={{ fontSize: 20 }} />
                        <ViewAllLink onClick={() => history.push(seeAllLink, { from: '/message', selectType: 'customGroups' })}>
                            See All Custom Tenant Groups
                        </ViewAllLink>
                    </ViewAllHolder>
                );
            default:
                return '';
        }
    }, [receiver]);

    const handleValuesChange = (data: any) => {
        setFormState((prevState) => ({
            ...prevState,
            ...data,
        }));
    }

    const handleRemove = <T extends { id: number }>(type: RecieverType, arg: any, selectedRecievers: T[]) => {
        messageService.selectRecievers<T>(type, selectedRecievers.filter(p => (p.id as keyof T) !== arg.id), true);
    }

    const handleTemplateChange = (e: ChangeEvent<any>) => {
        setTemplate(e.target.value);
        messageService.fetchSelectedTemplate(e.target.value);
    }

    const handleEditorChange = useCallback((editorState: any) => {
        // const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        // const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        // const contentState = editorState.getCurrentContent();

        const html = convertToHTML(editorState.getCurrentContent());

        setFormState((prevState) => ({
            ...prevState,
            body: html as unknown as string,
            options: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        }));
    }, [])

    const handleChangeType = (type: SearchType) => setReceiver(type);

    const handleSendMessage = async () => {
        const messageDto = messageService.createMessageDto(
            formState,
            selectedStaff,
            selectedOwners,
            selectedProspects,
            selectedTenants,
            selectedCustomTenantGroups,
            selectedPropertyGroups,
        );

        if (!formState.body || formState.body.length <= EMPTY_TAG) {
            snackbarService.createSnackbar({
                text: "Body field is required!",
                type: SnackbarType.ERROR,
            }, 5000);

            return false;
        }

        await messageService.sendMessage(messageDto, formState.type.toUpperCase());

        if (formState.subject !== '' || !formState.subject) {
            messageService.resetMessageState();
        }
    }

    const hadleMessageTypeChange = (value: MessageType) => {
        setFormState(prevState => ({
            ...prevState,
            type: value,
        }));

        messageService.updateMessageType(value);
    }

    if (!emailNotification.fromEmails?.length) {
        return <BlockingLoader />
    }

    return (
        <ContentHolder noPadding smallPadding>
            <div className={baseStyles.link_wrapper} onClick={() => history.push(
                `/${location?.state.category}/${location.state?.backLink || location.state?.from || 'tenants'}`
            )}>
                <ChevronLeft /> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
            </div>
            <Panel additionalStyles={
                isMobile ? headMobileStyles : { marginTop: 7 }
            } type={PanelType.EMPTY}>
                <div className={styles.top_wrapper}>
                    <Header3 style={{margin: '10px 0'}}>
                        Send Message
                    </Header3>
                    {
                        !isMobile
                            ? (
                                <div className={styles.actionBarHolder}>
                                    <Button
                                        icon={<MessageIcon />}
                                        btnVariant={ButtonVariant.PRIMARY}
                                        onClick={handleSendMessage}
                                        text="Send"
                                    />
                                </div>
                            )
                            : null
                    }
                </div>
            </Panel>
            <Panel additionalStyles={
                isMobile ? panelMobileStyles : {}
            }>
                <FormHolder
                    title="Contact Details"
                    overflow="none"
                    icon={<PersonIcon />}>
                        <Form
                        type={FormType.DEFAULT}
                        data={formState}
                        onChange={handleValuesChange}
                        fields={{
                            type: {
                                index: 0,
                                label: 'Send Via',
                                labelDisplay: 'block',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                height: 'auto',
                                groupStyles: {
                                    margin: '8px 0 0',
                                },
                                customElement: <RadioButtonsHolder>
                                    <RadioButton 
                                        id="email" 
                                        label="Email" 
                                        name="type" 
                                        value="email" 
                                        checked={formState.type === MessageType.EMAIL} 
                                        onChange={hadleMessageTypeChange} />
                                    <RadioButton 
                                        id="sms" 
                                        label="SMS" 
                                        name="type"
                                        value="sms" 
                                        checked={formState.type === MessageType.SMS} 
                                        onChange={hadleMessageTypeChange} />
                                </RadioButtonsHolder>
                            },
                            emailFrom: {
                                index: 0,
                                label: 'From',
                                labelDisplay: 'block',
                                fieldType: InputType.DROPDOWN,
                                height: 'auto',
                                readonly: formState.type === MessageType.SMS,
                                selectableItems: (emailNotification as IEmailNotification).fromEmails.map(email => ({
                                    content: email,
                                    label: email,
                                    value: email,
                                })),
                                groupStyles: {
                                    margin: formState.type === "email" ? '-10px 0 0' : 0,
                                    padding: '11px 0',
                                }
                            },
                            to: {
                                index: 1,
                                label: 'To',
                                height: 'auto',
                                labelDisplay: 'block',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <div className={styles.dropdown_container}>
                                    <Dropdown
                                        onChange={(e) => {
                                            handleChangeType(e.target.value);
                                        }}
                                        value={receiver}
                                        menuItems={
                                            [
                                                {value: SearchType.STAFF, content: <div className={styles.optionHolder}>
                                                    <div className={styles.optionIcon}>
                                                        <PersonIcon />
                                                    </div>
                                                    <span>Staff</span>
                                                </div>},
                                                {value: SearchType.TENANTS, content: <div className={styles.optionHolder}>
                                                    <div className={styles.optionIcon}>
                                                        <TenantIcon />
                                                    </div>
                                                    <span>Tenants</span>
                                                </div>},
                                                {value: SearchType.PROPERTY_GROUPS, content: <div className={styles.optionHolder}>
                                                    <div className={styles.optionIcon}>
                                                        <PropertyIcon style={{ fontSize: 20 }} />
                                                    </div>
                                                    <span>Property Group</span>
                                                </div>},
                                                {value: SearchType.TENANTS_GROUPS, content: <div className={styles.optionHolder}>
                                                    <div className={styles.optionIcon}>
                                                        <GroupIcon style={{ fontSize: 20 }} />
                                                    </div>
                                                    <span>Custom Tenant Group</span>
                                                </div>},
                                                {value: SearchType.OWNERS, content: <div className={styles.optionHolder}>
                                                    <div className={styles.optionIcon}>
                                                        <OwnerIcon />
                                                    </div>
                                                    <span>Owner</span>
                                                </div>},
                                                {value: SearchType.PROSPECTS, content: <div className={styles.optionHolder}>
                                                    <div className={styles.optionIcon}>
                                                        <ProspectIcon />
                                                    </div>
                                                    <span>Prospect</span>
                                                </div>},
                                            ]
                                        }
                                    />
                                    {seeAll}
                                </div>
                            },
                            search: {
                                index: 2,
                                label: '',
                                labelDisplay: 'block',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <CustomAutocomplete
                                    url={searchUrlMap.get(receiver)}
                                    searchType={receiver}
                                    id="guests-list"
                                    labelField="name"
                                    changeHandler={onSelect}
                                    placeholder="Search"
                                />
                            },
                            selected: {
                                index: 3,
                                label: '',
                                height: 'auto',
                                labelDisplay: isMobile ? 'none' : 'block',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <div className={styles.selectedHolder}>
                                    <div className={styles.selectedCount}>{ 
                                        `Selected (${allSelected})` 
                                    }</div>
                                        <RenderSelected<any>
                                            data={selectedStaff} 
                                            title="Staff"
                                            uniqField="id"
                                            icon={<PersonIcon />}
                                            renderItem={(item) => (
                                                <Fragment>
                                                    {
                                                        item.name
                                                            ? <div>{item.name}</div>
                                                            : <div>{item.firstName} {item.lastName !== '-' ? item.lastName : ''}</div>
                                                    }
                                                    {
                                                        formState.type === MessageType.EMAIL
                                                            ? <div>{item.email || EMPTY_STRING_PLACEHOLDER}</div>
                                                            : <div>{item.phoneNumber || EMPTY_STRING_PLACEHOLDER}</div>
                                                    }
                                                </Fragment>
                                            )}
                                            onRemove={(arg) => handleRemove<IStaff>(RecieverType.STAFF, arg, selectedStaff)} />

                                        <RenderSelected<any>
                                            data={selectedTenants} 
                                            title="Tenants"
                                            uniqField="id"
                                            withScrollbar
                                            icon={<TenantIcon />}
                                            renderItem={(item) => (
                                                <Fragment>
                                                    {
                                                        item.name
                                                            ? <div>{item.name}</div>
                                                            : <div>{item.firstName} {item.lastName !== '-' ? item.lastName : ''}</div>
                                                    }
                                                    {
                                                        formState.type === MessageType.EMAIL
                                                            ? <div>{item.email || EMPTY_STRING_PLACEHOLDER}</div>
                                                            : <div>{item.mobilePhone || EMPTY_STRING_PLACEHOLDER}</div>
                                                    }
                                                </Fragment>
                                            )}
                                            onRemove={(arg) => handleRemove<ITenant>(RecieverType.TENANTS, arg, selectedTenants)} />

                                        <RenderSelected<any>
                                            data={selectedProspects} 
                                            title="Prospects"
                                            uniqField="id"
                                            withScrollbar
                                            icon={<ProspectIcon />}
                                            renderItem={(item) => (
                                                <Fragment>
                                                    {
                                                        item.name
                                                            ? <div>{item.name}</div>
                                                            : <div>{item.firstName} {item.lastName}</div>
                                                    }
                                                    {
                                                        formState.type === MessageType.EMAIL
                                                            ? <div>{item.email || EMPTY_STRING_PLACEHOLDER}</div>
                                                            : <div>{item.phoneNumber || EMPTY_STRING_PLACEHOLDER}</div>
                                                    }
                                                </Fragment>
                                            )}
                                            onRemove={(arg) => handleRemove<IProspect>(RecieverType.PROSPECTS, arg, selectedProspects)} />

                                        <RenderSelected<any>
                                            data={selectedOwners} 
                                            title="Owners"
                                            uniqField="id"
                                            withScrollbar
                                            icon={<OwnerIcon />}
                                            renderItem={(item) => (
                                                <Fragment>
                                                    {
                                                        item.name
                                                            ? <div>{item.name}</div>
                                                            : <div>{item.fullname}</div>
                                                    }
                                                    {
                                                        formState.type === MessageType.EMAIL
                                                            ? <div>{item.email || EMPTY_STRING_PLACEHOLDER}</div>
                                                            : <div>{item.mobilePhone || EMPTY_STRING_PLACEHOLDER}</div>
                                                    }
                                                </Fragment>
                                            )}
                                            onRemove={(arg) => handleRemove<IOwner>(RecieverType.OWNERS, arg, selectedOwners)} />

                                        <RenderSelected<any>
                                            data={selectedPropertyGroups} 
                                            title="Property Groups"
                                            uniqField="id"
                                            withScrollbar
                                            icon={<PropertyIcon style={{ fontSize: 20 }} />}
                                            renderItem={(item) => {
                                                const filteredEmails = item.emails.filter((email: string) => email !== '');
                                                const filteredPhones = item.phoneNumbers.filter((phone: string) => phone !== '');

                                                return (
                                                    <Fragment>
                                                        {
                                                            item.name
                                                                ? <div>{item.name}</div>
                                                                : <div>{item.property}</div>
                                                        }
                                                        {
                                                            formState.type === MessageType.EMAIL
                                                                ? (
                                                                    <div>
                                                                        <Tooltip title={filteredEmails
                                                                            .map((email: string, idx: number) => {
                                                                                if (idx !== filteredEmails.length - 1) {
                                                                                    return `${email}, `;
                                                                                }
    
                                                                                return email;
                                                                            })}>
                                                                            <span className={styles.groupEmails}>{filteredEmails
                                                                                .map((email: string, idx: number) => {
                                                                                    if (idx !== filteredEmails.length - 1) {
                                                                                        return `${email}, `;
                                                                                    }
    
                                                                                    return email;
                                                                                })
                                                                            }</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div>
                                                                        <Tooltip title={filteredPhones
                                                                                .map((phone: string, idx: number) => {
                                                                                    if (idx !== filteredPhones.length - 1) {
                                                                                        return `${phone}, `;
                                                                                    }
    
                                                                                    return phone;
                                                                                })}>
                                                                            <span className={styles.groupEmails}>{filteredPhones
                                                                                .map((phone: string, idx: number) => {
                                                                                    if (idx !== filteredPhones.length - 1) {
                                                                                        return `${phone}, `;
                                                                                    }
    
                                                                                    return phone;
                                                                                })
                                                                            }</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                )
                                                        }
                                                    </Fragment>
                                                )
                                            }}
                                            onRemove={(arg) => handleRemove<any>(RecieverType.PROPERTY_GROUPS, arg, selectedPropertyGroups)} />

                                        <RenderSelected<IGroup>
                                            data={selectedCustomTenantGroups} 
                                            title="Custom Tenant Groups"
                                            uniqField="id"
                                            withScrollbar
                                            icon={<GroupIcon style={{ fontSize: 20 }} />}
                                            renderItem={(item) => {
                                                const filteredEmails = uniqBy([
                                                    ...item.tenants,
                                                    ...item.properties.flatMap(property => property.tenants.flatMap(tenant => tenant)),
                                                ], 'id').filter(tenant => tenant.email !== '');

                                                const filteredPhones = uniqBy([
                                                    ...item.tenants,
                                                    ...item.properties.flatMap(property => property.tenants.flatMap(tenant => tenant)),
                                                ], 'id').filter(tenant => tenant.mobilePhone !== '');

                                                return (
                                                    <Fragment>
                                                        <div> 
                                                            <span>{item.name}</span>
                                                        </div>
                                                        {
                                                            formState.type === MessageType.EMAIL
                                                                ? (
                                                                    <div>
                                                                        <Tooltip title={
                                                                            filteredEmails.map((tenant, idx) => {
                                                                                if (idx === filteredEmails.length - 1) {
                                                                                    return tenant.email;
                                                                                }
        
                                                                                return `${tenant.email}, `;
                                                                            })
                                                                        }>
                                                                            <span className={styles.groupEmails}>{
                                                                                filteredEmails.map((tenant, idx) => {
                                                                                    if (idx === filteredEmails.length - 1) {
                                                                                        return tenant.email;
                                                                                    }
            
                                                                                    return `${tenant.email}, `;
                                                                                })
                                                                            }</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div>
                                                                        <Tooltip title={
                                                                            filteredPhones.map((tenant: any, idx: number) => {
                                                                                if (idx === filteredPhones.length - 1) {
                                                                                    return tenant.mobilePhone;
                                                                                }
        
                                                                                return `${tenant.mobilePhone}, `
                                                                            })
                                                                        }>
                                                                            <span className={styles.groupEmails}>{
                                                                                filteredPhones.map((tenant: any, idx: number) => {
                                                                                    if (idx === filteredPhones.length - 1) {
                                                                                        return tenant.mobilePhone;
                                                                                    }
            
                                                                                    return `${tenant.mobilePhone}, `
                                                                                })
                                                                            }</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                )
                                                        }
                                                    </Fragment>
                                                )
                                            }}
                                            onRemove={(arg) => handleRemove<any>(RecieverType.CUSTOM_GROUPS, arg, selectedCustomTenantGroups)} />
                                </div>

                            },
                            withBcc: {
                                index: 4,
                                label: '',
                                hidden: formState.type === "sms",
                                labelDisplay: 'block',
                                labelStyles: {
                                    fontWeight: "normal",
                                },
                                fieldType: InputType.SINGLE_VALUE_SELECT,
                                selectableItems: [
                                    {
                                        label: 'Send as BCC',
                                        value: true,
                                    },
                                ]
                            },
                        }}
                    />
                </FormHolder>
            </Panel>
            <Panel additionalStyles={
                isMobile ? panelMobileStyles : {}
            }>
                <FormHolder
                    title="Message Details"
                    icon={<MessageIcon />}>
                    <Form
                        type={FormType.DEFAULT}
                        data={formState}
                        wrapperStyles={{
                            padding: '0 0 30px',
                        }}
                        onChange={handleValuesChange}
                        fields={{
                            templateId: {
                                index: 0,
                                label: 'Template',
                                labelDisplay: 'block',
                                height: 'auto',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <Dropdown
                                    onChange={handleTemplateChange}
                                    value={template}
                                    // placeholder="No template selected"
                                    menuItems={templatesOptions}
                                />
                            },
                            subject: {
                                index: 1,
                                label: 'Subject',
                                height: 'auto',
                                labelDisplay: 'block',
                                fieldType: InputType.STRING,
                                wrapperStyles: {
                                    display: formState.type === MessageType.SMS ? 'none' : 'flex',
                                },
                            },
                            body: {
                                index: 2,
                                label: 'Body',
                                height: 'auto',
                                labelDisplay: 'block',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <MessageEditor formState={formState} templateId={formState.templateId} onChange={handleEditorChange} />
                            },
                        }} />
                </FormHolder>
            </Panel>
            {
                isMobile
                    ? (
                        <Panel type={PanelType.EMPTY} additionalStyles={
                            isMobile ? { margin: 0 } : {}
                        }>
                            <div className={baseStyles.save_bar_mobile}>
                                <Button
                                    icon={<MessageIcon />}
                                    btnVariant={ButtonVariant.PRIMARY}
                                    onClick={handleSendMessage}
                                    text="Send"
                                />
                            </div>
                        </Panel>
                    ) : null
            }
        </ContentHolder>
    );
}