import React, {Fragment, useEffect, useMemo, useState} from "react";
import CssClasses from './view.module.scss';
import {Panel, PanelType} from "../../../ui/components/panel";
import {Header3} from "../../../ui/components/headers";
import {
    Cancel,
    CheckCircle,
    ChevronLeft,
    // FireplaceRounded,
    // InvertColors,
} from "@material-ui/icons";
// import {Title} from "../../../ui/components/forms/title";
import {Form, FormType, InputType} from "../../../ui/components/forms/formBuilder/form";
import {IProperty} from "../../../../store/properties/models/property.interface";
import {propertiesService} from "../../../../store/properties/properties.service";
import {useObservable} from "@libreact/use-observable/dist";
import {propertiesQuery} from "../../../../store/properties/properties.query";
import {RouteComponentProps} from "react-router-dom";
import {BlockingLoader} from "../../../ui/components/loaders/blocking-loader";
// import {Chips, IChipProps} from "../../../ui/components/chips";
import {ITab, Tabs} from "../../../ui/components/tabs/tabs";
import styled from "styled-components";
import {COLORS} from "../../../ui/theme/variables/colors";
// import {RoutesEnum} from "../../../routing/routes";
import {TenantsList} from "../../tenants/pages/tenants-list/tabs/tenants-list";
import {tenantsQuery} from "../../../../store/tenants/tenants.query";
import { HomeIcon, KeyIcon } from "src/components/ui/icons";
import { has } from "lodash";
// import { RenderAmenities } from "./render-amenities";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { ContentHolder } from "src/components/ui/main-layout/layout";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { tenantsService } from "src/store/tenants/tenants.service";
import { usePaging } from "src/store/common/hooks/use-paging";
import { PagingType } from "src/store/common/models/common.interface";
import { RoutesEnum } from "src/components/routing/routes";
import { Info } from "./tabs/info";
import { ContactHistory } from "src/components/ui/components/contact-history/contact-history";

const StatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${props => props.color};

    * {
        color: ${props => props.color};
    }
`;

// const PortfolioHolder = styled.span`
//     display: inline-block; 
//     font-weight: bold; 
//     margin: 14px 52px 0;

//     @media screen and (max-width: 1200px) {
//         margin: 14px 0 0;
//     }
// `;

// const amenitiesNamesToIconsMap = new Map<string, React.ReactNode>()
//     .set('Heat', <FireplaceRounded/>)
//     .set('Water', <InvertColors/>);

interface IRouteParams {
    id: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
}

export const ViewProperty: React.FC<IProps> = ({match, location: {search}}) => {
    const [,, pushLocation] = usePaging(PagingType.PROPERTIES);
    const [property] = useObservable(propertiesQuery.selectedProperty$);
    const [isLoadingProperty] = useObservable(propertiesQuery.isLoading$);
    const [tenantsCount] = useObservable(tenantsQuery.tenantsCount$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    const [propertyId, setPropertyId] = useState<number>();

    useEffect(() => {
        tenantsService.resetTenants();

        return () => {
            tenantsService.resetTenants();
            propertiesService.resetMessageHistory();
        }
    }, []);

    useEffect(() => {
        if (match.params.id) {
            setPropertyId(parseInt(match.params.id));
        }
    }, [match, setPropertyId]);

    useEffect(() => {
        if (propertyId) {
            propertiesService.fetchPropertyById(propertyId);
        }
    }, [propertyId]);

    const prepearedProperty = useMemo(() => {
        if (has(property, 'doWeManage')) {
            return property
        } else {
            return {
                ...property,
                doWeManage: '',
            }
        }
    }, [property]);

    if (!property || (property.id !== parseInt(match.params.id))) {
        return <BlockingLoader/>
    }

    const currentLocation = `/properties/${match.params.id}`;

    const tabs: ITab[] = [
        {
            basePath: currentLocation,
            specificPath: '',
            tabName: 'Info',
            children: <Info property={prepearedProperty as IProperty} />
        },
        {
            basePath: currentLocation,
            specificPath: 'tenants',
            tabName: 'Tenants',
            children: <>
                <FormHolder
                    title="Tenant / Tenant groups"
                    icon={<KeyIcon />}>
                    <Form<{ name: string }>
                        type={FormType.DEFAULT}
                        data={{name: 'Group - 1514 Western Rd.'}}
                        readOnly
                        fields={{
                            name: {
                                label: 'Name',
                                labelDisplay: 'block',
                                wrapperStyles: {
                                    flexDirection: 'row',
                                },
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <div>
                                    <div style={{fontWeight: 600}}>
                                        Group - {property.unitAddress}
                                    </div>
                                    <div style={{color: COLORS.GRAY}}>
                                        {tenantsCount} {
                                            !tenantsCount
                                                ? 'People'
                                                : tenantsCount === 1 
                                                    ? 'Person' 
                                                    : 'People'
                                            }
                                    </div>
                                </div>
                            }
                        }}
                    />
                </FormHolder>
            </>
        },
        {
            basePath: currentLocation,
            specificPath: 'lease_contracts',
            tabName: 'Lease Contracts',
            children: <div>
                Leasing
            </div>
        },
        {
            basePath: currentLocation,
            specificPath: 'history',
            tabName: 'Contact History',
            children: <ContactHistory id={property.id} context="properties" field="properties" />,
            panelType: PanelType.EMPTY,
        },
    ]

    return <ContentHolder noPadding smallPadding>
        <div className={CssClasses.link_wrapper} style={{
            cursor: 'pointer',
        }} onClick={() => pushLocation(RoutesEnum.PROPERTIES_LIST, PagingType.PROPERTIES)}>
            <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
        </div>
        <Panel additionalStyles={
            isMobile ? { margin: '0 0 28px' } : { marginTop: 7 }
        } type={PanelType.EMPTY}>
            <div className={CssClasses.top_wrapper}>
                <Header3 style={{margin: '10px 0'}}>
                    Property Info
                </Header3>
            </div>
        </Panel>
        <Panel additionalStyles={
                isMobile ? { 
                    margin: '0 0 30px',
                    width: '97.5%',
                } : {}
            }>
            <FormHolder
                title="Property"
                icon={<HomeIcon />}>
                <Form<Partial<IProperty>>
                    type={FormType.DEFAULT}
                    data={property!}
                    readOnly
                    fields={{
                        unitAddress: {
                            index: 0,
                            label: 'Unit Address',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        unitId: {
                            index: 1,
                            label: 'Sync ID',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        readyToLease: {
                            index: 2,
                            label: 'Ready To Lease',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            groupStyles: {
                                margin: '0 0 0 -2px',
                            },
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <Fragment>
                                {property.readyToLease === 'Yes' && <StatusWrapper color={COLORS.BLUE}>
                                    <CheckCircle/> <span style={{marginLeft: 5}}>Yes</span>
                                </StatusWrapper>}
                                {property.readyToLease === 'No' && <StatusWrapper color={COLORS.DARK_ORANGE}>
                                    <Cancel/> <span style={{marginLeft: 5}}>No</span>
                                </StatusWrapper>}
                            </Fragment>
                        }
                    }}
                />
            </FormHolder>
        </Panel>
        <Tabs
            tabs={tabs}
        />
        {
            property.unitId
                ? (search === '?tab=tenants' && !isLoadingProperty) && <TenantsList
                    isFilterable={false}
                    omitFields={['building', 'portfolio.name', 'group']}
                    useSelectedUnitId={property.unitId as unknown as string}
                />
                : null
        }
    </ContentHolder> 
}