import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import {SignIn} from "./pages/sign-in";
import {SignUp} from "./pages/sign-up";
import {RoutesEnum} from "../../routing/routes";
import {ForgotPassword} from "./pages/forgot-password";
// import posed, {PoseGroup} from 'react-pose';
import {ForgotPasswordEmailSent} from "./pages/forgot-password-email-sent";
import {PasswordReset} from "./pages/password-reset";

// const RouteContainer = posed.div({
//     enter: {marginLeft: 0, overflowY: 'scroll', opacity: 1, transition: { duration: 400 }, beforeChildren: true},
//     exit: {marginLeft: -1000, overflowY: 'scroll', transition: { duration: 400 }, opacity: 0.5}
// });

export function Auth() {
    return (
        <>
            <Route render={({location}) => {
                return <>
                    <Switch location={location}>
                        <Route path={RoutesEnum.SIGN_IN} component={SignIn} key={RoutesEnum.SIGN_IN} />
                        <Route path={RoutesEnum.SIGN_UP} component={SignUp} key={RoutesEnum.SIGN_UP} />
                        <Route path={RoutesEnum.FORGOT_PASSWORD} component={ForgotPassword} key={RoutesEnum.FORGOT_PASSWORD} />
                        <Route path={RoutesEnum.FORGOT_PASSWORD_EMAIL_SENT} component={ForgotPasswordEmailSent} key={RoutesEnum.FORGOT_PASSWORD_EMAIL_SENT} />
                        <Route path={RoutesEnum.PASSWORD_RESET} component={PasswordReset} key={RoutesEnum.PASSWORD_RESET} />
                        <Redirect key="redirect-to-sign-in" to="redirect"/>
                    </Switch>
                </>
            }}
            />
        </>
    )
}
