import React, { useEffect, useMemo, useState } from 'react';
import { ExportIcon, MessageIcon } from "../../../ui/icons";
import { Button, ButtonVariant } from "../../../ui/components/buttons/button";
import { Add } from "@material-ui/icons";
import { ArrowLeftIcon, ArrowRightIcon } from 'src/components/ui/icons';  
import { useObservable } from '@libreact/use-observable';
import { uiQuery } from 'src/store/ui-storage/ui.query';
import { useHistory } from 'react-router-dom';

import styles from './calendar.module.scss';
import { CustomDropdown } from 'src/components/ui/components/autocomplete/custom-dropdown';
import { ToolbarProps } from 'react-big-calendar';

interface ICustomToolbar {
    toolbar: ToolbarProps;
    setIsExportOpened: (isOpened: boolean) => void;
    scheduleAppointment: () => void;
}

export const CustomToolbar: React.FC<ICustomToolbar> = ({ toolbar, setIsExportOpened, scheduleAppointment }) => {
    const history = useHistory();
    const [currentView, setView] = useState(toolbar.view);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        if (currentView !== toolbar.view) {
            setView(toolbar.view);
        }
    }, [toolbar.view, currentView]);

    const calendarLabel = useMemo(() => {
        return shortLabel(toolbar.label);
    }, [toolbar.label]);

    const handleChangeView = (value: string) => {
        setView(() => value as any);
        toolbar.onView(value as any);
    }

    const goToBack = () => {
        switch(currentView) {
            case 'month':
                toolbar.date.setMonth(toolbar.date.getMonth());
                break;
            case 'day':
                toolbar.date.setDate(toolbar.date.getDate());
                break;
            case 'week':
                toolbar.date.setDate(toolbar.date.getDate());
                break;
        }

        toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
        switch(currentView) {
            case 'month':
                toolbar.date.setMonth(toolbar.date.getMonth());
                break;
            case 'day':
                toolbar.date.setDate(toolbar.date.getDate());
                break;
            case 'week':
                toolbar.date.setDate(toolbar.date.getDate());
                break;
        }

        toolbar.onNavigate('NEXT');
    };

    const handleScrollView = () => {
        document.getElementById('calendar-view')?.scrollIntoView({
            behavior: 'smooth',
        });
    }

    return (
        <div className={styles['toolbar-container']}>
            <div className={styles['toolbar-left']}>
                <div className={styles['control-holder']}>
                    <div className={styles['back-next-buttons']}>
                        <button className={styles['btn-back']} onClick={goToBack}>
                            <ArrowLeftIcon />
                        </button>
                        <label className={styles['label-date']}>{calendarLabel}</label>
                        <button className={styles['btn-next']} onClick={goToNext}>
                            <ArrowRightIcon />
                        </button>
                    </div>

                    {/* <Dropdown
                        onChange={handleChangeView}
                        value={currentView}
                        styles={{
                            margin: isMobile ? '0 -26px 28px 8px' : '0 24px 0 0',
                            minWidth: 100,
                        }}
                        menuItems={toolbar.views.map((view: any) => ({
                            content: <div style={{ textTransform: 'capitalize' }}>{ view }</div>,
                            value: view,
                        }))} /> */}

                    <div className={styles.wrapper_custom_dropdown} 
                    // style={{ margin: '0 48px 0 0' }}
                    >
                        <CustomDropdown
                            id="select-view"
                            width="100px"
                            changeHandler={handleChangeView}
                            selectedValue={currentView}
                            // labelField="value"
                            setOnlyValue
                            options={(toolbar.views as any[]).map(view => ({
                                content: <div style={{ textTransform: 'capitalize' }}>{ view }</div>,
                                label: view,
                                value: view,
                            }))} />
                    </div>
                </div>

                <div style={{ fontSize: 14, cursor: 'pointer' }} onClick={handleScrollView}>Calendar View</div>
            </div>

            <div className={styles.list_action_bar_right}>
                <Button
                    icon={<MessageIcon />}
                    btnVariant={ButtonVariant.OUTLINE}
                    text={isMobile ? '' : 'Message'}
                    onClick={() => history.push('/message', { 
                        from: 'calendar-view', 
                        backLink: 'calendar-view',
                        category: 'calendar',
                    })}
                />
                <Button
                    onClick={() => setIsExportOpened(true)}
                    icon={<ExportIcon/>}
                    btnVariant={ButtonVariant.OUTLINE}
                    text={isMobile ? '' : 'Export'}
                />
                <Button
                    icon={<Add style={{fontSize: 14}}/>}
                    onClick={scheduleAppointment}
                    btnVariant={ButtonVariant.PRIMARY}
                    text={isMobile ? 'Schedule' : 'Schedule Appointment'}
                />
            </div>
        </div>
    );
};

function shortLabel(label: string) {
    const labelArr = label.split(' ');

    if (labelArr.length > 4) {
        labelArr.splice(0, 1, labelArr[0].substr(0, 3));
        labelArr.splice(3, 1, labelArr[3].substr(0, 3));

        return labelArr.join(' ');
    }

    if (labelArr.length === 3) {
        labelArr.splice(0, 1, labelArr[0].substr(0, 3));

        return labelArr.join(' ');
    }

    return label;
}