import { useObservable } from "@libreact/use-observable";
import { autocompleteQuery } from '../autocomplete.query';

export type AutocompleteHookTuple = [any[], number, {
    [k: string]: {
        loading: boolean;
    };
}, boolean];

export function useAutoComplete (): AutocompleteHookTuple {
    const [searchList] = useObservable(autocompleteQuery.searchList$);
    const [searchTotal] = useObservable(autocompleteQuery.searchTotal$);
    const [searchStatus] = useObservable(autocompleteQuery.searchStatus$);
    const [resetInput] = useObservable(autocompleteQuery.resetInput$);

    return [searchList, searchTotal, searchStatus, resetInput];
}