import { createEntityQuery } from "@datorama/akita";
import { KeyedNamedEntity } from "../../properties/search/search-properties.query";
import { ISearchStaffState, IStaffSearchCriteria, searchStaffStore } from "./staff-search.store";
import { StaffRole } from "../models/staff.inreface";

const rolesMap = new Map();
rolesMap.set(StaffRole.AGENT, 'Agent');
rolesMap.set(StaffRole.ADMINISTRATOR, 'Administrator');
rolesMap.set(StaffRole.STAFF, 'Staff');

export const staffSearchQuery = createEntityQuery<ISearchStaffState>(searchStaffStore);

export const allSelectedFilters$ = staffSearchQuery.select<KeyedNamedEntity<keyof IStaffSearchCriteria>[]>(state => {
    const { selectedCriteria: { roles, prospectsLeading } } = state;
    const entities: KeyedNamedEntity<keyof IStaffSearchCriteria>[] = [];

    if (prospectsLeading.from && !isNaN(prospectsLeading.from)) {
        entities.push({
            id: prospectsLeading.from,
            name: `Prospects from: ${prospectsLeading.from}`,
            key: 'prospectsLeadingF',
        });
    }

    if (prospectsLeading.to && !isNaN(prospectsLeading.to)) {
        entities.push({
            id: prospectsLeading.to,
            name: `Prospects to: ${prospectsLeading.to}`,
            key: 'prospectsLeadingT',
        });
    }

    roles.forEach(role => {
        entities.push({
            id: role,
            name: `Role: ${rolesMap.get(role)}`,
            key: 'roles',
        })
    });

    return entities;
})

export const loading$ = staffSearchQuery.selectLoading();
export const filterCriteria$ = staffSearchQuery.select(state => state.selectedCriteria);
export const searchData$ = staffSearchQuery.select(state => state.searchData);