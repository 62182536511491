import React from "react";
import {Header} from "./header";
// import {LayoutContainer} from "./container";
import {Sidenav} from "./sidenav";
import {MainContainer} from "./main-container";
import {ContentContainer} from "./content-container";
import {useObservable} from "@libreact/use-observable/dist";
import {uiQuery} from "../../../store/ui-storage/ui.query";
import styled, { css } from "styled-components";

const LayoutHolder = styled.div`
    display: flex;
    width: 100%;
    min-height: 100vh;
`;

interface IContentHolder {
    noPadding?: boolean;
    paddingBoth?: boolean;
    smallPadding?: boolean;
}

const layoutStyles = ({ noPadding }: IContentHolder) => {
    if (noPadding) {
        return css`
            padding: 0;
        `;
    }

    return css`
        padding: 0 20px;
    `;
}

const layoutMobileStyles = ({ smallPadding, paddingBoth }: IContentHolder) => {
    if (smallPadding) {
        return css`
            padding: 0 0 0 10px;
        `;
    }

    if (paddingBoth) {
        return css`
            padding: 0 10px;
        `;
    }
}

export const ContentHolder = styled.div<IContentHolder>`
    ${layoutStyles}

    @media screen and (max-width: 1200px) {
        ${layoutMobileStyles}
    }
`;

export const headMobileStyles: React.CSSProperties = {
    margin: '0 0 28px'
};


export const panelMobileStyles: React.CSSProperties = {
    margin: '0 0 30px',
    width: '97.5%',
};

interface IProps {
    children?: React.ReactNode;
}

export function MainLayout(props: IProps) {
    const [isSidenavOpen] = useObservable(uiQuery.isSidenavOpen$);
    return <LayoutHolder>
        <Sidenav isSidenavOpen={isSidenavOpen} />
        <MainContainer>
            <Header />
            <ContentContainer>
                {props.children}
            </ContentContainer>
        </MainContainer>
    </LayoutHolder>
}