import React, { useEffect, useMemo, useState } from 'react';
import { ChevronLeft } from '@material-ui/icons';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import ContactDetails from './tabs/contact-details';
import { RoutesEnum } from 'src/components/routing/routes';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { ITab, Tabs } from 'src/components/ui/components/tabs/tabs';
import { IProspect } from 'src/store/prospects/models/prospect.interface';
import { useObservable } from '@libreact/use-observable';
import { prospectsQuery } from 'src/store/prospects/prospects.query';
import { prospectsService } from 'src/store/prospects/prospects.service';
import { BlockingLoader } from 'src/components/ui/components/loaders/blocking-loader';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';

import { ArchiveBoxIcon, EditIcon, ProspectIcon } from 'src/components/ui/icons';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { ContentHolder } from 'src/components/ui/main-layout/layout';
import { uiQuery } from 'src/store/ui-storage/ui.query';

// import baseStyles from 'src/styles/base.module.scss';
import styles from './prospect-info.module.scss';
import { Modal } from 'src/components/ui/components/modal/modal';
import { SYSTEM_MESSAGES } from 'src/store/prospects/prospects.config';
import { ILocationState, PagingType } from 'src/store/common/models/common.interface';
import { usePaging } from 'src/store/common/hooks/use-paging';
import { ContactHistory } from 'src/components/ui/components/contact-history/contact-history';


interface IProspectsInfoProps extends RouteComponentProps<{id: string}> {}

const ProspectInfo: React.FC<IProspectsInfoProps> = ({ history, match }) => {
    const location = useLocation<ILocationState>();
    const [,, pushLocation] = usePaging(PagingType.PROSPECTS);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [currentProspect] = useObservable(prospectsQuery.currentProspect$);
    // const [isLoading] = useObservable(prospectsQuery.isLoading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        const parsedId = parseInt(match.params.id, 10);
        prospectsService.fetchProspectById(parsedId);

        return () => {
            !editMode && prospectsService.resetCurrentProspect();
        }
    }, [editMode, match.params.id])

    const prepearedProspect = useMemo(() => {
        return {
            ...currentProspect,
            photo: '',
        };
    }, [currentProspect]);

    const handleEdit = () => {
        setEditMode(true);
        history.push(`/profiles/prospects/${match.params.id}/edit`);
    }

    const archiveHandler = () => {
        if (currentProspect) {
            prospectsService.archiveProspect(currentProspect.id);
            history.push('/profiles/prospects');
        }
    }

    const handleClose = () => setOpen(false);


    if (!currentProspect || (currentProspect.id !== parseInt(match.params.id))) {
        return <BlockingLoader />
    }

    const tabs: ITab[] = [
        {
            basePath: location.pathname,
            specificPath: '',
            children: <ContactDetails prospect={currentProspect} />,
            tabName: 'Contact Details'
        },
        {
            basePath: location.pathname,
            specificPath: 'contact_history',
            children: <ContactHistory id={currentProspect.id} context="prospects" field="prospects" />,
            tabName: 'Contact History',
            panelType: PanelType.EMPTY,
        },
        {
            basePath: location.pathname,
            specificPath: 'showing_history',
            children: <div>Showing History</div>,
            tabName: 'Showing History'
        },
        {
            basePath: location.pathname,
            specificPath: 'edit_history',
            children: <div>Edit History</div>,
            tabName: 'Edit History'
        }
    ];

    return (
        <ContentHolder noPadding smallPadding>
            <div style={{ cursor: 'pointer' }} className={styles.link_wrapper} onClick={() => 
                pushLocation(RoutesEnum.PROSPECTS_LIST, PagingType.PROSPECTS)
            }>
                <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
            </div>
            <Panel additionalStyles={
                isMobile ? { margin: '0 0 28px' } : { marginTop: 7 }
            } type={PanelType.EMPTY}>
                <div className={styles.top_wrapper}>
                    <Header3 style={{margin: '10px 0'}}>
                        Prospect Info
                    </Header3>
                    {
                        !isMobile
                            ? (
                                <div className={styles.actionBarHolder}>
                                    <Button
                                        icon={<ArchiveBoxIcon />}
                                        btnVariant={ButtonVariant.OUTLINE}
                                        onClick={() => setOpen(true)}
                                        text="Archive"
                                    />
                                    {/* <Button
                                        icon={<MessageIcon />}
                                        btnVariant={ButtonVariant.OUTLINE}
                                        text="Message"
                                    /> */}
                                    <Button
                                        icon={<EditIcon />}
                                        btnVariant={ButtonVariant.PRIMARY}
                                        onClick={handleEdit}
                                        text="Edit"
                                    />
                                </div>
                            )
                            : null
                    }
                </div>
            </Panel>
            <Panel additionalStyles={
                isMobile ? { 
                    margin: '0 0 30px',
                    width: '97.5%',
                } : {}
            }>
                <FormHolder
                    title="Prospect"
                    icon={<ProspectIcon />}>
                    <Form<Partial<IProspect>>
                        type={FormType.DEFAULT}
                        data={prepearedProspect}
                        readOnly
                        fields={{
                            firstName: {
                                index: 1,
                                label: 'First Name',
                                labelDisplay: 'block',
                                wrapperStyles: {
                                    flexDirection: 'row',
                                },
                                fieldType: InputType.STRING,
                            },
                            lastName: {
                                index: 2,
                                label: 'Last Name',
                                labelDisplay: 'block',
                                wrapperStyles: {
                                    flexDirection: 'row',
                                },
                                fieldType: InputType.STRING,
                            },
                            // id: {
                            //     index: 3,
                            //     label: 'ID',
                            //     fieldType: InputType.STRING,
                            // }
                        }}
                    />
                </FormHolder>
            </Panel>
            <Tabs
                tabs={tabs}
            />
            {
                isMobile
                    ? (
                        <Panel type={PanelType.EMPTY}>
                            <div className={styles.list_action_bar_mobile}>
                                <Button
                                    icon={<ArchiveBoxIcon />}
                                    btnVariant={ButtonVariant.OUTLINE}
                                    onClick={archiveHandler}
                                    text="Archive"
                                />
                                {/* <Button
                                    icon={<MessageIcon />}
                                    btnVariant={ButtonVariant.OUTLINE}
                                    text=""
                                /> */}
                                <Button
                                    icon={<EditIcon />}
                                    btnVariant={ButtonVariant.PRIMARY}
                                    onClick={handleEdit}
                                    text="Edit"
                                />
                            </div>
                        </Panel>
                    ) : null
            }
            <Modal
                open={open}
                title={SYSTEM_MESSAGES.DELETE_CONFIRM}
                wrapperStyles={{ padding: '37px' }}
                content={<div style={{ width: '400px' }}></div>}
                saveButtonText="Archive"
                onClose={handleClose}
                onSave={archiveHandler} />
        </ContentHolder>
    );
}

export default ProspectInfo