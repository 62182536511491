import React, { Fragment, useEffect, useState } from 'react';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Title } from 'src/components/ui/components/forms/title';
import { Panel } from 'src/components/ui/components/panel';
import { IEmailNotification } from 'src/store/admin-settings/models/email-notification.interface';
import { ITemplate } from 'src/store/admin-settings/models/template.interface';
import { TemplatesTable, TemplateType } from '../../../templates-table';

import { isEmpty } from 'lodash';
import { adminSettingsService } from 'src/store/admin-settings/admin-settings.service';
import { SettingsOutlinedIcon, InfoIcon, SaveIcon, EditIcon } from 'src/components/ui/icons';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { panelMobileStyles } from 'src/components/ui/main-layout/layout';

interface IEmailSettingsProps {
    emailNotification: Partial<IEmailNotification>;
    templates: ITemplate[];
    loading?: boolean;
    isMobile?: boolean;
}

const inititalState = {
    fromEmails: [],
    bodyHeader: "",
    fromName: "",
    subject: "",
};

export const EmailSettings: React.FC<IEmailSettingsProps> = ({ templates, emailNotification, isMobile }) => {
    const [editMode, setEditMode] = useState(false);
    const [formState, setFormState] = useState<Partial<IEmailNotification>>(inititalState);

    useEffect(() => {
        if (!isEmpty(emailNotification)) {
            setFormState(emailNotification);
        }
    }, [emailNotification]);

    const handleValuesChange = (data: any) => {
        setFormState(prevState => ({
            ...prevState,
            ...data,
        }));
    }

    const handleSaveEmailSettings = () => {
        const { fromEmails, ...updatedEmailSettings } = formState;
        adminSettingsService.saveEmailSettings(updatedEmailSettings);

        setEditMode(false);
    }

    return (
        <Fragment>
            <Panel additionalStyles={
                isMobile ? {
                    margin: '-22px 0px 30px',
                    borderRadius: '0 0 0 10px',
                } : { margin: '-5px 0 30px', borderRadius: '0 0 10px 10px' }
            }>
                <Title
                    icon={<SettingsOutlinedIcon />}
                    iconStyle={{ fontSize: '14px', width: '30px', height: '30px' }}
                    text="Email Settings"
                />
            </Panel>
            <Panel additionalStyles={
                isMobile ? panelMobileStyles : { margin: '-5px 0 30px' }
            }>
                <FormHolder
                    title="Showing Notification"
                    icon={<InfoIcon />}
                    rightConnerButton={<Fragment>
                        {
                            editMode
                                ? (
                                    <Button
                                        icon={<SaveIcon />}
                                        btnVariant={ButtonVariant.OUTLINE}
                                        onClick={handleSaveEmailSettings}
                                        text="Save"
                                    />
                                )
                                : (
                                    <Button
                                        icon={<EditIcon />}
                                        btnVariant={ButtonVariant.OUTLINE}
                                        onClick={() => setEditMode(true)}
                                        text="Edit"
                                    />
                                )
                        }
                    </Fragment>}>
                    <Form<Partial<any>>
                        type={FormType.DEFAULT}
                        data={formState}
                        onChange={handleValuesChange}
                        readOnly={!editMode}
                        fields={{
                            fromEmails: {
                                index: 0,
                                label: 'From Email Address',
                                labelDisplay: 'block',
                                wrapperStyles: {
                                    flexDirection: 'row',
                                },
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <div>
                                    {/* { (emailNotification?.fromEmails as string[]).join(', ') } */}
                                    { (emailNotification?.fromEmails as string[])[0] }
                                </div>
                            },
                            fromName: {
                                index: 1,
                                readonly: true,
                                label: 'From Name',
                                labelDisplay: 'block',
                                wrapperStyles: {
                                    flexDirection: 'row',
                                },
                                placeholder: 'From Name',
                                fieldType: InputType.STRING,
                            },
                            subject: {
                                index: 2,
                                label: 'Subject',
                                labelDisplay: 'block',
                                wrapperStyles: {
                                    flexDirection: 'row',
                                },
                                placeholder: 'Subject',
                                fieldType: InputType.STRING,
                            },
                            bodyHeader: {
                                index: 3,
                                label: 'Body Header',
                                labelDisplay: 'block',
                                wrapperStyles: {
                                    flexDirection: 'row',
                                },
                                placeholder: 'Body Header',
                                fieldType: InputType.STRING,
                            }
                        }}
                    />
                </FormHolder>
            </Panel>
            <TemplatesTable templateType={TemplateType.EMAIL} isMobile={isMobile} />
        </Fragment>
    );
}