import React, {useEffect, useMemo} from "react";
import {tenantsService} from "../../../../../store/tenants/tenants.service";
import {RouteComponentProps} from 'react-router-dom';
import {ITab, Tabs} from "../../../../ui/components/tabs/tabs";
import CssClasses from "./tenant-view.module.scss";
import {RoutesEnum} from "../../../../routing/routes";
import {ChevronLeft} from "@material-ui/icons";
import {Panel, PanelType} from "../../../../ui/components/panel";
import {Header3} from "../../../../ui/components/headers";
import {Form, FormType, InputType} from "../../../../ui/components/forms/formBuilder/form";
import {useObservable} from "@libreact/use-observable/dist";
import {tenantsQuery} from "../../../../../store/tenants/tenants.query";
import {ITenant} from "../../../../../store/tenants/models/tenant.interface";
import {BlockingLoader} from "../../../../ui/components/loaders/blocking-loader";
import {ContactDetails} from "./tabs/contact-details";
import { KeyIcon } from "src/components/ui/icons";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { ContentHolder } from "src/components/ui/main-layout/layout";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { usePaging } from "src/store/common/hooks/use-paging";
import { PagingType } from "src/store/common/models/common.interface";
import { ContactHistory } from "src/components/ui/components/contact-history/contact-history";

interface IProps extends RouteComponentProps<{id: string}> {}

export const TenantView: React.FC<IProps> = ({match: {params: {id}}, location}) => {
    const [,, pushLocation] = usePaging(PagingType.TENANTS);
    const [currentTenant] = useObservable(tenantsQuery.currentTenant$);
    // const [isLoading] = useObservable(tenantsQuery.isLoading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        const parsedId = parseInt(id);
        tenantsService.fetchTenantById(parsedId);
        
        return () => {
            tenantsService.resetCurrentTenant();
        }
    }, [id]);

    const prepearedTenant = useMemo(() => {
        return {
            ...currentTenant,
        };
    }, [currentTenant]);

    if (!currentTenant) {
        return <BlockingLoader />
    }

    const tabs: ITab[] = [
        {
            basePath: location.pathname,
            specificPath: '',
            children: <ContactDetails tenant={currentTenant} />,
            tabName: 'Contact Details'
        },
        {
            basePath: location.pathname,
            specificPath: 'lease_contract',
            children: <div>Leasing</div>,
            tabName: 'Lease Contracts'
        },
        {
            basePath: location.pathname,
            specificPath: 'contact_history',
            children: <ContactHistory id={currentTenant.id} context="tenants" field="tenants" />,
            tabName: 'Contact History',
            panelType: PanelType.EMPTY,
        },
        {
            basePath: location.pathname,
            specificPath: 'prospect_history',
            children: <div>Prospect History</div>,
            tabName: 'Prospect History'
        }
    ];

    return <ContentHolder noPadding smallPadding>
        <div style={{ cursor: 'pointer' }} className={CssClasses.link_wrapper} onClick={() => 
            pushLocation(RoutesEnum.TENANTS_LIST, PagingType.TENANTS)
        }>
            <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
        </div>
        <Panel additionalStyles={
                isMobile ? { margin: '0 0 28px' } : { marginTop: 7 }
        } type={PanelType.EMPTY}>
            <div className={CssClasses.top_wrapper}>
                <Header3 style={{margin: '10px 0'}}>
                    Tenant Info
                </Header3>
            </div>
        </Panel>
        <Panel additionalStyles={
            isMobile ? { 
                margin: '0 0 30px',
                width: '97.5%',
            } : {}
        }>
            <FormHolder
                title="Tenant"
                icon={<KeyIcon />}>
                <Form<Partial<ITenant>>
                    type={FormType.DEFAULT}
                    data={prepearedTenant}
                    readOnly
                    fields={{
                        firstName: {
                            index: 1,
                            label: 'First Name',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        lastName: {
                            index: 2,
                            label: 'Last Name',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        id: {
                            index: 3,
                            label: 'ID',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        }
                    }}
                />
            </FormHolder>
        </Panel>
        <Tabs
            tabs={tabs}
        />
    </ContentHolder>
}