import React, {Fragment, useCallback, useMemo} from "react";
import DataTable, {createTheme, IDataTableColumn, IDataTableConditionalRowStyles} from "react-data-table-component";
import classNames from "classnames";
import CssClasses from './table.module.scss';
import {COLORS} from "../../theme/variables/colors";
import {numerate} from "../../../utils/fns/numerate";
import { TableCheckbox } from "../forms/inputs/checkbox";
import { ISelectedData } from "src/store/utils/common.interface";
import { ExpandMore } from '@material-ui/icons';
import styled from "styled-components";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { useObservable } from "@libreact/use-observable";

const NO_DATA = 'There are no records to display';

const IconHolder = styled.div`
    position: relative;
    /* top: 28px; */
    right: -10px;
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #BEC4D3;
    color: #fff;
`;

const IconHolderExpaned = styled.div`
    position: relative;
    /* top: 28px; */
    right: -10px;
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #BEC4D3;
    color: #fff;

    & > svg {
        transform: rotate(180deg);
    }
`;

createTheme('custom_default', {
    text: {
        primary: COLORS.TEXT_COLOR,
        secondary: COLORS.BLUE,
    },
    divider: {
        default: 'rgba(0, 0, 0, 0)'
    },
    highlightOnHover: {
        default: COLORS.LIGHTEST_BLUE,
    },
    header: {
        style: {
            fontSize: '22px',
            minHeight: '56px',
            paddingLeft: '16px',
            paddingRight: '8px',
        },
    },
    subHeader: {
        style: {
            minHeight: '52px',
        },
    },
    head: {
        style: {},
    },
    headRow: {
        style: {
            minHeight: '200px',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: 'rgba(200, 206, 214, 0.47)',
        },
        denseStyle: {
            minHeight: '32px',
        },
    },
    headCells: {
        style: {
            fontSize: '12px',
            fontWeight: 500,
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        activeSortStyle: {
            '&:focus': {
                outline: 'none',
            },
        },
    },
});

interface INumerated {
    i: number;
}

export type SelectionChanged<T> = {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: (T & INumerated)[];
}

interface IProps<T> {
    title?: string;
    highlightRow?: string[];
    scrollable?: boolean;
    onRowClicked?: (e: T, activePage: number, paginationPerPage: number) => void;
    striped?: boolean;
    highlightOnHover?: boolean;
    data: Array<T> | any;
    totalRecordsNumber?: number;
    noTableHead?: boolean;
    expandableRows?: boolean;
    expandableRowsComponent?: React.ReactNode;
    selectableRows?: boolean;
    expandableRowExpanded?: (row: any) => boolean;
    onRowExpandToggled?: (toggleState: any, row: any) => void;
    // onSort?: (column: IDataTableColumn<T & INumerated>, sortDirect: 'asc' | 'desc') => void;
    onSort?: any;

    onChangePage?: (page: number, totalRows: number) => void;

    onSelectionChange?: (e: ISelectedData<any>) => void;
    selectableRowSelected?: any;

    contextActions?: React.ReactNode | React.ReactNode[];
    clearedSelectionRows?: boolean;
    loading?: boolean;
    columns: IDataTableColumn<any>[];
    conditionalRowStyles?: IDataTableConditionalRowStyles[];

    onChangeRowsNumber?(rowPerPage: number, page: number): void;

    paginationPerPage?: number;
    pagination?: boolean;
    activePage?: number;
    pushBottom?: boolean;
    paginationServer?: boolean;
    paginationServerOptions?: {
        persistSelectedOnPageChange: boolean, 
        persistSelectedOnSort: boolean,
    },
    noDataText?: string;
}

const customStyles: any = {
    header: {
        style: {
            fontSize: '22px',
            minHeight: '56px',
            paddingLeft: '16px',
            paddingRight: '8px',
            paddingTop: '30px',
            borderRadius: '10px',
        },
    },
    subHeader: {
        style: {
            minHeight: '52px',
        },
    },
    head: {
        style: {},
    },
    headRow: {
        style: {
            minHeight: '56px',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            paddingTop: '30px',
            paddingBottom: '30px',
            borderBottom: `1px solid rgba(200, 206, 214, 0.47)`,
        },
        denseStyle: {
            minHeight: '32px',
        },
    },
    rows: {
        style: {
            // position: 'relative',
        },
    },
    cells: {
        style: {
            fontFamily: 'ManropeThin',
        },
    },
    headCells: {
        style: {
            fontSize: '12px',
            fontWeight: 600,
            paddingLeft: '16px',
            color: COLORS.GRAY,
            paddingRight: '16px',
        },
        activeSortStyle: {
            color: COLORS.GRAY,
            '&:focus': {
                outline: 'none',
            },
        },
        inactiveSortStyle: {
            '&:focus': {
                outline: 'none',
            },
        },
    },
}

const prepareColumns = (columns: any[], keys: string[] = []) => columns.map((col, i) => {
    return {
        ...col,
        style: {
            ...col.style,
            fontWeight: keys.includes(col.selector) ? 600 : 500,
            fontSize: 13,
            paddingTop: 18,
            paddingBottom: 18,
        },
    }
});

export function Table<T>(props: IProps<T>) {
    const [isMobile] = useObservable(uiQuery.isMobile$);
    const {
        title,
        clearedSelectionRows = false,
        contextActions,
        onRowClicked,
        onSelectionChange,
        selectableRowSelected,
        highlightRow,
        columns,
        data,
        onSort,
        onChangePage,
        striped = false,
        pagination = true,
        expandableRows = false,
        noTableHead = false,
        highlightOnHover = true,
        expandableRowsComponent,
        totalRecordsNumber,
        loading = false,
        conditionalRowStyles = [],
        onChangeRowsNumber,
        expandableRowExpanded,
        onRowExpandToggled,
        paginationPerPage = 10,
        activePage,
        pushBottom = true,
        paginationServer = true,
        paginationServerOptions = {
            persistSelectedOnPageChange: true, 
            persistSelectedOnSort: true,
        },
        noDataText,
        scrollable,
    } = props;

    const klass = classNames({
        [CssClasses.table]: true,
        [CssClasses.table_no_title]: !!title,
        [CssClasses.table_caption]: scrollable,
    });

    const preparedColumns = useMemo(() => prepareColumns(columns, highlightRow), [columns, highlightRow]);

    const numeratedData: Array<T & INumerated> = useMemo(() => numerate<T>(data), [data])

    const handleSelectedRowsChange = useCallback((args) => {
        if (onSelectionChange) {
            onSelectionChange(args);
        }
    }, []);

    const handleOnSort = useCallback(async (column: any, sortDirection: any) => {
        if (typeof onSort === "function") {
            onSort(column, sortDirection, paginationPerPage, activePage);
        }
    }, [onSort, paginationPerPage, activePage]);

    const handleRowClick = useCallback((item: T) => {
        if (typeof onRowClicked === 'function') {
            onRowClicked(item, activePage as number, paginationPerPage as number);
        }
    }, [activePage, paginationPerPage, onRowClicked]);

    const handleChangePage = useCallback((page: number, totalRows: number) => {
        if (typeof onChangePage === "function") {
            onChangePage(page, totalRows)
        }
    }, [onChangePage]);

    return !numeratedData.length
        ? (
            <div style={isMobile ? {
                display: "flex",
                padding: "34px 0",
                width: "100%",
                fontSize: "13px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                justifyContent: "center",
            } : {
                padding: '24px',
                width: '100%',
                textAlign: "center",
                // whiteSpace: 'nowrap',
            }}>
                <div>{  NO_DATA }</div>
                <div>{ noDataText }</div>
            </div>
        )
        : (
            <Fragment>
                <DataTable
                    onRowClicked={handleRowClick}
                    className={klass}
                    title={title}
                    onSelectedRowsChange={handleSelectedRowsChange}
                    selectableRowSelected={selectableRowSelected}
                    noTableHead={noTableHead}
                    expandableRows={expandableRows}
                    expandableRowsComponent={expandableRowsComponent}
                    expandableInheritConditionalStyles
                    expandableIcon={{
                        collapsed: <IconHolder>
                            <ExpandMore />
                        </IconHolder>,
                        expanded: <IconHolderExpaned>
                            <ExpandMore />
                        </IconHolderExpaned>
                    }}
                    expandableRowsHideExpander
                    expandableRowExpanded={expandableRowExpanded}
                    onRowExpandToggled={onRowExpandToggled}
                    // expandableRowsHideExpander
                    // selectableRowsVisibleOnly
                    contextActions={contextActions}
                    pointerOnHover={!!onRowClicked}
                    columns={preparedColumns}
                    sortServer={!!onSort}
                    striped={striped}
                    onSort={handleOnSort}
                    noHeader={!title}
                    clearSelectedRows={clearedSelectionRows}
                    theme="custom_default"
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={onChangeRowsNumber}
                    data={numeratedData}
                    customStyles={customStyles}
                    progressPending={loading}
                    conditionalRowStyles={conditionalRowStyles}
                    selectableRows={!!onSelectionChange}
                    highlightOnHover={highlightOnHover}
                    paginationDefaultPage={(activePage as number) + 1}
                    pagination={pagination}
                    paginationServer={paginationServer}
                    paginationTotalRows={totalRecordsNumber}
                    paginationPerPage={paginationPerPage}
                    paginationServerOptions={paginationServerOptions}
                    selectableRowsComponent={TableCheckbox}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    noDataComponent={
                        <div style={isMobile ? {
                            display: "flex",
                            padding: "34px 0",
                            width: "100%",
                            fontSize: "13px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            justifyContent: "center",
                        } : {
                            padding: '24px',
                            width: '100%',
                            // whiteSpace: 'nowrap',
                        }}>
                            <div>{  NO_DATA }</div>
                            <div>{ noDataText }</div>
                        </div>
                    }
                />
                {
                    !pagination && pushBottom && <div style={{width: '100%', height: '40px', background: 'white'}} />
                }
            </Fragment>
        )
}