import { createEntityQuery } from "@datorama/akita";
import { sortBy } from "lodash";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { prospectsSearchQuery } from "./prospects-search/prospects-search.query";
import { 
    getFilteredProspects,
} from "./prospects.filter";
import { IProspectsState, prospectsStore } from "./prospects.store";

export const pq = createEntityQuery<IProspectsState>(prospectsStore);

export const prospectsCount$ = pq.select(state => state.prospectsCount);

export const prospects$ = pq.selectAll();

export const selectFilteredProspects$ = combineLatest([
    prospectsSearchQuery.appliedFilters$,
    pq.selectAll(),
]).pipe(
    map(([searchCriteria, prospects]) => getFilteredProspects({
        searchCriteria, 
        prospects,
    }))
);

export const isLoading$ = pq.selectLoading();
export const currentProspect$ = pq.select(state => state.selectedProspect);

export const cities$ = pq.select(state => state.cities);
//* Pickup Locations
export const pickupLocations$ = pq.select(state => sortBy(state.pickupLocations, ['id']));
export const isPickupLocationsLoading$ = pq.select(state => state.isPickupLocationsLoading);

export const messageHistory$ = pq.select(state => state.messageHistory);

export const selectProspectsTotal$ = pq.select(state => state.inititalProspectsTotal);
export const selectProspectsImportState$ = pq.select(state => state.isProspectsImporting);
export const selectLastImportedProspects$ = pq.select(state => state.lastImportedProspects);

export const prospectsQuery = {
    prospects$,
    prospectsCount$,
    selectFilteredProspects$,
    isLoading$,
    currentProspect$,
    cities$,
    pickupLocations$,
    isPickupLocationsLoading$,
    messageHistory$,
    selectProspectsTotal$,
    selectProspectsImportState$,
    selectLastImportedProspects$,
};