import { createEntityQuery } from "@datorama/akita";
import { IStaffState, staffStore } from "./staff.store";

export const staffQuery = createEntityQuery<IStaffState>(staffStore);

export const staff$ = staffQuery.selectAll({
    filterBy: ({ isDeleted }) => !isDeleted,
});

export const staffCount$ = staffQuery.select(state => state.staffCount);
export const selectedStaffs$ = staffQuery.select(state => state.selectedStaffs);

export const isLoading$ = staffQuery.selectLoading();
export const isUploading$ = staffQuery.select(state => state.uploadLoading);

export const currentStaff$ = staffQuery.select(state => state.selectedStaff);
export const hasActiveStaff = staffQuery.hasActive();

//* Prospects Leading
export const prospectsLeading$ = staffQuery.select(state => state.prospectsLeading);
export const prospectsLeadingTotal$ = staffQuery.select(state => state.prospectsLeadingTotal);
export const loadingProspects$ = staffQuery.select(state => state.loadingProspects);