import { createEntityStore, EntityState } from "@datorama/akita";
import { ISort } from "../../properties/search/search-properties.store";
import { IStaff } from "../models/staff.inreface";

export interface INotificationMethod {
    id: number | string;
    name: string;
}

export interface IStaffSearchCriteria {
    search: string;
    roles: string[];
    prospectsLeadingF: number;
    prospectsLeadingT: number;
    prospectsLeading: {
        from: number | null;
        to: number | null;
    };
}

export interface ISearchStaffState extends EntityState {
    searchData: {
        roles: string[];
    };
    selectedCriteria: IStaffSearchCriteria;
    sort: ISort<keyof IStaff>;
}

export const searchStaffInitialState: ISearchStaffState = {
    searchData: {
        roles: [],
    },
    selectedCriteria: {
        search: '',
        roles: [],
        prospectsLeadingF: 0,
        prospectsLeadingT: 0,
        prospectsLeading: {
            from: null,
            to: null,
        }
    },
    sort: {
        field: 'email',
        type: 'desc'
    }
}

export const searchStaffStore = createEntityStore<ISearchStaffState>(searchStaffInitialState, {
    name: 'staff_search'
});
