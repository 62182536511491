import React, {useCallback, useState} from "react";
import CssClasses from './forgot-password.module.scss';
import {Header4, Header6} from "../../../ui/components/headers";
import {InputLabel} from "../../../ui/components/forms/labels/label";
import {Input} from "../../../ui/components/forms/inputs/input";
import {Button, ButtonVariant} from "../../../ui/components/buttons/button";
import {RouteComponentProps} from 'react-router-dom';
import {RoutesEnum} from "../../../routing/routes";
// import {userService} from "../../../../store/user/user.service";
import { authService } from "src/store/auth/auth.service";

interface IProps extends RouteComponentProps {

}

export function ForgotPassword({history}: IProps) {


    const [email, setEmail] = useState('');
    const onSubmit = useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        await authService.sendPasswordReset(email);

        history.push({
            pathname: RoutesEnum.FORGOT_PASSWORD_EMAIL_SENT,
            search: `?email=${email}`,
        });
    }, [history, email]);

    const onBack = () => {
        history.push(RoutesEnum.SIGN_IN);
    }

    const changeEmail = (e: React.ChangeEvent<any>) => {
        setEmail(e.target.value);
    }

    return <div className={CssClasses.forgot_password}>
        <Header4>
            Please enter you email
        </Header4>
        <Header6 style={{fontWeight: 300, textAlign: 'center'}}>
            You will receive a link for changing your password
        </Header6>
        <form onSubmit={onSubmit} className={CssClasses.forgot_password_form}>
            <InputLabel style={{width: '100%'}} htmlFor="email">
                Email:
            </InputLabel>
            <Input onChange={changeEmail} id="email" placeholder="fancy_email@mail.com" style={{width: '100%'}} />
            <Button style={{width: '100%', marginTop: 20}} btnVariant={ButtonVariant.PRIMARY} text="Send" />
        </form>
        <Button btnVariant={ButtonVariant.OUTLINE} style={{width: '100%', marginTop: 20}} onClick={onBack} text="Back To Sign In" />
    </div>
}
