import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { IStaffColor } from 'src/store/admin-settings/models/calendar.interface';
import { IStaff } from 'src/store/staff/models/staff.inreface';
import { Avatar, FallbackType } from 'src/components/ui/components/avatar/avatar';

import styles from '../admin.module.scss';
import { EditIcon } from 'src/components/ui/icons';

interface IUserColorItemProps {
    user: IStaff;
    randomColor?: string;
    color: IStaffColor | {};
    selectedColor: IStaffColor;
    handleOpen: (user: IStaff, sColor: string) => void;
    handleClose: () => void;
}

export const UserColorItem: React.FC<IUserColorItemProps> = ({ user, color, randomColor, handleOpen }) => {
    const [currentColor, setCurrentColor] = useState<any>(color ? (color as IStaffColor).color : randomColor);
    const [isHovered, setIsHovered] = useState(false);

    const handleModalOpen = (sColor: IStaffColor) => {
        if (!isEmpty(sColor)) {
            setCurrentColor(sColor);
            handleOpen(user, sColor.color);
        }
    };

    return (
        <>
            <div className={styles.list_row}>
                <div className={styles.list_userName}>
                    <Avatar
                        src={user.photo} 
                        fallback={user.name}
                        fallbackType={FallbackType.ABBR} />
                    { user.name }
                </div>
                <div 
                    className={styles.list_userColor}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{ backgroundColor: currentColor?.color 
                        ? currentColor.color 
                        : !isEmpty(color) 
                            ? (color as IStaffColor).color 
                            : randomColor
                        }}
                    onClick={() => handleModalOpen(color as IStaffColor)}>
                        { isHovered ? <EditIcon /> : null }
                    </div>
            </div>
        </>
    );
}