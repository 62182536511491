import { QueryEntity } from "@datorama/akita";
// import { ITemplate } from "../models/template.interface";
import { ITemplateState, TemplateStore, templateStore } from "./template.store";

export class TemplateQuery extends QueryEntity<ITemplateState> {
    templates$ = this.selectAll();
    activeEntity$ = this.selectActive();
    loading$ = this.selectLoading();

    constructor(protected store: TemplateStore) {
        super(store);
    }
}

export const templateQuery = new TemplateQuery(templateStore);