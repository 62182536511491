import {Panel, PanelType} from "../../../../../ui/components/panel";
import CssClasses from "../tenants.module.scss";
import {SearchInput, SearchInputType} from "../../../../../ui/components/forms/inputs/search";
import {Button, ButtonVariant} from "../../../../../ui/components/buttons/button";
import {Add, ChevronLeft, Delete} from "@material-ui/icons";
import React, {ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useObservable} from "@libreact/use-observable";
// import {tenantGroupsQuery} from "../../../../../../store/tenants/groups/tenant-groups.query";
import {Table} from "../../../../../ui/components/table";
import {useHistory, useLocation} from 'react-router-dom';
// import {IDataTableColumn} from "react-data-table-component";
import {tenantGroupsService} from "../../../../../../store/tenants/groups/tenant-groups.service";
import {IGroup} from "../../../../../../store/tenants/groups/models/group.interface";
import {INumerated} from "../../../../../../store/properties/models/numerated.interface";
import {ITenant} from "../../../../../../store/tenants/models/tenant.interface";
import {COLORS} from "../../../../../ui/theme/variables/colors";
import { MessageIcon } from "src/components/ui/icons";
import { ISelectedData } from "src/store/utils/common.interface";
import { messageService, RecieverType } from "src/store/message/message.service";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { TitleHolder } from "src/components/containers/properties/pages/list";
import { usePaging } from "src/store/common/hooks/use-paging";
// import { useGroups } from "src/store/tenants/hooks/use-groups";
import { PagingType } from "src/store/common/models/common.interface";

const conditionalRowStyles = [
    {
        // actively renting & i % 2 === 0
        when: (row: INumerated & ITenant) => row.i % 2 === 0,
        style: {
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    }
];


const columns = (clickHandler: (e: ChangeEvent<any>) => void) => [
    {
        name: 'Group Name',
        selector: 'name',
        sortable: true,
        minWidth: '150px',
        maxWidth: '400px',
        cell: (row: IGroup) => {
            return (
                <TitleHolder onClick={clickHandler} id={String(row.id)}>{row.name}</TitleHolder>
            );
        }
    },
    // {
    //     name: 'Tenants',
    //     selector: 'tenantNames',
    //     sortable: false,
    //     minWidth: '150px',
    //     cell: (row: IGroup) => {
    //         return <div>
    //             {row.tenantNames.map((r, id) => <div key={r + id}>
    //                 {r}
    //             </div>)}
    //         </div>
    //     }
    // },
    // {
    //     name: 'Phone Number',
    //     selector: 'phoneNumbers',
    //     sortable: false,
    //     minWidth: '150px',
    //     cell: (row: IGroup) => {
    //         return <div>
    //             {row.emails.map((r, id) => <div key={r + id}>
    //                 {r}
    //             </div>)}
    //         </div>
    //     }
    // },
    // {
    //     name: 'Email',
    //     selector: 'emails',
    //     sortable: false,
    //     minWidth: '150px',
    //     cell: (row: IGroup) => {
    //         return <div>
    //             {row.emails.map((r, id) => <div key={r + id}>
    //                 {r}
    //             </div>)}
    //         </div>
    //     }
    // },
    {
        name: 'Notes',
        selector: 'notes',
        sortable: true,
        minWidth: '150px',
    },
];

interface IGroupLocation {
    from: string;
    selectType: string;
}

interface ITenantGroups {
    groups: IGroup[];
    isLoading: boolean;
}

const TenantGroupsComponent: React.FC<ITenantGroups> = ({ groups, isLoading }) => {
    const location = useLocation<IGroupLocation>();
    const [selectMode] = useState(location.state?.selectType);

    // const [groups, isLoading] = useGroups();
    const [paging, setPaging, pushLocation] = usePaging(PagingType.GROUPS);

    const history = useHistory();
    // const [count] = useObservable(tenantGroupsQuery.recordsCount$);

    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedGroups, setSelectedGroups] = useState<IGroup[]>();

    const [search, setSearch] = useState<string>('');

    const [isMobile] = useObservable(uiQuery.isMobile$);

    const smallMargin: React.CSSProperties = {
        marginLeft: 10,
    };

    useEffect(() => {
        return () => {
            tenantGroupsService.setGroupsTotal(groups.length);
        }
    }, []);

    const filteredGroups = useMemo(() => {
        let filtered: any = null;

        if (search) {
            const searchQ = search.toLowerCase().trim();

            filtered = groups.filter(group => {
                if (
                    group.name.toLowerCase().includes(searchQ)
                ) {
                    return group;
                }
            });

            return filtered;
        }

        return groups;
    }, [groups, search]);

    useEffect(() => {
        tenantGroupsService.setGroupsTotal(filteredGroups.length);
    }, [filteredGroups]);

    const onSearchChange = (q: string) => {
        // tenantGroupsService.changeSearch(q);
        setSearch(q);
    }

    const goToView = (group: IGroup) => {
        pushLocation(`/profiles/tenants/groups/${group.id}`, PagingType.GROUPS);
    }

    const onCustomCellClick = useCallback((e: any) => {
        pushLocation(`/profiles/tenants/groups/${e.target.id}`, PagingType.GROUPS);
    }, [history, paging])

    const goToCreateTenantGroup = () => {
        history.push(`/profiles/tenants/groups/new`);
    }

    const handleSelectionChange = (data: ISelectedData<IGroup>) => {
        setSelectedCount(data.selectedCount);
        setSelectedGroups(data.selectedRows)
    }

    // const onSort = (column: IDataTableColumn<IGroup & INumerated>, sortDirection: "desc" | "asc") => {
    //     tenantGroupsService.setSorting({
    //         type: sortDirection,
    //         field: column.selector as keyof IGroup,
    //     });
    //     tenantGroupsService.fetchGroups();
    // }

    const handleAdd = () => {
        if (selectedGroups?.length) {
            messageService.selectRecievers(RecieverType.CUSTOM_GROUPS, selectedGroups);

            history.push('/message/prospects', { 
                from: 'groups', 
                backLink: '/profiles/tenants?tab=custom_tenant_groups',
                category: 'profiles',
            });
        }
    }

    const handleSendMessage = () => {
        if (selectedGroups?.length) {
            messageService.selectRecievers(RecieverType.CUSTOM_GROUPS, selectedGroups);
            tenantGroupsService.resetCurrentGroup();;
            history.push('/message', { 
                from: 'groups', 
                backLink: 'tenants?tab=custom_tenant_groups',
                category: 'profiles', 
            });
        }
    }

    const changeRowsNumber = (rowPerPage: number, page: number) => {
        setPaging(page, rowPerPage);
        // tenantGroupsService.fetchGroups(page - 1, rowPerPage);
    }

    const changePage = (page: number) => {
        // table component numerating start from 1, BE expects 0 as the first page
        setPaging(page, paging.rowsPerPage);
        // tenantGroupsService.fetchGroups(page - 1, paging.rowsPerPage);
    }

    // console.log('tenantGroupsService -> ', filteredGroups);

    return <Fragment>
        {
            location.state?.from
            ? (
                <Panel type={PanelType.EMPTY}>
                    <div className={CssClasses.link_wrapper} 
                        onClick={() => history.push(location.state.from, { 
                            from: 'groups', 
                            backLink: 'tenants?tab=custom_tenant_groups',
                            category: 'profiles',
                        })}>
                        <ChevronLeft /> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
                    </div>  
                </Panel>
            ) : null
        }
        <Panel type={PanelType.EMPTY}>
            {
                isMobile
                    ? (
                        <div className={CssClasses.list_action_bar_mobile}>
                            <Button
                                icon={<MessageIcon />}
                                btnVariant={ButtonVariant.OUTLINE}
                                text=""
                                onClick={() => history.push('/message', { 
                                    from: 'groups', 
                                    backLink: 'tenants?tab=custom_tenant_groups',
                                    category: 'profiles',
                                })}
                            />
                            <Button
                                icon={<Add style={{fontSize: 14}}/>}
                                btnVariant={ButtonVariant.PRIMARY}
                                onClick={goToCreateTenantGroup}
                                text="Add group"
                            />
                        </div>
                    ) : null
            }
            <div className={CssClasses.list_action_bar}>
                <div className={CssClasses.list_action_bar_left}>
                    <SearchInput
                        inputType={SearchInputType.PRIMARY}
                        onChange={onSearchChange}
                        placeholderText="Search..."
                    />
                </div>
                    {
                        selectMode
                            ? (
                                <Button
                                    onClick={handleAdd}
                                    icon={<Add style={{fontSize: 14}}/>}
                                    btnVariant={ButtonVariant.PRIMARY}
                                    text={`Add to the list ${selectedCount ? `:${selectedCount}` : ''}`}
                                />
                            )
                            : !isMobile
                                ? (
                                    <div className={CssClasses.list_action_bar_right}>
                                        <Button
                                            icon={<MessageIcon />}
                                            style={smallMargin}
                                            btnVariant={ButtonVariant.OUTLINE}
                                            text="Message"
                                            onClick={() => history.push('/message', { 
                                                from: 'groups', 
                                                backLink: 'tenants?tab=custom_tenant_groups',
                                                category: 'profiles',
                                            })}
                                        />
                                        <Button
                                            icon={<Add style={{fontSize: 14}}/>}
                                            style={smallMargin}
                                            btnVariant={ButtonVariant.PRIMARY}
                                            text="Add Tenant Group"
                                            onClick={goToCreateTenantGroup}
                                        />
                                    </div>
                                )
                                : null
                    }
            </div>
        </Panel>
        {(selectedCount > 0 && !selectMode) && <Panel additionalStyles={
            isMobile ? { 
                margin: '0 0 15px',
                width: '97.5%',
            } : {}
        }>
            <div className={CssClasses.list_action_props_selected}>
                <div className={CssClasses.list_action_bar_left}>
                    {`${selectedCount} Selected`}
                </div>
                <div className={CssClasses.selected_action_bar_right}>
                    <Button
                        icon={<MessageIcon />}
                        btnVariant={ButtonVariant.OUTLINE}
                        text={ isMobile ? '' : 'Message' }
                        onClick={handleSendMessage}
                    />
                </div>
            </div>
        </Panel>}
        <Panel noPadding sideBorder>
            <Table<IGroup>
                conditionalRowStyles={conditionalRowStyles}
                // selectableRowSelected={(row: any) => row.isSelected}
                onSelectionChange={handleSelectionChange}
                contextActions={[
                    <div className={CssClasses.delete_properties}>
                        <Delete/> Delete Selected
                    </div>
                ]}
                onChangePage={changePage}
                totalRecordsNumber={filteredGroups.length}
                onRowClicked={goToView}
                paginationServer={false}
                paginationServerOptions={{
                    persistSelectedOnPageChange: false, 
                    persistSelectedOnSort: false,
                }}
                paginationPerPage={paging.rowsPerPage}
                onChangeRowsNumber={changeRowsNumber}
                columns={columns(onCustomCellClick)}
                // onSort={onSort}
                loading={isLoading}
                data={filteredGroups}
                highlightRow={['firstName', 'lastName']}
                activePage={paging.activePage}
            />
        </Panel>
    </Fragment>
}

export const TenantGroups = React.memo(TenantGroupsComponent, (prevProps, nextProps) => {
    if (prevProps.isLoading !== nextProps.isLoading) {
        return false;
    }

    if (prevProps.groups.length === nextProps.groups.length) {
        return true;
    }

    return false;
});