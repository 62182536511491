import React, { ChangeEvent, useMemo } from 'react';
import { guid } from '@datorama/akita';

import styles from '../admin.module.scss';

export interface IColorItemProps {
    color: string;
    selectedColor: string;
    onColorChange: (color: string) => void;
}

export const ColorItem: React.FC<IColorItemProps> = ({ color, selectedColor, onColorChange }) => {
    const checked = useMemo<boolean>(() => {
        return color === selectedColor;
    }, [selectedColor, color]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (typeof onColorChange === 'function') {
            onColorChange(e.target.value);
        }
    }

    return (
        <label 
            className={styles.itemColor}
            style={{ backgroundColor: color }}>
                <input 
                    id={guid()} 
                    className={styles.itemRadio}
                    name="color" 
                    type="radio" 
                    checked={checked}
                    value={color}
                    onChange={handleChange} />
                <span className={styles.itemControl}></span>
            </label>
    );
}