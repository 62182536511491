import {Panel, PanelType} from "../../../../ui/components/panel";
import React, {Fragment, useMemo, useState} from "react";
import {Header3} from "../../../../ui/components/headers";
import CssClasses from './tenants.module.scss';
import {useObservable} from "@libreact/use-observable";
import {tenantsQuery} from "../../../../../store/tenants/tenants.query";
import {ITab, Tabs} from "../../../../ui/components/tabs/tabs";
import {RoutesEnum} from "../../../../routing/routes";
import {Form, FormType, InputType} from "../../../../ui/components/forms/formBuilder/form";
import {Title} from "../../../../ui/components/forms/title";
import {RouteComponentProps, useLocation} from 'react-router-dom';

import {TenantGroups} from "./tabs/groups";

import {tenantGroupsQuery} from "../../../../../store/tenants/groups/tenant-groups.query";
import {PropertyGroups} from "./tabs/property-groups";
import {propertyGroupQuery} from "../../../../../store/tenants/property-groups/property-group.query";
import {TenantsList} from "./tabs/tenants-list";
import {ParseQueryParams} from "../../../../helpers/query-params-parser";
import { KeyIcon } from "src/components/ui/icons";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { ContentHolder } from "src/components/ui/main-layout/layout";
import { useGroups } from "src/store/tenants/hooks/use-groups";

import baseStyles from 'src/styles/base.module.scss';
import { formatLastImportDate } from "src/components/utils/formatDate";

interface IProps extends RouteComponentProps {}

interface ITenantsLocation {
    from: string;
    selectType: string;
}

export const Tenants: React.FC<IProps> = () => {
    const [tenantsTotal] = useObservable(tenantsQuery.tenantsTotal$);
    const [ft] = useObservable(tenantsQuery.selectFilteredTenants$);
    const [groupsPropertyTotal] = useObservable(propertyGroupQuery.groupsTotal$);
    const [groupsTotal] = useObservable(tenantGroupsQuery.groupsTotal$);

    const [groups, isLoading] = useGroups();

    // const [recordsNumber] = useObservable(tenantsQuery.tenantsCount$);
    // const [groupsCount] = useObservable(tenantGroupsQuery.recordsCount$);

    const [isMobile] = useObservable(uiQuery.isMobile$);

    const location = useLocation<ITenantsLocation>();
    const [selectMode] = useState(location.state?.selectType ? true : false);

    // const [propertyGroupsCount] = useObservable(propertyGroupQuery.groupsCount$);

    const [lastImported] = useObservable(tenantsQuery.lastImported$);
    const basePath = useMemo(() => RoutesEnum.TENANTS_LIST, []);
    const currentSubPath = location.search;

    const totalData = {
        total: ft.length,
    };

    const queryParams = ParseQueryParams<{backLink: string}>(currentSubPath);
    // console.log('ft -> ', ft);

    const tabs: ITab[] = [
        {
            tabName: 'Active Tenants',
            specificPath: '',
            basePath,
            children: <Fragment>
                <Title
                    icon={<KeyIcon />}
                    iconStyle={{ fontSize: '14px', width: '30px', height: '30px' }}
                    text="Active Tenants"
                />
                <Form<{ total: number }>
                    type={FormType.DEFAULT}
                    data={totalData}
                    readOnly
                    fields={{
                        total: {
                            label: 'Total',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        }
                    }}
                />
            </Fragment>
        },
        {
            tabName: 'Custom Tenant Groups',
            specificPath: 'custom_tenant_groups',
            basePath,
            children: <Fragment>
                <Title
                    icon={<KeyIcon />}
                    iconStyle={{ fontSize: '14px', width: '30px', height: '30px' }}
                    text="Tenant Groups"
                />
                <Form<{ total: number }>
                    type={FormType.DEFAULT}
                    data={{total: groupsTotal}}
                    readOnly
                    fields={{
                        total: {
                            label: 'Total',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        }
                    }}
                />
            </Fragment>
        },
        {
            tabName: 'Property Groups',
            specificPath: 'property_groups',
            basePath,
            children: <Fragment>
                <Title
                    icon={<KeyIcon />}
                    iconStyle={{ fontSize: '14px', width: '30px', height: '30px' }}
                    text="Property Groups"
                />
                <Form<{ total: number }>
                    type={FormType.DEFAULT}
                    data={{total: groupsPropertyTotal}}
                    readOnly
                    fields={{
                        total: {
                            label: 'Total',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        }
                    }}
                />
            </Fragment>
        }
    ];

    const currentSelection: 'tenants' | 'tenantGroups' | 'propertyGroups' = useMemo(() => {
        if (currentSubPath.includes('?tab=custom_tenant_groups')) {
            return 'tenantGroups';
        } else if (currentSubPath.includes('?tab=property_groups')) {
            return 'propertyGroups'
        } else {
            return 'tenants';
        }
    }, [currentSubPath]);

    const hasBackLink = useMemo(() => {
        return currentSubPath.includes('backLink');
    }, [currentSubPath]);

    return <ContentHolder noPadding smallPadding>
        {
            (!hasBackLink && !selectMode) && <Panel type={PanelType.EMPTY}>
                {
                    !isMobile
                        ? (
                            <div className={CssClasses.header_wrapper}>
                                <Header3 style={{margin: 0, display: 'flex', alignItems: 'center'}}>
                                    Tenants
                                    <span className={baseStyles.total}>
                                        ({tenantsTotal})
                                    </span>
                                </Header3>
                                {
                                    lastImported
                                        ? (
                                            <div className={CssClasses.last_imported}>
                                                {formatLastImportDate(lastImported as string)}
                                            </div>
                                        ): null
                                }
                            </div>
                        )
                        : null
                }
            </Panel>
        }

        {
            (!hasBackLink && !selectMode) && <Tabs
                tabs={tabs}
            />
        }

        {
            currentSelection === 'tenants' && <TenantsList
                backLink={queryParams.backLink}
                onSubmit={hasBackLink ? () => {console.log('success!')} : undefined}
            />
        }
        {
            currentSelection === 'tenantGroups' && <TenantGroups groups={groups} isLoading={isLoading} />
        }
        {
            currentSelection === 'propertyGroups' && <PropertyGroups
                backLink={queryParams.backLink}
                onSubmit={hasBackLink ? () => {console.log('success!')} : undefined} />
        }

    </ContentHolder>
}