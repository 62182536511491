import { useObservable } from "@libreact/use-observable";
import { useEffect } from "react";
import { adminSearchQuery } from "../admin-search/admin-search.query";
import { adminSearchService } from "../admin-search/admin-search.service";
import { IAdminSearchCriteria } from "../admin-search/admin-search.store";
import { AdminSearchType, adminSettingsService } from "../admin-settings.service";

export type AdminSearchHookTuple = [IAdminSearchCriteria, (q: string) => void];

export function useAdminSearch (searchType: AdminSearchType): AdminSearchHookTuple {
    const [searchCriteria] = useObservable(adminSearchQuery.filterCriteria$);

    useEffect(() => {
        adminSettingsService.searchInit(searchType);
    }, []);

    const changeSearch = (q: string) => {
        adminSearchService.changeSearchQ(q);
    }

    return [searchCriteria, changeSearch];
}