import React, { ChangeEvent, Fragment } from 'react'
import InputMask from 'react-input-mask';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form'
import { StaffDto } from '../staff-editor';
import { Input } from 'src/components/ui/components/forms/inputs/input';
import { InfoIcon } from 'src/components/ui/icons';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';

import editorStyles from '../staff-editor.module.scss'
import { StaffRole } from 'src/store/staff/models/staff.inreface';


interface IContactDetailsProps {
    staff: Partial<StaffDto>;
    formState: Partial<StaffDto>;
    handleSelectChange: (key: keyof StaffDto) => (e: ChangeEvent<HTMLInputElement>) => void;
    handleValuesChange: (data: Partial<StaffDto>) => void;
}

export const ContactDetails: React.FC<IContactDetailsProps> = ({ 
    formState,
    handleSelectChange,
    handleValuesChange }) => {

    return (
        <Fragment>
            <FormHolder
                title="Contact Details"
                icon={<InfoIcon />}>
                <Form<Partial<StaffDto>>
                    type={FormType.DEFAULT}
                    data={{
                        ...formState,
                        hstN: formState.hstN === 0 ? null : formState.hstN,
                    }}
                    onChange={handleValuesChange}
                    fields={{
                        email: {
                            index: 0,
                            label: 'Email',
                            labelDisplay: 'block',
                            fieldType: InputType.STRING,
                            height: 'auto',
                            placeholder: "Email",
                        },
                        phoneNumber: {
                            index: 1,
                            label: 'Mobile Number',
                            labelDisplay: 'block',
                            placeholder: 'Mobile Number',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            height: 'auto',
                            customElement: <InputMask 
                                className={editorStyles.input}
                                mask="(999) 999-9999" 
                                value={formState.phoneNumber}
                                placeholder="(999) 999-9999" 
                                onChange={handleSelectChange('phoneNumber')} >
                                    <Input />
                            </InputMask>
                        },
                        alternNumber: {
                            index: 2,
                            label: 'Altern. number',
                            labelDisplay: 'block',
                            placeholder: 'Altern. number',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            height: 'auto',
                            customElement: <InputMask 
                                className={editorStyles.input}
                                mask="(999) 999-9999" 
                                value={formState.alternNumber}
                                placeholder="(999) 999-9999" 
                                onChange={handleSelectChange('alternNumber')} >
                                    <Input />
                            </InputMask>
                        },
                        hstN: {
                            index: 3,
                            label: 'HST N.',
                            labelDisplay: 'block',
                            fieldType: InputType.NUMBER,
                            height: 'auto',
                            placeholder: "HST N.",
                            wrapperStyles: {
                                display: formState.role !== StaffRole.AGENT ? 'none' : 'flex',
                            },
                            inputStyles: {
                                margin: '0 -10px 0 0',
                            },
                        },
                    }}
                />
            </FormHolder>
        </Fragment>
    );
}