import { ChevronLeft } from '@material-ui/icons';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { ContactDetails } from '../prospect-editor/tabs/contact-details';
import { RoutesEnum } from 'src/components/routing/routes';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { ITab, Tabs } from 'src/components/ui/components/tabs/tabs';
import { IProspect, LeadStatus } from 'src/store/prospects/models/prospect.interface';

import { useObservable } from '@libreact/use-observable';
import { prospectsQuery } from 'src/store/prospects/prospects.query';
import { prospectsService } from 'src/store/prospects/prospects.service';
import { staffService } from 'src/store/staff/staff.service';
import { staff$ } from 'src/store/staff/staff.query';
import { BlockingLoader } from 'src/components/ui/components/loaders/blocking-loader';
import {appointmentService} from "../../../../../store/appointment/appointment.service";
import { ProspectIcon, SaveIcon } from 'src/components/ui/icons';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { ContentHolder, headMobileStyles, panelMobileStyles } from 'src/components/ui/main-layout/layout';

import styles from '../prospect-info/prospect-info.module.scss'
import baseStyles from 'src/styles/base.module.scss';
import { uiQuery } from 'src/store/ui-storage/ui.query';

const STAFF_PAGE = 0;
const STAFF_SIZE = 9999;

interface IProspectEditorProps extends RouteComponentProps<{id: string}> {
    prospect: ProspectDto;
}

export interface ProspectDto {
    id?: number | string;
    agentAttachedId?: number | string;
    agentShowedId: number | string;
    approxBudgetFrom: number;
    approxBudgetTo: number;
    bedrN: number;
    cityId: number | string;
    email: string;
    firstName: string;
    isDeleted: boolean;
    lastName: string;
    leadStatus: LeadStatus;
    neighborhoodId: number;
    notificationIds: number[] | string[];
    phoneNumber: string;
    pickupLocation?: string;
    pickupLocationId: number;
    seenBefore: boolean;
    notification?: any;
    location?: any;
    approxBudget?: {
        from: number;
        to: number;
    },
    notes?: string;
}

const initialFormState: ProspectDto = {
    agentAttachedId: "",
    agentShowedId: "",
    approxBudgetFrom: 0,
    approxBudgetTo: 0,
    approxBudget: {
        from: 0,
        to: 0,
    },
    bedrN: 1,
    cityId: 1,
    email: '',
    firstName: '',
    isDeleted: false,
    lastName: '',
    leadStatus: LeadStatus.FOR_FOLLOW_UP,
    neighborhoodId: 0,
    notificationIds: [],
    phoneNumber: '',
    location: 'LONDON',
    pickupLocation: 'Alumni House',
    pickupLocationId: 1,
    seenBefore: false,
    notes: '',
};

interface IProspectLocation {
    from: string;
    selectType: string;
    backLink: string;
}

export const ProspectEditor: React.FC<IProspectEditorProps> = ({ history, match, prospect }) => {
    const location = useLocation<IProspectLocation>();
    const [editMode] = useState(!!match.params.id && location.pathname.includes('edit'));
    const [currentProspect] = useObservable(prospectsQuery.currentProspect$);
    const [formState, setFormState] = useState<Partial<ProspectDto>>(initialFormState);
    const [cities] = useObservable(prospectsQuery.cities$);
    const [pickupLocations] = useObservable(prospectsQuery.pickupLocations$);

    const [staff] = useObservable(staff$);

    const [isLoading] = useObservable(prospectsQuery.isLoading$);
    const [isPickupLocationsLoading] = useObservable(prospectsQuery.isPickupLocationsLoading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

    useEffect(() => {
        prospectsService.fetchCities();
        prospectsService.fetchPickupLocations();
        staffService.fetchStaff(STAFF_PAGE, STAFF_SIZE);

        if (!currentProspect && editMode) {
            prospectsService.fetchProspectById(Number(match.params.id));
        }

        return () => {
            if (!history.location.pathname.includes('edit')) {
                prospectsService.resetCurrentProspect();
            }
        }
    }, []);

    useEffect(() => {
        if (currentProspect) {
            setFormState(() => ({
                ...initialFormState,
                ...currentProspect,
                agentAttachedId: currentProspect?.agentAttachedId || '',
                agentShowedId: currentProspect?.agentShowedId || '',
                approxBudget: {
                    from: currentProspect?.approxBudgetFrom as number,
                    to: currentProspect?.approxBudgetTo as number,
                },
            }));
        }
    }, [currentProspect]);

    const handleValuesChange = (data: Partial<ProspectDto>) => {
        setFormState((prevState) => ({
            ...prevState,
            ...data,
        }));
    }

    const handleSelectChange = useCallback((key: string) => (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();

        setFormState((prevState) => ({
            ...prevState,
            [key]: e.target.value,
        }))
    }, [formState])

    const handleSubmit = async () => {
        const { approxBudget, cityId, neighborhoodId, ...rest } = formState;

        const prospectDto = {
            ...rest,
            approxBudgetFrom: approxBudget?.from,
            approxBudgetTo: approxBudget?.to,
        }

        if (currentProspect) {
            const { agentAttached, agentShowed, notification, ...updatedProspectDto } = prospectDto as Partial<IProspect>;

            prospectsService.updateProspect({
                ...updatedProspectDto,
                agentShowedId: updatedProspectDto.seenBefore
                    ? updatedProspectDto.agentShowedId
                    : null,
            } as Partial<ProspectDto>);
        } else {
            const newProspect = await prospectsService.createProspect({
                ...prospectDto,
                agentShowedId: prospectDto.seenBefore
                    ? prospectDto.agentShowedId
                    : null,
            } as Partial<ProspectDto>);
            
            if (newProspect) {
                appointmentService.addProspect(newProspect);
            }

            if (params.get('redirectUrl')) {
                history.push(params.get('redirectUrl')!);

                return null;
            }

            prospectsService.resetCurrentProspect();
        }

        history.push('/profiles/prospects');
    }

    const tabs: ITab[] = [
        {
            basePath: location.pathname,
            specificPath: '',
            children: <ContactDetails
                formState={formState}
                handleSelectChange={handleSelectChange}
                handleValuesChange={handleValuesChange}
                cities={cities}
                pickupLocations={pickupLocations}
                isPickupLocationsLoading={isPickupLocationsLoading}
                staff={staff}
                prospect={formState} />,
            tabName: 'Contact Details'
        },
    ];

    if (editMode && (!currentProspect || isLoading)) {
        return <BlockingLoader />
    }

    return (
        <ContentHolder noPadding smallPadding>
            <Link className={baseStyles.link_wrapper} to={location.state?.from || RoutesEnum.PROSPECTS_LIST}>
                <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
            </Link>
            <Panel additionalStyles={
                isMobile ? headMobileStyles : { marginTop: 7 }
            } type={PanelType.EMPTY}>
                <div className={styles.top_wrapper}>
                    <Header3 style={{margin: '10px 0'}}>
                        { editMode ? 'Edit Prospect' : 'Add Prospect' }
                    </Header3>
                    {
                        !isMobile
                            ? (
                                <div className={styles.actionBarHolder}>
                                    <Button
                                        icon={<SaveIcon />}
                                        btnVariant={ButtonVariant.PRIMARY}
                                        onClick={handleSubmit}
                                        text="Save"
                                    />
                                </div>
                            )
                            : null
                    }
                </div>
            </Panel>
            <Panel additionalStyles={
                isMobile ? panelMobileStyles : {}
            }>
                <FormHolder
                    title="Prospect"
                    icon={<ProspectIcon />}>
                    <Form<Partial<ProspectDto>>
                        type={FormType.DEFAULT}
                        data={formState}
                        // wrapperStyles={{
                        //     margin: 0,
                        // }}
                        onChange={handleValuesChange}
                        fields={{
                            firstName: {
                                index: 0,
                                label: 'First Name',
                                labelDisplay: 'block',
                                fieldType: InputType.STRING,
                                height: 'auto',
                                placeholder: "First Name",
                            },
                            lastName: {
                                index: 1,
                                label: 'Last Name',
                                labelDisplay: 'block',
                                placeholder: 'Last Name',
                                fieldType: InputType.STRING,
                                height: 'auto',
                            },
                        }}
                    />
                </FormHolder>
            </Panel>
            <Tabs
                tabs={tabs}
            />
            {
                isMobile
                    ? (
                        <Panel type={PanelType.EMPTY}>
                            <div className={baseStyles.save_bar_mobile}>
                                <Button
                                    icon={<SaveIcon />}
                                    btnVariant={ButtonVariant.PRIMARY}
                                    onClick={handleSubmit}
                                    text="Save"
                                />
                            </div>
                        </Panel>
                    ) : null
            }
        </ContentHolder>
    );
}