import React, { Fragment, useMemo, useState } from 'react'
// import { Notes, VpnKey } from '@material-ui/icons'
import { Title } from 'src/components/ui/components/forms/title'
import { ITenant } from 'src/store/tenants/models/tenant.interface'
import { IGroup } from 'src/store/tenants/groups/models/group.interface'
import { Panel, PanelType } from 'src/components/ui/components/panel'
import { SearchInput, SearchInputType } from 'src/components/ui/components/forms/inputs/search'
import { tenantsQuery } from 'src/store/tenants/tenants.query'
import { useObservable } from '@libreact/use-observable'
import { IDataTableColumn } from 'react-data-table-component'
import { Table } from 'src/components/ui/components/table'
import { INumerated } from 'src/store/properties/models/numerated.interface'
import { tenantsService } from 'src/store/tenants/tenants.service'
// import { searchTenantsService } from 'src/store/tenants/search/search.service'
// import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form'
import { IProperty } from 'src/store/properties/models/property.interface'
import { uniqBy } from 'lodash'

import { KeyIcon, NoteIcon } from 'src/components/ui/icons'
import { COLORS } from 'src/components/ui/theme/variables/colors'
import { uiQuery } from 'src/store/ui-storage/ui.query'
import { panelMobileStyles } from 'src/components/ui/main-layout/layout'

import styles from '../tenant-group-info.module.scss'
import baseStyles from 'src/styles/base.module.scss';

const conditionalRowStyles = [
    {
        // actively renting & i % 2 === 0
        when: (row: INumerated & ITenant) => row.i % 2 === 0,
        style: {
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    }
];

const columns: IDataTableColumn<ITenant>[] = [
    {
        name: 'Name',
        selector: 'firstName',
        sortable: true,
        minWidth: '150px',
        cell: (row: ITenant) => {
            return <div>
                { `${row.firstName} ${row.lastName}` }
            </div>
        }
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Phone number',
        selector: 'mobilePhone',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Notification Method',
        selector: 'sms',
        sortable: true,
        minWidth: '150px',
        cell: (row: ITenant) => {
            return <div className={styles.notification_method}>
                {row.sms !== 'NO' && <div>
                    Phone,
                </div>}
                {row.email && <div>
                    Email
                </div>}
            </div>
        }
    },
];

const ROWS_PER_PAGE = 10;

interface IGroupDetailsProps {
    isFilterable?: boolean;
    group: IGroup;
}

const GroupDetails: React.FC<IGroupDetailsProps> = ({ group, isFilterable = true }) => {
    // const [tenants] = useObservable(tenantsQuery.tenants$);
    // const [totalTenants] = useObservable(tenantsQuery.tenantsCount$);
    const [isLoading] = useObservable(tenantsQuery.isLoading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    const [rowsPerPage] = useState(ROWS_PER_PAGE);
    const [search, setSearch] = useState('');

    const prepearedTenants = useMemo(() => {
        const propertiesTenants = group.properties?.reduce((prev: any, current: IProperty) => {
            if (current.tenants.length) {
                return [
                    ...prev,
                    ...current.tenants
                ];
            }

            return prev;
        }, []);

        const allTenants = uniqBy<ITenant>(propertiesTenants.concat(group.tenants), 'id');

        if (search) {
            return allTenants
                .filter(tenant => tenant.firstName.includes(search) ||
                    tenant.lastName.includes(search) || 
                    tenant.email.includes(search))
                .map(tenant => ({
                ...tenant,
                name: `${tenant.firstName} ${tenant.lastName}`,
            }));
        }

        return allTenants
            .map(tenant => ({
            ...tenant,
            name: `${tenant.firstName} ${tenant.lastName}`,
        }));
    }, [group.tenants, search]);

    const changeSearch = (q: string) => {
        setSearch(q);
    }

    // const onSort = (column: IDataTableColumn<ITenant & INumerated>, sortDirection: "desc" | "asc") => {
    //     searchTenantsService.setSorting({
    //         type: sortDirection,
    //         field: column.selector as keyof ITenant,
    //     });
    //     tenantsService.fetchTenants();
    // }

    const changePage = (page: number) => {
        // table component numerating start from 1, BE expects 0 as the first page
        tenantsService.fetchTenants(page - 1, rowsPerPage);
    }

    return (
        <Fragment>
            <Panel additionalStyles={
                isMobile ? {
                    ...panelMobileStyles,
                    margin: '-20px 0 0',
                    width: '100%',
                    borderRadius: '0 0 0 10px',
                } : { 
                margin: '-5px 0px',
                borderRadius: '0 0 10px 10px' }}>
                <Title
                    icon={<KeyIcon />}
                    iconStyle={{ fontSize: '14px', width: '30px', height: '30px' }}
                    text="Tenant List"
                />
            </Panel>
            <Panel type={PanelType.EMPTY} additionalStyles={{ margin: '27px 0' }}>
                <div className={baseStyles.list_action_bar}>
                    <div className={baseStyles.list_action_bar_left}>
                        <SearchInput
                            onChange={changeSearch}
                            inputType={SearchInputType.PRIMARY}
                            placeholderText="Search..."
                        />
                        {/* {isFilterable && <FavButton
                            style={{marginLeft: 15}}
                            icon={<SettingsIcon/>}
                            onClick={onOpen}
                            badge={allSelectedFilters.length > 0 ? allSelectedFilters.length.toString() : undefined}
                        />} */}
                    </div>
                </div>
            </Panel>
            <Panel noPadding sideBorder additionalStyles={{ margin: '27px 0' }}>
                <Table<ITenant>
                    // onSelectionChange={handleSelectionChange}
                    // onRowClicked={onRowClicked}'
                    totalRecordsNumber={group.tenants.length}
                    pagination={false}
                    columns={columns}
                    onChangePage={changePage}
                    conditionalRowStyles={conditionalRowStyles}
                    // onSort={onSort}
                    loading={isLoading}
                    data={prepearedTenants}
                    highlightRow={['firstName', 'lastName']}
                />
            </Panel>
            <Panel additionalStyles={
                isMobile ? panelMobileStyles : { margin: 0 }
            }>
                <div className={styles.flexHolder}>
                    <Title
                        icon={<NoteIcon />}
                        iconStyle={{ fontSize: '14px', width: '30px', height: '30px', borderRadius: '50%' }}
                        text="Notes"
                    />
                    <div className={styles.groupNotes}>
                        { group.notes }
                    </div>
                </div>
            </Panel>
        </Fragment>
    );
}

export default GroupDetails;