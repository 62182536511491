import { QueryEntity } from "@datorama/akita";
import { IMessageState, messageStore, MessageStore } from "./message.store";

export class MessageQuery extends QueryEntity<IMessageState> {
    emailFrom$ = this.select(state => state.emailFrom);
    sendToList$ = this.select(state => state.sendToList);
    //* Selected recievers
    selectedStaff$ = this.select(state => state.selectedStaff);
    selectedProspects$ = this.select(state => state.selectedProspects);
    selectedTenants$ = this.select(state => state.selectedTenants);
    selectedOwners$ = this.select(state => state.selectedOwners);
    selectedCustomTenantGroups$ = this.select(state => state.selectedCustomTenantGroups);
    selectedPropertyGroups$ = this.select(state => state.selectedPropertyGroups);
    //* -----------------------------------------------------------------
    templates$ = this.select(state => state.templatesList);
    selectedTemplate$ = this.select(state => state.selectedTemplate);
    isLoading$ = this.selectLoading();

    messageHistory$ = this.select(state => state.messageHistory);
    messageType$ = this.select(state => state.messageType);

    constructor(protected store: MessageStore) {
        super(store);
    }
}

export const messageQuery = new MessageQuery(messageStore);