import {appointmentStore, AppointmentStore, initialNewAppointment} from "./appointment.store";
import {IStatistics} from "./models/statistics.interface";
import {IOwnersSearchFilters} from "../owners/models/owners-search-filters.interface";
import {PagingStore} from "../utils/paging-store";
import {IPagedResponse} from "../properties/models/paged-response.interface";
import autobind from "autobind-decorator";
import {ISort} from "../properties/search/search-properties.store";
import {AppointmentType, IAppointment} from "./models/appointment.interface";
import {IAppointmentFilters} from "./models/filters.interface";
import {IInitializable} from "../portfolio/models/on-init.interface";
import {BehaviorSubject} from "rxjs";
import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";
import {IAppointmentFiltersDto} from "./models/appointments-filter.interface";
import moment from "moment";
import {GuestsDropdownValue, ICreateAppointment} from "./models/appointment-create.interface";
import {IAppointmentCreateDto} from "./models/appointment-create-dto.interface";
import {snackbarService} from "../snackbar/snackbar.service";
import {SnackbarType} from "../snackbar/snackbar.store";
import {INamedEntityWithType} from "../properties/models/named-entity.interface";
import {IProperty} from "../properties/models/property.interface";
import {IStaff} from "../staff/models/staff.inreface";
import {IProspect} from "../prospects/models/prospect.interface";
import {IExportParams} from "./models/export-params.interface";
import { get, isEmpty, isEqual, uniqBy } from "lodash";
import { arrayAdd, arrayUpdate } from "@datorama/akita";
import { EMPTY_STRING_PLACEHOLDER } from "../tenants/tenants.config";
import jsPDF from 'jspdf';
import { api } from "src/api/api.service";

const SNACK_TIMEOUT = 5000;

export enum FormatType {
    PDF = 'PDF',
    EXCEL = 'EXCEL',
}

interface ISearchByGuests {
    search?: string;
    date?: any;
    page?: any;
    size?: any;
}

export class AppointmentService implements IInitializable {

    private pagingStore = new PagingStore();
    private static readonly AM_PM_TIME_FORMAT = 'hh:mm A';
    private _searchQ: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private _searchByGuests: BehaviorSubject<ISearchByGuests> = new BehaviorSubject<ISearchByGuests>({});
    private _searchByProperties: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(
        protected snackbarService: any,
        protected appointmentStore: AppointmentStore
    ) {
    }

    private toCreateDto(appointmentCreate: ICreateAppointment): IAppointmentCreateDto {
        const {agents = [], owners = [], tenants = [], prospects = [], staffs = []} = appointmentCreate.guests;
        const {timeFrom, date, timeTo} = appointmentCreate.dateRange;


        const momentDate = moment(date);

        const datetimeFrom = moment().set({
            year: momentDate.year(),
            month: momentDate.month(),
            date: momentDate.date(),
            hour: moment(timeFrom, AppointmentService.AM_PM_TIME_FORMAT).hour(),
            minute: moment(timeFrom, AppointmentService.AM_PM_TIME_FORMAT).minute(),
        }).toISOString();

        const datetimeTo = moment().set({
            year: momentDate.year(),
            month: momentDate.month(),
            date: momentDate.date(),
            hour: moment(timeTo, AppointmentService.AM_PM_TIME_FORMAT).hour(),
            minute: moment(timeTo, AppointmentService.AM_PM_TIME_FORMAT).minute(),
        }).toISOString();

        return {
            type: appointmentCreate.type!,
            title: appointmentCreate.title,
            notes: appointmentCreate.notes,
            datetimeFrom,
            datetimeTo,
            staffIds: staffs.map(s => s.id as number),
            agentIds: agents.map(a => a.id as number),
            ownerIds: owners.map(o => o.id as number),
            tenantIds: tenants.map(t => t.id as number),
            prospectIds: prospects.map(p => p.id as number),
            propertyIds: appointmentCreate.properties.map(p => p.id as number),
            sendEmail: appointmentCreate.sendEmail,
        }
    }

    private toEditingFromDto(appointment: IAppointment): ICreateAppointment {
        const mapper = (type: GuestsDropdownValue) => (item: any) => {
            let name = '';
            if (type === GuestsDropdownValue.Properties) {
                name = item.unitAddress;
            } else {
                name = item.name ? item.name : `${item.firstName} ${item.lastName}`;
            }
            return {
                ...item,
                id: item.id,
                type,
                name,
            } as unknown as INamedEntityWithType<GuestsDropdownValue>;
        }

        return {
            id: appointment.id,
            type: appointment.type,
            title: appointment.title,
            guestsDropdownValue: GuestsDropdownValue.Agents,
            properties: Array.isArray(appointment.properties) ? appointment.properties?.map(mapper(GuestsDropdownValue.Properties)) : [],
            notes: appointment.notes,
            mode: 'edit',
            guests: {
                [GuestsDropdownValue.Owners]: Array.isArray(appointment?.owners) ? appointment?.owners.map(mapper(GuestsDropdownValue.Owners)) : [],
                [GuestsDropdownValue.Agents]: Array.isArray(appointment?.agents) ? appointment?.agents.map(mapper(GuestsDropdownValue.Agents)) : [],
                [GuestsDropdownValue.Tenants]: Array.isArray(appointment?.tenants) ? appointment?.tenants.map(mapper(GuestsDropdownValue.Tenants)) : [],
                [GuestsDropdownValue.Prospects]: Array.isArray(appointment?.prospects) ? appointment?.prospects.map(mapper(GuestsDropdownValue.Prospects)) : [],
                [GuestsDropdownValue.Staff]: Array.isArray(appointment?.staffs) ? appointment?.staffs.map(mapper(GuestsDropdownValue.Staff)) : [],
            },
            dateRange: {
                date: moment(appointment.datetimeTo),
                timeFrom: moment(appointment.datetimeFrom).format(AppointmentService.AM_PM_TIME_FORMAT),
                timeTo: moment(appointment.datetimeTo).format(AppointmentService.AM_PM_TIME_FORMAT),
            },
            sendEmail: appointment.sendEmail,
        }
    }

    private buildSorting(field: keyof IAppointment, type: string): string {
        let sort = '';
        if (field === "currentAgent") {
            sort = 'user.name'
        } else {
            sort = field;
        }
        return `${sort},${type}`
    }

    private buildFilteredRequest() {

        const {
            filters: {
                from,
                type,
                to,
                agents,
                properties
            }
        } = this.appointmentStore.getValue();

        const params: Partial<IAppointmentFiltersDto> = {};
        const {currentPageSize, currentPage} = this.pagingStore;
        const sort = appointmentStore.getValue().sort;
        params.sort = this.buildSorting(sort.field, sort.type);
        params.size = currentPageSize.getValue();
        params.page = currentPage.getValue();
        // params.search = this._searchQ.getValue();
        if (type && type !== AppointmentType.Any) {
            params.type = type;
        }
        if (from) {
            params.datetimeFrom = moment(from).set({
                hour: 0,
                minute: 0,
            }).toISOString();
        }
        if (to) {
            params.datetimeTo = moment(to).set({
                hour: 23,
                minute: 59,
            }).toISOString();
        }
        if (agents?.length) {
            params.agents = agents?.map(a => a.id).join(',')
        }
        if (properties?.length) {
            params.properties = properties?.map(p => p.id).join(',')
        }

        return params;
    }

    async fetchAppointments(page: number = 0, size: number = 10, search: string = "") {
        this.appointmentStore.setLoading(true);
        this.pagingStore.setPagingData({page, size});

        const params: Partial<IOwnersSearchFilters> = {
            ...this.buildFilteredRequest()
        };

        const objParams = search !== ""
            ? {
                ...params,
                search,
            }
            : {...params}

        const response = await api.get<IPagedResponse<IAppointment>>('/appointments', {
            params: {
                ...objParams,
            }
        });

        if (response.ok) {
            const { data } = response;

            this.appointmentStore.update(state => ({
                ...state,
                appointmentsCount: data.total,
                loading: false,
                appointments: (data.result || []).map(appointment => ({
                    ...appointment,
                    datetimeFrom: moment(appointment.datetimeFrom),
                    datetimeTo: moment(appointment.datetimeTo),
                    agentNamesString: appointment.agents?.length ?
                        appointment.agents.map(agent => agent.name).join(', ') :
                        EMPTY_STRING_PLACEHOLDER,
                    currentAgent: appointment.agent ? appointment.agent.name : EMPTY_STRING_PLACEHOLDER
                })),
            }))
        }

        this.appointmentStore.setLoading(false);
    }

    async fetchStatistics() {
        const response = await api.get<IStatistics>('/appointments/statistic');

        if (response.ok) {
            this.appointmentStore.update(state => ({
                ...state,
                statistics: response.data,
            }))
        }
    }

    @autobind
    public setSorting(sorting: ISort<keyof IAppointment>) {
        this.appointmentStore.update(state => ({
            ...state,
            sort: sorting,
        }));
    }

    @autobind
    changeFilterCriteria(e: Partial<IAppointmentFilters>) {
        this.appointmentStore.update(state => ({
            ...state,
            filters: {
                ...state.filters,
                ...e
            }
        }))
    }

    @autobind
    search(q: string) {
        this._searchQ.next(q);
    }

    @autobind
    searchByGuests({ search, date }: ISearchByGuests) {
        this._searchByGuests.next({
            search,
            date,
        });
    }

    @autobind
    searchByProperties(q: string) {
        this._searchByProperties.next(q);
    }

    @autobind
    printCalendar(img: string, orientation: 'portrait' | 'landscape') {
        return new Promise((resolve) => {
            setTimeout(() => {
                const pdf = new jsPDF({ orientation });
    
                if (orientation === 'portrait') {
                    pdf.addImage(img, "PNG", 0, 0, 205, 290);
                } else {
                    pdf.addImage(img, "PNG", 0, 0, 298, 210);
                }
    
                pdf.save('calendar.pdf', { returnPromise: true });

                resolve('calendar.pdf');
            }, 0);
        })
    }

    @autobind
    async exportCalendar(params: IExportParams) {
        this.appointmentStore.update(state => ({
            ...state,
            isExporting: true,
        }));

        const { data } = await api.getBlob('/appointments/export', {
            ...params,
            date: moment(params.date).set({
                hour: 23,
                minutes: 59,
            }).toISOString(),
        });

        const url = URL.createObjectURL(new Blob([data]));

        const link = document.createElement('a');
        link.href = url;

        if (params.formatType === FormatType.EXCEL) {
            link.setAttribute('download', `appointments.xlsx`);
        } else {
            link.setAttribute('download', `appointments.pdf`);
        }

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);

        this.appointmentStore.update(state => ({
            ...state,
            isExporting: false,
        }));
    }

    isInitialized = false;

    async init(): Promise<void> {
        this._searchQ.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(search => {
                return this.fetchAppointments();
            })
        ).subscribe();

        // this._searchByProperties.pipe(debounceTime(500)).subscribe(async (search: string) => {
        //     await this.fetchProperties({search});
        // });

        // this._searchByGuests.pipe(debounceTime(500)).subscribe(async ({search, date}: ISearchByGuests) => {
        //     this.appointmentStore.update(state => ({
        //         ...state,
        //         loadingSearchItems: true
        //     }));
        //     await this.fetchSearchData({search, date});
        //     this.appointmentStore.update(state => ({
        //         ...state,
        //         loadingSearchItems: false,
        //     }));
        // });

        const [allProperties, allAgents] = await Promise.all([
            api.get<{ response: IPagedResponse<IProperty> }>('/properties', {
                params: {
                    size: 0,
                    page: 0,
                }
            }),
            api.get<IStaff[]>('/user/agents', {
                params: {
                    size: 0,
                    page: 0,
                }
            })
        ]);

        this.appointmentStore.update(state => ({
            ...state,
            filtersData: {
                properties: Array.isArray(allProperties.data.response.result) && allProperties.data.response.result.map(p => ({
                    id: p.id,
                    key: 'properties',
                    name: p.unitAddress
                })),
                agents: Array.isArray(allAgents.data) && allAgents.data.map(a => ({
                    id: a.id,
                    key: 'agents',
                    name: a.name,
                })),
            }
        }));

        Promise.all([
            // this.fetchAppointments(),
            this.fetchStatistics()
        ]).then(() => {
            this.isInitialized = true;
        })
    }

    getNewAppointmentData() {
        return this.appointmentStore.getValue().newAppointment;
    }

    @autobind
    changeCreateAppointmentData(data: Partial<ICreateAppointment>) {
        const {newAppointment} = this.appointmentStore.getValue();

        if (!isEqual(data.dateRange, newAppointment.dateRange)) {
            const dateTo = moment().set({
                date: moment(data?.dateRange?.date).date(),
                hour: moment(data?.dateRange?.timeTo, AppointmentService.AM_PM_TIME_FORMAT).hour(),
                minute: moment(data?.dateRange?.timeTo, AppointmentService.AM_PM_TIME_FORMAT).minute(),
            }).toISOString();
    
            const dateFrom = moment().set({
                date: moment(data?.dateRange?.date).date(),
                hour: moment(data?.dateRange?.timeFrom, AppointmentService.AM_PM_TIME_FORMAT).hour(),
                minute: moment(data?.dateRange?.timeFrom, AppointmentService.AM_PM_TIME_FORMAT).minute(),
            }).toISOString();
            
            this._searchByGuests.next({
                search: '',
                date: {
                    dateFrom,
                    dateTo,
                }
            });
        }

        const dRange = {
            ...newAppointment.dateRange,
            ...data.dateRange,
        };

        const appointment = {
            ...newAppointment,
            ...data,
            dateRange: {
                ...newAppointment.dateRange,
                ...dRange,
            },
        };

        this.appointmentStore.update(state => ({
            ...state,
            newAppointment: {
                ...state.newAppointment,
                ...appointment,
            }
        }));

        const initialAppointment = this.appointmentStore.getValue().newAppointment;

        const dateTo = moment().set({
            date: moment(dRange && dRange.date ? dRange.date : initialAppointment.dateRange.date).date(),
            hour: moment(dRange && dRange.timeTo ? dRange.timeTo : initialAppointment.dateRange.timeTo, AppointmentService.AM_PM_TIME_FORMAT).hour(),
            minute: moment(dRange && dRange.timeTo ? dRange.timeTo : initialAppointment.dateRange.timeTo, AppointmentService.AM_PM_TIME_FORMAT).minute(),
        }).toISOString();

        const dateFrom = moment().set({
            date: moment(dRange && dRange.date ? dRange.date : initialAppointment.dateRange.date).date(),
            hour: moment(dRange && dRange.timeFrom ? dRange.timeFrom : initialAppointment.dateRange.timeFrom, AppointmentService.AM_PM_TIME_FORMAT).hour(),
            minute: moment(dRange && dRange.timeFrom ? dRange.timeFrom : initialAppointment.dateRange.timeFrom, AppointmentService.AM_PM_TIME_FORMAT).minute(),
        }).toISOString();

        if (data.guestsDropdownValue !== newAppointment.guestsDropdownValue) {
            this._searchByGuests.next({
                search: '',
                date: {
                    dateFrom,
                    dateTo,
                }
            });
        }
    }

    addProspect(data: IProspect) {
        this.appointmentStore.update(state => ({
            ...state,
            newAppointment: {
                ...state.newAppointment,
                guests: {
                    ...state.newAppointment.guests,
                    [GuestsDropdownValue.Prospects]: [
                        ...state.newAppointment.guests.prospects || [],
                        {
                            // id: data.id,
                            name: `${data.firstName} ${data.lastName}`,
                            key: GuestsDropdownValue.Prospects,
                            type: GuestsDropdownValue.Prospects,
                            ...data,
                        }
                    ],
                    agents: !isEmpty(data.agentAttached)
                        ? [
                            ...get(state.newAppointment, 'guests.agents', []),
                            {
                                ...data.agentAttached,
                                isAttached: true,
                            },
                        ]
                        : [
                            ...get(state.newAppointment, 'guests.agents', []),
                        ],
                }
            }
        }))
    }

    addProperties(data: IProperty[]) {
        this.appointmentStore.update(state => ({
            ...state,
            newAppointment: {
                ...state.newAppointment,
                properties: [
                    ...state.newAppointment.properties,
                    ...data.map(record => ({
                        id: record.id,
                        name: record.unitAddress,
                        key: 'properties',
                        type: GuestsDropdownValue.Properties
                    }))
                ]
            }
        }))
    }

    async fetchProperties({
        page = 0,
        size = 50,
        search,
    }: ISearchByGuests) {
        this.appointmentStore.update(state => ({
            ...state,
            searchData: {
                ...state.searchData,
                propertiesLoading: true,
            }
        }));

        const response = await api.get<{ response: IPagedResponse<IProperty>; }>('/properties', {
            params: {
                page,
                size,
                search,
            }
        });

        if (response.ok) {
            this.appointmentStore.update(state => ({
                ...state,
                searchData: {
                    ...state.searchData,
                    propertiesLoading: false,
                    propertiesTotal: response.data.response.total,
                    properties: uniqBy([
                        ...state.searchData.properties,
                        ...response.data.response.result.map(p => ({
                            id: p.id,
                            name: p.unitAddress,
                        })),
                    ], 'id'),
                }
            }));
        }
    }

    async resetProperties() {
        this.appointmentStore.update(state => ({
            ...state,
            searchData: {
                ...state.searchData,
                propertiesLoading: false,
                propertiesTotal: 0,
                properties: [],
            }
        }));
    }

    async createAppointment(appointment: ICreateAppointment): Promise<void> {
        try {
            this.appointmentStore.setLoading(true);

            const response = await api.post<IAppointment>(
                '/appointments',
                this.toCreateDto(appointment)
            );

            if (response.ok) {
                this.appointmentStore.update(state => ({
                    ...state,
                    appointments: arrayAdd(state.appointments, response.data),
                }));

                this.snackbarService.createSnackbar({
                    text: 'Appointment successfully scheduled',
                    type: SnackbarType.SUCCESS,
                }, SNACK_TIMEOUT);
    
                await this.fetchStatistics();
                this._searchQ.next('');
            }
        } catch (err) {
            this.snackbarService.createSnackbar({
                text: `Failed to schedule the appointment, error: ${(err as any).toString()}`,
                type: SnackbarType.ERROR
            }, SNACK_TIMEOUT);

        } finally {
            this.appointmentStore.setLoading(false);
            this.changeCreateAppointmentData(initialNewAppointment);
        }
    }

    openAppointment(appointment: IAppointment) {
        this.appointmentStore.update(state => ({
            ...state,
            isEditing: true,
            newAppointment: this.toEditingFromDto(appointment),
            mode: 'edit'
        }))
    }

    setEditing(isEditing: boolean) {
        this.appointmentStore.update(state => ({
            ...state,
            isEditing,
        }))
    }

    async deleteAppointment() {
        try {
            this.appointmentStore.setLoading(true);
            await api.del(`/appointments/${this.appointmentStore.getValue().newAppointment.id}`);
            await Promise.all([
                this.fetchAppointments(),
                this.fetchStatistics()
            ]);
            this.snackbarService.createSnackbar({
                type: SnackbarType.SUCCESS,
                text: `The appointment was successfully deleted`
            }, SNACK_TIMEOUT);
            
            this.appointmentStore.update(state => ({
                ...state,
                newAppointment: initialNewAppointment,
                isEditing: false,
            }))
        } catch (err) {
            this.snackbarService.createSnackbar({
                type: SnackbarType.ERROR,
                text: `Failed to delete the appointment, error message: ${(err as any).toString()}`
            }, SNACK_TIMEOUT);

        } finally {
            this.appointmentStore.setLoading(false);
        }

    }

    async createOrUpdateAppointment(newAppointment: ICreateAppointment) {
        if (newAppointment.mode.includes('edit')) {
            await this.updateAppointment(newAppointment);
        } else {
            await this.createAppointment(newAppointment);
        }
    }

    async updateAppointment(appointment: ICreateAppointment) {
        try {
            this.appointmentStore.setLoading(true);

            const response = await api.put<IAppointment>(
                `/appointments/${appointment.id}`,
                this.toCreateDto(appointment)
            );

            if (response.ok) {
                this.appointmentStore.update(state => ({
                    ...state,
                    appointments: arrayUpdate(state.appointments, response.data.id, response.data),
                }));
            }

            this.snackbarService.createSnackbar({
                text: 'Appointment successfully updated',
                type: SnackbarType.SUCCESS,
            }, SNACK_TIMEOUT);

            await this.fetchStatistics();
            this._searchQ.next('');

        } catch (err) {
            this.snackbarService.createSnackbar({
                text: `Failed to update the appointment, error: ${(err as any).toString()}`,
                type: SnackbarType.ERROR
            }, SNACK_TIMEOUT);

        } finally {
            this.appointmentStore.setLoading(false);
            this.changeCreateAppointmentData(initialNewAppointment);
        }
    }

    async openAppointmentById(id: number) {
        const response = await api.get<IAppointment>(`/appointments/${id}`);

        if (response.ok) {
            this.openAppointment(response.data);
        }
    }

    async resetAppointment() {
        this.changeCreateAppointmentData(initialNewAppointment);
    }

    resetAppointments() {
        this.appointmentStore.update(state => ({
            ...state,
            appointments: [],
        }));
    }
}

export const appointmentService = new AppointmentService(
    snackbarService,
    appointmentStore
);