import { QueryEntity } from "@datorama/akita";
import { AdminSettingsStore, adminSettingsStore, IAdminSettingsState } from "./admin-settings.store";

export class AdminSettingsQuery extends QueryEntity<IAdminSettingsState> {
    emailTemplates$ = this.select(state => state.emailTemplates);
    smsTemplates$ = this.select(state => state.smsTemplates);
    allTemplates$ = this.select(state => {
        return [
            ...state.emailTemplates,
            ...state.smsTemplates,
        ];
    });
    
    staff$ = this.select(state => state.staff);

    selectedTemplate$ = this.select(state => state.selectedTemplate);

    emailNotification$ = this.select(state => state.emailNotification);
    smsNotification$ = this.select(state => state.smsNotification);

    calendarSettings$ = this.select(state => state.calendarSettings);

    followUps$ = this.select(state => state.followUps);
    isFollowUpLoading$ = this.select(state => state.isFollowUpLoading);
    selectedFollowUp$ = this.select(state => state.selectedFollowUp);
    totalFollowups$ = this.select(state => state.totalFollowups);

    autoNotifications$ = this.select(state => state.autoNotifications);
    isNotificationsLoading$ = this.select(state => state.isNotificationsLoading);
    selectedAutoNotifications$ = this.select(state => state.selectedAutoNotifications);
    totalNotifications$ = this.select(state => state.totalNotifications);
    
    isUpdating$ = this.select(state => state.isUpdating);
    
    loading$ = this.selectLoading();

    constructor(protected store: AdminSettingsStore) {
        super(store);
    }
}

export const adminSettingsQuery = new AdminSettingsQuery(adminSettingsStore);