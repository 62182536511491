import { debounce } from "lodash";
import { useCallback, useEffect } from "react";
import { FetchStatus } from "src/store/data-fetcher/data-fetcher.store";
import { PagingType } from "../../../store/common/models/common.interface";
import { IPaging, usePaging } from "../../../store/common/hooks/use-paging";
import { IFetchRequest, useRequest } from "./use-request";
import { guid } from "@datorama/akita";

const DEBOUNCE_TIME = 400;
export interface IFetcherHook<TData, PActionParams extends unknown[]> {
    action: (...params: PActionParams) => Promise<TData | void>;
    pagingType?: PagingType;
    cache?: boolean;
    initParams?: PActionParams;
    inititalState?: TData;
}

interface RFetcherHook<T, P extends unknown[]> {
    data: T | null;
    fetchStatus: FetchStatus;
    error: any;
    changePage: (page: number) => void;
    changeRowsNumber: (rowsPerPage: number, page: number) => void;
    sortColumn: (column: string, sortDirection: "asc" | "desc") => void;
    changeSearch: (searchQ: string) => void;
    applyFilters: () => void;
    request: IFetchRequest<T, P>;
    refetch: () => void;
    paging: IPaging;
}

//** add ability to create multiple requests */
//** implement request cancelation */

const REQUEST_DELAY = 100;

export function useFetcher<T, P extends unknown[]>({ 
    action, 
    pagingType = PagingType.CONTRACTS, 
    initParams,
    inititalState,
}: IFetcherHook<T, P>): RFetcherHook<T, P> {
    const request = useRequest<T, P>(guid(), { inititalState });
    const [paging, setPaging] = usePaging(pagingType);

    //** Fetch Data */
    useEffect(() => {
        const createRequest = (requestId: string) => {
            if (initParams?.length) {
                if (initParams.every(param => typeof param !== "undefined")) {
                    if (request.fetchStatus === FetchStatus.IDLE) {
                        request.loading();
                        request.fetch(action, initParams);
                    }
                }
            } else {
                if (request.fetchStatus === FetchStatus.IDLE) {
                    request.loading();
                    request.fetch(action);
                }
            }
        }

        createRequest(guid());
    }, [request, action, initParams]);

    const refetch = useCallback(() => {
        request.loading();
        request.fetch(action, initParams);
    }, [request, action, initParams]);

     //** Pagination */
    const changeRowsNumber = useCallback(async (rowsPerPage: number, page: number) => {
        setPaging(page, rowsPerPage);

        const args = [
            page - 1,
            rowsPerPage,
            "",
            request.sort,
            request.filters,
        ];

        request.fetch(action, args);
    }, [request, setPaging, action]);

    const changePage = useCallback(async (page: number) => {
        setPaging(page, paging.rowsPerPage);

        const args = [
            page - 1,
            paging.rowsPerPage,
            "",
            request.sort,
            request.filters,
        ];

        request.fetch(action, args);
    }, [request, action, paging, setPaging]);

    const sortColumn = useCallback(async (column, sortDirection) => {
        const args = [
            0,
            paging.rowsPerPage,
            "",
            `${column.selector},${sortDirection}`,
            request.filters,
        ];

        setTimeout(() => {
            request.fetch(action, args)
        }, REQUEST_DELAY)
    }, [request, action, paging.rowsPerPage])

    //** Filters */
    const applyFilters = useCallback(async () => {
        const args = [
            0,
            paging.rowsPerPage,
        ];
        
        request.fetch(action, args);
    }, [request, action, paging.rowsPerPage]);

    //** Search */
    const changeSearch = debounce(async (searchQ: string) => {
        const args = [
            paging.activePage,
            paging.rowsPerPage,
            searchQ,
        ];
        
        request.fetch(action, args);
    }, DEBOUNCE_TIME);

    return {
        data: request.state as T,
        fetchStatus: request.fetchStatus,
        error: request.error,
        changePage,
        changeRowsNumber,
        sortColumn,
        changeSearch,
        applyFilters,
        request,
        refetch,
        paging,
    };
}