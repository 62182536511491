import styled from "styled-components";

interface IEventHoledr {
    flexDirection?: string;
}

export const EventHoledr = styled.div<IEventHoledr>`
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : 'column'};
    align-items: ${({ flexDirection }) => flexDirection === 'row' ? 'center' : 'flex-start'};
    padding: ${({ flexDirection }) => flexDirection === 'column' ? '8px 0 0 4px' : '4px 0 0'};
`;

interface IEventTitle {
    fontSize?: string | number;
    titleMargin?: string;
    overflowText?: boolean;
    color?: string;
}

export const EventTitle = styled.div<IEventTitle>`
    margin: ${({ titleMargin }) => titleMargin === 'bottom' ? '0 0 6px' : '0 4px 0 0'};
    white-space: nowrap;
    overflow: ${({ overflowText }) => overflowText ? 'hidden' : 'none'};
    text-overflow: ${({ overflowText }) => overflowText ? 'ellipsis' : 'none'};
    font-size: ${({ fontSize }) => fontSize ? fontSize : '12px'}; 
    font-family: 'ManropeSemibold';
    color: ${({ color }) => color ? color : 'inherit'};
`;

interface IEventlabel {
    fontSize?: string | number;
    overflowText?: boolean;
}

export const Eventlabel = styled.div<IEventlabel>`
    position: relative;
    top: 0;
    margin: 0 4px 0 0;
    font-size: ${({ fontSize }) => fontSize ? fontSize : '11px'}; 
    color: #737E98;
    font-family: 'ManropeRegular';
    white-space: nowrap;
    overflow: ${({ overflowText }) => overflowText ? 'hidden' : 'none'};
    text-overflow: ${({ overflowText }) => overflowText ? 'ellipsis' : 'none'};
`;