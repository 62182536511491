import React, { useState, Fragment } from 'react';
import { List } from 'immutable';
import { InfiniteLoader, List as IList, IndexRange, ListRowProps, AutoSizer } from 'react-virtualized';
import { AttentionIcon } from "src/components/ui/icons";
import { AutocompletHolder, BusyIcon, Content, IconHolder } from './styled';

const CHUNK_SIZE = 5;

interface IInfiniteList<T> {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    list: List<T>;
    loadNextPage: (params: IndexRange) => Promise<any>;
    getOptionProps?: any;
    labelField?: string;
    customRows?: any;
    customListRenderer?: (props: Partial<ListRowProps>) => void;
}

export const InfiniteList = <T extends {}>({
    //** Are there more items to load
    hasNextPage,
    //** Are we currently loading a page of items
    isNextPageLoading,
    //** List of items loaded
    list,
    //** Callback function, responsible for loading the next page of items
    loadNextPage,
    getOptionProps,
    customRows,
    labelField = 'title',
    customListRenderer,
}: IInfiniteList<T>) => {
    const [state] = useState({
        listHeight: 150,
        listRowHeight: 35,
        overscanRowCount: 0,
        rowCount: list.size,
    });

    const rowCount = hasNextPage ? list.size + 1 : list.size;

    const loadMoreRows = isNextPageLoading ? async (params: IndexRange) => {} : loadNextPage;

    const isRowLoaded = ({ index }: any) => !hasNextPage || index < list.size;

    const rowRenderer = ({index, key, style}: ListRowProps) => {
        let content;
        let isBusy;
    
        if (!isRowLoaded({index})) {
            content = 'Loading...';
        } else {
            content = list.getIn([index, `${labelField}`]);
            isBusy = list.getIn([index, `isBusy`]);
        }

        if (typeof customListRenderer === 'function') {
            return  customListRenderer({index, key, style});
        }

        return (
            <Fragment key={key}>
                <AutocompletHolder key={key} style={{
                    ...style,
                    cursor: 'pointer',
                }} {...getOptionProps({ content, index })}>
                    <Content>{content}</Content>
                    <IconHolder>
                        { isBusy ? (
                            <BusyIcon>
                                <AttentionIcon />
                                <span>busy</span>
                            </BusyIcon>
                        ) : null }
                    </IconHolder>  
                </AutocompletHolder>
            </Fragment>
        );
    };

    return (
        <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            threshold={4}
            rowCount={rowCount}>
            {({onRowsRendered, registerChild}) => (
                <AutoSizer disableHeight>
                    {({ width }) => {
                        return (
                            <IList
                                ref={registerChild}
                                autoHeight={list.size < CHUNK_SIZE}
                                height={state.listHeight}
                                rowHeight={state.listRowHeight}
                                overscanRowCount={state.overscanRowCount}
                                rowCount={rowCount}
                                onRowsRendered={onRowsRendered}
                                rowRenderer={customRows ? customRows : rowRenderer}
                                width={width}
                            />
                        )
                    }}
                </AutoSizer>
            )}
        </InfiniteLoader>
    );
}