import React from "react";
import {Route, Switch} from 'react-router-dom';
import {RoutesEnum} from "../../routing/routes";
import {Appointments} from "./appointments";
import {CalendarView} from "./calendar";

export function Calendars() {
    return <Switch>
        <Route exact path={RoutesEnum.APPOINTMENTS_LIST} component={Appointments} />
        <Route exact path={RoutesEnum.CALENDAR_VIEW} component={CalendarView} />
        {/* <Redirect to={RoutesEnum.APPOINTMENTS_LIST} /> */}
    </Switch>
}
