import { IOwnerCriteria, searchOwnersState, searchOwnersStore } from "./search-owners.store";
// import {ISort} from "../../properties/search/search-properties.store";
// import { IOwner } from "../models/owner.interface";
// import { ApiServiceInstance } from "../../api/api-service";
import { IPagedResponse } from "src/store/properties/models/paged-response.interface";
import { IPortfolio } from "src/store/portfolio/models/portfolio.interface";
import { api } from "src/api/api.service";

interface ISearch {
    page?: number;
    size?: number;
    search?: string;
}

// function setSorting(sorting: ISort<keyof IOwner>) {
//     this.searchOwnersStore.update(state => ({
//         ...state,
//         sort: sorting,
//     }));
// }

export function updateSelectionCriteria(data: Partial<IOwnerCriteria>) {
    searchOwnersStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...state.selectedCriteria,
            ...data,
        }
    }))
} 

// function getSorting() {
//     return searchOwnersStore.getValue().sort;
// }

export function getCriteria() {
    return searchOwnersStore.getValue().selectedCriteria;
}

export async function fetchPortfolios({
    search,
}: ISearch) {
    searchOwnersStore.setLoading(true);

    const response = await api.get<IPagedResponse<IPortfolio>>('/portfolios', {
        params: {
            search,
            page: 0,
            size: 0,
        }
    });

    if (response.ok) {
        searchOwnersStore.update(state => ({
            ...state,
            searchData: {
                ...state.searchData,
                portfolioList: response.data.result,
            }
        }))
    }

    searchOwnersStore.setLoading(false);

}

export function removeAllSelection() {
    searchOwnersStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...searchOwnersState.selectedCriteria
        }
    }))
}

export function applyFilters() {
    searchOwnersStore.update(state => ({
        ...state,
        appliedFilters: state.selectedCriteria,
    }));
}

export const searchOwnersService = {
    updateSelectionCriteria,
    fetchPortfolios,
    removeAllSelection,
    applyFilters,
    getCriteria,
};