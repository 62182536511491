export interface IGetISODate {
    date: Date;
    timeTo: string;
    timeFrom: string;
}

export interface RISODate {
    dateFrom: string;
    dateTo: string;
}

export interface IFetchSearchData {
    id: string;
    url: string;
    page?: number;
    size?: number;
    search?: string;
    isNextPage?: boolean;
    searchType: SearchType;
    dateProps?: IGetISODate;
    multiple?: boolean;
}

export interface ISearchData {
    result: any[];
    total: number;
}

export enum SearchType {
    TENANTS = 'tenants',
    PROSPECTS = 'prospects',
    OWNERS = 'owners',
    PROPERTIES = 'properties',
    PROPERTY_GROUPS = 'propertyGroups',
    TENANTS_GROUPS = 'groups',
    STAFF = 'user',
    AGENTS = 'agents',
    PORTFOLIOS = 'portfolios',
    CITIES = 'cities',
    NEIGHBORHOODS = 'neighborhoods',
    STAFFS = 'staffs',
}