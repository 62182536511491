import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { guid } from "@datorama/akita";
import { COLORS } from "../../theme/variables/colors";
import { useAutocomplete } from "@material-ui/lab";
import CssClasses from './dropdown.module.scss';
import InputClasses from '../forms/inputs/input.module.scss';
import moment from "moment";
import classNames from "classnames";
import { ArrowDownIcon } from 'src/components/ui/icons';
import { CircularProgress } from '@material-ui/core';

export const isTimeValid = (time: string, format = 'hh:mm A') => moment(time, format).format('hh:mm A') === time;

// const StyledMenuItem = withStyles((theme: Theme) => createStyles({
//     root: {
//         fontSize: '14px',
//         [theme.breakpoints.down("sm")]: {
//             fontSize: '10px',
//         }
//     },
// }));

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: COLORS.INPUT_BG,
            border: 'none',
            fontFamily: 'ManropeThin',
            fontSize: 14,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            '&:focus': {
                borderRadius: 4,
                borderColor: 'transparent',
                // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }),
)(InputBase);

const DropdownComponent = withStyles((theme: Theme) => createStyles({
    icon: {
        fontSize: '14px',
        position: 'relative',
        left: '-28px',
        color: '#2B65F0',
    }
}))(Select);

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         margin: {
//             margin: theme.spacing(1),
//             // minWidth: '250px'
//         },
//     }),
// );

interface IProps {
    onChange: (e: React.ChangeEvent<any>) => void;
    onBlur?: (e: React.ChangeEvent<any>) => void;
    value?: any;
    menuItems: { value: unknown, content: React.ReactNode }[];
    styles?: React.CSSProperties;
    inputStyles?: React.CSSProperties;
    freeSolo?: boolean;
    label?: string;
    isTime?: boolean;
    timePicker?: boolean;
    placeholder?: string;
    disabled?: boolean;
    loading?: boolean;
}

export const Dropdown: React.FC<IProps> = ({
    freeSolo = false,
    styles = {},
    inputStyles = {},
    menuItems = [],
    value,
    timePicker,
    placeholder,
    onChange,
    onBlur,
    disabled,
    loading,
    label = 'Tenant/Property Groups',
}) => {
    // const classes = useStyles();
    const id = useMemo(() => guid(), []);

    const activeOptionRef = useRef<HTMLLIElement>();

    useEffect(() => {
        if (activeOptionRef.current) {
            activeOptionRef.current.scrollIntoView(false);
        }
    });

    const onChangeEvt = (e: any) => {
        if(e.target.value) {
            onChange({
                target: {
                    value: e.target.value || ''
                }
            } as any);
        } else {
            onChange({
                target: {
                    value: e.target.textContent || ''
                }
            } as any);
        }
    }

    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id,
        options: menuItems,
        freeSolo,
        // defaultValue: value,
        onChange: onChangeEvt,
        getOptionLabel: (option) => option.content as string,
    });

    // const isInputInvalid = useMemo(() => {
    //     return !isTimeValid(value);
    // }, [value]);

    const inputClass = classNames({
        // [InputClasses.input_invalid]: isInputInvalid,
        [InputClasses.input]: true,
        [InputClasses.timePicker]: timePicker,
    })

    const handleBlur = (e: ChangeEvent<any>) => {
        const inputProps: any = getInputProps();
        inputProps.onBlur();

        if (typeof onBlur === 'function') {
            onBlur(e);
        }
    }

    return (
        <div>
            <FormControl style={{
                minWidth: 170,
                ...styles,
            }}>
                {freeSolo ? <div className={CssClasses.input_and_dropdown_wrapper}>
                    <div {...getRootProps()}>
                        <label {...getInputLabelProps()}>
                            {label}
                        </label>
                        <input style={inputStyles} className={inputClass} {...getInputProps()} value={value} onChange={onChangeEvt} onBlur={handleBlur} />
                    </div>
                    {groupedOptions.length > 0 ? (
                        <ul className={CssClasses.default_dropdown} {...getListboxProps()}>
                            {groupedOptions.map((option, index) => {
                                const props: any = getOptionProps({ option, index });
                                const optionProps = {
                                    ...props,
                                    ["aria-selected"]: option.value === value,
                                };

                                const isActive = option.value === value;

                                return (
                                    <li ref={isActive ? activeOptionRef : null} className={CssClasses.default_dropdown_item} 
                                        {...optionProps}>
                                            {option.content}
                                    </li>
                                )
                            })}
                        </ul>
                    ) : null}
                </div> : <DropdownComponent
                    labelId={`${id}-label`}
                    id={id}
                    value={value}
                    label={label}
                    displayEmpty
                    disabled={disabled}
                    onChange={onChange}
                    input={<BootstrapInput style={inputStyles} />}
                    IconComponent={ArrowDownIcon}
                >
                    {
                        placeholder
                            ? (
                                <MenuItem value="" disabled>
                                    {placeholder}
                                </MenuItem>
                            )
                            : null
                    }
                    {
                        loading 
                            ? <CircularProgress />
                            : (
                                menuItems?.map((item, i) => {
                                    return <MenuItem key={i}
                                        value={item.value as any}>{item.content}</MenuItem>
                                })
                            ) 
                    }
                </DropdownComponent>}

            </FormControl>
        </div>
    );
}
