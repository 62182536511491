import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import styles from './radio-button.module.scss';

const RadioHolder = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 20px;
    font-family: 'ManropeThin';
    cursor: pointer;
`;

interface IRadioLabel {
    isRadioCheck?: boolean;
}

const RadioLabel = styled.label<IRadioLabel>`
    padding: 0 0 0 24px;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 4px;
        left: 0;
        max-width: 13px;
        max-height: 13px;
        width: 100%;
        height: 100%;
        border: 1px solid #789EFF;
        border-radius: ${({ isRadioCheck }) => isRadioCheck ? '0' : '50%'};
        box-sizing: border-box;
    }

    @media (max-width: 767px) {
        font-size: 11px;
    }
`;

const RadioInput = styled.input`
    position: absolute;
    left: -999999px;
    cursor: pointer;

    &:checked + label:before {
        /* background-color: #789EFF; */
        border-width: 4px;
    }
`;

// const img = `../../icons/check.icon.svg`;

const RadioCheckInput = styled.input`
    position: absolute;
    left: -999999px;
    cursor: pointer;
`;

interface IRadioButton {
    id: string;
    name: string;
    label: string;
    labelStyle?: React.CSSProperties;
    checked?: boolean;
    value?: any;
    disabled?: boolean;
    onChange?: (value: any) => void;
}

const RadioOverlayStyled = styled.div`
    position: absolute;
    top: 4px;
    width: 100%;
    height: 80%;
    background: #fff;
    opacity: .7;
    z-index: 5;
`;

export const RadioButton: React.FC<IRadioButton> = ({ id, name, label, labelStyle, checked, value, disabled, onChange }) => {
    const handleChange = (e: ChangeEvent<any>) => {
        e.persist();

        if (typeof onChange === "function") {
            onChange(e.target.value);
        }
    }

    return (
        <RadioHolder>
            {
                disabled
                    ? <RadioOverlayStyled />
                    : null
            }
            <RadioInput id={id} type="radio" name={name} value={value} checked={checked} disabled={disabled} onClick={handleChange} />
            <RadioLabel style={{
                ...labelStyle
            }} htmlFor={id}>{ label }</RadioLabel>
        </RadioHolder>
    );
}

export const RadioCheckButton: React.FC<IRadioButton> = ({ id, name, label, labelStyle, checked, value, onChange }) => {
    const handleChange = (e: ChangeEvent<any>) => {
        e.persist();

        if (typeof onChange === "function") {
            const [ id, status ] = e.target.value.split("|");

            const data = {
                utilityId: Number(id),
                utilityPayment: status,
            };

            onChange(data);
        }
    }

    return (
        <RadioHolder>
            <RadioCheckInput className={styles.checkInput} id={id} type="radio" name={name} value={value} checked={checked} onClick={handleChange} />
            <RadioLabel style={{
                ...labelStyle
            }} isRadioCheck htmlFor={id}>{ label }</RadioLabel>
        </RadioHolder>
    );
}