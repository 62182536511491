import React, { Fragment } from 'react'
import { Email, Phone } from '@material-ui/icons'
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form'
import { INotificationMethod, IProspect } from 'src/store/prospects/models/prospect.interface'

import { Avatar, AvatarSize, FallbackType } from 'src/components/ui/components/avatar/avatar'
import { HomeIcon, InfoIcon, NoteIcon, PersonIcon } from 'src/components/ui/icons'
import { FormHolder } from 'src/components/ui/components/forms/form-holder'

import styles from '../prospect-info.module.scss'

const leadStatusMap = new Map();

leadStatusMap.set('FOR_FOLLOW_UP', 'For Follow Up');
leadStatusMap.set('LOST', 'Lost');
leadStatusMap.set('APPLICANT', 'Applicant');

interface RenderNotificationMethodsProps {
    notification?: INotificationMethod[] | undefined;
}

const RenderNotificationMethods: React.FC<RenderNotificationMethodsProps> = ({ notification }) => {
    if (notification) {
        return (
            <Fragment>
                {notification.map(nm => {
                    if (nm.name === 'Phone & Email') {
                        return (
                            <Fragment>
                                <div className={styles.iconHolder}>
                                    <div className={styles.icon_email}>
                                        <Email style={{fontSize: 14}} />
                                    </div>
                                    <span>Email</span>
                                </div>
                                <div className={styles.iconHolder}>
                                    <div className={styles.icon_phone}>
                                        <Phone style={{fontSize: 14}} />
                                    </div>
                                    <span>Phone</span>
                                </div>
                            </Fragment>
                        );
                    }

                    if (nm.name === 'Email') {
                        return (
                            <div className={styles.iconHolder}>
                                <div className={styles.icon_email}>
                                    <Email style={{fontSize: 14}} />
                                </div>
                                <span>{nm.name}</span>
                            </div>
                        );
                    }

                    if (nm.name === 'SMS') {
                        return (
                            <div className={styles.iconHolder}>
                                <div className={styles.icon_phone}>
                                    <Phone style={{fontSize: 14}} />
                                </div>
                                <span>{nm.name}</span>
                            </div>
                        );
                    }
                })}
            </Fragment>
        );
    }

    return null;
}

interface IContactDetailsProps {
    prospect: Partial<IProspect>;
}

const ContactDetails: React.FC<IContactDetailsProps> = ({ prospect }) => {
    return (
        <Fragment>
            <FormHolder
                title="Contact Details"
                withDivider
                icon={<InfoIcon />}>
                <Form<Partial<IProspect>>
                    type={FormType.DEFAULT}
                    data={prospect}
                    readOnly
                    fields={{
                        email: {
                            index: 0,
                            label: 'Email',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        phoneNumber: {
                            index: 1,
                            label: 'Mobile Number',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        notification: {
                            index: 3,
                            label: 'Notification Method',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <RenderNotificationMethods notification={prospect.notification} />,
                        }
                    }}
                />
            </FormHolder>
            <FormHolder
                title="Preferences"
                withDivider
                icon={<HomeIcon />}>
                <Form<Partial<IProspect>>
                    type={FormType.DEFAULT}
                    data={prospect}
                    readOnly
                    fields={{
                        location: {
                            index: 0,
                            label: 'Location',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        bedrN: {
                            index: 1,
                            label: 'Number Of Bedrooms',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        approxBudgetFrom: {
                            index: 2,
                            label: 'Approximate Budget',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <div style={{fontWeight: 'bold'}}>
                                <div>{`$${ prospect.approxBudgetFrom } - ${ prospect.approxBudgetTo }`}</div>
                            </div>
                        },
                        pickupLocation: {
                            index: 3,
                            label: 'Pick Up Location',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        seenBefore: {
                            index: 4,
                            label: 'Seen Properties With Us Before',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <div style={{ fontWeight: 'bold' }}>
                                { prospect.seenBefore ? 'Yes' : 'No' }
                            </div>
                        },
                        agentShowed: {
                            index: 5,
                            label: 'Agent Showed',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <div style={{fontWeight: 'bold'}}>
                                { prospect.agentShowed ? <div>{ prospect.agentShowed.name }</div> : null }
                            </div>
                        },
                    }}
                />
            </FormHolder>
            <FormHolder
                title="Agent Attached"
                withDivider
                icon={<PersonIcon />}>
                <Form<Partial<IProspect>>
                    type={FormType.DEFAULT}
                    data={prospect}
                    readOnly
                    fields={{
                        agentAttached: {
                            index: 0,
                            label: 'Agent Name',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <div style={{fontWeight: 'bold'}}>
                                { prospect.agentAttached 
                                    ? <div className={styles.nameCell}>
                                        <Avatar
                                            src={ prospect.agentAttached.photo } 
                                            size={AvatarSize.small}
                                            fallbackType={FallbackType.ABBR}
                                            fallback={prospect.agentAttached.name} />
                                        <div>{ prospect.agentAttached.name }</div>
                                    </div> : null }
                            </div>
                        },
                        leadStatus: {
                            index: 1,
                            label: 'Lead Status',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <div style={{fontWeight: 'bold'}}>{ leadStatusMap.get(prospect.leadStatus) }</div>,
                        },
                    }}
                />
            </FormHolder>
            <FormHolder
                title="Notes"
                iconStyle="circle"
                icon={<NoteIcon />}>
                <Form<Partial<IProspect>>
                    type={FormType.DEFAULT}
                    data={prospect}
                    readOnly
                    // wrapperStyles={{
                    //     margin: 0,
                    // }}
                    fields={{
                        notes: {
                            index: 1,
                            label: 'Notes',
                            labelDisplay: 'block',
                            height: 'auto',
                            fieldType: InputType.STRING,
                        },
                    }}
                />
            </FormHolder>
        </Fragment>
    );
}

export default ContactDetails