import {EntityStore, StoreConfig} from "@datorama/akita";

export enum DeviceType {
    UNKNOWN = 'unknown',
    DESKTOP = 'desktop',
    MOBILE = 'mobile'
}

export const DEVICE_THRESHOLDS = {
    [DeviceType.DESKTOP]: 1000
};

export interface IUiState {
    deviceType: DeviceType;
    isSidenavOpen: boolean;
    currentRouteName: string;
}

const initialState: IUiState = {
    deviceType: DeviceType.UNKNOWN,
    isSidenavOpen: true,
    currentRouteName: ''
};

@StoreConfig({ name: 'ui' })
export class UiStore extends EntityStore<IUiState> {
    constructor() {
        super(initialState);
    }
}

export const uiStore = new UiStore();
