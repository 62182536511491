import {propertyGroupsStore, PropertyGroupsStore} from "./property-groups.store";
import {IInitializable} from "../../portfolio/models/on-init.interface";
// import {ApiServiceInstance} from "../../api/api-service";
import {IPagedResponse} from "../../properties/models/paged-response.interface";
import {IPropertyGroup} from "./models/property-group.interface";
import {BehaviorSubject} from "rxjs";
import {PagingStore} from "../../utils/paging-store";
import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";
import {IProperty} from "../../properties/models/property.interface";
import { ISort } from "src/store/properties/search/search-properties.store";
import { api } from "src/api/api.service";

const CHUNKS_SIZE = 300;

export class PropertyGroupService implements IInitializable {

    isInitialized = false;
    searchQ: BehaviorSubject<string> = new BehaviorSubject<string>('');
    pagingStore: PagingStore = new PagingStore();
    constructor(protected propertyGroupStore: PropertyGroupsStore) {
    }

    replaceEmptyStrings(data: IPropertyGroup[], keys: Array<keyof IPropertyGroup>): IPropertyGroup[] {
        return data.map(record => {
            const copy = {...record};
            keys.forEach(key => {
                // @ts-ignore
                copy[key as Partial<keyof IPropertyGroup>] = copy[key as Partial<keyof IPropertyGroup>].map(str => {
                    if (typeof str === 'string' && str.length === 0) {
                        return '';
                    } else {
                        return str;
                    }
                });
            });
            return copy;
        });
    }


    private buildParams(page: number, size: number) {
        const params: Record<string, any> = {};
        params.page = page;
        params.size = size;

        // const {searchQ} = this.propertyGroupStore.getValue();
        // params.search = searchQ;

        return params;
    }

    async fetchPropertyGroups(page: number = 0, size: number = 10, loading: boolean = false) {
        this.pagingStore.setPagingData({page, size});

        // const sorting = this.getSorting();

        const params = this.buildParams(page, size);

        // params.sort = `${(sorting.field)},${sorting.type}`;

        const response = await api.get<IPagedResponse<IPropertyGroup>>('/properties/groups', {
            params: {
                ...params
            }
        });

        if (response.ok) {
            const { data } = response;

            this.propertyGroupStore.update(state => ({
                ...state,
                loading: false,
                groupsCount: data.total,
                groups: [
                    ...state.groups,
                    ...this.replaceEmptyStrings(data.result, ['tenants', 'phoneNumbers', 'emails']),
                ],
            }));
        }

        return {
            total: response.data.total,
        }
    }

    async fetchPropertyGroupById(groupId: number) {
        this.propertyGroupStore.setLoading(true);

        const response = await api.get<IProperty>(`/properties/${groupId}`);
        
        if (response.ok) {
            this.propertyGroupStore.update(state => ({
                ...state,
                currentGroup: {
                    ...response.data,
                    tenants: response.data.tenants.map(tenant => ({
                        ...tenant,
                        fullName: `${tenant.firstName} ${tenant.lastName}`,
                    }))
                },
            }));
        }

        this.propertyGroupStore.setLoading(false);
    }

    init() {
        this.searchQ.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(async search => {
                 // this.propertyGroupStore.setLoading(true);
                this.propertyGroupStore.update(state => ({
                    ...state,
                    searchQ: search,
                }))

                // const {size, page} = this.pagingStore.getPagingData();

                const { total } = await this.fetchPropertyGroups(0, CHUNKS_SIZE);

                const chunks = total / CHUNKS_SIZE;

                for (let i = 1; i <= chunks; i++) {
                    setTimeout(() => {
                        return this.fetchPropertyGroups(i, CHUNKS_SIZE);
                    }, 0);
                }
            }),
        )
        .subscribe();


        this.isInitialized = true;
    }

    changeSearch(q: string) {
        this.searchQ.next(q);
    }

    resetCurrentTenant() {
        this.propertyGroupStore.update(state => ({
            ...state,
            currentGroup: null,
        }))
    }

    getSorting() {
        return this.propertyGroupStore.getValue().sort;
    }

    public setSorting(sorting: ISort<keyof IPropertyGroup>) {
        this.propertyGroupStore.update(state => ({
            ...state,
            sort: sorting,
        }));
    }

    setGroupsTotal(total: number) {
        this.propertyGroupStore.update(state => ({
            ...state,
            groupsTotal: total,
        }));
    }
}

export const propertyGroupsService = new PropertyGroupService(propertyGroupsStore);
