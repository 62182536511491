import {IUiComponent} from "../../../types/ui-component.interface";
import React from "react";
import CssClasses from './label.module.scss';

interface IProps extends IUiComponent {
    htmlFor?: string;
}

export function InputLabel(props: IProps) {
    return <label {...props} className={CssClasses.label}>
        {props.children}
    </label>
}
