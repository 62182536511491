import React, { useEffect, useMemo, useState } from "react";
import {SearchInput, SearchInputType} from "../../../ui/components/forms/inputs/search";
import {INamedEntity} from "../../../../store/properties/models/named-entity.interface";
import {guid} from "@datorama/akita";
import { Tooltip } from "@material-ui/core";
import { CommonCheckbox } from "src/components/ui/components/forms/inputs/checkbox";
import { InfiniteList } from "src/components/ui/components/autocomplete/infinite-list";
import { List } from 'immutable';
import { IndexRange } from 'react-virtualized';
import { autocompleteService } from "src/store/autocomplete/autocomplete.service";
import { SearchType } from "src/store/autocomplete/models/autocomplete.interface";
import { useSearchData } from "src/store/autocomplete/hooks/use-search-data";
import { SearchableListHolder, SearchItem, SearchLabel } from "./styled";
import { useDebounce } from "src/store/autocomplete/hooks/use-debounce";

const DEBOUNCE = 400;

interface IProps {
    id: string;
    url: string;
    placeholderText?: string;
    options?: any[];
    searchType: SearchType;
    selectedCriteria?: any[];

    onSearchChange(q: string): void;
    onSelect(item: INamedEntity): void;
}

let START_PAGE = 0;
const CHUNK_SIZE = 10;

export function SearchableList({
    id,
    url,
    placeholderText,
    onSearchChange, 
    onSelect,
    searchType,
    selectedCriteria = [],
}: IProps) {
    const [searchData, searchStatus] = useSearchData();
    const [value, setValue] = useState<string>('');

    const debouncedValue = useDebounce(value, DEBOUNCE); 

    const data = useMemo<{
        searchList: any[];
        searchTotal: number;
        searchType: SearchType;
    }>(() => {
        if (searchData[id]) {
            return searchData[id];
        }

        return {
            searchList: [],
            searchTotal: 0,
            searchType: SearchType.PORTFOLIOS,
        };
        
    }, [searchData]);

    const options = useMemo(() => {
        return data.searchList.map(listItem => {
            return {
                ...listItem,
                isSelected: !!selectedCriteria.some(item => item.id === listItem.id),
                key: id,
            };
        });
    }, [data, selectedCriteria]);

    useEffect(() => {
        START_PAGE = 0;

        autocompleteService.fetchSearchData({
            id,
            url,
            multiple: true,
            page: START_PAGE,
            size: CHUNK_SIZE,
            search: debouncedValue,
            searchType,
        });
    }, [debouncedValue]);

    const loadNextPage = async (params: IndexRange) => {
        autocompleteService.fetchSearchData({
            id,
            url,
            multiple: true,
            page: ++START_PAGE,
            size: CHUNK_SIZE,
            search: debouncedValue,
            searchType,
            isNextPage: true,
        });
    }

    const handleChange = (value: string) => {
        setValue(value);
    }

    const handleSelect = (item: any) => {
        onSelect(item);
    }

    return <div>
        <SearchInput onChange={handleChange} value={value} inputType={SearchInputType.SECONDARY} placeholderText={placeholderText}/>
        <SearchableListHolder>
            <InfiniteList 
                hasNextPage={data.searchList.length < data.searchTotal} 
                isNextPageLoading={searchStatus[id]?.loading} 
                list={List(data?.searchList as any[])}
                labelField="name"
                loadNextPage={loadNextPage}
                customListRenderer={({ index, key, style }) => {
                    const option = options[index as number];
                    const inputId = guid();
                
                    return <SearchItem key={key} style={style} >
                        {
                            option
                                ? <CommonCheckbox 
                                    labelColor="#789EFF" 
                                    checked={option?.isSelected} 
                                    onChange={() => handleSelect(option)} 
                                    id={inputId}/>
                                : null
                        }
                        <Tooltip title={option?.name} placement="top-start">
                            <SearchLabel htmlFor={inputId}>
                                {option ? option?.name : 'Loading...'}
                            </SearchLabel>
                        </Tooltip>
                    </SearchItem>
                }} />
        </SearchableListHolder> 
    </div>
}