import React, { Fragment } from 'react';
import { TemplatesTable, TemplateType } from '../../../templates-table';
import { ITemplate } from 'src/store/admin-settings/models/template.interface';
import { Title } from 'src/components/ui/components/forms/title';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Panel } from 'src/components/ui/components/panel';
import { ISMSNotification } from 'src/store/admin-settings/models/sms-notification.interface';

import styles from '../../../admin.module.scss';
import { InfoIcon, SettingsOutlinedIcon } from 'src/components/ui/icons';

interface ISMSSettingsProps {
    templates: ITemplate[];
    smsNotification: Partial<ISMSNotification>;
    isMobile?: boolean;
}

export const SMSSettings: React.FC<ISMSSettingsProps> = ({ templates, smsNotification, isMobile }) => {
    return (
        <Fragment>
            <Panel additionalStyles={
                isMobile ? {
                    margin: '-22px 0px 30px',
                    borderRadius: '0 0 0 10px',
                } : { margin: '-5px 0 30px', borderRadius: '0 0 10px 10px' }
            }>
                <Title
                    icon={<SettingsOutlinedIcon />}
                    iconStyle={{ fontSize: '14px', width: '30px', height: '30px' }}
                    text="SMS Settings"
                />
            </Panel>
            <Panel additionalStyles={{ margin: '-5px 0 30px' }}>
                <div className={styles.panelTop}>
                    <Title
                        icon={<InfoIcon />}
                        iconStyle={{ fontSize: '14px', width: '30px', height: '30px' }}
                        text="Showing Notification"
                    />
                    {/* <Button
                        icon={<EditOutlined style={{fontSize: 14}}/>}
                        btnVariant={ButtonVariant.OUTLINE}
                        onClick={() => {}}
                        text="Edit"
                    /> */}
                </div>
                <div className={styles.form_wrapper}>
                    <Form<Partial<any>>
                        type={FormType.DEFAULT}
                        data={smsNotification}
                        readOnly
                        fields={{
                            fromPhoneNumber: {
                                index: 0,
                                label: 'From Phone Number',
                                fieldType: InputType.STRING,
                            },
                        }}
                    />
                </div>
            </Panel>
            <TemplatesTable templateType={TemplateType.SMS} isMobile={isMobile} />
        </Fragment>
    );
}