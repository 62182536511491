import { createEntityQuery } from "@datorama/akita";
import { ISearchOwnersState, searchOwnersStore } from "./search-owners.store";
import { INamedEntity } from "../../properties/models/named-entity.interface";

const osq = createEntityQuery<ISearchOwnersState>(searchOwnersStore);

export const allFilters$ = osq.select(({ selectedCriteria: { portfolio, erManaging }}) => {
    const items: INamedEntity[] = [];

    portfolio.forEach(p => {
        items.push({
            id: p.id,
            name: p.name
        });
    });

    if (erManaging !== 'ANY') {
        items.push({
            id: erManaging as any,
            name: `Appointment Type: ${erManaging}`,
            key: 'type'
        })
    }

    return items;

});

export const selectedCriteria$ = osq.select(state => state.selectedCriteria);
export const searchData$ = osq.select(state => state.searchData);
export const appliedFilters$ = osq.select(state => state.appliedFilters);

export const searchOwnersQuery = {
    allFilters$,
    selectedCriteria$,
    searchData$,
    appliedFilters$,
};
