import { ChevronLeft, Edit } from '@material-ui/icons';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';

import { useObservable } from '@libreact/use-observable';
import { adminSettingsQuery } from 'src/store/admin-settings/admin-settings.query';
import { BlockingLoader } from 'src/components/ui/components/loaders/blocking-loader';
// import { TemplateType } from '../../templates-table';
import { adminSettingsService } from 'src/store/admin-settings/admin-settings.service';
import { useTemplate } from 'src/store/admin-settings/hooks/use-template';

import { Dropdown } from 'src/components/ui/components/dropdown/dropdown';
import { ITemplate } from 'src/store/admin-settings/models/template.interface';
import { OwnerIcon, ProspectIcon, TenantIcon, PersonIcon, SaveIcon } from 'src/components/ui/icons';

import { IntegerInput } from 'src/components/ui/components/forms/inputs/int-input';
import { IFollowUp } from 'src/store/admin-settings/models/followup.interface';
import { LeadStatus } from 'src/store/prospects/models/prospect.interface';
// import adminStyles from '../../admin.module.scss';
import styles from './follow-up-editor.module.scss';
import baseStyles from 'src/styles/base.module.scss';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { ContentHolder, headMobileStyles, panelMobileStyles } from 'src/components/ui/main-layout/layout';
import { uiQuery } from 'src/store/ui-storage/ui.query';

const AGENTS = 'AGENTS';
const PROSPECTS = 'PROSPECTS';
const OWNERS = 'OWNERS';
const TENANTS = 'TENANTS';

const statusItems = [
    {
        value: LeadStatus.FOR_FOLLOW_UP,
        label: <div>For Follow Up</div>
    },
    {
        value: LeadStatus.LOST,
        label: <div>Lost</div>
    },
    {
        value: LeadStatus.APPLICANT,
        label: <div>Applicant</div>
    },
];

const initialFormState: Partial<IFollowUp> = {
    trigger: 'EVENT_TYPE',
    eventType: 'MEETING',
    hours: 1,
    hourType: 'AFTER',
    newLeadStatus: LeadStatus.FOR_FOLLOW_UP,
    previousLeadStatus: LeadStatus.FOR_FOLLOW_UP,
    receivers: [],
    templateId: 0,
};

export const FollowUpEditor = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams<any>();

    const [isNew] = useState(location.pathname.includes('new'));
    const [editMode] = useState((params.id && location.pathname.includes('edit')));
    const [viewMode] = useState((params.id && !location.pathname.includes('edit')));
    
    const [,,, allTemplates] = useTemplate();
    const [loading] = useObservable(adminSettingsQuery.loading$);
    const [formState, setFormState] = useState<Partial<IFollowUp>>(initialFormState);
    const [selectedFollowUp] = useObservable(adminSettingsQuery.selectedFollowUp$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        if (selectedFollowUp) {
            setFormState(selectedFollowUp);
        }

        return () => {
            if (!history.location.pathname.includes('edit') && !viewMode) {
                adminSettingsService.resetCurrentFollowup()
            }
        };
    }, [history.location.pathname, viewMode, selectedFollowUp]);

    useEffect(() => {
        if (!selectedFollowUp && !isNew) {
            adminSettingsService.fetchFollowUpById(Number(params.id));
        }
    }, [editMode, viewMode, isNew, params.id, selectedFollowUp]);

    useEffect(() => {
        if (!formState.template && allTemplates.length) {
            setFormState((prevState: any) => ({
                ...prevState,
                templateId: allTemplates[0].id,
            }));
        }
    }, [allTemplates, formState.template]);

    const availableTemplates = useMemo(() => {
        if (Array.isArray(allTemplates) && allTemplates.length) {
            return allTemplates.filter(template => !template.systemData || template.systemData === null);
        }

        return [];
    }, [allTemplates]);

    const handleValuesChange = (data: any) => {
        setFormState((prevState) => ({
            ...prevState,
            ...data,
        }));
    }

    const handleSelectChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
        setFormState(prevState => ({
            ...prevState,
            [field]: e.target.value,
        }));
    }

    const handleSave = () => {
        adminSettingsService.addFollowUp(formState as Partial<IFollowUp>);

        history.push('/admin/settings?tab=auto_follow_ups');
    }

    const eventFields = useMemo<any>(() => {
        return {
            eventType: {
                index: 1,
                label: 'Event Type',
                labelDisplay: 'block',
                wrapperStyles: {
                    flexDirection: 'row',
                },
                height: 'auto',
                readonly: viewMode,
                fieldType: InputType.CUSTOM_ELEMENT,
                // groupStyles: {
                //     margin: editMode || isNew ? '-22px 0 0 -48px' : '0',
                // },
                customElement: <div>
                    <Dropdown
                        onChange={handleSelectChange('eventType')}
                        value={formState.eventType}
                        menuItems={[
                            {
                                value: 'MEETING',
                                content: <div>Meeting</div>
                            },
                            {
                                value: 'SHOWING',
                                content: <div>Showing</div>
                            },
                            {
                                value: 'OTHER',
                                content: <div>Other</div>
                            },
                        ]} />
                </div>
            },
            receivers: {
                index: 3,
                label: 'Receiver',
                labelDisplay: 'block',
                wrapperStyles: {
                    flexDirection: 'row',
                    margin: '0 0 14px',
                },
                height: 'auto',
                fieldType: (editMode || isNew) ? InputType.SELECT : InputType.CUSTOM_ELEMENT,
                customElement: <div style={{ fontWeight: 'bold' }}>{ formState.receivers?.join(', ') }</div>,
                // readonly: viewMode,
                groupStyles: {
                    margin: isMobile ? '0 0 0 20px' : '0 0 0 10px',
                },
                selectableItems: [
                    {
                        label:  (
                            <div className={styles.checboxItem}>
                                <PersonIcon />
                                <span>Agents</span>
                            </div>
                        ) as unknown as string,
                        value: AGENTS,
                    },
                    {
                        label:  (
                            <div className={styles.checboxItem}>
                                <TenantIcon />
                                <span>Tenants</span>
                            </div>
                        ) as unknown as string,
                        value: TENANTS,
                    },
                    {
                        label:  (
                            <div className={styles.checboxItem}>
                                <OwnerIcon />
                                <span>Owners</span>
                            </div>
                        ) as unknown as string,
                        value: OWNERS,
                    },
                    {
                        label:  (
                            <div className={styles.checboxItem}>
                                <ProspectIcon />
                                <span>Prospects</span>
                            </div>
                        ) as unknown as string,
                        value: PROSPECTS,
                    }
                ]
            },
        };
    }, [formState, editMode, isNew, viewMode, isMobile]);

    const updateStatusFields = useMemo<any>(() => {
        return {
            previousLeadStatus: {
                index: 1,
                label: 'Previous Lead status',
                labelDisplay: 'block',
                wrapperStyles: {
                    flexDirection: 'row',
                },
                height: 'auto',
                readonly: viewMode,
                fieldType: InputType.DROPDOWN,
                selectableItems: statusItems,
            },
            newLeadStatus: {
                index: 2,
                label: 'New Lead status',
                labelDisplay: 'block',
                wrapperStyles: {
                    flexDirection: 'row',
                },
                height: 'auto',
                readonly: viewMode,
                fieldType: InputType.DROPDOWN,
                selectableItems: statusItems,
            },
        };
    }, [viewMode]);

    const commonFields = useMemo<any>(() => {
        return {
            trigger: {
                index: 0,
                label: 'Trigger',
                labelDisplay: 'block',
                wrapperStyles: {
                    flexDirection: 'row',
                },
                height: 'auto',
                readonly: viewMode,
                fieldType: InputType.CUSTOM_ELEMENT,
                // groupStyles: {
                //     margin: editMode || isNew ? '-22px 0 0 -48px' : '0',
                // },
                customElement: <Dropdown
                    onChange={handleSelectChange('trigger')}
                    value={formState.trigger}
                    menuItems={[
                        {
                            value: 'EVENT_TYPE',
                            content: <div>Event type</div>
                        },
                        {
                            value: 'PROSPECT_STATUS_UPDATE',
                            content: <div>Prospect status update</div>
                        },
                    ]} />
            },
            hours: {
                index: formState.trigger === 'EVENT_TYPE' ? 2 : 3,
                label: 'When (hours)',
                labelDisplay: 'block',
                wrapperStyles: {
                    flexDirection: 'row',
                },
                height: 'auto',
                readonly: viewMode,
                fieldType: InputType.CUSTOM_ELEMENT,
                // groupStyles: {
                //     margin: editMode || isNew ? '-22px 0 0 -48px' : '0',
                // },
                customElement: <div className={styles.whenHours}>
                    <IntegerInput value={formState.hours as number} onChange={handleSelectChange('hours')} type="square" />
                    <Dropdown
                        onChange={handleSelectChange('hourType')}
                        value={formState.hourType}
                        menuItems={[
                            {
                                value: 'AFTER',
                                content: <div>After</div>
                            },
                            {
                                value: 'BEFORE',
                                content: <div>Before</div>
                            },
                        ]} />
                </div>
            },
            templateId: {
                index: 4,
                label: 'Template',
                labelDisplay: 'block',
                wrapperStyles: {
                    flexDirection: 'row',
                },
                height: 'auto',
                readonly: viewMode,
                fieldType: InputType.CUSTOM_ELEMENT,
                // groupStyles: {
                //     margin: editMode || isNew ? '-22px 0 0 -48px' : '0',
                // },
                customElement: <Dropdown
                    onChange={handleSelectChange('templateId')}
                    value={formState.templateId}
                    menuItems={availableTemplates.map((template: ITemplate) => ({
                            value: template.id,
                            content: <div>{template.name}</div>
                        })
                    )} />
            },
        };
    }, [formState, availableTemplates, viewMode]);

    if (editMode && loading) {
        return <BlockingLoader />
    }

    return <ContentHolder noPadding smallPadding>
        <Link className={baseStyles.link_wrapper} to={'/admin/settings?tab=auto_follow_ups'}>
            <ChevronLeft /> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
        </Link>
        <Panel additionalStyles={
            isMobile ? headMobileStyles : { marginTop: 7 }
        } type={PanelType.EMPTY}>
            <div className={styles.top_wrapper}>
                <Header3 style={{margin: '10px 0'}}>
                    {
                        editMode 
                            ? 'Edit Follow Up'
                            : viewMode
                                ? 'Follow Up'
                                : 'Create Follow Up'
                    }
                </Header3>
                {
                    !isMobile
                        ? (
                            <div className={styles.actionBarHolder}>
                                {
                                    editMode || isNew
                                        ? (
                                            <Button
                                                icon={<SaveIcon />}
                                                btnVariant={ButtonVariant.PRIMARY}
                                                onClick={handleSave}
                                                text="Save"
                                            />
                                        )
                                        : (
                                            <Button
                                                icon={<Edit style={{fontSize: 14}}/>}
                                                btnVariant={ButtonVariant.PRIMARY}
                                                onClick={() => history.push(`/admin/settings/followup/${selectedFollowUp?.id}/edit`)}
                                                text="Edit"
                                            />
                                        )
                                }
                            </div>
                        )
                        : null
                }
            </div>
        </Panel>
        <Panel additionalStyles={
            isMobile ? panelMobileStyles : { marginTop: 7 }
        }>
            <FormHolder>
                <Form<any>
                    type={FormType.DEFAULT}
                    onChange={handleValuesChange}
                    data={formState}
                    readOnly={viewMode}
                    fields={
                        formState.trigger === 'EVENT_TYPE'
                        ? {
                            ...commonFields,
                            ...eventFields
                        }
                        : {
                            ...commonFields,
                            ...updateStatusFields
                        }
                    }
                />
            </FormHolder>
        </Panel>
    </ContentHolder>
}