import {INamedEntity} from "./named-entity.interface";
import {IPortfolio} from "../../portfolio/models/portfolio.interface";
import {ITenant} from "../../tenants/models/tenant.interface";
import { IGroup } from "src/store/tenants/groups/models/group.interface";

export enum PropertyStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ARCHIVED = 'ARCHIVED'
}

export interface IProperty {
    id: number;
    amenities: INamedEntity[];
    portfolio: IPortfolio;
    bathrooms: number;
    bedrooms: number;
    neighborhoodName?: string;
    buildingCity: number;
    buildingState: number;
    keyCode: string;
    neighborhood: INamedEntity;
    postalCode: string;
    targetRent: number;
    readyToLease: 'Yes' | 'No';
    status: PropertyStatus;
    title: string;
    unitAddress: string;
    unitAddress2: string;
    createdDate?: string;
    unitId: number;
    amenityNames: string;
    tenants: ITenant[];
    notes: string;
    city: string;
    gas: string;
    heat: string;
    water: string;
    electricity: string;
    groups: IGroup[];
    doWeManage: boolean;
    company?: string;
    furnitureIncluded?: string;
    activelyRenting?: string;
}
