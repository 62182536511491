import {useObservable} from "@libreact/use-observable/dist";
import {uiQuery} from "../../../../store/ui-storage/ui.query";
import {DesktopHeader} from "./desktop-header";
import {DeviceType} from "../../../../store/ui-storage/ui.store";
import React from "react";
import {MobileHeader} from "./mobile-header";

export function Header() {
    const [deviceType] = useObservable(uiQuery.deviceType$);

    switch (deviceType) {            
        case DeviceType.MOBILE:
            return <MobileHeader />
        default:
            return <DesktopHeader />
    }
}
