import React, { Fragment, useMemo, useState } from 'react'
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form'

import { Panel, PanelType } from 'src/components/ui/components/panel'
import { SearchInput, SearchInputType } from 'src/components/ui/components/forms/inputs/search'
import { Table } from 'src/components/ui/components/table'
import { LeadStatus } from 'src/store/prospects/models/prospect.interface'
import { IDataTableColumn } from 'react-data-table-component'
import { useObservable } from '@libreact/use-observable'
import { conditionalRowStyles } from 'src/components/containers/admin/templates-table'

import { ProspectIcon } from 'src/components/ui/icons'
import { isEqual } from 'lodash'
import { FormHolder } from 'src/components/ui/components/forms/form-holder'
import { uiQuery } from 'src/store/ui-storage/ui.query'
import { TitleHolder } from 'src/components/containers/properties/pages/list'
import { useProspectsLeading } from 'src/store/staff/hooks/use-prospects-leading'

import styles from '../staff-info.module.scss'

const leadStatusMap = new Map();

leadStatusMap.set('FOR_FOLLOW_UP', 'For Follow Up');
leadStatusMap.set('LOST', 'Lost');
leadStatusMap.set('APPLICANT', 'Applicant');

interface IAgentProspectsTable {
    syncId: string | number;
    name: string;
    email: string;
    phoneNumber: string;
    leadStatus: LeadStatus;
    followUpsNumber: number;
    location: string;
}

const columns: IDataTableColumn<IAgentProspectsTable>[] = [
    {
        name: 'Sync ID',
        selector: 'id',
        sortable: true,
        minWidth: '100px',
        maxWidth: '120px',
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
        minWidth: '150px',
        cell: (row) => {
            return (
                <div className={styles.nameCell}>
                    <TitleHolder>{ row.name }</TitleHolder>
                </div>
            )
        }
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Phone number',
        selector: 'phoneNumber',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Lead Status',
        selector: 'leadStatus',
        sortable: true,
        minWidth: '150px',
        cell: (row) => <div>{leadStatusMap.get(row.leadStatus)}</div>,
    },
    {
        name: 'Number of follow ups',
        selector: 'followUpsNumber',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Location',
        selector: 'location',
        sortable: true,
        minWidth: '150px',
    },
];

interface IProspectsLeadingProps {}

const ProspectsLeading: React.FC<IProspectsLeadingProps> = () => {
    const [prospectsLeading, prospectsLeadingTotal, isLoading] = useProspectsLeading();

    const [search] = useState('');

    const [isMobile] = useObservable(uiQuery.isMobile$);

    const prepearedProspects = useMemo(() => {
        if (prospectsLeading?.length) {
            return prospectsLeading
                .map(prospect => ({
                    ...prospect,
                    name: `${prospect.firstName} ${prospect.lastName}`,
            }));
        }
    }, [prospectsLeading, search]);

    // const onSort = (column: IDataTableColumn<IAgentProspectsTable & INumerated>, sortDirection: "desc" | "asc") => {
    //     searchProspectsService.setSorting({
    //         type: sortDirection,
    //         field: column.selector as keyof IProspect
    //     });
    //     staffService.fetchStaffProspects(staff.id);
    // }

    return (
        <Fragment>
            <Panel additionalStyles={
                isMobile ? { 
                    margin: '-7px 0 0',
                    borderRadius: '0 0 0 10px',
                } : { 
                    margin: '14px 20px',
                    borderRadius: '0 10px 10px 10px' 
                }
            }>
                <FormHolder
                    title="Prospects Leading"
                    icon={<ProspectIcon />}>
                    <Form<any>
                        type={FormType.DEFAULT}
                        data={{
                            prospectsLeading: prospectsLeadingTotal,
                        }}
                        readOnly
                        fields={{
                            prospectsLeading: {
                                index: 0,
                                label: 'Total',
                                labelDisplay: 'block',
                                wrapperStyles: {
                                    flexDirection: 'row',
                                },
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <div>{ (prospectsLeadingTotal && !isLoading) ? prospectsLeadingTotal : 0 }</div>
                            },
                        }}
                    />
                </FormHolder>
            </Panel>
            <Panel type={PanelType.EMPTY} additionalStyles={
                isMobile ? { margin: '27px 0' } : { margin: '27px 16px' }
            }>
                <div className={styles.list_action_bar}>
                    <div className={styles.list_action_bar_left}>
                        <SearchInput
                            id="leadeing-search"
                            inputType={SearchInputType.PRIMARY}
                            placeholderText="Search..."
                        />
                    </div>
                </div>
            </Panel>
            <Panel noPadding sideBorder additionalStyles={
                isMobile ? { margin: '27px 0' } : { margin: '27px 14px' }
            }>
                <Table<IAgentProspectsTable>
                    // onSelectionChange={handleSelectionChange}
                    // onRowClicked={onRowClicked}'
                    pagination={false}
                    totalRecordsNumber={prospectsLeading?.length!}
                    columns={columns}
                    conditionalRowStyles={conditionalRowStyles}
                    // onSort={onSort}
                    loading={isLoading}
                    data={prepearedProspects as unknown as IAgentProspectsTable[]}
                    highlightRow={['firstName', 'lastName']}
                />
            </Panel>
        </Fragment>
    );
}

export default React.memo(ProspectsLeading, (prevProps, nextProps) => isEqual(prevProps, nextProps))