import { useObservable } from "@libreact/use-observable";
// import { get } from "lodash";
import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { ROWS_PER_PAGE, START_PAGE } from "src/store/common/hooks/use-paging";
// import { ILocationState, PagingType } from "src/store/common/models/common.interface";
import { ITenant } from "../models/tenant.interface";
import { tenantsQuery } from "../tenants.query";
import { tenantsService } from "../tenants.service";

export type TenantsHookTuple = [ITenant[], boolean];

export function useTenants (unitId?: string): TenantsHookTuple {
    // const location = useLocation<ILocationState>();
    // const [tenants] = useObservable(tenantsQuery.tenants$);
    const [tenants] = useObservable(tenantsQuery.selectFilteredTenants$);
    const [loading] = useObservable(tenantsQuery.isLoading$);

    useEffect(() => {
        if (!tenants.length) {
            tenantsService.getTenants(unitId);
        } else {
            // tenantsService.fetchTenants(
            //     get(location, `state[${PagingType.TENANTS}].activePage`, START_PAGE),
            //     get(location, `state[${PagingType.TENANTS}].rowsPerPage`, ROWS_PER_PAGE),
            // );
        }
    }, []);

    return [tenants, loading];
}