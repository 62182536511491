import React, {Fragment, useEffect, useMemo, useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import {ownersService} from "../../../../../store/owners/owners.service";
import {useObservable} from "@libreact/use-observable/dist";
import {ownersQuery} from "../../../../../store/owners/owners.query";
import {BlockingLoader} from "../../../../ui/components/loaders/blocking-loader";
import {RoutesEnum} from "../../../../routing/routes";
import {ChevronLeft} from "@material-ui/icons";
import {Panel, PanelType} from "../../../../ui/components/panel";
import {Header3} from "../../../../ui/components/headers";
import {Form, FormType, InputType} from "../../../../ui/components/forms/formBuilder/form";
import {IOwner} from "../../../../../store/owners/models/owner.interface";
import {ITab, Tabs} from "../../../../ui/components/tabs/tabs";
import {OwnerContactDetails} from "./tabs/contact-details";
import {OwnerPortfolio} from "./tabs/owner-portfolio";
import {Table} from "../../../../ui/components/table";
import {IProperty} from "../../../../../store/properties/models/property.interface";
import {IDataTableColumn} from "react-data-table-component";
import {INumerated} from "../../../../../store/properties/models/numerated.interface";
import {COLORS} from "../../../../ui/theme/variables/colors";
// import styled from "styled-components";
import {SearchInput, SearchInputType} from "../../../../ui/components/forms/inputs/search";
import { HomeIcon } from "src/components/ui/icons";
import { FormHolder } from "src/components/ui/components/forms/form-holder";

import CssClasses from "./owner-view.module.scss";
import baseStyles from "src/styles/base.module.scss";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { ContentHolder, headMobileStyles, panelMobileStyles } from "src/components/ui/main-layout/layout";
import { usePaging } from "src/store/common/hooks/use-paging";
import { PagingType } from "src/store/common/models/common.interface";
import { ContactHistory } from "src/components/ui/components/contact-history/contact-history";

interface IRouteParams {
    id: string;
}

const conditionalRowStyles = [
    {
        // actively renting & i % 2 === 0
        when: (row: INumerated & IProperty) => (row.i % 2 === 0) && (row.readyToLease === 'Yes'),
        style: {
            borderLeft: `2px solid ${COLORS.BLUE}`,
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    },
    {
        // actively renting & i % 2 === 1
        when: (row: INumerated & IProperty) => (row.i % 2 === 1) && (row.readyToLease === 'Yes'),
        style: {
            borderLeft: `2px solid ${COLORS.BLUE}`,
        },
    },

    {
        // !actively renting & i % 2 === 1
        when: (row: INumerated & IProperty) => (row.i % 2 === 0) && (row.readyToLease === 'No'),
        style: {
            borderLeft: `2px solid ${COLORS.DARK_ORANGE}`,
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    },
    {
        // !actively renting & i % 2 === 1
        when: (row: INumerated & IProperty) => (row.i % 2 === 1) && (row.readyToLease === 'No'),
        style: {
            borderLeft: `2px solid ${COLORS.DARK_ORANGE}`,
        },
    },
];

// const StatusWrapper = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     color: ${props => props.color};
//     * {
//         color: ${props => props.color};
//     }
// `;


interface IProps extends RouteComponentProps<IRouteParams> {
}

const columns: IDataTableColumn<IProperty>[] = [
    {
        name: 'Property',
        selector: 'unitAddress',
        minWidth: '150px'
    },
    {
        name: 'Address',
        selector: 'unitAddress',
        minWidth: '150px',
        cell: (row: IProperty) => {
            return (
                <div style={{ fontWeight: 'normal' }}>{`${row.unitAddress}, ${row.city}, ${row.buildingState}`}</div>
            );
        }
    },
    {
        name: 'Bedroom #',
        selector: 'bedrooms',
        minWidth: '150px'
    },
    {
        name: 'Bathrooms #',
        selector: 'bathrooms',
        minWidth: '150px'
    },
    {
        name: 'Target Rent',
        selector: 'targetRent',
        minWidth: '150px',
        cell: (row) => <div>
            <span>{ `$ ${row.targetRent } `}</span>
        </div>
    },
    // {
    //     name: 'Neighborhood',
    //     selector: 'neighborhood',
    //     minWidth: '150px'
    // },
    {
        name: 'Actively Renting',
        selector: 'activelyRenting',
        minWidth: '150px',
        cell: (row) => <div style={{ textTransform: 'capitalize' }}>
            { row.activelyRenting }
        </div>
    }
];

export function OwnersView(props: IProps) {
    const [,, pushLocation] = usePaging(PagingType.OWNERS);
    const [currentOwner] = useObservable(ownersQuery.currentOwner$);
    // const [isLoading] = useObservable(ownersQuery.isLoading$);

    const [isMobile] = useObservable(uiQuery.isMobile$);

    const [propertiesSearch, setPropertiesQ] = useState('');

    const {match, location} = props;

    const [ownerId, setOwnerId] = useState<number>();

    useEffect(() => {
        if (match.params.id) {
            setOwnerId(parseInt(match.params.id));
        }
    }, [match, setOwnerId]);

    useEffect(() => {
        if (ownerId) {
            ownersService.fetchOwnerById(ownerId);
        }
    }, [ownerId]);

    const prepearedOwner = useMemo(() => {
        if (currentOwner) {
            return {
                ...currentOwner,
                city: typeof currentOwner?.city === 'string' ? currentOwner.city : '',
                photo: '',
            };
        }
    }, [currentOwner]);

    if (!prepearedOwner || (prepearedOwner.id !== parseInt(match.params.id))) {
        return <BlockingLoader/>
    }

    const tabs: ITab[] = [
        {
            basePath: location.pathname,
            specificPath: '',
            children: OwnerContactDetails({owner: prepearedOwner}),
            tabName: 'Contact Details'
        },
        {
            basePath: location.pathname,
            specificPath: 'contact_history',
            children: <ContactHistory id={prepearedOwner.id} context="owners" field="owners" />,
            tabName: 'Contact History',
            panelType: PanelType.EMPTY,
        }
    ]
    prepearedOwner.portfolios.forEach(portfolio => {
        tabs.push({
            basePath: location.pathname,
            specificPath: `portfolio-${portfolio.id}`,
            children: OwnerPortfolio(portfolio, prepearedOwner.doWeManage),
            tabName: portfolio.name
        })
    });

    let currentSelectedPortfolioId = location.search.includes('portfolio-') ? location.search.split('portfolio-')[1] : null;
    let selectedPortfolio = currentSelectedPortfolioId ? prepearedOwner.portfolios.find(p => p.id === parseInt(currentSelectedPortfolioId!)) : undefined;


    const propertiesList = selectedPortfolio ?
        selectedPortfolio.propertyDtos
            .filter(p => p.unitAddress.toLowerCase().includes(propertiesSearch))
            .map(p => ({
                ...p,
                neighborhoodName: p.neighborhood ? p.neighborhood.name : ''
            })): [];

    return <ContentHolder noPadding smallPadding>
        <div style={{ cursor: 'pointer' }} className={baseStyles.link_wrapper} onClick={() => 
            pushLocation(RoutesEnum.OWNERS_LIST, PagingType.OWNERS)
        }>
            <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
        </div>
        <Panel additionalStyles={
            isMobile ? headMobileStyles : { marginTop: 7 }
        } type={PanelType.EMPTY}>
            <div className={CssClasses.top_wrapper}>
                <Header3 style={{margin: '10px 0'}}>
                    Owner Info
                </Header3>
            </div>
        </Panel>
        <Panel additionalStyles={
            isMobile ? panelMobileStyles : {}
        }>
            <FormHolder 
                title="Owner"
                icon={<HomeIcon />}>
                <Form<Partial<IOwner>>
                    type={FormType.DEFAULT}
                    data={prepearedOwner}
                    readOnly
                    fields={{
                        fullname: {
                            index: 1,
                            label: 'Name & Surname',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        id: {
                            index: 2,
                            label: 'ID',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                    }}
                />
            </FormHolder>
        </Panel>
        <Tabs
            tabs={tabs}
        />
        {selectedPortfolio && <Fragment>
            <Panel type={PanelType.EMPTY}>
                <div className={baseStyles.list_action_bar}>
                    <div className={baseStyles.list_action_bar_left}>
                        <SearchInput
                            inputType={SearchInputType.PRIMARY}
                            onChange={(e) => {
                                setPropertiesQ(e.toLowerCase().trim());
                            }}
                            placeholderText="Search..."
                        />
                    </div>
                </div>
            </Panel>
            <Panel noPadding sideBorder>
                <Table<IProperty>
                    pagination={false}
                    data={propertiesList}
                    totalRecordsNumber={selectedPortfolio.propertyDtos.length}
                    columns={columns}
                    highlightRow={['unitAddress']}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </Panel>
        </Fragment>}
    </ContentHolder>
}