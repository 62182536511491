import { IPagedResponse } from './../../../store/properties/models/paged-response.interface';
import React from "react";
import { IPaging } from "src/store/common/hooks/use-paging";
import { PagingType } from "src/store/common/models/common.interface";
import { IFetchRequest } from "../hooks/use-request";

export enum LoaderType {
    BLOCKING = 'BLOCKING',
    LOCAL = 'LOCAL',
    TOAST = 'TOAST',
    TAB = 'TAB',
}
export interface IFetcherChildrenArgs<TData, P extends unknown[]> {
    data: TData;
    changePage: (page: number) => void;
    changeRowsNumber: (rowPerPage: number, page: number) => void;
    sortColumn: (column: string, sortDirection: "asc" | "desc") => void;
    changeSearch: (searchQ: string) => void;
    applyFilters: () => void;
    request: IFetchRequest<TData, P>;
    refetch: () => void;
    paging: IPaging;
}

type FnFallback<TData, PActionParams extends unknown[]> = (request: IFetchRequest<TData, PActionParams>) => React.ReactElement<{ request: IFetchRequest<TData, PActionParams> }>;

export interface IDataFetcher<TData, PActionParams extends unknown[]> {
    children: (args: IFetcherChildrenArgs<TData, PActionParams>) => React.ReactElement<{ data: TData }>;
    cache?: boolean;
    initParams?: PActionParams;
    inititalState?: TData;
    action: (...params: PActionParams) => Promise<TData | void | any>;
    pagingType?: PagingType;
    loaderType?: LoaderType;
    emptyDataText?: string | React.ReactNode
    noDataFallback?: FnFallback<TData, PActionParams>;
    fallbackData?: TData | undefined;
}

export interface IErrorComponent {
    onClick: (...args: any) => void;
}

export interface IRenderData<TData, PActionParams extends unknown[]> extends IFetcherChildrenArgs<TData, PActionParams> {
    componentFn: (args: IFetcherChildrenArgs<TData, PActionParams>) => React.ReactElement<{ data: TData }>; 
}