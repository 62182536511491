import React, { ChangeEvent, Fragment, useMemo } from 'react'
import InputMask from 'react-input-mask';
import { Form, FormFieldDividerType, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form'
import { ICity, IPickupLocation, LeadStatus } from 'src/store/prospects/models/prospect.interface'
import { ProspectDto } from '../prospect-editor';
import { Dropdown } from 'src/components/ui/components/dropdown/dropdown'
import { IStaff, StaffRole } from 'src/store/staff/models/staff.inreface'
import { HomeIcon, InfoIcon, NoteIcon, PersonIcon } from 'src/components/ui/icons';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { Input } from 'src/components/ui/components/forms/inputs/input';

import editorStyles from '../prospect-editor.module.scss'
import { useObservable } from '@libreact/use-observable';
import { uiQuery } from 'src/store/ui-storage/ui.query';

const FOR_FOLLOW_UP = 'For Follow Up';
const LOST = 'Lost';
const APPLICANT = 'Applicant';

const leadStatuses = [
    {
        value: LeadStatus.FOR_FOLLOW_UP,
        name: FOR_FOLLOW_UP,
        content: <div>{FOR_FOLLOW_UP}</div>
    },
    {
        value: LeadStatus.LOST,
        name: LOST,
        content: <div>{LOST}</div>
    },
    {
        value: LeadStatus.APPLICANT,
        name: APPLICANT,
        content: <div>{APPLICANT}</div>
    },
];

const seenBeforeOptions = [
    {
        value: true,
        name: "Yes",
        content: <div>Yes</div>
    },
    {
        value: false,
        name: "No",
        content: <div>No</div>
    },
];

interface IContactDetailsProps {
    prospect: Partial<ProspectDto>;
    formState: Partial<ProspectDto>;
    cities: ICity[];
    pickupLocations: IPickupLocation[];
    isPickupLocationsLoading: boolean;
    staff: IStaff[];
    handleSelectChange: (key: string) => (e: ChangeEvent<HTMLInputElement>) => void;
    handleValuesChange: (data: Partial<ProspectDto>) => void;
}

export const ContactDetails: React.FC<IContactDetailsProps> = ({ 
    prospect,
    staff,
    formState,
    cities,
    pickupLocations,
    isPickupLocationsLoading,
    handleSelectChange,
    handleValuesChange }) => {
    const [isMobile] = useObservable(uiQuery.isMobile$);

    const prepearedState = useMemo(() => {
        if (formState.notificationIds) {
            const ids = (formState.notificationIds as number[]).map((id: number) => String(id));

            return {
                ...formState,
                notificationIds: ids,
            };
        }

        return {
            ...formState,
        };
        
    }, [formState]);

    const prepearedPreferences = useMemo(() => {
        if (!formState.approxBudget) {
            return {
                ...formState,
                approxBudget: {
                    from: formState.approxBudgetFrom || 0,
                    to: formState.approxBudgetTo || 0,
                },
            }
        }

        return {
            ...formState
        };
    }, [formState]);

    return (
        <Fragment>
            <FormHolder
                title="Contact Details"
                icon={<InfoIcon />}>
                <Form<Partial<ProspectDto>>
                    type={FormType.DEFAULT}
                    data={prepearedState}
                    // wrapperStyles={{
                    //     margin: 0,
                    // }}
                    onChange={handleValuesChange}
                    fields={{
                        email: {
                            index: 0,
                            label: 'Email',
                            labelDisplay: 'block',
                            fieldType: InputType.STRING,
                            height: 'auto',
                            placeholder: "Email",
                        },
                        phoneNumber: {
                            index: 1,
                            label: 'Mobile Number',
                            labelDisplay: 'block',
                            placeholder: 'Mobile Number',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            height: 'auto',
                            customElement: <InputMask 
                                className={editorStyles.input}
                                mask="(999) 999-9999" 
                                value={formState.phoneNumber}
                                placeholder="(999) 999-9999" 
                                onChange={handleSelectChange('phoneNumber')} >
                                <Input />
                            </InputMask>
                        },
                        notificationIds: {
                            index: 2,
                            label: 'Notification Method',
                            labelDisplay: 'block',
                            fieldType: InputType.SELECT,
                            height: 'auto',
                            withDividers: FormFieldDividerType.BOTTOM,
                            groupStyles: {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                margin: isMobile ? '4px 0 0 8px' : '4px 0 0 11px',
                            },
                            selectableItems: [
                                {
                                    label: 'SMS',
                                    value: "2",
                                },
                                {
                                    label: 'Email',
                                    value: "1",
                                }
                            ]
                        }
                    }}
                />
            </FormHolder>
            <FormHolder
                title="Preferences"
                icon={<HomeIcon />}>
                <Form<Partial<ProspectDto>>
                    type={FormType.DEFAULT}
                    data={prepearedPreferences}
                    // wrapperStyles={{
                    //     margin: 0,
                    // }}
                    onChange={handleValuesChange}
                    fields={{
                        location: {
                            index: 0,
                            label: 'Location',
                            labelDisplay: 'block',
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <Dropdown
                                onChange={handleSelectChange('location')}
                                value={formState.location}
                                menuItems={cities.map(city => ({
                                    value: city.name,
                                    name: city.name,
                                    content: <div>{city.name}</div>
                                }))} />
                        },
                        bedrN: {
                            index: 1,
                            label: 'Number Of Bedrooms',
                            labelDisplay: 'block',
                            fieldType: InputType.INT_INPUT,
                            height: 'auto',
                            groupStyles: {
                                margin: '10px 0 0'
                            },
                        },
                        approxBudget: {
                            index: 2,
                            label: 'Approximate Budget',
                            labelDisplay: 'block',
                            fieldType: InputType.NUMBER_BETWEEN,
                            height: 'auto',
                        },
                        pickupLocationId: {
                            index: 3,
                            label: 'Pick Up Location',
                            labelDisplay: 'block',
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <Dropdown
                                onChange={handleSelectChange('pickupLocationId')}
                                value={formState.pickupLocationId}
                                loading={isPickupLocationsLoading}
                                menuItems={pickupLocations?.map(pickupLocation => ({
                                    value: pickupLocation.id,
                                    name: pickupLocation.name,
                                    content: <div>{pickupLocation.name}</div>
                                }))} />
                        },
                        seenBefore: {
                            index: 4,
                            label: 'Seen Properties With Us Before',
                            labelDisplay: 'block',
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <Dropdown
                                onChange={handleSelectChange('seenBefore')}
                                value={formState.seenBefore}
                                menuItems={seenBeforeOptions} />
                        },
                        agentShowedId: {
                            index: 5,
                            label: 'Agent Showed',
                            labelDisplay: 'block',
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <Dropdown
                                onChange={handleSelectChange('agentShowedId')}
                                value={formState.agentShowedId}
                                placeholder="Select Agent"
                                disabled={!prepearedPreferences.seenBefore}
                                menuItems={staff
                                    .filter(agent => agent.role === StaffRole.AGENT)
                                    .map(agent => ({
                                        value: String(agent.id),
                                        name: agent.name,
                                        content: <div>{agent.name}</div>
                                    }))} />
                        },
                    }}
                />
            </FormHolder>
            <FormHolder
                title="Agent Attached"
                withDivider
                icon={<PersonIcon />}>
                <Form<Partial<ProspectDto>>
                    type={FormType.DEFAULT}
                    data={prospect}
                    // wrapperStyles={{
                    //     margin: 0,
                    // }}
                    readOnly
                    fields={{
                        agentAttachedId: {
                            index: 0,
                            label: 'Agent Name',
                            labelDisplay: 'block',
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <Dropdown
                            onChange={handleSelectChange('agentAttachedId')}
                            placeholder="Select Agent"
                            value={`${formState.agentAttachedId}`}
                            // disabled={!prepearedPreferences.seenBefore}
                            menuItems={staff
                                .filter(agent => agent.role === StaffRole.AGENT)
                                .map(agent => ({
                                    value: String(agent.id),
                                    name: agent.name,
                                    content: <div>{agent.name}</div>
                                }))} />
                        },
                        leadStatus: {
                            index: 1,
                            label: 'Lead Status',
                            labelDisplay: 'block',
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <Dropdown
                                onChange={handleSelectChange('leadStatus')}
                                value={formState.leadStatus}
                                menuItems={leadStatuses} />
                        },
                    }}
                />
            </FormHolder>
            <FormHolder
                title="Notes"
                icon={<NoteIcon />}>
                <Form<Partial<ProspectDto>>
                    type={FormType.DEFAULT}
                    data={prospect}
                    onChange={handleValuesChange}
                    // wrapperStyles={{
                    //     margin: 0,
                    // }}
                    fields={{
                        notes: {
                            index: 1,
                            label: 'Notes',
                            labelDisplay: 'block',
                            height: 'auto',
                            fieldType: InputType.STRING,
                        },
                    }}
                />
            </FormHolder>
        </Fragment>
    );
}