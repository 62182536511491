import React from "react";
import CssClasses from './forgot-password.module.scss';
import {Header4, Header6} from "../../../ui/components/headers";
import {Button, ButtonVariant} from "../../../ui/components/buttons/button";
import {RouteComponentProps} from 'react-router-dom';
import {RoutesEnum} from "../../../routing/routes";
import {ParseQueryParams} from "../../../helpers/query-params-parser";

interface IProps extends RouteComponentProps {

}

interface ISearchObj {
    email: string
}

export function ForgotPasswordEmailSent(props: IProps) {

    const onBack = () => {
        props.history.push(RoutesEnum.SIGN_IN)
    }

    const searchObj: ISearchObj = ParseQueryParams<ISearchObj>(props.history.location.search);

    return <div className={CssClasses.forgot_password}>
        <Header4 style={{textAlign: 'center'}}>
            Magic Link was sent successfully to &nbsp;
            <a className={CssClasses.forgot_password_link} href={`mailto:${searchObj.email}`}>{searchObj.email}</a>
        </Header4>
        <Header6 style={{fontWeight: 300, textAlign: 'center'}}>
            Please check your email. A link to update your password has been sent to your email
        </Header6>
        <Button style={{marginTop: 20}} btnVariant={ButtonVariant.PRIMARY} text="Resend Recovery Link" />
        <Button style={{marginTop: 20}} onClick={onBack} btnVariant={ButtonVariant.OUTLINE} text="Back To Sign In" />
    </div>
}