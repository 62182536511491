import { flow, get } from "lodash";
import { ITenant } from "./models/tenant.interface";
import { ITenantSearchCriteria, NotificationType } from "./search/search.store";

interface ITenantsFilter {
    searchCriteria: ITenantSearchCriteria;
    tenants: ITenant[];
}

export function notificationMethodsSMSFilter({ searchCriteria, tenants }: ITenantsFilter) {
    const { notificationMethod } = searchCriteria;

    if (!get(searchCriteria, 'notificationMethod', []).length && !notificationMethod.includes(NotificationType.sms)) {
        return {
            searchCriteria,
            tenants,
        };
    }

    const ft = tenants.filter((tenant: ITenant) => {
        if (notificationMethod.includes(NotificationType.email)) {
            return tenant.sms !== 'NO' || tenant.email;
        }

        return tenant.sms !== 'NO';
    });

    return {
        searchCriteria,
        tenants: ft,
    };
}

export function notificationMethodsEmailFilter({ searchCriteria, tenants }: ITenantsFilter) {
    const { notificationMethod } = searchCriteria;

    if (!get(searchCriteria, 'notificationMethod', []).length && !notificationMethod.includes(NotificationType.email)) {
        return {
            searchCriteria,
            tenants,
        };
    }

    const ft = tenants.filter((tenant: ITenant) => {
        if (notificationMethod.includes(NotificationType.sms)) {
            return tenant.sms !== 'NO' || tenant.email;
        }

        return tenant.email;
    });

    return {
        searchCriteria,
        tenants: ft,
    };
}

export function propertyFilter({ searchCriteria, tenants }: ITenantsFilter) {
    if (!get(searchCriteria, 'property', []).length) {
        return tenants;
    }

    return searchCriteria.property.flatMap(prop => tenants.filter((tenant: ITenant) => tenant?.property?.unitAddress.includes(prop.name)));
}

export const getFilteredTenants = flow([
    notificationMethodsSMSFilter,
    notificationMethodsEmailFilter,
    propertyFilter,
]);