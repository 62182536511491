import { arrayRemove, arrayUpdate, arrayUpsert, transaction } from "@datorama/akita";
import autobind from "autobind-decorator";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { TemplateType } from "src/components/containers/admin/templates-table";
import { calendarStore, CalendarStore } from "../calendar/calendar.store";
import { IInitializable } from "../portfolio/models/on-init.interface";
import { IPagedResponse } from "../properties/models/paged-response.interface";
import { ISort } from "../properties/search/search-properties.store";
import { snackbarService } from "../snackbar/snackbar.service";
import { SnackbarType } from "../snackbar/snackbar.store";
import { staffStore } from "../staff/staff.store";
import { PagingStore } from "../utils/paging-store";
import { adminSearchService, AdminSearchService } from "./admin-search/admin-search.service";
import { IAdminSearchCriteria } from "./admin-search/admin-search.store";
import { adminSettingsStore, AdminSettingsStore } from "./admin-settings.store";
import { IAutoNitificaton, IRecurrence } from "./models/autonotification.interface";
import { ICalendarSettings } from "./models/calendar.interface";
import { IEmailNotification } from "./models/email-notification.interface";
import { IFollowUp } from "./models/followup.interface";
import { ISMSNotification } from "./models/sms-notification.interface";
import { ITemplate, TemplateDto } from "./models/template.interface";

import { SYSTEM_MESSAGES, SNACK_TIMEOUT, DEBOUNCE } from './admin-settings.config';
import { IStaff } from "../staff/models/staff.inreface";
import { extractContent } from "../utils/extract-content";
import { isEmpty } from "lodash";
import { api, ApiResponse } from "src/api/api.service";

interface IFollowUpGetDto extends IPagedResponse<Partial<IFollowUp>> {}
interface IAutoNotificationsGetDto extends IPagedResponse<Partial<IAutoNitificaton>> {}

export enum AdminSearchType {
    TEMPLATES_EMAIL = 'TEMPLATES_EMAIL',
    TEMPLATES_SMS = 'TEMPLATES_SMS',
    NOTIFICATIONS = 'NOTIFICATIONS',
    FOLLOW_UPS = 'FOLLOW_UPS',
    AUTO_NOTIFICATIONS = 'AUTO_NOTIFICATIONS',
}

export class AdminSettingsService implements IInitializable {
    pagingStore = new PagingStore();
    isInitialized = false;

    constructor(
        private adminSettingsStore: AdminSettingsStore,
        private adminSearchService: AdminSearchService,
        private staffStore: any,
        private calendarStore: CalendarStore,
        private snackbarService: any,
    ) {}

    @autobind
    buildFilteredRequest(): Partial<IAdminSearchCriteria> {
        const params: Partial<IAdminSearchCriteria> = {};
        const { search } = this.adminSearchService.getCriteria();

        if (search && search.length > 0) {
            params.search = search;
        }

        return params;
    }

    //* Template 
    @transaction()
    async fetchTemplates(type: TemplateType, field: string, search?: string) {
        this.adminSettingsStore.setLoading(true);

        const sorting = this.getSorting();

        const params: Partial<IAdminSearchCriteria> = {
            ...this.buildFilteredRequest(),
            sort: sorting.field.includes('lastEditAgent')
                ? `lastEditAgent.name,${sorting.type}`
                :   `${(sorting.field)},${sorting.type}`
        };

        const response = await api.get<IPagedResponse<ITemplate[]>>(`/templates?settingType=${type}`, {
            params: {
                ...params,
                search,
            }
        });

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                [field]: response.data.result
                // [field]: response.data,
            }));
        }

        this.adminSettingsStore.setLoading(false);

        return response.data;
    }

    @transaction()
    async fetchTemplateById(templateId: number) {
        this.adminSettingsStore.setLoading(true);

        const response = await api.get<ITemplate>(`/templates/${templateId}`);

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                selectedTemplate: response.data,
            }));
        }

        this.adminSettingsStore.setLoading(false);
    }

    @transaction()
    async addTemplate(templateDto: Partial<TemplateDto>, type: TemplateType) {
        const body = extractContent(templateDto?.notice as string);

        if (type === TemplateType.EMAIL && templateDto?.subject === '') {
            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.EMPTY_SUBJECT,
                type: SnackbarType.ERROR,
            }, SNACK_TIMEOUT);

            return null; 
        }

        if (!body && (!templateDto?.systemData || isEmpty(templateDto?.systemData))) {
            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.TEMPLATE_NO_BODY,
                type: SnackbarType.ERROR,
            }, SNACK_TIMEOUT);

            return null;
        }

        const selectedTemplate = this.adminSettingsStore.getValue().selectedTemplate;

        const dto = {
            ...templateDto,
            type,
            lastEditDate: new Date(),
            notice: type === 'SMS' ? extractContent(templateDto.notice as string) : templateDto.notice,
        }

        if (selectedTemplate?.id) {
            await this.updateTemplate(selectedTemplate.id, templateDto, type);

            return true;
        } else {
            this.adminSettingsStore.setLoading(true);

            const response = await api.post<ITemplate>('/templates', dto);

            if (response.ok) {
                if (type === TemplateType.EMAIL) {
                    this.adminSettingsStore.update(state => ({
                        ...state,
                        emailTemplates: arrayUpsert(state.emailTemplates, response.data.id as number, response.data)
                    }))
                }
                
                if (type === TemplateType.SMS) {
                    this.adminSettingsStore.update(state => ({
                        ...state,
                        smsTemplates: arrayUpsert(state.smsTemplates, response.data.id as number, response.data)
                    }))
                }

                this.snackbarService.createSnackbar({
                    text: SYSTEM_MESSAGES.TEMPLATE_CREATED,
                    type: SnackbarType.SUCCESS,
                }, SNACK_TIMEOUT);
            }
    
            this.adminSettingsStore.setLoading(false);

            return true;
        }
    }

    async updateTemplate(id: number, templateDto: Partial<TemplateDto> , type: TemplateType) {
        this.adminSettingsStore.setLoading(true);

        const dto = {
            ...templateDto,
            type,
            lastEditDate: new Date(),
            notice: type === 'SMS' ? extractContent(templateDto.notice as string) : templateDto.notice,
        }

        const response = await api.patch<ITemplate>(`/templates/${id}`, dto);

        if (response.ok) {
            if (type === TemplateType.EMAIL) {
                this.adminSettingsStore.update(state => ({
                    ...state,
                    emailTemplates: arrayUpsert(state.emailTemplates, response.data.id as number, response.data)
                }))
            }
            
            if (type === TemplateType.SMS) {
                this.adminSettingsStore.update(state => ({
                    ...state,
                    smsTemplates: arrayUpsert(state.smsTemplates, response.data.id as number, response.data)
                }))
            }

            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.TEMPLATE_UPDATED,
                type: SnackbarType.SUCCESS,
            }, SNACK_TIMEOUT);
        }

        this.adminSettingsStore.setLoading(false);
    }

    async deleteTemplate(templateId: string | number, type: TemplateType) {
        this.adminSettingsStore.setLoading(true);

        const response = await api.del(`/templates/${templateId}`);

        if (response.ok) {
            const field = type === TemplateType.EMAIL ? 'emailTemplates' : 'smsTemplates';
            this.adminSettingsStore.update(state => ({
                ...state,
                [field]: arrayRemove(state[field], templateId),
            }));

            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.TEMPLATE_DELETED,
                type: SnackbarType.SUCCESS,
            }, SNACK_TIMEOUT);
        }

        this.adminSettingsStore.setLoading(false);
    }

    //* Notification
    @transaction()
    async fetchEmailNotifications() {
        this.adminSettingsStore.setLoading(true);

        const response = await api.get<IEmailNotification>('/emailnotifications');

        if (response.ok) {
            this.adminSettingsStore.update(state => {
                return {
                    ...state,
                    emailNotification: response.data,
                };
            });
        }

        this.adminSettingsStore.setLoading(false);
    }

    @transaction()
    async fetchSMSNotifications() {
        this.adminSettingsStore.setLoading(true);

        const response = await api.get<ISMSNotification>('/smsnotifications');

        if (response.ok) {
            this.adminSettingsStore.update(state => {
                return {
                    ...state,
                    smsNotification: response.data,
                };
            });
        }

        this.adminSettingsStore.setLoading(false);
    }

    @autobind
    @transaction()
    async saveEmailSettings(emailNotificationDto: Partial<IEmailNotification>) {
        let response: ApiResponse<IEmailNotification>;
        const emailSettings = this.adminSettingsStore.getValue().emailNotification;

        this.adminSettingsStore.setLoading(true);

        if (emailSettings.id) {
            response = await api.patch<IEmailNotification>(`/emailnotifications/${emailSettings.id}`, emailNotificationDto);
        } else {
            response = await api.post<IEmailNotification>('/emailnotifications', emailNotificationDto);
        }

        if (response.ok) {
            this.adminSettingsStore.update(state => {
                return {
                    ...state,
                    emailNotification: {
                        ...state.emailNotification,
                        fromName: response.data.fromName,
                        subject: response.data.subject,
                        bodyHeader: response.data.bodyHeader,
                    },
                };
            });
        }

        this.snackbarService.createSnackbar({
            text: SYSTEM_MESSAGES.EMAIL_SETTINGS_UPDATED,
            type: SnackbarType.SUCCESS,
        }, SNACK_TIMEOUT);

        this.adminSettingsStore.setLoading(false);
    }

    @transaction()
    async fetchCalendarSettings() {
        this.adminSettingsStore.setLoading(true);

        const response = await api.get<ICalendarSettings>('/calendarsettings');

        if (response.ok) {
            this.adminSettingsStore.update(state => {
                return {
                    ...state,
                    calendarSettings: response.data,
                };
            });
        }

        this.adminSettingsStore.setLoading(false);
    }

    @autobind
    @transaction()
    async updateCalendarSettings(calendarDto: ICalendarSettings) {
        // let response: ApiResponse<ICalendarSettings>;

        this.adminSettingsStore.setLoading(true);

        // const calendarSettings = this.adminSettingsStore.getValue().calendarSettings;

        const response: ApiResponse<ICalendarSettings> = await api.put(`/calendarsettings`, calendarDto);

        if (response.ok) {
            this.adminSettingsStore.update(state => {
                return {
                    ...state,
                    calendarSettings: response.data,
                };
            });

            response.data.staffColors.forEach(item => {
                this.staffStore.update(item.staffId, { color: item.color });

                this.calendarStore.update(state => {
                    return {
                        ...state,
                        staffList: arrayUpdate(state.staffList, item.staffId, { color: item.color }),
                    };
                });
            });

            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.CALENDAR_SETTING_UPDTED,
                type: SnackbarType.SUCCESS,
            }, SNACK_TIMEOUT);
        }

        this.adminSettingsStore.setLoading(false);
    }

    //* Follow Ups
    @transaction()
    async fetchFollowUps() {
        // this.adminSettingsStore.setLoading(true);
        // const sorting = this.getSorting();
        this.adminSettingsStore.update(state => ({
            ...state,
            isFollowUpLoading: true,
        }));

        const params: Partial<IAdminSearchCriteria> = {
            ...this.buildFilteredRequest(),
            // sort: `${(sorting.field)},${sorting.type}`,
        };

        const response = await api.get<IFollowUpGetDto>('/followupnotifications', {
            params: {
                ...params,
            }
        });

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                followUps: response.data.result,
                totalFollowups: response.data.total,
            }));
        }

        this.adminSettingsStore.update(state => ({
            ...state,
            isFollowUpLoading: false,
        }));

        // this.adminSettingsStore.setLoading(false);
    }

    @transaction()
    async fetchFollowUpById(followUpId: number | string) {
        this.adminSettingsStore.setLoading(true);

        const response = await api.get<IFollowUp>(`/followupnotifications/${followUpId}`);

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                selectedFollowUp: {
                    ...response.data,
                    prevType: response.data.eventType
                },
            }));
        }

        this.adminSettingsStore.setLoading(false);
    }

    @transaction()
    async addFollowUp(followUpDto: Partial<IFollowUp>) {
        const selectedFollowUp = this.adminSettingsStore.getValue().selectedFollowUp;

        if (selectedFollowUp?.id) {
            this.updateFollowUp(followUpDto);
        } else {
            // this.adminSettingsStore.setLoading(true);
            this.adminSettingsStore.update(state => ({
                ...state,
                isUpdating: true,
            }));

            const response = await api.post<Partial<IFollowUp>>('/followupnotifications', followUpDto);

            if (response.ok) {
                this.adminSettingsStore.update(state => ({
                    ...state,
                    followUps: arrayUpsert(state.followUps, response.data?.id!, response.data),
                }));

                this.snackbarService.createSnackbar({
                    text: SYSTEM_MESSAGES.FOLLOW_UP_CREATED,
                    type: SnackbarType.SUCCESS,
                }, SNACK_TIMEOUT);
            }
    
            // this.adminSettingsStore.setLoading(false);
            this.adminSettingsStore.update(state => ({
                ...state,
                isUpdating: false,
            }));
        }
    }

    @transaction()
    async updateFollowUp(followUpDto: Partial<IFollowUp>) {
        // this.adminSettingsStore.setLoading(true);
        this.adminSettingsStore.update(state => ({
            ...state,
            isUpdating: true,
        }));

        const { template, ...updatedFollowup } = followUpDto;

        const response = await api.patch<Partial<IFollowUp>>(`/followupnotifications/${updatedFollowup.id}`, updatedFollowup);

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                followUps: arrayUpsert(state.followUps, updatedFollowup.id!, updatedFollowup),
            }));

            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.FOLLOW_UP_UPDATED,
                type: SnackbarType.SUCCESS,
            }, SNACK_TIMEOUT);
        }

        // this.adminSettingsStore.setLoading(false);
        this.adminSettingsStore.update(state => ({
            ...state,
            isUpdating: false,
        }));
    }

    async deleteFollowUp(followupId: string | number) {
        // this.adminSettingsStore.setLoading(true);
        this.adminSettingsStore.update(state => ({
            ...state,
            isUpdating: true,
        }));

        const response = await api.del(`/followupnotifications/${followupId}`);

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                followUps: arrayRemove(state.followUps, followupId),
            }));

            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.FOLLOW_UP_DELETED,
                type: SnackbarType.SUCCESS,
            }, SNACK_TIMEOUT);
        }

        // this.adminSettingsStore.setLoading(false);
        this.adminSettingsStore.update(state => ({
            ...state,
            isUpdating: false,
        }));
    }

    @autobind
    resetCurrentFollowup() {
        this.adminSettingsStore.update(state => ({
            ...state,
            selectedFollowUp: null,
        }))
    }


    //* AutoNotifications
    @transaction()
    async fetchAutoNotifications() {
        // this.adminSettingsStore.setLoading(true);

        // const sorting = this.getSorting();

        this.adminSettingsStore.update(state => ({
            ...state,
            isNotificationsLoading: true,
        }));

        const params: Partial<IAdminSearchCriteria> = {
            ...this.buildFilteredRequest(),
            // sort: `${(sorting.field)},${sorting.type}`,
        };

        const response = await api.get<IAutoNotificationsGetDto>('/autonotifications', {
            params: {
                ...params,
            }
        });

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                autoNotifications: response.data.result,
                totalNotifications: response.data.total,
            }));
        }

        this.adminSettingsStore.update(state => ({
            ...state,
            isNotificationsLoading: false,
        }));

        // this.adminSettingsStore.setLoading(false);
    }

    @transaction()
    async fetchAutoNotificationById(notificationId: number | string) {
        this.adminSettingsStore.setLoading(true);

        const response = await api.get<IAutoNotificationsGetDto>(`/autonotifications/${notificationId}`);

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                selectedAutoNotifications: response.data,
            }));
        }

        this.adminSettingsStore.setLoading(false);
    }

    //* POST AutoNotification
    @transaction()
    async addAutoNotification(notificationDto: Partial<IAutoNitificaton>) {
        const selectedAutoNotifications = this.adminSettingsStore.getValue().selectedAutoNotifications;

        if (selectedAutoNotifications?.id) {
            this.updateAutoNotification(notificationDto);
        } else {
            this.adminSettingsStore.update(state => ({
                ...state,
                isUpdating: true,
            }));

            const response = await api.post<Partial<IFollowUp>>('/autonotifications', notificationDto);

            if (response.ok) {
                this.adminSettingsStore.update(state => ({
                    ...state,
                    autoNotifications: arrayUpsert(state.autoNotifications, response.data?.id!, response.data),
                    selectedAutoNotifications: null,
                }));

                this.snackbarService.createSnackbar({
                    text: SYSTEM_MESSAGES.AUTO_NOTIFICATION_CREATED,
                    type: SnackbarType.SUCCESS,
                }, SNACK_TIMEOUT);
            }

            this.adminSettingsStore.update(state => ({
                ...state,
                isUpdating: false,
            }));
    
            // this.adminSettingsStore.setLoading(false);
        }
    }

    //* PATCH AutoNotification
    @transaction()
    async updateAutoNotification(notificationDto: Partial<IAutoNitificaton>) {
        // this.adminSettingsStore.setLoading(true);
        this.adminSettingsStore.update(state => ({
            ...state,
            isUpdating: true,
        }));

        const response = await api.patch<Partial<IAutoNitificaton>>(`/autonotifications/${notificationDto.id}`, notificationDto);

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                autoNotifications: arrayUpsert(state.autoNotifications, notificationDto.id!, notificationDto),
                selectedAutoNotifications: null,
            }));

            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.AUTO_NOTIFICATION_UPDATED,
                type: SnackbarType.SUCCESS,
            }, SNACK_TIMEOUT);
        }

        // this.adminSettingsStore.setLoading(false);
        this.adminSettingsStore.update(state => ({
            ...state,
            isUpdating: false,
        }));
    }

    async deleteAutoNotification(notificationId: string | number) {
        // this.adminSettingsStore.setLoading(true);
        this.adminSettingsStore.update(state => ({
            ...state,
            isUpdating: true,
        }));

        const response = await api.del(`/autonotifications/${notificationId}`);

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                autoNotifications: arrayRemove(state.autoNotifications, notificationId),
            }));

            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.AUTO_NOTIFICATION_DELETED,
                type: SnackbarType.SUCCESS,
            }, SNACK_TIMEOUT);
        }

        // this.adminSettingsStore.setLoading(false);
        this.adminSettingsStore.update(state => ({
            ...state,
            isUpdating: false,
        }));
    }

    @autobind
    resetCurrentNotification() {
        this.adminSettingsStore.update(state => ({
            ...state,
            selectedAutoNotifications: null,
        }))
    }

    //* Recurrence
    async addRecurrence(recurrence: Partial<IRecurrence>): Promise<number | null> {
        const response = await api.post<IRecurrence>(`/autonotifications/recurrence`, recurrence);

        if (response.ok) {
            return response.data.id as number;
        }

        return null;
    }

    async updateRecurrence(recurrenceId: number): Promise<number | null> {
        const response = await api.post<IRecurrence>(`/autonotifications/recurrence/${recurrenceId}`);

        if (response.ok) {
            return response.data.id as number;
        }

        return null;
    }

    //* ------------------------------------------------

    @autobind
    resetCurrentTemplate() {
        this.adminSettingsStore.update(state => ({
            ...state,
            selectedTemplate: null,
        }))
    }

    searchInit(searchType: AdminSearchType) {
        this.adminSearchService.searchQ.pipe(
            debounceTime(DEBOUNCE),
            distinctUntilChanged(),
            switchMap(search => {
                this.adminSearchService.updateSelectionCriteria({
                    search,
                });

                switch(searchType) {
                    case AdminSearchType.TEMPLATES_EMAIL:
                        return this.fetchTemplates(TemplateType.EMAIL, 'emailTemplates');
                    case AdminSearchType.TEMPLATES_SMS:
                        return this.fetchTemplates(TemplateType.SMS, 'smsTemplates');
                    // case AdminSearchType.NOTIFICATIONS:
                    //     return this.fetchTemplates(TemplateType.EMAIL, 'emailTemplates');
                    case AdminSearchType.FOLLOW_UPS:
                        return this.fetchFollowUps();
                    case AdminSearchType.AUTO_NOTIFICATIONS:
                        return this.fetchAutoNotifications();
                    default:
                        throw new Error('searchType" is missing');
                }
            }),
        ).subscribe();
    }

    async init(): Promise<void> {
        // this.fetchTemplates(TemplateType.EMAIL, 'emailTemplates');
        // this.fetchTemplates(TemplateType.SMS, 'smsTemplates');
        this.isInitialized = true;
    }

    getSorting() {
        return this.adminSettingsStore.getValue().sort;
    }

    public setSorting(sorting: ISort<keyof ITemplate>) {
        this.adminSettingsStore.update(state => ({
            ...state,
            sort: sorting,
        }));
    }

    async fetchStaff () {
        const response = await api.get<IPagedResponse<IStaff>>('/user?size=9999');

        if (response.ok) {
            this.adminSettingsStore.update(state => ({
                ...state,
                staff: response.data.result,
            }));
        }
    }
}

export const adminSettingsService = new AdminSettingsService(
    adminSettingsStore, 
    adminSearchService, 
    staffStore, 
    calendarStore,
    snackbarService
);