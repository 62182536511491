import format from "date-fns/format";

const DATE_FORMAT = "MM.dd.yyyy";
const DATE_FORMAT_SLASH = "MM/dd/yyyy";
const DATE_FORMAT_WITH_TIME = "MM.dd.yyyy hh:mm a";
const TIME_FORMAT = 'hh:mm';
const LAST_IMPORT_DATE_FORMAT = 'MMMM dd, yyyy hh:mm a';
const DATE_TIME_FORMAT = 'do MMMM yyyy, hh:mm';

export function formatDate(date: string, type: "DOT" | "SLASH" = "DOT", isCurrentTime: boolean = false): string | boolean {
    if (!date && isCurrentTime) {
        switch(type) {
            case "SLASH":
                return format(new Date(), DATE_FORMAT_SLASH);
            default:
                return format(new Date(), DATE_FORMAT);
        }
    }

    if (!date) {
        return false;
    }

    switch(type) {
        case "SLASH":
            return format(new Date(date), DATE_FORMAT_SLASH);
        default:
            return format(new Date(date), DATE_FORMAT);
    }
}

export function formatDateWithTime(date: Date): string | boolean {
    if (!date) {
        return false;
    }

    return format(new Date(date), DATE_TIME_FORMAT);
}

export function formatContractCreatedDate(date: string) {
    if (!date) {
        return false;
    }

    return format(new Date(date), DATE_FORMAT_WITH_TIME);
}

export function formatDateForComments(date: Date) {
    if (!date) {
        return false;
    }

    return `${format(new Date(date), DATE_FORMAT)} at ${format(new Date(date), TIME_FORMAT)}`;
}

export function formatLastImportDate(date: string) {
    if (!date) {
        return false;
    }

    return `Last Imported: ${format(new Date(date), LAST_IMPORT_DATE_FORMAT)}`;
}