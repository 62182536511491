import {Redirect, Route, Switch} from "react-router-dom";
import {RoutesEnum} from "../../routing/routes";
import React from "react";
import {OwnersList} from "./pages/list/owners-list";
import {OwnersView} from "./pages/view/owners-view";

export function Owners() {
    return <Switch>
        <Route exact path={RoutesEnum.OWNERS_LIST} component={OwnersList} />
        <Route path={RoutesEnum.OWNERS_VIEW} component={OwnersView} />
        <Redirect to={RoutesEnum.OWNERS_LIST} />
    </Switch>
}
