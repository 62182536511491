import { IStaff } from "src/store/staff/models/staff.inreface";

export enum LeadStatus {
    FOR_FOLLOW_UP = 'FOR_FOLLOW_UP',
    LOST = 'LOST',
    APPLICANT = 'APPLICANT',
}

export interface ICity {
    id: number;
    name: string;
}

export interface IPickupLocation {
    id: number;
    name: string;
}

export interface INeighborhood extends ICity {}

export interface INotificationMethod {
    id: number;
    name: string;
}

export interface IProspect {
    id: number;
    agentAttachedId?: number;
    agentShowedId?: number;
    agentAttached?: IStaff;
    agentShowed?: IStaff;
    approxBudgetFrom?: number;
    approxBudgetTo?: number;
    bedrN: number;
    city?: ICity;
    cityId?: number;
    email: string;
    firstName: string;
    isDeleted?: boolean;
    lastName: string;
    leadStatus: LeadStatus;
    location: string;
    neighborhood?: INeighborhood;
    neighborhoodId?: number;
    notificationIds?: number[];
    phoneNumber: string;
    pickupLocation?: string;
    seenBefore?: boolean;
    notificationMethod?: INotificationMethod;
    notification?: INotificationMethod[];
    photo?: string;
    isBusy: boolean;
    notes?: string;
}

export type FetchInitProspectsTuple = [
    number,
    number,
    string,
]