import {Panel, PanelType} from "../../../../../ui/components/panel";
import CssClasses from "../tenants.module.scss";
import {SearchInput, SearchInputType} from "../../../../../ui/components/forms/inputs/search";
import {Button, ButtonVariant} from "../../../../../ui/components/buttons/button";
import {Add, ChevronLeft, Delete} from "@material-ui/icons";
import React, {ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useObservable} from "@libreact/use-observable";
import {Table} from "../../../../../ui/components/table";
// import {IDataTableColumn} from "react-data-table-component";
import {INumerated} from "../../../../../../store/properties/models/numerated.interface";
import {ITenant} from "../../../../../../store/tenants/models/tenant.interface";
import {COLORS} from "../../../../../ui/theme/variables/colors";
// import {propertyGroupQuery} from "../../../../../../store/tenants/property-groups/property-group.query";
import {propertyGroupsService} from "../../../../../../store/tenants/property-groups/property-group.service";
import {IPropertyGroup} from "../../../../../../store/tenants/property-groups/models/property-group.interface";
import {useHistory, useLocation} from 'react-router-dom';
import { ISelectedData } from "src/store/utils/common.interface";
import { tenantGroupsService } from "src/store/tenants/groups/tenant-groups.service";
import { MessageIcon } from "src/components/ui/icons";
import { messageService, RecieverType } from "src/store/message/message.service";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { guid } from "@datorama/akita";
import { TitleHolder } from "src/components/containers/properties/pages/list";
import { usePaging } from "src/store/common/hooks/use-paging";
import { usePropertyGroups } from "src/store/tenants/hooks/use-property-groups";
import { PagingType } from "src/store/common/models/common.interface";

const conditionalRowStyles = [
    {
        // actively renting & i % 2 === 0
        when: (row: INumerated & ITenant) => row.i % 2 === 0,
        style: {
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    }
];


const columns = (clickHandler: (e: ChangeEvent<any>) => void) => [
    {
        name: 'Property',
        selector: 'property',
        sortable: true,
        minWidth: '150px',
        cell: (row: IPropertyGroup) => {
            return (
                <TitleHolder onClick={clickHandler} id={String(row.id)}>{row.property}</TitleHolder>
            );
        }
    },
    {
        name: 'Tenants',
        selector: 'tenantsNames',
        sortable: false,
        minWidth: '150px',
        cell: (row: IPropertyGroup) => {
            return <div>
                {row.tenants.map(r => <div onClick={clickHandler} id={String(row.id)} key={guid()}>
                    {r}
                </div>)}
            </div>
        }
    },
    {
        name: 'Phone Numbers',
        selector: 'phoneNumbers',
        sortable: false,
        minWidth: '150px',
        cell: (row: IPropertyGroup) => {
            return <div>
                {row.phoneNumbers.map(r => <div onClick={clickHandler} id={String(row.id)} key={guid()}>
                    {r}
                </div>)}
            </div>
        }
    },
    {
        name: 'Email',
        selector: 'emails',
        sortable: false,
        minWidth: '150px',
        cell: (row: IPropertyGroup) => {
            return <div>
                {row.emails.map(r => {
                    if (r === '') {
                        return (<div onClick={clickHandler} id={String(row.id)} key={guid()} style={{ visibility: 'hidden' }}>
                            ----
                        </div>)
                    }

                    return (
                        <div onClick={clickHandler} id={String(row.id)} key={guid()}>
                            {r}
                        </div>
                    );     
                })}
            </div>
        }
    }
];

interface IPropertyGroupsLocation {
    from: string;
    selectType: string;
    backLink: string;
}

interface IPropertyGroupsProps {
    isFilterable?: boolean;
    useSelectedUnitId?: number | string;
    omitFields?: string[];
    backLink?: string;
    onSubmit?: (selectedTenants: ITenant[]) => void;
}

export function PropertyGroups({ onSubmit, backLink }: IPropertyGroupsProps) {
    const location = useLocation<IPropertyGroupsLocation>();
    const [groups, isLoading] = usePropertyGroups();
    const [paging, setPaging, pushLocation] = usePaging(PagingType.PROPERTY_GROUPS);

    const [selectMode] = useState(location.state?.selectType);
    // const [count] = useObservable(propertyGroupQuery.groupsCount$);

    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedItems, setSelectedItems] = useState<IPropertyGroup[]>([]);

    const [search, setSearch] = useState<string>('');

    const [isMobile] = useObservable(uiQuery.isMobile$);

    const history = useHistory();

    const smallMargin: React.CSSProperties = {
        marginLeft: 10,
    };

    useEffect(() => {
        return () => {
            propertyGroupsService.changeSearch('');
            propertyGroupsService.setGroupsTotal(groups.length);
        }
    }, []);

    const filteredGroups = useMemo(() => {
        if (search) {
            const searchQ = search.toLowerCase().trim();

            return groups.filter(group => {
                if (
                    group.property.toLowerCase().includes(searchQ)
                ) {
                    return group;
                }
            });
        }

        return groups;
    }, [groups, search]);

    useEffect(() => {
        propertyGroupsService.setGroupsTotal(filteredGroups.length);
    }, [filteredGroups]);

    const onSearchChange = (q: string) => {
        // propertyGroupsService.changeSearch(q);
        setSearch(q);
    }

    const changePage = (page: number) => {
        // table component numerating start from 1, BE expects 0 as the first page
        setPaging(page, paging.rowsPerPage);
        // propertyGroupsService.fetchPropertyGroups(page - 1, paging.rowsPerPage);
    }

    const changeRowsNumber = (rowPerPage: number, page: number) => {
        setPaging(page, rowPerPage);
        // propertyGroupsService.fetchPropertyGroups(page - 1, rowPerPage);
    }

    const goToView = (e: IPropertyGroup) => {
        pushLocation(`/profiles/tenants/properties/${e.id}`, PagingType.PROPERTY_GROUPS);
    }

    const onCustomCellClick = useCallback((e: any) => {
        pushLocation(`/profiles/tenants/properties/${e.target.id}`, PagingType.PROPERTY_GROUPS);
    }, [history, paging])

    const handleSelectionChange = (data: ISelectedData<IPropertyGroup>) => {
        setSelectedCount(data.selectedCount);
        setSelectedItems(data.selectedRows);
    }

    const addToTheList = () => {
        switch(location.state?.from) {
            case '/message':
                messageService.selectRecievers(RecieverType.PROPERTY_GROUPS, selectedItems);
                history.push('/message', { 
                    from: 'propertyGroups', 
                    backLink: 'tenants?tab=property_groups',
                    category: 'profiles'
,                });
                break;
            default:
                tenantGroupsService.updateSelectedData(selectedItems, 'propertyGroup');
                history.push(location.state?.backLink);
        }

        // if (selectMode) {
        //     messageService.selectRecievers(RecieverType.PROPERTY_GROUPS, selectedItems);
        //     history.push('/message', { from: 'propertyGroups', backLink: '/profiles/tenants?tab=property_groups' });
        // } else {
        //     console.log('selectedItems -> ', selectedItems);
        //     tenantGroupsService.updateSelectedData(selectedItems, 'propertyGroup');

        //     history.push(`${backLink}?mode=EDITING`);
        // }
    }

    // const onSort = (column: IDataTableColumn<IPropertyGroup & INumerated>, sortDirection: "desc" | "asc") => {
    //     propertyGroupsService.setSorting({
    //         type: sortDirection,
    //         field: column.selector === 'property' 
    //             ? ('unitAddress' as keyof IPropertyGroup) 
    //             : column.selector as keyof IPropertyGroup,
    //     });
    //     propertyGroupsService.fetchPropertyGroups();
    // }

    const handleSendMessage = () => {
        if (selectedItems.length) {
            messageService.selectRecievers(RecieverType.PROPERTY_GROUPS, selectedItems);
            tenantGroupsService.resetCurrentGroup();;
            history.push('/message', { 
                from: 'propertyGroups', 
                backLink: 'tenants?tab=property_groups',
                category: 'profiles', 
            });
        }
    }

    return <Fragment>
        { (backLink || location.state?.from) &&
            <div className="link_wrapper" 
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(location.state.from || `${backLink}?mode=EDITING`, { 
                    from: 'propertyGroups', 
                    backLink: 'tenants?tab=property_groups',
                    category: 'profiles',
                })}>
                <ChevronLeft /> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
            </div>  
        }
        <Panel type={PanelType.EMPTY}>
            <div className={CssClasses.list_action_bar}>
                <div className={CssClasses.property_action_bar_left}>
                    <SearchInput
                        inputType={SearchInputType.PRIMARY}
                        onChange={onSearchChange}
                        placeholderText="Search..."
                    />
                </div>
                <div className={CssClasses.list_action_bar_props}>
                    { !selectMode ? (<Button
                        icon={<MessageIcon />}
                        style={smallMargin}
                        btnVariant={ButtonVariant.OUTLINE}
                        text={ isMobile ? '' : 'Message' }
                        onClick={() => history.push('/message', { 
                            from: 'propertyGroups', 
                            backLink: 'tenants?tab=property_groups',
                            category: 'profiles',
                        })}
                    /> ) : null }
                    {(onSubmit || selectMode) && <Button
                        onClick={addToTheList}
                        icon={<Add style={{fontSize: 14}}/>}
                        style={smallMargin}
                        btnVariant={ButtonVariant.PRIMARY}
                        text={`Add to the list ${selectedCount ? `:${selectedCount}` : ''}`}
                    />}
                </div>
            </div>
        </Panel>
        {(selectedCount > 0 && !selectMode) && <Panel additionalStyles={
            isMobile ? { 
                margin: '0 0 15px',
                width: '97.5%',
            } : {}
        }>
            <div className={CssClasses.list_action_props_selected}>
                <div className={CssClasses.list_action_bar_left}>
                    {`${selectedCount} Selected`}
                </div>
                <div className={CssClasses.selected_action_bar_right}>
                    <Button
                        icon={<MessageIcon />}
                        btnVariant={ButtonVariant.OUTLINE}
                        text={ isMobile ? '' : 'Message' }
                        onClick={handleSendMessage}
                    />
                </div>
            </div>
        </Panel>}
        <Panel noPadding sideBorder>
            <Table<IPropertyGroup>
                conditionalRowStyles={conditionalRowStyles}
                contextActions={[
                    <div className={CssClasses.delete_properties}>
                        <Delete/> Delete Selected
                    </div>
                ]}
                onSelectionChange={handleSelectionChange}
                // selectableRowSelected={(row: any) => row.isSelected}
                onChangePage={changePage}
                totalRecordsNumber={filteredGroups.length}
                onRowClicked={!selectMode ? goToView : () => {}}
                paginationPerPage={paging.rowsPerPage}
                paginationServer={false}
                paginationServerOptions={{
                    persistSelectedOnPageChange: false,
                    persistSelectedOnSort: false,
                }}
                onChangeRowsNumber={changeRowsNumber}
                columns={columns(onCustomCellClick)}
                // onSort={onSort}
                loading={isLoading}
                data={filteredGroups}
                highlightRow={['property']}
                activePage={paging.activePage}
            />
        </Panel>
    </Fragment>
}