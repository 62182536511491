import { guid } from '@datorama/akita';
import { useObservable } from '@libreact/use-observable';
import { ExpandMore } from '@material-ui/icons';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { IDataTableColumn, IDataTableConditionalRowStyles } from 'react-data-table-component';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { Table } from 'src/components/ui/components/table';
import { MessageIcon } from 'src/components/ui/icons';
import { COLORS } from 'src/components/ui/theme/variables/colors';
import { usePaging } from 'src/store/common/hooks/use-paging';
import { PagingType } from 'src/store/common/models/common.interface';
// import { uiQuery } from 'src/store/ui-storage/ui.query';
// import { userQuery } from 'src/store/user/user.query';
import styled from 'styled-components';
import moment from 'moment';
import { messageService } from 'src/store/message/message.service';
import { messageQuery } from 'src/store/message/message.query';
import { IMessageDto } from 'src/store/message/models/message.interface';
// import { sortBy } from 'lodash';

function extractContent(htmlString: string) {
    var span = document.createElement('span');
    span.innerHTML = htmlString;

    return span.textContent || span.innerText;
};

const historyResultMap = new Map();
historyResultMap.set('SENT_SUCCESSFULLY', 'Sent Successfully');
historyResultMap.set('NOT_RECEIVED', 'Not Received');

interface IIconHolder {
    isExpanded?: boolean;
}

const IconHolder = styled.div<IIconHolder>`
    position: relative;
    right: -10px;
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #FF6F43;
    color: #fff;
    cursor: pointer;

    & > svg {
        transform: ${({ isExpanded }) => isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
    }
`;

const buildConditionalRowStyles = () => {
    const availableColors = {
        message: '#5384F9',
    };

    const conditionalRowStyles: IDataTableConditionalRowStyles<any>[] = [];

    Object.entries(availableColors).forEach(([key, color]) => {
        conditionalRowStyles.push({
            when: (row) => key === 'message',
            style: {
                borderLeft: `2px solid ${color}`,
                backgroundColor: COLORS.TABLE.EXPANDED_ROW_BG,
            }
        });
        conditionalRowStyles.push({
            when: (row) => (row.i % 2 === 1) && key === 'message',
            style: {
                borderLeft: `2px solid ${color}`,
            }
        });
    });

    return conditionalRowStyles;
}

const conditionalRowStyles = buildConditionalRowStyles();

const columns: IDataTableColumn<any>[] = [
    {
        name: '',
        selector: 'subject',
        minWidth: '20px',
        maxWidth: '20px',
        cell: () => null,
    },
    {
        name: 'Subject',
        selector: 'subject',
        minWidth: '200px',
        maxWidth: '300px',
    },
    {
        name: 'Contact date',
        selector: 'date',
        minWidth: '140px',
        cell: (row) => <div>{ moment(row.date).isValid() ? moment(row.date).format('MM.DD.YYYY hh:mm A') : '' }</div>
    },
    {
        name: 'Main body',
        selector: 'body',
        minWidth: '300px',
        cell: (row) => <div>{ extractContent(row.body) }</div>
    },
    {
        name: 'Result',
        selector: 'result',
        minWidth: '200px',
        cell: (row) => <div>{ historyResultMap.get(row.result) }</div>
    },
];

const InnerHolder = styled.div`
    padding: 20px 0 0 36px;
`;

const InnerItem = styled.div`
    display: flex;
    align-items: center;
`;

const InnerTitle = styled.div`
    margin: 0 0 20px;
    width: 180px; 
    color: rgba(83,132,249,1); 
    font-family: 'ManropeSemibold';
`;

const InnerBody = styled.div`
    margin: 0 0 20px;
    white-space: mowrap;
`;

const innerColumns: IDataTableColumn<any>[] = [
    {
        name: 'Receivers',
        selector: 'allRecipients',
        minWidth: '400px',
        style: {
            padding: '0 12px !important',
            backgroundColor: '#FBFBFF',
        },
        cell: (row) => <InnerHolder>
            <InnerItem>
                <InnerTitle>Receivers</InnerTitle>
                <InnerBody>
                    { row.allRecipients.map((receiver: IMessageDto, idx: number) => {
                        if (idx === row.allRecipients.length - 1) {
                            return receiver;
                        }

                        if (receiver) {
                            return `${receiver}, `
                        }
                    }) }
                </InnerBody>
            </InnerItem>
            <InnerItem>
                <InnerTitle>Subject</InnerTitle>
                <InnerBody>{ row.subject }</InnerBody>
            </InnerItem>
            <InnerItem>
                <InnerTitle>Main Body</InnerTitle>
                <InnerBody>{ extractContent(row.body) }</InnerBody>
            </InnerItem>
        </InnerHolder>,
    },
];

const ExpandableComponent: React.FC<any> = ({ data }) => {
    return <div style={{ backgroundColor: '#FBFBFF' }}>
        <Table<any>
            columns={innerColumns}
            data={[data]}
            noTableHead
            pagination={false}
            pushBottom={false}
            conditionalRowStyles={conditionalRowStyles}
        />
    </div>
};

interface IContactHistory {
    id: number;
    context: string;
    field: 'tenants' | 'groups' | 'properties' | 'prospects' | 'propertyGroups' | 'owners';
}

export const ContactHistory: React.FC<IContactHistory> = ({ id, context, field }) => {
    // const [user] = useObservable(userQuery.user$);
    const [paging, setPaging] = usePaging(PagingType.STAFF);
    // const [isMobile] = useObservable(uiQuery.isMobile$);
    const [messageHistory] = useObservable(messageQuery.messageHistory$);
    const [expandKey, setExpandKey] = useState<any>({});

    useEffect(() => {
        messageService.fetchMessageHistory(id, context, field);
    }, []);

    const prepearedHistory = useMemo(() => {
        const messages = messageHistory[field].map(item => ({
            ...item,
            keyField: guid(),
        }));

        return messages.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }, [messageHistory, field]);

    const tableColumns = useMemo(() => {
        return [
            ...columns,
            {
                name: '',
                selector: 'email',
                sortable: true,
                maxWidth: '90px',
                cell: (row: any) => <IconHolder 
                    isExpanded={row.keyField === expandKey.key && expandKey.expanded} 
                    onClick={() => handleExpandRow(row)}>
                    <ExpandMore />
                </IconHolder>,
            },
        ];
    }, [expandKey]);

    const tableData = useMemo(() => {
        return prepearedHistory.map((item: any) => {
            if (item.keyField === expandKey.key) {
                return {
                    ...item,
                    expandMe: expandKey.expanded,
                };
            }

            return item;
        });
    }, [prepearedHistory, expandKey]);

    const handleExpandRow = (row: any) => {
        setExpandKey((prevState: any) => {
            return {
                key: row.keyField,
                expanded: row.keyField === prevState.key ? !prevState.expanded : true,
            };
        });
    }

    const changeRowsNumber = (rowPerPage: number, page: number) => {
        setPaging(page, rowPerPage);
    }

    // const onSort = (column: IDataTableColumn<any>, sortDirection: "desc" | "asc", rows: number) => {

    // }

    const changePage = (page: number) => {
        // table component numerating start from 1, BE expects 0 as the first page
        setPaging(page, paging.rowsPerPage);
    }

    return (
        <Fragment>
            <Panel additionalStyles={{ margin: '-10px 0 20px' }}>
                <FormHolder
                    title="Messages"
                    noPadding
                    icon={<MessageIcon />}>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '0 0 0 44px', }}>
                            <div style={{ margin: '0 38px 0 0', fontSize: '14px', color: '#363E59', }}>Total</div>
                            <div style={{ fontSize: '14px', color: '#363E59', }}>{ messageHistory[field].length }</div>
                        </div>
                </FormHolder>
            </Panel>
            <Panel type={PanelType.EMPTY} noPadding sideBorder additionalStyles={{ margin: 0 }}>
                <Table<any>
                    pagination={false}
                    onChangePage={changePage}
                    totalRecordsNumber={messageHistory[field].length}
                    paginationPerPage={paging.rowsPerPage}
                    onChangeRowsNumber={changeRowsNumber}
                    columns={tableColumns}
                    conditionalRowStyles={conditionalRowStyles}
                    // onSort={onSort}
                    loading={false}
                    data={tableData}
                    // highlightRow={['name', 'email']}
                    activePage={paging.activePage}
                    expandableRows
                    expandableRowsComponent={<ExpandableComponent />}
                    expandableRowExpanded={(row) => row.expandMe}
                />
            </Panel>
        </Fragment>
    );
}