
import { prospectsStore } from "./prospects.store";
import { IPagedResponse } from "../properties/models/paged-response.interface";
import { IProspect } from "./models/prospect.interface";
import { PagingStore } from "../utils/paging-store";
import { searchProspectsService } from "./prospects-search/prospects-search.service";
import { IProspectSearchFilters } from "./models/prospects-search-filters.interface";

import { ICityGetDto } from './prospects-search/prospects-search.service';
import { ProspectDto } from "src/components/containers/prospects/pages/prospect-editor/prospect-editor";
import { snackbarService } from "../snackbar/snackbar.service";
import { SnackbarType } from "../snackbar/snackbar.store";
import { SYSTEM_MESSAGES, SNACK_TIMEOUT } from "./prospects.config";
import { api, ApiResponse } from "src/api/api.service";
import { fromJS } from "immutable";

interface IProspectsGetDto {
    lastImported: string;
    response: IPagedResponse<IProspect>;
}

const pagingStore = new PagingStore();

function buildFilteredRequest(page: number, size: number): Partial<IProspectSearchFilters> {
    const params: Partial<IProspectSearchFilters> = {};
    const { notificationMethod, leadStatus, approximateBudget, bedrN, agentsAttached, city } = searchProspectsService.getCriteria();
    params.page = page;
    params.size = size;

    if (agentsAttached.length) {
        const agentIds = agentsAttached.map(agent => agent.id);
        params.agentsAttached = agentIds.join(',');
    }

    if (city.length) {
        const cityNames = city.map(city => city.name);
        params.cities = cityNames.join(',');
    }

    // if (neighborhood.length) {
    //     const neighIds = neighborhood.map(n => n.id);
    //     params.neighborhoodIds = neighIds.join(',');
    // }

    if (notificationMethod.length > 0) {
        const notifIds = notificationMethod.map(n => n.id);

        if (notifIds.includes(3)) {
            params.notifications = [1,2].join(',');
        } else {
            params.notifications = notifIds.join(',');
        }
    }

    if (leadStatus.length) {
        params.statuses = leadStatus.join(',').toUpperCase();
    }

    if (approximateBudget.from !== null) {
        params.approxBudgetF = approximateBudget.from;
    }

    if (approximateBudget.to) {
        params.approxBudgetT = approximateBudget.to;
    }

    if (bedrN.from) {
        params.bedroomsNumberF = bedrN.from;
    }

    if (bedrN.to) {
        params.bedroomsNumberT = bedrN.to;
    }

    return params;
}

export async function fetchProspects(page: number = 0, size: number = 10, tab: 'GENERAL' | 'INITIAL') {
    prospectsStore.setLoading(true);
    pagingStore.setPagingData({ page, size });
    // const sorting = this.searchProspectsService.getSorting();

    const params: Partial<IProspectSearchFilters> = {
        ...buildFilteredRequest(page, size),
    };

    // params.sort = `${(sorting.field)},${sorting.type}`;
    // params.sort = `${(sorting.field)}`;

    // const response = await ApiServiceInstance.get<IProspectsGetDto>('/prospects', {
    //     params: {
    //         ...params,
    //     }
    // });

    const response = await api.get<IProspectsGetDto>(`/prospects?tab=${tab}`, {
        params: {
            ...params,
        }
    })

    if (response.ok) {
        prospectsStore.set(response.data.response.result);
        prospectsStore.update(state => ({
            ...state,
            prospectsCount: response.data.response.total,
        }))
    }

    prospectsStore.setLoading(false);
}

export async function fetchProspectById(id: number) {
    prospectsStore.setLoading(true);
    const response = await api.get<IProspect>(`/prospects/${id}`);

    if (response.ok) {
        prospectsStore.update(state => ({
            ...state,
            selectedProspect: response.data,
        }))
    }

    prospectsStore.setLoading(false);
}

export async function createProspect(prospectDto: Partial<ProspectDto>): Promise<IProspect | undefined> {
    prospectsStore.setLoading(true);

    const response = await api.post<IProspect>(`/prospects`, prospectDto);

    if (response.ok) {
        
        prospectsStore.add(response.data);
        prospectsStore.update(state => ({
            ...state,
            prospectsCount: state.prospectsCount + 1,
        }));

        snackbarService.createSnackbar({
            text: SYSTEM_MESSAGES.CREATED,
            type: SnackbarType.SUCCESS,
        }, SNACK_TIMEOUT);

        prospectsStore.setLoading(false);

        return response.data;
    }
}

export async function updateProspect(prospectDto: Partial<ProspectDto>) {
    let response: ApiResponse<IProspect> | undefined = undefined;

    prospectsStore.setLoading(true);

    if (prospectDto.agentShowedId === null) {
        response = await api.put(`/prospects/${prospectDto.id}`, prospectDto);

        return false;
    }

    response = await api.patch(`/prospects/${prospectDto.id}`, prospectDto);

    if (response?.ok) {
        prospectsStore.update(response.data.id, response.data);

        snackbarService.createSnackbar({
            text: SYSTEM_MESSAGES.UPDATED,
            type: SnackbarType.SUCCESS,
        }, SNACK_TIMEOUT);
    }

    prospectsStore.setLoading(false);
}

export function resetCurrentProspect() {
    prospectsStore.update(state => ({
        ...state,
        selectedProspect: null,
    }))
}

export async function fetchCities() {
    const response = await api.get<ICityGetDto>('/cities');

    if (response.ok) {
        prospectsStore.update(state => ({
            ...state,
            cities: response.data,
        }));
    }
}

export async function fetchPickupLocations() {
    prospectsStore.update(state => ({
        ...state,
        isPickupLocationsLoading: true,
    }));

    const response = await api.get<ICityGetDto>('/pickUpLocations');

    if (response.ok) {
        prospectsStore.update(state => ({
            ...state,
            pickupLocations: response.data,
        }));
    }

    prospectsStore.update(state => ({
        ...state,
        isPickupLocationsLoading: false,
    }));
}

export async function archiveProspect(prospectId: number) {
    prospectsStore.setLoading(true);

    const response = await api.del(`/prospects/${prospectId}`);

    if (response.ok) {
        prospectsStore.remove(prospectId);
        prospectsStore.update(state => ({
            ...state,
            prospectsCount: state.prospectsCount - 1,
            selectedStaff: null,
        }));

        snackbarService.createSnackbar({
            text: SYSTEM_MESSAGES.DELETED,
            type: SnackbarType.SUCCESS,
        }, SNACK_TIMEOUT);
    }

    prospectsStore.setLoading(false);
}

export function setSelectedProspects(data: IProspect[]) {
    prospectsStore.update(state => ({
        ...state,
        selectedProspects: {
            data,
        },
    }));
}

export function resetSelectedProspects() {
    prospectsStore.update(state => {
        return {
            ...state,
            selectedProspects: {
                data: [],
            },
        }
    });
}

export async function fetchInitialProspects(page: number = 0, size: number = 10, search: string = ''): Promise<void> {
    prospectsStore.setLoading(true);
    pagingStore.setPagingData({page, size});

    // const sorting = getSorting();

    const params = {
        ...buildFilteredRequest(page, size),
    };

    if (search) {
        params.search = search;
    }

    // params.sort = `${(sorting.field)},${sorting.type}`;

    // const response = await api.get<any>('/lease-contracts', {
    //     params: {
    //         ...params,
    //     }
    // });

    const response = await api.get<any>(`/prospects?tab=INITIAL`, {
        params: {
            ...params,
        }
    });

    if (response.ok) {
        if (!response.data?.response.total) {
            prospectsStore.update(state => ({
                ...state,
                prospects: fromJS([]),
            }));
        } else {
            prospectsStore.update(state => ({
                ...state,
                prospects: fromJS(response.data.response.result),
                prospectsTotal: fromJS(response.data.response.total),
                lastImportedProspects: response.data.lastImported,
            }));
        }

        return response.data.response;
    }

    prospectsStore.setLoading(false);
}

export async function importInititalProspects() {
    const response = await api.post(`/prospects/import`);

    prospectsStore.update(state => ({
        ...state,
        isProspectsImporting: true,
    }));

    if (response.ok) {
        prospectsStore.update(state => ({
            ...state,
            isProspectsImporting: false,
        }));

        await fetchInitialProspects();

        snackbarService.createSnackbar({
            text: 'Prospects data has been imported',
            type: SnackbarType.SUCCESS,
        }, SNACK_TIMEOUT);
    }
}

export const prospectsService = {
    fetchProspects,
    fetchProspectById,
    createProspect,
    updateProspect,
    resetCurrentProspect,
    archiveProspect,
    fetchCities,
    fetchPickupLocations,
    setSelectedProspects,
    resetSelectedProspects,
    fetchInitialProspects,
    importInititalProspects,
};