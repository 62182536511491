import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { RoutesEnum } from "../../routing/routes";
import { ProspectEditor } from './pages/prospect-editor/prospect-editor';
import ProspectsInfo from './pages/prospect-info/prospect-info';
import Prospects from './pages/prospects-list/prospects';

export function PropspectsContainer () {
    return (
        <Switch>
            <Route exact path={RoutesEnum.PROSPECTS_LIST} component={Prospects} />
            <Route exact path={RoutesEnum.PROSPECTS_CREATE} component={ProspectEditor} />
            <Route exact path={RoutesEnum.PROSPECTS_EDIT} component={ProspectEditor} />
            <Route path={RoutesEnum.PROSPECTS_DETAILS} component={ProspectsInfo} />
            <Redirect to="/profiles/prospects" />
        </Switch>
    );
}
