export enum RoutesEnum {
    BASE_AUTH_PATH = '/auth',
    SIGN_IN = '/auth/sign-in',
    SIGN_UP = '/auth/sign-up',
    FORGOT_PASSWORD = '/auth/forgot-password',
    FORGOT_PASSWORD_EMAIL_SENT = '/auth/forgot-password-email-sent',
    PASSWORD_RESET = '/auth/password-reset',

    PROPERTIES_LIST = '/properties',
    PROPERTIES_CREATE = '/properties/new',
    PROPERTIES_EDIT = '/properties/:id',

    TENANTS_LIST = '/profiles/tenants',
    TENANT_VIEW = '/profiles/tenants/:id',

    PROPERTY_VIEW = '/profiles/tenants/properties/:id',

    TENANT_GROUP_VIEW = '/profiles/tenants/groups/:id',
    TENANT_GROUP_CREATE = '/profiles/tenants/groups/new',
    TENANT_GROUP_EDIT = '/profiles/tenants/groups/:id/edit',

    OWNERS_LIST = '/profiles/owners',
    OWNERS_VIEW = '/profiles/owners/:id',

    PROSPECTS_LIST = '/profiles/prospects',
    PROSPECTS_CREATE = '/profiles/prospects/new',
    PROSPECTS_DETAILS = '/profiles/prospects/:id',
    PROSPECTS_EDIT = '/profiles/prospects/:id/edit',

    STAFF_LIST = '/profiles/staff',
    STAFF_CREATE = '/profiles/staff/new',
    STAFF_DETAILS = '/profiles/staff/:id',
    STAFF_EDIT = '/profiles/staff/:id/edit',

    ADMIN_SETTINGS = '/admin/settings',
    ADMIN_ADD_TEMPLATE = '/admin/settings/templates/new',
    ADMIN_VIEW_TEMPLATE = '/admin/settings/templates/:id',
    ADMIN_EDIT_TEMPLATE = '/admin/settings/templates/:id/edit',
    
    ADMIN_ADD_NOTIFICATION = '/admin/settings/autonotifications/new',
    ADMIN_EDIT_NOTIFICATION = '/admin/settings/autonotifications/:id/edit',
    
    ADMIN_ADD_FOLLOW_UP = '/admin/settings/followup/new',
    ADMIN_EDIT_FOLLOW_UP = '/admin/settings/followup/:id/edit',
    ADMIN_VIEW_FOLLOW_UP = '/admin/settings/followup/:id',
    
    APPOINTMENTS_LIST = '/calendar/appointments',

    SEND_MESSAGE = '/message',
    CALENDAR = '/calendar/',
    CALENDAR_VIEW = '/calendar/calendar-view',

    //** Dashboard */
    DASHBOARD = '/dashboard',
    LEASE_CONTRACTS = '/dashboard/contracts',
    LEASE_REQUESTS = '/dashboard/requests',
    DOC_LIBRARY = '/dashboard/doc-lib',
    REPORTS = '/dashboard/reports',

    LEASE_CONTRACT = '/dashboard/contracts/:id/type/:type',

    IMPORT_STATUS = '/dashboard/import-status',
}
