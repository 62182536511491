import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {AuthState} from "./models/auth-state.enum";
import {IUser} from "./models/user.interface";

export interface IUserState extends EntityState {
    authState: AuthState;
    user: IUser | null;
}

const initialState: IUserState = {
    user: null,
    authState: AuthState.UNAUTHORIZED,
}

@StoreConfig({ name: 'user' })
export class UserStore extends EntityStore<IUserState> {
    constructor() {
        super(initialState);
    }
}

export const userStore = new UserStore();
