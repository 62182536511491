import { createEntityQuery } from "@datorama/akita";
import { authStore, IAuthState } from "./auth.store";

const auq = createEntityQuery<IAuthState>(authStore);

export const selectUser$ = auq.select(state => state.user);
export const selectAuthState$ = auq.select(state => state.authState);

export const authQuery = {
    selectUser$,
    selectAuthState$,
};