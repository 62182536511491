import {
  Calendar as C,
  momentLocalizer,
  stringOrDate,
} from "react-big-calendar";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Panel, PanelType } from "../../../ui/components/panel";
import CssClasses from "../appointments/appointments.module.scss";
import { Header3 } from "../../../ui/components/headers";
import {
  calendarService,
  IAppointmentEvent,
} from "../../../../store/calendar/calendar.service";
import { useObservable } from "@libreact/use-observable/dist";
import { calendarQuery } from "../../../../store/calendar/calendar.query";
import { CreateAppointment } from "../appointments/components/create-appointment";
import { appointmentService } from "../../../../store/appointment/appointment.service";
import { BlockingLoader } from "../../../ui/components/loaders/blocking-loader";
import { appointmentQuery } from "../../../../store/appointment/appointment.query";
import { initialNewAppointment } from "../../../../store/appointment/appointment.store";
import { COLOR_MAP_TO_BG } from "../../admin/user-color-view/user-color-view";
import { ExportCalendar } from "../appointments/components/export-calendar";
import { uiQuery } from "../../../../store/ui-storage/ui.query";
import { useLocation } from "react-router-dom";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { CalendarAgents } from "./calendar-agents";
import { ContentHolder } from "src/components/ui/main-layout/layout";
import { CustomToolbar } from "./custom-toolbar";
import { CustomEvent } from "./custom-event";
import { CustomMonthEvent } from "./custom-month-event";
import { MonthWrapperEvent } from "./month-event-wrapper";
import { CustomDayEvent } from "./custom-day-event";
import { authQuery } from "src/store/auth/auth.query";

const localizer = momentLocalizer(moment);
const Calendar = withDragAndDrop<any, any>(C);

const SCROLL_TO = 100;

type OnSelectSlotReturnType = {
  start: stringOrDate;
  end: stringOrDate;
  slots: Date[] | string[];
  action: "select" | "click" | "doubleClick";
};

// interface ISelectedAgent extends IStaff {
//     isSelected: boolean;
// }

export function CalendarView() {
  // const history = useHistory();
  const location = useLocation();
  const [newAppointment] = useObservable(appointmentQuery.newAppointment$);

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const [eventsLoading] = useObservable(calendarQuery.isLoading$);
  const [events] = useObservable(calendarQuery.events$);
  const [staff] = useObservable(calendarQuery.allStaff);
  const [colorsMap] = useObservable(calendarQuery.staffToColors);
  const [isMobile] = useObservable(uiQuery.isMobile$);
  const [user] = useObservable(authQuery.selectUser$);
  console.log(user);
  const [isExporting] = useObservable(appointmentQuery.isExporting$);
  const [isExportOpened, setIsExportOpened] = useState(false);

  const [selectedAgents, setSelectedAgents] = useState(new Set<number>());
  const [appointments] = useObservable(appointmentQuery.appointments$);

  // const [scrollToDate, setScrollToDate] = useState<Date>(new Date());
  const [calendarEvents, setEvents] = useState<any>([]);
  const [scrollTo] = useState<any>(moment().set({ h: 7, m: 0, s: 0 }).toDate());

  useEffect(() => {
    appointmentService.init();
    calendarService.fetchCalendarEvents();

    const timeContent = document.querySelector(".rbc-time-content");

    timeContent?.scrollTo(0, SCROLL_TO);

    return () => {
      document.body.style.overflow = "inherit";
    };
  }, []);

  useEffect(() => {
    if (!calendarEvents.length) {
      setEvents(() =>
        events.filter((e) => (e.agent ? selectedAgents.has(e.agent.id) : true))
      );
    }
  }, [events, selectedAgents]);

  useEffect(() => {
    setEvents(() =>
      events.filter((e) => (e.agent ? selectedAgents.has(e.agent.id) : true))
    );
  }, [selectedAgents, events]);

  //* --------------------------------------------------

  const shouldOpenAppointmentWithId = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("open");
  }, [location.search]);

  useEffect(() => {
    if (shouldOpenAppointmentWithId) {
      setIsModalOpen(true);
    }
    if (shouldOpenAppointmentWithId !== "new") {
      appointments.find((a) => a.id === parseInt(shouldOpenAppointmentWithId!));
    }
  }, [shouldOpenAppointmentWithId]);

  //* ---------------------------------------------------

  const onSelectSlot = useCallback(
    (e: OnSelectSlotReturnType) => {
      setIsEditing(false);
      setIsModalOpen(true);
      appointmentService.changeCreateAppointmentData({
        ...initialNewAppointment,
        dateRange: {
          timeFrom: moment(e.start, "hh:mm A").format("hh:mm A"),
          timeTo: moment(e.end, "hh:mm A").format("hh:mm A"),
          date: moment(e.start),
        },
      });
    },
    [setIsEditing, setIsModalOpen]
  );

  // const activeEvents = useMemo(() => {
  //     return events.filter(e => e.agent ? selectedAgents.has(e.agent.id) : true)
  // }, [selectedAgents, events]);

  const onSelectEvent = useCallback(
    async (e: IAppointmentEvent) => {
      setIsLoading(true);
      await appointmentService.openAppointmentById(e.id);
      setIsEditing(true);
      setIsModalOpen(true);
      setIsLoading(false);
    },
    [setIsEditing, setIsModalOpen, setIsLoading]
  );

  const onCloseCreateModal = () => {
    // calendarService.fetchCalendarEvents();
    setIsModalOpen(false);
    setIsEditing(false);
  };

  const onToggleCreateModal = async (isOpen: boolean) => {
    await calendarService.fetchCalendarEvents();
    setIsModalOpen(isOpen);
    setIsEditing(false);
  };

  const scheduleAppointment = useCallback(() => {
    setIsModalOpen(true);
    appointmentService.changeCreateAppointmentData({
      ...initialNewAppointment,
    });
  }, []);

  const eventPropGetter = (event: IAppointmentEvent): any => {
    const style: React.CSSProperties = {
      borderRadius: "5px",
      // borderLeft: '1px solid white',
      // borderBottom: '1px solid white',
      // boxShadow: `0 0 0 1px #D3D9E7`,
      color: "black",
    };

    if (event.agent?.color) {
      style.color = event.agent.color;
      style.background = COLOR_MAP_TO_BG.get(event.agent.color) || "#a9a9a9";
    }

    return {
      style,
    };
  };

  const onEventDrop = async ({ start, end, event }: any) => {
    const events = Array.from(calendarEvents).map((evt: any) => {
      if (evt.id === event.id) {
        return {
          ...evt,
          start,
          end,
        };
      }

      return evt;
    });

    setEvents(() => events);
    // setIsLoading(true);
    await calendarService.changeAppointmentTime(event.id, start, end);
    // setIsLoading(false);
  };

  const onEvtResize = async ({
    event,
    start,
    end,
  }: {
    event: IAppointmentEvent;
    start: stringOrDate;
    end: stringOrDate;
  }) => {
    const events = Array.from(calendarEvents).map((evt: any) => {
      if (evt.id === event.id) {
        return {
          ...evt,
          start,
          end,
        };
      }

      return evt;
    });

    setEvents(() => events);
    // setIsLoading(true);
    await calendarService.changeAppointmentTime(event.id, start, end);
    // setIsLoading(false);
  };

  const handleExportOpened = useCallback((isOpen: boolean) => {
    setIsExportOpened(isOpen);
  }, []);

  const handleSelectAgent = useCallback((sa: any) => {
    setSelectedAgents(sa);
  }, []);

  return (
    <ContentHolder noPadding>
      <BlockingLoader open={loading} />
      {!isMobile ? (
        <Panel type={PanelType.EMPTY}>
          <div className={CssClasses.calendar_header_wrapper}>
            <Header3
              style={{ margin: 0, display: "flex", alignItems: "center" }}
            >
              Calendar
            </Header3>
            <div className={CssClasses.last_imported}>
              {/* Last Imported: {moment().format('LLL')} */}
            </div>
          </div>
        </Panel>
      ) : null}
      <Panel
        type={PanelType.WITH_SHADOW}
        additionalStyles={
          isMobile
            ? {
                margin: "20px 0 20px",
              }
            : { margin: "0 20px 20px" }
        }
      >
        <Calendar
          defaultView="week"
          localizer={localizer}
          events={calendarEvents}
          selectable
          onEventDrop={onEventDrop}
          onEventResize={onEvtResize}
          // date={moment().toDate()}
          min={moment().set({ h: 6, m: 0, s: 0 }).toDate()}
          max={moment().set({ h: 21, m: 0, s: 0 }).toDate()}
          // views={['month', 'week', 'day']}
          views={{
            month: true,
            week: true,
            day: true,
          }}
          eventPropGetter={eventPropGetter}
          onSelectSlot={onSelectSlot}
          onSelectEvent={onSelectEvent}
          startAccessor="start"
          endAccessor="end"
          resizable={false}
          showMultiDayTimes
          scrollToTime={scrollTo}
          components={{
            toolbar: (toolbar: any) => (
              <CustomToolbar
                toolbar={toolbar}
                scheduleAppointment={scheduleAppointment}
                setIsExportOpened={handleExportOpened}
              />
            ),
            event: (event: any) => <CustomEvent event={event} />,
            eventWrapper: (eventProps: any) => (
              <MonthWrapperEvent eventProps={eventProps} />
            ),
            month: {
              event: (event: any) => <CustomMonthEvent event={event} />,
            },
            day: {
              event: (event: any) => <CustomDayEvent event={event} />,
            },
            // dateCellWrapper: (props: any) => <MonthView events={events} {...props} />,
          }}
          style={{
            height: isMobile
              ? window.screen.availHeight - 150
              : window.screen.availHeight - 150,
          }}
        />
      </Panel>
      <CalendarAgents
        staff={staff}
        colorsMap={colorsMap}
        onAgentSelect={handleSelectAgent}
        user={user}
      />
      <ExportCalendar
        open={isExportOpened}
        loading={isExporting}
        onClose={() => setIsExportOpened(false)}
      />
      <CreateAppointment
        isEditing={isEditing}
        isCreateModalOpened={isModalOpen}
        onCloseCreateModal={onCloseCreateModal}
        newAppointment={newAppointment}
        setIsCreateModalOpened={onToggleCreateModal}
      />
    </ContentHolder>
  );
}
