import { useObservable } from "@libreact/use-observable";
import { useEffect } from "react";
import { fromEvent } from "rxjs";
import { distinctUntilChanged, pluck, switchMap, debounceTime } from "rxjs/operators";
import { IProspect } from "src/store/prospects/models/prospect.interface";
import { currentStaff$, prospectsLeading$, prospectsLeadingTotal$, loadingProspects$ } from "../staff.query";
import { staffService } from "../staff.service";

export type ProspectsLeadingHookTuple = [
    IProspect[],
    number,
    boolean,
];

export function useProspectsLeading (): ProspectsLeadingHookTuple {
    const [currentStaff] = useObservable(currentStaff$);
    const [prospectsLeading] = useObservable(prospectsLeading$);
    const [prospectsLeadingTotal] = useObservable(prospectsLeadingTotal$);
    const [loading] = useObservable(loadingProspects$);

    useEffect(() => {
        if (currentStaff?.id) {
            staffService.fetchStaffProspects(currentStaff?.id!);
        }
    }, []);

    useEffect(() => {
        const input: HTMLElement = document.getElementById('leadeing-search') as HTMLElement;

        const subscription = fromEvent(input, 'input').pipe(
            pluck('target', 'value'),
            distinctUntilChanged(),
            debounceTime(400),
            switchMap(search => staffService.fetchStaffProspects(currentStaff?.id as number, search as string)),
        ).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    return [prospectsLeading, prospectsLeadingTotal, loading];
}