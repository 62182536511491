import { ChevronLeft, Visibility } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { MessageEditor } from './template-text-editor/template-text-editor';
import { RoutesEnum } from 'src/components/routing/routes';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { convertToRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';

import adminStyles from '../../admin.module.scss';
import { useObservable } from '@libreact/use-observable';
import { adminSettingsQuery } from 'src/store/admin-settings/admin-settings.query';
import { BlockingLoader } from 'src/components/ui/components/loaders/blocking-loader';
import { TemplateType } from '../../templates-table';
import { adminSettingsService } from 'src/store/admin-settings/admin-settings.service';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { SaveIcon } from 'src/components/ui/icons';
import { ContentHolder, panelMobileStyles } from 'src/components/ui/main-layout/layout';
import { uiQuery } from 'src/store/ui-storage/ui.query';

import styles from './template-editor.module.scss';
import baseStyles from 'src/styles/base.module.scss';

const initialFormState = {
    name: '',
    subject: '',
    notice: '',
    options: null,
};

interface ILocation {
    type: TemplateType;
}

export const TemplateEditor = () => {
    const history = useHistory();
    const location = useLocation<ILocation>();
    const params = useParams<any>();
    const [isNew] = useState<any>(location.pathname.includes('new'));
    const [editMode] = useState(params.id ? true : false);
    
    const [selectedTemplate] = useObservable(adminSettingsQuery.selectedTemplate$);
    const [loading] = useObservable(adminSettingsQuery.loading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);
    const [formState, setFormState] = useState(selectedTemplate ? selectedTemplate : initialFormState);

    useEffect(() => {
        if (selectedTemplate) {
            setFormState(selectedTemplate);
        }

        return () => {
            if (!history.location.pathname.includes('edit')) {
                adminSettingsService.resetCurrentTemplate();
            }
        };
    }, [selectedTemplate, history.location.pathname]);

    useEffect(() => {
        if (!selectedTemplate && editMode) {
            adminSettingsService.fetchTemplateById(Number(params.id));
        }
    }, [editMode, params.id, selectedTemplate]);

    const handleValuesChange = (data: any) => {
        setFormState((prevState) => ({
            ...prevState,
            ...data,
        }));
    }

    const handleEditorChange = (editorState: any) => {
        const html = convertToHTML(editorState.getCurrentContent());

        setFormState((prevState) => ({
            ...prevState,
            notice: html as unknown as string,
            options: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        }));
    }

    const toggleSysData = (isSysData: boolean) => {
        setFormState((prevState) => ({
            ...prevState,
            systemData: isSysData ? {
                hours: 1,
            } : {},
        }));
    }

    const handleSystemData = (hours: number) => {
        setFormState((prevState) => ({
            ...prevState,
            options: null,
            systemData: {
                // content: 'Notice of Scheduled Showing for the next (hours)',
                hours,
            },
        }));
    }

    const handleSave = async () => {
        const ok = await adminSettingsService.addTemplate(formState, location.state.type);

        if (ok) {
            if (location.state.type === TemplateType.EMAIL) {
                history.push(RoutesEnum.ADMIN_SETTINGS);
            } else {
                history.push(`${RoutesEnum.ADMIN_SETTINGS}?tab=sms_settings`);
            }
        }
    }

    if (editMode && (!selectedTemplate || loading)) {
        return <BlockingLoader />
    }

    return <ContentHolder noPadding smallPadding>
        <div className={baseStyles.link_wrapper} onClick={() => history.push(location.state.type === TemplateType.SMS ? `${RoutesEnum.ADMIN_SETTINGS}?tab=sms_settings` : RoutesEnum.ADMIN_SETTINGS)}>
            <ChevronLeft /> <span>Back</span>
        </div>
        <Panel additionalStyles={{marginTop: 7}} type={PanelType.EMPTY}>
            <div className={styles.top_wrapper}>
                <Header3 style={{margin: '10px 0'}}>
                    { editMode ? 'Edit Template' : 'Add Template' }
                </Header3>
                {
                    !isMobile
                        ? (
                            <div className={styles.actionBarHolder}>
                                {
                                    !isNew
                                        ? (
                                            <Button
                                                icon={<Visibility style={{fontSize: 14}}/>}
                                                btnVariant={ButtonVariant.OUTLINE}
                                                onClick={() => history.push(`/admin/settings/templates/${params.id}`)}
                                                text="View Template"
                                            />
                                        )
                                        : null
                                }
                                <Button
                                    icon={<SaveIcon />}
                                    btnVariant={ButtonVariant.PRIMARY}
                                    onClick={handleSave}
                                    text="Save"
                                />
                            </div>
                        )
                        : null
                }
            </div>
        </Panel>
        <Panel additionalStyles={
            isMobile ? panelMobileStyles : { marginTop: 7 }
        }>
            <FormHolder>
                <Form
                    type={FormType.DEFAULT}
                    onChange={handleValuesChange}
                    data={formState}
                    fields={{
                        name: {
                            index: 0,
                            label: 'Name',
                            labelDisplay: 'block',
                            placeholder: 'Name',
                            fieldType: InputType.STRING,
                            // groupStyles: {
                            //     margin: '-10px 0 0 -48px',
                            // },
                        },
                        subject: {
                            index: 1,
                            label: 'Subject',
                            labelDisplay: 'block',
                            placeholder: 'Subject',
                            fieldType: InputType.STRING,
                            wrapperStyles: {
                                display: location.state.type === TemplateType.EMAIL ? 'flex' : 'none',
                            },
                            // groupStyles: {
                            //     margin: '-10px 0 0 -48px',
                            // },
                        },
                    }}
                />
            </FormHolder>
        </Panel>
        <Panel additionalStyles={
            isMobile ? {
                ...panelMobileStyles,
                padding: '0 10px 30px',
            } : { marginTop: 7 }
        }>
            <FormHolder overflow="none">
                <Form
                    type={FormType.DEFAULT}
                    onChange={handleValuesChange}
                    data={formState}
                    fields={{
                        notice: {
                            index: 0,
                            label: 'Body Part',
                            labelDisplay: 'block',
                            placeholder: 'Notice',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            height: 'auto',
                            // groupStyles: {
                            //     margin: '0 -64px 48px'
                            // },
                            customElement: <div className={adminStyles.bodyHolder}>
                                <MessageEditor 
                                    formState={formState} 
                                    onChange={handleEditorChange} 
                                    toggleSysData={toggleSysData}
                                    onSystemDataChange={handleSystemData} />
                                {/* <div className={adminStyles.addSysButton}>
                                    <span className={adminStyles.addIcon}>
                                        <Add fontSize="small" />
                                    </span>
                                    <span className={adminStyles.buttonText}>Add system data</span>
                                </div> */}
                            </div>
                        }
                    }}
                />
            </FormHolder>
        </Panel>
        {
            isMobile
                ? (
                    <Panel type={PanelType.EMPTY}>
                        <div className={styles.list_action_bar_mobile}>
                            {
                                !isNew
                                    ? (
                                        <Button
                                            icon={<Visibility style={{fontSize: 14}}/>}
                                            btnVariant={ButtonVariant.OUTLINE}
                                            onClick={() => history.push(`/admin/settings/templates/${params.id}`)}
                                            text="View"
                                        />
                                    )
                                    : null
                            }
                            <Button
                                icon={<SaveIcon />}
                                btnVariant={ButtonVariant.PRIMARY}
                                onClick={handleSave}
                                text="Save"
                            />
                        </div>
                    </Panel>
                ) : null
        }
    </ContentHolder>
}