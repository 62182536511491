import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {IStatistics} from "./models/statistics.interface";
import {ISort} from "../properties/search/search-properties.store";
import {AppointmentType, IAppointment} from "./models/appointment.interface";
import {IAppointmentFilters} from "./models/filters.interface";
import {GuestsDropdownValue, ICreateAppointment} from "./models/appointment-create.interface";
import {ITenant} from "../tenants/models/tenant.interface";
import {IProspect} from "../prospects/models/prospect.interface";
import {IOwner} from "../owners/models/owner.interface";
import {INamedEntityWithType, ISelectableItem} from "../properties/models/named-entity.interface";
import moment from "moment";

export interface IAppointmentsState extends EntityState {
    appointments: IAppointment[];
    appointmentsCount: number;
    statistics: IStatistics | null;
    sort: ISort<keyof IAppointment>;
    filters: Partial<IAppointmentFilters>;
    filtersData: {
        agents: ISelectableItem[];
        properties: ISelectableItem[];
    }
    loadingSearchItems: boolean;
    isEditing: boolean;
    searchType: string;
    searchList: any[],
    searchTotal: number;
    searchLoading: boolean;

    searchData: {
        properties: INamedEntityWithType<GuestsDropdownValue>[];
        propertiesTotal: number;
        propertiesLoading: boolean;
        agents: any[];
        tenants: ITenant[];
        prospects: IProspect[];
        owners: IOwner[];
    }

    newAppointment: ICreateAppointment;
    isExporting?: boolean;
}

export const initialNewAppointment: ICreateAppointment = {
    title: '',
    dateRange: {
        date: moment(),
        timeFrom: '01:00 PM',
        timeTo: '02:00 PM',
    },
    mode: 'create',
    type: AppointmentType.Showing,
    notes: '',
    guestsDropdownValue: GuestsDropdownValue.Agents,
    properties: [],
    guests: {
        [GuestsDropdownValue.Tenants]: [],
        [GuestsDropdownValue.Prospects]: [],
        [GuestsDropdownValue.Owners]: [],
        [GuestsDropdownValue.Agents]: [],
        [GuestsDropdownValue.Staff]: [],
    },
    sendEmail: true,
}

export const initialAppointmentFilters: IAppointmentFilters = {
    properties: [],
    agents: [],
    type: AppointmentType.Any,
    from: null,
    to: null,
}

const initialState: IAppointmentsState = {
    appointments: [],
    appointmentsCount: 0,
    statistics: null,
    isEditing: false,
    loadingSearchItems: true,
    filtersData: {
        agents: [],
        properties: [],
    },
    searchType: '',
    searchList: [],
    searchTotal: 0,
    searchLoading: false,
    searchData: {
        agents: [],
        tenants: [],
        properties: [],
        propertiesTotal: 0,
        propertiesLoading: false,
        prospects: [],
        owners: []
    },
    filters: initialAppointmentFilters,
    sort: {
        field: 'id',
        type: 'desc'
    },
    newAppointment: initialNewAppointment,

}

@StoreConfig({ name: 'appointments' })
export class AppointmentStore extends EntityStore<IAppointmentsState> {
    constructor() {
        super(initialState);
    }
}

export const appointmentStore = new AppointmentStore();
