import React, { Fragment, useEffect, useState, useMemo, useCallback, ChangeEvent } from "react";
import { ProspectsFilter } from './prospects-filter';
import { Panel, PanelType } from "src/components/ui/components/panel";
import { Header3 } from "src/components/ui/components/headers";
import { useObservable } from "@libreact/use-observable";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import {SearchInput, SearchInputType} from "src/components/ui/components/forms/inputs/search";
import { prospectsService } from "src/store/prospects/prospects.service";
import { searchProspectsService } from "src/store/prospects/prospects-search/prospects-search.service";
import { MessageIcon, SettingsIcon } from "src/components/ui/icons";
import { prospectsSearchQuery } from "src/store/prospects/prospects-search/prospects-search.query";

import { FavButton } from "src/components/ui/components/buttons/fav-button";
import { IProspectSearchCriteria } from "src/store/prospects/prospects-search/prospect-search.store";
import { Button, ButtonVariant } from "src/components/ui/components/buttons/button";
import { Table } from "src/components/ui/components/table";
import { IProspect, LeadStatus } from "src/store/prospects/models/prospect.interface";

import { useProspects } from 'src/store/prospects/hooks/use-prospects';

// icons
import { Delete, Add, ChevronLeft } from "@material-ui/icons";
// import { IDataTableColumn } from "react-data-table-component";
import { INumerated } from "src/store/properties/models/numerated.interface";
import { COLORS } from "src/components/ui/theme/variables/colors";
import { INamedEntity } from "src/store/properties/models/named-entity.interface";
import { KeyedNamedEntity } from "src/store/properties/search/search-properties.query";

import { FiltersList } from "src/components/ui/components/filters-list";
// import { IStaff } from "src/store/staff/models/staff.inreface";
import { Avatar, AvatarSize, FallbackType } from "src/components/ui/components/avatar/avatar";
import { ISelectedData } from "src/store/utils/common.interface";
import { messageService, RecieverType } from "src/store/message/message.service";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { ContentHolder } from "src/components/ui/main-layout/layout";

import baseStyles from 'src/styles/base.module.scss';
import CssClasses from './prospects.module.scss';
import { TitleHolder } from "src/components/containers/properties/pages/list";
import { usePaging } from "src/store/common/hooks/use-paging";
import { PagingType } from "src/store/common/models/common.interface";
// import { getFilterEntities, prospectsFilter } from "src/store/prospects/prospects.filter";

const FOR_FOLLOW_UP = 'For Follow Up';
const LOST = 'Lost';
const APPLICANT = 'Applicant';

const conditionalRowStyles = [
    {
        // actively renting & i % 2 === 0
        when: (row: INumerated & IProspect) => row.i % 2 === 0,
        style: {
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    }
];

const columns = (clickHandler: (e: ChangeEvent<any>) => void) => [
    {
        name: 'First Name',
        selector: 'firstName',
        sortable: true,
        minWidth: '150px',
        cell: (row: IProspect) => {
            return (
                <TitleHolder onClick={clickHandler} id={String(row.id)}>{row.firstName}</TitleHolder>
            );
        }
    },
    {
        name: 'Last Name',
        selector: 'lastName',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Phone Number',
        selector: 'phoneNumber',
        sortable: true,
        minWidth: '140px',
    },
    {
        name: 'Agent attached',
        selector: 'agentAttached',
        sortable: true,
        minWidth: '160px',
        maxWidth: '160px',
        cell: (row: IProspect) => {
            if (row.agentAttached) {
                return (
                    <div className={CssClasses.nameCell} onClick={clickHandler} id={String(row.id)}>
                        <Avatar 
                            src={ row.agentAttached.photo } 
                            size={AvatarSize.tiny}
                            fallbackType={FallbackType.ABBR}
                            fallback={row.agentAttached.name} />
                        <div>{ row.agentAttached.name }</div>
                    </div>
                )
            }
        }
    },
    {
        name: 'Lead Status',
        selector: 'leadStatus',
        sortable: false,
        minWidth: '120px',
        cell: (row: IProspect) => {
            if (row.leadStatus) {
                switch(row.leadStatus) {
                    case LeadStatus.FOR_FOLLOW_UP:
                        return <div onClick={clickHandler} id={String(row.id)}>{ FOR_FOLLOW_UP }</div>
                    case LeadStatus.LOST:
                        return <div onClick={clickHandler} id={String(row.id)}>{ LOST }</div>
                    case LeadStatus.APPLICANT:
                        return <div onClick={clickHandler} id={String(row.id)}>{ APPLICANT }</div>
                    default:
                        return null;
                }
            }
        }
    },
    {
        name: 'Bedr. N',
        selector: 'bedrN',
        sortable: true,
        minWidth: '80px',
        cell: (row: IProspect) => <div onClick={clickHandler} id={String(row.id)} style={{ width: '55%', textAlign: 'right' }}>{ row.bedrN }</div>
    },
    {
        name: 'Location',
        selector: 'location',
        sortable: true,
        minWidth: '120px',
    }
];

interface IPropsectsLocation {
    from: string;
    selectType: string;
}

interface IProspectsProps extends RouteComponentProps {
    isFilterable?: boolean;
    useSelectedUnitId?: number | string;
    omitFields?: string[];
}

// const ROWS_PER_PAGE = 10;

export default function Prospects ({
    isFilterable = true,
    useSelectedUnitId,
    // omitFields = [],
}: IProspectsProps) {
    const history = useHistory();
    const location = useLocation<IPropsectsLocation>();
    const [isFilterOpened, setIsFilterOpened] = useState(false);
    //* Selected Prospects
    const [selectedItemsCount, setSelectedItemsCount] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState<any>([]);

    const [search, setSearch] = useState<string>('');

    const [paging, setPaging, pushLocation] = usePaging(PagingType.PROSPECTS);
    const [prospects, isLoading] = useProspects();

    const [allSelectedFilters] = useObservable(prospectsSearchQuery.allSelectedFilters$);
    const [searchCriteria] = useObservable(prospectsSearchQuery.filterCriteria$);

    const [searchData] = useObservable(prospectsSearchQuery.searchData$);
    const [isMobile] = useObservable(uiQuery.isMobile$);
    const [filterQueries, setFilterQueries] = useState({
        attachedAgentQ: '',
        cityQ: '',
    });

    useEffect(() => {
        return () => {
            if (!history.location.pathname.includes('prospects')) {
                removeAllSelection();
            }
        }
    }, []);

    //* Filter options

    const notificationsFiltered = useMemo(() => {
        return searchData.notificationMethod
            .map(n => ({
                ...n,
                isSelected: !!searchCriteria.notificationMethod.find(item => item.id === n.id)
            }));
    }, [searchCriteria, searchData.notificationMethod, filterQueries]);

    const filteredProspects = useMemo(() => {
        if (search) {
            const searchQ = search.toLowerCase().trim();

            return prospects.filter(prospect => {
                const fullName = `${prospect.firstName} ${prospect.lastName}`;
                
                if (
                    fullName.toLowerCase().includes(searchQ) ||
                    prospect.email.toLowerCase().includes(searchQ) ||
                    prospect.phoneNumber.toLowerCase().includes(searchQ)
                ) {
                    return prospect;
                }
            });
        }

        return prospects;
    }, [prospects, search]);

    //* Filter options --------------------------------------------------

    const changeSearch = (q: string) => {
        setSearch(q);
    }

    const onOpen = () => {
        setIsFilterOpened(true);
    }

    const onApply = () => {
        // prospectsService.fetchProspects(0, paging.rowsPerPage);
        searchProspectsService.applyFilters();
        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const onClose = (cachedData?: IProspectSearchCriteria) => {
        if (cachedData) {
            searchProspectsService.updateSelectionCriteria(cachedData);
        }

        setIsFilterOpened(false);
    }

    const changeRowsNumber = (rowsPerPage: number, page: number) => {
        // setRowsPerPage(rowPerPage);
        setPaging(page, rowsPerPage);
        // prospectsService.fetchProspects(page - 1, rowsPerPage);
    }

    const onRowClicked = useCallback((e: any) => {
        pushLocation(`/profiles/prospects/${e.id}`, PagingType.PROSPECTS);
    }, [pushLocation]);

    const onCustomCellClick = useCallback((e: ChangeEvent<any>) => {
        e.persist();
        e.stopPropagation();

        pushLocation(`/profiles/prospects/${e.target.id}`, PagingType.PROSPECTS);
    }, [pushLocation])

    // const onSort = (column: IDataTableColumn<IProspect & INumerated>, sortDirection: "desc" | "asc", rows: number) => {
    //     searchProspectsService.setSorting({
    //         type: sortDirection,
    //         field: column.selector as keyof IProspect
    //     });
    //     prospectsService.fetchProspects(0, rows);
    // }

    const changePage = (page: number) => {
        // table component numerating start from 1, BE expects 0 as the first page
        setPaging(page, paging.rowsPerPage);
        // prospectsService.fetchProspects(page - 1, paging.rowsPerPage);
    }

    const handleSelectionChange = (data: ISelectedData<IProspect>) => {
        // setSelectedCount(data.selectedCount);
        // setSelectedProspects(data.selectedRows);
        // prospectsService.setSelectedProspects(data.selectedRows);

        setSelectedItemsCount(() => data.selectedCount);
        setSelectedItems(() => data.selectedRows);
    }

    const changeCriteria = (criteriaName: keyof IProspectSearchCriteria) => (item: INamedEntity) => {
        let data = (searchCriteria[criteriaName] as Array<any>);

        if (data.find(i => i.id === item.id)) {
            data = data.filter(i => i.id !== item.id);
        } else {
            data = [
                ...data,
                {
                    ...item,
                    isSelected: true
                }
            ]
        }

        searchProspectsService.updateSelectionCriteria({
            ...searchCriteria,
            [criteriaName]: data
        })
    }

    const removeAllSelection = () => {
        searchProspectsService.removeAllSelection();

        searchProspectsService.applyFilters();
        // prospectsService.fetchProspects(0, paging.rowsPerPage);
        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const onFilterDelete = (shouldResetPropspects: boolean) => (e: KeyedNamedEntity<keyof IProspectSearchCriteria>) => {
        searchProspectsService.deleteItem(e);

        searchProspectsService.applyFilters();

        // if (shouldResetPropspects) {
        //     prospectsService.fetchProspects(0, paging.rowsPerPage);
        // }
    }

    const handleSendMessage = () => {
        if (selectedItems.length) {
            messageService.selectRecievers(RecieverType.PROSPECTS, selectedItems);
            // prospectsService.resetSelectedProspects();
            history.push('/message', { from: 'prospects', backLink: 'prospects', category: 'profiles' });
        }
    }

    const handleAdd = () => {
        if (selectedItems.length) {
            messageService.selectRecievers(RecieverType.PROSPECTS, selectedItems);

            prospectsService.resetSelectedProspects();

            history.push('/message/prospects', { from: 'prospects', backLink: 'prospects', category: 'profiles' });
        }
    }

    const handleFiltersChange = (data: IProspectSearchCriteria) => {

        searchProspectsService.updateSelectionCriteria(data)
    }

    return (
        <ContentHolder noPadding smallPadding>
            {
                location.state?.from
                    ? (
                        <Panel type={PanelType.EMPTY}>
                            <div className={CssClasses.link_wrapper}  
                                onClick={() => history.push(location.state.from, { 
                                    from: 'prospects', 
                                    backLink: 'prospects',
                                    category: 'profiles',
                                })}>
                                <ChevronLeft /> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
                            </div>  
                        </Panel>
                    ) : null
            }
            {
                !isMobile
                    ? (
                        <Panel type={PanelType.EMPTY}>
                            <div className={CssClasses.header_wrapper}>
                                <Header3 style={{margin: 0, display: 'flex', alignItems: 'center'}}>
                                    Prospects
                                    <span className={baseStyles.total}>
                                        ({filteredProspects.length})
                                    </span>
                                </Header3>
                            </div>
                        </Panel>
                    )
                    : null
            }
            <Panel type={PanelType.EMPTY}>
                {
                    isMobile
                        ? (
                            <div className={CssClasses.list_action_bar_mobile}>
                                <Button
                                    icon={<MessageIcon />}
                                    btnVariant={ButtonVariant.OUTLINE}
                                    text=""
                                    onClick={() => history.push('/message', { 
                                        from: 'prospects', 
                                        backLink: 'prospects',
                                        category: 'profiles',
                                    })}
                                />
                                <Button
                                    icon={<Add style={{fontSize: 14}}/>}
                                    btnVariant={ButtonVariant.PRIMARY}
                                    onClick={() => history.push('/profiles/prospects/new')}
                                    text="Add prospect"
                                />
                            </div>
                        ) : null
                }
                <div className={CssClasses.list_action_bar}>
                    <div className={CssClasses.list_action_bar_left}>
                        <SearchInput
                            onChange={changeSearch}
                            inputType={SearchInputType.PRIMARY}
                            placeholderText="Search..."
                        />
                        {isFilterable && <FavButton
                            style={{marginLeft: 15}}
                            icon={<SettingsIcon/>}
                            onClick={onOpen}
                            badge={allSelectedFilters.length > 0 ? allSelectedFilters.length.toString() : undefined}
                        />}
                    </div>
                    <div className={CssClasses.list_action_bar_right}>
                        {
                            location.state?.selectType
                                ? (
                                    <Button
                                        onClick={handleAdd}
                                        icon={<Add style={{fontSize: 14}}/>}
                                        btnVariant={ButtonVariant.PRIMARY}
                                        text={`Add to the list ${selectedItems.length ? `:${selectedItems.length}` : ''}`}
                                    />)
                                : !isMobile
                                    ? (
                                        <Fragment>
                                            <Button
                                                icon={<MessageIcon />}
                                                btnVariant={ButtonVariant.OUTLINE}
                                                text="Message"
                                                onClick={() => history.push('/message', { 
                                                    from: 'prospects', 
                                                    backLink: 'prospects',
                                                    category: 'profiles',
                                                })}
                                            />
                                            <Button
                                                icon={<Add style={{fontSize: 14}}/>}
                                                btnVariant={ButtonVariant.PRIMARY}
                                                onClick={() => history.push('/profiles/prospects/new')}
                                                text="Add Prospect"
                                            />
                                        </Fragment>
                                    )
                                    : null
                        }
                    </div>
                </div>
            </Panel>
            {allSelectedFilters.length > 0 && <Panel additionalStyles={
                isMobile ? { 
                    margin: '0 0 15px',
                    padding: '8px 0',
                    width: '97.5%',
                } : {}
            }>
                <FiltersList filtersTitleName="" onDelete={onFilterDelete(true)} items={allSelectedFilters}/>
            </Panel>}
            {(selectedItemsCount > 0 && !location.state?.selectType) && <Panel additionalStyles={
                isMobile ? { 
                    margin: '0 0 15px',
                    width: '97.5%',
                } : {}
            }>
                <div className={CssClasses.list_action_props_selected}>
                    <div className={CssClasses.list_action_bar_left}>
                        {`${selectedItemsCount} Selected`}
                    </div>
                    <div className={CssClasses.selected_action_bar_right}>
                        <Button
                            icon={<MessageIcon />}
                            btnVariant={ButtonVariant.OUTLINE}
                            text={ isMobile ? '' : 'Message' }
                            onClick={handleSendMessage}
                        />
                    </div>
                </div>
            </Panel>}
            <Panel noPadding sideBorder>
                <Table<IProspect>
                    contextActions={[
                        <div className={CssClasses.delete_properties}>
                            <Delete/> Delete Selected
                        </div>
                    ]}
                    onSelectionChange={handleSelectionChange}
                    // selectableRowSelected={(row: any) => row.isSelected}
                    pagination={!useSelectedUnitId}
                    onChangePage={changePage}
                    totalRecordsNumber={filteredProspects.length}
                    onRowClicked={onRowClicked}
                    paginationPerPage={paging.rowsPerPage}
                    onChangeRowsNumber={changeRowsNumber}
                    columns={columns(onCustomCellClick)}
                    conditionalRowStyles={conditionalRowStyles}
                    // onSort={onSort}
                    loading={isLoading}
                    data={filteredProspects}
                    highlightRow={['firstName', 'lastName']}
                    activePage={paging.activePage}
                    paginationServer={false}
                    paginationServerOptions={{
                        persistSelectedOnPageChange: false,
                        persistSelectedOnSort: false,
                    }}
                />
            </Panel>
            <ProspectsFilter 
                removeAllSelection={removeAllSelection} 
                isFilterOpened={isFilterOpened} 
                searchCriteria={searchCriteria}
                onApply={onApply}
                onClose={onClose} 
                allSelectedFilters={allSelectedFilters} 
                onFilterDelete={onFilterDelete} 
                setFilterQueries={setFilterQueries} 
                filterQueries={filterQueries} 
                changeCriteria={changeCriteria}
                cssClasses={CssClasses}
                onFiltersCange={handleFiltersChange}
                notificationsFiltered={notificationsFiltered}
                />
        </ContentHolder>
    )
}