import { createEntityStore, EntityState } from "@datorama/akita";
import { List } from "immutable";
import { PropertiesImportingState } from "../properties/properties.store";
import { ICity, IPickupLocation, IProspect } from "./models/prospect.interface";

export interface IProspectsState extends EntityState {
    prospects: IProspect[];
    prospectsCount: number;
    selectedProspect: IProspect | null;
    cities: ICity[];
    pickupLocations: IPickupLocation[];
    isPickupLocationsLoading: boolean;
    selectedProspects: {
        data: IProspect[];
        total: number;
    };
    messageHistory: any[];
    isApplyFilters: boolean;
    isDeleteFilter: boolean;
    inititalProspects: List<IProspect>;
    inititalProspectsTotal: number;
    lastImportedProspects?: string;
    isProspectsImporting: boolean;
}

const initialState: IProspectsState = {
    prospects: [],
    prospectsCount: 0,
    selectedProspect: null,
    cities: [],
    pickupLocations: [],
    isPickupLocationsLoading: false,
    selectedProspects: {
        data: [],
        total: 0,
    },
    messageHistory: [],
    isApplyFilters: false,
    isDeleteFilter: false,
    inititalProspects: List([]),
    inititalProspectsTotal: 0,
    isProspectsImporting: false,
}

export const prospectsStore = createEntityStore<IProspectsState>(initialState, {
    name: 'prospects',
});