import React, { useMemo } from 'react';
import moment from 'moment';
import { EventHoledr, Eventlabel, EventTitle } from './styled';

export const CustomEvent = ({ event }: any) => {
    const startLabel = moment(event.event.start).format('hh:mm');
    const endLabel = moment(event.event.end).format('hh:mm A');

    const fontSize = useMemo(() => {
        switch(moment(event.event.end).diff(moment(event.event.start), 'minutes')) {
            case 0:
            case 15:
                return '8px';
            case 30:
                return '9px';
            default:
                return '12px';
        } 
    }, [event]);

    const titleMargin = useMemo(() => {
        switch(moment(event.event.end).diff(moment(event.event.start), 'minutes')) {
            case 0:
            case 15:
                return 'right';
            case 30:
                return 'right';
            default:
                return 'bottom';
        } 
    }, [event]);

    const flexDirection = useMemo(() => {
        switch(moment(event.event.end).diff(moment(event.event.start), 'minutes')) {
            case 0:
            case 15:
                return 'row';
            case 30:
                return 'row';
            default:
                return 'column';
        } 
    }, [event]);

    return (
        <EventHoledr flexDirection={flexDirection}>
            <EventTitle titleMargin={titleMargin} fontSize={fontSize}>{ event.title }</EventTitle>
            <Eventlabel overflowText fontSize={fontSize}>{ `${startLabel} - ${endLabel}` }</Eventlabel>
        </EventHoledr>
    );
}