import React, { Fragment, useEffect, useMemo, useState } from "react";
import CssClasses from './header.module.scss';
import MenuIcon from '@material-ui/icons/Menu';
import {useLocation} from 'react-router-dom';
import {routeGroupNameToUrlMap} from "../sidenav";
import {uiService} from "../../../../store/ui-storage/ui.service";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { guid } from "@datorama/akita";
import { MoreVert } from "@material-ui/icons";
import styled from "styled-components";
// import { userService } from "src/store/user/user.service";
import { useObservable } from "@libreact/use-observable";
import { appointmentQuery } from "src/store/appointment/appointment.query";
import { propertiesQuery } from "src/store/properties/properties.query";
import { staffCount$ } from "src/store/staff/staff.query";
import { prospectsQuery } from "src/store/prospects/prospects.query";
import { ownersQuery } from "src/store/owners/owners.query";
import { authService } from "src/store/auth/auth.service";

const ITEM_HEIGHT = 48;

const ProfileButton = styled(MoreVert)`
    color: #5384F9;
`;

enum TotalType {
    appointments = 'appointments',
    properties = 'properties',
    staff = 'staff',
    tenants = 'tenants',
    prospects = 'prospects',
    owners = 'owners',
}

const RenderTitle: React.FC<any> = ({ totalMap, pathName }) => {
    const title = useMemo(() => {
        switch(pathName?.toLowerCase()) {
            case TotalType.appointments:
                return (
                    <Fragment>
                        <span className={CssClasses.headerTitle}>{ pathName }</span>
                        <span className={CssClasses.headerTotal}>{ `(${totalMap?.appointments})` }</span>
                    </Fragment>
                );
            case TotalType.properties:
                return (
                    <Fragment>
                        <span className={CssClasses.headerTitle}>{ pathName }</span>
                        <span className={CssClasses.headerTotal}>{ `(${totalMap?.properties})` }</span>
                    </Fragment>
                );
            case TotalType.staff:
                return (
                    <Fragment>
                        <span className={CssClasses.headerTitle}>{ pathName }</span>
                        <span className={CssClasses.headerTotal}>{ `(${totalMap?.staff})` }</span>
                    </Fragment>
                );
            case TotalType.prospects:
                return (
                    <Fragment>
                        <span className={CssClasses.headerTitle}>{ pathName }</span>
                        <span className={CssClasses.headerTotal}>{ `(${totalMap?.prospects})` }</span>
                    </Fragment>
                );
            case TotalType.owners:
                return (
                    <Fragment>
                        <span className={CssClasses.headerTitle}>{ pathName }</span>
                        <span className={CssClasses.headerTotal}>{ `(${totalMap?.owners})` }</span>
                    </Fragment>
                );
            // case TotalType.tenants:
            //     return (
            //         <Fragment>
            //             <span className={CssClasses.headerTitle}>{ pathName }</span>
            //             <span className={CssClasses.headerTotal}>{ `(${totalMap?.tenants})` }</span>
            //         </Fragment>
            //     );
            default:
                return (
                    <Fragment>
                        <span className={CssClasses.headerTitle}>{ pathName }</span>
                    </Fragment>
                );
        }
    }, [totalMap, pathName]);

    return (
        <div>{ title }</div>
    );
}

export function MobileHeader() {
    const {pathname} = useLocation();
    const [anchorEl, setAnchorEl] = useState<any>(false);
    const open = Boolean(anchorEl);
    const [total, setTotal] = useState<any>();

    const [appTotal] = useObservable(appointmentQuery.count$);
    const [propTotal] = useObservable(propertiesQuery.selectFilteredProperties$);
    const [staffTotal] = useObservable(staffCount$);
    const [prospectsTotal] = useObservable(prospectsQuery.selectFilteredProspects$);
    const [ownersTotal] = useObservable(ownersQuery.selectFilteredOwners$);
    // const [tenatsTotal] = useObservable(tenantsQuery.tenantsCount$);


    useEffect(() => {
        setTotal((prevState: any) => ({
            ...prevState,
            appointments: appTotal,
            properties: propTotal.length,
            staff: staffTotal,
            prospects: prospectsTotal.length,
            owners: ownersTotal.length,
            // ['tenants']: tenatsTotal,
        }));
    }, [appTotal, propTotal, staffTotal, prospectsTotal, ownersTotal]);

    const options = useMemo(() => {
        return [
            {
                name: 'Log Out',
                icon: '',
                onClick: () => authService.signOut(),
            },
        ];
    }, []);

    const path = routeGroupNameToUrlMap.find(r => {
        if (r.url === '/' && pathname === '/') {
            return true
        }

        return pathname.includes(r.url) && r.url !== '/';
    });

    const handleClick = (event: React.SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div className={CssClasses.header_mobile_wrapper}>
        <div onClick={uiService.toggleSidenav} className={CssClasses.header_mobile_icon}>
            <MenuIcon style={{fontSize: 30}} />
        </div>
        <div className={CssClasses.header_mobile_title}>
            <RenderTitle totalMap={total} pathName={path?.name} />
        </div>
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <ProfileButton />
            </IconButton>
            <Menu
                id="logout-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '22ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={guid()} onClick={option.onClick}>
                        <span className={CssClasses.moreIcon}>{option.icon}</span>
                        <span className={CssClasses.moreName}>{option.name}</span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    </div>
}