import React, { Dispatch, Fragment, SetStateAction } from "react";
import { Settings } from "@material-ui/icons";
import { Button } from "src/components/ui/components/buttons/button";
import { ButtonVariant } from "src/components/ui/components/buttons/button";
import { FiltersList, ChipViewTypes } from "src/components/ui/components/filters-list";
import { Form, FormType, FormFieldDividerType, InputType } from "src/components/ui/components/forms/formBuilder/form";
import { Sidenav } from "src/components/ui/components/sidenav";
import { INamedEntity, ISelectableItem } from "src/store/properties/models/named-entity.interface";
import { KeyedNamedEntity } from "src/store/properties/search/search-properties.query";
import { IStaffSearchCriteria } from "src/store/staff/staff-search/staff-search.store";
import { searchStaffService } from "src/store/staff/staff-search/staff-search.service";
import { StaffRole } from "src/store/staff/models/staff.inreface";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { useObservable } from "@libreact/use-observable";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { DeviceType } from "src/store/ui-storage/ui.store";

const AGENT = 'Agent';
const ADMIN = 'Administrator';
const STAFF = 'Staff';

interface IStaffFilterProps {
    removeAllSelection: () => void;
    isFilterOpened: boolean;
    searchCriteria: IStaffSearchCriteria;
    onApply: () => void;
    onClose: () => void;
    allSelectedFilters: INamedEntity[];
    onFilterDelete: (k: boolean) => ((id: KeyedNamedEntity<keyof IStaffSearchCriteria>) => void) | undefined;
    setFilterQueries: Dispatch<SetStateAction<{ 
        attachedAgentQ: string;
        cityQ: string;
        naeighborhoodsQ: string;
    }>>
    filterQueries: { 
        attachedAgentQ: string;
        cityQ: string;
        naeighborhoodsQ: string;
    };
    changeCriteria: (criteriaName: keyof IStaffSearchCriteria) => (item: INamedEntity) => void;
    attachedAgentOptionsFiltered?: ISelectableItem[];
    cssClasses: {
        [k: string]: string;
    }
}

export const StaffFilter: React.FC<IStaffFilterProps> = ({
    removeAllSelection,
    isFilterOpened,
    searchCriteria,
    onApply,
    onClose,
    allSelectedFilters,
    onFilterDelete,
    cssClasses,
}) => {
    const [deviceType] = useObservable(uiQuery.deviceType$);

    return (
        <Sidenav<IStaffSearchCriteria> 
            header={
                <Fragment>
                    <Settings />
                    <span>
                        Filters
                    </span>
                </Fragment>
            } 
            footer={
                <Fragment>
                    <Button btnVariant={ButtonVariant.OUTLINE} onClick={removeAllSelection} text="Clear All" />
                    <Button style={{
                        marginLeft: 12
                    }} 
                    onClick={onApply}
                    btnVariant={ButtonVariant.PRIMARY} text="Apply" />
                </Fragment>
            } 
            width={
                (deviceType === DeviceType.MOBILE)
                    ? '100%'
                    : 542
                }
            isOpen={isFilterOpened}
            cachedData={searchCriteria}
            onClose={onClose}>
            <Fragment>
                <div className={cssClasses.filters_wrapper}>
                    <FiltersList 
                        filtersTitleName="Choose From The List Below" 
                        type={ChipViewTypes.BLOCK} 
                        items={allSelectedFilters} 
                        onDelete={onFilterDelete(false)} />
                </div>
                <FormHolder>
                    <Form<IStaffSearchCriteria> 
                        type={FormType.DEFAULT}
                        data={searchCriteria}
                        wrapperStyles={{
                            margin: '0 0 0 20px'
                        }}
                        onChange={e => {
                            searchStaffService.updateSelectionCriteria(e);
                        }} 
                        fields={{
                            roles: {
                                index: 1,
                                label: 'Role',
                                labelDisplay: 'block',
                                withDividers: FormFieldDividerType.BOTTOM,
                                //! TODO: improve select type
                                fieldType: InputType.SELECT,
                                height: 'auto',
                                labelStyles: {
                                    padding: 0,
                                },
                                groupStyles: {
                                    width: '100%',
                                    margin: deviceType === DeviceType.MOBILE
                                        ? '0 0 0 30px'
                                        : '0 0 18px 100px',
                                    // flexDirection: 'column'
                                },
                                selectableItems: [{
                                    label: AGENT,
                                    value: StaffRole.AGENT
                                }, {
                                    label: ADMIN,
                                    value: StaffRole.ADMINISTRATOR
                                }, {
                                    label: STAFF,
                                    value: StaffRole.STAFF
                                }]
                            },
                            prospectsLeading: {
                                index: 3,
                                label: 'Prospects Leading',
                                labelDisplay: 'block',
                                labelStyles: {
                                    padding: 0,
                                },
                                height: 'auto',
                                withDividers: FormFieldDividerType.BOTTOM,
                                fieldType: InputType.NUMBER_BETWEEN
                            },
                    }} />
                </FormHolder>
            </Fragment>
        </Sidenav>
    );
}
