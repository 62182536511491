import React, { useEffect, useState } from 'react';
import { ContentBlock, convertFromRaw, Editor, EditorState, Modifier, RichUtils } from 'draft-js';

import styles from './send-message.module.scss';
import { Toolbar } from './toolbar';

import 'draft-js/dist/Draft.css';
import { IntegerInput } from 'src/components/ui/components/forms/inputs/int-input';

export interface IMessageEditorProps {
    formState?: any;
    templateId?: number;
    onChange: (value: any) => void;
}

export const MessageEditor: React.FC<IMessageEditorProps> = ({ formState, templateId, onChange }) => {
    const [editorState, setEditorState] = useState(() => formState.options 
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(formState.options))) 
        : EditorState.createEmpty());

    const [hours, setHours] = useState(1);

    useEffect(() => {
        if (formState.options) {
            setEditorState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(formState.options))))
        } else {
            setEditorState(() => EditorState.createEmpty())
        }

        if (formState.systemData) {
            setHours(formState.systemData.hours);
        }
    }, [templateId]);

    const handleChange = (value: any) => {
        if (onChange) {
            setEditorState(value);
            onChange(value);
        }
    }

    const handleKeyCommand = (command: string, editorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
    
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
    
        return 'not-handled';
    }

    const applyStyle = (style: any) => {
        setEditorState(() => RichUtils.toggleInlineStyle(editorState, style));
    }

    const Component = (props: any) => {
        const key = props.contentState.getEntityMap().getLastCreatedEntityKey();
    
        const handleChange = (data: any) => {
            setHours(() => data.target.value);

            props.contentState.replaceEntityData(key, {
                text: `Notice of Scheduled Showing for the next (hours) ${data.target.value}`
            });

            const newContentState = Modifier.applyEntity(props.contentState, props.selection, props.block.getEntityAt(0));

            const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');
            setEditorState(newEditorState);
        }
    
        return (
            <div className={styles.customBlockHolder}>
                <span style={{ 
                    margin: '0 -40px', 
                    padding: '10px', 
                    backgroundColor: 'rgba(238,242,255,0.8)',
                    borderRadius: '8px',

                }}>Notice of Scheduled Showing</span>
                <span>for the next (hours)</span>
                <IntegerInput type="square" style={{ backgroundColor: 'rgba(238,242,255,0.8)' }} value={hours} onChange={handleChange}/>
            </div>
        );
    };

    const blockRenderer = (contentBlock: ContentBlock) => {
        const type = contentBlock.getType();

        if (type === "atomic") {
            return {
                component: Component,
                editable: false,
                props: {},
            };
        }
    };

    return (
        <div className={styles.editorWrapper}>
            <div className={styles.editorHolder}>
                <Editor 
                    blockRendererFn={blockRenderer}
                    handleKeyCommand={handleKeyCommand}
                    editorState={editorState} 
                    onChange={handleChange} />
            </div>
            <div className={styles.editorCotrols}>
                <Toolbar 
                    editorState={editorState} 
                    applyStyle={applyStyle} />
            </div>
        </div>
    );
}