import { ownersStore } from "./owners.store";
import { PagingStore } from "../utils/paging-store";
import { searchOwnersService } from "./search/search-owners.service";
import { IOwnersSearchFilters } from "./models/owners-search-filters.interface";
// import { ApiServiceInstance } from "../api/api-service";
import { IPagedResponse } from "../properties/models/paged-response.interface";
import { IOwner } from "./models/owner.interface";
import { PropertiesImportingState, propertiesStore } from "../properties/properties.store";
import { snackbarService } from "../snackbar/snackbar.service";
import { SnackbarType } from "../snackbar/snackbar.store";
import { SNACK_TIMEOUT, SYSTEM_MESSAGES } from "./owners.config";
import { propertiesService } from "../properties/properties.service";
import { tenantsService } from "../tenants/tenants.service";
import { api } from "src/api/api.service";
import { tenantsStore } from "../tenants/tenants.store";

interface IGetDto {
    response: IPagedResponse<IOwner>;
    lastImported: string | number;
}

const pagingStore = new PagingStore();

function buildFilteredRequest() {
    // const sorting = this.ownersSearchService.getSorting();
    // const filterCriteria = this.ownersSearchService.getCriteria();
    const params: Partial<IOwnersSearchFilters> = {};
    // params.sort = `${(sorting.field)},${sorting.type}`;
    params.page = pagingStore.currentPage.getValue();
    params.size = pagingStore.currentPageSize.getValue();

    const { portfolio, erManaging } = searchOwnersService.getCriteria();
    
    // if (search) {
    //     params.search = search;
    // }

    if (portfolio.length > 0) {
        params.portfolios = portfolio.map(item => item.id).join(',')
    }

    if (erManaging !== 'ANY') {
        params.doWeManage = erManaging === 'YES' ? 'true' : 'false';
    }

    return params;

}

export async function fetchOwners(page: number = 0, size: number = 10) {
    ownersStore.setLoading(true);
    pagingStore.setPagingData({page, size});

    const params: Partial<IOwnersSearchFilters> = {
        ...buildFilteredRequest()
    };
    const response = await api.get<IGetDto>('/owners', {
        params: {
            ...params,
        }
    });

    if (response.ok) {
        const { data } = response;

        ownersStore.update(state => ({
            ...state,
            ownersCount: data.response.total,
            owners: data.response.result.map(result => {
                return {
                    ...result,
                    portfolioNames: result.portfolios.map(p => p.name).join(', ')
                }
            }),
            lastImported: data.lastImported,
        }))
    }

    ownersStore.setLoading(false);
}

export async function fetchOwnerById(id: number) {
    ownersStore.setLoading(true);

    const response = await api.get<IOwner>(`/owners/${id}`);

    if (response.ok) {
        ownersStore.update(state => ({
            ...state,
            currentOwner: {
                ...response.data,
                city: response.data.city ? response.data.city : {}
            },
        }));
    }

    ownersStore.setLoading(false);
}

export async function importOwners() {
    try {
        ownersStore.update(state => ({
            ...state,
            importingState: PropertiesImportingState.IN_PROGRESS,
            owners: [],
        }));

        tenantsStore.update(state => ({
            ...state,
            tenants: [],
        }));

        propertiesStore.update(state => ({
            ...state,
            properties: [],
        }));

        await api.post('/properties/import');

        await tenantsService.getTenants();
        await propertiesService.getProperties();
        await fetchOwners(0, 9999);

        ownersStore.update(state => ({
            ...state,
            importingState: PropertiesImportingState.SUCCESSFULLY_FINISHED
        }));

        snackbarService.createSnackbar({
            type: SnackbarType.INFO,
            text: SYSTEM_MESSAGES.IMPORT_SUCCESS,
        }, SNACK_TIMEOUT);
    } catch (err) {
        ownersStore.update(state => ({
            ...state,
            importingState: PropertiesImportingState.ERROR
        }));

        snackbarService.createSnackbar({
            type: SnackbarType.ERROR,
            text: SYSTEM_MESSAGES.IMPORT_FAILED,
        }, SNACK_TIMEOUT);
    }
}

export function setSelectedOwners(data: IOwner[]) {
    ownersStore.update(state => ({
        ...state,
        selectedOwners: data,
    }));
}

export function resetSelectedOwners() {
    ownersStore.update(state => {
        return {
            ...state,
            selectedOwners: {
                data: [],
            },
        }
    });
}

export function resetOwners() {
    ownersStore.update(state => {
        return {
            ...state,
            owners: [],
            ownersCount: 0,
        }
    });
}

export const ownersService = {
    fetchOwners,
    fetchOwnerById,
    importOwners,
    setSelectedOwners,
    resetSelectedOwners,
    resetOwners,
};