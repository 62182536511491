import {BehaviorSubject} from "rxjs";

interface IPageData {
    page: number;
    size: number;
}

export class PagingStore {
    currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    currentPageSize: BehaviorSubject<number> = new BehaviorSubject<number>(10);

    setPagingData(data: IPageData): void {
        this.currentPageSize.next(data.size);
        this.currentPage.next(data.page);
    }

    getPagingData(): IPageData {
        return {
            page: this.currentPage.getValue(),
            size: this.currentPageSize.getValue()
        }
    }
}