import {IStatistics} from "../../../../../store/appointment/models/statistics.interface";
import CssClasses from '../appointments.module.scss';
import {CircularProgress} from "@material-ui/core";
import React from "react";

interface IProps {
    statistics: IStatistics | null;
}

export function AppointmentsStatistics(props: IProps) {
    const {statistics} = props;
    return <div className={CssClasses.statistics}>
        <div className={CssClasses.statistics_header}>
            Statistic
        </div>
        <div className={CssClasses.statistics_wrapper}>
            <div className={CssClasses.statistics_row}>
                <div className={CssClasses.statistics_col}>
                    <div className={CssClasses.statistics_col_number}>
                        {statistics ? statistics.overallMeetings : <CircularProgress/>}
                    </div>
                    <div className={CssClasses.btn_overall_meetings}>
                        overall meetings
                    </div>
                </div>
                <div className={CssClasses.statistics_col}>
                    <div className={CssClasses.statistics_col_number}>
                        {statistics ? statistics.meetings : <CircularProgress/>}
                    </div>
                    <div className={CssClasses.btn_meetings}>
                        meetings
                    </div>
                </div>
                <div className={CssClasses.statistics_col}>
                    <div className={CssClasses.statistics_col_number}>
                        {statistics ? statistics.showing : <CircularProgress/>}
                    </div>
                    <div className={CssClasses.btn_showings}>
                        showings
                    </div>
                </div>
                <div className={CssClasses.statistics_col}>
                    <div className={CssClasses.statistics_col_number}>
                        {statistics ? statistics.others : <CircularProgress/>}
                    </div>
                    <div className={CssClasses.btn_others}>
                        others
                    </div>
                </div>
            </div>
        </div>
    </div>
}
