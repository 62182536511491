import { createEntityStore, EntityState } from "@datorama/akita";
import { ISort } from "../../properties/search/search-properties.store";
import { IProspect, LeadStatus } from "../models/prospect.interface";
// import {INamedEntity, ISelectableItem} from "../../properties/models/named-entity.interface";

export enum NotificationType {
    sms = 'SMS',
    email = 'EMAIL',
    phoneEmail = 'Phone & Email'
}

interface IAgent {
    id: string;
    email: string;
    name: string;
}

interface ICity {
    id: number;
    name: string;
}

export interface INotificationMethod {
    id: number | string;
    name: string;
}

export interface IProspectSearchCriteria {
    notificationMethod: INotificationMethod[];
    // search: string;
    agentsAttached: IAgent[];
    leadStatus: LeadStatus[];
    approximateBudget: {
        from: number | null;
        to: number | null;
    };
    bedrN: {
        from: number | null;
        to: number | null;
    };
    city: ICity[];
    approximateBudgetF: string;
    approximateBudgetT: string;
    bedroomsNumberF: string;
    bedroomsNumberT: string;
}

export interface ISearchProspectsState extends EntityState {
    searchData: {
        agentsAttached: IAgent[];
        leadStatus: LeadStatus[];
        city: ICity[];
        notificationMethod: INotificationMethod[];
    };
    selectedCriteria: IProspectSearchCriteria;
    appliedFilters: IProspectSearchCriteria;
    sort: ISort<keyof IProspect>;
}

export const searchProspectInitialState: ISearchProspectsState = {
    searchData: {
        agentsAttached: [],
        leadStatus: [],
        city: [],
        notificationMethod: [],
    },
    selectedCriteria: {
        // search: '',
        notificationMethod: [],
        agentsAttached: [],
        leadStatus: [],
        approximateBudget: {
            from: null,
            to: null,
        },
        bedrN: {
            from: null,
            to: null,
        },
        city: [],
        approximateBudgetF: '',
        approximateBudgetT: '',
        bedroomsNumberF: '',
        bedroomsNumberT: '',
    },
    sort: {
        field: 'firstName',
        type: 'asc'
    },
    appliedFilters: {
        notificationMethod: [],
        agentsAttached: [],
        leadStatus: [],
        approximateBudget: {
            from: null,
            to: null,
        },
        bedrN: {
            from: null,
            to: null,
        },
        city: [],
        approximateBudgetF: '',
        approximateBudgetT: '',
        bedroomsNumberF: '',
        bedroomsNumberT: '',
    },
}

export const searchProspectsStore = createEntityStore<ISearchProspectsState>(searchProspectInitialState, {
    name: 'prospects_search',
});