import { IProspectSearchCriteria, searchProspectInitialState, searchProspectsStore } from "./prospect-search.store";
import { INamedEntity } from "../../properties/models/named-entity.interface";
import { ICity, INotificationMethod } from "../models/prospect.interface";
import { IPagedResponse } from 'src/store/properties/models/paged-response.interface';
// import { ApiServiceInstance } from "src/store/api/api-service";
import { api } from "src/api/api.service";

export interface ICityGetDto extends IPagedResponse<ICity> {}
interface INotificationMethodGetDto extends IPagedResponse<INotificationMethod> {}

// export function getSorting() {
//     return searchProspectsStore.getValue().sort;
// }

// export function setSorting(sorting: ISort<keyof IProspect>) {
//     searchProspectsStore.update(state => ({
//         ...state,
//         sort: sorting,
//     }));
// }

export function updateSelectionCriteria(data: Partial<IProspectSearchCriteria>) {
    searchProspectsStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...state.selectedCriteria,
            ...data,
        }
    }))
}

export function deleteItem(item: INamedEntity) {
    searchProspectsStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...state.selectedCriteria,
            approximateBudget: {
                from: item.key === "approximateBudgetF" ? null : state.selectedCriteria.approximateBudget.from,
                to: item.key === "approximateBudgetT" ? null : state.selectedCriteria.approximateBudget.to,
            },
            bedrN: {
                from: item.key === "bedroomsNumberF" ? null : state.selectedCriteria.bedrN.from,
                to: item.key === "bedroomsNumberT" ? null : state.selectedCriteria.bedrN.to,
            },
            agentsAttached: state.selectedCriteria.agentsAttached.filter(agent => agent.id !== item.id),
            city: state.selectedCriteria.city.filter(c => c.id !== item.id),
            // neighborhood: state.selectedCriteria.neighborhood.filter(n => n.id !== item.id),
            leadStatus: state.selectedCriteria.leadStatus.filter(status => {
                return status !== item.id
            }),
            notificationMethod: state.selectedCriteria.notificationMethod.filter(nm => {
                return nm.id !== item.id
            })
        }
    }))
}

export async function fetchNotificationMethods(): Promise<void> {
    const notificationsReposne = await api.get<INotificationMethodGetDto>('/prospects/notifications');

    searchProspectsStore.update(state => ({
        ...state,
        searchData: {
            ...state.searchData,
            notificationMethod: notificationsReposne.data,
        }
    }));
}

export function resetSearchState() {
    searchProspectsStore.update(state => ({
        ...state,
        searchData: {
            city: [],
            neighborhood: [],
            notificationMethod: [],
            agentsAttached: [],
        },
    }))
}

export function removeAllSelection() {
    searchProspectsStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...searchProspectInitialState.selectedCriteria
        }
    }))
}

export function getCriteria() {
    return searchProspectsStore.getValue().selectedCriteria;
}

export function applyFilters() {
    searchProspectsStore.update(state => ({
        ...state,
        appliedFilters: state.selectedCriteria,
    }));
}

export const searchProspectsService = {
    updateSelectionCriteria,
    deleteItem,
    resetSearchState,
    removeAllSelection,
    getCriteria,
    applyFilters,
    fetchNotificationMethods,
};