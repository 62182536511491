import {QueryEntity} from "@datorama/akita";
import {IPropertyGroupState, propertyGroupsStore, PropertyGroupsStore} from "./property-groups.store";

export class PropertyGroupQuery extends QueryEntity<IPropertyGroupState> {

    groupsCount$ = this.select(state => state.groupsCount);
    groups$ = this.select(state => state.groups);
    selectableGroups$ = this.select(state => state.selectableGroups)
    isLoading$ = this.selectLoading();

    currentPropertyGroup$ = this.select(state => state.currentGroup);

    groupsTotal$ = this.select(state => state.groupsTotal);

    constructor(protected store: PropertyGroupsStore) {
        super(store);
    }
}

export const propertyGroupQuery = new PropertyGroupQuery(propertyGroupsStore);
