import { ChevronLeft } from '@material-ui/icons';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import ContactDetails from './tabs/contact-details';
import { RoutesEnum } from 'src/components/routing/routes';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { ITab, Tabs } from 'src/components/ui/components/tabs/tabs';
import { useObservable } from '@libreact/use-observable';
import { BlockingLoader } from 'src/components/ui/components/loaders/blocking-loader';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { Modal } from 'src/components/ui/components/modal/modal';
import { staffService } from 'src/store/staff/staff.service';
import { currentStaff$ } from 'src/store/staff/staff.query';
import { IStaff, StaffRole } from 'src/store/staff/models/staff.inreface';
import ProspectsLeading from './tabs/prospects-leading';
import { Avatar, AvatarSize, FallbackType } from 'src/components/ui/components/avatar/avatar';
import { AdminIcon, AgentIcon, EditIcon, PersonIcon, StaffIcon, ArchiveBoxIcon } from 'src/components/ui/icons';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { ContentHolder } from 'src/components/ui/main-layout/layout';
import { uiQuery } from 'src/store/ui-storage/ui.query';
import { SYSTEM_MESSAGES } from 'src/store/staff/staff.config';
import { ILocationState, PagingType } from 'src/store/common/models/common.interface';
import { usePaging } from 'src/store/common/hooks/use-paging';
import { authQuery } from 'src/store/auth/auth.query';

import styles from './staff-info.module.scss'

interface IStaffInfoProps extends RouteComponentProps<{id: string}> {}

interface IRenderRole {
    staffRole: StaffRole;
    onRowClick?: (e: ChangeEvent<any>) => void;
    id?: string;
}

const AGENT = 'Agent';
const ADMIN = 'Administrator';
const STAFF = 'Staff';

export const RenderRole = ({ staffRole, onRowClick, id }: IRenderRole) => {
    switch(staffRole) {
        case StaffRole.AGENT:
            return <span className={styles.icon_table_holder}>
                <span className={styles.icon_table}>
                    <AgentIcon />
                </span>
                <span className={styles.role} onClick={onRowClick} id={String(id)}>{ AGENT }</span>
            </span>
        case StaffRole.STAFF:
            return <span className={styles.icon_table_holder}>
                <span className={styles.icon_table}>
                    <StaffIcon />
                </span>
                <span className={styles.role} onClick={onRowClick} id={String(id)}>{ STAFF }</span>
            </span>
        case StaffRole.ADMINISTRATOR:
            return <span className={styles.icon_table_holder}>
                <span className={styles.icon_table}>
                    <AdminIcon />
                </span>
                <span className={styles.role} onClick={onRowClick} id={String(id)}>{ ADMIN }</span>
            </span>
        default:
            return null;
    }
}

const StaffInfo: React.FC<IStaffInfoProps> = ({ match, history }) => {
    const location = useLocation<ILocationState>();
    const [,, pushLocation] = usePaging(PagingType.STAFF);
    const [user] = useObservable(authQuery.selectUser$);
    const [open, setOpen] = useState<boolean>(false);
    const [currentStaff] = useObservable(currentStaff$);
    // const [isLoading] = useObservable(isLoading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        const parsedId = parseInt(match.params.id, 10);
        staffService.fetchStaffById(parsedId);

        return () => {
            if (!history.location.pathname.includes('edit')) {
                staffService.resetCurrentStaff();
            }
        }
    }, [])

    const archiveHandler = () => {
        if (currentStaff) {
            staffService.archiveStaff(currentStaff.id);
            history.push('/profiles/staff');
        }
    }

    const handleClose = () => setOpen(false);

    if (!currentStaff) {
        return <BlockingLoader />
    }

    const tabs: ITab[] = [
        {
            basePath: location.pathname,
            specificPath: '',
            children: <ContactDetails staff={currentStaff} />,
            tabName: 'Contact Details',
            additionalStyles: isMobile
                ? { borderRadius: '0 0 0 10px' } : {}
        },
        {
            basePath: location.pathname,
            specificPath: 'prospepcts_leading',
            children: <ProspectsLeading />,
            tabName: 'Prospects Leading',
            panelType: PanelType.EMPTY,
            additionalStyles: isMobile
                ? { borderRadius: '10px 0 0 10px', margin: 0 } : {
                    margin: 0
            },
        },
        {
            basePath: location.pathname,
            specificPath: 'lease_contracts',
            children: <div>Showing History</div>,
            tabName: 'Lease Contracts'
        },
        {
            basePath: location.pathname,
            specificPath: 'edit_history',
            children: <div>Edit History</div>,
            tabName: 'Edit History'
        }
    ];

    return (
        <ContentHolder noPadding smallPadding>
            <div style={{ cursor: 'pointer' }} className={styles.link_wrapper} onClick={() => 
                pushLocation(RoutesEnum.STAFF_LIST, PagingType.STAFF)
            }>
                <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
            </div>
            <Panel additionalStyles={
                isMobile ? { margin: '0 0 28px' } : { marginTop: 7 }
            } type={PanelType.EMPTY}>
                <div className={styles.top_wrapper}>
                    <Header3 style={{margin: '10px 0'}}>
                        Agent Info
                    </Header3>
                    {
                        !isMobile
                            ? (
                                <div className={styles.actionBarHolder}>
                                    {
                                        user?.role === StaffRole.ADMINISTRATOR
                                            ? (
                                                <Button
                                                    icon={<ArchiveBoxIcon />}
                                                    btnVariant={ButtonVariant.OUTLINE}
                                                    onClick={() => setOpen(true)}
                                                    text="Archive"
                                                />
                                            ) : null
                                    }
                                    {
                                        user?.role === StaffRole.ADMINISTRATOR ||
                                        user?.id === currentStaff?.id
                                            ? (
                                                <Button
                                                    icon={<EditIcon />}
                                                    btnVariant={ButtonVariant.OUTLINE}
                                                    onClick={() => history.push(`/profiles/staff/${match.params.id}/edit`)}
                                                    text="Edit"
                                                />
                                            ) : null
                                    }
                                </div>
                            )
                            : null
                    }
                </div>
            </Panel>
            <Panel additionalStyles={
                isMobile ? { 
                    margin: '0 0 30px',
                    width: '97.5%',
                } : {}
            }>
                <FormHolder
                    title="Staff"
                    icon={<PersonIcon />}>
                    <Form<Partial<IStaff>>
                        type={FormType.DEFAULT}
                        data={currentStaff}
                        readOnly
                        fields={{
                            name: {
                                index: 0,
                                label: 'Name & Position',
                                labelDisplay: 'block',
                                labelStyles: {
                                    fontFamily: 'ManropeThin',
                                    fontSize: '14px',
                                },
                                height: 'auto',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <div className={styles.staffName}>
                                    <Avatar 
                                        src={currentStaff.photo} 
                                        size={AvatarSize.mid}
                                        fallbackType={FallbackType.ABBR}
                                        fallback={currentStaff.name}
                                        style={{
                                            margin: '0 25px 0 0'
                                        }} />
                                    <div>
                                        <div style={{ fontFamily: 'ManropeSemibold', margin: '0 0 4px' }}>{ currentStaff.name }</div>
                                        <RenderRole staffRole={currentStaff.role} />
                                    </div>
                                </div>
                            },
                        }}
                    />
                </FormHolder>
            </Panel>
            <Tabs
                tabs={tabs}
            />
            {
                isMobile
                    ? (
                        <Panel type={PanelType.EMPTY}>
                            <div className={styles.list_action_bar_mobile}>
                                {
                                    user?.role === StaffRole.ADMINISTRATOR
                                        ? (
                                            <Button
                                                icon={<ArchiveBoxIcon />}
                                                btnVariant={ButtonVariant.OUTLINE}
                                                onClick={() => setOpen(true)}
                                                text="Archive"
                                            />
                                        ) : null
                                }
                                {
                                    user?.role === StaffRole.ADMINISTRATOR ||
                                    user?.id === currentStaff?.id
                                        ? (
                                            <Button
                                                icon={<EditIcon />}
                                                btnVariant={ButtonVariant.OUTLINE}
                                                onClick={() => history.push(`/profiles/staff/${match.params.id}/edit`)}
                                                text="Edit"
                                            />
                                        ) : null
                                }
                            </div>
                        </Panel>
                    ) : null
            }
            <Modal
                open={open}
                title={SYSTEM_MESSAGES.ARCHIVE_CONFIRM}
                wrapperStyles={{ padding: '37px' }}
                content={<div style={{ width: '400px' }}></div>}
                saveButtonText="Archive"
                onClose={handleClose}
                onSave={archiveHandler} />
        </ContentHolder>
    );
}

export default StaffInfo