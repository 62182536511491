import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

interface ICustomScrollbars {
    children: React.ReactNode | React.ReactNode[];
    style?: React.CSSProperties;
}

export const CustomScrollbars: React.FC<ICustomScrollbars> = ({ children, style }) => {
    const renderTrack = ({ style, ...props }: any) => {
        return <div 
            style={{ ...style, backgroundColor: '#C3DDFF', width: '2.62px' }}
            {...props} />;
    }

    const renderThumb = ({ style, ...props }: any) => {
        return <div 
            style={{ ...style, backgroundColor: '#789EFF', width: '2.62px' }}
            {...props} />;
    }

    const handleUpdate = (values: any) => {
        // console.log('handleUpdate -> ', values);
    }

    return (
        <Scrollbars
            style={{
                ...style
            }}
            autoHeight
            onUpdate={handleUpdate}
            renderTrackVertical={renderTrack}
            renderThumbVertical={renderThumb}
            renderTrackHorizontal={renderTrack}
            renderThumbHorizontal={renderThumb}
            renderView={props => <div {...props} className="view"/>}
            >
            {children}
        </Scrollbars>
    );
}