import React, { useEffect } from 'react';
import { ChevronLeft } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router-dom';
import { RoutesEnum } from 'src/components/routing/routes';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { ITab, Tabs } from 'src/components/ui/components/tabs/tabs';
import { useObservable } from '@libreact/use-observable';
import { BlockingLoader } from 'src/components/ui/components/loaders/blocking-loader';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';

// Tabs
import GroupDetails from './tabs/group-details';
import { tenantGroupsQuery } from 'src/store/tenants/groups/tenant-groups.query';
import { IGroup } from 'src/store/tenants/groups/models/group.interface';
import { tenantGroupsService } from 'src/store/tenants/groups/tenant-groups.service';
import { ArchiveIcon, EditIcon, KeyIcon } from 'src/components/ui/icons';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { ContentHolder, headMobileStyles, panelMobileStyles } from 'src/components/ui/main-layout/layout';
import { uiQuery } from 'src/store/ui-storage/ui.query';

import styles from './tenant-group-info.module.scss'
import baseStyles from 'src/styles/base.module.scss';
import { usePaging } from 'src/store/common/hooks/use-paging';
import { PagingType } from 'src/store/common/models/common.interface';
import { ContactHistory } from 'src/components/ui/components/contact-history/contact-history';

interface ITenantGroupInfoProps extends RouteComponentProps<{id: string}> {}

const TenantGroupInfo: React.FC<ITenantGroupInfoProps> = ({ location, match, history }) => {
    const [,, pushLocation] = usePaging(PagingType.GROUPS);
    const [currentGroup] = useObservable(tenantGroupsQuery.currentGroup$);
    // const [isLoading] = useObservable(tenantGroupsQuery.isLoading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        const parsedId = parseInt(match.params.id, 10);
        tenantGroupsService.fetchGroupById(parsedId);

        return () => {
            if (!history.location.pathname.includes('new') && !history.location.pathname.includes('edit')) {
                tenantGroupsService.resetCurrentGroup();
            }
        }
    }, [])

    const onDelete = async () => {
        await tenantGroupsService.delete(parseInt(match.params.id));
        history.push('/profiles/tenants?tab=custom_tenant_groups');
    }

    if (!currentGroup.id) {
        return <BlockingLoader />
    }

    const tabs: ITab[] = [
        {
            basePath: location.pathname,
            specificPath: '',
            children: <GroupDetails group={currentGroup} />,
            tabName: 'Group Details',
            panelType: PanelType.EMPTY,
        },
        // {
        //     basePath: location.pathname,
        //     specificPath: 'prospepcts_leading',
        //     children: <ProspectsLeading staff={currentStaff} />,
        //     tabName: 'Prospects Leading',
        //     panelType: PanelType.EMPTY,
        //     additionalStyles: {
        //         margin: 0
        //     },
        // },
        {
            basePath: location.pathname,
            specificPath: 'contact_history',
            children: <ContactHistory id={currentGroup.id} context="groups" field="groups" />,
            tabName: 'Contact History',
            panelType: PanelType.EMPTY,
        },
        {
            basePath: location.pathname,
            specificPath: 'editing_history',
            children: <div>Editing History</div>,
            tabName: 'Editinig History'
        }
    ];

    return (
        <ContentHolder noPadding smallPadding>
            <div style={{ cursor: 'pointer' }} className={baseStyles.link_wrapper} onClick={() => 
                pushLocation(`${RoutesEnum.TENANTS_LIST}?tab=custom_tenant_groups`, PagingType.GROUPS)
            }>
                <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
            </div>
            <Panel additionalStyles={
                isMobile ? headMobileStyles : { marginTop: 7 }
            } type={PanelType.EMPTY}>
                <div className={styles.top_wrapper}>
                    <Header3 style={{margin: '10px 0'}}>
                        Tenant Group Info
                    </Header3>
                    {
                        !isMobile
                            ? (
                                <div className={styles.actionBarHolder}>
                                    <Button
                                        icon={<ArchiveIcon />}
                                        onClick={onDelete}
                                        btnVariant={ButtonVariant.OUTLINE}
                                        text="Delete"
                                    />
                                    {/* <Button
                                        icon={<MessageIcon />}
                                        btnVariant={ButtonVariant.OUTLINE}
                                        text="Message"
                                    /> */}
                                    <Button
                                        icon={<EditIcon />}
                                        btnVariant={ButtonVariant.OUTLINE}
                                        onClick={() => history.push(`/profiles/tenants/groups/${match.params.id}/edit?mode=EDITING`)}
                                        text="Edit"
                                    />
                                </div>
                            )
                            : null
                    }
                </div>
            </Panel>
            <Panel additionalStyles={
                isMobile ? panelMobileStyles : {}
            }>
                <FormHolder
                    title="Tenant Group"
                    icon={<KeyIcon />}>
                    <Form<Partial<IGroup>>
                        type={FormType.DEFAULT}
                        data={currentGroup}
                        readOnly
                        fields={{
                            name: {
                                label: 'Title',
                                labelDisplay: 'block',
                                wrapperStyles: {
                                    flexDirection: 'row',
                                },
                                fieldType: InputType.STRING,
                            },
                            createdDate: {
                                fieldType: InputType.STRING,
                                label: 'Date Of Creation',
                                labelDisplay: 'block',
                                wrapperStyles: {
                                    flexDirection: 'row',
                                },
                                readonly: true,
                            }
                        }}
                    />
                </FormHolder>
            </Panel>
            <Tabs
                tabs={tabs}
            />
            {
                isMobile
                    ? (
                        <Panel additionalStyles={{ margin: 0 }} type={PanelType.EMPTY}>
                            <div className={styles.save_bar_mobile}>
                                <Button
                                    icon={<ArchiveIcon />}
                                    onClick={onDelete}
                                    btnVariant={ButtonVariant.OUTLINE}
                                    text="Delete"
                                />
                                {/* <Button
                                    icon={<MessageIcon />}
                                    btnVariant={ButtonVariant.OUTLINE}
                                    text="Message"
                                /> */}
                                <Button
                                    icon={<EditIcon />}
                                    btnVariant={ButtonVariant.OUTLINE}
                                    onClick={() => history.push(`/profiles/tenants/groups/${match.params.id}/edit?mode=EDITING`)}
                                    text="Edit"
                                />
                            </div>
                        </Panel>
                    ) : null
            }
        </ContentHolder>
    );
}

export default TenantGroupInfo