import styled from "styled-components";

export const AutocompletHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    outline: none !important;

    .ReactVirtualized__Grid {
        outline: none !important;
    }
`;

export const BusyIcon = styled.div`
    position: absolute;
    right: 62px;
    display: flex;
    align-items: center;
    font-size: 16px;

    & span {
        display: inline-block;
        margin: 0 0 0 4px;
        text-transform: none;
        font-weight: normal;
        font-size: 12px;
    }
`;

export const Content = styled.div`
    font-weight: 'normal';
`;

export const IconHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
