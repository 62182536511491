import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {List} from "./pages/list";
import {ViewProperty} from "./pages/view";
import {RoutesEnum} from "../../routing/routes";

export function Properties() {
    return <Switch>
        <Route exact path={RoutesEnum.PROPERTIES_LIST} component={List} />
        <Route path={RoutesEnum.PROPERTIES_EDIT} component={ViewProperty} />
        <Redirect to="/properties/" />
    </Switch>
}
