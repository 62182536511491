import { createEntityStore } from "@datorama/akita";
import { AuthState } from "../user/models/auth-state.enum";
import { IUser } from "../user/models/user.interface";

export interface ISigninDto {
    email: string;
    password: string;
}

export interface INewTokenPairDto {
    refreshToken: string;
    accessToken: string;
}

export interface IConfirmNewPasswordDto {
    newPassword: string;
    token: string;
}

export interface IAuthState {
    authState: AuthState;
    user: IUser | null;
    token: string | null;
    refToken: string | null;
}

const authState = {
    authState: AuthState.UNAUTHORIZED,
    user: null,
    token: null,
    refToken: null,
};

export const authStore = createEntityStore<IAuthState>(authState, {
    name: 'auth',
});