import React from 'react';
import CssClasses from './title.module.scss';
import {Header4} from "../../headers";
import classNames from "classnames";

interface IProps {
    icon: React.ReactNode;
    iconStyle?: React.CSSProperties;
    text: string;
    withDividers?: boolean;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

export function Title(props: IProps) {
    const {
        icon,
        iconStyle,
        text,
        withDividers = false,
        children,
        style = {}
    } = props;

    const klass = classNames({
        [CssClasses.title]: true,
        [CssClasses.title_divers]: withDividers
    })

    return <div style={style} className={klass}>
        <Header4 style={{margin: '0', fontSize: 14}}>
            {text}
        </Header4>
        <div className={CssClasses.title_icon} style={iconStyle}>
            {icon}
        </div>
        {children && <div>
            {children}
        </div>}
    </div>
}
