import { EntityState, ActiveState, StoreConfig, EntityStore } from '@datorama/akita';
import { ITemplate } from '../models/template.interface';

export interface ITemplateState extends EntityState, ActiveState {
    templates: ITemplate[];
    loading: boolean;
    sort: ISort<keyof ITemplate>;
}

export interface ISort<T = string> {
    field: T;
    type: 'asc' | 'desc';
}

const initialState: ITemplateState = {
    templates: [],
    loading: false,
    active: null,
    sort: {
        field: 'name',
        type: 'asc'
    }
}

@StoreConfig({ name: 'templates' })
export class TemplateStore extends EntityStore<ITemplateState> {
    constructor() {
        super(initialState);
    }
}

export const templateStore = new TemplateStore();