import { ChevronLeft } from '@material-ui/icons';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';

import { useObservable } from '@libreact/use-observable';
import { adminSettingsQuery } from 'src/store/admin-settings/admin-settings.query';
import { BlockingLoader } from 'src/components/ui/components/loaders/blocking-loader';
import { TemplateType } from '../../templates-table';
import { adminSettingsService } from 'src/store/admin-settings/admin-settings.service';
import { useTemplate } from 'src/store/admin-settings/hooks/use-template';

import { Dropdown } from 'src/components/ui/components/dropdown/dropdown';
import { ITemplate } from 'src/store/admin-settings/models/template.interface';
import { OwnerIcon, ProspectIcon, TenantIcon, PersonIcon, SaveIcon } from 'src/components/ui/icons';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import moment from 'moment';
import { ContentHolder, headMobileStyles, panelMobileStyles } from 'src/components/ui/main-layout/layout';
// import adminStyles from '../../admin.module.scss';

import styles from './notification-editor.module.scss';
import baseStyles from 'src/styles/base.module.scss';
import { uiQuery } from 'src/store/ui-storage/ui.query';
import { Modal } from 'src/components/ui/components/modal/modal';
import { CustomRecurrence } from './custom-recurrence';
import { IAutoNitificaton, IRecurrence, RecurrenceType } from 'src/store/admin-settings/models/autonotification.interface';

const WEEK_DAYS = 7;
const INIT_FROM = 8;

export const daysOfWeek = new Map();
daysOfWeek.set(1, 'Monday');
daysOfWeek.set(2, 'Tuesday');
daysOfWeek.set(3, 'Wednsday');
daysOfWeek.set(4, 'Thursday');
daysOfWeek.set(5, 'Friday');
daysOfWeek.set(6, 'Saturday');
daysOfWeek.set(0, 'Sunday');

export const daysMap = new Map();
daysMap.set('MON', 'Monday');
daysMap.set('TUE', 'Tuesday');
daysMap.set('WED', 'Wednesday');
daysMap.set('THU', 'Thursday');
daysMap.set('FRI', 'Friday');
daysMap.set('SAT', 'Saturday');
daysMap.set('SUN', 'Sunday');

export const daysShortMap = new Map();
daysShortMap.set('monday', 'MON');
daysShortMap.set('tuesday', 'TUE');
daysShortMap.set('wednesday', 'WED');
daysShortMap.set('thursday', 'THU');
daysShortMap.set('friday', 'FRI');
daysShortMap.set('saturday', 'SAT');
daysShortMap.set('sunday', 'SUN');

export const dayInMonth = new Map();
dayInMonth.set(1, 'First');
dayInMonth.set(2, 'Second');
dayInMonth.set(3, 'Third');
dayInMonth.set(4, 'Fourth');
dayInMonth.set(5, 'Last');

export const recurrenceTypesMap = new Map();

recurrenceTypesMap.set(RecurrenceType.DONT_REPEAT, `Don't repeat`);
recurrenceTypesMap.set(RecurrenceType.EVERY_DAY, 'Every day');
recurrenceTypesMap.set(RecurrenceType.EVERY_WEEK, 'Every week');

interface IFormState {
    date: any;
    recurrenceType: string;
    customType: string;
    recurrence: Partial<IRecurrence>;
    appointmentType: string;
    receivers: string[];
    template: string | number;
    endType?: string;
    recurrenecId?: number;
}

const initialFormState = {
    date: {
        date: moment(),
        timeFrom: moment().set({
            hour: INIT_FROM,
            minutes: 0,
        }).format('hh:mm A'),
        // timeTo: moment().format('hh:mm A'),
    },
    recurrenceType: RecurrenceType.DONT_REPEAT,
    customType: '',
    recurrence: {},
    appointmentType: 'MEETING',
    receivers: [],
    templateId: 0,
    endType: 'never',
};

interface ILocation {
    type: TemplateType;
}

export const NotificationEditor = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams<any>();
    const [open, setOpen] = useState(false);

    const [isNew] = useState(location.pathname.includes('new'));
    const [editMode] = useState((params.id && location.pathname.includes('edit')));
    const [customOption, setCustomOption] = useState<any>();
    
    const [_email, _sms, _loading, allTemplates] = useTemplate();
    const [loading] = useObservable(adminSettingsQuery.loading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    const [selectedAutoNotifications] = useObservable(adminSettingsQuery.selectedAutoNotifications$);

    const [formState, setFormState] = useState<any>(initialFormState);

    useEffect(() => {
        if (selectedAutoNotifications) {
            setFormState(() => ({
                ...initialFormState,
                ...selectedAutoNotifications,
                date: {
                    date: new Date(selectedAutoNotifications?.datetime!),
                    timeFrom: moment(selectedAutoNotifications.datetime).format('hh:mm A'),
                },
                recurrenceType: selectedAutoNotifications.recurrenceId ? RecurrenceType.CUSTOM_OPTION : selectedAutoNotifications.recurrenceType,
            }));
        }

        return () => {
            if (!history.location.pathname.includes('edit')) {
                adminSettingsService.resetCurrentNotification()
            }
        };
    }, [selectedAutoNotifications]);

    useEffect(() => {
        if ((
            !selectedAutoNotifications ||
            selectedAutoNotifications.id !== params.id
        ) && !isNew) {
            adminSettingsService.fetchAutoNotificationById(Number(params.id));
        }
    }, [editMode]);

    useEffect(() => {
        if (!formState.template && allTemplates?.length) {
            setFormState((prevState: any) => ({
                ...prevState,
                templateId: allTemplates[0].id,
            }));
        }
    }, [allTemplates]);

    const isDisabled = useMemo(() => {
        if (formState?.templateId) {
            const [selectedTemplate] = allTemplates.filter(template => template.id === formState.templateId);
            return selectedTemplate?.systemData !== null;
        }
    }, [formState]);


   console.log(allTemplates)
   console.log(formState)
    const prepearedFormState = useMemo(() => {
        if (isDisabled) {
            return {
                ...formState,
                receivers: formState.receivers.filter((receiver: string) => receiver === "TENANTS"),
            };
        }

        return formState;
    }, [isDisabled, formState]);

    const dayNumber = useMemo(() => Math.ceil(moment(formState.date.date).date() / WEEK_DAYS), [formState]);

    const options = useMemo(() => {
        const custom = {
            value: RecurrenceType.CUSTOM,
            label: <div style={{ width: '100%' }} onClick={() => setOpen(true)}>Custom...</div>
        };

        const commonOptions = [
            {
                value: RecurrenceType.DONT_REPEAT,
                content: 'Don’t repeat',
                label: <div>Don’t repeat</div>
            },
            {
                value: RecurrenceType.EVERY_DAY,
                content: 'Every day',
                label: <div>Every day</div>
            },
            {
                value: RecurrenceType.EVERY_WEEK,
                content: `Every Week - ${ daysOfWeek.get(moment(formState.date.date).weekday()) }`,
                label: <div>{ `Every Week - ${ daysOfWeek.get(moment(formState.date.date).weekday()) }` }</div>
            },
            {
                value: RecurrenceType.EVERY_FIRST_WEEKDAY_OF_THE_MONTH,
                content: `Every ${ dayInMonth.get(dayNumber) } ${ daysOfWeek.get(moment(formState.date.date).weekday()) } of the month`,
                label: <div>{ `Every ${ dayInMonth.get(dayNumber) } ${ daysOfWeek.get(moment(formState.date.date).weekday()) } of the month` }</div>
            },
            {
                value: RecurrenceType.ANNUALLY,
                content: `Annually on ${ moment(formState.date.date, 'MMMM').format('MMMM') } ${ moment(formState.date.date).date() }`,
                label: <div>{ `Annually on ${ moment(formState.date.date, 'MMMM').format('MMMM') } ${ moment(formState.date.date).date() }` }</div>
            },
            {
                value: RecurrenceType.EVERY_WORK_DAY,
                content: 'Every work day (From Monday to Friday)',
                label: <div>Every work day (From Monday to Friday)</div>
            },
        ];

        if (customOption) {
            return [
                ...commonOptions,
                {
                    value: RecurrenceType.CUSTOM_OPTION,
                    content: customOption,
                    label: <div>{customOption}</div>
                } as any,
                custom,
            ];
        }

        if (formState.recurrenceType === RecurrenceType.CUSTOM_OPTION) {
            return [
                ...commonOptions,
                {
                    value: RecurrenceType.CUSTOM_OPTION,
                    content: formState.recurrenceName,
                    label: <div>{formState.recurrenceName}</div>
                } as any,
                custom,
            ];
        }

        return [
            ...commonOptions,
            custom,
        ]
    }, [formState, dayNumber, customOption]);

    const handleValuesChange = (data: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    }
   

    const handleSelectChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();

        setFormState((prevState: any) => ({
            ...prevState,
            [field]: e.target.value,
        }));
    }

    //* Save custom recurrence
    const handleSave = async () => {
        const { recurrence } = formState;
        const { endType, ...updatedRecurrence } = recurrence;

        const customOption = `
            Every ${recurrence.frequency} ${recurrence.timePeriod?.toLowerCase()}s
            ${(recurrence.monthlyRepeat && recurrence.timePeriod === 'MONTH') ? ` ${recurrence?.monthlyRepeat}` : ``}
            ${(recurrence.weekDays && recurrence.weekDays.length === 7 && recurrence.timePeriod === 'WEEK') 
                ?  'on all days'
                : (recurrence.weekDays && recurrence.timePeriod === 'WEEK')
                    ? `${recurrence.weekDays.map((day: string, index: number) => {
                        if (
                            index === (recurrence.weekDays?.length! - 1) ||
                            recurrence.weekDays?.length === 1
                        ) {
                            return  `${daysMap.get(day)}`;
                        }
                        
                        return  `${daysMap.get(day)} `;
                    })}`
                    : ''
            }
            ${recurrence.endsDate ? `, until ${moment(recurrence.endsDate).format('MMM DD, YYYY')}` : ``}
            ${recurrence.endsAfterOccurrence ? `, ${recurrence.endsAfterOccurrence} times` : ``}
        `;

        setCustomOption(customOption);

        const recurrenecId = await adminSettingsService.addRecurrence({
            ...updatedRecurrence,
            monthlyRepeat: updatedRecurrence.monthlyRepeat?.includes('on day') ? 'ON_DAY' : 'ON_WEEKDAY',
            weekDays: recurrence.weekDays,
        });

        setOpen(false);
        setFormState((prevState: any) => ({
            ...prevState,
            recurrenceType: RecurrenceType.CUSTOM_OPTION,
            recurrenecId: recurrenecId as number,
        }));
    }

    //* --------------------------------------------------------

    const handleClose = () => {
        setOpen(false);
        setFormState((prevState: any) => ({
            ...prevState,
            recurrenceType: prevState.recurrenceId ? RecurrenceType.CUSTOM_OPTION : RecurrenceType.DONT_REPEAT,
        }));
    }

    const handleRecurrenceChange = (recurrence: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            recurrence: {
                ...prevState.recurrence,
                ...recurrence,
            },
        }));
    }

    //* Save notification
    const handleSaveNotification = () => {
        const { recurrenceType, endType, appointmentType, customType, templateId, date, recurrence, recurrenecId, ...updatedFormState } = formState;
        const [recurrenceName] = options.filter(option => option.value === recurrenceType);

        const notification: IAutoNitificaton = {
            ...updatedFormState,
            datetime: moment(date.date).set({
                date: moment(date.date).date(),
                hour: moment(date.timeFrom, 'hh:mm A').hour(),
                minute: moment(date.timeFrom, 'hh:mm A').minute(),
            }).toISOString() as unknown as Date,
            appointmentType: appointmentType.toUpperCase(),
            receivers: formState.receivers.map((reciver: string) => reciver.toUpperCase()),
            recurrenceId: recurrenecId as number,
            recurrenceType: recurrenceType === RecurrenceType.CUSTOM_OPTION ? null : recurrenceType,
            recurrenceName: recurrenceName.content,
            templateId: templateId as number,
        };

        adminSettingsService.addAutoNotification(notification);
        adminSettingsService.resetCurrentNotification();

        history.push('/admin/settings?tab=auto_notifications');
    }

    //* --------------------------------------

    const handleBlur = (field: string) => (e: ChangeEvent<any>) => {
        if (!e.target.value) {
            setFormState((prevState: any) => ({
                ...prevState,
                date: { 
                    ...prevState.date,
                    [field]: moment().set({
                        hour: INIT_FROM,
                        minutes: 0,
                    }).format('hh:mm A')
                }
            }));
        }
    }

    if (editMode && loading) {
        return <BlockingLoader />
    }
    
    return <ContentHolder noPadding smallPadding>
        <div className={baseStyles.link_wrapper} onClick={() => history.push('/admin/settings?tab=auto_notifications')}>
            <ChevronLeft /> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
        </div>
        <Panel additionalStyles={
            isMobile ? headMobileStyles : { marginTop: 7 }
        } type={PanelType.EMPTY}>
            <div className={styles.top_wrapper}>
                <Header3 style={{margin: '10px 0'}}>
                    { editMode ? 'Edit Notification' : 'Create Notification' }
                </Header3>
                {
                    !isMobile
                        ? (
                            <div className={styles.actionBarHolder}>
                                <Button
                                    icon={<SaveIcon />}
                                    btnVariant={ButtonVariant.PRIMARY}
                                    onClick={handleSaveNotification}
                                    text="Save"
                                />
                            </div>
                        )
                        : null
                }
            </div>
        </Panel>
        <Panel additionalStyles={
            isMobile ? panelMobileStyles : { marginTop: 7 }
        }>
            <FormHolder>
                <Form
                    type={FormType.DEFAULT}
                    onChange={handleValuesChange}
                    data={prepearedFormState}
                    onBlur={handleBlur}
                    fields={{
                        date: {
                            index: 0,
                            label: 'Date',
                            labelDisplay: 'block',
                            height: isMobile ? '91px' : 'auto',
                            wrapperStyles: {
                                flexDirection: 'row',
                                margin: '0 0 10px',
                            },
                            validate: true,
                            fieldType: InputType.DATE_RANGE,
                            // placeholder: 'Friday, August 7th',
                            // groupStyles: {
                            //     margin: '-10px 0 0 -48px',
                            // },
                        },
                        recurrenceType: {
                            index: 1,
                            label: 'Recurrence',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            height: 'auto',
                            fieldType: InputType.DROPDOWN,
                            selectableItems: options,
                        },
                        // appointmentType: {
                        //     index: 2,
                        //     label: 'Event Type',
                        //     labelDisplay: 'block',
                        //     wrapperStyles: {
                        //         flexDirection: 'row',
                        //     },
                        //     height: 'auto',
                        //     fieldType: InputType.DROPDOWN,
                        //     selectableItems: [
                        //         {
                        //             value: 'MEETING',
                        //             label: <div>Meetingt</div>,
                        //         },
                        //         {
                        //             value: 'SHOWING',
                        //             label: <div>Showing</div>,
                        //         },
                        //         {
                        //             value: 'OTHER',
                        //             label: <div>Other</div>,
                        //         },
                        //     ]
                        // },
                        receivers: {
                            index: 3,
                            label: 'Receiver',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                                margin: '0 0 14px',
                            },
                            height: 'auto',
                            fieldType: InputType.SELECT,
                            groupStyles: {
                                margin: isMobile ? '0 0 0 20px' : '0 0 0 10px',
                            },
                            selectableItems: [
                                {
                                    label:  (
                                        <div className={styles.checboxItem}>
                                            <PersonIcon />
                                            <span>Agents</span>
                                        </div>
                                    ) as unknown as string,
                                    value: "AGENTS",
                                    disabled: isDisabled,
                                },
                                {
                                    label:  (
                                        <div className={styles.checboxItem}>
                                            <TenantIcon />
                                            <span>Tenants</span>
                                        </div>
                                    ) as unknown as string,
                                    value: "TENANTS",
                                },
                                {
                                    label:  (
                                        <div className={styles.checboxItem}>
                                            <OwnerIcon />
                                            <span>Owners</span>
                                        </div>
                                    ) as unknown as string,
                                    value: "OWNERS",
                                    disabled: isDisabled,
                                },
                                {
                                    label:  (
                                        <div className={styles.checboxItem}>
                                            <ProspectIcon />
                                            <span>Prospects</span>
                                        </div>
                                    ) as unknown as string,
                                    value: "PROSPECTS",
                                    disabled: isDisabled,
                                }
                            ]
                        },
                        templateId: {
                            index: 4,
                            label: 'Template',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            // groupStyles: {
                            //     margin: '-22px 0 0 -48px',
                            // },
                            customElement: <Dropdown
                                onChange={handleSelectChange('templateId')}
                                value={formState.templateId}
                                menuItems={allTemplates?.map((template: ITemplate) => ({
                                        value: template.id,
                                        content: <div>{template.name}</div>
                                    })
                                )} />
                        },
                    }}
                />
            </FormHolder>
        </Panel>
        {
            isMobile
                ? (
                    <Panel type={PanelType.EMPTY}>
                        <div className={baseStyles.save_bar_mobile}>
                            <Button
                                icon={<SaveIcon />}
                                btnVariant={ButtonVariant.PRIMARY}
                                onClick={handleSaveNotification}
                                text="Save"
                            />
                        </div>
                    </Panel>
                ) : null
        }
        <Modal
            open={open}
            title="Custom Recurrence"
            wrapperStyles={{ padding: '37px' }}
            content={<CustomRecurrence 
                    dayNumber={dayNumber}
                    onChange={handleRecurrenceChange}
                    initialState={formState.recurrence}
                    selectedDate={formState.date} />}
            onClose={handleClose}
            onSave={handleSave} />
    </ContentHolder>
}