import {IDataStorage} from "../data-storage.interface";
import {IStoredData} from "../stored-data.interface";

export class LocalStorageStrategy implements IDataStorage<IStoredData> {
    clear(): this {
        localStorage.clear();
        return this;
    }

    getItem(key: keyof IStoredData): Partial<IStoredData> | null {
        const data = localStorage.getItem(key);
        if(data) {
            return JSON.parse(data) as Partial<IStoredData>;
        }
        return null;
    }

    removeItem(key: keyof IStoredData): this {
        localStorage.removeItem(key);
        return this;
    }

    setItem(key: keyof IStoredData, value: Partial<IStoredData>): this {
        localStorage.setItem(key, JSON.stringify(value));
        return this;
    }

}
