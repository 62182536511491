import {IDataStorage} from "./data-storage.interface";
import {IStoredData} from "./stored-data.interface";
import {LocalStorageStrategy} from "./strategies/local-storage.strategy";

export class DataStorageContext {
    private strategy: IDataStorage<IStoredData>;

    constructor(strategy: IDataStorage<IStoredData>) {
        this.strategy = strategy;
    }

    public setStrategy(strategy: IDataStorage<IStoredData>): IDataStorage<IStoredData>{
        this.strategy = strategy;
        return this.strategy;
    }

    public getStrategy() {
        return this.strategy;
    }
}


// use local storage as strategy by default.
export const defaultDataStorage = new DataStorageContext(new LocalStorageStrategy());
