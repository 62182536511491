import { useObservable } from "@libreact/use-observable";
import { autocompleteQuery } from '../autocomplete.query';
import { SearchType } from "../models/autocomplete.interface";

export type SearchDataHookTuple = [{
    [k: string]: {
        searchList: any[];
        searchTotal: number;
        searchType: SearchType;
    }
}, {
    [k: string]: {
        loading: boolean;
    }
}];

export function useSearchData (): SearchDataHookTuple {
    const [searchData] = useObservable(autocompleteQuery.searchData$);
    const [searchStatus] = useObservable(autocompleteQuery.searchStatus$);

    return [searchData, searchStatus];
}