import React, { Fragment, Suspense } from 'react';
import { useObservable } from '@libreact/use-observable';
import { MainLayout } from "../ui/main-layout/layout";
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Properties } from "../containers/properties";
import { AuthState } from "../../store/user/models/auth-state.enum";
import { RoutesEnum } from "./routes";
import { TenantsContainer } from "../containers/tenants";
import { Owners } from "../containers/owners";
import { PropspectsContainer } from '../containers/prospects';
import { Calendars } from "../containers/calendars";
import { StaffContainer } from '../containers/staff';
import { SendMessage } from '../containers/send-message/send-message';
import { AdminSettingsContainer } from '../containers/admin';
import { DashboardContainer } from '../containers/dashboard';
import { authQuery } from 'src/store/auth/auth.query';
import { BlockingLoader } from '../ui/components/loaders/blocking-loader';
// import { NoticeOfRentIncrease } from '../helpers/n1-form-gen/notice-of-rent-increase';

const NoticeOfRentIncrease = React.lazy(() => import('../helpers/n1-form-gen/notice-of-rent-increase'));
const NoticeToEndTenancy = React.lazy(() => import('../helpers/n9-form-gen/notice-to-end-tenancy'));

export function AuthorizedRoutes() {
    const location = useLocation();
    const [authStatus] = useObservable(authQuery.selectAuthState$);

    if (authStatus === AuthState.UNAUTHORIZED) {
        return <Redirect to={RoutesEnum.SIGN_IN}/>
    }

    if (location.pathname.includes('notice-of-rent-increase')) {
        return <Switch location={location}>
            <Suspense fallback={<BlockingLoader />}>
                <Route exact path="/notice-of-rent-increase/:id/:unitId/:unitName" render={() => {
                    return <NoticeOfRentIncrease />
                }} />
            </Suspense>
        </Switch>
    }

    if (location.pathname.includes('notice-to-end-tenancy')) {
        return <Switch location={location}>
            <Suspense fallback={<BlockingLoader />}>
                <Route exact path="/notice-to-end-tenancy/:id/:unitId/:signer/:unitName" render={() => {
                    return <NoticeToEndTenancy />
                }} />
            </Suspense>
        </Switch>
    }

    return (
        <MainLayout>
            <Route render={({location}) => {
                return <Fragment>
                    <Switch location={location}>
                        {/* <Route path={RoutesEnum.REPORTS} component={DashboardContainer} key="dashboard"/>
                        <Route path={RoutesEnum.DOC_LIBRARY} component={DashboardContainer} key="dashboard"/>
                        <Route path={RoutesEnum.LEASE_CONTRACTS} component={DashboardContainer} key="dashboard"/>
                        <Route path={RoutesEnum.IMPORT_STATUS} component={DashboardContainer} key="dashboard"/>
                        <Route path={RoutesEnum.LEASE_REQUESTS} component={DashboardContainer} key="dashboard"/> */}

                        <Route path={RoutesEnum.PROPERTIES_LIST} component={Properties} key="properties"/>
                        <Route path={RoutesEnum.TENANTS_LIST} component={TenantsContainer} key="tenants"/>
                        <Route path={RoutesEnum.PROSPECTS_LIST} component={PropspectsContainer} key="prospects"/>
                        <Route path={RoutesEnum.STAFF_LIST} component={StaffContainer} key="staff"/>
                        <Route path={RoutesEnum.OWNERS_LIST} component={Owners} key="owners" />
                        <Route path={RoutesEnum.APPOINTMENTS_LIST} component={Calendars} key="calendars" />
                        <Route path={RoutesEnum.SEND_MESSAGE} component={SendMessage} key="message" />
                        <Route path={RoutesEnum.ADMIN_SETTINGS} component={AdminSettingsContainer} key="settings" />
                        <Route path={RoutesEnum.CALENDAR} component={Calendars} key="calendars" />
                    </Switch>
                </Fragment>
            }}/>
        </MainLayout>
    )
}
