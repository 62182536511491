import React, {useCallback, useState} from 'react';
import CssClasses from './sign-in.module.scss';
import {Input} from "../../../ui/components/forms/inputs/input";
import {Header4, Header6} from "../../../ui/components/headers";
import {Button, ButtonVariant} from "../../../ui/components/buttons/button";
import {InputLabel} from "../../../ui/components/forms/labels/label";
import {Checkbox} from "../../../ui/components/forms/inputs/checkbox";
import {Link, RouteComponentProps} from "react-router-dom";
import {RoutesEnum} from "../../../routing/routes";
import {ParseQueryParams} from "../../../helpers/query-params-parser";
import { authService } from 'src/store/auth/auth.service';
import { ISigninDto } from 'src/store/auth/auth.store';

interface ISearchParams {
    status: 'password_reset_successfully' | undefined;
}

interface IProps extends RouteComponentProps {

}

export function SignIn(props: IProps) {

    const [rememberMe, setRememberMe] = useState(true);
    const [signInError, setSignInError] = useState<string>();
    const [loginDto, setLoginDto] = useState<ISigninDto>({
        email: '',
        password: ''
    });

    const searchParams: ISearchParams = ParseQueryParams<ISearchParams>(props.history.location.search);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginDto({
            ...loginDto,
            [e.target.id]: e.target.value
        })
    }

    const onSubmit = useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            await authService.signIn(loginDto, rememberMe);
        } catch (err) {
            if(err.message) {
                setSignInError(err.message)
            } else {
                setSignInError('Error occurred during the request. Try again.')
            }
        }
    }, [loginDto, rememberMe, setSignInError]);

    return <div className={CssClasses.sign_in}>
        <Header4 style={{textAlign: 'center'}}>
            {
                (searchParams.status && searchParams.status === 'password_reset_successfully')
                    ? 'You password has been changed successfully'
                    : 'Welcome Back'
            }
        </Header4>
        <Header6 style={{fontWeight: 300}}>
            Log In To Continue
        </Header6>
        <form onSubmit={onSubmit} className={CssClasses.sign_in_form}>
            <div className={CssClasses.formItem}>
                <InputLabel style={{display: 'block', width: '100%', margin: '0 0 4px'}} htmlFor="email">
                    Login
                </InputLabel>
                <Input valid={!signInError} onChange={onChange} id="email" placeholder="fancy_email@mail.com" style={{width: '100%'}} />
            </div>
            <InputLabel style={{width: '100%'}} htmlFor="password">
                Password
            </InputLabel>
            <Input valid={!signInError} onChange={onChange} id="password" type="password" placeholder="****" style={{width: '100%'}} />
            <div className={CssClasses.sign_in_checkbox_wrapper}>
                <Checkbox onChange={() => setRememberMe(!rememberMe)} checked={rememberMe} label="Remember me?" />
                <div className={CssClasses.sign_in_forgot_password}>
                    <Link to={RoutesEnum.FORGOT_PASSWORD}>
                        Forgot Password?
                    </Link>
                </div>
            </div>
            <Button style={{width: 120, marginTop: 20}} btnVariant={ButtonVariant.PRIMARY} text="Login" />
        </form>
    </div>
}