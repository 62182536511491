import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { UnauthorizedRoutes } from "./unauthorized-routes";
import { AuthorizedRoutes } from "./authorized-routes";
import { useObservable } from "@libreact/use-observable/dist";
// import {userQuery} from "../../store/user/user.query";
import { AuthState } from "../../store/user/models/auth-state.enum";
// import {userService} from "../../store/user/user.service";
import { BlockingLoader } from "../ui/components/loaders/blocking-loader";
import { Snackbars } from "../ui/components/snackbars/snackbars";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { uiService } from "../../store/ui-storage/ui.service";
import { authQuery } from 'src/store/auth/auth.query';
import { authService } from 'src/store/auth/auth.service';
import { defaultDataStorage } from 'src/store/data-storage/context';
import { FetcherClientProvider } from '../fetcher/fetcher-client';
import { useOnlineStatus } from 'src/components/fetcher/hooks/use-online-status';
import { ErrorPage } from '../helpers/error-page';


export function Routing() {
    const isOnline = useOnlineStatus();
    const [authStatus] = useObservable(authQuery.selectAuthState$, AuthState.UNKNOWN);
    // const [user] = useObservable(authQuery.selectUser$);

    useEffect(() => {
        const token = defaultDataStorage.getStrategy().getItem('token');
        const refToken = defaultDataStorage.getStrategy().getItem('refToken');
    
        if (token || refToken) {
            authService.getUser();
            uiService.init();
        }
    }, []);

    if (authStatus === AuthState.FETCHING || authStatus === AuthState.UNKNOWN) {
        return <BlockingLoader open={true}/>
    }

    if (!isOnline) {
        return <ErrorPage />;
    }

    return (
        <FetcherClientProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/auth" component={UnauthorizedRoutes}/>
                        <Route path="" component={AuthorizedRoutes}/>
                    </Switch>
                    <Snackbars/>
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </FetcherClientProvider>
    );
}