import React from 'react'
import styled from 'styled-components';
import { Panel } from '../ui/components/panel'
import { CogIcon } from '../ui/icons';

const LoaderHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    width: 100%;
    height: 100%;
    font-size: 18px;

    @keyframes rotate {
        from { transform: rotate( 0deg ); }
        to   { transform: rotate( 360deg ); }
    }

    div {
        margin: 0 8px 0 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        animation: rotate 2s infinite linear;
    }

    div > svg {
        transform: scale(3);
    }
`;

export const TabLoader  = () => (
    <Panel additionalStyles={{ margin: '-6px 0 0', borderRadius: '0px 0px 10px 10px' }}>
        <LoaderHolder>
            <div>
                <CogIcon />
            </div>
            Loading...
        </LoaderHolder>
    </Panel>
)

export const PanelLoader = () => (
    <Panel additionalStyles={{ margin: '-6px 0 0', borderRadius: '0px 0px 10px 10px' }}>
        <LoaderHolder>
            <div>
                <CogIcon />
            </div>
            Loading...
        </LoaderHolder>
    </Panel>
)
