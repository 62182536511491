import { useObservable } from "@libreact/use-observable";
import { get } from "lodash";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ILocationState, PagingType } from "src/store/common/models/common.interface";
import { IStaff } from "../models/staff.inreface";
import { staff$, isLoading$ } from "../staff.query";
import { staffService } from "../staff.service";
import { START_PAGE, ROWS_PER_PAGE } from 'src/store/common/hooks/use-paging';

export type StaffHookTuple = [IStaff[], boolean];

export function useStaff (): StaffHookTuple {
    const location = useLocation<ILocationState>();
    const [staff] = useObservable(staff$);
    const [loading] = useObservable(isLoading$);

    useEffect(() => {
        if (!staff.length) {
            staffService.fetchStaff();
        } else {
            staffService.fetchStaff(
                get(location, `state[${PagingType.STAFF}].activePage`, START_PAGE),
                get(location, `state[${PagingType.STAFF}].rowsPerPage`, ROWS_PER_PAGE),
            );
        }
    }, [location.state]);

    return [staff, loading];
}