import React, { Dispatch, Fragment, SetStateAction } from "react";
import { Settings } from "@material-ui/icons";
import { Button } from "src/components/ui/components/buttons/button";
import { ButtonVariant } from "src/components/ui/components/buttons/button";
import { FiltersList, ChipViewTypes } from "src/components/ui/components/filters-list";
import { Form, FormType, FormFieldDividerType, InputType } from "src/components/ui/components/forms/formBuilder/form";
import { Sidenav } from "src/components/ui/components/sidenav";
import { LeadStatus } from "src/store/prospects/models/prospect.interface";
import { IProspectSearchCriteria } from "src/store/prospects/prospects-search/prospect-search.store";
// import { NotificationType } from "src/store/prospects/prospects-search/prospect-search.store";
import { INamedEntity, ISelectableItem } from "src/store/properties/models/named-entity.interface";
import { KeyedNamedEntity } from "src/store/properties/search/search-properties.query";
import { List } from "src/components/ui/components/list/list";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { useObservable } from "@libreact/use-observable";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { SearchableList } from "src/components/ui/components/searchable-list/searchable-list";
import { SearchType } from "src/store/autocomplete/models/autocomplete.interface";
import { searchUrlMap } from "src/store/autocomplete/autocomplete.store";

const FOR_FOLLOW_UP = 'For Follow Up';
const LOST = 'Lost';
const APPLICANT = 'Applicant';

interface IProspectsFilterProps {
    removeAllSelection: () => void;
    isFilterOpened: boolean;
    searchCriteria: IProspectSearchCriteria;
    onApply: () => void;
    onClose: () => void;
    allSelectedFilters: INamedEntity[];
    onFilterDelete: (k: boolean) => ((id: KeyedNamedEntity<keyof IProspectSearchCriteria>) => void) | undefined;
    setFilterQueries: Dispatch<SetStateAction<{ 
        attachedAgentQ: string;
        cityQ: string;
        // naeighborhoodsQ: string;
    }>>
    filterQueries: { 
        attachedAgentQ: string;
        cityQ: string;
        // naeighborhoodsQ: string;
    };
    changeCriteria: (criteriaName: keyof IProspectSearchCriteria) => (item: INamedEntity) => void;
    // attachedAgentOptionsFiltered: ISelectableItem[];
    // citiesFiltered?: ISelectableItem[];
    // neighborhoodsFiltered?: ISelectableItem[];
    notificationsFiltered?: ISelectableItem[];
    cssClasses: {
        [k: string]: string;
    },
    onFiltersCange: (data: IProspectSearchCriteria) => void;
}

export const ProspectsFilter: React.FC<IProspectsFilterProps> = ({
    removeAllSelection,
    isFilterOpened,
    searchCriteria,
    onApply,
    onClose,
    allSelectedFilters,
    onFilterDelete,
    setFilterQueries,
    filterQueries,
    changeCriteria,
    // attachedAgentOptionsFiltered,
    // citiesFiltered,
    // neighborhoodsFiltered,
    notificationsFiltered,
    cssClasses,
    onFiltersCange,
}) => {
    const [isMobile] = useObservable(uiQuery.isMobile$);

    // const handleChange = (data: IProspectSearchCriteria) => {
    //     searchProspectsService.updateSelectionCriteria(data);
    // }

    // console.log('allSelectedFilters -> ', allSelectedFilters);

    return (
        <Sidenav<IProspectSearchCriteria> 
            header={
                <Fragment>
                    <Settings />
                    <span>
                        Filters
                    </span>
                </Fragment>
            } 
            footer={
                <Fragment>
                    <Button btnVariant={ButtonVariant.OUTLINE} onClick={removeAllSelection} text="Clear All" />
                    <Button style={{
                        marginLeft: 12
                    }} 
                    onClick={onApply}
                    btnVariant={ButtonVariant.PRIMARY} text="Apply" />
                </Fragment>
            } 
            width={isMobile
                ? '100%'
                : 542
            }
            isOpen={isFilterOpened}
            // cachedData={searchCriteria}
            onClose={onClose}>
            <Fragment>
                <div className={cssClasses.filters_wrapper}>
                    <FiltersList 
                        filtersTitleName="Choose From The List Below" 
                        type={ChipViewTypes.BLOCK} 
                        items={allSelectedFilters} 
                        onDelete={onFilterDelete(false)} />
                </div>
                <FormHolder>
                    <Form<IProspectSearchCriteria> 
                        type={FormType.DEFAULT}
                        data={searchCriteria}
                        wrapperStyles={{
                            margin: '0 0 0 20px'
                        }}
                        onChange={onFiltersCange} 
                        fields={{
                            agentsAttached: {
                                index: 0,
                                label: 'Agents Attached',
                                labelDisplay: 'block',
                                labelStyles: {
                                    padding: 0,
                                },
                                height: 'auto',
                                withDividers: FormFieldDividerType.BOTTOM,
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: <Fragment>
                                    <SearchableList 
                                        id="agents"
                                        url={searchUrlMap.get(SearchType.AGENTS)}
                                        searchType={SearchType.AGENTS}
                                        selectedCriteria={searchCriteria.agentsAttached}
                                        onSearchChange={(q: string) => 
                                            setFilterQueries({ 
                                                ...filterQueries,
                                                attachedAgentQ: q
                                        })} 
                                        onSelect={changeCriteria('agentsAttached')}
                                    />
                                </Fragment>
                            },
                            leadStatus: {
                                index: 1,
                                label: 'Lead Status',
                                labelDisplay: 'block',
                                withDividers: FormFieldDividerType.BOTTOM,
                                fieldType: InputType.SELECT,
                                height: 'auto',
                                labelStyles: {
                                    padding: 0,
                                },
                                groupStyles: {
                                    margin: isMobile ? '0 0 8px 32px' : '0 0 8px 54px',
                                },
                                selectableItems: [{
                                    label: FOR_FOLLOW_UP,
                                    value: LeadStatus.FOR_FOLLOW_UP
                                }, {
                                    label: LOST,
                                    value: LeadStatus.LOST
                                }, {
                                    label: APPLICANT,
                                    value: LeadStatus.APPLICANT
                                }]
                            },
                            approximateBudget: {
                                index: 2,
                                label: 'Approximate budget',
                                labelDisplay: 'block',
                                labelStyles: {
                                    padding: 0,
                                },
                                height: 'auto',
                                withDividers: FormFieldDividerType.BOTTOM,
                                fieldType: InputType.NUMBER_BETWEEN,
                            },
                            bedrN: {
                                index: 3,
                                label: 'N. of Bedrooms',
                                labelDisplay: 'block',
                                labelStyles: {
                                    padding: 0,
                                },
                                height: 'auto',
                                withDividers: FormFieldDividerType.BOTTOM,
                                fieldType: InputType.NUMBER_BETWEEN
                            },
                            city: {
                                index: 4,
                                label: 'City',
                                labelDisplay: 'block',
                                labelStyles: {
                                    padding: 0,
                                },
                                groupStyles: {
                                    margin: '0 0 14px',
                                },
                                height: 'auto',
                                withDividers: FormFieldDividerType.BOTTOM,
                                fieldType: InputType.CUSTOM_ELEMENT,
                                customElement: 
                                    <Fragment>
                                        <SearchableList
                                            id="city"
                                            url={searchUrlMap.get(SearchType.CITIES)}
                                            searchType={SearchType.CITIES}
                                            selectedCriteria={searchCriteria.city}
                                            onSearchChange={(q: string) => 
                                                setFilterQueries({ 
                                                    ...filterQueries,
                                                    cityQ: q
                                            })} 
                                            onSelect={changeCriteria('city')} 
                                        />
                                    </Fragment>
                            },
                            notificationMethod: {
                                index: 6,
                                label: 'Notification Method',
                                labelDisplay: 'block',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                height: 'auto',
                                labelStyles: {
                                    padding: 0,
                                },
                                groupStyles: {
                                    margin: '0 0 0 0px',
                                    alignItems: 'flex-start',
                                },
                                customElement: <Fragment>
                                    <List 
                                        onSelect={changeCriteria('notificationMethod')} 
                                        options={notificationsFiltered} />
                                </Fragment>
                            }
                    }} />
                </FormHolder>
            </Fragment>
        </Sidenav>
    );
}