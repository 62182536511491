import { useEffect, useState } from "react";
import { onlineManager$ } from "src/components/fetcher/online-manager";

export function useOnlineStatus(): boolean {
    const [isOnline, setOnlineStatus] = useState<boolean>(true);

    useEffect(() => {
        const onlineSub = onlineManager$.subscribe(online => setOnlineStatus(() => online))

        return () => onlineSub.unsubscribe();
    }, [])

    return isOnline;
}