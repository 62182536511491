
import React, { Fragment, SyntheticEvent } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Close, ExpandMore } from '@material-ui/icons';
import styled from 'styled-components';

const StylesTypography = withStyles((theme: Theme) => ({
    root: {
        fontSize: '14px',
        fontFamily: 'ManropeThin',
        color: '#737E98',

        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
        }
    },
}))(Typography);

const Accordion = withStyles({
    root: {
        border: 'none',
        backgroundColor: 'rgba(246,248,254, .5)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            backgroundColor: '#fff',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: 'rgba(246,248,254, .5)',
        borderBottom: 'none',
        marginBottom: -1,
        minHeight: 56,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: '68px',
        '&$expanded': {
            minHeight: 56,
            backgroundColor: '#fff',
        },

        [theme.breakpoints.down('sm')]: {
            paddingLeft: '26px',
            paddingRight: 0,
            fontSize: '12px',
        }
    },
    expandIcon: {
        right: '80px',
        opacity: '.5',
        transition: 'opacity .3s',
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {
        '&$expandIcon': {
            opacity: '1',
        }
    },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
            padding: '0 0 0 26px',
        },
    },
}))(MuiAccordionDetails);

const IconHolder = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #BEC4D3;
    color: #fff;
`;

const Group = styled.span`
    display: inline-block;
    margin: 0 0 0 2px;
    color: #737E98;
    font-family: 'ManropeThin';
`;

export const RemoveButton = styled.div`
    position: absolute;
    top: 46%;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px !important;
    height: 20px;
    opacity: 0.7;
    border-radius: 50%;
    background-color: #789EFF;
    color: #fff;
    cursor: pointer;
    margin-top: -7px;
    text-align: center;

    svg {
        font-size: 16px;
    }

    &:hover {
        opacity: 1;
    }
`;

interface ICustomAccordion<T> {
    title?: string;
    data: any;
    renderItem?: (item: T, index: number) => React.ReactNode;
    renderData?: () => React.ReactNode;
    onRemove?: (id: number) => void;
}

export const CustomAccordion = <T extends {}>({ title, data, renderItem, renderData, onRemove }: ICustomAccordion<T>) => {
    
    const handleRemove = (e: SyntheticEvent) => {
        e.stopPropagation();

        if (typeof onRemove === 'function') {
            onRemove(data.id)
        }
    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<IconHolder><ExpandMore /></IconHolder>}
                aria-controls="panel-content"
                id="panel-header">
                <StylesTypography>
                    { title ? title : (
                        <Fragment>
                            {data.property}<Group>(Group)</Group>
                        </Fragment>
                    ) }
                </StylesTypography>
                {
                    typeof onRemove === 'function'
                        ? (
                            <RemoveButton onClick={handleRemove}>
                                <Close />
                            </RemoveButton>
                        ) : null
                }
            </AccordionSummary>
            {
                typeof renderItem === 'function'
                    ? (
                        <AccordionDetails>
                            {data.emails?.map((email: any, index: number) => renderItem(email, index))}
                        </AccordionDetails>
                    ) : null
            }
            {typeof renderData === 'function' 
                ? (
                    <AccordionDetails>
                        {renderData()}
                    </AccordionDetails>
                )  : null}
        </Accordion>
    )
}