import React from "react";
import {AuthLayout} from "../ui/auth-layout";
import {Route, Redirect} from 'react-router-dom';
import {Auth} from "../containers/auth";
import {RoutesEnum} from "./routes";
import {useObservable} from "@libreact/use-observable";
// import {userQuery} from "../../store/user/user.query";
import {AuthState} from "../../store/user/models/auth-state.enum";
import { authQuery } from "src/store/auth/auth.query";

export function UnauthorizedRoutes() {
    const [authStatus] = useObservable(authQuery.selectAuthState$);

    if(authStatus === AuthState.SIGNED_IN) {
        // return <Redirect to={RoutesEnum.LEASE_CONTRACTS} />
        return <Redirect to={RoutesEnum.CALENDAR_VIEW} />
    }
    return <AuthLayout>
        <Route path={RoutesEnum.BASE_AUTH_PATH} component={Auth} />
    </AuthLayout>
}
