import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { refreshToken } from "src/store/auth/auth.service";
import { defaultDataStorage } from "src/store/data-storage/context";
import { snackbarService } from "src/store/snackbar/snackbar.service";
import { SnackbarType } from "src/store/snackbar/snackbar.store";
import { AuthState } from "src/store/user/models/auth-state.enum";

export interface ApiResponse<T> extends AxiosResponse<T> {
    ok: boolean;
}

export interface PagableResponse<T> {
    result: T;
    total: number;
}

const SNACK_TIMEOUT = 5000;
// const STATUS_OK = 200;
const BAD_REQUEST = 400;
const UNAUTHORIZED = 401;
const SERVER_ERROR = 500;

const baseConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_BASE_URL,
    // timeout: 10000,
    // headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/json',
    // },
    // validateStatus: function (status) {
    //     return status >= STATUS_OK;
    // },
};

export const erApi = axios.create(baseConfig);

erApi.interceptors.request.use(
    async config => {
        const token = defaultDataStorage.getStrategy()
                        .getItem('token');

        if (config.auth) {
            return {
                ...config,
                data: {
                    email: config.auth.username,
                    password: config.auth.password,
                },
            };
        }

        if (token) {
            config.headers = { 
                'Authorization': `bearer ${token}`,
                // 'Accept': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        return config;
    },
    error => Promise.reject(error)
);

erApi.interceptors.response.use(response => {
    return {
        ok: true,
        ...response,
    };
}, async error => {
    const originalRequest = error.config;

    if (error.response?.status === UNAUTHORIZED && !originalRequest._retry) {
        originalRequest._retry = true;
        
        const access_token = await refreshToken();   

        return erApi({
            ...originalRequest,
            headers: {
                'Authorization': `bearer ${access_token}`,
            },
        });
    }

    if (error.response?.status === UNAUTHORIZED) {
        snackbarService.createSnackbar({
            text: `Error: ${AuthState.UNAUTHORIZED}`,
            type: SnackbarType.ERROR,
        }, SNACK_TIMEOUT);
    }

    if (error.response?.status >= BAD_REQUEST && error.response?.status <= SERVER_ERROR) {
        snackbarService.createSnackbar({
            text: `Error: ${error.response.data.message || error.response.data.error}`,
            type: SnackbarType.ERROR,
        }, SNACK_TIMEOUT);

        return {
            ok: false,
            ...error.response.data,
        };
    }

    if (!error.response) {
        snackbarService.createSnackbar({
            text: `Error: CORS`,
            type: SnackbarType.ERROR,
        }, SNACK_TIMEOUT);
    }

    return error.response;
});

export function get<T>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return erApi.get<T, ApiResponse<T>>(url, config);
}

export function post<ResponseDto = any, RequestDto = any>(url: string, data?: RequestDto, config?: AxiosRequestConfig): Promise<ApiResponse<ResponseDto>> {
    return erApi.post<RequestDto, ApiResponse<ResponseDto>>(url, data, config);
}

export function patch<ResponseDto = any, RequestDto = any>(url: string, data?: RequestDto, config?: AxiosRequestConfig): Promise<ApiResponse<ResponseDto>> {
    return erApi.patch<RequestDto, ApiResponse<ResponseDto>>(url, data, config);
}

export function put<ResponseDto = any, RequestDto = any>(url: string, data?: RequestDto, config?: AxiosRequestConfig): Promise<ApiResponse<ResponseDto>> {
    return erApi.put<RequestDto, ApiResponse<ResponseDto>>(url, data, config);
}

export function del<T = any>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return erApi.delete(url, config);
}

export async function uploadPhoto(formData: FormData): Promise<ApiResponse<string>> {
    return erApi.post('/public/files/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export function getBlob(url: string, params: any = {}, method: "GET" | "POST" = "POST"): Promise<AxiosResponse<Blob>> {
    return erApi({
        url,
        method,
        responseType: 'blob',
        data: params,
    });
}

export const api = {
    ...erApi,
    get,
    post,
    patch,
    put,
    del,
    uploadPhoto,
    getBlob,
};