import { useObservable } from "@libreact/use-observable";
// import { get } from "lodash";
import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { ROWS_PER_PAGE, START_PAGE } from "src/store/common/hooks/use-paging";
// import { ILocationState, PagingType } from "src/store/common/models/common.interface";
import { IGroup } from "../groups/models/group.interface";
import { tenantGroupsQuery } from "../groups/tenant-groups.query";
import { tenantGroupsService } from "../groups/tenant-groups.service";

export type GroupsHookTuple = [IGroup[], boolean];

export function useGroups (): GroupsHookTuple {
    // const location = useLocation<ILocationState>();
    const [groups] = useObservable(tenantGroupsQuery.groups$);
    const [loading] = useObservable(tenantGroupsQuery.isLoading$);

    useEffect(() => {
        if (!groups.length) {
            tenantGroupsService.init();
        } else {
            // tenantGroupsService.fetchGroups(
            //     get(location, `state[${PagingType.GROUPS}].activePage`, START_PAGE),
            //     get(location, `state[${PagingType.GROUPS}].rowsPerPage`, ROWS_PER_PAGE),
            // );
        }
    }, [groups.length]);

    return [groups, loading];
}