import React, { ChangeEvent, useRef, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { InputBase, withStyles } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ArrowDownIcon } from '../../icons';
import { useClickOutside } from 'src/store/common/hooks/use-click-outside';
import { COLORS } from "../../theme/variables/colors";

const AutocomleteInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '4px 10px',
            // borderRadius: '4px',
            // backgroundColor: COLORS.INPUT_BG,
            maxWidth: 340,
            width: '100%',
            '& > svg': {
                margin: '0 14px 0 0',
                color: ' #2B65F0',
            },

            '& div[role="progressbar"] svg': {
                color: 'orange',
            },

            [theme.breakpoints.down("sm")]: {
                maxWidth: '100%',
            }
        },
        input: {
            textTransform: 'capitalize',
        },
        focused: {
            border: '1px solid #789EFF',
            '& > svg': {
                margin: '0 14px 0 0',
                color: ' #789EFF',
            },
        },
        // endAdornment: {
        //     position: 'absolute',
        //     top: '15px',
        //     left: 10,
        // },
        // popupIndicator: {
        //     fontSize: '14px !important',
        //     color: '#BEC4D3',
        // },
        // popupIndicatorOpen: {
        //     transform: "rotate(0)",
        //     color: '#789EFF',
        // },
        '@global': {
            '.MuiInput-underline:before': {
                borderBottom: 'none !important'
            },
            '.MuiInput-underline:after': {
                borderBottom: 'none !important'
            }
        }
    })
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            // border: '1px solid #BEC4D3',
            // borderRadius: '4px',
            maxWidth: 340,
            width: '100%',

            [theme.breakpoints.down("sm")]: {
                maxWidth: '100%',
            }
        },
        label: {
            display: 'block',
        },
        listbox: {
            width: '100%',
            margin: 0,
            padding: 0,
            zIndex: 99999,
            position: 'absolute',
            top: 50,
            listStyle: 'none',
            backgroundColor: theme.palette.background.paper,
            overflow: 'hidden',
            borderRadius: '4px',
            boxShadow: '15px 15px 25px #f8f9fc',
            '& li': {
                padding: '4px 4px 4px 10px',
                textTransform: 'capitalize',
            },
            '& li:not(:last-child)': {
                margin: '0 0 8px',
            },
            '& li[data-focus="true"]': {
                backgroundColor: '#4a8df6',
                color: 'white',
                cursor: 'pointer',
            },
            '& li:active': {
                backgroundColor: '#2977f5',
                color: 'white',
            },
        },
    }),
);

export interface IOption {
    value: string;
    label: string;
    content?: string | React.ReactNode;
}

interface ICustomAutocomplete {
    id: string;
    labelField?: 'value' | 'content';
    changeHandler?: (value: any) => void;
    placeholder?: string;
    maxWidth?: number | string;
    width?: number | string;
    options: IOption[];
    selectedValue?: string;
    setOnlyValue?: boolean;
    bgColor?: string;
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    startAddornment?: React.ReactNode;
}

export const CustomDropdown: React.FC<ICustomAutocomplete> = ({
    id,
    labelField,
    changeHandler,
    placeholder,
    maxWidth = '175px',
    width = '100%',
    options,
    selectedValue,
    setOnlyValue = false,
    bgColor,
    style,
    inputStyle,
    startAddornment,
}) => {
    const rootRef = useRef(null);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    useClickOutside(rootRef, () => setOpen(false));

    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id,
        open,
        options,
        getOptionLabel: (option) => option.label as string,
        getOptionSelected: (option, value) => option.label === value.label,
    });

    const toggleDropdown = () => {
        setOpen(open => !open);
    }

    const handleMouseDown = (e: ChangeEvent<{ textContent: string }>) => {
        const rootProps: any = getRootProps();

        rootProps.onMouseDown(e);

        const found = options.find(item => item.label === e.target?.textContent);

        if (typeof changeHandler === 'function') {
            if (found) {
                if (setOnlyValue) {
                    changeHandler(found.value);
                } else {
                    changeHandler(found);
                }
            }
        }

        toggleDropdown();
    }

    const {onMouseDown, ...restListBoxProps}: any = getListboxProps();

    return (
        <div style={{
            position: 'relative',
            maxWidth,
            width,
            backgroundColor: bgColor ? bgColor : COLORS.INPUT_BG,
            borderRadius: '4px',
            ...style,
        }}>
            <div ref={rootRef} className={classes.root} {...getRootProps()} onClick={toggleDropdown}>
                <AutocomleteInput {...getInputProps()} 
                    placeholder={placeholder}
                    value={selectedValue}
                    disabled
                    style={inputStyle}
                    startAdornment={startAddornment}
                    endAdornment={
                        <div style={{ 
                            display: 'flex',
                            cursor: 'pointer',
                            fontSize: '12px',
                            color: ' #2B65F0',
                            transform: open ? `rotate(180deg)` : `rotate(0deg)`,
                        }}>
                            <ArrowDownIcon />
                        </div>
                    }
                />
            </div>
                {groupedOptions.length > 0 ? (
                    <ul className={classes.listbox} {...restListBoxProps} onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}>
                        {groupedOptions.map((option, index) => (
                            <li {...getOptionProps({ option, index })}>{option?.content || option?.label}</li>
                        ))}
                    </ul>
                    ) : null}
        </div>
    );
}