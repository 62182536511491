export const SYSTEM_MESSAGES = {
    EMAIL_SETTINGS_UPDATED: 'Email settings has been successfully updated',

    TEMPLATE_CREATED: 'Template has been successfully created',
    TEMPLATE_UPDATED: 'Template has been successfully updated',
    TEMPLATE_DELETED: 'Template has been successfully deleted',

    TEMPLATE_NO_BODY: `Body can't be blank`,
    EMPTY_SUBJECT: `Subject field can't be blank`,
    
    AUTO_NOTIFICATION_CREATED: 'Notification has been successfully created',
    AUTO_NOTIFICATION_UPDATED: 'Notification has been successfully updated',
    AUTO_NOTIFICATION_DELETED: 'Notification has been successfully deleted',

    FOLLOW_UP_CREATED: 'Follow Up has been successfully created',
    FOLLOW_UP_UPDATED: 'Follow Up has been successfully updated',
    FOLLOW_UP_DELETED: 'Follow Up has been successfully deleted',

    CALENDAR_SETTING_UPDTED: 'Calendar settings has been successfully updated',
};

export const SNACK_TIMEOUT = 4000;
export const DEBOUNCE = 400;