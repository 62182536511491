import React, { forwardRef, useEffect, useState } from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import html2canvas from 'html2canvas';

import styles from '../appointments.module.scss';
import { CircularProgress } from '@material-ui/core';
// import { FormHolder } from 'src/components/ui/components/forms/form-holder';
// import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';

const Holder = posed.div({
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    transition: { duration: 300 },
});

const ImageHolder = styled.div`
    margin: 0 -20px;
    padding: 40px;
    background-color: #868A91;
`;

const LoaderHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 400px;
`;

interface IPrintCalendar {
    ref: any;
    name: string;
    active?: boolean;
    imgData?: any;
}

export const PrintCalendar: React.FC<IPrintCalendar> = forwardRef(({ active }, ref) => {
    const [image, setImage] = useState<any>();

    useEffect(() => {
        if (document.querySelector('.rbc-time-view')) {
            const el = document.querySelector('.rbc-time-view');
            // rbc-calendar
            html2canvas(document.querySelector('.rbc-time-view') as any, {
                width: el?.getBoundingClientRect().width,
                height: 1545,
                onclone: function(clone) {
                    (clone as any).querySelector('body').style.overflow = 'auto';
                    (clone as any).querySelector('.rbc-calendar').style.height = 'auto';

                    return true;
                }
            }).then(function(canvas) {
                const imgData = canvas.toDataURL('image/png');

                setImage(() => imgData);
            });
        } else {
            const el = document.querySelector('.rbc-month-view');
            html2canvas(document.querySelector('.rbc-month-view') as any, {
                width: el?.getBoundingClientRect().width,
                height: el?.getBoundingClientRect().height,
            }).then(function(canvas) {
                const imgData = canvas.toDataURL('image/png');

                setImage(() => imgData);
            });
        }
    }, []);

    return (
        <Holder pose={ active ? 'visible' : 'hidden' }>
            <ul className={styles.export_holder}>
                <li className={styles.export_title}>
                    <span>Print</span>
                </li>
            </ul>
            { image ? (
                <ImageHolder>
                    <img ref={ref as any} style={{ maxWidth: '100%', maxHeight: '100%' }} src={image} alt="print preview" />
                </ImageHolder>
            ) : (
                <LoaderHolder>
                    <CircularProgress />
                </LoaderHolder>
            ) }
        </Holder>
    );
})