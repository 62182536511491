import { createEntityQuery } from "@datorama/akita";
import { IOwnersState, ownersStore } from "./owners.store";
import moment from "moment";
import { combineLatest } from "rxjs";
import { getFilteredOwners } from "./owners.filter";
import { searchOwnersQuery } from "./search/search-owners.query";
import { map } from "rxjs/operators";

export const oq = createEntityQuery<IOwnersState>(ownersStore);

export const lastImported$ = oq.select(state => moment(state.lastImported).format('LLL'));
export const owners$ = oq.select(state => state.owners);
export const ownersCount$ = oq.select(state => state.ownersCount);
export const isLoading$ = oq.selectLoading();
export const currentOwner$ = oq.select(state => state.currentOwner);
export const importState$ = oq.select(state => state.importingState);

export const selectedOwners$ = oq.select(state => state.selectedOwners);

export const messageHistory$ = oq.select(state => state.messageHistory);

export const selectFilteredOwners$ = combineLatest([
    searchOwnersQuery.appliedFilters$,
    owners$,
]).pipe(
    map(([searchCriteria, owners]) => {
        return getFilteredOwners({
            searchCriteria, 
            owners,
        });
    })
);

export const ownersQuery = {
    lastImported$,
    owners$,
    ownersCount$,
    isLoading$,
    currentOwner$,
    importState$,
    selectedOwners$,
    messageHistory$,
    selectFilteredOwners$,
};