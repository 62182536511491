import { flow, get } from "lodash";
import { IProperty } from "./models/property.interface";
import { IPropertiesSelectedCriteria } from "./search/search-properties.store";
import { ActivelyRentingState } from './search/search-properties.store';

interface IPropertiesFilter {
    searchCriteria: IPropertiesSelectedCriteria,
    properties: IProperty[],
}

export function readyToLeaseFilter({ searchCriteria, properties }: IPropertiesFilter) {
    if (!get(searchCriteria, 'readyToLease', []).length) {
        return {
            searchCriteria,
            properties,
        };
    }

    const fprop = properties.filter((property: IProperty) => searchCriteria.readyToLease.includes(property.readyToLease.toUpperCase() as ActivelyRentingState));

    return {
        searchCriteria,
        properties: fprop,
    };
}

export function targetRentFromFilter({ searchCriteria, properties }: IPropertiesFilter) {
    if (get(searchCriteria, 'targetRent.from', null) === null || isNaN(searchCriteria.targetRent.from as number)) {
        return {
            searchCriteria,
            properties,
        };
    }

    const fprop = properties.filter((proeprty: IProperty) => Number(proeprty.targetRent) >= Number(searchCriteria.targetRent.from));

    return {
        searchCriteria,
        properties: fprop,
    }
}

export function targetRentToFilter({ searchCriteria, properties }: IPropertiesFilter) {
    if (get(searchCriteria, 'targetRent.to', null) === null || isNaN(searchCriteria.targetRent.to as number)) {
        return {
            searchCriteria,
            properties,
        };
    }

    const fprop = properties.filter((proeprty: IProperty) => Number(proeprty.targetRent) <= Number(searchCriteria.targetRent.to));

    return {
        searchCriteria,
        properties: fprop,
    }
}

export function bedroomsFromFilter({ searchCriteria, properties }: IPropertiesFilter) {
    if (get(searchCriteria, 'numberOfBedrooms.from', null) === null || isNaN(searchCriteria.numberOfBedrooms.from as number)) {
        return {
            searchCriteria,
            properties,
        };
    }

    const fprop = properties.filter((proeprty: IProperty) => Number(proeprty.bedrooms) >= Number(searchCriteria.numberOfBedrooms.from));

    return {
        searchCriteria,
        properties: fprop,
    }
}

export function bedroomsToFilter({ searchCriteria, properties }: IPropertiesFilter) {
    if (get(searchCriteria, 'numberOfBedrooms.to', null) === null || isNaN(searchCriteria.numberOfBedrooms.to as number)) {
        return {
            searchCriteria,
            properties,
        };
    }

    const fprop = properties.filter((proeprty: IProperty) => Number(proeprty.bedrooms) <= Number(searchCriteria.numberOfBedrooms.to));

    return {
        searchCriteria,
        properties: fprop,
    }
}

export function bathroomsFromFilter({ searchCriteria, properties }: IPropertiesFilter) {
    if (get(searchCriteria, 'numberOfBathrooms.from', null) === null || isNaN(searchCriteria.numberOfBathrooms.from as number)) {
        return {
            searchCriteria,
            properties,
        };
    }

    const fprop = properties.filter((proeprty: IProperty) => Number(proeprty.bathrooms) >= Number(searchCriteria.numberOfBathrooms.from));

    return {
        searchCriteria,
        properties: fprop,
    }
}

export function bathroomsToFilter({ searchCriteria, properties }: IPropertiesFilter) {
    if (get(searchCriteria, 'numberOfBathrooms.to', null) === null || isNaN(searchCriteria.numberOfBathrooms.to as number)) {
        return properties;
    }

    return properties.filter((proeprty: IProperty) => Number(proeprty.bathrooms) <= Number(searchCriteria.numberOfBathrooms.to));
}

export const getFilteredProperties = flow([
    readyToLeaseFilter,
    targetRentFromFilter,
    targetRentToFilter,
    bedroomsFromFilter,
    bedroomsToFilter,
    bathroomsFromFilter,
    bathroomsToFilter,
]);