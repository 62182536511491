import React, { Fragment } from 'react';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { CaseIcon, InfoIcon, KeyIcon, StarIcon } from 'src/components/ui/icons';
import { IProperty } from 'src/store/properties/models/property.interface';
import styled from 'styled-components';

const PortfolioHolder = styled.span`
    display: inline-block; 
    font-weight: bold; 
    margin: 14px 52px 0;

    @media screen and (max-width: 1200px) {
        margin: 14px 0 0;
    }
`;

interface IInfo {
    property: IProperty;
}

export const Info: React.FC<IInfo> = ({ property }) => {
    return (
        <Fragment>
            <FormHolder
                title="Information"
                noPadding
                withDivider
                icon={<InfoIcon />}>
                <Form<Partial<IProperty>>
                    type={FormType.DEFAULT}
                    data={property! as Partial<IProperty>}
                    readOnly
                    fields={{
                        unitAddress2: {
                            index: 0,
                            label: 'Unit Address Cont',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        city: {
                            index: 1,
                            label: 'Building City',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        buildingState: {
                            index: 2,
                            label: 'Building State',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        postalCode: {
                            index: 3,
                            label: 'Postal Code',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        // neighborhood: {
                        //     index: 4,
                        //     label: 'Neighborhood',
                        //     labelDisplay: 'block',
                        //     wrapperStyles: {
                        //         flexDirection: 'row',
                        //     },
                        //     selector: 'name',
                        //     fieldType: InputType.STRING,
                        // },
                        targetRent: {
                            index: 5,
                            label: 'Target Rent',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            labelDisplay: 'block',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <span style={{ fontWeight: 'bold' }}>{ `$${property.targetRent}` }</span>
                        },
                        keyCode: {
                            index: 6,
                            label: 'Key Code',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING
                        }
                    }}
                />
            </FormHolder>
            <FormHolder
                title="Portfolio"
                dirrection="row"
                withDivider
                icon={<CaseIcon />}>
                <PortfolioHolder>{property.portfolio.name}</PortfolioHolder>
            </FormHolder>
            <FormHolder
                title="Amenities"
                withDivider
                icon={<StarIcon />}>
                <Form<Partial<IProperty>>
                    type={FormType.DEFAULT}
                    data={property!}
                    readOnly
                    fields={{
                        bedrooms: {
                            index: 0,
                            label: 'Bedrooms',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.INT_INPUT,
                        },
                        bathrooms: {
                            index: 1,
                            label: 'Bathrooms',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.INT_INPUT,
                        },
                        furnitureIncluded: {
                            index: 2,
                            label: 'Furniture Included',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            height: '40px',
                            fieldType: InputType.STRING,
                        }
                    }}
                />
            </FormHolder>
            <FormHolder
                title="Manage"
                icon={<KeyIcon />}>
                <Form<Partial<IProperty>>
                    type={FormType.DEFAULT}
                    data={property! as Partial<IProperty>}
                    readOnly
                    fields={{
                        doWeManage: {
                            index: 1,
                            label: 'ER Manage',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <div>
                                {property.doWeManage && <span style={{fontWeight: 'bold'}}>Yes</span>}
                                {!property.doWeManage && <span style={{fontWeight: 'bold'}}>No</span>}
                            </div>
                        },
                        gas: {
                            index: 2,
                            label: 'Gas',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        heat: {
                            index: 3,
                            label: 'Heat',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        electricity: {
                            index: 4,
                            label: 'Electricity',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        water: {
                            index: 5,
                            label: 'Water',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        }
                    }}
                />
            </FormHolder>
        </Fragment>
    );
}