import {IUiComponent} from "../../types/ui-component.interface";
import React from "react";
import Classes from './chip.module.scss';
import {CloseIcon} from "../../icons";
import {INamedEntity} from "../../../../store/properties/models/named-entity.interface";


interface IProps extends IUiComponent {
    item: INamedEntity;
    onDelete<T>(data: T): void;
}

export function Chip(props: IProps) {
    const { item, onDelete } = props;
    return (
        <div className={Classes.chip}>
            <div className={Classes.chip_text}>
                {item.name}
            </div>
            <div onClick={() => onDelete(item)} className={Classes.chip_icon}>
                <CloseIcon />
            </div>
        </div>
    )
}
