import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { convertFromRaw, Editor, EditorState, RichUtils } from 'draft-js';

import styles from 'src/components/containers/send-message/send-message.module.scss';
import { get, isEqual } from 'lodash';
import { IntegerInput } from 'src/components/ui/components/forms/inputs/int-input';
import { Add } from '@material-ui/icons';

import { Toolbar } from 'src/components/containers/send-message/toolbar';
import { getBlockStyle } from 'src/components/containers/send-message/inline-styles';

import 'draft-js/dist/Draft.css';

const Component = ({hoursProp, onHoursChange}: any) => {
    const [hours, setHours] = useState(hoursProp);

    const handleInputChange = (e: ChangeEvent<any>) => {
        setHours(() => e.target.value);
        
        if (typeof onHoursChange === 'function') {
            onHoursChange(e.target.value);
        }
    }

    return (
        <div className={styles.customBlockHolder}>
            <span style={{ 
                margin: '0 -40px', 
                padding: '10px', 
                backgroundColor: 'rgba(238,242,255,0.8)',
                borderRadius: '8px',

            }}>Notice of Scheduled Showing</span>
            <span>for the next (hours)</span>
            <IntegerInput 
                type="square" 
                style={{ backgroundColor: 'rgba(238,242,255,0.8)' }} 
                value={hours} 
                onChange={handleInputChange}/>
        </div>
    );
};

export interface IMessageEditorProps {
    formState?: any;
    templateId?: number;
    onChange: (value: any) => void;
    onSystemDataChange?: (hours: number) => void;
    toggleSysData: (isSysData: boolean) => void;
}

const MessageEditorComponent: React.FC<IMessageEditorProps> = ({ formState, templateId, onChange, onSystemDataChange, toggleSysData }) => {
    const [editorState, setEditorState] = useState(() => formState.options 
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(formState.options))) 
        : EditorState.createEmpty());

    const [isSystemData, toggleSystemData] = useState<boolean>(false);
    // const [hours, setHours] = useState(1);

    useEffect(() => {
        if (formState.options) {
            setEditorState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(formState.options))));
        }

        if (get(formState, 'systemData.hours', false)) {
            toggleSystemData(() => true);
        }

    }, [templateId, formState]);

    const handleChange = (editorState: EditorState) => {
        if (onChange) {
            setEditorState(editorState);
            onChange(editorState);
        }
    }

    const handleKeyCommand = (command: string, editorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
    
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
    
        return 'not-handled';
    }

    const insertBlock = () => {    
        toggleSystemData(prevState => !prevState);
        toggleSysData(!isSystemData);
    };

    const applyStyle = (style: string) => {
        setEditorState(() => RichUtils.toggleInlineStyle(editorState, style));
    }

    return (
        <div className={styles.editorWrapper}>
            {
                isSystemData
                    ? null // (
                    //     <div className={styles.addSysButton} style={{
                    //         margin: '8px 0 0',
                    //     }} onClick={insertBlock}>
                    //         <span className={styles.addIcon}>
                    //             <Remove fontSize="small" />
                    //         </span>
                    //         <span className={styles.buttonText}>Remove system data</span>
                    //     </div>
                    // )
                    : (
                        <div className={styles.addSysButton} onClick={insertBlock}>
                            <span className={styles.addIcon}>
                                <Add fontSize="small" />
                            </span>
                            <span className={styles.buttonText}>Add system data</span>
                        </div>
                    )
            }
            {
                !isSystemData
                    ? (
                        <Fragment>
                            <div className={styles.editorHolder}>
                                <Editor 
                                    blockStyleFn={getBlockStyle}
                                    handleKeyCommand={handleKeyCommand}
                                    editorState={editorState} 
                                    onChange={handleChange} />
                            </div>
                            <div className={styles.editorCotrols}>
                                <Toolbar editorState={editorState} updateEditorState={setEditorState} applyStyle={applyStyle}  />
                            </div>
                        </Fragment>
                    )
                    : <Component hoursProp={get(formState, 'systemData.hours', 1)} onHoursChange={onSystemDataChange} />
            }
        </div>
    );
}

export const MessageEditor = React.memo(MessageEditorComponent, (prevProps, nextProps) => {
    return isEqual(prevProps.formState, nextProps.formState);
});