import { createEntityQuery } from "@datorama/akita";
import { KeyedNamedEntity } from "../../properties/search/search-properties.query";
import { IProspectSearchCriteria, ISearchProspectsState, searchProspectsStore } from "./prospect-search.store";
import { LeadStatus } from "../models/prospect.interface";

const statusMap = new Map();
statusMap.set(LeadStatus.FOR_FOLLOW_UP, 'For Follow Up');
statusMap.set(LeadStatus.LOST, 'Lost');
statusMap.set(LeadStatus.APPLICANT, 'Applicant');

export const psq = createEntityQuery<ISearchProspectsState>(searchProspectsStore);

export const allSelectedFilters$ = psq.select<KeyedNamedEntity<keyof IProspectSearchCriteria>[]>(state => {
    const { selectedCriteria: { notificationMethod, agentsAttached, leadStatus, approximateBudget, bedrN, city } } = state;
    const entities: KeyedNamedEntity<keyof IProspectSearchCriteria>[] = [];

    if (approximateBudget.from) {
        entities.push({
            id: approximateBudget.from,
            name: `Budget from: ${approximateBudget.from}`,
            key: 'approximateBudgetF',
        });
    }

    if (approximateBudget.to) {
        entities.push({
            id: approximateBudget.to,
            name: `Budget to: ${approximateBudget.to}`,
            key: 'approximateBudgetT',
        });
    }

    if (bedrN.from) {
        entities.push({
            id: bedrN.from,
            name: `Bedr. number from: ${bedrN.from}`,
            key: 'bedroomsNumberF',
        });
    }

    if (bedrN.to) {
        entities.push({
            id: bedrN.to,
            name: `Bedr. number to: ${bedrN.to}`,
            key: 'bedroomsNumberT',
        });
    }

    agentsAttached.forEach(agent => {
        entities.push({
            id: agent.id,
            name: `${agent.name}`,
            key: 'agentsAttached',
        })
    });

    leadStatus.forEach(status => {
        entities.push({
            id: status,
            name: `Lead status: ${statusMap.get(status)}`,
            key: 'leadStatus',
        })
    });
    
    notificationMethod.forEach(m => {
        entities.push({
            id: m.id,
            name: `${m.name}`,
            key: 'notificationMethod'
        })
    });

    city.forEach(c => {
        entities.push({
            id: c.id,
            name: `${c.name}`,
            key: 'city'
        })
    });

    return entities;
})

export const loading$ = psq.selectLoading();
export const filterCriteria$ = psq.select(state => state.selectedCriteria);
export const searchData$ = psq.select(state => state.searchData);
export const appliedFilters$ = psq.select(state => state.appliedFilters);

export const prospectsSearchQuery = {
    allSelectedFilters$,
    loading$,
    filterCriteria$,
    searchData$,
    appliedFilters$,
};
