import React, { Fragment, SyntheticEvent } from 'react';
import { ItalicIcon, UnderlineIcon } from 'src/components/ui/icons';
import styled from 'styled-components';
import { ContentBlock } from 'draft-js';

interface StyledButtonProps {
    active?: boolean;
    onClick?: (e: SyntheticEvent) => void;
}

const StyledButton = styled.button<StyledButtonProps>`
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    background-color: ${({ active }) => active ? '#789EFF' : '#F8F9FE'};
    color: ${({ active }) => active ?' #F8F9FE' : '#789EFF'};
    font-size: 14px;
    cursor: pointer;

    &:focus,
    &:active {
        outline: none !important;
    }

    &:not(:last-child) {
        margin: 0 10px 0 0;
    }

    & svg {
        fill: ${({ active }) => active ? '#F8F9FE' : '#789EFF'};
    }
`;

// const inlineStyles = [
//     {
//         label: 'bold',
//         style: 'BOLD',
//         icon: <span>B</span>,
//     },
//     {
//         label: 'italic',
//         style: 'ITALIC',
//         icon: <ItalicIcon />,
//     },
//     {
//         label: 'underline',
//         style: 'UNDERLINE',
//         icon: <UnderlineIcon />,
//     }
// ];

export const blockTypes = [
    { label: " “ ” ", style: "blockquote" },
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "{ }", style: 'code-block' }
];

export const getBlockStyle = (block: ContentBlock): string => {    
    switch (block.getType()) {
        case "bold":
            return "RichEditor-bold";
        default:
            return '';
    }
}

export const RenderInlineStyles: React.FC<any> = ({ editorState, applyStyle }) => {
    const isActive = (style: any) => {
        const currentStyle = editorState.getCurrentInlineStyle();

        return currentStyle.has(style);
    }

    return (
        <Fragment>
            <StyledButton active={isActive('BOLD')} onClick={() => applyStyle('BOLD')}>
                <span>B</span>
            </StyledButton>
            <StyledButton active={isActive('ITALIC')} onClick={() => applyStyle('ITALIC')}>
                <ItalicIcon />
            </StyledButton>
            <StyledButton active={isActive('UNDERLINE')} onClick={() => applyStyle('UNDERLINE')}>
                <UnderlineIcon />
            </StyledButton>
            {/* {inlineStyles.map(item => 
                <StyledButton key={guid()} active={isActive(item.style)} onClick={(e: SyntheticEvent) => applyStyle(item.style)}>
                    { item.icon || item.label }
                </StyledButton>
            )} */}
        </Fragment>
    );
};