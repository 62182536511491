import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {RoutesEnum} from "../../routing/routes";
import {Tenants} from "./pages/tenants-list/tenants";
import {TenantGroupView} from "./pages/tenant-group-view/tenant-group-view";
import {TenantView} from "./pages/tenant-view/tenant-view";
import {PropertyView} from "./pages/property-view/property-view";
import TenantGroupInfo from "./pages/tenant-group-view/tenant-group-info";

export function TenantsContainer() {
    return <Switch>
        <Route exact path={RoutesEnum.TENANTS_LIST} component={Tenants} />
        <Route exact path={RoutesEnum.TENANT_GROUP_CREATE} component={TenantGroupView} />
        <Route exact path={RoutesEnum.TENANT_GROUP_EDIT} component={TenantGroupView} />
        <Route exact path={RoutesEnum.TENANT_VIEW} component={TenantView} />
        <Route path={RoutesEnum.PROPERTY_VIEW} component={PropertyView} />
        <Route exact path={RoutesEnum.TENANT_GROUP_VIEW} component={TenantGroupInfo} />
        <Redirect to="/profiles/tenants" />
    </Switch>
}
