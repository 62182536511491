import React from "react";
// import {SIZES} from "../theme/variables/sizes";

interface IProps {
    children: React.ReactNode;
}
export function ContentContainer(props: IProps) {
    return <div style={{
        // height: `calc(100vh - ${SIZES.HEADER_HEIGHT}px - 22px)`,
        overflowY: 'scroll',
    }}>
        {props.children}
    </div>
}
