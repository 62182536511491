import React, { Fragment, useMemo, useState } from 'react';
import { Add, MoreVert } from '@material-ui/icons';
import { IDataTableColumn } from 'react-data-table-component';
import { Title } from 'src/components/ui/components/forms/title';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { INumerated } from 'src/store/properties/models/numerated.interface';
import { COLORS } from 'src/components/ui/theme/variables/colors';
import { Table } from 'src/components/ui/components/table';

import { SearchInput, SearchInputType } from 'src/components/ui/components/forms/inputs/search';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { useHistory } from 'react-router-dom';
import { ArchiveIcon, EditIcon, SettingsOutlinedIcon } from 'src/components/ui/icons';
import { panelMobileStyles } from 'src/components/ui/main-layout/layout';
import { useAutoNotification } from 'src/store/admin-settings/hooks/use-autonotification';
import { useAdminSearch } from 'src/store/admin-settings/hooks/use-admin-search';
import { AdminSearchType, adminSettingsService } from 'src/store/admin-settings/admin-settings.service';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { guid } from '@datorama/akita';
import moment from 'moment';

import styles from '../../../admin.module.scss';
import baseStyles from 'src/styles/base.module.scss';
import { IAutoNitificaton } from 'src/store/admin-settings/models/autonotification.interface';
import { TitleHolder } from 'src/components/containers/properties/pages/list';
import { useObservable } from '@libreact/use-observable';
import { adminSettingsQuery } from 'src/store/admin-settings/admin-settings.query';

const appointmentsTypesMap = new Map();
appointmentsTypesMap.set('SHOWING', 'Showing');
appointmentsTypesMap.set('MEETING', 'Meeting');
appointmentsTypesMap.set('OTHER', 'Other');

const receiverTypesMap = new Map();
receiverTypesMap.set('PROSPECTS', 'Prospects');
receiverTypesMap.set('AGENTS', 'Agents');
receiverTypesMap.set('OWNERS', 'Owners');
receiverTypesMap.set('TENANTS', 'Tenants');

export const conditionalRowStyles = [
    {
        // actively renting & i % 2 === 0
        when: (row: INumerated & any) => row.i % 2 === 0,
        style: {
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    }
];

const ITEM_HEIGHT = 48;

export const MoreOptions = ({ itemId }: any) => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const moreOptions = [
        {
            name: 'Edit Notification',
            icon: <EditIcon />,
            onClick: () => history.push(`/admin/settings/autonotifications/${itemId}/edit`),
        },
        {
            name: 'Archive Notification',
            icon: <ArchiveIcon />,
            onClick: () => adminSettingsService.deleteAutoNotification(itemId),
        },
    ];

    return (
        <div className={styles.moreOptionsBtn}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '22ch',
                    },
                }}
            >
                {moreOptions.map((option) => (
                    <MenuItem key={guid()} onClick={option.onClick}>
                        <span className={styles.moreIcon}>{option.icon}</span>
                        <span className={styles.moreName}>{option.name}</span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

const columns: IDataTableColumn<Partial<IAutoNitificaton>>[] = [
    {
        name: 'Date/time',
        selector: 'datetime',
        sortable: true,
        minWidth: '150px',
        maxWidth: '200px',
        cell: (row) => {
            return (
                <div>
                    <TitleHolder>{ moment(row.datetime).format('MM.DD.YYYY, hh:mm A') }</TitleHolder>
                    <span></span>
                </div>
            );
        }
    },
    {
        name: 'Recurrence',
        selector: 'recurrenceName',
        sortable: true,
        minWidth: '150px',
    },
    // {
    //     name: 'Event type',
    //     selector: 'appointmentType',
    //     sortable: true,
    //     minWidth: '150px',
    //     cell: (row) => {
    //         return (
    //             <div>{ appointmentsTypesMap.get(row.appointmentType) }</div>
    //         );
    //     }
    // },
    {
        name: 'Receiver',
        selector: 'receivers',
        sortable: true,
        minWidth: '150px',
        cell: (row: Partial<IAutoNitificaton>) => {
            if (row.receivers?.length) {
                return (
                    <div>{ row.receivers.map((receiver, idx) => {
                        if (row.receivers?.length === 1) {
                            return receiverTypesMap.get(receiver);
                        }

                        if (idx === row.receivers?.length! - 1) {
                            return receiverTypesMap.get(receiver);
                        } else {
                            return `${receiverTypesMap.get(receiver)}, `;
                        }
                    }) }</div>
                );
            }

            return null;
        }
    },
    {
        name: 'Template',
        selector: 'templateId',
        sortable: true,
        minWidth: '150px',
        cell: (row) => {
            return (
                <div>{ row.template?.name }</div>
            );
        }
    },
    {
        name: '',
        selector: '',
        sortable: false,
        minWidth: '100px',
        maxWidth: '100px',
        cell: (row) => <MoreOptions itemId={row.id} />
    },
];

interface INotificationSettingsProps {
    loading?: boolean;
    isMobile?: boolean;
}

export const NotificationSettings: React.FC<INotificationSettingsProps> = ({ isMobile }) => {
    const history = useHistory();
    const [search] = useState('');
    // const [, setSelectedCount] = useState(0);
    const [autoNotifications, isLoading, total] = useAutoNotification();
    const [, changeSearch] = useAdminSearch(AdminSearchType.AUTO_NOTIFICATIONS);

    const [isUpdating] = useObservable(adminSettingsQuery.isUpdating$);

    const prepearedNotifications = useMemo<Partial<IAutoNitificaton>[]>(() => {
        // const nList = [
        //     {
        //         time: '03.19.2020, 7:30 PM',
        //         recurring: 'Doesn’t repeat',
        //         receiver: 'All Tenants',
        //         template: 'Showing scheduled',
        //     },
        //     {
        //         time: '-',
        //         recurring: 'Every 19 day of the month',
        //         receiver: 'Group: 5 Lexingtone Avenue',
        //         template: 'Maintenance planned',
        //     }
        // ];

        // if (search) {
        //     return nList
        //         .filter(item => item.recurring.toLowerCase().includes(search) ||
        //             item.receiver.toLowerCase().includes(search));
        // }

        if (autoNotifications.length) {
            const sortedNotifications = autoNotifications.slice().sort((a: any, b: any) => b.id - a.id);

            return sortedNotifications;
        }

        return [];
    }, [search, autoNotifications]);

    // const handleSelectionChange = (data: any) => {
    //     setSelectedCount(data.selectedCount);
    // }

    // const changeSearch = (q: string) => {
    //     setSearch(q);
    // }
    
    return (
        <Fragment>
            <Panel additionalStyles={
                isMobile ? {
                    ...panelMobileStyles,
                    margin: '-22px 0 16px',
                    width: '100%',
                    borderRadius: '0 0 0 10px',
                } : { margin: '-5px 0 30px', borderRadius: '0 0 10px 10px' }
            }>
                <Title
                    icon={<SettingsOutlinedIcon />}
                    iconStyle={{ fontSize: '14px', width: '30px', height: '30px' }}
                    text="Auto Notifications"
                />
            </Panel>
            <Panel type={PanelType.EMPTY} additionalStyles={{ margin: '0 0 15px' }}>
                {
                    isMobile
                        ? (
                            <div className={baseStyles.save_bar_mobile}>
                                <Button
                                    icon={<Add style={{fontSize: 14}}/>}
                                    btnVariant={ButtonVariant.PRIMARY}
                                    onClick={() => history.push('/admin/settings/autonotifications/new')}
                                    text="Add Notification"
                                />
                            </div>
                        ) : null
                }
                <div className={styles.actionBarHolder}>
                    <div className={baseStyles.list_action_bar_left}>
                        <SearchInput
                            onChange={changeSearch}
                            inputType={SearchInputType.PRIMARY}
                            placeholderText="Search..."
                        />
                    </div>
                    {
                        !isMobile
                            ? (
                                <div>
                                    <Button
                                        icon={<Add style={{fontSize: 14}}/>}
                                        btnVariant={ButtonVariant.PRIMARY}
                                        onClick={() => history.push('/admin/settings/autonotifications/new')}
                                        text="Add Notification"
                                    />
                                </div>
                            )
                            : null
                    }
                </div>
            </Panel>
            {/* {selectedCount > 0 && <Panel additionalStyles={{ margin: '-5px 0 22px' }}>
                <div className={styles.list_action_bar}>
                    <div className={styles.list_action_bar_left}>
                        {`${selectedCount} Selected`}
                    </div>
                </div>
            </Panel>} */}
            <Panel noPadding sideBorder additionalStyles={{ margin: '0 0 30px' }}>
                <Table<Partial<IAutoNitificaton>>
                    // onSelectionChange={handleSelectionChange}
                    // onRowClicked={onRowClicked}'
                    pagination={false}
                    totalRecordsNumber={total}
                    columns={columns}
                    conditionalRowStyles={conditionalRowStyles}
                    // onSort={onSort}
                    loading={(!prepearedNotifications.length && isLoading) || isUpdating}
                    data={prepearedNotifications}
                    highlightRow={['name']}
                />
            </Panel>
        </Fragment>
    );
}