import { arrayRemove } from "@datorama/akita";
import { api, PagableResponse } from "src/api/api.service";
import { StaffDto } from "src/components/containers/staff/pages/staff-editor/staff-editor";
// import { ApiServiceInstance } from "../api/api-service";
import { calendarStore } from "../calendar/calendar.store";
import { IPagedResponse } from "../properties/models/paged-response.interface";
import { IProspect } from "../prospects/models/prospect.interface";
import { snackbarService } from "../snackbar/snackbar.service";
import { SnackbarType } from "../snackbar/snackbar.store";
import { userStore } from "../user/user.store";
import { PagingStore } from "../utils/paging-store";
import { IStaffSearchFilters } from "./models/staff-search-filters.interface";
import { IStaff } from "./models/staff.inreface";
import { searchStaffService } from "./staff-search/staff-search.service";
import { SNACK_TIMEOUT, SYSTEM_MESSAGES } from "./staff.config";
import { staffStore } from "./staff.store";

interface IStaffGetDto extends IPagedResponse<IStaff> {}

interface ISelectedStaffs {
    data: IStaff[];
    page?: number;
}

const pagingStore = new PagingStore();

// @transaction()
export async function fetchAgents() {
    staffStore.setLoading(true);

    const response = await api.get<PagableResponse<IStaffGetDto>>('/user/agents');

    if (response.ok) {
        staffStore.set(response.data.result);
        staffStore.update(state => ({
            ...state,
            staffCount: response.data.total,
        }));
    }

    staffStore.setLoading(false);
}

function buildFilteredRequest(page: number, size: number): Partial<IStaffSearchFilters> {
    const params: Partial<IStaffSearchFilters> = {};
    const { roles, prospectsLeading } = searchStaffService.getCriteria();
    params.page = page;
    params.size = size;

    if (roles.length) {
        params.roles = roles.join(',').toUpperCase();
    }

    if (prospectsLeading?.from !== null) {
        params.prospectsLeadingF = prospectsLeading?.from;
    }

    if (prospectsLeading?.to !== null) {
        params.prospectsLeadingT = prospectsLeading?.to;
    }

    return params;
}

export async function fetchStaff(page: number = 0, size: number = 10, search: string = '') {
    staffStore.setLoading(true);
    pagingStore.setPagingData({page, size});

    const sorting = searchStaffService.getSorting();

    const params: Partial<IStaffSearchFilters> = {
        ...buildFilteredRequest(page, size),
    };

    if (search) {
        params.search = search;
    }

    params.sort = `${(sorting.field)},${sorting.type}`;

    const response = await api.get<PagableResponse<IStaff[]>>('/user', {
        params: {
            ...params
        }
    });

    if (response.ok) {
        staffStore.set(response.data.result);
        staffStore.update(state => ({
            ...state,
            staffCount: response.data.total,
        }));
        
        staffStore.setLoading(false);
    }
}

export async function fetchStaffById(id: number) {
    staffStore.setLoading(true);
    const response = await api.get<IStaff>(`/user/${id}`);

    if (response.ok) {
        staffStore.update(state => ({
            ...state,
            selectedStaff: response.data,
        }))
    }

    staffStore.setLoading(false);
}

//* GET
// @transaction()
export async function fetchStaffProspects(id: number, search: string = '') {
    staffStore.update(state => ({
        ...state,
        loadingProspects: true,
    }));

    const params: Partial<IStaffSearchFilters> = {
        ...buildFilteredRequest(0, 0),
    };

    if (search) {
        params.search = search;
    }

    const response = await api.get<PagableResponse<IProspect[]>>(`/user/${id}/prospects`, {
        params: {
            ...params,
        }
    });

    if (response.ok) {
        staffStore.update(state => ({
            ...state,
            prospectsLeading: response.data.result,
            prospectsLeadingTotal: response.data.total,
        }))
    }

    staffStore.update(state => ({
        ...state,
        loadingProspects: false,
    }));
}

    //* POST
// @transaction()
export async function createStaff(staffDto: Partial<StaffDto>) {
    staffStore.setLoading(true);
    const { selectedStaff } = staffStore.getValue();
    const { user } = userStore.getValue();

    const response = await api.post<IStaff>('/user', staffDto);

    if (response.ok) {
        staffStore.add(response.data);
        staffStore.update(state => ({
            ...state,
            staffCount: state.staffCount + 1,
        }));

        if (selectedStaff?.id === user?.id) {
            userStore.update(state => {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        photo: response.data.photo,
                    }
                };
            });
        }

        snackbarService.createSnackbar({
            text: SYSTEM_MESSAGES.CREATED,
            type: SnackbarType.SUCCESS,
        }, SNACK_TIMEOUT);

        staffStore.setLoading(false);

        return response.ok;
    } else {
        staffStore.update(state => ({
            ...state,
            selectedStaff: staffDto,
        }));

        staffStore.setLoading(false);

        return response.ok;
    }
}

    //* PATCH
// @transaction()
export async function updateStaff(staffDto: Partial<StaffDto>) {
    staffStore.setLoading(true);

    const { selectedStaff } = staffStore.getValue();
    const { user } = userStore.getValue();

    const response = await api.patch<IStaff>(`/user/${staffDto.id}`, staffDto);

    if (response.ok) {
        const updatedStaff = {
            ...response.data,
            photo: staffDto.photo,
        };

        staffStore.upsert(response.data.id, updatedStaff);

        if (selectedStaff?.id === user?.id) {
            userStore.update(state => {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        role: response.data.role,
                        photo: response.data.photo,
                    }
                };
            });
        }

        snackbarService.createSnackbar({
            text: SYSTEM_MESSAGES.UPDATED,
            type: SnackbarType.SUCCESS,
        }, SNACK_TIMEOUT);

        staffStore.setLoading(false);

        return response.ok;
    } else {
        staffStore.update(state => ({
            ...state,
            selectedStaff: staffDto,
        }));

        staffStore.setLoading(false);

        return response.ok;
    }
}

    //* DELETE
// @autobind
// @transaction()
export async function archiveStaff(staffId: number) {
    staffStore.setLoading(true);

    const response = await api.del<IStaff>(`/user/${staffId}`);

    if (response.ok) {
        staffStore.remove(staffId);
        staffStore.update(state => ({
            ...state,
            staffCount: state.staffCount - 1,
            selectedStaff: null,
        }));

        calendarStore.update(state => ({
            ...state,
            staffList: arrayRemove(state.staffList, staffId),
        }));

        snackbarService.createSnackbar({
            text: SYSTEM_MESSAGES.ARCHIVED,
            type: SnackbarType.SUCCESS,
        }, SNACK_TIMEOUT);
    }

    staffStore.setLoading(false);
}

//* RESET
// @autobind
export function resetCurrentStaff() {
    staffStore.update(state => ({
        ...state,
        selectedStaff: null,
        prospectsLeading: [],
        prospectsLeadingTotal: 0,
    }))
}

//* UPLOAD PHOTO
// @autobind
export async function uploadPhoto(file: File) {
    staffStore.update(state => ({
        ...state,
        uploadLoading: true
    }));

    const formData = new FormData();
    formData.append('file', file);

    const response = await api.uploadPhoto(formData);

    // const { data } = await erApi.post('/public/files/upload', formData, {
    //     'Content-Type': 'multipart/form-data'
    // });

    // const photo = data.path.split('/')[data.path.split('/').length - 1];

    if (response.ok) {
        staffStore.update(state => ({
            ...state,
            selectedStaff: {
                ...state.selectedStaff,
                photo: response.data,
            },
            uploadLoading: false,
        }));
    }
}

// @autobind
export function deletePhoto() {
    staffStore.update(state => ({
        ...state,
        selectedStaff: {
            ...state.selectedStaff,
            photo: null,
        },
    }))
}

export function setSelectedStaffs({
    data,
}: ISelectedStaffs) {
    staffStore.update(state => {
        return {
            ...state,
            selectedStaffs: {
                data,
            },
        }
    });
}

export function resetSelectedStaffs() {
    staffStore.update(state => {
        return {
            ...state,
            selectedStaffs: {
                data: [],
            },
        }
    });
}

export function updateSelectedStaff(data: Partial<StaffDto>) {
    staffStore.update(state => ({
        ...state,
        selectedStaff: {
            ...state.selectedStaff,
            ...data,
        },
    }));
}

//** --------------------------------------------------------- */

export function getStaff(): IStaff[] {
    return staffStore.getValue().staff;
}

//** Define Bonus */
export interface IBonusDto {
    amount: string;
    daysAging: string;
    isBonus: boolean;
}

export async function defineAgentBonus(agentId: number, contractId: number, bonusDto: IBonusDto) {
    const response = await api.post(`/agent/${agentId}/contract/${contractId}/bonus`, bonusDto);

    if (response.ok) {
        snackbarService.createSnackbar({
            text: SYSTEM_MESSAGES.BONUS_DEFINED,
            type: SnackbarType.SUCCESS,
        }, SNACK_TIMEOUT);
    }
}

export const staffService = {
    fetchAgents,
    fetchStaff,
    fetchStaffById,
    fetchStaffProspects,
    createStaff,
    updateStaff,
    archiveStaff,
    resetCurrentStaff,
    uploadPhoto,
    deletePhoto,
    setSelectedStaffs,
    resetSelectedStaffs,
    updateSelectedStaff,
    getStaff,
    defineAgentBonus,
};