import { CircularProgress, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import React, { ChangeEvent, useEffect } from 'react';
import { ArchiveIcon } from '../../icons';

import styles from './avatar.module.scss';

const theme = createMuiTheme({
    overrides: {
        MuiCircularProgress: {
            colorPrimary: {
                color: "#FF6F43"
            }
        },
    },
});

export enum AvatarSize {
    tiny = '24px',
    small = '32px',
    mid = '58px',
}

export enum FallbackType {
    ABBR = 'ABBR'
}

const RenderFallback = ({ fallback, fallbackType }: Partial<IAvatarProps>) => {
    switch(fallbackType) {
        case FallbackType.ABBR:
            const fName = fallback.split(' ')[0] || '';
            const lName = fallback.split(' ')[1] || '';

            if (fName || lName) {
                return (
                    <div className={styles.fallbackAbbr}>{`${fName?.charAt(0)} ${lName?.charAt(0)}`}</div>
                );
            }

            return <Person />;

        default:
            return <Person />;
    }
}

export interface IAvatarProps {
    src?: string;
    size?: AvatarSize;
    style?: React.CSSProperties;
    upload?: boolean;
    loading?: boolean;
    fallback?: any;
    fallbackType?: FallbackType;
    customRequest?: (files: FileList) => void;
    onRemove?: () => void;
}

export const Avatar = ({ 
    src, 
    size = AvatarSize.small, 
    style, 
    upload, 
    loading,
    fallback,
    fallbackType,
    customRequest, 
    onRemove }: IAvatarProps) => {
    useEffect(() => {
        return 
    }, []);

    const handleFileChange = (files: FileList | null) => {
        if (files?.length && typeof customRequest === 'function') {
            customRequest(files)
        }
    };

    const handleRemove = () => {
        if (typeof onRemove === 'function') {
            onRemove();
        }
    }

    if (upload) {
        if (loading) {
            return (
                <div className={styles.staffAvatar} style={{
                    ...style,
                    minWidth: size,
                    width: size,
                    height: size,
                }}>
                    <ThemeProvider theme={theme}>
                        <CircularProgress size={30} />
                    </ThemeProvider>
                </div>
            );
        }

        return (
            <div className={styles.uploaderHolder}>
                <div className={styles.staffAvatar} style={{
                    ...style,
                    minWidth: size,
                    width: size,
                    height: size,
                }}>
                    <label htmlFor="file" className={styles.uploader}>
                        <input type="file" name="file" id="file" onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileChange(e.target.files)} />
                        { src
                            ? <img src={`${src}`} width={size} height={size} alt="avatar image"/>
                            : <RenderFallback fallback={fallback} fallbackType={fallbackType} /> }
                    </label>
                    
                </div>
                { src
                    ? (
                        <div className={styles.deleteImageBtn} onClick={handleRemove}>
                            <ArchiveIcon />
                        </div>
                    )
                    : null }
            </div>
        );
    }

    return (
        <div className={styles.staffAvatar} style={{
            ...style,
            minWidth: size,
            width: size,
            height: size,
            fontSize: size === AvatarSize.tiny ? '11px' : '13px',
        }}>
            { src
                ? <img src={src} width={size} height={size} alt="avatar image"/>
                : <RenderFallback fallback={fallback} fallbackType={fallbackType} /> }
        </div>
    );
}