import { createEntityStore, EntityState } from "@datorama/akita";
import { IProspect } from "../prospects/models/prospect.interface";
import { IStaff } from "./models/staff.inreface";

export interface IStaffState extends EntityState {
    staffCount: number;
    selectedStaff: IStaff | null;
    uploadLoading?: boolean;
    prospectsLeading: IProspect[];
    prospectsLeadingTotal: number;
    loadingProspects: boolean;
    hasActive: boolean;
    loading: boolean;
    selectedStaffs: {
        data: IStaff[],
        total: 0,
    };
}

const initialState: IStaffState = {
    staffCount: 0,
    loading: false,
    selectedStaff: null,
    uploadLoading: false,
    hasActive: false,
    prospectsLeading: [],
    prospectsLeadingTotal: 0,
    loadingProspects: false,
    selectedStaffs: {
        data: [],
        total: 0,
    },
}

export const staffStore = createEntityStore<IStaffState>(initialState, {
    name: 'staff'
});