import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { ITemplate } from "../admin-settings/models/template.interface";
import { IOwner } from "../owners/models/owner.interface";
import { IProspect } from "../prospects/models/prospect.interface";
import { IStaff } from "../staff/models/staff.inreface";
import { IGroup } from "../tenants/groups/models/group.interface";
import { ITenant } from "../tenants/models/tenant.interface";
import { IPropertyGroup } from "../tenants/property-groups/models/property-group.interface";
// import { RecieverType } from "./message.service";
// import { IPropertyGroup } from "../tenants/property-groups/models/property-group.interface";

export enum MessageType {
    EMAIL = 'email',
    SMS = 'sms',
}

export enum RecieverType {
    STAFF = "STAFF",
    TENANTS = 'TENANTS',
    LEASE_TENANTS = 'LEASE_TENANTS',
    PROSPECTS = 'PROSPECTS',
    OWNERS = 'OWNERS',
    CUSTOM_GROUPS = 'CUSTOM_GROUPS',
    PROPERTY_GROUPS = 'PROPERTY_GROUPS',
}

interface IMessageHistory {
    tenants: any[],
    groups: any[],
    properties: any[],
    prospects: any[],
    propertyGroups: any[],
    owners: any[];
}

export interface IMessageState extends EntityState {
    emailFrom: string[];
    sendToList: Partial<IStaff[] | ITenant[]>;
    //* send to
    selectedStaff: IStaff[];
    selectedProspects: IProspect[];
    selectedTenants: ITenant[];
    selectedOwners: IOwner[];
    selectedPropertyGroups: IPropertyGroup[];
    selectedCustomTenantGroups: IGroup[];
    //* -----------------------------------
    templatesList: ITemplate[];
    selectedTemplate?: ITemplate;
    messageHistory: IMessageHistory;
    messageType: MessageType;
}

export const initialState: IMessageState = {
    templatesList: [],
    emailFrom: [],
    sendToList: [],
    selectedList: [],
    selectedStaff: [],
    selectedProspects: [],
    selectedTenants: [],
    selectedOwners: [],
    selectedPropertyGroups: [],
    selectedCustomTenantGroups: [],
    messageHistory: {
        tenants: [],
        groups: [],
        properties: [],
        prospects: [],
        propertyGroups: [],
        owners: [],
    },
    messageType: MessageType.EMAIL,
}

@StoreConfig({ name: 'message' })
export class MessageStore extends EntityStore<IMessageState> {
    constructor() {
        super(initialState);
    }
}

export const messageStore = new MessageStore();