import { tenantsStore } from "./tenants.store";
import { IPagedResponse } from "../properties/models/paged-response.interface";
import { ITenant } from "./models/tenant.interface";
import { guid } from "@datorama/akita";
import { searchTenantsService } from "./search/search.service";
import { PagingStore } from "../utils/paging-store";
import { ITenantSearchFilters } from "./models/tenant-search-filters.interface";
import { PropertiesImportingState, propertiesStore } from "../properties/properties.store";
import { propertiesService } from "../properties/properties.service";
import { ownersService } from "../owners/owners.service";
import { snackbarService } from "../snackbar/snackbar.service";
import { SNACK_TIMEOUT, SYSTEM_MESSAGES } from "./tenants.config";
import { SnackbarType } from "../snackbar/snackbar.store";
import { api } from "src/api/api.service";
import { ownersStore } from "../owners/owners.store";

const CHUNKS_SIZE = 300;
let selectedUnitId: string | undefined = undefined;

interface ISelectedTenants {
    data: ITenant[];
    page?: number;
}

interface IGetDto {
    response: IPagedResponse<ITenant>;
    lastImported: string | number;
}

const pagingStore = new PagingStore();

function buildFilteredRequest(page: number, size: number): Partial<ITenantSearchFilters> {
    const params: Partial<ITenantSearchFilters> = {};
    const { property } = searchTenantsService.getCriteria();

    params.page = page;
    params.size = size;

    if (property.length > 0) {
        params.unitIds = property.map(p => p.unitId as number).join(',');
    }

    // if (notificationMethod.length > 0) {
    //     params.notifications = notificationMethod.join(',');
    // }

    // if(search && search.length > 0) {
    //     params.search = search;
    // }

    return params;
}

export async function importTenants() {
    try {
        tenantsStore.update(state => ({
            ...state,
            importingState: PropertiesImportingState.IN_PROGRESS,
            tenants: [],
        }));

        ownersStore.update(state => ({
            ...state,
            owners: [],
        }));

        propertiesStore.update(state => ({
            ...state,
            properties: [],
        }));

        await api.post('/properties/import');

        await propertiesService.getProperties();
        await ownersService.fetchOwners(0, 9999);
        await getTenants();

        tenantsStore.update(state => ({
            ...state,
            importingState: PropertiesImportingState.SUCCESSFULLY_FINISHED
        }));

        snackbarService.createSnackbar({
            type: SnackbarType.INFO,
            text: SYSTEM_MESSAGES.IMPORT_SUCCESS,
        }, SNACK_TIMEOUT);
    } catch (err) {
        tenantsStore.update(state => ({
            ...state,
            importingState: PropertiesImportingState.ERROR
        }));

        snackbarService.createSnackbar({
            type: SnackbarType.ERROR,
            text: SYSTEM_MESSAGES.IMPORT_FAILED,
        }, SNACK_TIMEOUT);
    }
}

export async function fetchTenants(page: number = 0, size: number = 10, loading: boolean = false) {
    if (loading) {
        tenantsStore.setLoading(true);
    }

    pagingStore.setPagingData({page, size});

    // const sorting = this.tenantsSearchService.getSorting();

    // if (sorting.field === 'groups') {
    //     // @ts-ignore
    //     sorting.field = 'property.unitAddress';
    // }

    const params: Partial<ITenantSearchFilters> = {
        ...buildFilteredRequest(page, size),
    };

    // params.sort = `${(sorting.field)},${sorting.type}`;

    if (selectedUnitId) {
        params.unitIds = selectedUnitId.toString();
    }

    const response = await api.get<IGetDto>('/tenants', {
        params: {
            ...params,
        }
    });

    if (response.ok) {
        tenantsStore.update(state => ({
            ...state,
            tenants: [
                ...state.tenants,
                ...response.data.response.result.map(property => ({
                    ...property,
                    portfolio: property.portfolio ? property.portfolio : {
                        name: '',
                        id: guid(),
                        ownerId: null
                    }
                }))
            ],
            lastImported: response.data.lastImported,
            tenantsCount: response.data.response.total,
        }))
    }

    tenantsStore.setLoading(false);

    return {
        total: response.data.response.total,
    };
}

export async function fetchTenantById(id: number) {
    tenantsStore.setLoading(true);
    const response = await api.get(`/tenants/${id}`);

    if (response.ok) {
        tenantsStore.update(state => ({
            ...state,
            selectedTenant: response.data,
        }))
    }

    tenantsStore.setLoading(false);
}

export function resetCurrentTenant() {
    tenantsStore.update(state => ({
        ...state,
        selectedTenant: null,
    }))
}

export async function getTenants(onlySelectedUnitId?: string) {
    selectedUnitId = onlySelectedUnitId;

    const { total } = await fetchTenants(0, CHUNKS_SIZE, true);

    const chunks = total / CHUNKS_SIZE;

    for (let i = 1; i <= chunks; i++) {
        setTimeout(() => {
            return fetchTenants(i, CHUNKS_SIZE, false);
        }, 0);
    }
}

export function setSelectedTenants({
    data,
}: ISelectedTenants) {
    tenantsStore.update(state => {
        return {
            ...state,
            selectedTenants: {
                data,
            },
        }
    });
}

export function resetSelectedTenants() {
    tenantsStore.update(state => {
        return {
            ...state,
            selectedTenants: {
                data: [],
            },
        }
    });
}

export function resetTenants() {
    selectedUnitId = undefined;
    tenantsStore.update(state => {
        return {
            ...state,
            tenants: [],
            tenantsCount: 0,
        }
    });
}

export function setTenantsTotal(total: number) {
    tenantsStore.update(state => ({
        ...state,
        tenantsTotal: total,
    }));
}

export function applyFilters(isApply: boolean) {
    tenantsStore.update(state => ({
        ...state,
        isApplyFilters: isApply,
    }));
}

export function deleteFilter() {
    tenantsStore.update(state => ({
        ...state,
        isDeleteFilter: !state.isDeleteFilter,
    }));
}

export const tenantsService = {
    fetchTenants,
    fetchTenantById,
    resetCurrentTenant,
    setSelectedTenants,
    resetSelectedTenants,
    resetTenants,
    applyFilters,
    deleteFilter,
    getTenants,
    setTenantsTotal,
    importTenants,
};