import { useEffect } from 'react'
import { useObservable } from '@libreact/use-observable'
import { adminSettingsQuery } from '../admin-settings.query';
import { ITemplate } from '../models/template.interface';
import { adminSettingsService } from '../admin-settings.service';
import { TemplateType } from 'src/components/containers/admin/templates-table';

export type TemplateHookTuple = [ITemplate[], ITemplate[], boolean, ITemplate[]];

export function useTemplate(): TemplateHookTuple {
    const [emailTemapltes] = useObservable(adminSettingsQuery.emailTemplates$);
    const [smsTemapltes] = useObservable(adminSettingsQuery.smsTemplates$);
    const [allTemplates] = useObservable(adminSettingsQuery.allTemplates$);
    const [loading] = useObservable(adminSettingsQuery.loading$);

    useEffect(() => {
        if (!emailTemapltes?.length) {
            adminSettingsService.fetchTemplates(TemplateType.EMAIL, 'emailTemplates');
        }

        if (!smsTemapltes?.length) {
            adminSettingsService.fetchTemplates(TemplateType.SMS, 'smsTemplates');
        }
    }, []);

    return [emailTemapltes, smsTemapltes, loading, allTemplates];
}