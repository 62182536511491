import { searchTenantInitialState, searchTenantsStore } from "./search.store";
import { ISort } from "../../properties/search/search-properties.store";
import { INamedEntity } from "../../properties/models/named-entity.interface";
import { ITenant } from "../models/tenant.interface";
import { IOwnerCriteria } from "../../owners/search/search-owners.store";

export function getSorting() {
    return searchTenantsStore.getValue().sort;
}

export function setSorting(sorting: ISort<keyof ITenant>) {
    searchTenantsStore.update(state => ({
        ...state,
        sort: sorting,
    }));
}

export function updateSelectionCriteria(data: Partial<IOwnerCriteria>) {
    searchTenantsStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...state.selectedCriteria,
            ...data,
        }
    }))
}

export function deleteItem(item: INamedEntity) {
    const predicate = (i: INamedEntity) => !(i.id === item.id && i.name === item.name);

    searchTenantsStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...state.selectedCriteria,
            property: state.selectedCriteria.property.filter(predicate),
            notificationMethod: state.selectedCriteria.notificationMethod.filter(nm => {
                return nm !== item.id
            })
        }
    }))
}

export function removeAllSelection() {
    searchTenantsStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...searchTenantInitialState.selectedCriteria
        }
    }))
}

export function getCriteria() {
    return searchTenantsStore.getValue().selectedCriteria;
}

export function applyFilters() {
    searchTenantsStore.update(state => ({
        ...state,
        appliedFilters: state.selectedCriteria,
    }));
}

export const searchTenantsService = {
    getSorting,
    setSorting,
    updateSelectionCriteria,
    deleteItem,
    removeAllSelection,
    getCriteria,
    applyFilters,
};