import {IDataStorage} from "../data-storage.interface";
import {IStoredData} from "../stored-data.interface";

export class InMemoryStrategy implements IDataStorage<IStoredData> {

    private data: Map<keyof IStoredData, any> = new Map();

    clear(): this {
        this.data.clear();
        return this;
    }

    getItem(key: keyof IStoredData): Partial<IStoredData> | null {
        return this.data.get(key) || null;
    }

    removeItem(key: keyof IStoredData): this {
        this.data.delete(key);
        return this;
    }

    setItem(key: keyof IStoredData, value: Partial<IStoredData>): this {
        this.data.set(key, value);
        return this;
    }

}
