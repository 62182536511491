import React from "react";
import {useObservable} from "@libreact/use-observable";
import {snackbarQuery} from "../../../../store/snackbar/snackbar.query";
import {Snackbar} from "@material-ui/core";
import {snackbarService} from "../../../../store/snackbar/snackbar.service";
import {Alert} from "@material-ui/lab";

export function Snackbars() {

    const [snackbars] = useObservable(snackbarQuery.snackbars$);

    const onClose = (id: string) => {
        snackbarService.removeById(id!);
    }

    return <>
        {snackbars.map(s => {
            return <Snackbar
                open
                key={s.id}
                onClose={() => onClose(s.id!)}
            >
                <Alert onClose={() => onClose(s.id!)} severity={s.type}>
                    {s.text}
                </Alert>
            </Snackbar>
        })}
    </>
}