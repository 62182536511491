import React, {Fragment, useEffect, useState} from "react";
import CssClasses from './sidenav.module.scss';
import {Close} from "@material-ui/icons";
import classnames from 'classnames';

interface IProps<T = any> {
    width?: number | string;
    isOpen: boolean;
    header?: React.ReactNode;
    // stores the data passed when the sidenav is being opened.
    // when the sidenav closes this data will be passed to onClose
    cachedData?: T;
    withIcon?: boolean;

    onClose(cachedData?: T): void;

    children: React.ReactNode | React.ReactNode[];
    footer?: React.ReactNode | React.ReactNode[];
}

export function Sidenav<T>(props: IProps<T>) {
    const [cachedData, setCachedData] = useState<any>();
    const {width, header, isOpen, onClose, cachedData: data, children, footer} = props;

    useEffect(() => {
        if(!isOpen) {
            setCachedData(data);
        }

        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
    }, [setCachedData, data, isOpen]);


    // const containerRef = useRef<HTMLDivElement>(null);
    // const closeIconRef = useRef<HTMLDivElement>(null);

    const sidenavStyles: React.CSSProperties = {
        maxWidth: (width && isOpen) ? `${width}px` : '100%',
        width: (width && isOpen) ? `${width}px` : '100%',
        // right: 0,
        // transform: isOpen ? `translateX(0)` : `translateX(${Number(width) + 10}px)`
    }

    const bgStyles: React.CSSProperties = {
        opacity: isOpen ? 1 : 0,
        left: isOpen ? 0 : '100%',
    }

    const onCloseHandler = () => {
        document.body.style.overflow = 'inherit';
        onClose(cachedData);
    }

    // useLayoutEffect(() => {
    //     const hideOnClick = () => {
    //         onClose();
    //     }
    //
    //     const preventClosing = (e: MouseEvent) => {
    //         e.preventDefault();
    //         e.stopPropagation();
    //     };
    //
    //     document.querySelector('body')!.addEventListener('click', hideOnClick);
    //     containerRef.current!.addEventListener('click', preventClosing);
    //     closeIconRef.current!.addEventListener('click', hideOnClick);
    //
    //     return () => {
    //         document.querySelector('body')!.removeEventListener('click', hideOnClick);
    //         containerRef.current!.removeEventListener('click', preventClosing);
    //         closeIconRef.current!.removeEventListener('click', hideOnClick);
    //     }
    // }, [onClose]);

    const sidenavClasses = classnames({
        [CssClasses.sidenav]: true,
        [CssClasses.active]: isOpen,
    });

    const sidenavHeaderClasses = classnames({
        [CssClasses.sidenav_header]: true,
        [CssClasses.with_icon]: props.withIcon,
    });

    return <Fragment>
        <div className={sidenavClasses} style={sidenavStyles}>
            <div className={sidenavHeaderClasses}>
                {header}
                <div onClick={onCloseHandler} className={CssClasses.sidenav_close}>
                    <Close/>
                </div>
            </div>
            <div className={CssClasses.sidenav_wrapper}>
                <div className={CssClasses.sidenav_body}>
                    {children}
                </div>
                {footer && <div className={CssClasses.sidenav_footer}>
                    {footer}
                </div>}
            </div>
        </div>
        <div onClick={onCloseHandler} style={bgStyles} className={CssClasses.sidenav_background}/>
    </Fragment>
}
