import {IPortfolioWithProperties} from "../../../../../../store/portfolio/models/portfolio.interface";
import React, { Fragment } from "react";
import {Form, FormType, InputType} from "../../../../../ui/components/forms/formBuilder/form";
import { HomeIcon } from "src/components/ui/icons";
import { FormHolder } from "src/components/ui/components/forms/form-holder";

type MappedFormType = IPortfolioWithProperties & {numberOfProperties: number, erManaging: boolean}

export function OwnerPortfolio(portfolio: IPortfolioWithProperties, erManaging: boolean) {
    const data: MappedFormType = {
        ...portfolio,
        numberOfProperties: portfolio.propertyDtos.length,
        erManaging,
    }
    return <Fragment>
        <FormHolder
            title="Portfolio"
            icon={<HomeIcon />}>
            <Form<Partial<MappedFormType>>
                data={data}
                type={FormType.DEFAULT}
                readOnly
                fields={{
                    name: {
                        index: 0,
                        fieldType: InputType.STRING,
                        label: 'Title',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                    },
                    erManaging: {
                        index: 1,
                        fieldType: InputType.CUSTOM_ELEMENT,
                        label: 'ER Managing',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        customElement: <div style={{fontWeight: 'bold'}}>{data.erManaging ? 'Yes' : 'No'}</div>
                    },
                    numberOfProperties: {
                        index: 2,
                        fieldType: InputType.STRING,
                        label: 'N. Of Properties In Portfolio',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                    }

                }}
            />
        </FormHolder>
    </Fragment>
}
