import {DEVICE_THRESHOLDS, DeviceType, uiStore, UiStore} from "./ui.store";
import {IInitializable} from "../portfolio/models/on-init.interface";
import autobind from "autobind-decorator";

export class UiService implements IInitializable{
    constructor(protected uiStore: UiStore) {}

    isInitialized = false;

    changeRoute(routeName: string) {
        this.uiStore.update(state => ({
            ...state,
            currentRouteName: routeName,
        }));
    }

    private selectDeviceType(width: number) {
        const currentType = this.uiStore.getValue().deviceType;
        if(width > DEVICE_THRESHOLDS[DeviceType.DESKTOP] && currentType !== DeviceType.DESKTOP) {
            this.uiStore.update(state => ({
                ...state,
                deviceType: DeviceType.DESKTOP,
                isSidenavOpen: true,
            }));
        } else if(width < DEVICE_THRESHOLDS[DeviceType.DESKTOP] && currentType !== DeviceType.MOBILE) {
            this.uiStore.update(state => ({
                ...state,
                deviceType: DeviceType.MOBILE,
                isSidenavOpen: false,
            }));
        }
    }

    init(): void {
        this.selectDeviceType(document.documentElement.getBoundingClientRect().width);
        window.addEventListener('resize', () => {
            const width = document.documentElement.getBoundingClientRect().width;
            this.selectDeviceType(width);
        });
        this.isInitialized = true;
    }

    @autobind
    toggleSidenav() {
        this.uiStore.update(state => ({
            ...state,
            isSidenavOpen: !state.isSidenavOpen,
        }))
    }
}

export const uiService = new UiService(uiStore);
