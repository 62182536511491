import { ISnackbar, snackbarStore } from "./snackbar.store";
import { guid } from "@datorama/akita";

export function removeById(id: string) {
    snackbarStore.update(state => ({
        ...state,
        snackbars: state.snackbars.filter(snackbar => snackbar.id !== id)
    }))
}

export function createSnackbar(snackbar: ISnackbar, automaticallyRemoveIn?: number) {
    const id = guid();

    snackbarStore.update(state => ({
        ...state,
        snackbars: [
            ...state.snackbars,
            {
                ...snackbar,
                id
            }
        ]
    }));

    if (automaticallyRemoveIn) {
        setTimeout(() => {
            removeById(id);
        }, automaticallyRemoveIn)
    }
}

export const snackbarService = {
    removeById,
    createSnackbar,
};