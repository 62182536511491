import React from 'react';
import styled from 'styled-components';

const ErrorPageHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const ErrorTitle = styled.div`
    font-family: 'ManropeSemibold';
    font-size: 28px;
`;

const ErrorSubtitle = styled.div`
    font-family: 'ManropeThin';
    font-size: 18px;
`;

export const ErrorPage = () => {
    return (
        <ErrorPageHolder>
            <ErrorTitle>Oops! Something went wrong.</ErrorTitle>
            <ErrorSubtitle>Please check your internet connection or refresh the page</ErrorSubtitle>
        </ErrorPageHolder>
    );
}