import React, { Fragment, useMemo, useState, useEffect } from "react";
import moment from "moment";
import { IDataTableColumn } from "react-data-table-component";
import { Title } from "src/components/ui/components/forms/title";
import { Panel, PanelType } from "src/components/ui/components/panel";
import { Table } from "src/components/ui/components/table";
import { Add, MoreVert } from "@material-ui/icons";
import { ITemplate } from "src/store/admin-settings/models/template.interface";
import { INumerated } from "src/store/properties/models/numerated.interface";
import {
  SearchInput,
  SearchInputType,
} from "src/components/ui/components/forms/inputs/search";
import { COLORS } from "src/components/ui/theme/variables/colors";
// import { templateService } from 'src/store/admin-settings/templates/template.service';
import { EditIcon, ArchiveIcon, MessageIcon } from "src/components/ui/icons";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { guid } from "@datorama/akita";
import { useHistory } from "react-router-dom";
import { RoutesEnum } from "src/components/routing/routes";

import styles from "./admin.module.scss";
import baseStyles from "src/styles/base.module.scss";
// import { useAdminSearch } from 'src/store/admin-settings/hooks/use-admin-search';
import { adminSettingsService } from "src/store/admin-settings/admin-settings.service";
import { TitleHolder } from "../properties/pages/list";
import { Fetcher } from "src/components/fetcher";
import { PagingType } from "src/store/common/models/common.interface";
import {
  IFetcherChildrenArgs,
  LoaderType,
} from "src/components/fetcher/model/fetcher.interface";
import { IPagedResponse } from "src/store/properties/models/paged-response.interface";

export const conditionalRowStyles = [
  {
    // actively renting & i % 2 === 0
    when: (row: INumerated & ITemplate) => row.i % 2 === 0,
    style: {
      backgroundColor: COLORS.BACKGROUND_GRAY,
    },
  },
];

const ITEM_HEIGHT = 48;

export const MoreOptions = ({ itemId, templateType }: any) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const moreOptions = [
    {
      name: "View Template",
      icon: "",
      onClick: () => history.push(`/admin/settings/templates/${itemId}`),
    },
    {
      name: "Edit Template",
      icon: <EditIcon />,
      onClick: () =>
        history.push(`/admin/settings/templates/${itemId}/edit`, {
          type: templateType,
        }),
    },
    {
      name: "Archive Template",
      icon: <ArchiveIcon />,
      onClick: () => adminSettingsService.deleteTemplate(itemId, templateType),
    },
  ];

  return (
    <div className={styles.moreOptionsBtn}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "22ch",
          },
        }}
      >
        {moreOptions.map((option) => (
          <MenuItem key={guid()} onClick={option.onClick}>
            <span className={styles.moreIcon}>{option.icon}</span>
            <span className={styles.moreName}>{option.name}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export enum TemplateType {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

interface ITemplatesTableProps {
  // templates: ITemplate[];
  templateType: TemplateType;
  isMobile?: boolean;
}

export const TemplatesTableHolder: React.FC<
  IFetcherChildrenArgs<any, any> & ITemplatesTableProps
> = ({ data , changeSearch, templateType, isMobile }) => {
  const [selectedCount] = useState(0);
  const history = useHistory();

  // const [, changeSearch] = useAdminSearch(
  //     templateType === TemplateType.EMAIL
  //         ? AdminSearchType.TEMPLATES_EMAIL
  //         : AdminSearchType.TEMPLATES_SMS
  // );

  // const prepearedTemplates = useMemo(() => {
  //     // if (Array.isArray(templates) && templates.length) {
  //     //     const sortedTemplate = templates.slice().sort((a: any, b: any) => b.id - a.id);

  //     //     return sortedTemplate;
  //     // }

  //     return templates;
  // }, [templates]);


  const columns = useMemo(() => {
    return [
      {
        name: "Templates name",
        selector: "name",
        sortable: true,
        minWidth: "100px",
        maxWidth: "300px",
        cell: (row: Partial<ITemplate>) => {
          return <TitleHolder>{row.name}</TitleHolder>;
        },
      },
      {
        name: "Subject",
        selector: "subject",
        sortable: true,
        minWidth: "150px",
        maxWidth: "300px",
        omit: templateType === TemplateType.SMS,
      },
      {
        name: "Last edit agent",
        selector: "lastEditAgentId",
        sortable: true,
        minWidth: "150px",
        cell: (row: Partial<ITemplate>) => {
          return <div>{row.lastEditAgent?.name}</div>;
        },
      },
      {
        name: "Last edit date",
        selector: "lastEditDate",
        sortable: true,
        minWidth: "150px",
        cell: (row: Partial<ITemplate>) => {
          return (
            <div>{`${moment(row.lastEditDate).format(
              "MM.DD.YYYY hh:mm"
            )}`}</div>
          );
        },
      },
      {
        name: "",
        selector: "",
        sortable: false,
        minWidth: "100px",
        maxWidth: "100px",
        cell: (row: Partial<ITemplate>) => (
          <MoreOptions itemId={row.id} templateType={templateType} />
        ),
      },
    ];
  }, [templateType]);

  const onSort = (
    column: IDataTableColumn<Partial<ITemplate> & INumerated>,
    sortDirection: "desc" | "asc"
  ) => {
    adminSettingsService.setSorting({
      type: sortDirection,
      field: column.selector as keyof ITemplate,
    });

    const field =
      templateType === TemplateType.EMAIL ? "emailTemplates" : "smsTemplates";

    adminSettingsService.fetchTemplates(templateType, field);
  };

  // const changeSearch = (q: string) => {
  //     setSearch(q);
  // }

  // const handleSelectionChange = (data: any) => {
  //     setSelectedCount(data.selectedCount);
  // }

  return (
    <Fragment>
      <Panel type={PanelType.EMPTY} additionalStyles={{ margin: "0 0 15px" }}>
        <div className={baseStyles.list_action_bar}>
          <div className={baseStyles.list_action_bar_left}>
            <SearchInput
              onChange={changeSearch}
              inputType={SearchInputType.PRIMARY}
              placeholderText="Search..."
            />
          </div>
        </div>
      </Panel>
      {selectedCount > 0 && (
        <Panel additionalStyles={{ margin: "-5px 0 22px" }}>
          <div className={styles.list_action_bar}>
            <div className={styles.list_action_bar_left}>
              {`${selectedCount} Selected`}
            </div>
          </div>
        </Panel>
      )}
      <Panel
        additionalStyles={
          isMobile
            ? {
                padding: "10px 10px",
                borderRadius: "10px 0 0 10px",
              }
            : { margin: "-5px 0" }
        }
      >
        <div className={styles.panelTop}>
          <Title
            icon={<MessageIcon />}
            iconStyle={{ fontSize: "14px", width: "30px", height: "30px" }}
            text="Templates List"
          />
          <div
            className={styles.addTemplateButton}
            onClick={() =>
              history.push(RoutesEnum.ADMIN_ADD_TEMPLATE, {
                type: templateType,
              })
            }
          >
            <span className={styles.addIcon}>
              <Add fontSize="small" />
            </span>
            <span>Add New Template</span>
          </div>
        </div>
        <Table<Partial<ITemplate>>
          // onSelectionChange={handleSelectionChange}
          // onRowClicked={onRowClicked}'
          pagination={false}
        //   totalRecordsNumber={data.total}
          columns={columns}
          conditionalRowStyles={conditionalRowStyles}
          onSort={onSort}
          // loading={isLoading}
          data={data.length > 0 && [...data[0]?.result, ...data[1]?.result]}
          highlightRow={["name"]}
        />
      </Panel>
    </Fragment>
  );
};

export const TemplatesTable: React.FC<ITemplatesTableProps> = ({
  templateType,
  isMobile,
}) => {
  const tt =
    templateType === TemplateType.EMAIL ? "emailTemplates" : "smsTemplates";

  const fetchEmailTemplates = (page: number, size: number, search: string) => {
    return Promise.all([
      adminSettingsService.fetchTemplates(templateType, tt, search),
      adminSettingsService.fetchTemplates(TemplateType.SMS, tt, search),
    ]);
  };

  return (
    <Fetcher<IPagedResponse<any>, any>
      pagingType={PagingType.CONTRACTS}
      loaderType={LoaderType.LOCAL}
      initParams={[templateType, "lastEditDate,desc", ""]}
      inititalState={{
        result: [],
        total: 0,
      }}
      action={fetchEmailTemplates}
    >
      {(props) => (
        <TemplatesTableHolder
          {...props}
          templateType={templateType}
          isMobile={isMobile}
        />
      )}
    </Fetcher>
  );
};
