import React, {useCallback, useState} from 'react';
import Styles from './search.module.scss';
import classnames from 'classnames';
import { SearchIcon } from 'src/components/ui/icons';

export enum SearchInputType {
    PRIMARY,
    SECONDARY,
    MAIN,
}

interface IProps {
    id?: string;
    placeholderText?: string;
    inputType?: SearchInputType;
    onChange?: (e: string) => void;
    inputStyles?: React.CSSProperties;
    value?: string;
    wrapperStyles?: React.CSSProperties;
}

export const SearchInput: React.FC<IProps> = ({
    id,
    placeholderText = 'Search...',
    inputType = SearchInputType.SECONDARY,
    onChange,
    inputStyles = {},
    wrapperStyles = {},
    value,
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const setFocused = useCallback(() => {
        setIsFocused(true);
    }, [setIsFocused]);

    const setBlurred = useCallback(() => {
        setIsFocused(false);
    }, [setIsFocused]);

    const searchInputStyles = classnames({
        [Styles.search_input_secondary]: inputType === SearchInputType.SECONDARY,
        [Styles.search_input_primary]: inputType === SearchInputType.PRIMARY,
        [Styles.search_input_main]: inputType === SearchInputType.MAIN,
    });

    const wrapperClass = classnames({
        [Styles.search_wrapper]: true,
        [Styles.search_wrapper_focused]: isFocused,
    })

    return (
        <div className={wrapperClass} style={wrapperStyles}>
            <input
                id={id}
                value={value}
                onFocus={setFocused}
                onBlur={setBlurred}
                onChange={(e) => {
                    if (onChange) {
                        onChange(e.target.value);
                    }
                }}
                placeholder={placeholderText}
                style={inputStyles}
                className={searchInputStyles}
            />
            <div className={Styles.search_icon}>
                <SearchIcon />
            </div>
        </div>
    );
}
