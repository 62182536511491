import { useEffect } from 'react'
import { useObservable } from '@libreact/use-observable'
import { adminSettingsQuery } from '../admin-settings.query';
// import { adminSettingsService } from '../admin-settings.service';
import { isEmpty } from 'lodash';
// import { IFollowUp } from '../models/followup.interface';
import { IAutoNitificaton } from '../models/autonotification.interface';

export type AutoNotificationHookTuple = [Partial<IAutoNitificaton>[], boolean, number];

export function useAutoNotification(): AutoNotificationHookTuple {
    const [autoNotifications] = useObservable(adminSettingsQuery.autoNotifications$);
    const [loading] = useObservable(adminSettingsQuery.isNotificationsLoading$);
    const [totalNotifications] = useObservable(adminSettingsQuery.totalNotifications$);

    useEffect(() => {
        if (isEmpty(autoNotifications) && !loading) {
            // adminSettingsService.fetchAutoNotifications();
        }
    }, [autoNotifications, loading]);

    return [autoNotifications, loading, totalNotifications];
}