import { createEntityStore, EntityState } from "@datorama/akita";

export enum SnackbarType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info'
}

export interface ISnackbar {
    id?: string;
    type: SnackbarType;
    text: string
}

export interface ISnackbarState extends EntityState {
    snackbars: Array<ISnackbar>;
}
const initialState: ISnackbarState = {
    snackbars: []
}

export const snackbarStore = createEntityStore<ISnackbarState>(initialState, {
    name: 'snackbars',
});