import { api, erApi } from "src/api/api.service";
import { defaultDataStorage } from "../data-storage/context";
import { InMemoryStrategy } from "../data-storage/strategies/in-memory.strategy";
import { AuthState } from "../user/models/auth-state.enum";
import { IUser } from "../user/models/user.interface";
import { authStore, ISigninDto } from "./auth.store";

export interface IConfirmNewPasswordDto {
    newPassword: string;
    token: string;
}

export async function refreshToken() {
    const refToken: string = defaultDataStorage.getStrategy().getItem('refToken')

    if (refToken) {
        const { data: { accessToken, refreshToken }} = await erApi
            .post('/public/auth/new-access-token', {
                refreshToken: refToken
            })
        if(accessToken && refreshToken){
            defaultDataStorage.getStrategy().setItem('token', accessToken)
            defaultDataStorage.getStrategy().setItem('refToken', refreshToken)
            return refreshToken;
        } else {
            defaultDataStorage.getStrategy().clear();

            authStore.update(state => ({
                ...state,
                authState: AuthState.UNAUTHORIZED,
                user: null
            }));
            return null;
        }
    }

    return null;
}

export async function signIn(signInDto: ISigninDto, rememberMe: boolean = true) {
    const response = await erApi({
            url: '/public/auth/sign-in',
            method: 'post',
            auth: {
                username: signInDto.email,
                password: signInDto.password,
            },
        });

    if (response.data?.accessToken) {
        authStore.update(state => ({
            ...state,
            user: response.data,
            authState: AuthState.SIGNED_IN,
            token: response.data.accessToken,
            refToken: response.data.refreshToken,
        }))

        if (!rememberMe) {
            defaultDataStorage.setStrategy(new InMemoryStrategy());
        }

        defaultDataStorage.getStrategy()
            .setItem('token', response.data.accessToken)
            .setItem('refToken', response.data.refreshToken)
            .setItem('user', response.data);
    }
}

export function signOut() {
    defaultDataStorage.getStrategy().clear();

    authStore.update(state => ({
        ...state,
        authState: AuthState.UNAUTHORIZED,
        user: null
    }));
}

export async function getUser () {
    authStore.update(state => ({
        ...state,
        authState: AuthState.FETCHING,
    }))

    const response = await api.get<IUser>('/auth/me');

    if (response?.ok) {
        authStore.update(state => ({
            ...state,
            user: response.data,
            authState: AuthState.SIGNED_IN,
            token: response.data.accessToken,
            refToken: response.data.refreshToken,
        }))
    } else {
        authStore.update(state => ({
            ...state,
            user: null,
            authState: AuthState.UNAUTHORIZED,
            token: null,
            refToken: null,
        }))
    }
}

export async function sendPasswordReset(email: string) {
    return await api.post('/public/auth/forgot-password/step-1', {email});
}

export async function confirmNewPassword(dto: IConfirmNewPasswordDto) {
    return await api.post('/public/auth/forgot-password/step-2', dto);
}


export const authService = {
    signIn,
    signOut,
    getUser,
    confirmNewPassword,
    sendPasswordReset,
};