import {guid, QueryEntity} from "@datorama/akita";
import {appointmentStore, AppointmentStore, IAppointmentsState, initialAppointmentFilters} from "./appointment.store";
import {KeyedNamedEntity} from "../properties/search/search-properties.query";
import {AppointmentType} from "./models/appointment.interface";
import moment from "moment";

export class AppointmentQuery extends QueryEntity<IAppointmentsState> {

    appointments$ = this.select(state => state.appointments);
    count$ = this.select(state => state.appointmentsCount);
    isLoading$ = this.select(state => state.loading);
    isExporting$ = this.select(state => state.isExporting);
    statistics$ = this.select(state => state.statistics);
    filters$ = this.select(state => state.filters);
    loadingSearchItems$ = this.select(state => state.loadingSearchItems);

    newAppointment$ = this.select(state => state.newAppointment);
    searchData$ = this.select(state => {
        return {
            ...state.searchData,
            agents: state.searchData.agents.filter(agent => !agent.isDeleted),
        };
    });
    isEditing$ = this.select(state => state.isEditing);

    filterData$ = this.select(state => state.filtersData);
    allAppliedFilters$ = this.select(state => {
        const filters: KeyedNamedEntity<any>[] = [];
        state.filters.properties?.forEach((item) => {
            filters.push({
                id: item.id,
                name: item.name,
                key: item.key,
            })
        });

        state.filters.agents?.forEach((item) => {
            filters.push({
                id: item.id,
                name: item.name,
                key: item.key,
            })
        });

        if (state.filters.type !== AppointmentType.Any) {
            filters.push({
                id: state.filters.type as any,
                name: `Appointment Type: ${state.filters.type}`,
                key: 'type'
            })
        }

        if (state.filters.from !== initialAppointmentFilters.from) {
            filters.push({
                id: guid(),
                name: `From: ${moment(state.filters.from).format('MMM DD, YYYY')}`,
                key: 'from'
            })
        }

        if (state.filters.to !== initialAppointmentFilters.to) {
            filters.push({
                id: guid(),
                name: `To: ${moment(state.filters.to).format('MMM DD, YYYY')}`,
                key: 'to'
            })
        }

        return filters;
    });

    properties$ = this.select(state => state.searchData.properties);
    propertiesTotal$ = this.select(state => state.searchData.propertiesTotal);
    propertiesLoading$ = this.select(state => state.searchData.propertiesLoading);
    searchList$ = this.select(state => state.searchList);
    searchTotal$ = this.select(state => state.searchTotal);
    searchLoading$ = this.select(state => state.searchLoading);

    constructor(protected calendarStore: AppointmentStore) {
        super(calendarStore);
    }
}

export const appointmentQuery = new AppointmentQuery(appointmentStore);
