import { createEntityQuery } from "@datorama/akita";
import { ISearchTenantsState, ITenantSearchCriteria, searchTenantsStore } from "./search.store";
import { KeyedNamedEntity } from "../../properties/search/search-properties.query";

const tsq = createEntityQuery<ISearchTenantsState>(searchTenantsStore);

export const allSelectedFilters$ = tsq.select<KeyedNamedEntity<keyof ITenantSearchCriteria>[]>(state => {
    const { selectedCriteria: { notificationMethod, property }} = state;
    const entities: KeyedNamedEntity<keyof ITenantSearchCriteria>[] = [];

    property.forEach(p => {
        entities.push({
            id: p.id,
            name: p.name,
            key: 'property'
        });
    });

    notificationMethod.forEach(m => {
        entities.push({
            id: m,
            name: `Notification method: ${m}`,
            key: 'notificationMethod'
        })
    });

    return entities;
})
export const loading$ = tsq.select(state => state.loading);
export const filterCriteria$ = tsq.select(state => state.selectedCriteria);
export const searchData$ = tsq.select(state => state.searchData);
export const appliedFilters$ = tsq.select(state => state.appliedFilters);

export const tenantsSearchQuery = {
    allSelectedFilters$,
    loading$,
    filterCriteria$,
    searchData$,
    appliedFilters$,
};