import { createEntityStore, EntityState } from "@datorama/akita";
import { PropertiesImportingState } from "../properties/properties.store";
import { ITenant } from "./models/tenant.interface";

export interface ITenantsState extends EntityState {
    tenants: ITenant[];
    tenantsCount: number;
    lastImported?: string | number | Date;
    selectedTenant: ITenant | null;
    selectedTenants: {
        data: ITenant[],
        total: number;
    };
    loading?: boolean;
    error?: any;
    messageHistory: any[];
    tenantsTotal: number;
    importingState: PropertiesImportingState;
}

const initialState: ITenantsState = {
    tenants: [],
    tenantsCount: 0,
    selectedTenant: null,
    selectedTenants: {
        data: [],
        total: 0,
    },
    messageHistory: [],
    tenantsTotal: 0,
    importingState: PropertiesImportingState.NOT_STARTED,
}

export const tenantsStore = createEntityStore<ITenantsState>(initialState, {
    name: 'tenants',
});