import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { Info } from '@material-ui/icons';
import {COLORS} from "../../../theme/variables/colors";

const HintIcon = styled.span`
    display: inline-block;
    border-radius: 100%;
    cursor: pointer;
    margin: 0 5px;
    &:hover {
      position: relative;
      z-index: 3;
    }
`;

const BackgroundLayout = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 2;
`;

const TOOLTIP_WIDTH = 260;

const TooltipModal = styled.div`
    position: fixed;
    min-height: 30px;
    width: ${TOOLTIP_WIDTH}px;
    padding: 8px;
    margin-left: 5px;
    border-radius: 12px;
    border-top-left-radius: 0;
    background: ${COLORS.WHITE};
    z-index: 3;
`;

interface IProps {
    children?: React.ReactNode;
}

const INITIAL_POSITION = {
    top: -1000,
    left: -1000,
}

export function HintInfo (props: IProps) {

    const [position, setPosition] = useState(INITIAL_POSITION);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const hide = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setPosition(INITIAL_POSITION);
    }, [setPosition]);

    const show = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setPosition({
            top: e.clientY + 20,
            left: e.clientX,
        });
    }, [setPosition]);

    useLayoutEffect(() => {
        const onScroll = () => {
            setPosition(INITIAL_POSITION);
        };
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [setPosition]);

    const preventEventBubbling = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    return <HintIcon onClick={show}>
        <Info />
        {position !== INITIAL_POSITION && <BackgroundLayout onClick={hide}/>}
        <TooltipModal onClick={preventEventBubbling}  ref={tooltipRef} style={{...position}}>
            {props.children}
        </TooltipModal>
    </HintIcon>
}
