import React from 'react';
import styled from 'styled-components';
import { Title } from './title';

interface IFormWrapper {
    dirrection: 'row' | 'column';
    divider?: boolean;
    noPanel?: boolean;
}

const FormWrapper = styled.div<IFormWrapper>`
    position: relative;
    display: flex;
    flex-direction: ${({ dirrection }) => `${dirrection}`};
    border-bottom: ${({ divider }) => divider && `1px solid #D3D9E7`};
    padding: ${({ noPanel }) => noPanel ? '26px' : '20px 0 0'};

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
`;

const TitleHolder = styled.div`
    display: flex;
    align-items: center;
    align-self: baseline;
    justify-content: space-between;
    margin: 0 0 16px;
    width: 205px;
    white-space: nowrap;

    @media screen and (max-width: 1200px) {
        margin: 0;
    }
`;

const RightButtonHolder = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;

    @media screen and (max-width: 1200px) {
        top: 20px;
    }
`;

const ChildrenHolder = styled.div<any>`
    padding: ${({ noPadding }) => noPadding ? 0 : '0 0 20px'};
    width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
    overflow: ${({ overflow }) => overflow ? overflow : 'hidden'};
`;

// const Divider = styled.div`
//     margin: 14px 0;
//     height: 1px;
//     width: 100%;
//     background-color: #D3D9E7;
// `;

interface IFormHolder {
    title?: string;
    icon?: React.ReactNode;
    iconStyle?: 'square' | 'circle';
    children?: React.ReactNode | React.ReactNode[];
    dirrection?: 'row' | 'column';
    withDivider?: boolean;
    noPanel?: boolean;
    noPadding?: boolean;
    labelStyles?: React.CSSProperties;
    fullWidth?: boolean;
    rightConnerButton?: React.ReactNode;
    overflow?: 'none' | 'hidden' | 'auto';
    hidden?: boolean; 
}

export const FormHolder: React.FC<IFormHolder> = ({ 
    icon, 
    title, 
    withDivider,
    noPanel,
    noPadding,
    overflow,
    children, 
    iconStyle,
    labelStyles,
    fullWidth,
    hidden,
    dirrection = 'column', 
    rightConnerButton }) => {
        
    if (hidden) {
        return null;
    }

    return (
        <FormWrapper dirrection={dirrection} divider={withDivider} noPanel={noPanel}>
            <TitleHolder style={{ ...labelStyles }}>
                { title ? <Title
                    icon={icon}
                    iconStyle={{ fontSize: '14px', width: '30px', height: '30px', borderRadius: `${iconStyle === 'circle' ? '50%' : '10px'}` }}
                    text={title || ''}
                /> : null }
            </TitleHolder>
            <RightButtonHolder>
                { rightConnerButton
                    ? rightConnerButton
                    : null }
            </RightButtonHolder>
            <ChildrenHolder noPadding={noPadding} fullWidth={fullWidth} overflow={overflow}>
                {children}
            </ChildrenHolder>
        </FormWrapper>
    );
}