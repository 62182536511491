
import React from 'react';
import { dataFetcherService } from 'src/store/data-fetcher/data-fetcher.service';
import { FetchStatus, IFetchRequest } from 'src/store/data-fetcher/data-fetcher.store';

export const FetcherClientContext = React.createContext<any>(null);

interface FetcherClientProviderProps {
    children: React.ReactChild;
}

export function FetcherClientProvider ({ children }: FetcherClientProviderProps) {
    return (
        <FetcherClientContext.Provider value={{ fetcherClient: dataFetcherService }}>
            {children}
        </FetcherClientContext.Provider>
    );
}

interface IFetcherClient {
    createRequest: <TData>(requestScope: string) => (request: IFetchRequest<TData>) => void
    deleteRequest: (requestScope: string) => void
    updateFetchStatus: (requestScope: string) => (fetchStatus: FetchStatus) => void
    setError: (requestScope: string) => (error: any) => void;
    setCache: (requestScope: string) => (cacheMap: unknown) => void;
    deleteItem: (requestScope: string) => (id: number, dataField?: string, idField?: string) => void;
    updateData: <T>(requestScope: string) => (id: number, data: T, dataField?: string, idField?: string) => void;
    runAction<T>(
        action: () => Promise<T | void>,
        requestKey: string,
        cache: boolean,
        initParams?: unknown[],
    ): Promise<T>
}

export function useFetcherClient() {
    const { fetcherClient } = React.useContext<{ fetcherClient: IFetcherClient }>(FetcherClientContext);

    return fetcherClient;
};