import {IOwner} from "../../owners/models/owner.interface";
import {IProperty} from "../../properties/models/property.interface";
import {ITenant} from "../../tenants/models/tenant.interface";
import {IStaff} from "../../staff/models/staff.inreface";

export enum AppointmentType {
    Meeting = 'MEETING',
    Showing = 'SHOWING',
    Other = 'OTHER',
    Any = 'ANY'
}

export interface IAppointment {
    id: number;
    agent?: IStaff;
    agents: IStaff[];
    datetimeFrom: string;
    datetimeTo: string;
    isDeleted: boolean;
    notes: string;
    ownerIds: number[];
    owners: IOwner[];
    properties: IProperty[];
    propertyIds: number[];
    prospectIds: number[];
    prospects: unknown[];
    staffs: unknown[];
    staffIds: number[];
    tenantIds: number[];
    tenants: ITenant[];
    title: string;
    type: AppointmentType;
    sendEmail: boolean;
    // FE-calculated
    agentNamesString: string;
    currentAgent?: string;
    portfolioNames?: string;
}