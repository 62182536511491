import { createEntityQuery } from "@datorama/akita";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { tenantsSearchQuery } from "./search/search.query";
import { getFilteredTenants } from "./tenants.filter";
import { ITenantsState, tenantsStore } from "./tenants.store";
// import {numerate} from "../../components/utils/fns/numerate";
// import { ITenant } from "./models/tenant.interface";

const tq = createEntityQuery<ITenantsState>(tenantsStore);

export const tenantsCount$ = tq.select(state => state.tenantsCount);

export const tenants$ = tq.select(state => state.tenants);

export const selectFilteredTenants$ = combineLatest([
    tenantsSearchQuery.appliedFilters$,
    tenants$,
]).pipe(
    map(([ searchCriteria, tenants ]) => getFilteredTenants({ searchCriteria, tenants }))
);

export const isLoading$ = tq.selectLoading();
export const lastImported$ = tq.select(state => state.lastImported);

export const currentTenant$ = tq.select(state => state.selectedTenant);

export const selectedTenants$ = tq.select(state => state.selectedTenants);

export const messageHistory$ = tq.select(state => state.messageHistory);

export const tenantsTotal$ = tq.select(state => state.tenantsTotal);

export const selectImportState$ = tq.select(state => state.importingState);

export const tenantsQuery = {
    tenants$,
    tenantsCount$,
    isLoading$,
    lastImported$,
    currentTenant$,
    selectedTenants$,
    messageHistory$,
    tenantsTotal$,
    selectFilteredTenants$,
    selectImportState$,
};