export const COLORS = {
    DARKEST_GRAY: '#363E59',
    DARK_GRAY: '#737E98',
    GRAY: '#AAB3C8',
    LIGHTER_GRAY: '#BEC4D3',
    LIGHTEST_GRAY: '#D3D9E7',
    DARK_BLUE: '#498BE2',
    LIGHTEST_BLUE: '#F3F8FF',
    BLUE: '#5384F9',
    LIGHT_BLUE: '#789EFF',
    // LIGHTEST_BLUE: '#b5caff',
    SECONDARY_BLUE: '#C3DDFF',
    WHITE: '#FFFFFF',
    BACKGROUND_GRAY: '#fafafb',
    LIGHT_YELLOW: '#FDF1DE',
    LIGHT_GREEN: '#F3FFE8',
    ORANGE: '#F5A322',
    DARK_ORANGE: '#FF6F43',
    GREEN: '#53BE42',
    TEXT_COLOR: '#363E59',
    SIDENAV_BG_COLOR: '#273F77',

    MAIN_BG_COLOR: '#F6F8FE',

    AGENT_COLOR: '#D59FFF',
    STAFF_COLOR: '#A4FFC3',
    ADMIN_COLOR: '#FED545',

    INPUT_BG: 'rgba(246, 248, 254, 0.8)',

    TABLE: {
        EXPANDED_ROW_BG: '#F8F8FF',
    }
}
