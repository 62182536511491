import { PropertiesImportingState, propertiesStore } from "./properties.store";
import { IProperty } from "./models/property.interface";
// import { ApiServiceInstance } from "../api/api-service";
import { IPagedResponse } from "./models/paged-response.interface";
import { snackbarService } from "../snackbar/snackbar.service";
import { SnackbarType } from "../snackbar/snackbar.store";
import { IPropertySearchFilters } from "./models/property-search-filters.interface";
import { searchPropertiesService } from "./search/search-properties.service";
import { PagingStore } from "../utils/paging-store";
import { SNACK_TIMEOUT, SYSTEM_MESSAGES } from "./properties.config";
import { ownersService } from "../owners/owners.service";
import { tenantsService } from "../tenants/tenants.service";
import { tenantsStore } from '../tenants/tenants.store';
import { api } from "src/api/api.service";
import { ownersStore } from "../owners/owners.store";
// import { TenantsService, tenantsService } from "../tenants/tenants.service";
// import { uniqBy } from "lodash";
// import { SelectionChanged } from "src/components/ui/components/table";

const CHUNKS_SIZE = 300;

const pagingStore = new PagingStore();

interface IGetDto {
    response: IPagedResponse<IProperty>;
    lastImported: string | number;
}

function normalizeSortName(sort: keyof IProperty): string {
    // if (sort === 'neighborhoodName') {
    //     return 'neighborhood.name';
    // }

    return sort;
}

function buildFilteredRequest(page: number, size: number): Partial<IPropertySearchFilters> {
    const filters: Partial<IPropertySearchFilters> = {page, size};
    const {
        targetRent, numberOfBathrooms, numberOfBedrooms, readyToLease,
    } = searchPropertiesService.getCriteria();
    if (readyToLease.length) {
        filters.readyToLease = readyToLease.join(',');
    }

    if (targetRent.from) {
        filters.targetRentF = targetRent.from;
    }

    if (targetRent.to) {
        filters.targetRentT = targetRent.to;
    }

    if (numberOfBathrooms.from) {
        filters.bathroomsNF = numberOfBathrooms.from;
    }

    if (numberOfBathrooms.to) {
        filters.bathroomsNT = numberOfBathrooms.to;
    }

    if (numberOfBedrooms.from) {
        filters.bedroomsNF = numberOfBedrooms.from;
    }

    if (numberOfBedrooms.to) {
        filters.bedroomsNT = numberOfBedrooms.to;
    }

    // if (search && search.length > 0) {
    //     filters.search = search;
    // }

    return filters;
}

export async function importProperties() {
    try {
        propertiesStore.update(state => ({
            ...state,
            importingState: PropertiesImportingState.IN_PROGRESS,
            properties: [],
        }));

        tenantsStore.update(state => ({
            ...state,
            tenants: [],
        }));

        ownersStore.update(state => ({
            ...state,
            owners: [],
        }));

        await api.post('/properties/import');

        await tenantsService.getTenants();
        await ownersService.fetchOwners(0, 9999);
        await getProperties();

        propertiesStore.update(state => ({
            ...state,
            importingState: PropertiesImportingState.SUCCESSFULLY_FINISHED
        }));

        snackbarService.createSnackbar({
            type: SnackbarType.INFO,
            text: SYSTEM_MESSAGES.IMPORT_SUCCESS,
        }, SNACK_TIMEOUT);
    } catch (err) {
        propertiesStore.update(state => ({
            ...state,
            importingState: PropertiesImportingState.ERROR
        }));

        snackbarService.createSnackbar({
            type: SnackbarType.ERROR,
            text: SYSTEM_MESSAGES.IMPORT_FAILED,
        }, SNACK_TIMEOUT);
    }
}

export async function fetchProperties(page: number = 0, size: number = 10, loading: boolean = false) {
    if (loading) {
        propertiesStore.setLoading(true);
    }

    pagingStore.setPagingData({page, size});

    const params = buildFilteredRequest(page, size);
    const sorting = searchPropertiesService.getSorting();

    params.sort = `${normalizeSortName(sorting.field)},${sorting.type}`;

    const response = await api.get<IGetDto>('/properties', {
        params,
    });

    if (response.ok) {
        const { data } = response;

        propertiesStore.update(state => ({
            ...state,
            properties: [
                ...state.properties,
                ...data.response.result,
            ],
            totalRecordsNumber: data.response.total,
            lastImported: data.lastImported,
        }));
    }

    propertiesStore.setLoading(false);

    return {
        total: response.data.response.total,
    };
}

export async function fetchPropertyById(id: number) {
    propertiesStore.setLoading(true);

    const response = await api.get<IProperty>(`/properties/${id}`);

    if (response.ok) {
        propertiesStore.update(state => ({
            ...state,
            selectedProperty: {
                ...response.data,
            },
            loading: false
        }));
    
        return response.data;
    }
}

export async function getProperties() {
    const { total } =  await fetchProperties(0, CHUNKS_SIZE, true);

    const chunks = total / CHUNKS_SIZE;

    for (let i = 1; i <= chunks; i++) {
        setTimeout(() => {
            fetchProperties(i, CHUNKS_SIZE);
        }, 0);
    }
}

export function setSelectedProperties(data: IProperty[]) {
    propertiesStore.update(state => ({
        ...state,
        selectedProperties: {
            data,
        },
    }));
}

export function resetSelectedproperties() {
    propertiesStore.update(state => ({
        ...state,
        selectedProperties: {
            data: [],
        },
    }));
}

export function resetMessageHistory() {
    propertiesStore.update(state => ({
        ...state,
        messageHistory: [],
    }));
}

export function resetProperties() {
    propertiesStore.update(state => {
        return {
            ...state,
            properties: [],
            totalRecordsNumber: 0,
        }
    });
}

export const propertiesService = {
    importProperties,
    fetchProperties,
    fetchPropertyById,
    getProperties,
    setSelectedProperties,
    resetSelectedproperties,
    resetMessageHistory,
    resetProperties,
};