import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Eventlabel, EventTitle } from './styled';

interface IEventHoledr {
    flexDirection?: string;
    dotColor?: string;
}

const EventHoledr = styled.div<IEventHoledr>`
    position: relative;
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : 'row'};
    align-items: ${({ flexDirection }) => flexDirection === 'row' ? 'center' : 'center'};
    padding: 4px 0 0 12px;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        left: 2px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${({ dotColor }) => dotColor};
    }
`;

export const MonthWrapperEvent = ({ eventProps }: any) => {
    const startLabel = moment(eventProps.event.start).format('hh:mm');
    const endLabel = moment(eventProps.event.end).format('hh:mm A');

    const handleKeyPress = (e: any) => eventProps.onKeyPress(e);
    const handleDoubleClick = (e: any) => eventProps.onDoubleClick(e);
    const handleClick = (e: any) => {
        eventProps.onSelect({
            id: eventProps.event.id
        });
    };

    if (eventProps.type === "time") {
        return React.cloneElement(eventProps.children);
    }

    return (
        <EventHoledr 
            dotColor={eventProps.event.agent.color}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            onKeyPress={handleKeyPress}>
            <Eventlabel>{ `${startLabel} - ${endLabel}` }</Eventlabel>
            <EventTitle color="#363E59" overflowText>{ eventProps.event.title }</EventTitle>
        </EventHoledr>
    );
}