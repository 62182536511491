import { useObservable } from "@libreact/use-observable";
import { fromEvent } from "rxjs";
import { distinctUntilChanged, pluck, switchMap, debounceTime } from "rxjs/operators";
import { useEffect } from "react";
import { KeyedNamedEntity } from "src/store/properties/search/search-properties.query";
import { IPaging } from "src/store/common/hooks/use-paging";
import { appointmentQuery } from "src/store/appointment/appointment.query";
import { appointmentService } from "src/store/appointment/appointment.service";
import { IAppointmentFilters } from "src/store/appointment/models/filters.interface";

export type AppointmentSearchHookTuple = [
    KeyedNamedEntity<keyof IAppointmentFilters>[], 
    IAppointmentFilters, 
];

export function useAppointmentsSearch (paging: IPaging): AppointmentSearchHookTuple {
    const [allAppliedFilters] = useObservable(appointmentQuery.allAppliedFilters$);
    const [filters] = useObservable(appointmentQuery.filters$);


    useEffect(() => {
        const input: HTMLElement = document.getElementById('appointments-search') as HTMLElement;

        const subscription = fromEvent(input, 'input').pipe(
            pluck('target', 'value'),
            distinctUntilChanged(),
            debounceTime(400),
            switchMap(search => appointmentService.fetchAppointments(paging.activePage, paging.rowsPerPage, search as string)),
        ).subscribe();

        return () => subscription.unsubscribe();
    }, [paging]);

    return [allAppliedFilters, filters as IAppointmentFilters];
}