import { transaction } from "@datorama/akita";
import autobind from "autobind-decorator";
import { api } from "src/api/api.service";
import { ISort } from "src/store/properties/search/search-properties.store";
// import { ApiServiceInstance } from "../../api/api-service";
import { IInitializable } from "../../portfolio/models/on-init.interface";
import { ITemplate, TemplateDto } from "../models/template.interface";
import { templateStore, TemplateStore } from "./template.store";

export class TemplateService implements IInitializable {
    isInitialized = false;

    constructor(
        private templateStore: TemplateStore,
    ) {}

    @transaction()
    async fetchTemplates() {
        this.templateStore.setLoading(true);

        const sorting = this.getSorting();

        const params = {
            sort: `${(sorting.field)},${sorting.type}`
        };

        const response = await api.get<ITemplate[]>('/templates', {
            params: {
                ...params
            }
        });

        if (response.ok) {
            this.templateStore.set(response.data);
        }

        this.templateStore.setLoading(false);
    }

    @transaction()
    async fetchTemplateById(templateId: number) {
        this.templateStore.setLoading(true);

        const response = await api.get<ITemplate>(`/templates/${templateId}`);

        if (response.ok) {
            this.templateStore.set([response.data]);
            this.templateStore.setActive(templateId);
        }

        this.templateStore.setLoading(false);
    }

    @transaction()
    async addTemplate(templateDto: Partial<TemplateDto>) {
        this.templateStore.setLoading(true);

        const dto = {
            ...templateDto,
            lastEditDate: new Date(),
        }

        const response = await api.post<ITemplate>('/templates', dto);

        if (response.ok) {
            this.templateStore.update(state => ({
                ...state,
                selectedTemplate: response.data,
            }))
        }

        this.templateStore.setLoading(false);
    }

    @autobind
    resetCurrentTemplate() {
        this.templateStore.update(state => ({
            ...state,
            selectedTemplate: null,
        }))
    }

    getSorting() {
        return this.templateStore.getValue().sort;
    }

    public setSorting(sorting: ISort<keyof ITemplate>) {
        this.templateStore.update(state => ({
            ...state,
            sort: sorting,
        }));
    }

    async init(): Promise<void> {
        this.fetchTemplates();
        this.isInitialized = true;
    }
}

export const templateService = new TemplateService(templateStore);