import { convertFromHTML } from "draft-js";

export function extractContent(htmlString: string) {
    // var span = document.createElement('span');
    // span.innerHTML = htmlString;

    const blocks = convertFromHTML(htmlString);
    const value = blocks.contentBlocks.map((block: any) => (!block.text.trim() && '\n') || block.text).join('\n');

    return value;
};