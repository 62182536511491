
import autobind from "autobind-decorator";
import { BehaviorSubject } from "rxjs";
import { IInitializable } from "src/store/portfolio/models/on-init.interface";
import { ISort } from "src/store/properties/search/search-properties.store";
import { ITemplate } from "../models/template.interface";
import { adminSearchStore, AdminSearchStore, IAdminSearchCriteria } from "./admin-search.store";

export class AdminSearchService implements IInitializable {
    isInitialized = false;

    constructor(private adminSearchStore: AdminSearchStore) {}

    get searchQ(): BehaviorSubject<string> {
        return this._searchQ;
    }

    private _searchQ: BehaviorSubject<string> = new BehaviorSubject<string>('');

    @autobind
    public changeSearchQ(q: string) {
        this._searchQ.next(q);
    }

    getSorting() {
        return this.adminSearchStore.getValue().sort;
    }

    public setSorting(sorting: ISort<keyof ITemplate>) {
        this.adminSearchStore.update(state => ({
            ...state,
            sort: sorting,
        }));
    }

    @autobind
    updateSelectionCriteria(data: Partial<IAdminSearchCriteria>) {
        this.adminSearchStore.update(state => ({
            ...state,
            selectedCriteria: {
                ...state.selectedCriteria,
                ...data,
            }
        }))
    }

    init() {
        this.isInitialized = true;
    }

    getCriteria() {
        return this.adminSearchStore.getValue().selectedCriteria;
    }
}

export const adminSearchService = new AdminSearchService(
    adminSearchStore,
);
