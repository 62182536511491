import { QueryEntity } from "@datorama/akita";
import { AutocompleteStore, autocompleteStore, IAutocompleteState } from "./autocomplete.store";

export class AutocompleteQuery extends QueryEntity<IAutocompleteState> {
    isLoading$ = this.selectLoading();

    //** Search
    searchList$ = this.select(state => state.searchList);
    searchTotal$ = this.select(state => state.searchTotal);
    searchType$ = this.select(state => state.searchType);
    searchStatus$ = this.select(state => state.searchStatus);
    resetInput$ = this.select(state => state.resetInput);

    searchData$ = this.select(state => state.searchData);

    constructor(protected store: AutocompleteStore) {
        super(store);
    }
}

export const autocompleteQuery = new AutocompleteQuery(autocompleteStore);