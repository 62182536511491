import React, { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Panel, PanelType } from "../../../ui/components/panel";
import { Header3 } from "../../../ui/components/headers";
import { SearchInput, SearchInputType } from "../../../ui/components/forms/inputs/search";
import { ImportIcon, SettingsIcon  } from "../../../ui/icons";
import { FavButton } from "../../../ui/components/buttons/fav-button";
import CssClasses from './list.module.scss';
import { Button, ButtonVariant } from "../../../ui/components/buttons/button";
import { ChipViewTypes, FiltersList } from "../../../ui/components/filters-list";
import { SelectionChanged, Table } from "../../../ui/components/table";
import { useObservable } from "@libreact/use-observable/dist";
import { propertiesQuery } from "../../../../store/properties/properties.query";
import { propertiesService } from "../../../../store/properties/properties.service";
import { IProperty } from "../../../../store/properties/models/property.interface";
import { INumerated } from "../../../../store/properties/models/numerated.interface";
import { COLORS } from "../../../ui/theme/variables/colors";
import styled from "styled-components";
import { Add, Cancel, CheckCircle, ChevronLeft, Delete, Settings } from "@material-ui/icons";
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Sidenav } from "../../../ui/components/sidenav";
import { KeyedNamedEntity, searchPropertiesQuery } from "../../../../store/properties/search/search-properties.query";
import { searchPropertiesService } from "../../../../store/properties/search/search-properties.service";
import { Form, FormFieldDividerType, FormType, InputType } from "../../../ui/components/forms/formBuilder/form";
import {
    ActivelyRentingState,
    IPropertiesSelectedCriteria
} from "../../../../store/properties/search/search-properties.store";
import { PropertiesImportingState } from "../../../../store/properties/properties.store";
import { appointmentService } from "../../../../store/appointment/appointment.service";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { ContentHolder } from "src/components/ui/main-layout/layout";

import baseStyles from 'src/styles/base.module.scss';
import { useProperties } from "src/store/properties/hooks/use-properties";
import { usePaging } from "src/store/common/hooks/use-paging";
import { PagingType } from "src/store/common/models/common.interface";
import { formatLastImportDate } from "src/components/utils/formatDate";

const conditionalRowStyles = [
    {
        // actively renting & i % 2 === 0
        when: (row: INumerated & IProperty) => (row.i % 2 === 0) && (row.readyToLease === 'Yes'),
        style: {
            borderLeft: `2px solid ${COLORS.BLUE}`,
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    },
    {
        // actively renting & i % 2 === 1
        when: (row: INumerated & IProperty) => (row.i % 2 === 1) && (row.readyToLease === 'Yes'),
        style: {
            borderLeft: `2px solid ${COLORS.BLUE}`,
        },
    },

    {
        // !actively renting & i % 2 === 1
        when: (row: INumerated & IProperty) => (row.i % 2 === 0) && (row.readyToLease === 'No'),
        style: {
            borderLeft: `2px solid ${COLORS.DARK_ORANGE}`,
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    },
    {
        // !actively renting & i % 2 === 1
        when: (row: INumerated & IProperty) => (row.i % 2 === 1) && (row.readyToLease === 'No'),
        style: {
            borderLeft: `2px solid ${COLORS.DARK_ORANGE}`,
        },
    },
];

const StatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${props => props.color};
    font-size: 10px;

    * {
        color: ${props => props.color};
    }
`;

export const TitleHolder = styled.div`
    font-family: 'ManropeSemibold';
`;

const columns = (clickHandler: (e: ChangeEvent<any>) => void) => [
    {
        name: 'Property',
        selector: 'unitAddress',
        sortable: true,
        minWidth: '150px',
        // maxWidth: '100px',
        cell: (row: IProperty) => {
            return (
                <TitleHolder onClick={clickHandler} id={String(row.id)}>{row.unitAddress}</TitleHolder>
            );
        }
    },
    {
        name: 'Unit Address Cont',
        selector: 'unitAddress2',
        sortable: true,
        minWidth: '130px',
        // maxWidth: '130px',
    },
    {
        name: 'Company',
        selector: 'company',
        sortable: true,
        minWidth: '60px',
        // maxWidth: '90px',
    },
    {
        name: 'Bedr. N',
        selector: 'bedrooms',
        sortable: true,
        // right: true,
        // minWidth: '90px',
        maxWidth: '90px',
        cell: (row: IProperty) => <div onClick={clickHandler} id={String(row.id)} style={{ width: '70%', textAlign: 'right' }}>
            {row.bedrooms}
        </div>
    },
    {
        name: 'Bathr. N',
        selector: 'bathrooms',
        sortable: true,
        // right: true,
        maxWidth: '90px',
        cell: (row: IProperty) => <div onClick={clickHandler} id={String(row.id)} style={{ width: '70%', textAlign: 'right' }}>
            {row.bathrooms}
        </div>
    },
    {
        name: 'Target Rent',
        selector: 'targetRent',
        sortable: true,
        minWidth: '120px',
        maxWidth: '120px',
        cell: (row: IProperty) => <div onClick={clickHandler} id={String(row.id)} style={{ width: '70%', textAlign: 'right' }}>
            {row.targetRent}
        </div>
    },
    {
        name: 'ER Manage',
        selector: 'doWeManage',
        sortable: true,
        minWidth: '120px',
        maxWidth: '120px',
        cell: (row: IProperty) => <div>
            {row.doWeManage && <span onClick={clickHandler} id={String(row.id)} style={{marginLeft: 5}}>Yes</span>}
            {!row.doWeManage && <span onClick={clickHandler} id={String(row.id)} style={{marginLeft: 5}}>No</span>}
        </div>
    },
    {
        name: 'Heat',
        selector: 'heat',
        sortable: true,
        // maxWidth: '100px'
    },
    {
        name: 'Electricity',
        selector: 'electricity',
        sortable: true,
        // maxWidth: '100px'
    },
    {
        name: 'Water',
        selector: 'water',
        sortable: true,
        // maxWidth: '100px'
    },
    {
        name: 'Ready To Lease',
        selector: 'readyToLease',
        sortable: true,
        // maxWidth: '100px',
        cell: (row: IProperty) => <div>
            {row.readyToLease === 'Yes' && <StatusWrapper onClick={clickHandler} id={String(row.id)} color={COLORS.BLUE}>
                <CheckCircle style={{ fontSize: '19px' }} /> <span style={{marginLeft: 5}}>Yes</span>
            </StatusWrapper>}
            {row.readyToLease === 'No' && <StatusWrapper onClick={clickHandler} id={String(row.id)} color={COLORS.DARK_ORANGE}>
                <Cancel style={{ fontSize: '19px' }} /> <span style={{marginLeft: 5}}>No</span>
            </StatusWrapper>}
        </div>
    },
];

interface IPropertiesLocation {
    from: string;
    selectType: string;
    backLink: string;
}

interface IProps extends RouteComponentProps {
}

export const List: React.FC<IProps> = ({ history }) => {
    const [properties, isLoading] = useProperties();
    const location = useLocation<IPropertiesLocation>();
    // const [selectedCount, setSelectedCount] = useState(0);
    const [paging, setPaging, pushLocation] = usePaging(PagingType.PROPERTIES);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedCriteria] = useObservable(searchPropertiesQuery.selectedCriteria$);
    const [allFilters] = useObservable(searchPropertiesQuery.allSelectedFilters$);
    // const [recordsNumber] = useObservable(propertiesQuery.totalRecordsNumber$);
    const [importState] = useObservable(propertiesQuery.importState$);
    const [lastImported] = useObservable(propertiesQuery.lastImported$);
    // const [selectedItems, setSelectedItems] = useState<IProperty[]>([]);
    const [isFilterOpened, setIsFilterOpened] = useState<boolean>(false);
    const [toggledClearRows] = useState(false);

    const [search, setSearch] = useState<string>('');

    const [selectedProperties] = useObservable(propertiesQuery.selectedProperties$);

    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        return () => {
            if (!history.location.pathname.includes('properties')) {
                removeAllSelection();
            }
        }
    }, [history.location.pathname]);

    const sidenavWidth = useMemo(() => {
        const DESKTOP_SIDENAV_WIDTH = 542;

        if(isMobile) {
            return '100%';
        } else {
            return DESKTOP_SIDENAV_WIDTH;
        }
    }, [isMobile]);

    const redirectUrl = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return params.get('redirectUrl');
    }, [location.search])

    //** Filter */

    const filteredProperties = useMemo(() => {
        if (search) {
            const searchQ = search.toLowerCase().trim();

            return properties.filter(property => {
                if (
                    property.unitAddress.toLowerCase().includes(searchQ) ||
                    property.unitAddress2.toLowerCase().includes(searchQ)
                ) {
                    return property;
                }
            });
        }

        return properties;
    }, [properties, search]);

    //** ---------------- Filter */

    const importData = useCallback(() => {
        propertiesService.importProperties()
    }, []);

    const onSelectionChange = useCallback((data: SelectionChanged<IProperty>) => {
        propertiesService.setSelectedProperties(data.selectedRows);
    }, []);

    const smallMargin: React.CSSProperties = {
        marginLeft: 10,
    };

    const onRowClicked = useCallback((e: IProperty) => {
        pushLocation(`/properties/${e.id}`, PagingType.PROPERTIES)
    }, [pushLocation]);

    const onCustomCellClick = useCallback((e: ChangeEvent<any>) => {
        pushLocation(`/properties/${e.currentTarget.id}`, PagingType.PROPERTIES);
    }, [pushLocation])

    const changeSearch = (q: string) => {
        // searchPropertiesService.changeSearchQ(q);

        setSearch(q);
    }

    const onFilterDelete = (item: KeyedNamedEntity<any>) => {
        searchPropertiesService.deleteItem(item);

        searchPropertiesService.applyFilters();
        // propertiesService.fetchProperties(0, paging.rowsPerPage);
    }

    // const onSort = (column: IDataTableColumn<IProperty & INumerated>, sortDirection: "desc" | "asc", rows: number) => {
    //     searchPropertiesService.setSorting({
    //         type: sortDirection,
    //         field: column.selector as keyof IProperty
    //     });

    //     propertiesService.fetchProperties(0, rows);
    // };

    const changePage = (page: number) => {
        // table component numerating start from 1, BE expects 0 as the first page
        setPaging(page, paging.rowsPerPage);
        // propertiesService.fetchProperties(page - 1, paging.rowsPerPage);
    }

    const changeRowsNumber = (rowPerPage: number, page: number) => {
        setPaging(page, rowPerPage);
        // propertiesService.fetchProperties(page - 1, rowPerPage);
    }

    const onClose = (cachedData?: IPropertiesSelectedCriteria) => {
        if (cachedData) {
            searchPropertiesService.updateSelectionCriteria(cachedData);
        }

        setIsFilterOpened(false);
    }

    const onApply = () => {
        // propertiesService.fetchProperties(0, paging.rowsPerPage);
        searchPropertiesService.applyFilters();

        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }


    const removeAllSelection = () => {
        searchPropertiesService.removeAllSelection();
        searchPropertiesService.applyFilters();
        // propertiesService.fetchProperties(0, paging.rowsPerPage);
        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const addPropertiesToAppointment = () => {
        appointmentService.addProperties(selectedProperties.data);

        propertiesService.resetSelectedproperties();

        history.push(location.state.from!);
    }

    return <ContentHolder noPadding smallPadding>
        {location.state?.from && <Link className="link_wrapper" to={location.state.from}>
            <ChevronLeft /> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
        </Link>}
        {
            !isMobile
                ? (
                    <Panel type={PanelType.EMPTY} additionalStyles={
                        isMobile ? { margin: '10px 0 0' } : {}
                    }>
                        <div className={CssClasses.header_wrapper}>
                            <Header3 style={{margin: 0, display: 'flex', alignItems: 'center'}}>
                                Properties
                                <span className={baseStyles.total}>
                                    ({filteredProperties.length})
                                </span>
                            </Header3>
                            <div className={CssClasses.last_imported}>
                                {formatLastImportDate(lastImported)}
                            </div>
                        </div>
                    </Panel>
                )
                : null
        }
        <Panel type={PanelType.EMPTY}>
            <div className={CssClasses.mobileButton}>
                {(!redirectUrl && isMobile) && <Button
                    onClick={importData}
                    icon={<ImportIcon />}
                    style={{ margin: '0 0 18px' }}
                    isLoading={importState === PropertiesImportingState.IN_PROGRESS}
                    loadingText="Importing Properties Data..."
                    btnVariant={ButtonVariant.PRIMARY}
                    text="Import"
                />}
            </div>
            <div className={CssClasses.list_action_bar}>
                <div className={CssClasses.list_action_bar_left}>
                    <SearchInput onChange={changeSearch}
                        inputType={SearchInputType.PRIMARY}
                        placeholderText="Search..."
                    />
                    <FavButton
                        onClick={() => setIsFilterOpened(true)} style={{marginLeft: 15}}
                        icon={<SettingsIcon/>}
                        badge={allFilters.length > 0 ? allFilters.length.toString() : undefined}
                    />
                </div>
                <div className={CssClasses.list_action_bar_right}>
                    {(!redirectUrl && !isMobile) && <Button
                        onClick={importData}
                        icon={<ImportIcon />}
                        style={smallMargin}
                        isLoading={importState === PropertiesImportingState.IN_PROGRESS}
                        loadingText="Importing Properties Data..."
                        btnVariant={ButtonVariant.PRIMARY}
                        text="Import"
                    />}
                    {!!redirectUrl && <Button
                        icon={<Add style={{fontSize: 14}}/>}
                        style={smallMargin}
                        onClick={addPropertiesToAppointment}
                        btnVariant={ButtonVariant.PRIMARY}
                        text={`Add to the list: ${selectedProperties.data.length}`}
                    />}
                </div>
            </div>
        </Panel>
        {
            isMobile
                ? (
                    <Panel type={PanelType.EMPTY} additionalStyles={{ margin: '0' }}>
                        <div className={CssClasses.last_imported_mobile}>
                            {formatLastImportDate(lastImported)}
                        </div>
                    </Panel>
                )
                : null
        }
        {allFilters.length > 0 && <Panel additionalStyles={
            isMobile ? { 
                margin: '0 0 15px',
                padding: '8px 0',
                width: '97.5%',
            } : {}
        }>
            <FiltersList filtersTitleName="" onDelete={onFilterDelete} items={allFilters}/>
        </Panel>}
        <Panel noPadding sideBorder>
            <Table<IProperty>
                contextActions={[
                    <div className={CssClasses.delete_properties}>
                        <Delete/> Delete Selected
                    </div>
                ]}
                // selectableRowSelected={(row: any) => row.isSelected}
                onSelectionChange={!!redirectUrl ? onSelectionChange : undefined}
                selectableRows={!!redirectUrl}
                onChangePage={changePage}
                totalRecordsNumber={filteredProperties.length}
                onRowClicked={(e: any) => !redirectUrl && onRowClicked(e)}
                paginationPerPage={paging.rowsPerPage}
                onChangeRowsNumber={changeRowsNumber}
                columns={columns(onCustomCellClick)}
                // onSort={onSort}
                clearedSelectionRows={toggledClearRows}
                conditionalRowStyles={conditionalRowStyles}
                loading={isLoading}
                data={filteredProperties}
                highlightRow={['unitAddress']}
                activePage={paging.activePage}
                paginationServer={false}
                paginationServerOptions={{
                    persistSelectedOnPageChange: false,
                    persistSelectedOnSort: false,
                }}
                noDataText={importState === PropertiesImportingState.IN_PROGRESS 
                    ? `The data will be displayed after importing` 
                    : ''}
            />
        </Panel>
        <Sidenav<IPropertiesSelectedCriteria>
            header={<Fragment>
                <Settings/>
                <span>
                    Filters
                </span>
            </Fragment>}
            footer={<Fragment>
                <Button onClick={removeAllSelection} btnVariant={ButtonVariant.OUTLINE}
                        text="Clear All"/>
                <Button onClick={onApply} style={{marginLeft: 12}} btnVariant={ButtonVariant.PRIMARY}
                        text="Apply"/>
            </Fragment>}
            width={sidenavWidth}
            isOpen={isFilterOpened}
            onClose={onClose}
            cachedData={selectedCriteria}
        >
            <Fragment>
                <div className={CssClasses.filters_wrapper}>
                    <FiltersList
                        filtersTitleName="Choose From The List Below"
                        type={ChipViewTypes.BLOCK}
                        items={allFilters}
                        onDelete={onFilterDelete}
                    />
                </div>
                <FormHolder>
                    <Form<IPropertiesSelectedCriteria>
                        type={FormType.DEFAULT}
                        data={selectedCriteria}
                        wrapperStyles={{
                            margin: '0 0 0 20px'
                        }}
                        onChange={searchPropertiesService.updateSelectionCriteria}
                        fields={{
                            readyToLease: {
                                index: 0,
                                label: 'Actively Renting',
                                labelDisplay: 'block',
                                height: 'auto',
                                fieldType: InputType.SELECT,
                                withDividers: FormFieldDividerType.BOTTOM,
                                labelStyles: {
                                    padding: 0,
                                },
                                inputStyles: {
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '128px',
                                },
                                groupStyles: {
                                    margin: isMobile ? '0 0 0 30px' : '7px 0 0 48px',
                                    flexDirection: 'row',
                                },
                                selectableItems: [{label: 'Yes', value: ActivelyRentingState.YES}, {
                                    label: 'No',
                                    value: ActivelyRentingState.NO
                                }],
                            },
                            targetRent: {
                                index: 4,
                                height: 'auto',
                                labelStyles: {
                                    padding: 0,
                                },
                                labelDisplay: 'block',
                                label: 'Target Rent',
                                fieldType: InputType.NUMBER_BETWEEN
                            },
                            numberOfBedrooms: {
                                index: 5,
                                height: 'auto',
                                labelStyles: {
                                    padding: '0',
                                },
                                label: 'N. Of Bedrooms',
                                labelDisplay: 'block',
                                withDividers: FormFieldDividerType.BOTTOM,
                                fieldType: InputType.NUMBER_BETWEEN
                            },
                            numberOfBathrooms: {
                                index: 6,
                                height: 'auto',
                                withDividers: FormFieldDividerType.BOTTOM,
                                labelStyles: {
                                    padding: '0',
                                },
                                labelDisplay: 'block',
                                label: 'N. Of Bathrooms',
                                fieldType: InputType.NUMBER_BETWEEN
                            },
                        }}
                    />
                </FormHolder>
            </Fragment>
        </Sidenav>
    </ContentHolder>
}