import React from "react";
import { SIZES } from "../theme/variables/sizes";
import { COLORS } from "../theme/variables/colors";
import { useObservable } from "@libreact/use-observable/dist";
import { uiQuery } from "../../../store/ui-storage/ui.query";
import { DeviceType } from "../../../store/ui-storage/ui.store";

export const MainContainer: React.FC<any> = (props) => {
    const [deviceType] = useObservable(uiQuery.deviceType$);

    const containerStyles: React.CSSProperties = {
        width: deviceType === DeviceType.MOBILE ? '100%' : 'calc(100% - 222px)',
        backgroundColor: COLORS.MAIN_BG_COLOR,
        marginTop: deviceType === DeviceType.MOBILE ? SIZES.HEADER_HEIGHT : 0,
    }

    return <div style={containerStyles}>
        {props.children}
    </div>
}
