import React, {useEffect} from "react";
import {RouteComponentProps} from "react-router-dom";
import {useObservable} from "@libreact/use-observable/dist";
import {propertyGroupQuery} from "../../../../../store/tenants/property-groups/property-group.query";
import {propertyGroupsService} from "../../../../../store/tenants/property-groups/property-group.service";
import {BlockingLoader} from "../../../../ui/components/loaders/blocking-loader";
import {ITab, Tabs} from "../../../../ui/components/tabs/tabs";
import {GroupDetails} from "./tabs/group-details";
import {RoutesEnum} from "../../../../routing/routes";
import {ChevronLeft} from "@material-ui/icons";
import {Panel, PanelType} from "../../../../ui/components/panel";
import {Header3} from "../../../../ui/components/headers";
import {Form, FormType, InputType} from "../../../../ui/components/forms/formBuilder/form";
import {IProperty} from "../../../../../store/properties/models/property.interface";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { KeyIcon } from "src/components/ui/icons";
import { ContentHolder, headMobileStyles, panelMobileStyles } from "src/components/ui/main-layout/layout";

import CssClasses from "./property-view.module.scss";
import baseStyles from 'src/styles/base.module.scss';
import { uiQuery } from "src/store/ui-storage/ui.query";
import { usePaging } from "src/store/common/hooks/use-paging";
import { PagingType } from "src/store/common/models/common.interface";
import { ContactHistory } from "src/components/ui/components/contact-history/contact-history";

interface IProps extends RouteComponentProps<{id: string}> {

}

export const PropertyView: React.FC<IProps> = ({match: {params: {id}}, location}) => {
    const [,, pushLocation] = usePaging(PagingType.PROPERTY_GROUPS);
    const [currentGroup] = useObservable(propertyGroupQuery.currentPropertyGroup$);
    // const [isLoading] = useObservable(propertyGroupQuery.isLoading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        const parsedId = parseInt(id);
        propertyGroupsService.fetchPropertyGroupById(parsedId);
        return () => {
            propertyGroupsService.resetCurrentTenant();
        }
    }, []);

    if (!currentGroup) {
        return <BlockingLoader />
    }

    const tabs: ITab[] = [
        {
            basePath: location.pathname,
            specificPath: '',
            children: <GroupDetails group={currentGroup!} isMobile={isMobile} />,
            tabName: 'Group Details',
            panelType: PanelType.EMPTY,
        },
        {
            basePath: location.pathname,
            specificPath: 'contact_history',
            children: <ContactHistory id={currentGroup.id} context="properties" field="properties" />,
            tabName: 'Contact History',
            panelType: PanelType.EMPTY,
        }
    ]

    return <ContentHolder noPadding smallPadding>
        <div style={{ cursor: 'pointer' }} className={baseStyles.link_wrapper} onClick={() => 
            pushLocation(`${RoutesEnum.TENANTS_LIST}?tab=property_groups`, PagingType.PROPERTY_GROUPS)
        }>
            <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
        </div>
        <Panel additionalStyles={
            isMobile ? headMobileStyles : { marginTop: 7 }
        } type={PanelType.EMPTY}>
            <div className={CssClasses.top_wrapper}>
                <Header3 style={{margin: '10px 0'}}>
                    Property Group Info
                </Header3>
            </div>
        </Panel>
        <Panel additionalStyles={
            isMobile ? panelMobileStyles : {}
        }>
            <FormHolder
                title="Tenant"
                icon={<KeyIcon />}>
                <Form<Partial<IProperty>>
                    type={FormType.DEFAULT}
                    data={currentGroup}
                    readOnly
                    fields={{
                        unitAddress: {
                            index: 0,
                            label: 'Title',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        createdDate: {
                            index: 1,
                            label: 'Date Of Creation',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        }
                    }}
                />
            </FormHolder>
        </Panel>
        <Tabs
            tabs={tabs}
        />
    </ContentHolder>
}