import React, { useEffect, useState } from 'react';
import { guid } from '@datorama/akita';
import { Modal } from 'src/components/ui/components/modal/modal';
import { ICalendarSettings, IStaffColor } from 'src/store/admin-settings/models/calendar.interface';
import { IStaff } from 'src/store/staff/models/staff.inreface';
import { ModalContent } from './modal-content';
import { UserColorItem } from './user-color-item';

export interface IColor {
    color: string;
    bgColor: string;
}

export const colorList: IColor[] = [
    {
        color: '#679186',
        bgColor: '#E6EEEC',
    },
    {
        color: '#454D66',
        bgColor: '#F9FBFF',
    },
    {
        color: '#5151E1',
        bgColor: '#E0E0FF',
    },
    {
        color: '#7149E2',
        bgColor: '#E5DCFF',
    },
    {
        color: '#53BE42',
        bgColor: '#F3FFE8',
    },
    {
        color: '#E43C58',
        bgColor: '#FFD9DF',
    },
    {
        color: '#498BE2',
        bgColor: '#C3DDFF',
    },
    {
        color: '#DF49E2',
        bgColor: '#FEDFFF',
    },
    {
        color: '#DAD873',
        bgColor: '#FFFFF1',
    },
    {
        color: '#F58765',
        bgColor: '#FFEAE3',
    },
    {
        color: '#42A8BE',
        bgColor: '#E4FAFF',
    },
    {
        color: '#DDACF5',
        bgColor: '#FBF4FF',
    },
];

export const COLOR_MAP_TO_BG = new Map<string, string>();
colorList.forEach(color => {
    COLOR_MAP_TO_BG.set(color.color, color.bgColor);
});

interface IRenderUsersColorView {
    usersList?: IStaff[];
    calendarSettings: Partial<ICalendarSettings>;
    onSave?: (staffColors: IStaffColor[]) => void;
}

export const RenderUsersColorView: React.FC<IRenderUsersColorView> = ({ usersList, calendarSettings, onSave }) => {
    const [open, setOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState<IStaffColor>();
    const [selectedColors, setSelectedColors] = useState<IStaffColor[]>([]);

    useEffect(() => {
        if (typeof onSave === 'function') {
            onSave(selectedColors as IStaffColor[]);
        }
    }, [selectedColors]);

    useEffect(() => {
        if (calendarSettings?.staffColors && usersList?.length) {
            const colors = calendarSettings?.staffColors;

            const sColors = usersList.map((user) => {
                const [currentColor] = colors.filter(item => item.staffId === user.id);

                return {
                    staffId: user.id,
                    color: currentColor?.color,
                };
            })

            setSelectedColors(sColors);

            if (typeof onSave === 'function') {
                onSave(sColors as IStaffColor[]);
            }
        }
    }, [calendarSettings, usersList]);

    const handleOpen = (user: IStaff, sColor: string) => {
        setSelectedColor({
            staffId: user.id,
            color: sColor,
        });
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleChangeColor = (staffId: number | string, color: string) => {
        setSelectedColor({
            staffId,
            color,
        });
    }

    const handleSave = () => {
        if (selectedColor) {
            if (!selectedColors.some(color => color.staffId === selectedColor.staffId)) {
                setSelectedColors(prevState => {
                    return [
                        ...prevState,
                        selectedColor,
                    ];
                });
            } else {
                setSelectedColors(() => selectedColors.map(color => {
                    if (color.staffId === selectedColor.staffId) {
                        return {
                            ...color,
                            ...selectedColor,
                        };
                    } else {
                        return color;
                    }
                }));
            }
        }

        setOpen(false);
    }

    if (usersList?.length) {
        return (
            <div>
                { usersList.map((user) =>
                    <UserColorItem
                        key={guid()}
                        user={user}
                        selectedColor={selectedColor as IStaffColor}
                        color={selectedColors?.filter((color: any) => color.staffId === user.id)[0] || {}}
                        handleOpen={handleOpen}
                        handleClose={handleClose} />) }
                    <Modal
                        open={open}
                        title="Choose Colour"
                        wrapperStyles={{ padding: '37px' }}
                        content={
                            <ModalContent
                                colorList={colorList}
                                selectedColor={selectedColor as IStaffColor}
                                onColorChange={handleChangeColor} />
                            }
                        onClose={handleClose}
                        onSave={handleSave} />
            </div>
        );
    }

    return null;
}