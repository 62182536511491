import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    TenantGroupSearchType,
    tenantGroupsService
} from "../../../../../store/tenants/groups/tenant-groups.service";
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { RoutesEnum } from "../../../../routing/routes";
import {
    ChevronLeft,
    Close,
} from "@material-ui/icons";
import { Panel, PanelType } from "../../../../ui/components/panel";
import {Header3 } from "../../../../ui/components/headers";
import { useObservable } from "@libreact/use-observable/dist";
import { tenantGroupsQuery } from "../../../../../store/tenants/groups/tenant-groups.query";
import { Form, FormType, InputType } from "../../../../ui/components/forms/formBuilder/form";
import { IGroup } from "../../../../../store/tenants/groups/models/group.interface";
// import { BlockingLoader } from "../../../../ui/components/loaders/blocking-loader";
import { ParseQueryParams } from "../../../../helpers/query-params-parser";
import { Button, ButtonVariant } from "../../../../ui/components/buttons/button";
import { ITab, Tabs } from "../../../../ui/components/tabs/tabs";
import { Dropdown } from "../../../../ui/components/dropdown/dropdown";
import { ITenant } from "../../../../../store/tenants/models/tenant.interface";
import { IPropertyGroup } from "../../../../../store/tenants/property-groups/models/property-group.interface";
import { isEmpty, uniqBy } from 'lodash';
import { HomeIcon, KeyIcon, NoteIcon, SaveIcon } from 'src/components/ui/icons';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
// import { SearchInput } from 'src/components/ui/components/autocomplete/autocomplete';
import styled from 'styled-components';
import { CustomAccordion } from 'src/components/ui/components/accordion/accordion';

import CssClasses from "./tenant-view.module.scss";
import baseStyles from 'src/styles/base.module.scss';
import { ContentHolder, headMobileStyles, panelMobileStyles } from 'src/components/ui/main-layout/layout';
import { uiQuery } from 'src/store/ui-storage/ui.query';
import { guid } from '@datorama/akita';
import { CustomAutocomplete } from 'src/components/ui/components/autocomplete/custom-autocomplete';
import { SearchType } from 'src/store/autocomplete/models/autocomplete.interface';
import { searchUrlMap } from 'src/store/autocomplete/autocomplete.store';
import { EMPTY_STRING_PLACEHOLDER } from 'src/store/tenants/tenants.config';

interface IProps extends RouteComponentProps<{ id: string }> {
}

enum Mode {
    EDITING = 'EDITING',
    CREATING = 'CREATING',
    VIEW = 'VIEW',
}

const AccordionContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
    padding: 0 0 0 52px;
    width: 70%;
    font-size: 14px;
    color: #737E98;
    text-align: left;

    & > div {
        flex: 1;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        width: 100%;
        font-size: 12px;
    }
`;

const renderTenantsTable = (tenants: ITenant[], onRemove: (id: number) => void) => {
    const uniqTenants = uniqBy(tenants, 'id');

    return (
        <Fragment>
            <div className={CssClasses.tenant_header}>
                <KeyIcon />
                <span>Tenants</span>
            </div>
            <div className={CssClasses.tenants_table}>
                {uniqTenants.map(t => <div key={t.id} className={CssClasses.tenant_row}>
                    <div className={CssClasses.tenant_row_content}>
                        <div>{t.firstName} {t.lastName}</div>
                        <div>{t.email || EMPTY_STRING_PLACEHOLDER}</div>
                        <div>{t.mobilePhone || t.homePhone || EMPTY_STRING_PLACEHOLDER}</div>
                    </div>
                    <div>
                        <div className={CssClasses.tenant_remove} onClick={() => onRemove(t.id)}>
                            <Close />
                        </div>
                    </div>
                </div>)}
            </div>
        </Fragment>
    );
}

interface IRenderPropertyGroups {
    propertyGroups: IPropertyGroup[];
    onRemove: (id: number) => void
}

const RenderPropertyGroups: React.FC<IRenderPropertyGroups> = ({ propertyGroups, onRemove }) => {
    if (!propertyGroups.length) {
        return null;
    }

    const data: any = uniqBy(propertyGroups.map(item => ({
        ...item,
        property: `${item.property}`
    })), 'id');

    return <Fragment>
        <div className={CssClasses.property_header}>
            <HomeIcon />
            <span>Property Group</span>
        </div>
        <div className={CssClasses.property_content}>
            {data?.map((group: any) => {
                return (
                    <CustomAccordion
                        key={guid()}
                        data={group} 
                        onRemove={onRemove}
                        renderItem={(email: any, index: number) => {
                        return (
                            <AccordionContent key={guid()}>
                                <div>{ group?.tenants[index] }</div>
                                <div>{ email }</div>
                                <div>{ group?.phoneNumbers[index] || EMPTY_STRING_PLACEHOLDER }</div>
                            </AccordionContent>
                        )
                    }} />
                )
            })}
        </div>
    </Fragment>
}

export function TenantGroupView(props: IProps) {
    const {match: {params = {id: undefined}}, history, location: {search, pathname}} = props;
    // const [open, setOpen] = useState(false);

    const [group] = useObservable(tenantGroupsQuery.currentGroup$);
    // const [isLoading] = useObservable(tenantGroupsQuery.isLoading$);
    // const [loadingSearchItems] = useObservable(tenantGroupsQuery.loadingSearchItems$);
    // const [currentTenants] = useObservable(tenantGroupsQuery.currentGroupTenants$);
    const [selectedData] = useObservable(tenantGroupsQuery.selectedItems$);
    const [isMobile] = useObservable(uiQuery.isMobile$);
    // const [mergedTenants] = useObservable(tenantGroupsQuery.mergedTenants$);

    const location = useLocation<{
        from?: string;
        selectType?: SearchType;
    }>();
    const [, setSearchValue] = useState<string>('');

    const [selectedOption, setSelectOption] = useState<SearchType>(location?.state?.selectType || SearchType.PROPERTY_GROUPS);

    const { id } = params;

    const groupId = id ? id : 'new';

    const mode = useMemo(() => {
        if (groupId === 'new') {
            return Mode.CREATING;
        } else {
            const {mode} = ParseQueryParams<{ mode: string }>(search);
            return mode ? mode : Mode.VIEW;
        }
    }, [groupId, search]);

    const returnBackLink = useMemo(() => `${location.pathname}`, [location]);

    const smallMargin: React.CSSProperties = {
        marginRight: '5px'
    };

    useEffect(() => {
        if (groupId && groupId !== 'new') {
            if (isEmpty(group)) {
                // tenantGroupsService.fetchGroupById(parseInt(groupId));
            }
        }
    }, [groupId]);

    // if (isLoading) {
    //     return <BlockingLoader/>
    // }

    const onSelect = (item: any) => {
        setSearchValue('');

        tenantGroupsService.selectTenantsOrGroups(selectedOption, item);
    }

    const onDataChange = (data: Partial<IGroup>) => {
        tenantGroupsService.updateGroup(data);
    }

    // const handleAutocompleteSearch = useCallback(async (value: string) => {
    //     setSearchValue(value);

    //     // await tenantGroupsService.fetchSearchTenants({
    //     //     page: 0,
    //     //     size: CHUNK_SIZE,
    //     //     type: selectedOption,
    //     //     search: value,
    //     // })
    // }, [selectedOption]);

    // const handleAutocompleteBlur = () => {
    //     tenantGroupsService.resetSearch();
    // }

    // const onDelete = async () => {
    //     await tenantGroupsService.delete(parseInt(groupId!));
    //     history.push('/profiles/tenants?tab=custom_tenant_groups');
    // }

    const formChildren = <div>
        <Panel noPadding additionalStyles={
            isMobile ? {
                margin: '-16px 0 10px',
                borderRadius: '0 0 0 10px',
            } : { margin: '-6px 0 10px' }
        }>
            <FormHolder
                title="Members List"
                noPanel
                noPadding
                overflow="none"
                icon={<KeyIcon />}>
                <Form<any>
                    type={FormType.DEFAULT}
                    data={{
                        group: selectedOption,
                        search: '',
                    }}
                    wrapperStyles={
                        isMobile ? {
                            margin: 0,
                        } : {
                            margin: '0 0 0 44px',
                        }
                    }
                    onChange={onDataChange}
                    fields={{
                        group: {
                            index: 0,
                            label: '',
                            labelDisplay: 'none',
                            height: 'auto',
                            groupStyles: {
                                margin: '0 0 28px',
                            },
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Dropdown
                                    onChange={(e) => {
                                        setSelectOption(e.target.value);
                                    }}
                                    value={selectedOption}
                                    menuItems={
                                        [
                                            {value: SearchType.TENANTS, content: <div>Tenants</div>},
                                            {value: SearchType.PROPERTY_GROUPS, content: <div>Property Group</div>}
                                        ]
                                    }
                                />
                                <div className={CssClasses.view_all}>
                                    <div className={CssClasses.see_all_icon}>
                                        {selectedOption === SearchType.TENANTS ? <KeyIcon /> : <HomeIcon />}
                                    </div>
                                    {
                                        selectedOption === SearchType.TENANTS 
                                            ? <div onClick={() => history.push(`${RoutesEnum.TENANTS_LIST}?backLink=${returnBackLink}`, 
                                                { 
                                                    from: mode === Mode.CREATING ? '/profiles/tenants/groups/new' : `/profiles/tenants/groups/${groupId}/edit?mode=EDITING`, 
                                                    selectType: 'tenants', 
                                                    backLink: returnBackLink,
                                                })}>
                                                    See All Tenants
                                                </div>
                                            : <div onClick={() => history.push(`${RoutesEnum.TENANTS_LIST}?tab=property_groups&backLink=${returnBackLink}`, 
                                                { 
                                                    from: mode === Mode.CREATING ? '/profiles/tenants/groups/new' : `/profiles/tenants/groups/${groupId}/edit?mode=EDITING`, 
                                                    selectType: 'propertyGroups', 
                                                    backLink: returnBackLink,
                                                })}>
                                                See All Property Groups
                                            </div>
                                    }
                                </div>
                            </div>
                        },
                        search: {
                            index: 1,
                            label: '',
                            labelDisplay: 'none',
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <CustomAutocomplete
                                id="search"
                                url={searchUrlMap.get(selectedOption)}
                                searchType={selectedOption}
                                labelField="name"
                                changeHandler={onSelect}
                                placeholder="Search"
                            />
                        },
                    }}/>
            </FormHolder>
            <div>
                <div className={CssClasses.tenant_selected}>
                    Selected ({[
                        // ...selectedData.propertyGroups.flatMap(group => group.tenants),
                        ...selectedData.propertyGroups,
                        ...selectedData.tenants,
                    ].length})
                </div>

                {
                    selectedData.propertyGroups.length > 0 && 
                        <RenderPropertyGroups 
                            propertyGroups={selectedData.propertyGroups} 
                            onRemove={(id: number) => tenantGroupsService.unselectTenantOrGroup(TenantGroupSearchType.BY_PROPERTY_GROUPS, id)}/>
                }
                {
                    selectedData.tenants.length > 0 && renderTenantsTable(
                        selectedData.tenants, (id: number) => tenantGroupsService.unselectTenantOrGroup(TenantGroupSearchType.BY_TENANTS, id))
                }
            </div>
        </Panel>
        <Panel noPadding additionalStyles={
            isMobile ? panelMobileStyles : { margin: 0 }
        }>
            <FormHolder
                title="Notes"
                noPanel
                fullWidth
                dirrection="row"
                icon={<NoteIcon />}>
                <Form<IGroup>
                    type={FormType.DEFAULT}
                    data={group}
                    // readOnly={mode === Mode.VIEW}
                    onChange={onDataChange}
                    fields={{
                        notes: {
                            index: 0,
                            label: 'Notes',
                            placeholder: 'Notes',
                            fieldType: InputType.STRING,
                        }
                    }}
                />
            </FormHolder>
        </Panel>
    </div>;

    const tabs: ITab[] = [
        {
            basePath: pathname,
            specificPath: '',
            tabName: 'Group Details',
            children: formChildren,
            panelType: PanelType.EMPTY,
        },
    ];

    const isValid = (val: string) => {
        if (!val) {
            return false
        }

        return true;
    }

    const saveOrUpdate = useCallback(async () => {
        if (isValid(group.name)) {
            await tenantGroupsService.saveOrUpdate(Number(groupId));

            tenantGroupsService.resetCurrentGroup();

            history.push('/profiles/tenants?tab=custom_tenant_groups');

            // if (mode === Mode.CREATING) {
            //     history.push('/profiles/tenants?tab=custom_tenant_groups');
            // } else {
            //     history.push(`/profiles/tenants/groups/${groupId}`);
            // }
        }
    }, [group]);

    return <ContentHolder noPadding smallPadding>
            {/* <Link className={baseStyles.link_wrapper} to={`${RoutesEnum.TENANTS_LIST}?tab=custom_tenant_groups`}>
                <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
            </Link> */}
            <div className={baseStyles.link_wrapper} onClick={() => {
                tenantGroupsService.resetCurrentGroup();
                history.push(`${RoutesEnum.TENANTS_LIST}?tab=custom_tenant_groups`);
            }}>
                <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
            </div>
            <Panel additionalStyles={
                isMobile ? headMobileStyles : { marginTop: 7 }
            } type={PanelType.EMPTY}>
                <div className={CssClasses.top_wrapper}>
                    <Header3 style={{margin: '10px 0'}}>
                        { mode === Mode.EDITING ? 'Edit' : 'Add'  } Custom Tenant Group
                    </Header3>
                    {
                        !isMobile
                            ? (
                                <div className={CssClasses.actionBarHolder}>
                                    <Button
                                        btnVariant={ButtonVariant.PRIMARY}
                                        onClick={saveOrUpdate}
                                        style={smallMargin}
                                        icon={<SaveIcon />}
                                        text={mode === Mode.CREATING ? 'Save' : 'Update'}
                                    />
                                </div>
                            )
                            : null
                    }
                </div>
            </Panel>
            <Panel additionalStyles={
                isMobile ? panelMobileStyles : {}
            }>
                <FormHolder
                    title="Tenant Group"
                    icon={<KeyIcon />}>
                    <Form<Partial<IGroup>>
                        type={FormType.DEFAULT}
                        data={group}
                        // readOnly={mode === Mode.VIEW}
                        onChange={onDataChange}
                        fields={{
                            name: {
                                label: 'Title',
                                placeholder: 'Title',
                                labelDisplay: 'block',
                                labelStyles: {
                                    width: '170px',
                                },
                                fieldType: InputType.STRING,
                            },
                            // createdDate: (group.createdDate && mode === Mode.VIEW) ? {
                            //     fieldType: InputType.STRING,
                            //     label: 'Date Of Creation',
                            //     readonly: true,
                            // } : undefined,
                        }}
                    />
                </FormHolder>
            </Panel>
            <Tabs
                noPadding
                tabs={tabs}
            />
            {
                isMobile
                    ? (
                        <Panel type={PanelType.EMPTY}>
                            <div className={baseStyles.save_bar_mobile}>
                                <Button
                                    btnVariant={ButtonVariant.PRIMARY}
                                    onClick={saveOrUpdate}
                                    style={smallMargin}
                                    icon={<SaveIcon />}
                                    text={mode === Mode.CREATING ? 'Save' : 'Update'}
                                />  
                            </div>
                        </Panel>
                    ) : null
            }
        </ContentHolder>
}