import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {IPropertyGroup} from "./models/property-group.interface";
import {ISelectableItem} from "../../properties/models/named-entity.interface";
import {IProperty} from "../../properties/models/property.interface";
import { ISort } from "src/store/properties/search/search-properties.store";

export interface IPropertyGroupState extends EntityState {
    groups: IPropertyGroup[];
    groupsCount: number;
    selectableGroups: ISelectableItem[];
    currentGroup: IProperty | null;
    sort: ISort<keyof IPropertyGroup>;
    groupsTotal: number;
}

const initialState: IPropertyGroupState = {
    groups: [],
    groupsCount: 0,
    loading: true,
    selectableGroups: [],
    currentGroup: null,
    sort: {
        field: 'unitAddress',
        type: 'desc'
    },
    groupsTotal: 0,
}

@StoreConfig({ name: 'property_groups' })
export class PropertyGroupsStore extends EntityStore<IPropertyGroupState> {
    constructor() {
        super(initialState);
    }
}

export const propertyGroupsStore = new PropertyGroupsStore();
