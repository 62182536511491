import React, { useMemo } from "react";
import { useLocation, Link } from "react-router-dom";
import { Panel, PanelType } from "../panel";
import classNames from "classnames";
import { ParseQueryParams } from "../../../helpers/query-params-parser";
import CssClasses from './tabs.module.scss';
export interface ITab {
    basePath: string;
    specificPath: string;
    tabName: string | React.ReactNode;
    activeClassName?: string;
    children?: React.ReactNode;
    panelType?: PanelType;
    linkStyle?: React.CSSProperties;
    additionalStyles?: React.CSSProperties;
    tabStyles?: React.CSSProperties;
    wrapperStyles?: React.CSSProperties;
    hidden?: boolean;
    withBorder?: boolean;
    disabled?: boolean;
}

interface IProps {
    tabs: ITab[];
    noPadding?: boolean;
}

export function Tabs(props: IProps) {
    const location = useLocation();

    const {tabs, noPadding = false} = props;

    const queryParams = ParseQueryParams<{ tab: string }>(location.search);

    const currentPath = `${location.pathname}?tab=${queryParams.tab || ''}`;
    const componentsToPathsMap: Map<string, React.ReactNode> = useMemo(() => {
        const map = new Map<string, React.ReactNode>()
        tabs.forEach(tab => {
            let pathName = `${tab.basePath}?tab=${tab.specificPath || ''}`;
            map.set(pathName, tab.children);
        });
        return map;
    }, [tabs]);

    const activeIdx = useMemo(() => {
        return tabs.findIndex(tab => {
            const activePath = currentPath.split('=')[1];
            return tab.specificPath.includes(activePath);
        })
    }, [currentPath, tabs]);

    return <div className={CssClasses.tabs}>
        <div className={CssClasses.tabs_wrapper} style={tabs[activeIdx].wrapperStyles}>
            {tabs.map((tab) => {
                let pathName = tab.basePath;

                const isActive = tabs[activeIdx].specificPath === tab.specificPath;

                pathName += `?tab=${tab.specificPath || ''}`;

                const klass = classNames({
                    [CssClasses.tab]: true,
                    [CssClasses.active_tab]: pathName === currentPath,
                }, {
                    [tab.activeClassName as string]: pathName === currentPath,
                })

                if (tab.hidden) {
                    return null;
                }

                return (
                    <Link
                        to={pathName}
                        className={klass}
                        style={{
                            pointerEvents: tab.disabled ? "none" : "auto",
				            opacity: tab.disabled ? "0.2" : isActive ? "1" : "0.6",
                            ...tabs[activeIdx].tabStyles,
                            borderTop: tab.withBorder ? '4px solid #FF6F43' : 'none',
                        }}
                        key={`tab_title_${pathName}`}
                    >
                        {tab.tabName}
                    </Link>
                )
            })}
        </div>
        <Panel type={tabs[activeIdx].panelType} noPadding={noPadding} additionalStyles={{
                ...tabs[activeIdx].additionalStyles,
                borderTopLeftRadius: 0
            }}>
            {componentsToPathsMap.get(currentPath) || ''}
        </Panel>
    </div>
}