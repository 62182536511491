import React from 'react';
import moment from 'moment';
import { EventHoledr, Eventlabel, EventTitle } from './styled';

export const CustomMonthEvent = ({ event }: any) => {
    const startLabel = moment(event.event.start).format('hh:mm');
    const endLabel = moment(event.event.end).format('hh:mm A');

    return (
        <EventHoledr>
            <Eventlabel>{ `${startLabel} - ${endLabel}` }</Eventlabel>
            <EventTitle>{ event.title }</EventTitle>
        </EventHoledr>
    );
}