import { createEntityStore, EntityState, guid } from "@datorama/akita";
import { INamedEntity } from "../models/named-entity.interface";
import { IProperty } from "../models/property.interface";

export interface IPropertiesSearchData {
    // amenities: INamedEntity[];
    cities: INamedEntity[];
    // neighborhoods: INamedEntity[];
}

export interface ISort<T = string> {
    field: T;
    type: 'asc' | 'desc';
}

export enum ActivelyRentingState {
    YES = 'YES',
    NO = 'NO',
}

export interface IPropertiesSelectedCriteria {
    readyToLease: ActivelyRentingState[];
    city: INamedEntity[];
    // neighborhood: INamedEntity[];
    // amenities: INamedEntity[];
    numberOfBedrooms: INumberBetween;
    numberOfBathrooms: INumberBetween;
    targetRent: INumberBetween;
    // search: string;
}

export interface ISearchPropertiesState extends EntityState {
    selectedCriteria: IPropertiesSelectedCriteria;
    appliedFilters: IPropertiesSelectedCriteria;
    searchData: IPropertiesSearchData;
    sort: ISort<keyof IProperty>;
}

export interface INumberBetween {
    id?: string;
    from: number | string | null;
    to: number | string | null;
}

export const searchPropertiesInitialState: ISearchPropertiesState = {
    searchData: {
        cities: [],
    },
    selectedCriteria: {
        readyToLease: [],
        city: [],
        numberOfBedrooms: {
            id: guid(),
            from: null,
            to: null
        },
        numberOfBathrooms: {
            id: guid(),
            from: null,
            to: null
        },
        targetRent: {
            id: guid(),
            from: null,
            to: null
        },
    },
    appliedFilters: {
        readyToLease: [],
        city: [],
        numberOfBedrooms: {
            id: guid(),
            from: null,
            to: null
        },
        numberOfBathrooms: {
            id: guid(),
            from: null,
            to: null
        },
        targetRent: {
            id: guid(),
            from: null,
            to: null
        },
    },
    sort: {
        type: 'desc',
        field: 'unitAddress'
    }
}

export const searchPropertiesStore = createEntityStore<ISearchPropertiesState>(searchPropertiesInitialState, {
    name: 'search_properties',
});