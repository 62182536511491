import {QueryEntity} from "@datorama/akita";
import {DeviceType, IUiState, uiStore, UiStore} from "./ui.store";

export class UiQuery extends QueryEntity<IUiState> {

    deviceType$ = this.select(state => state.deviceType);
    isSidenavOpen$ = this.select(state => state.isSidenavOpen);
    currentRouteName$ = this.select(state => state.currentRouteName);
    isMobile$ = this.select(state => state.deviceType !== DeviceType.DESKTOP);

    constructor(protected store: UiStore) {
        super(store);
    }
}

export const uiQuery = new UiQuery(uiStore);
