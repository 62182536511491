import { get } from "lodash";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ILocationState } from "../models/common.interface";

export const ROWS_PER_PAGE = 10;
export const START_PAGE = 0;

export interface IPaging {
    activePage: number;
    rowsPerPage: number;
}

export type PagingHookTuple = [IPaging, (page: number, rows: number) => void, (url: string, id: string, from?: string) => void];

export function usePaging (id: string): PagingHookTuple {
    const history = useHistory();
    const location = useLocation<ILocationState>();
    const [activePage, setActivePage] = useState(get(location, `state[${id}].activePage`, START_PAGE));
    const [rowsPerPage, setRowsPerPage] = useState(get(location, `state[${id}].rowsPerPage`, ROWS_PER_PAGE));

    const setPaging = (page: number, rows: number) => {
        setActivePage(() => page - 1);
        setRowsPerPage(() => rows);
    }

    const pushLocation = (url: string, id: string, from: string = '') => {
        history.push(url, {
            from,
            pagingType: id,
            [id]: {
                activePage,
                rowsPerPage,
            },
        })
    }

    return [
        {
            activePage,
            rowsPerPage,
        },
        setPaging,
        pushLocation,
    ];
}