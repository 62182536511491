import React from "react";
import Classes from './panel.module.scss';
import classnames from 'classnames';

export enum PanelType {
    EMPTY,
    WITH_SHADOW,
}

interface IProps {
    id?: string;
    children?: React.ReactNode | React.ReactNode[];
    type?: PanelType;
    additionalStyles?: React.CSSProperties;
    noPadding?: boolean;
    sideBorder?: boolean;
    twiceBorder?: boolean;
}

export function Panel(props: IProps) {
    const {
        id,
        children,
        type = PanelType.WITH_SHADOW,
        noPadding = false,
        sideBorder = false,
        twiceBorder = false,
        additionalStyles = {}
    } = props;
    const classname = classnames({
        [Classes.panel]: type === PanelType.WITH_SHADOW,
        [Classes.panel_empty]: type === PanelType.EMPTY,
        [Classes.panel_no_pading]: noPadding,
        [Classes.panel_partial_border]: sideBorder,
        [Classes.panel_twice_border]: twiceBorder,
    })
    return <div id={id} style={{...additionalStyles}} className={classname}>
        {children}
    </div>
}
