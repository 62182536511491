import { useObservable } from '@libreact/use-observable';
import { Add, Delete } from '@material-ui/icons';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { IDataTableColumn, IDataTableConditionalRowStyles } from 'react-data-table-component';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Avatar as StaffAvatar, FallbackType } from 'src/components/ui/components/avatar/avatar';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { FavButton } from 'src/components/ui/components/buttons/fav-button';
import { FiltersList } from 'src/components/ui/components/filters-list';
import { SearchInput, SearchInputType } from 'src/components/ui/components/forms/inputs/search';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { Table } from 'src/components/ui/components/table';
import { MessageIcon, SettingsIcon } from 'src/components/ui/icons';
import { COLORS } from 'src/components/ui/theme/variables/colors';
import { INamedEntity } from 'src/store/properties/models/named-entity.interface';
import { INumerated } from 'src/store/properties/models/numerated.interface';
import { KeyedNamedEntity } from 'src/store/properties/search/search-properties.query';

import { IStaff, StaffRole } from 'src/store/staff/models/staff.inreface'
import { searchStaffService } from 'src/store/staff/staff-search/staff-search.service';
import { IStaffSearchCriteria } from 'src/store/staff/staff-search/staff-search.store';
import { staffCount$, selectedStaffs$ } from 'src/store/staff/staff.query';
import { staffService } from 'src/store/staff/staff.service';
import { StaffFilter } from './staff-filter';
import { RenderRole } from '../staff-info/staff-info';

import { useStaff } from 'src/store/staff/hooks/use-staff';
import { useStaffSearch } from 'src/store/staff/hooks/use-staff-search';
import { uiQuery } from 'src/store/ui-storage/ui.query';
import { ContentHolder } from 'src/components/ui/main-layout/layout';

import styles from './staff.module.scss';
import baseStyles from 'src/styles/base.module.scss';
import { TitleHolder } from 'src/components/containers/properties/pages/list';
// import { userQuery } from 'src/store/user/user.query';
import { ISelectedData } from 'src/store/utils/common.interface';
import { messageService } from 'src/store/message/message.service';
import { RecieverType } from 'src/store/message/message.store';
import { PagingType } from 'src/store/common/models/common.interface';
import { usePaging } from 'src/store/common/hooks/use-paging';
import { authQuery } from 'src/store/auth/auth.query';

const buildConditionalRowStyles = () => {
    const availableColors = {
        [StaffRole.ADMINISTRATOR]: COLORS.ADMIN_COLOR,
        [StaffRole.AGENT]: COLORS.AGENT_COLOR,
        [StaffRole.STAFF]: COLORS.STAFF_COLOR,
    };

    const conditionalRowStyles: IDataTableConditionalRowStyles<IStaff & INumerated>[] = [];

    Object.entries(availableColors).forEach(([key, color]) => {
        conditionalRowStyles.push({
            when: (row) => (row.i % 2 === 0) && row.role === key,
            style: {
                borderLeft: `2px solid ${color}`,
                backgroundColor: COLORS.BACKGROUND_GRAY,
            }
        });
        conditionalRowStyles.push({
            when: (row) => (row.i % 2 === 1) && row.role === key,
            style: {
                borderLeft: `2px solid ${color}`,
            }
        });
    });

    return conditionalRowStyles;
}

const conditionalRowStyles = buildConditionalRowStyles();

interface IStaffProps extends RouteComponentProps {
    isFilterable?: boolean;
    useSelectedUnitId?: number | string;
    omitFields?: string[];
}

const columns = (clickHandler: (e: ChangeEvent<any>) => void) => [
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
        minWidth: '150px',
        cell: (row: IStaff) => {
            return (
                <div className={styles.nameCell}>
                    <StaffAvatar 
                        src={ row.photo } 
                        fallbackType={FallbackType.ABBR}
                        fallback={row.name} />
                    <TitleHolder onClick={clickHandler} id={String(row.id)}>{ row.name }</TitleHolder>
                </div>
            )
        }
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Phone Number',
        selector: 'phoneNumber',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Prospects leading',
        selector: 'prospectsLeading',
        sortable: true,
        minWidth: '120px',
        cell: (row: IStaff) => <div onClick={clickHandler} id={String(row.id)} style={{ width: '55%', textAlign: 'right' }}>{ row.prospectsLeading }</div>
    },
    {
        name: 'Role',
        selector: 'role',
        sortable: true,
        minWidth: '120px',
        cell: (row: IStaff) => <RenderRole onRowClick={clickHandler} staffRole={row.role} id={String(row.id)} />
    },
];

export const Staff: React.FC<IStaffProps> = ({
    isFilterable = true,
    useSelectedUnitId,
    omitFields = [],
}) => {
    const [user] = useObservable(authQuery.selectUser$);
    const [paging, setPaging, pushLocation] = usePaging(PagingType.STAFF);
    const [staff, isLoading] = useStaff();
    const history = useHistory();

    const [allSelectedFilters, searchCriteria] = useStaffSearch(paging);
    const [isFilterOpened, setIsFilterOpened] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);

    const [recordsNumber] = useObservable(staffCount$);
    const [selectedStaffs] = useObservable(selectedStaffs$);
    const [isMobile] = useObservable(uiQuery.isMobile$);
    const [filterQueries, setFilterQueries] = useState({
        attachedAgentQ: '',
        cityQ: '',
        naeighborhoodsQ: '',
    });

    useEffect(() => {
        return () => {
            if (!history.location.pathname.includes('staff')) {
                return removeAllSelection();
            }
        };
    }, []);

    const onOpen = () => {
        setIsFilterOpened(true);
    }

    const onApply = () => {
        staffService.fetchStaff(0, paging.rowsPerPage);
        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const onClose = (cachedData?: IStaffSearchCriteria) => {
        if (cachedData) {
            searchStaffService.updateSelectionCriteria(cachedData);
        }

        setIsFilterOpened(false);
    }

    const changeRowsNumber = (rowPerPage: number, page: number) => {
        setPaging(page, rowPerPage);
        staffService.fetchStaff(page - 1, rowPerPage);
    }

    const onRowClicked = useCallback((e: any) => {
        pushLocation(`/profiles/staff/${e.id}`, PagingType.STAFF);
    }, [pushLocation]);

    const onCustomCellClick = useCallback((e: ChangeEvent<any>) => {
        pushLocation(`/profiles/staff/${e.target.id}`, PagingType.STAFF);
    }, [pushLocation])

    const onSort = (column: IDataTableColumn<IStaff & INumerated>, sortDirection: "desc" | "asc", rows: number) => {
        searchStaffService.setSorting({
            type: sortDirection,
            field: column.selector as keyof IStaff
        });
        staffService.fetchStaff(0, rows);
    }

    const changePage = (page: number) => {
        // table component numerating start from 1, BE expects 0 as the first page
        setPaging(page, paging.rowsPerPage);
        staffService.fetchStaff(page - 1, paging.rowsPerPage);
    }

    // const preparedColumns = useMemo(() => {
    //     return columns.filter(col => {
    //         return !omitFields.includes(col.selector as string);
    //     });
    // }, [omitFields]);

    const onFilterDelete = (shouldResetPropspects: boolean) => (e: KeyedNamedEntity<keyof IStaffSearchCriteria>) => {
        searchStaffService.deleteItem(e);

        if (shouldResetPropspects) {
            staffService.fetchStaff(0, paging.rowsPerPage);
        }
    }

    // Filter
    const removeAllSelection = () => {
        searchStaffService.removeAllSelection();
        staffService.fetchStaff(0, paging.rowsPerPage);
        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const changeCriteria = (criteriaName: keyof IStaffSearchCriteria) => (item: INamedEntity) => {
        let data = (searchCriteria[criteriaName] as Array<any>);

        if (data.find(i => i.id === item.id)) {
            data = data.filter(i => i.id !== item.id);
        } else {
            data = [
                ...data,
                {
                    ...item,
                    isSelected: true
                }
            ]
        }

        searchStaffService.updateSelectionCriteria({
            ...searchCriteria,
            [criteriaName]: data
        })
    }

    const handleSelectionChange = (data: ISelectedData<IStaff>) => {
        setSelectedCount(data.selectedCount);

        staffService.setSelectedStaffs({
            data: data ? data?.selectedRows : [],
        });
    }

    const handleSendMessage = () => {
        if (selectedStaffs?.data.length) {
            messageService.selectRecievers(RecieverType.STAFF, selectedStaffs.data);
            staffService.resetSelectedStaffs();
            history.push('/message', { from: 'staff', backLink: 'staff', category: 'profiles' });
        }
    }

    return (
            <ContentHolder noPadding smallPadding>
                {
                    !isMobile
                        ? (
                            <Panel type={PanelType.EMPTY}>
                                <div className={styles.header_wrapper}>
                                    <Header3 style={{margin: 0, display: 'flex', alignItems: 'center'}}>
                                        Staff
                                        <span className={baseStyles.total}>
                                            ({recordsNumber})
                                        </span>
                                    </Header3>
                                </div>
                            </Panel>
                        )
                        : null
                }
                <Panel type={PanelType.EMPTY}>
                    {
                        isMobile
                            ? (
                                <div className={styles.list_action_bar_mobile}>
                                    <Button
                                        icon={<MessageIcon />}
                                        btnVariant={ButtonVariant.OUTLINE}
                                        text=""
                                        onClick={() => history.push('/message', { from: 'staff', backLink: 'staff', catrgory: 'profiles' })}
                                    />
                                    {
                                        user?.role === StaffRole.ADMINISTRATOR
                                            ? (
                                                <Button
                                                    icon={<Add style={{fontSize: 14}}/>}
                                                    btnVariant={ButtonVariant.PRIMARY}
                                                    onClick={() => history.push(`/profiles/staff/new`)}
                                                    text="Add staff"
                                                />
                                            ) : null
                                    }
                                </div>
                            ) : null
                    }
                    <div className={styles.list_action_bar}>
                        <div className={styles.list_action_bar_left}>
                            <SearchInput
                                id="staff-search"
                                inputType={SearchInputType.PRIMARY}
                                placeholderText="Search..."
                            />
                            {isFilterable && <FavButton
                                style={{marginLeft: 15}}
                                icon={<SettingsIcon/>}
                                onClick={onOpen}
                                badge={allSelectedFilters.length > 0 ? allSelectedFilters.length.toString() : undefined}
                            />}
                        </div>
                        {
                            !isMobile
                                ? (
                                    <div className={styles.list_action_bar_right}>
                                        <Button
                                            icon={<MessageIcon />}
                                            btnVariant={ButtonVariant.OUTLINE}
                                            text="Message"
                                            onClick={() => history.push('/message', { 
                                                from: 'staff', 
                                                backLink: 'staff',
                                                category: 'profiles',
                                            })}
                                        />
                                        {
                                            user?.role === StaffRole.ADMINISTRATOR
                                            ? (
                                                <Button
                                                    icon={<Add style={{fontSize: 14}}/>}
                                                    btnVariant={ButtonVariant.PRIMARY}
                                                    onClick={() => history.push(`/profiles/staff/new`)}
                                                    text="Add staff"
                                                />
                                            ) : null
                                        }
                                    </div>
                                )
                                : null
                        }
                    </div>
                </Panel>
                {allSelectedFilters.length > 0 && <Panel additionalStyles={
                    isMobile ? { 
                        margin: '0 0 15px',
                        padding: '8px 0',
                        width: '97.5%',
                    } : {}
                }>
                    <FiltersList filtersTitleName="" onDelete={onFilterDelete(true)} items={allSelectedFilters}/>
                </Panel>}
                {selectedCount > 0 && <Panel additionalStyles={
                    isMobile ? { 
                        margin: '0 0 15px',
                        width: '97.5%',
                    } : {}
                }>
                    <div className={styles.list_action_props_selected}>
                        <div className={styles.list_action_bar_left}>
                            {`${selectedCount} Selected`}
                        </div>
                        <div className={styles.selected_action_bar_right}>
                            <Button
                                icon={<MessageIcon />}
                                btnVariant={ButtonVariant.OUTLINE}
                                text={isMobile ? '' : 'Message'}
                                onClick={handleSendMessage}
                            />
                        </div>
                    </div>
                </Panel>}
                <Panel noPadding sideBorder>
                    <Table<IStaff>
                        contextActions={[
                            <div className={styles.delete_properties}>
                                <Delete /> Delete Selected
                            </div>
                        ]}
                        onSelectionChange={handleSelectionChange}
                        pagination={!useSelectedUnitId}
                        onChangePage={changePage}
                        totalRecordsNumber={recordsNumber}
                        onRowClicked={onRowClicked}
                        paginationPerPage={paging.rowsPerPage}
                        onChangeRowsNumber={changeRowsNumber}
                        columns={columns(onCustomCellClick)}
                        conditionalRowStyles={conditionalRowStyles}
                        onSort={onSort}
                        loading={isLoading}
                        data={staff}
                        highlightRow={['name', 'email']}
                        activePage={paging.activePage}
                    />
                </Panel>
                <StaffFilter
                    removeAllSelection={removeAllSelection} 
                    isFilterOpened={isFilterOpened} 
                    searchCriteria={searchCriteria}
                    onApply={onApply}
                    onClose={onClose} 
                    allSelectedFilters={allSelectedFilters} 
                    onFilterDelete={onFilterDelete} 
                    setFilterQueries={setFilterQueries} 
                    filterQueries={filterQueries} 
                    changeCriteria={changeCriteria}
                    cssClasses={styles}
                />
            </ContentHolder>
        )
}