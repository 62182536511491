import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {IGroup} from "./models/group.interface";
import {IPropertyGroup} from "../property-groups/models/property-group.interface";
import {ITenant} from "../models/tenant.interface";
import { ISort } from "src/store/properties/search/search-properties.store";

export interface ITenantGroupsState extends EntityState{
    groups: Array<IGroup>;
    selectedGroup: IGroup;
    recordsCount: number;
    searchQ: string;
    sort: ISort<keyof IGroup>;
    groupsTotal: number;

    mergedTenantsList: ITenant[];
    searchData: {
        propertyGroups: IPropertyGroup[];
        propertyGroupsTotal: number;
        tenants: ITenant[];
        tenantsTotal: number;
        loading: boolean;
    }
    selectedData: {
        propertyGroups: IPropertyGroup[];
        tenants: ITenant[];
    },
}

export const tenantGroupInitialState: ITenantGroupsState = {
    groups: [],
    selectedGroup: {
        name: '',
        notes: '',
        tenantIds: [],
        tenants: [],
        tenantNames: [],
        phoneNumbers: [],
        emails: [],
        properties: [],
        createdDate: new Date(),
        lastEdit: new Date(),
        mergedTenantsList: [],
    },
    recordsCount: 0,
    searchQ: '',
    mergedTenantsList: [],
    searchData: {
        propertyGroups: [],
        propertyGroupsTotal: 0,
        tenants: [],
        tenantsTotal: 0,
        loading: false,
    },
    selectedData: {
        propertyGroups: [],
        tenants: []
    },
    sort: {
        field: 'name',
        type: 'desc'
    },
    groupsTotal: 0,
}

@StoreConfig({ name: 'tenant_groups' })
export class TenantGroupsStore extends EntityStore<ITenantGroupsState> {
    constructor() {
        super(tenantGroupInitialState);
    }
}

export const tenantGroupsStore = new TenantGroupsStore();