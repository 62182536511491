import { createEntityQuery } from "@datorama/akita";
import { IPropertiesState, propertiesStore } from "./properties.store";
import { PropertyStatus } from "./models/property.interface";
import moment from "moment";
import { combineLatest } from "rxjs";
import { searchPropertiesQuery } from "./search/search-properties.query";
import { map } from "rxjs/operators";
import { getFilteredProperties } from "./properties.filter";

const propq = createEntityQuery<IPropertiesState>(propertiesStore);

export const properties$ = propq.select(state => state.properties.filter(p => p.status !== PropertyStatus.ARCHIVED));

export const selectFilteredProperties$ = combineLatest([
        searchPropertiesQuery.appliedFilters$,
        properties$,
    ]).pipe(
        map(([searchCriteria, properties]) => getFilteredProperties({ 
            searchCriteria, 
            properties,
        }))
    );

export const isLoading$ = propq.selectLoading();

export const selectedProperty$ = propq.select(state => state.selectedProperty);
export const totalRecordsNumber$ = propq.select(state => state.totalRecordsNumber);
export const importState$ = propq.select(state => state.importingState);
export const lastImported$ = propq.select(state => moment(state.lastImported).format('LLL'));

export const selectedProperties$ = propq.select(state => state.selectedProperties);

export const messageHistory$ = propq.select(state => state.messageHistory);

export const propertiesQuery = {
    properties$,
    selectFilteredProperties$,
    isLoading$,
    selectedProperty$,
    importState$,
    lastImported$,
    selectedProperties$,
    messageHistory$,
    totalRecordsNumber$,
};