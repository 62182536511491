import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { ITemplate } from "./models/template.interface";
import { IEmailNotification } from "./models/email-notification.interface";
import { ISMSNotification } from "./models/sms-notification.interface";
import { ICalendarSettings } from "./models/calendar.interface";
import { IFollowUp } from "./models/followup.interface";
import { IAutoNitificaton } from "./models/autonotification.interface";
import { IStaff } from "../staff/models/staff.inreface";

export interface IAdminSettingsState extends EntityState {
    emailTemplates: ITemplate[];
    smsTemplates: ITemplate[];
    selectedTemplate?: ITemplate;
    emailNotification: Partial<IEmailNotification>;
    smsNotification: Partial<ISMSNotification>;
    staff: IStaff[];
    sort: ISort<keyof ITemplate>;
    calendarSettings: Partial<ICalendarSettings>;

    followUps: Partial<IFollowUp>[];
    isFollowUpLoading: boolean;
    selectedFollowUp?: Partial<IFollowUp>;
    totalFollowups: number;

    autoNotifications: Partial<IAutoNitificaton>[];
    isNotificationsLoading: boolean;
    selectedAutoNotifications?: Partial<IAutoNitificaton>;
    totalNotifications: number;

    isUpdating: boolean;
}

export interface ISort<T = string> {
    field: T;
    type: 'asc' | 'desc';
}

const initialState: IAdminSettingsState = {
    emailTemplates: [],
    smsTemplates: [],
    emailNotification: {
        fromEmails: [],
    },
    staff: [],
    smsNotification: {},
    calendarSettings: {},
    followUps: [],
    totalFollowups: 0,
    isFollowUpLoading: false,
    autoNotifications: [],
    totalNotifications: 0,
    isNotificationsLoading: false,
    isUpdating: false,
    sort: {
        field: 'lastEditDate',
        type: 'desc'
    }
}

@StoreConfig({ name: 'admin-settings' })
export class AdminSettingsStore extends EntityStore<IAdminSettingsState> {
    constructor() {
        super(initialState);
    }
}

export const adminSettingsStore = new AdminSettingsStore();