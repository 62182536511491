import {ITenant} from "../../../../../../store/tenants/models/tenant.interface";
import React, { Fragment, useMemo } from "react";
import CssClasses from "../tenant-view.module.scss";
import {Form, FormType, InputType} from "../../../../../ui/components/forms/formBuilder/form";
import { IProperty } from "src/store/properties/models/property.interface";
import { HomeIcon, InfoIcon, MessageIcon, PhoneIcon } from "src/components/ui/icons";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { isEmpty, uniq } from "lodash";

interface IProps {
    tenant: ITenant;
}

export function ContactDetails({tenant} : IProps) {
    const propertyDto = useMemo(() => {
        // return tenant.portfolio.propertyDtos.filter(dto => dto.unitId === tenant.unitId)[0] || [];
        return tenant.property || {};
    }, [tenant]);

    return <Fragment>
        <FormHolder
            title="Contact Details"
            withDivider
            icon={<InfoIcon />}>
            <Form<Partial<ITenant>>
                type={FormType.DEFAULT}
                data={tenant}
                readOnly
                fields={{
                    email: {
                        index: 0,
                        label: 'Email',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    mobilePhone: {
                        index: 1,
                        label: 'Mobile Number',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    homePhone: {
                        index: 2,
                        label: 'Home Number',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    sms: {
                        index: 3,
                        label: 'Notification Method',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            display: 'flex',
                            flexDirection: 'row',
                        },
                        fieldType: InputType.CUSTOM_ELEMENT,
                        customElement: <div style={{ display: 'flex', alignItems: 'center' }}>
                            {tenant.email && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className={CssClasses.icon_email}>
                                        <MessageIcon />
                                    </div>
                                    <span>Email</span>
                                </div>
                            )}
                            {tenant.sms !== 'NO' && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className={CssClasses.icon_phone}>
                                        <PhoneIcon />
                                    </div>
                                    <span>Phone</span>
                                </div>
                            )}
                        </div>,
                    }
                }}
            />
        </FormHolder>
        <FormHolder
            title="Property"
            icon={<HomeIcon />}>
            <Form<Partial<IProperty> | { noData: string }>
                type={FormType.DEFAULT}
                data={
                    !isEmpty(propertyDto)
                        ? {
                            ...propertyDto,
                        } : { noData: 'No Data' }
                }
                readOnly
                fields={{
                    noData: {
                        index: 0,
                        label: '',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    unitAddress: {
                        index: 0,
                        label: 'Address',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.CUSTOM_ELEMENT,
                        customElement: <div style={{ fontWeight: 'bold' }}>
                            <div>
                                { `${propertyDto?.unitAddress}.` }
                            </div>
                            {/* <div>
                                { `${propertyDto.postalCode}` }
                            </div> */}
                            <div>
                                { `${propertyDto?.city ? `${propertyDto?.city},` : ''} ${propertyDto?.neighborhood ? `${propertyDto?.neighborhood},` : ''} ${propertyDto?.postalCode ? propertyDto.postalCode : ''}` }
                            </div>
                        </div>
                    },
                    groups: {
                        index: 1,
                        label: 'Tenant Group',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.CUSTOM_ELEMENT,
                        customElement: <div style={{ fontWeight: 'bold' }}>
                            <div>
                                { `${uniq([
                                    ...(propertyDto?.groups || []),
                                    ...tenant.groups,
                                ]).join(', ')}` }
                            </div>
                        </div>
                    },
                    targetRent: {
                        index: 2,
                        label: 'Rent Price',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.CUSTOM_ELEMENT,
                        customElement: <div style={{ fontWeight: 'bold' }}>
                            <div>
                                { `$ ${propertyDto.targetRent}` }
                            </div>
                        </div>
                    },
                    bedrooms: {
                        index: 3,
                        label: 'N. Bedrooms',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    bathrooms: {
                        index: 4,
                        label: 'N. Bathrooms',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    keyCode: {
                        index: 5,
                        label: 'Keycode Number',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    gas: {
                        index: 6,
                        label: 'Gas',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    heat: {
                        index: 7,
                        label: 'Heat',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    electricity: {
                        index: 8,
                        label: 'Electircity',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                    water: {
                        index: 9,
                        label: 'Water',
                        labelDisplay: 'block',
                        wrapperStyles: {
                            flexDirection: 'row',
                        },
                        fieldType: InputType.STRING,
                    },
                }}
            />
        </FormHolder>
    </Fragment>
}