import React from 'react';
import { Route, Switch } from "react-router-dom";
import { RoutesEnum } from "../../routing/routes";
import { FollowUpEditor } from './pages/follow-up-editor/follow-up-editor';
import { NotificationEditor } from './pages/notification-editor/notification-editor';
import { AdminSettings } from './pages/settings/settings';
import { TemplateEditor } from './pages/template-editor/template-editor';
import { TemplateView } from './pages/template-view/template-view';

export function AdminSettingsContainer () {
    return (
        <Switch>
            <Route exact path={RoutesEnum.ADMIN_SETTINGS} component={AdminSettings} />
            <Route exact path={RoutesEnum.ADMIN_ADD_TEMPLATE} component={TemplateEditor} />
            <Route exact path={RoutesEnum.ADMIN_EDIT_TEMPLATE} component={TemplateEditor} />
            <Route exact path={RoutesEnum.ADMIN_ADD_NOTIFICATION} component={NotificationEditor} />
            <Route exact path={RoutesEnum.ADMIN_EDIT_NOTIFICATION} component={NotificationEditor} />
            <Route exact path={RoutesEnum.ADMIN_ADD_FOLLOW_UP} component={FollowUpEditor} />
            <Route exact path={RoutesEnum.ADMIN_EDIT_FOLLOW_UP} component={FollowUpEditor} />
            {/* <Route path={RoutesEnum.ADMIN_VIEW_FOLLOW_UP} component={FollowUpEditor} /> */}
            <Route path={RoutesEnum.ADMIN_VIEW_TEMPLATE} component={TemplateView} />
            {/* <Redirect to="/admin/settings" /> */}
        </Switch>
    );
}