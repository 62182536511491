import React from "react";
import CssClasses from './blocking-loader.module.scss';
import {CogIcon} from "../../icons";
import {IUiComponent} from "../../types/ui-component.interface";
import posed, {PoseGroup} from "react-pose";

interface IProps extends IUiComponent {
    open?: boolean;
}

const Modal = posed.div({
    enter: { opacity: 0.7, transition: { duration: 350 } },
    exit: { opacity: 0, transition: { duration: 350 } }
});

export function BlockingLoader(props: IProps) {
    const {open = true, ...rest} = props;

    return <PoseGroup>
        {open && <Modal key="modal" {...rest} className={CssClasses.blocking_loader}>
            <div className={CssClasses.blocking_loader_text}>
                <div className={CssClasses.blocking_loader_icon}>
                    <CogIcon/>
                </div>
                Loading...
            </div>
        </Modal>}
    </PoseGroup>
}
