import React, {ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {Panel, PanelType} from "../../../../ui/components/panel";
import {Header3} from "../../../../ui/components/headers";
import {useHistory, useLocation} from 'react-router-dom';
import {SearchInput, SearchInputType} from "../../../../ui/components/forms/inputs/search";
import {FavButton} from "../../../../ui/components/buttons/fav-button";
import {MessageIcon, SettingsIcon, ImportIcon} from "../../../../ui/icons";
import {Button, ButtonVariant} from "../../../../ui/components/buttons/button";
import {Add, ChevronLeft, Delete, Settings} from "@material-ui/icons";
import {PropertiesImportingState} from "../../../../../store/properties/properties.store";
import {Table} from "../../../../ui/components/table";
import {useObservable} from "@libreact/use-observable/dist";
import {ownersQuery} from "../../../../../store/owners/owners.query";
// import {IDataTableColumn} from "react-data-table-component";
import {IOwner} from "../../../../../store/owners/models/owner.interface";
import {INumerated} from "../../../../../store/properties/models/numerated.interface";
import {ownersService} from "../../../../../store/owners/owners.service";
import {COLORS} from "../../../../ui/theme/variables/colors";
import {searchOwnersService} from "../../../../../store/owners/search/search-owners.service";
import {Sidenav} from "../../../../ui/components/sidenav";
import {IOwnerCriteria} from "../../../../../store/owners/search/search-owners.store";
import {ChipViewTypes, FiltersList} from "../../../../ui/components/filters-list";
import {INamedEntity} from "../../../../../store/properties/models/named-entity.interface";
import {searchOwnersQuery} from "../../../../../store/owners/search/search-owners.query";
import {Form, FormFieldDividerType, FormType, InputType} from "../../../../ui/components/forms/formBuilder/form";
import { guid } from "@datorama/akita";
import { FormHolder } from "src/components/ui/components/forms/form-holder";
import { messageService, RecieverType } from "src/store/message/message.service";
import { ISelectedData } from "src/store/utils/common.interface";
// import { messageQuery } from "src/store/message/message.query";
// import { uniqBy } from "lodash";
import { uiQuery } from "src/store/ui-storage/ui.query";
import { ContentHolder, panelMobileStyles } from "src/components/ui/main-layout/layout";

import CssClasses from "./owners-list.module.scss";
import baseStyles from 'src/styles/base.module.scss';
import { TitleHolder } from "src/components/containers/properties/pages/list";
import { useOwners } from "src/store/owners/hooks/use-owners";
import { searchUrlMap } from "src/store/autocomplete/autocomplete.store";
import { SearchType } from "src/store/autocomplete/models/autocomplete.interface";
import { SearchableList } from "src/components/ui/components/searchable-list/searchable-list";
import { usePaging } from "src/store/common/hooks/use-paging";
import { PagingType } from "src/store/common/models/common.interface";
// import { propertiesService } from "src/store/properties/properties.service";
import { formatLastImportDate } from "src/components/utils/formatDate";

const conditionalRowStyles = [
    {
        // actively renting & i % 2 === 0
        when: (row: INumerated & IOwner) => row.i % 2 === 0,
        style: {
            backgroundColor: COLORS.BACKGROUND_GRAY,
        },
    }
];

const columns = (clickHandler: (e: ChangeEvent<any>) => void) => [
    {
        name: 'Name',
        selector: 'fullname',
        sortable: true,
        minWidth: '150px',
        ignoreRowClick: true,
        cell: (row: IOwner) => {
            return (
                <TitleHolder onClick={clickHandler} id={String(row.id)}>{row.fullname}</TitleHolder>
            );
        }
    },
    {
        name: 'Company',
        selector: 'company',
        sortable: true,
        minWidth: '60px',
        maxWidth: '150px',
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Phone Number',
        selector: 'mobilePhone',
        sortable: true,
        minWidth: '150px',
    },
    {
        name: 'Portfolio',
        selector: 'portfolioNames',
        sortable: false,
        minWidth: '200px',
        cell: (row: IOwner) => <div>
            {row.portfolios.map(p => (
                <div key={guid()} onClick={clickHandler} id={String(row.id)}>{ p.name }</div>
            ))}
        </div>
    },
];

interface IOwnersLocation {
    from: string;
    selectType: string;
}

export function OwnersList() {
    const [isFilterOpened, setIsFilterOpened] = useState(false);
    const history = useHistory();

    const location = useLocation<IOwnersLocation>();
    const [selectMode] = useState(location.state?.selectType);

    const [search, setSearch] = useState<string>('');

    const [selectedItemsCount, setSelectedItemsCount] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState<any>([]);

    const [importState] = useObservable(ownersQuery.importState$);
    // const [selectedOwners] = useObservable(ownersQuery.selectedOwners);

    const [lastImported] = useObservable(ownersQuery.lastImported$);
    // const [count] = useObservable(ownersQuery.ownersCount$);

    const [paging, setPaging, pushLocation] = usePaging(PagingType.OWNERS);
    const [owners, isLoading] = useOwners();

    const [allFilters] = useObservable(searchOwnersQuery.allFilters$);
    const [selectedCriteria] = useObservable(searchOwnersQuery.selectedCriteria$);

    const [isMobile] = useObservable(uiQuery.isMobile$);

    const [filterQueries, setFilterQueries] = useState({
        propertyQ: '',
    });

    useEffect(() => {
        return () => {
            if (!history.location.pathname.includes('owners')) {
                removeAllSelection();
            }
        };
    }, []);

    const sidenavWidth = useMemo(() => {
        const DESKTOP_SIDENAV_WIDTH = 542;

        if(isMobile) {
            return '100%';
        } else {
            return DESKTOP_SIDENAV_WIDTH;
        }
    }, [isMobile]);

    const filteredOwners = useMemo(() => {
        if (search) {
            const searchQ = search.toLowerCase().trim();

            return owners.filter(owner => {
                if (
                    owner.fullname.toLowerCase().includes(searchQ) ||
                    owner.email.toLowerCase().includes(searchQ) ||
                    owner.mobilePhone.toLowerCase().includes(searchQ)
                ) {
                    return owner;
                }
            });
        }

        return owners;
    }, [owners, search]);

    const onClose = useCallback(() => {
        setIsFilterOpened(false);
    }, [setIsFilterOpened]);

    const onRowClicked = useCallback((e: any) => {
        pushLocation(`/profiles/owners/${e.id}`, PagingType.OWNERS);
    }, [history, paging]);

    const onCustomCellClick = useCallback((e: ChangeEvent<any>) => {
        pushLocation(`/profiles/owners/${e.target.id}`, PagingType.OWNERS);
    }, [history, paging])

    const handleFilterChange = (e: IOwnerCriteria) => {
        searchOwnersService.updateSelectionCriteria(e);
    }

    const importData = useCallback(() => {
        ownersService.importOwners()
    }, []);

    // const onSort = (column: IDataTableColumn<IOwner & INumerated>, sortDirection: "desc" | "asc", rows: number) => {
    //     searchOwnersService.setSorting({
    //         type: sortDirection,
    //         field: column.selector as keyof IOwner
    //     });
    //     ownersService.fetchOwners(0, rows);
    // }

    const removeAllSelection = () => {
        searchOwnersService.removeAllSelection();

        // setClearAll(state => !state);
        searchOwnersService.applyFilters();
        // ownersService.fetchOwners(0, paging.rowsPerPage);
        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const handleSelectionChange = (data: ISelectedData<IOwner>) => {
        // ownersService.setSelectedOwners(data.selectedRows);
        setSelectedItemsCount(() => data.selectedCount);
        setSelectedItems(() => data.selectedRows);
    }

    const onApply = () => {
        // ownersService.fetchOwners(0, paging.rowsPerPage);
        searchOwnersService.applyFilters();
        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }

    const changeSearch = (q: string) => {
        // searchOwnersService.changeSearchQ(q);
        setSearch(q);
    }

    const changePage = (page: number) => {
        // table component numerating start from 1, BE expects 0 as the first page
        setPaging(page, paging.rowsPerPage);
        // ownersService.fetchOwners(page - 1, paging.rowsPerPage);
    }

    const changeRowsNumber = (rowPerPage: number, page: number) => {
        // setRowsPerPage(rowPerPage);
        setPaging(page, rowPerPage);
        // ownersService.fetchOwners(page - 1, rowPerPage);
    }


    const onFilterDelete = (filter: INamedEntity) => {
        if (filter.id === 'YES' || filter.id === 'NO') {
            searchOwnersService.updateSelectionCriteria({
                ...selectedCriteria,
                erManaging: 'ANY',
            })
        } else {
            searchOwnersService.updateSelectionCriteria({
                ...selectedCriteria,
                portfolio: selectedCriteria.portfolio.filter(item => item.id !== filter.id),
            });
        }

        searchOwnersService.applyFilters();

        // ownersService.fetchOwners(0, paging.rowsPerPage);
    }

    const changeCriteria = (item: any) => {
        let data = selectedCriteria.portfolio;

        if (data.find(i => i.id === item.id)) {
            data = data.filter(i => i.id !== item.id);
        } else {
            data = [
                ...data,
                {
                    ...item,
                    isSelected: true
                }
            ]
        }

        searchOwnersService.updateSelectionCriteria({
            ...selectedCriteria,
            portfolio: data,
        })
    }

    const handleSendMessage = () => {
        if (selectedItems?.length) {
            messageService.selectRecievers(RecieverType.OWNERS, selectedItems);
            // ownersService.resetSelectedOwners();
            history.push('/message', { from: 'owners', backLink: 'owners', category: 'profiles' });
        }
    }

    const handleAdd = () => {
        if (selectedItems?.length) {
            messageService.selectRecievers(RecieverType.OWNERS, selectedItems);

            ownersService.resetSelectedOwners();

            history.push('/message/owners', { from: 'owners', backLink: 'owners', category: 'profiles' });
        }
    }

    return <ContentHolder noPadding smallPadding>
        {
            location.state?.from
                ?  (
                    <div className="link_wrapper"
                        style={{ cursor: 'pointer' }}  
                        onClick={() => history.push(location.state.from, { 
                            from: 'owners', 
                            backLink: 'owners',
                            category: 'profiles',
                        })}>
                        <ChevronLeft /> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
                    </div>  
                )
                : null
        }
        {
            !isMobile
                ? (
                    <Panel type={PanelType.EMPTY}>
                        <div className={CssClasses.header_wrapper}>
                            <Header3 style={{margin: 0, display: 'flex', alignItems: 'center'}}>
                                Owners
                                <span className={baseStyles.total}>
                                    ({ filteredOwners.length })
                                </span>
                            </Header3>
                            <div className={CssClasses.last_imported}>
                                {formatLastImportDate(lastImported)}
                            </div>
                        </div>
                    </Panel>
                )
                : null
        }
        <Panel type={PanelType.EMPTY}>
            {
                isMobile
                    ? (
                        <div className={CssClasses.list_action_bar_right}>
                            {
                                selectMode
                                    ? (
                                        <Button
                                            onClick={handleAdd}
                                            icon={<Add style={{fontSize: 14}}/>}
                                            btnVariant={ButtonVariant.PRIMARY}
                                            text={`Add to the list${selectedItems.length ? ` :${selectedItems.length}` : ''}`}
                                        />)
                                    : (
                                        <Fragment>
                                            <Button
                                                icon={<MessageIcon />}
                                                style={{ margin: '0 10px 0 0' }}
                                                btnVariant={ButtonVariant.OUTLINE}
                                                text=""
                                                onClick={() => history.push('/message', { 
                                                    from: 'owners', 
                                                    backLink: 'owners',
                                                    category: 'profiles',
                                                })}
                                            />
                                            <Button
                                                onClick={importData}
                                                icon={<ImportIcon />}
                                                isLoading={importState === PropertiesImportingState.IN_PROGRESS}
                                                loadingText="Importing Data..."
                                                btnVariant={ButtonVariant.PRIMARY}
                                                text="Import"
                                            />
                                        </Fragment>
                                    )
                            }
                        </div>
                    )
                    : null
            }
            <div className={CssClasses.list_action_bar}>
                <div className={CssClasses.list_action_bar_left}>
                    <SearchInput
                        onChange={changeSearch}
                        inputType={SearchInputType.PRIMARY}
                        placeholderText="Search..."
                    />
                    <FavButton
                        onClick={() => setIsFilterOpened(true)} style={{marginLeft: 15}}
                        icon={<SettingsIcon/>}
                        badge={allFilters.length > 0 ? allFilters.length.toString() : undefined}
                    />
                </div>
                {
                    !isMobile
                        ? (
                            <div className={CssClasses.list_action_bar_right}>
                                {
                                    selectMode
                                        ? (
                                            <Button
                                                onClick={handleAdd}
                                                icon={<Add style={{fontSize: 14}}/>}
                                                btnVariant={ButtonVariant.PRIMARY}
                                                text={`Add to the list${selectedItems.length ? `: ${selectedItems.length}` : ''}`}
                                            />)
                                        : (
                                            <Fragment>
                                                <Button
                                                    icon={<MessageIcon />}
                                                    style={{ margin: '0 10px 0 0' }}
                                                    btnVariant={ButtonVariant.OUTLINE}
                                                    text="Message"
                                                    onClick={() => history.push('/message', { 
                                                        from: 'owners', 
                                                        backLink: 'owners',
                                                        category: 'profiles',
                                                    })}
                                                />
                                                <Button
                                                    onClick={ownersService.importOwners}
                                                    icon={<ImportIcon />}
                                                    isLoading={importState === PropertiesImportingState.IN_PROGRESS}
                                                    loadingText="Importing Data..."
                                                    btnVariant={ButtonVariant.PRIMARY}
                                                    text="Import"
                                                />
                                            </Fragment>
                                        )
                                }
                            </div>
                        )
                        : null
                }
            </div>
        </Panel>
        {
            isMobile
                ? (
                    <Panel type={PanelType.EMPTY}>
                        <div className={CssClasses.last_imported}>
                            {formatLastImportDate(lastImported)}
                        </div>
                    </Panel>
                )
                : null
        }
        {allFilters.length > 0 && <Panel additionalStyles={
            isMobile ? panelMobileStyles : {}
        }>
            <FiltersList filtersTitleName="" onDelete={onFilterDelete} items={allFilters}/>
        </Panel>}
        {(selectedItemsCount > 0 && !selectMode) && <Panel additionalStyles={
            isMobile ? panelMobileStyles : {}
        }>
            <div className={baseStyles.list_action_props_selected}>
                <div className={baseStyles.list_action_bar_left}>
                    {`${selectedItemsCount} Selected`}
                </div>
                <div className={baseStyles.selected_action_bar_right}>
                    <Button
                        icon={<MessageIcon />}
                        btnVariant={ButtonVariant.OUTLINE}
                        text={ isMobile ? '' : 'Message' }
                        onClick={handleSendMessage}
                    />
                </div>
            </div>
        </Panel>}
        <Panel noPadding sideBorder>
            <Table<IOwner>
                contextActions={[
                    <div className={CssClasses.delete_properties}>
                        <Delete/> Delete Selected
                    </div>
                ]}
                // selectableRowSelected={(row: any) => row.isSelected}
                onSelectionChange={handleSelectionChange}
                onChangePage={changePage}
                totalRecordsNumber={filteredOwners.length}
                onRowClicked={onRowClicked}
                paginationPerPage={paging.rowsPerPage}
                onChangeRowsNumber={changeRowsNumber}
                columns={columns(onCustomCellClick)}
                // onSort={onSort}
                conditionalRowStyles={conditionalRowStyles}
                loading={isLoading}
                data={filteredOwners}
                highlightRow={['fullname']}
                activePage={paging.activePage}
                paginationServer={false}
                paginationServerOptions={{
                    persistSelectedOnPageChange: false, 
                    persistSelectedOnSort: false,
                }}
                noDataText={importState === PropertiesImportingState.IN_PROGRESS 
                    ? `The data will be displayed after importing` 
                    : ''}
            />
        </Panel>
        <Sidenav<IOwnerCriteria>
            header={<Fragment>
                <Settings/>
                <span>
                    Filters
                </span>
            </Fragment>}
            footer={<Fragment>
                <Button
                    btnVariant={ButtonVariant.OUTLINE}
                    onClick={removeAllSelection}
                    text="Clear All"
                />
                <Button
                    style={{marginLeft: 12}}
                    onClick={onApply}
                    btnVariant={ButtonVariant.PRIMARY}
                    text="Apply"
                />
            </Fragment>}
            width={sidenavWidth}
            isOpen={isFilterOpened}
            onClose={onClose}
        >
            <div className={CssClasses.filters_wrapper}>
                <FiltersList
                    filtersTitleName="Choose From The List Below"
                    type={ChipViewTypes.BLOCK}
                    items={allFilters}
                    onDelete={onFilterDelete}
                />
            </div>
            <FormHolder>
                <Form<IOwnerCriteria>
                    type={FormType.DEFAULT}
                    data={selectedCriteria}
                    wrapperStyles={{
                        margin: '0 0 0 20px',
                    }}
                    onChange={handleFilterChange}
                    fields={{
                        portfolio: {
                            index: 0,
                            label: 'Portfolio',
                            labelDisplay: 'block',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            labelStyles: {
                                padding: 0,
                            },
                            height: 'auto',
                            wrapperStyles: {
                                overflow: 'hidden',
                            },
                            withDividers: FormFieldDividerType.BOTTOM,
                            customElement: <Fragment>
                                <SearchableList
                                    id="portfolis"
                                    selectedCriteria={selectedCriteria.portfolio}
                                    url={searchUrlMap.get(SearchType.PORTFOLIOS)}
                                    searchType={SearchType.PORTFOLIOS}
                                    onSearchChange={(q: string) => setFilterQueries({
                                        ...filterQueries,
                                        propertyQ: q
                                    })}
                                    onSelect={changeCriteria}
                                />
                            </Fragment>
                        },
                        erManaging: {
                            index: 1,
                            label: 'ER Managing',
                            labelDisplay: 'block',
                            fieldType: InputType.DROPDOWN,
                            labelStyles: {
                                padding: 0,
                            },
                            selectableItems: [
                                {
                                    label: 'Yes',
                                    value: 'YES'
                                },
                                {
                                    label: 'No',
                                    value: 'NO'
                                },
                                {
                                    label: 'Any',
                                    value: 'ANY',
                                }
                            ]
                        }
                    }}
                />
            </FormHolder>
        </Sidenav>
    </ContentHolder>
}