import { ChevronLeft, Edit } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { RoutesEnum } from 'src/components/routing/routes';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { templateService } from 'src/store/admin-settings/templates/template.service';
import { useObservable } from '@libreact/use-observable';
// import { adminSettingsQuery } from 'src/store/admin-settings/admin-settings.query';

import { BlockingLoader } from 'src/components/ui/components/loaders/blocking-loader';
import { templateQuery } from 'src/store/admin-settings/templates/template.query';

import styles from '../template-editor/template-editor.module.scss';
import baseStyle from 'src/styles/base.module.scss';
import { ContentHolder, headMobileStyles, panelMobileStyles } from 'src/components/ui/main-layout/layout';
import { uiQuery } from 'src/store/ui-storage/ui.query';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { isEmpty } from 'lodash';
import { TemplateType } from '../../templates-table';

export const TemplateView = () => {
    const history = useHistory();
    const params = useParams<any>();
    
    const [selectedTemplate] = useObservable(templateQuery.activeEntity$);
    const [loading] = useObservable(templateQuery.loading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        if (params.id) {
            templateService.fetchTemplateById(params.id);
        }
    }, []);

    if (!selectedTemplate || loading) {
        return <BlockingLoader />
    }

    return <ContentHolder noPadding smallPadding>
        <Link className={baseStyle.link_wrapper} to={
            selectedTemplate.type === TemplateType.EMAIL ? RoutesEnum.ADMIN_SETTINGS : `${RoutesEnum.ADMIN_SETTINGS}?tab=sms_settings`
        }>
            <ChevronLeft /> <span>Back</span>
        </Link>
        <Panel additionalStyles={
            isMobile ? headMobileStyles : { marginTop: 7 }
        } type={PanelType.EMPTY}>
            <div className={styles.top_wrapper}>
                <Header3 style={{margin: '10px 0'}}>
                    Template
                </Header3>
                <div className={styles.actionBarHolder}>
                    <Button
                        icon={<Edit style={{fontSize: 14}}/>}
                        btnVariant={ButtonVariant.PRIMARY}
                        onClick={() => history.push(`/admin/settings/templates/${params.id}/edit`, { type: selectedTemplate.type })}
                        text="Edit"
                    />
                </div>
            </div>
        </Panel>
        <Panel additionalStyles={
            isMobile ? panelMobileStyles : { marginTop: 7 }
        }>
            <FormHolder>
                <Form<any>
                    type={FormType.DEFAULT}
                    data={selectedTemplate}
                    readOnly
                    fields={{
                        name: {
                            index: 0,
                            label: 'Name',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                        subject: {
                            index: 1,
                            label: 'Subject',
                            labelDisplay: 'block',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            fieldType: InputType.STRING,
                        },
                    }}
                />
            </FormHolder>
        </Panel>
        <Panel additionalStyles={
            isMobile ? panelMobileStyles : { marginTop: 7 }
        }>
            <div className={styles.form_wrapper}>
                <Form<any>
                    type={FormType.DEFAULT}
                    data={selectedTemplate}
                    readOnly
                    fields={{
                        notice: {
                            index: 0,
                            label: 'Body Part',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            height: 'auto',
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                            groupStyles: {
                                margin: !isEmpty(selectedTemplate.systemData) && selectedTemplate.systemData.hours ? '10px 0 0' : '-4px 0 0',
                            },
                            labelDisplay: 'block',
                            customElement: !isEmpty(selectedTemplate.systemData) && selectedTemplate.systemData.hours
                                ? <div>{`Notice of Scheduled Showing for the next (hours) ${selectedTemplate.systemData.hours}`}</div>
                                : <div dangerouslySetInnerHTML={{ __html: selectedTemplate.notice }} />
                        }
                    }}
                />
            </div>
        </Panel>
    </ContentHolder>
}