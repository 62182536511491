import { fromJS, List, Map } from "immutable";
import { dataFetcherStore, FetchStatus, IFetchRequest } from "./data-fetcher.store";

export const createRequest = <T extends {}>(requestScope: string) => (request: IFetchRequest<T>) => {
    dataFetcherStore.update(state => {
        return {
            ...state,
            requestMap: state.requestMap.setIn([requestScope], fromJS(request)),
        };
    }); 
}

export const deleteRequest = (requestScope: string) => {
    dataFetcherStore.update(state => {
        return {
            ...state,
            requestMap: state.requestMap.deleteIn([requestScope]),
        };
    }); 
}

export const updateFetchStatus = <T extends {}>(requestScope: string) => (fetchStatus: FetchStatus) => {
    dataFetcherStore.update(state => {
        return {
            ...state,
            requestMap: state.requestMap.updateIn([requestScope], (request: Map<string, IFetchRequest<T>>) => 
                request.setIn(['fetchStatus'], fromJS(fetchStatus))),
        };
    }); 
}

export const setError = <T extends {}>(requestScope: string) => (error: any) => {
    dataFetcherStore.update(state => {
        return {
            ...state,
            requestMap: state.requestMap.updateIn([requestScope], (request: Map<string, IFetchRequest<T>>) => 
                request.setIn(['error'], fromJS(error))),
        };
    }); 
}

export const setCache = <T extends {}>(requestScope: string) => (cacheMap: unknown) => {
    dataFetcherStore.update(state => {
        return {
            ...state,
            requestMap: state.requestMap.updateIn([requestScope], (request: Map<string, IFetchRequest<T>>) => 
                request.setIn(['cacheMap'], fromJS(cacheMap))),
        };
    }); 
}

export const deleteItem = <T extends {}>(requestScope: string) => (id: number, dataField: string, idField: string = 'id') => {
    let cacheMap: List<Map<string, T>> = List([]);

    const store = dataFetcherStore.getValue();

    if (dataField) {
        cacheMap = store.requestMap.getIn([requestScope, 'cacheMap', dataField]);
    } else {
        cacheMap = store.requestMap.getIn([requestScope, 'cacheMap']);
    }
    
    const idx = cacheMap.findIndex((item: any) => item.get(idField) === id);

    dataFetcherStore.update(state => {
        return {
            ...state,
            requestMap: dataField
                ? state.requestMap.deleteIn([requestScope, 'cacheMap', dataField, idx])
                : state.requestMap.deleteIn([requestScope, 'cacheMap', idx]),
        };
    });
}

export const updateData = <T extends {}>(requestScope: string) => (id: number, data: T, dataField: string, idField: string = 'id') => {
    let cacheMap: List<Map<string, T>> = List([]);

    const store = dataFetcherStore.getValue();

    if (dataField) {
        cacheMap = store.requestMap.getIn([requestScope, 'cacheMap', dataField]);
    } else {
        cacheMap = store.requestMap.getIn([requestScope, 'cacheMap']);
    }
    
    const idx = cacheMap.findIndex((item: any) => item.get(idField) === id);

    dataFetcherStore.update(state => {
        const updatedCache = cacheMap.update(idx, (item: Map<string, T>) => item.merge(data))

        return {
            ...state,
            requestMap: state.requestMap.updateIn([requestScope], (request: Map<string, IFetchRequest<T>>) => {
                if (dataField) {
                    return request.setIn(['cacheMap', dataField], updatedCache)
                }

                return request.setIn(['cacheMap'], updatedCache)
            }),
        };
    });
}

export type ErrorAction = { isError: boolean }

//** TODO: Improve run action */
export const runAction = async <T>(action: (...args: unknown[]) => Promise<T | void>, requestKey: string, cache: boolean = false, initParams: unknown[]): Promise<T | ErrorAction> => {
    const { requestMap } = dataFetcherStore.getValue();

    const currentCache: Map<string, T> = requestMap.getIn([requestKey, 'cacheMap']);

    if (cache && currentCache) {
        const cachedData = currentCache.toJS();

        return cachedData;
    }

    try {
        if (Array.isArray(initParams) && initParams.length) {
            const data: T = await action(...initParams) as T;
            return data;
        }

        const data: T = await action() as T;
        return data;

    } catch(error) {
        setError(requestKey)(error);
        updateFetchStatus(requestKey)(FetchStatus.FAILED);

        console.log('Error: ', error);

        return {
            isError: true,
        };
    }
}

export const dataFetcherService = {
    createRequest,
    deleteRequest,
    updateFetchStatus,
    setError,
    setCache,
    runAction,
    updateData,
    deleteItem,
};