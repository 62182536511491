import React from "react";
import CssClasses from './auth-layout.module.scss';
import BigRhombus from '../media/imgs/big-rombus.svg';
import AppPreview from '../media/imgs/app-preview.svg';
import SmallRhombus from '../media/imgs/small-rombus.svg';
import {Header1} from "../components/headers";
import {Link} from "react-router-dom";
import {RoutesEnum} from "../../routing/routes";
import { LogoSecondary } from "../icons";

interface IProps {
    children: React.ReactNode;
}

export function AuthLayout(props: IProps) {
    return <div className={CssClasses.auth_layout}>
        <div className={CssClasses.auth_layout_left}>
            <Link to={RoutesEnum.SIGN_IN} className={CssClasses.auth_layout_logo_section}>
                {/* <img src={Logo}  alt="Logo" /> */}
                <LogoSecondary />
            </Link>
            <div>
                {props.children}
            </div>
        </div>
        <div className={CssClasses.auth_layout_right}>
            <div className={CssClasses.auth_layout_preview_rhombus_big}>
                <img alt="" width={800} src={BigRhombus} />
            </div>
            <div className={CssClasses.auth_layout_preview_img}>
                <img alt="" width={550} src={AppPreview} />
            </div>
            <div className={CssClasses.auth_layout_preview_rhombus_small}>
                <img alt="" src={SmallRhombus} />
            </div>
            <div className={CssClasses.auth_layout_preview_rhombus_small_bottom}>
                <img alt="" src={SmallRhombus} />
            </div>
            <div className={CssClasses.auth_layout_title}>
                <Header1 style={{textAlign: 'center', fontSize: 32}}>
                    Set Appointment And Send Messages
                </Header1>
            </div>
            <div className={CssClasses.auth_layout_dots}>
                <div className={CssClasses.auth_layout_dot_active} />
                <div className={CssClasses.auth_layout_dot_inactive} />
                <div className={CssClasses.auth_layout_dot_inactive} />
            </div>
        </div>
    </div>
}
