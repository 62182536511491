import autobind from "autobind-decorator";
import {tenantGroupInitialState, tenantGroupsStore, TenantGroupsStore} from "./tenant-groups.store";
import {PagingStore} from "../../utils/paging-store";
// import {ApiServiceInstance} from "../../api/api-service";
import {IPagedResponse} from "../../properties/models/paged-response.interface";
import {IGroup} from "./models/group.interface";
import {IInitializable} from "../../portfolio/models/on-init.interface";
import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";
import moment from "moment";
import {IPropertyGroup} from "../property-groups/models/property-group.interface";
import {ITenant} from "../models/tenant.interface";
import {IGroupCreateDto} from "./models/group-create.dto";
import {snackbarService} from "../../snackbar/snackbar.service";
import {SnackbarType} from "../../snackbar/snackbar.store";
import {arrayAdd, arrayRemove, arrayUpdate, guid, transaction} from "@datorama/akita";
import {IProperty} from "../../properties/models/property.interface";
import { ISort } from "src/store/properties/search/search-properties.store";
import { uniqBy } from "lodash";
import { SearchType } from "src/store/autocomplete/models/autocomplete.interface";
import { SNACK_TIMEOUT, SYSTEM_MESSAGES, EMPTY_STRING_PLACEHOLDER, DATE_FORMAT } from "../tenants.config";
import { api } from "src/api/api.service";

export const emptyTenantGroup: IGroup = {
    name: '',
    id: null,
    tenantNames: [],
    phoneNumbers: [],
    createdDate: new Date(),
    emails: [],
    notes: '',
    lastEdit: new Date(),
    tenantIds: [],
    tenants: [],
    properties: [],
    mergedTenantsList: [],
}

export enum TenantGroupSearchType {
    BY_TENANTS = 'tenants',
    BY_PROPERTY_GROUPS = 'propertyGroup',
}

export class TenantGroupsService implements IInitializable {
    pagingStore = new PagingStore();
    isInitialized = false;
    private searchQ: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private searchForTenantsAndGroups: BehaviorSubject<{ q: string, type: TenantGroupSearchType }> =
        new BehaviorSubject<{ q: string; type: TenantGroupSearchType }>({
            q: '',
            type: TenantGroupSearchType.BY_PROPERTY_GROUPS
        })

    constructor(
        protected tenantsGroupStore: TenantGroupsStore,
        protected snackbarService: any,
    ) {
    }

    private propertyDtoToPropertyGroup(property: IProperty): IPropertyGroup {
        return {
            property: property.unitAddress,
            tenants: property.tenants.map(t => `${t.firstName} ${t.lastName}`),
            phoneNumbers: property.tenants.map(t => t.mobilePhone || t.homePhone || EMPTY_STRING_PLACEHOLDER),
            emails: property.tenants.map(t => t.email || EMPTY_STRING_PLACEHOLDER),
            tenantIds: property.tenants.map(tenant => tenant.id),
            id: property.id
        }
    }

    buildParams(page: number, size: number) {
        const params: Record<string, any> = {};
        params.page = page;
        params.size = size;

        // const {searchQ} = this.tenantsGroupStore.getValue();

        // params.search = searchQ;
        
        return params;
    }

    @autobind
    @transaction()
    async fetchGroups(page: number = 0, size: number = 10) {
        // this.tenantsGroupStore.setLoading(true);
        this.pagingStore.setPagingData({page, size});

        // const sorting = this.getSorting();
        const params = this.buildParams(page, size);

        // params.sort = `${(sorting.field)},${sorting.type}`;

        const response = await api.get<IPagedResponse<IGroup>>('/groups', {
            params: {
                ...params
            }
        });

        if (response.ok) {
            const EMPTY_STRING = '';
            this.tenantsGroupStore.update(state => ({
                ...state,
                recordsCount: response.data.total,
                groups: response.data.result.map(group => ({
                    ...group,
                    tenantNames: group.tenants.map(tenant => `${tenant.firstName} ${tenant.lastName}`),
                    phoneNumbers: group.tenants.map(tenant => tenant.mobilePhone || tenant.homePhone || EMPTY_STRING),
                    emails: group.tenants.map(tenant => tenant.email || EMPTY_STRING)
                })),
            }))
        }

        this.tenantsGroupStore.setLoading(false);

        return response.data;
    }

    @autobind
    @transaction()
    async fetchGroupById(groupId: number) {
        this.tenantsGroupStore.setLoading(true);

        const response = await api.get<IGroup>(`/groups/${groupId}`);

        if (response.ok) {
            const { data } = response;

            const selectedGroup: IGroup = {
                ...data,
                createdDate: moment(data.createdDate).format(DATE_FORMAT),
                tenantNames: data.tenants.map(tenant => `${tenant.firstName} ${tenant.lastName}`),
                phoneNumbers: data.tenants.map(tenant => tenant.mobilePhone || tenant.homePhone || EMPTY_STRING_PLACEHOLDER),
                emails: data.tenants.map(tenant => tenant.email || EMPTY_STRING_PLACEHOLDER),
            };
    
            this.tenantsGroupStore.update(state => ({
                ...state,
                selectedGroup,
                selectedData: {
                    tenants: selectedGroup.tenants,
                    propertyGroups: selectedGroup.properties.map(this.propertyDtoToPropertyGroup)
                },
                mergedTenantsList: [
                    ...selectedGroup.tenants,
                    ...selectedGroup.properties.map(p => p.tenants).flat(),
                ],
            }))
        }

        this.tenantsGroupStore.setLoading(false);

    }

    @autobind
    resetCurrentGroup() {
        this.tenantsGroupStore.update(state => ({
            ...state,
            selectedGroup: {
                name: '',
                notes: '',
                tenantIds: [],
                tenants: [],
                tenantNames: [],
                phoneNumbers: [],
                emails: [],
                properties: [],
                createdDate: new Date(),
                lastEdit: new Date(),
                mergedTenantsList: [],
            },
            selectedData: tenantGroupInitialState.selectedData,
            searchData: tenantGroupInitialState.searchData,
        }))
    }

    @autobind
    unselectTenantOrGroup(type: TenantGroupSearchType, id: number) {
        if (type === TenantGroupSearchType.BY_TENANTS) {
            this.tenantsGroupStore.update(state => ({
                ...state,
                selectedData: {
                    ...state.selectedData,
                    tenants: state.selectedData.tenants.filter(t => t.id !== id)
                }
            }));
        } else {
            this.tenantsGroupStore.update(state => ({
                ...state,
                selectedData: {
                    ...state.selectedData,
                    propertyGroups: state.selectedData.propertyGroups.filter(t => t.id !== id)
                }
            }));
        }
    }

    @autobind
    @transaction()
    selectTenantsOrGroups(type: SearchType, item: ITenant | IPropertyGroup) {
        if (type === SearchType.TENANTS) {
            if (this.tenantsGroupStore.getValue().selectedData.tenants.find(t => t.id === item.id)) {
                return;
            }

            this.tenantsGroupStore.update(state => ({
                ...state,
                selectedData: {
                    ...state.selectedData,
                    tenants: [
                        ...state.selectedData.tenants,
                        item as ITenant
                    ]
                }
            }));
        } else {
            if (this.tenantsGroupStore.getValue().selectedData.propertyGroups.find(t => t.id === item.id)) {
                return;
            }
            this.tenantsGroupStore.update(state => ({
                ...state,
                selectedData: {
                    ...state.selectedData,
                    propertyGroups: [
                        ...state.selectedData.propertyGroups,
                        item as IPropertyGroup
                    ]
                }
            }));
        }
    }

    @autobind
    @transaction()
    updateSelectedData(selectedData: ITenant[] | IPropertyGroup[], dataType: 'tenants' | 'propertyGroup') {
        switch(dataType) {
            case 'tenants':
                this.tenantsGroupStore.update(state => ({
                    ...state,
                    selectedData: {
                        ...state.selectedData,
                        // tenants: arrayAdd(state.selectedData.tenants, selectedData as ITenant[]),
                        tenants: uniqBy([
                            ...state.selectedData.tenants,
                            ...selectedData,
                        ], 'id'),
                    }
                }));
                break;
            case 'propertyGroup':
                this.tenantsGroupStore.update(state => ({
                    ...state,
                    selectedData: {
                        ...state.selectedData,
                        // propertyGroups: arrayAdd(state.selectedData.propertyGroups, selectedData as IPropertyGroup[]),
                        propertyGroups: uniqBy([
                            ...state.selectedData.propertyGroups,
                            ...selectedData,
                        ], 'id'),
                    }
                }));
                break;
            default:
                return null;
        }
    }

    @autobind
    search(searchFor: TenantGroupSearchType, q: string) {
        this.searchForTenantsAndGroups.next({
            q,
            type: searchFor,
        })
    }

    @autobind
    @transaction()
    init(): void {
        // this.searchForTenantsAndGroups.pipe(
        //     distinctUntilChanged(),
        //     debounceTime(400)
        // ).subscribe(async (search) => {
        //     this.tenantsGroupStore.update(state => ({
        //         ...state,
        //         searchData: {
        //             ...state.searchData,
        //             // loading: true
        //         }
        //     }))
        // });

        this.searchQ.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(search => {
                // this.tenantsGroupStore.setLoading(true);
                this.tenantsGroupStore.update(state => ({
                    ...state,
                    searchQ: search,
                }))
                // const {size, page} = this.pagingStore.getPagingData();

                return this.fetchGroups(0, 9999);

                // this.tenantsGroupStore.setLoading(false);
            }),
        ).subscribe();
        this.isInitialized = true;
    }

    @autobind
    changeSearch(q: string) {
        this.searchQ.next(q);
    }

    @autobind
    setEmptyGroup() {
        this.tenantsGroupStore.update(state => ({
            ...state,
            selectedGroup: {
                name: '',
                notes: ''
            }
        }))
    }

    @autobind
    @transaction()
    async saveOrUpdate(id?: number) {
        const { selectedGroup, selectedData } = this.tenantsGroupStore.getValue();

        if (!selectedGroup) {
            return;
        }

        if (id) {
            const response = await api.put<any, IGroupCreateDto>(`/groups/${id}`, {
                name: selectedGroup.name,
                notes: selectedGroup.notes,
                propertyIds: selectedData.propertyGroups.map(p => p.id),
                tenantIds: selectedData.tenants.map(t => t.id),
                updatedDate: new Date().toISOString()
            });
            
            if (response.ok) {
                const { data } = response;

                const selectedGroup: IGroup = {
                    ...data,
                    createdDate: moment(data.createdDate).format(DATE_FORMAT),
                    tenantNames: data.tenants?.map((tenant: any) => `${tenant.firstName} ${tenant.lastName}`),
                    phoneNumbers: data.tenants?.map((tenant: any) => tenant.mobilePhone || tenant.homePhone || EMPTY_STRING_PLACEHOLDER),
                    emails: data.tenants?.map((tenant: any) => tenant.email || EMPTY_STRING_PLACEHOLDER),
                };
        
                this.tenantsGroupStore.update(state => ({
                    ...state,
                    groups: arrayUpdate(state.groups, (id as number), selectedGroup),
                }))

                this.snackbarService.createSnackbar({
                    text: SYSTEM_MESSAGES.GROUP_UPDATED,
                    type: SnackbarType.SUCCESS,
                    id: guid()
                }, SNACK_TIMEOUT);

                return response.ok;
            }
        } else {
            const response = await api.post<any, IGroupCreateDto>(`/groups`, {
                name: selectedGroup.name,
                createdDate: new Date().toISOString(),
                notes: selectedGroup.notes,
                propertyIds: selectedData.propertyGroups.map(p => p.id),
                tenantIds: selectedData.tenants.map(t => t.id),
                updatedDate: new Date().toISOString()
            });

            if (response.ok) {
                const { data } = response;

                const selectedGroup: IGroup = {
                    ...data,
                    createdDate: moment(data.createdDate).format(DATE_FORMAT),
                    tenantNames: data.tenants?.map((tenant: any) => `${tenant.firstName} ${tenant.lastName}`),
                    phoneNumbers: data.tenants?.map((tenant: any) => tenant.mobilePhone || tenant.homePhone || EMPTY_STRING_PLACEHOLDER),
                    emails: data.tenants?.map((tenant: any) => tenant.email || EMPTY_STRING_PLACEHOLDER),
                };
        
                this.tenantsGroupStore.update(state => ({
                    ...state,
                    groups: arrayAdd(state.groups, selectedGroup),
                    groupsTotal: arrayAdd(state.groups, selectedGroup).length,
                }))

                this.snackbarService.createSnackbar({
                    text: SYSTEM_MESSAGES.GROUP_CREATED,
                    type: SnackbarType.SUCCESS,
                    id: guid()
                }, SNACK_TIMEOUT);

                return response.ok;
            }
        }

    }

    @autobind
    updateGroup(data: Partial<IGroup>) {
        this.tenantsGroupStore.update(state => ({
            ...state,
            selectedGroup: {
                ...state.selectedGroup,
                ...data,
            }
        }))
    }

    async delete(groupId: number) {
        const response = await api.del(`/groups/${groupId}`);

        if (response.ok) {
            this.tenantsGroupStore.update(state => ({
                ...state,
                groups: arrayRemove(state.groups, [groupId]),
                groupsTotal: arrayRemove(state.groups, [groupId]).length,
            }))
    
            this.snackbarService.createSnackbar({
                text: SYSTEM_MESSAGES.GROUP_DELETED,
                type: SnackbarType.SUCCESS,
                id: guid(),
            }, SNACK_TIMEOUT);
        }
    }

    //** Sort */
    getSorting() {
        return this.tenantsGroupStore.getValue().sort;
    }

    setGroupsTotal(total: number) {
        this.tenantsGroupStore.update(state => ({
            ...state,
            groupsTotal: total,
        }));
    }

    public setSorting(sorting: ISort<keyof IGroup>) {
        this.tenantsGroupStore.update(state => ({
            ...state,
            sort: sorting,
        }));
    }
}

export const tenantGroupsService = new TenantGroupsService(
    tenantGroupsStore,
    snackbarService
);