import React, { Fragment } from 'react'
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form'

import { IStaff } from 'src/store/staff/models/staff.inreface'
import { InfoIcon } from 'src/components/ui/icons'
import { FormHolder } from 'src/components/ui/components/forms/form-holder'

interface IContactDetailsProps {
    staff: Partial<IStaff>;
}

const ContactDetails: React.FC<IContactDetailsProps> = ({ staff }) => {
    return (
        <Fragment>
            <FormHolder
                title="Contact Details"
                icon={<InfoIcon />}>
                <Form<Partial<IStaff>>
                    type={FormType.DEFAULT}
                    data={{
                        ...staff,
                        hstN: staff.hstN === 0 ? null : staff.hstN,
                    }}
                    readOnly
                    fields={{
                        email: {
                            index: 0,
                            label: 'Email',
                            labelDisplay: 'block',
                            fieldType: InputType.STRING,
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                        },
                        phoneNumber: {
                            index: 1,
                            label: 'Mobile Number',
                            labelDisplay: 'block',
                            fieldType: InputType.STRING,
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                        },
                        alternNumber: {
                            index: 2,
                            label: 'Altern. number',
                            labelDisplay: 'block',
                            fieldType: InputType.STRING,
                            wrapperStyles: {
                                flexDirection: 'row',
                            },
                        },
                        hstN: {
                            index: 3,
                            label: 'HST N.',
                            labelDisplay: 'block',
                            fieldType: InputType.STRING,
                            wrapperStyles: {
                                flexDirection: 'row',
                                display: staff.hstN === 0 ? 'none' : 'flex',
                            },
                        },
                    }}
                />
            </FormHolder>
        </Fragment>
    );
}

export default ContactDetails