import { useObservable } from "@libreact/use-observable";
// import { get } from "lodash";
import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { ROWS_PER_PAGE, START_PAGE } from "src/store/common/hooks/use-paging";
// import { ILocationState, PagingType } from "src/store/common/models/common.interface";
import { IPropertyGroup } from "../property-groups/models/property-group.interface";
import { propertyGroupQuery } from "../property-groups/property-group.query";
import { propertyGroupsService } from "../property-groups/property-group.service";

export type PropertyGroupsHookTuple = [IPropertyGroup[], boolean];

export function usePropertyGroups (): PropertyGroupsHookTuple {
    // const location = useLocation<ILocationState>();
    const [groups] = useObservable(propertyGroupQuery.groups$);
    const [loading] = useObservable(propertyGroupQuery.isLoading$);

    useEffect(() => {
        if (!groups.length) {
            propertyGroupsService.init();
        } else {
            // propertyGroupsService.fetchPropertyGroups(
            //     get(location, `state[${PagingType.PROPERTY_GROUPS}].activePage`, START_PAGE),
            //     get(location, `state[${PagingType.PROPERTY_GROUPS}].rowsPerPage`, ROWS_PER_PAGE)
            // );
        }
    }, []);

    return [groups, loading];
}