import React, {useCallback, useMemo, useState} from "react";
import {Header4} from "../../../ui/components/headers";
import CssClasses from './password-reset.module.scss';
import {RouteComponentProps, Redirect} from 'react-router-dom';
import {ParseQueryParams} from "../../../helpers/query-params-parser";
import {RoutesEnum} from "../../../routing/routes";
import {InputLabel} from "../../../ui/components/forms/labels/label";
import {Input} from "../../../ui/components/forms/inputs/input";
import {Button, ButtonVariant} from "../../../ui/components/buttons/button";
// import {userService} from "../../../../store/user/user.service";
import { authService } from "src/store/auth/auth.service";

interface ISearchQuery {
    token: string;
}

interface IProps extends RouteComponentProps {

}

interface IFormValues {
    password: string;
    confirmPassword: string;
}

export function PasswordReset({history, location}: IProps) {


    const [form, setForm] = useState<IFormValues>({
        password: '',
        confirmPassword: ''
    });
    const obj = ParseQueryParams<ISearchQuery>(location.search);

    const onChange = (e: React.ChangeEvent<any>) => {
        setForm({
            ...form,
            [e.target.id as keyof IFormValues]: e.target.value as string
        });
    }

    const isValid: boolean = useMemo(() => (form.confirmPassword === form.password) && !!form.password && !!form.password.length, [form])
    const onSubmit = useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if(isValid) {
            await authService.confirmNewPassword({
                newPassword: form.password,
                token: obj.token
            });
            history.push({
                pathname: RoutesEnum.SIGN_IN,
                search: '?status=password_reset_successfully'
            })
        }
    }, [isValid, form, history]);

    if(!obj.token) {
        return <Redirect to={RoutesEnum.SIGN_IN} />
    }

    return <div className={CssClasses.password_reset}>
        <Header4>
            Create New Password
        </Header4>
        <form onSubmit={onSubmit} className={CssClasses.password_reset_form}>
            <InputLabel style={{width: '100%'}} htmlFor="password">
                New Password
            </InputLabel>
            <Input onChange={onChange} type="password" id="password" placeholder="****" style={{width: '100%'}} />
            <InputLabel style={{width: '100%'}} htmlFor="confirmPassword">
                Confirm New Password
            </InputLabel>
            <Input valid={!form.confirmPassword || isValid} onChange={onChange} id="confirmPassword" type="password" placeholder="****" style={{width: '100%'}} />
            <Button
                disabled={!isValid}
                style={{width: 120, marginTop: 20}}
                btnVariant={ButtonVariant.PRIMARY}
                text="Submit"
            />
        </form>
    </div>
}