import { flow, get } from "lodash";
import { IProspect, LeadStatus } from "./models/prospect.interface";
import { IProspectSearchCriteria } from "./prospects-search/prospect-search.store";

const statusMap = new Map();
statusMap.set(LeadStatus.FOR_FOLLOW_UP, 'For Follow Up');
statusMap.set(LeadStatus.LOST, 'Lost');
statusMap.set(LeadStatus.APPLICANT, 'Applicant');

export interface IProspectsFilter {
    prospects: IProspect[];
    searchCriteria: IProspectSearchCriteria;
}

export function agentFilter({
    searchCriteria, 
    prospects = []
}: IProspectsFilter) {
    if (!get(searchCriteria, 'agentsAttached', []).length) {
        return {
            searchCriteria,
            prospects,
        };
    }

    const fp = prospects.filter((prospect: IProspect) => searchCriteria.agentsAttached.some(agent => Number(agent.id) === Number(prospect.agentAttachedId)));

    return {
        searchCriteria,
        prospects: fp,
    };
}

export function leadStatusFilter({
    searchCriteria, 
    prospects = [],
}: IProspectsFilter) {
    if (!get(searchCriteria, 'leadStatus', []).length) {
        return {
            searchCriteria,
            prospects,
        };
    }

    const fp = prospects.filter((prospect: IProspect) => searchCriteria.leadStatus.includes(prospect.leadStatus));

    return {
        searchCriteria,
        prospects: fp,
    };
}

export function budgetFromFilter({
    searchCriteria, 
    prospects = [],
}: IProspectsFilter) {
    if (get(searchCriteria, 'approximateBudget.from', null) === null && isNaN(get(searchCriteria, 'searchCriteria.approximateBudget.from', 'NaN'))) {
        return {
            searchCriteria,
            prospects,
        };
    }

    const fp = prospects.filter((prospect: IProspect) => Number(prospect.approxBudgetTo) >= Number(searchCriteria.approximateBudget.from));


    return {
        searchCriteria,
        prospects: fp,
    };
    
}

export function budgetToFilter({
    searchCriteria, 
    prospects = [],
}: IProspectsFilter) {
    if (get(searchCriteria, 'approximateBudget.to', null) === null && isNaN(get(searchCriteria, 'searchCriteria.approximateBudget.to', 'NaN'))) {
        return {
            searchCriteria,
            prospects,
        };
    }

    const fp = prospects.filter((prospect: IProspect) => Number(prospect.approxBudgetFrom) <= Number(searchCriteria.approximateBudget.to));

    return {
        searchCriteria,
        prospects: fp,
    };
}

export function bedroomsFromFilter({
    searchCriteria,
    prospects = [],
}: IProspectsFilter) {
    if (get(searchCriteria, 'bedrN.from', null) === null && isNaN(get(searchCriteria, 'searchCriteria.bedrN.from', 'NaN'))) {
        return {
            searchCriteria,
            prospects,
        };
    }

    const fp = prospects.filter((prospect: IProspect) => Number(prospect.bedrN) >= Number(searchCriteria.bedrN.from));

    return {
        searchCriteria,
        prospects: fp,
    };
}

export function bedroomsToFilter({
    searchCriteria, 
    prospects = [],
}: IProspectsFilter) {
    if (get(searchCriteria, 'bedrN.to', null) === null && isNaN(get(searchCriteria, 'searchCriteria.bedrN.to', 'NaN'))) {
        return {
            searchCriteria,
            prospects,
        };
    }

    const fp = prospects.filter((prospect: IProspect) => Number(prospect.bedrN) <= Number(searchCriteria.bedrN.to));

    return {
        searchCriteria,
        prospects: fp,
    };
}

export function cityFilter({
    searchCriteria,
    prospects = [],
}: IProspectsFilter) {
    if (!get(searchCriteria, 'city', []).length) {
        return {
            searchCriteria,
            prospects,
        };
    }

    const fp = prospects.filter((prospect: IProspect) => searchCriteria.city.some(c => c.name === prospect.location));

    return {
        searchCriteria,
        prospects: fp,
    };
}

export function notificationMethodsFilter({
    searchCriteria,
    prospects = [],
}: IProspectsFilter) {
    if (!get(searchCriteria, 'notificationMethod', []).length) {
        return prospects;
    }

    return prospects.filter((prospect: IProspect) => searchCriteria.notificationMethod.some(nm => prospect.notificationIds?.includes(nm.id as number)));
}

export const getFilteredProspects = flow([
    agentFilter,
    leadStatusFilter,
    budgetFromFilter,
    budgetToFilter,
    bedroomsFromFilter,
    bedroomsToFilter,
    cityFilter,
    notificationMethodsFilter,
]);