import { createEntityStore, EntityState } from "@datorama/akita";
import { IProperty } from "./models/property.interface";

export enum PropertiesImportingState {
    NOT_STARTED,
    IN_PROGRESS,
    SUCCESSFULLY_FINISHED,
    ERROR
}

export interface IPropertiesState extends EntityState {
    properties: IProperty[];
    totalRecordsNumber: number;
    selectedProperty?: IProperty;
    lastImported?: string | number;
    importingState: PropertiesImportingState;
    selectedProperties: {
        data: IProperty[];
        total: number;
    };
    messageHistory: any[];
    isApplyFilters: boolean;
    isDeleteFilter: boolean;
}

const initialState: IPropertiesState = {
    properties: [],
    selectedProperties: {
        data: [],
        total: 0,
    },
    totalRecordsNumber: 0,
    importingState: PropertiesImportingState.NOT_STARTED,
    messageHistory: [],
    isApplyFilters: false,
    isDeleteFilter: false,
}

export const propertiesStore = createEntityStore<IPropertiesState>(initialState, {
    name: 'properties',
});