import { ISort } from "../../properties/search/search-properties.store";
import { INamedEntity } from "../../properties/models/named-entity.interface";
import { IStaff } from "src/store/staff/models/staff.inreface";
import { IStaffSearchCriteria, searchStaffInitialState, searchStaffStore } from './staff-search.store';

export function getSorting() {
    return searchStaffStore.getValue().sort;
}

export function setSorting(sorting: ISort<keyof IStaff>) {
    searchStaffStore.update(state => ({
        ...state,
        sort: sorting,
    }));
}

export function updateSelectionCriteria(data: Partial<IStaffSearchCriteria>) {
    searchStaffStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...state.selectedCriteria,
            ...data,
            prospectsLeading: {
                from: !isNaN(data.prospectsLeading?.from as number) ? data.prospectsLeading?.from : null,
                to: !isNaN(data.prospectsLeading?.to as number) ? data.prospectsLeading?.to : null,
            },
        }
    }))
}

export function deleteItem(item: INamedEntity) {
    searchStaffStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...state.selectedCriteria,
            roles: state.selectedCriteria.roles.filter(r => r !== item.id),
            prospectsLeading: {
                from: item.key === "prospectsLeadingF" ? null : state.selectedCriteria.prospectsLeading.from,
                to: item.key === "prospectsLeadingT" ? null : state.selectedCriteria.prospectsLeading.to,
            },
        }
    }))
}

export function removeAllSelection() {
    searchStaffStore.update(state => ({
        ...state,
        selectedCriteria: {
            ...searchStaffInitialState.selectedCriteria
        }
    }))
}

export function getCriteria() {
    return searchStaffStore.getValue().selectedCriteria;
}

export const searchStaffService = {
    getSorting,
    setSorting,
    updateSelectionCriteria,
    deleteItem,
    removeAllSelection,
    getCriteria,
};