import { useObservable } from "@libreact/use-observable";
// import { get } from "lodash";
import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { ROWS_PER_PAGE, START_PAGE } from "src/store/common/hooks/use-paging";
// import { ILocationState, PagingType } from "src/store/common/models/common.interface";
import { IProperty } from "../models/property.interface";
import { propertiesQuery } from "../properties.query";
import { propertiesService } from "../properties.service";

export type PropertiesHookTuple = [IProperty[], boolean];

export function useProperties (): PropertiesHookTuple {
    // const location = useLocation<ILocationState>();
    const [properties] = useObservable(propertiesQuery.properties$);
    const [fprop] = useObservable(propertiesQuery.selectFilteredProperties$);
    const [loading] = useObservable(propertiesQuery.isLoading$);

    useEffect(() => {
        if (!properties.length) {
            propertiesService.getProperties();
        } else {
            // propertiesService.fetchProperties(
            //     get(location, `state[${PagingType.PROPERTIES}].activePage`, START_PAGE),
            //     get(location, `state[${PagingType.PROPERTIES}].rowsPerPage`, ROWS_PER_PAGE),
            // );
        }
    }, []);

    return [fprop, loading];
}