import React from "react";
import {INamedEntity, ISelectableItem} from "../../../../store/properties/models/named-entity.interface";
import {guid} from "@datorama/akita";

import CssClasses from './list.module.scss';
import { CommonCheckbox } from "../forms/inputs/checkbox";

interface IProps {
    options?: ISelectableItem[];
    onSelect(item: INamedEntity): void;
}

export function List(props: IProps) {
    const {options = [], onSelect} = props;

    return <React.Fragment>
        <div className={CssClasses.searchable_list}>
            {options.map(option => {
                const id = `${option.id}-${option.name}`;
                const inputId = `${id}_${guid()}`;
                return <div className={CssClasses.search_item} key={guid()}>
                    <CommonCheckbox labelColor="#789EFF" checked={option.isSelected} onChange={() => onSelect(option)} id={inputId}/>
                    <label className={CssClasses.search_label} htmlFor={inputId}>
                        {option.name}
                    </label>
                </div>
            })}
        </div>
    </React.Fragment>
}