import { ChevronLeft } from '@material-ui/icons';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ContactDetails } from './tabs/contact-details';
import { RoutesEnum } from 'src/components/routing/routes';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Header3 } from 'src/components/ui/components/headers';
import { Panel, PanelType } from 'src/components/ui/components/panel';
import { ITab, Tabs } from 'src/components/ui/components/tabs/tabs';

import { useObservable } from '@libreact/use-observable';
import { staffService } from 'src/store/staff/staff.service';
import { currentStaff$, isLoading$, isUploading$ } from 'src/store/staff/staff.query';
import { BlockingLoader } from 'src/components/ui/components/loaders/blocking-loader';
import { StaffRole } from 'src/store/staff/models/staff.inreface';
import { Dropdown } from 'src/components/ui/components/dropdown/dropdown';
import { Avatar as StaffAvatar, AvatarSize, FallbackType } from 'src/components/ui/components/avatar/avatar';

import { get, isEqual } from 'lodash';
import { PersonIcon, SaveIcon } from 'src/components/ui/icons';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { ContentHolder } from 'src/components/ui/main-layout/layout';

import styles from '../staff-info/staff-info.module.scss'
import baseStyles from 'src/styles/base.module.scss';
import { uiQuery } from 'src/store/ui-storage/ui.query';
import { IUser } from 'src/store/user/models/user.interface';
import { authQuery } from 'src/store/auth/auth.query';

const AGENT = 'Agent';
const ADMIN = 'Administrator';
const STAFF = 'Staff';

const roles = [
    {
        label: AGENT,
        value: StaffRole.AGENT,
    },
    {
        label: STAFF,
        value: StaffRole.STAFF,
    },
    {
        label: ADMIN,
        value: StaffRole.ADMINISTRATOR,
    }
];

interface IStaffEditorProps extends RouteComponentProps<{id: string}> {
    staff: StaffDto;
}

export interface StaffDto {
    id?: number | string;
    firstName: string;
    lastName: string;
    name?: string;
    email: string;
    phoneNumber: string;
    alternNumber: string;
    role: StaffRole;
    prospectsLeading?: number;
    photo?: string;
    hstN?: number | string | null;
}

const initialFormState: StaffDto = {
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    phoneNumber: '',
    alternNumber: '',
    role: StaffRole.AGENT,
    prospectsLeading: 0,
    photo: '',
    hstN: '',
};

export const StaffEditorComponent: React.FC<IStaffEditorProps> = ({ location, history, match }) => {
    const [user] = useObservable(authQuery.selectUser$);

    const [editMode] = useState((match.params.id && location.pathname.includes('edit')));
    const [isSaving, setIsSaving] = useState(false);
    const [currentStaff] = useObservable(currentStaff$);
    const [formState, setFormState] = useState<Partial<StaffDto>>(currentStaff ? currentStaff : initialFormState);
    const [isLoading] = useObservable(isLoading$);
    const [isUploading] = useObservable(isUploading$);
    const [isMobile] = useObservable(uiQuery.isMobile$);

    useEffect(() => {
        if (currentStaff && editMode) {
            if (!formState.firstName || !formState.lastName) {
                setFormState(() => ({
                    ...formState,
                    photo: currentStaff?.photo || '',
                    firstName: currentStaff.name.split(' ')[0],
                    lastName: currentStaff.name.split(' ')[1],
                }));
            }
        }

        return () => {
            if (!history.location.pathname.includes('edit')) {
                staffService.resetCurrentStaff();
            }
        }
    }, [isSaving]);

    useEffect(() => {
        if (currentStaff) {
            const name = get(currentStaff, 'name', '').split(' ');
            const [firstName, ...lastName] = name;

            setFormState(() => ({
                ...formState,
                photo: currentStaff?.photo || '',
                firstName,
                lastName: lastName.join(' '),
            }));
        }

        if (!currentStaff && editMode && !isSaving) {
            staffService.fetchStaffById(Number(match.params.id));
        }
    }, [editMode, isLoading]);

    useEffect(() => {
        if (currentStaff) {
            setFormState((prevState) => ({
                ...prevState,
                ...currentStaff
            }));
        }
    }, [currentStaff]);

    const prepearedFormState = useMemo(() => {
        return {
            ...formState,
            photo: currentStaff?.photo || '',
            firstName: formState.firstName,
            lastName: formState.lastName,
        };
    }, [currentStaff, formState]);

    const handleValuesChange = (data: Partial<StaffDto>) => {
        setFormState((prevState) => ({
            ...prevState,
            ...data,
        })); 

        staffService.updateSelectedStaff(data);
    }

    const handleSelectChange = useCallback((key: keyof StaffDto) => (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();

        const value = e.target.value;

        setFormState((prevState) => ({
            ...prevState,
            [key]: value
        }))

        staffService.updateSelectedStaff({
            [key]: value
        });
    }, [formState])

    const handleSubmit = async () => {
        const { firstName, lastName, prospectsLeading, photo, hstN, ...rest } = formState;

        const staffDto = {
            ...rest,
            photo: photo !== null ? photo : '',
            name: `${prepearedFormState.firstName} ${prepearedFormState.lastName}`,
            hstN: formState.role !== StaffRole.AGENT ? 0 : hstN,
        }

        setIsSaving(true);

        if (currentStaff?.id) {
            const isOk = await staffService.updateStaff(staffDto as Partial<StaffDto>);

            if (isOk) {
                history.push('/profiles/staff');
            }
        } else {
            const isOk = await staffService.createStaff(staffDto as Partial<StaffDto>);

            if (isOk) {
                history.push('/profiles/staff');
            }
        }

        // staffService.resetCurrentStaff();
    }

    const uploadRequest = (files: FileList) => {
        const file = files[0];

        staffService.uploadPhoto(file);
    }

    const tabs: ITab[] = [
        {
            basePath: location.pathname,
            specificPath: '',
            children: <ContactDetails
                formState={formState}
                handleSelectChange={handleSelectChange}
                handleValuesChange={handleValuesChange}
                staff={formState} />,
            tabName: 'Contact Details'
        },
    ];

    if (editMode && (!formState || isLoading)) {
        return <BlockingLoader />
    }

    return (
        <ContentHolder noPadding smallPadding>
            <Link className={baseStyles.link_wrapper} to={RoutesEnum.STAFF_LIST}>
                <ChevronLeft/> <span style={{ fontFamily: 'ManropeThin' }}>Back</span>
            </Link>
            <Panel additionalStyles={
                isMobile ? { margin: '0 0 28px' } : { marginTop: 7 }
            } type={PanelType.EMPTY}>
                <div className={styles.top_wrapper}>
                    <Header3 style={{margin: '10px 0'}}>
                        { editMode ? 'Edit Staff' : 'Add Staff' }
                    </Header3>
                    {
                        !isMobile
                            ? (
                                <div className={styles.actionBarHolder}>
                                    <Button
                                        icon={<SaveIcon />}
                                        btnVariant={ButtonVariant.PRIMARY}
                                        onClick={handleSubmit}
                                        text="Save"
                                    />
                                </div>
                            )
                            : null
                    }
                </div>
            </Panel>
            <Panel additionalStyles={
                isMobile ? { 
                    margin: '0 0 30px',
                    width: '97.5%',
                } : {}
            }>
                <FormHolder
                    title="Staff"
                    icon={<PersonIcon />}>
                    <Form<Partial<StaffDto>>
                        type={FormType.DEFAULT}
                        data={prepearedFormState}
                        onChange={handleValuesChange}
                        fields={{
                            photo: {
                                index: 0,
                                label: 'Photo',
                                labelDisplay: 'block',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                height: 'auto',
                                validators: [],
                                groupStyles: {
                                    margin: '0 0 12px',
                                },
                                customElement: <StaffAvatar 
                                    src={prepearedFormState.photo} 
                                    size={AvatarSize.mid}
                                    fallbackType={FallbackType.ABBR}
                                    fallback={`${prepearedFormState.firstName} ${prepearedFormState.lastName}`}
                                    customRequest={uploadRequest}
                                    onRemove={() => staffService.deletePhoto()}
                                    loading={isUploading}
                                    upload />
                            },
                            firstName: {
                                index: 1,
                                label: 'First Name',
                                labelDisplay: 'block',
                                fieldType: InputType.STRING,
                                groupStyles: {
                                    margin: (user as IUser).role !== StaffRole.ADMINISTRATOR ? '10px 0 0' : 0,
                                },
                                height: 'auto',
                                placeholder: "First Name",
                                readonly: (user as IUser).role !== StaffRole.ADMINISTRATOR,
                                validators: [],
                            },
                            lastName: {
                                index: 2,
                                label: 'Last Name',
                                labelDisplay: 'block',
                                placeholder: 'Last Name',
                                fieldType: InputType.STRING,
                                groupStyles: {
                                    margin: (user as IUser).role !== StaffRole.ADMINISTRATOR ? '10px 0 0' : 0,
                                },
                                readonly: (user as IUser).role !== StaffRole.ADMINISTRATOR,
                                height: 'auto',
                            },
                            role: {
                                index: 3,
                                label: 'Role',
                                labelDisplay: 'block',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                height: 'auto',
                                customElement: <Dropdown
                                    onChange={handleSelectChange('role')}
                                    value={formState.role}
                                    disabled={(user as IUser).role !== StaffRole.ADMINISTRATOR}
                                    menuItems={roles.map(role => ({
                                        value: role.value,
                                        name: role.label,
                                        content: <div>{role.label}</div>
                                    }))} />
                            }
                        }}
                    />
                </FormHolder>
            </Panel>
            <Tabs
                tabs={tabs}
            />
            {
                isMobile
                    ? (
                        <Panel type={PanelType.EMPTY}>
                            <div className={baseStyles.save_bar_mobile}>
                                <Button
                                    icon={<SaveIcon />}
                                    btnVariant={ButtonVariant.PRIMARY}
                                    onClick={handleSubmit}
                                    text="Save"
                                />
                            </div>
                        </Panel>
                    ) : null
            }
        </ContentHolder>
    );
}

export const StaffEditor = React.memo(StaffEditorComponent, (prevProps, nextProps) => isEqual(prevProps, nextProps));