import { useObservable } from '@libreact/use-observable';
import React, { ChangeEvent, Fragment, useMemo, useRef, useState } from 'react';
import posed from 'react-pose';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
// import { Dropdown } from 'src/components/ui/components/dropdown/dropdown';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Sidenav } from 'src/components/ui/components/sidenav';
import { ChevronRightIcon } from 'src/components/ui/icons';
import { appointmentService } from 'src/store/appointment/appointment.service';
// import { IExportParams } from 'src/store/appointment/models/export-params.interface';
import { uiQuery } from 'src/store/ui-storage/ui.query';
import { DeviceType } from 'src/store/ui-storage/ui.store';
import { PrintCalendar } from './print-calendar';

import styles from '../appointments.module.scss';
import { useLocation } from 'react-router-dom';

const Holder = posed.div({
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    transition: { duration: 300 },
});

const exportRoutes = new Map();
exportRoutes.set('/options', { active: true });
exportRoutes.set('/daily', { active: false });
exportRoutes.set('/one', { active: false });
exportRoutes.set('/print', { active: false });

const exportTypesMap = new Map();
exportTypesMap.set('/one', 'ONEDAYS');
exportTypesMap.set('/daily', 'DAILY');

interface IRenderExportDaily {
    name: string;
    active?: boolean;
    formState: IFormState;
    onChange: (data: IFormState) => void;
    
}

const RenderExportDaily: React.FC<IRenderExportDaily> = ({ active, formState, onChange }) => {
    return (
        <Holder pose={ active ? 'visible' : 'hidden' }>
            <div className={styles.export_chose}>Pick the date&time for export data</div>
            <ul className={styles.export_holder}>
                <li className={styles.export_title}>
                    <span>Export Daily agenda</span>
                </li>
                <FormHolder>
                    <Form<any>
                        type={FormType.DEFAULT}
                        data={formState}
                        wrapperStyles={{
                            margin: 0,
                        }}
                        onChange={onChange}
                        fields={{
                            date: {
                                index: 0,
                                label: 'Date & time',
                                labelDisplay: 'block',
                                fieldType: InputType.DATE,
                                height: 'auto',
                                labelStyles: {
                                    padding: 0,
                                }
                            },
                        }}
                    />
                </FormHolder>
            </ul>
        </Holder>
    );
}

interface IRenderExportOne {
    name: string;
    active?: boolean;
    formState: IFormState;
    onChange: (data: IFormState) => void;
    onFormatChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RenderExportOne: React.FC<IRenderExportOne> = ({ active, formState, onChange, onFormatChange }) => {
    return (
        <Holder pose={ active ? 'visible' : 'hidden' }>
            <div className={styles.export_chose}>Pick the format, date&time for export data</div>
            <ul className={styles.export_holder}>
                <li className={styles.export_title}>
                    <span>Export One day’s appointment</span>
                </li>
                <FormHolder>
                    <Form<any>
                        type={FormType.DEFAULT}
                        data={formState}
                        wrapperStyles={{
                            margin: 0,
                        }}
                        onChange={onChange}
                        fields={{
                            date: {
                                index: 0,
                                label: 'Date & time',
                                labelDisplay: 'block',
                                fieldType: InputType.DATE,
                                height: 'auto',
                                labelStyles: {
                                    padding: 0,
                                }
                            },
                            formatType: {
                                index: 1,
                                label: 'Format',
                                labelDisplay: 'block',
                                height: 'auto',
                                labelStyles: {
                                    padding: 0,
                                },
                                fieldType: InputType.DROPDOWN,
                                selectableItems: [
                                    {
                                        value: 'EXCEL',
                                        label: <div>Excel</div>
                                    },
                                    {
                                        value: 'PDF',
                                        label: <div>PDF</div>
                                    },
                                ]
                            },
                        }}
                    />
                </FormHolder>
            </ul>
        </Holder>
    );
}

interface IExportPath {
    from: string;
    to: string;
}

interface IRenderExportOptions {
    name: string;
    active?: boolean;
    onClick: (path: IExportPath) => void;
}

const RenderExportOptions: React.FC<IRenderExportOptions> = ({ active, name, onClick }) => {
    const location = useLocation();

    return (
        <Holder pose={ active ? 'visible' : 'hidden' }>
            <div className={styles.export_chose}>Choose from the list below</div>
            <ul className={styles.export_holder}>
                <li className={styles.export_item} onClick={() => onClick({
                    from: '/options',
                    to: '/daily',
                })}>
                    <span>Export Daily agenda</span>
                    <ChevronRightIcon />
                </li>
                <li className={styles.export_item} onClick={() => onClick({
                    from: '/options',
                    to: '/one',
                })}>
                    <span>Export One day’s appointment</span>
                    <ChevronRightIcon />
                </li>
                {
                    location.pathname.includes('calendar-view')
                        ? (
                            <li className={styles.export_item} onClick={() => onClick({
                                from: '/options',
                                to: '/print',
                            })}>
                                <span>Print</span>
                                <ChevronRightIcon />
                            </li>
                        ) : null
                }
            </ul>
        </Holder>
    );
}

interface ISwitchOptions {
    children: React.ReactNode | React.ReactNode[];
    routes: any;
}

const SwitchOptions: React.FC<ISwitchOptions> = ({ children, routes }) => {
    let element;

    React.Children.forEach(children, child => {
        if (React.isValidElement(child)) {
            const isActive = routes.get(child.props?.name).active;

            if (isActive) {
                element = child;
            }
        }
    })

    return element
        ? React.cloneElement(element, { active: true })
        : null;
}

interface IExportCalendar {
    open: boolean;
    loading?: boolean;
    onClose: () => void;
}

interface IFormState {
    date: Date;
    formatType: string;
}

const inititalFormState: IFormState = {
    date: new Date(),
    formatType: 'EXCEL',
};

export const ExportCalendar: React.FC<IExportCalendar> = ({ open, loading, onClose }) => {
    const printRef = useRef<any>(null);
    const [formState, setFormState] = useState(inititalFormState);
    const [activePath, setActivePath] = useState<IExportPath>({
        from: '/',
        to: '/options',
    });
    const [deviceType] = useObservable(uiQuery.deviceType$);
    const [printing, setPrinting] = useState(false);

    const sidenavWidth = useMemo(() => {
        const DESKTOP_SIDENAV_WIDTH = 542;
        if (deviceType === DeviceType.MOBILE) {
            return '100%';
        } else {
            return DESKTOP_SIDENAV_WIDTH;
        }
    }, [deviceType]);

    const handleValuesChange = (data: IFormState) => {
        setFormState(prevState => ({
            ...prevState,
            ...data,
        }));
    }

    const handleFormatChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState(prevState => ({
            ...prevState,
            format: e.target.value,
        }));
    }

    const handleBack = () => {
        exportRoutes.set(activePath.from, { active: true });
        exportRoutes.set(activePath.to, { active: false });

        setActivePath({
            from: activePath.to,
            to: activePath.from,
        });

        setFormState(inititalFormState);
    }

    const handleClick = (path: IExportPath) => {
        exportRoutes.set(path.from, { active: false });
        exportRoutes.set(path.to, { active: true });

        setActivePath(path);
    }

    const handleExport = () => {
        const { date, formatType } = formState;
        const exportParams = {
            date,
            formatType,
            type: exportTypesMap.get(activePath.to),
        };

        appointmentService.exportCalendar(exportParams);
    }

    const handlePrint = () => {
        setPrinting(true);
        const img = printRef.current.src;

        const isMonth = document.querySelector('.rbc-month-view');
        const orientation = isMonth ? 'landscape' : 'portrait';

        if (img) {
            appointmentService.printCalendar(img, orientation)
                .then(() =>  setPrinting(false));
        }
    }

    const handleClose = () => {
        onClose();

        setTimeout(() => {
            exportRoutes.set('/options', { active: true });
            exportRoutes.set('/daily', { active: false });
            exportRoutes.set('/one', { active: false });
            exportRoutes.set('/print', { active: false });
        }, 500);
    }

    return (
        <Sidenav<any> 
            header={<>
                <span>
                    Export Appointments
                </span>
            </>}
            footer={
                <Fragment>
                    {
                        !exportRoutes.get('/options').active
                            ? (
                                <Button 
                                    btnVariant={ButtonVariant.OUTLINE} 
                                    text="Back"
                                    onClick={handleBack} />
                            ) : <div style={{ height: '28px' }} />
                    }
                    {
                        !exportRoutes.get('/options').active
                            ? exportRoutes.get('/print').active
                                ? (
                                    <Button 
                                        style={{
                                            marginLeft: 12
                                        }} 
                                        btnVariant={ButtonVariant.PRIMARY} 
                                        text="Print"
                                        isLoading={printing}
                                        loadingText="Printing..."
                                        onClick={handlePrint}
                                        disabled={activePath.to === '/options'} />
                                )
                                : (
                                    <Button 
                                        style={{
                                            marginLeft: 12
                                        }} 
                                        btnVariant={ButtonVariant.PRIMARY} 
                                        text="Export"
                                        isLoading={loading}
                                        loadingText="Exporting..."
                                        onClick={handleExport}
                                        disabled={activePath.to === '/options'} />
                                )
                            : null
                    }
                </Fragment>
            } 
            isOpen={open}
            width={sidenavWidth} 
            onClose={handleClose}>
                <SwitchOptions routes={exportRoutes}>
                    <RenderExportOptions name="/options" onClick={handleClick} />
                    <RenderExportOne name="/one" formState={formState} onChange={handleValuesChange} onFormatChange={handleFormatChange} />
                    <RenderExportDaily name="/daily" formState={formState} onChange={handleValuesChange} />
                    <PrintCalendar ref={printRef} name="/print" />
                </SwitchOptions>
        </Sidenav>
    );
}