import { useEffect } from 'react'
import { useObservable } from '@libreact/use-observable'
import { adminSettingsQuery } from '../admin-settings.query';
import { adminSettingsService } from '../admin-settings.service';
import { ICalendarSettings } from '../models/calendar.interface';

export type CalendarSettingsHookTuple = [Partial<ICalendarSettings>, boolean];

export function useCalendarSettings(): CalendarSettingsHookTuple {
    const [calendarSettings] = useObservable(adminSettingsQuery.calendarSettings$);
    const [loading] = useObservable(adminSettingsQuery.loading$);

    useEffect(() => {
        adminSettingsService.fetchCalendarSettings();
    }, []);

    return [calendarSettings, loading];
}