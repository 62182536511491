import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Panel, PanelType} from "../../../ui/components/panel";
import CssClasses from './appointments.module.scss';
import {Header3} from "../../../ui/components/headers";
import {useObservable} from "@libreact/use-observable/dist";
import {appointmentQuery} from "../../../../store/appointment/appointment.query";
import {appointmentService} from "../../../../store/appointment/appointment.service";
import {AppointmentsStatistics} from "./components/statistics";
import {SearchInput, SearchInputType} from "../../../ui/components/forms/inputs/search";
import {FavButton} from "../../../ui/components/buttons/fav-button";
import {ExportIcon, MessageIcon, SettingsIcon, SettingsOutlinedIcon, ElipsIcon} from "../../../ui/icons";
import {Button, ButtonVariant} from "../../../ui/components/buttons/button";
import {Add} from "@material-ui/icons";
import {Table} from "../../../ui/components/table";
import {IDataTableColumn, IDataTableConditionalRowStyles} from "react-data-table-component";
import {INumerated} from "../../../../store/properties/models/numerated.interface";
import {AppointmentType, IAppointment} from "../../../../store/appointment/models/appointment.interface";
import {COLORS} from "../../../ui/theme/variables/colors";
import {Sidenav} from "../../../ui/components/sidenav";
import {ChipViewTypes, FiltersList} from "../../../ui/components/filters-list";
import {INamedEntity} from "../../../../store/properties/models/named-entity.interface";
import moment from "moment";
import {Form, FormFieldDividerType, InputType} from "../../../ui/components/forms/formBuilder/form";
import {IAppointmentFilters} from "../../../../store/appointment/models/filters.interface";
import {initialAppointmentFilters} from "../../../../store/appointment/appointment.store";
import {useHistory, useLocation} from 'react-router-dom';
import {CreateAppointment} from "./components/create-appointment";
import {Avatar, AvatarSize, FallbackType} from "../../../ui/components/avatar/avatar";
import {ExportCalendar} from './components/export-calendar';
import {uiQuery} from "../../../../store/ui-storage/ui.query";
import {DeviceType} from "../../../../store/ui-storage/ui.store";
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { ContentHolder } from 'src/components/ui/main-layout/layout';
import { TitleHolder } from '../../properties/pages/list';
import { searchUrlMap } from 'src/store/autocomplete/autocomplete.store';
import { SearchType } from 'src/store/autocomplete/models/autocomplete.interface';
import { SearchableList } from 'src/components/ui/components/searchable-list/searchable-list';
import { autocompleteService } from 'src/store/autocomplete/autocomplete.service';
import { EMPTY_STRING_PLACEHOLDER } from 'src/store/tenants/tenants.config';
import { usePaging } from 'src/store/common/hooks/use-paging';
import { PagingType } from 'src/store/common/models/common.interface';
import { guid } from '@datorama/akita';
import { useAppointmentsSearch } from 'src/store/calendar/hooks/use-appointments-search';


const buildConditionalRowStyles = () => {
    const availableColors = {
        [AppointmentType.Meeting]: COLORS.BLUE,
        [AppointmentType.Showing]: COLORS.ORANGE,
        [AppointmentType.Other]: COLORS.GREEN
    };

    const conditionalRowStyles: IDataTableConditionalRowStyles<IAppointment & INumerated>[] = [];

    Object.entries(availableColors).forEach(([key, color]) => {
        conditionalRowStyles.push({
            when: (row) => (row.i % 2 === 0) && row.type === key,
            style: {
                borderLeft: `2px solid ${color}`,
                backgroundColor: COLORS.BACKGROUND_GRAY,
            }
        });
        conditionalRowStyles.push({
            when: (row) => (row.i % 2 === 1) && row.type === key,
            style: {
                borderLeft: `2px solid ${color}`,
            }
        });
    });

    return conditionalRowStyles;
}

const conditionalRowStyles = buildConditionalRowStyles();

const styles: Record<string, React.CSSProperties> = {
    [AppointmentType.Showing]: {
        // width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: COLORS.ORANGE,
        textTransform: 'capitalize',
        fontSize: '14px',
    },
    [AppointmentType.Meeting]: {
        // width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: COLORS.BLUE,
        textTransform: 'capitalize',
        fontSize: '14px',
    },
    [AppointmentType.Other]: {
        // width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: COLORS.GREEN,
        textTransform: 'capitalize',
        fontSize: '14px',
    }
}

const columns = (clickHandler: (appointment: any) => void) => [
    {
        name: 'Type',
        selector: 'type',
        sortable: true,
        minWidth: '150px',
        maxWidth: '200px',
        cell: (row: IAppointment) => {
            return (
                <TitleHolder onClick={() => clickHandler(row)} id={String(row.id)} style={styles[row.type]}>
                    <div style={{ fontSize: 5, margin: '3px 4px 0 0' }}>
                        <ElipsIcon />
                    </div>
                    {row.type ? row.type.toLowerCase() : AppointmentType.Meeting.toLowerCase()}
                </TitleHolder>
            )
        }
    },
    {
        name: 'Appointment',
        selector: 'id',
        sortable: false,
        minWidth: '150px',
        cell: (row: IAppointment) => {
            return (
                <div onClick={() => clickHandler(row)} id={String(row.id)}>
                    <div style={{fontFamily: 'ManropeSemibold', margin: '0 0 4px'}}>
                        {row.title}
                    </div>
                    <div style={{fontFamily: 'ManropeThin'}}>
                        {moment(row.datetimeFrom).format('MM.DD.YYYY')} &nbsp;
                        {moment(row.datetimeFrom).format('hh:mm')} - {moment(row.datetimeTo).format('hh:mm A')}
                    </div>
                </div>
            )
        }
    },
    {
        name: 'Agent',
        selector: 'currentAgent',
        sortable: true,
        minWidth: '150px',
        cell: (row: IAppointment) => {
            if (
                (Array.isArray(row.agents) && row.agents.length) ||
                (Array.isArray(row.staffs) && row.staffs.length)
            ) {
                return (
                    <div onClick={() => clickHandler(row)} id={String(row.id)} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        {
                            [
                                ...(row?.agents || []),
                                ...(row?.staffs || []),
                            ].map((agent: any) => (
                                <div key={guid()} style={{
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: '0 0 10px',
                                }}>
                                    <Avatar
                                        src={agent?.photo}
                                        size={AvatarSize.tiny}
                                        fallbackType={FallbackType.ABBR}
                                        fallback={agent?.name}/>
                                    <div>{agent?.name}</div>
                                </div>
                            ))
                        }
                    </div>
                );
            } else {
                return <div onClick={() => clickHandler(row)} id={String(row.id)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Avatar
                        src={row.agent?.photo}
                        size={AvatarSize.tiny}
                        fallbackType={FallbackType.ABBR}
                        fallback={row.agent?.name}/>
                    <div>{row.agent?.name}</div>
                </div>
            }
        }
    },
    {
        name: 'Properties',
        selector: 'id',
        sortable: false,
        minWidth: '150px',
        cell: (row: IAppointment) => {
            if (Array.isArray(row.properties)) {
                return <div onClick={() => clickHandler(row)} id={String(row.id)}>
                    {row.properties.map(p => (
                        <p key={guid()}>
                            {p.unitAddress}
                        </p>
                    ))}
                </div>
            }

            return null;
        }
    },
    {
        name: 'Notes',
        selector: 'notes',
        sortable: true,
        minWidth: '150px',
        cell: (row: IAppointment) => {
            return <div onClick={() => clickHandler(row)} id={String(row.id)}>
                {(row.notes && row.notes.length > 0) ? row.notes : EMPTY_STRING_PLACEHOLDER}
            </div>
        },
        allowOverflow: false,
    },
];

export function Appointments() {
    const history = useHistory();
    const [count] = useObservable(appointmentQuery.count$)
    const [statistics] = useObservable(appointmentQuery.statistics$);
    const [appointments] = useObservable(appointmentQuery.appointments$);
    const [isLoading] = useObservable(appointmentQuery.isLoading$);
    const [isExporting] = useObservable(appointmentQuery.isExporting$);
    // const [filters] = useObservable(appointmentQuery.filters$);
    const [newAppointment] = useObservable(appointmentQuery.newAppointment$);
    const [isEditing] = useObservable(appointmentQuery.isEditing$);
    const [deviceType] = useObservable(uiQuery.deviceType$);
    const [isMobile] = useObservable(uiQuery.isMobile$);
    // const [allAppliedFilters] = useObservable(appointmentQuery.allAppliedFilters$);
    const [isFilterOpened, setIsFilterOpened] = useState(false);
    const [isExportOpened, setIsExportOpened] = useState(false);
    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

    const [paging, setPaging] = usePaging(PagingType.APPOINTMENTS);
    const [allAppliedFilters, filters] = useAppointmentsSearch(paging);

    const [searchByFilters, setSearchByFilters] = useState({
        agentsQ: '',
        propertiesQ: ''
    });

    const sidenavWidth = useMemo(() => {
        const DESKTOP_SIDENAV_WIDTH = 542;
        if(deviceType === DeviceType.MOBILE) {
            return  '100%';
        } else {
            return DESKTOP_SIDENAV_WIDTH;
        }
    }, [deviceType]);

    const {search} = useLocation();

    const shouldOpenAppointmentWithId = useMemo(() => {
        const params = new URLSearchParams(search);
        return params.get('open');
    }, [search]);

    useEffect(() => {
        if (shouldOpenAppointmentWithId) {
            autocompleteService.init();
            setIsCreateModalOpened(true);
        }
        if (shouldOpenAppointmentWithId !== 'new') {
            appointments.find(a => a.id === parseInt(shouldOpenAppointmentWithId!))
        }
    }, [shouldOpenAppointmentWithId, appointments]);

    useEffect(() => {
        appointmentService.init();

        return () => {
            removeAllSelection(true);
            appointmentService.resetAppointments();
        };
    }, []);

    const smallMargin: React.CSSProperties = {
        marginLeft: 10,
    };


    const onApply = () => {
        appointmentService.fetchAppointments(paging.activePage, paging.rowsPerPage);
        setIsFilterOpened(false);
        document.body.style.overflow = 'inherit';
    }

    // const changeSearch = (q: string) => {
    //     appointmentService.search(q);
    // }

    const changePage = (page: number) => {
        // table component numerating start from 1, BE expects 0 as the first page
        setPaging(page, paging.rowsPerPage);
        appointmentService.fetchAppointments(page - 1, paging.rowsPerPage);
    }

    const changeRowsNumber = (rowPerPage: number, page: number) => {
        // setRowsPerPage(rowPerPage);
        setPaging(page, rowPerPage);
        appointmentService.fetchAppointments(page - 1, rowPerPage);
    }

    const onSort = (column: IDataTableColumn<IAppointment & INumerated>, sortDirection: "desc" | "asc") => {
        appointmentService.setSorting({
            type: sortDirection,
            field: column.selector as keyof IAppointment
        });
        appointmentService.fetchAppointments();
    }


    const onClose = useCallback((cachedData?: Partial<IAppointmentFilters>) => {
        if (cachedData) {
            appointmentService.changeFilterCriteria(cachedData);
        }
        setIsFilterOpened(false);
        appointmentService.setEditing(false);
    }, [setIsFilterOpened]);

    const openCreateModal = useCallback(() => {
        setIsCreateModalOpened(true);
        autocompleteService.init();
        appointmentService.setEditing(false);
        appointmentService.resetAppointment();
    }, [setIsCreateModalOpened])

    const onFilterDelete = (e: INamedEntity) => {
        const fls = (filters[e.key as keyof IAppointmentFilters] as any);

        appointmentService.changeFilterCriteria({
            ...filters,
            [e.key as string]: Array.isArray(fls) 
                ? fls.filter((item: INamedEntity) => item.id !== e.id)
                : typeof fls === 'string' 
                    ? AppointmentType.Any
                    : null
        });

        appointmentService.fetchAppointments(0, paging.rowsPerPage);
    }

    const removeAllSelection = (isReset: boolean = false) => {
        setIsFilterOpened(false);
        autocompleteService.resetSearchData();
        appointmentService.changeFilterCriteria(initialAppointmentFilters);
        document.body.style.overflow = 'inherit';

        if (!isReset) {
            appointmentService.fetchAppointments(0, paging.rowsPerPage);
        }
    }

    const onRowClicked = async (appointment: IAppointment) => {
        setIsCreateModalOpened(true);
        autocompleteService.init();
        await appointmentService.openAppointmentById(appointment.id);
        appointmentService.setEditing(true);
    }

    return <ContentHolder noPadding smallPadding>
        <Panel type={PanelType.EMPTY} additionalStyles={
            isMobile ? { padding: '35px 0 0' } : {}
        }>
            {
                !isMobile
                    ? (
                        <div className={CssClasses.header_wrapper}>
                            <Header3 style={{margin: 0, display: 'flex', alignItems: 'center'}}>
                                Appointments
                                <span className={CssClasses.total}>
                                    ({count})
                                </span>
                            </Header3>
                        </div>
                    )
                    : null
            }
        </Panel>
        <Panel twiceBorder additionalStyles={
            isMobile ? { margin: '0 0 16px' } : {}
        }>
            <AppointmentsStatistics statistics={statistics}/>
        </Panel>
        <Panel type={PanelType.EMPTY}>
            {
                isMobile
                    ? (
                        <div className={CssClasses.list_action_bar_right_top}>
                            <Button
                                icon={<ExportIcon style={{fontSize: 14}}/>}
                                btnVariant={ButtonVariant.OUTLINE}
                                // onClick={appointmentService.exportCalendar}
                                onClick={() => setIsExportOpened(true)}
                                text={isMobile ? '' : 'Export'}
                            />
                            <Button
                                icon={<MessageIcon />}
                                style={smallMargin}
                                btnVariant={ButtonVariant.OUTLINE}
                                text={isMobile ? '' : 'Message'}
                                onClick={() => history.push('/message', { 
                                    from: 'appointments', 
                                    backLink: 'appointments',
                                    category: 'calendar',
                                })}
                            />
                            <Button
                                style={{marginLeft: 10}}
                                icon={<Add style={{fontSize: 14}}/>}
                                btnVariant={ButtonVariant.PRIMARY}
                                onClick={openCreateModal}
                                text={isMobile ? 'Schedule' : 'Schedule Appointment'}
                            />
                        </div>
                    ) : null
            }
            <div className={CssClasses.list_action_bar}>
                <div className={CssClasses.list_action_bar_left}>
                    <SearchInput
                        id="appointments-search"
                        // onChange={changeSearch}
                        inputType={SearchInputType.PRIMARY}
                        placeholderText="Search..."
                    />
                    <FavButton
                        onClick={() => setIsFilterOpened(true)} style={{marginLeft: 15}}
                        icon={<SettingsIcon/>}
                        badge={allAppliedFilters.length > 0 ? allAppliedFilters.length.toString() : undefined}
                    />
                </div>
                {
                    !isMobile
                        ? (
                            <div className={CssClasses.list_action_bar_right_top}>
                                <Button
                                    icon={<ExportIcon style={{fontSize: 14}}/>}
                                    btnVariant={ButtonVariant.OUTLINE}
                                    // onClick={appointmentService.exportCalendar}
                                    onClick={() => setIsExportOpened(true)}
                                    text={isMobile ? '' : 'Export'}
                                />
                                <Button
                                    icon={<MessageIcon />}
                                    style={smallMargin}
                                    btnVariant={ButtonVariant.OUTLINE}
                                    text={isMobile ? '' : 'Message'}
                                    onClick={() => history.push('/message', { 
                                        from: 'appointments', 
                                        backLink: 'appointments',
                                        category: 'calendar',
                                    })}
                                />
                                <Button
                                    style={{marginLeft: 10}}
                                    icon={<Add style={{fontSize: 14}}/>}
                                    btnVariant={ButtonVariant.PRIMARY}
                                    onClick={openCreateModal}
                                    text={isMobile ? 'Schedule' : 'Schedule Appointment'}
                                />
                            </div>
                        ) : null
                }
            </div>
        </Panel>
        {allAppliedFilters.length > 0 && <Panel additionalStyles={
            isMobile ? { 
                margin: '0 0 15px',
                width: '97.5%',
            } : {}
        }>
            <FiltersList filtersTitleName="" onDelete={onFilterDelete} items={allAppliedFilters}/>
        </Panel>}
        <Panel noPadding sideBorder>
            <Table<IAppointment>
                onChangePage={changePage}
                // onSelectionChange={handleSelectionChange}
                totalRecordsNumber={count}
                paginationPerPage={paging.rowsPerPage}
                onChangeRowsNumber={changeRowsNumber}
                columns={columns(onRowClicked)}
                onRowClicked={onRowClicked}
                onSort={onSort}
                conditionalRowStyles={conditionalRowStyles}
                loading={isLoading && !appointments.length}
                data={appointments}
                highlightOnHover
                highlightRow={['fullname']}
                activePage={paging.activePage}
            />
        </Panel>
        <Sidenav<any>
            header={<Fragment>
                <SettingsOutlinedIcon />
                <span>
                    Filters
                </span>
            </Fragment>}
            footer={<Fragment>
                <Button
                    btnVariant={ButtonVariant.OUTLINE}
                    onClick={() => removeAllSelection(false)}
                    text="Clear All"
                />
                <Button
                    style={{marginLeft: 12}}
                    onClick={onApply}
                    btnVariant={ButtonVariant.PRIMARY}
                    text="Apply"
                />
            </Fragment>}
            width={sidenavWidth}
            withIcon
            isOpen={isFilterOpened}
            onClose={onClose}>
            <div className={CssClasses.filters_wrapper}>
                <FiltersList
                    filtersTitleName="Choose From The List Below"
                    type={ChipViewTypes.BLOCK}
                    items={allAppliedFilters}
                    onDelete={onFilterDelete}
                />
            </div>
            <FormHolder>
                <Form<Partial<IAppointmentFilters>>
                    data={filters}
                    onChange={appointmentService.changeFilterCriteria}
                    wrapperStyles={{
                        margin: '0 0 0 20px'
                    }}
                    fields={{
                        type: {
                            index: 0,
                            labelStyles: {
                                padding: 0,
                            },
                            height: 'auto',
                            withDividers: FormFieldDividerType.BOTTOM,
                            label: 'Type',
                            labelDisplay: 'block',
                            fieldType: InputType.DROPDOWN,
                            selectableItems: [
                                {value: AppointmentType.Showing, label: 'Showing'},
                                {value: AppointmentType.Meeting, label: 'Meeting'},
                                {value: AppointmentType.Other, label: 'Other'},
                                {value: AppointmentType.Any, label: 'Any Type'}
                            ],
                        },
                        from: {
                            index: 1,
                            label: 'Date: From',
                            labelDisplay: 'block',
                            labelStyles: {
                                padding: 0,
                            },
                            height: 'auto',
                            fieldType: InputType.DATE
                        },
                        to: {
                            index: 2,
                            label: 'Date: To',
                            labelDisplay: 'block',
                            height: 'auto',
                            labelStyles: {
                                padding: 0,
                            },
                            withDividers: FormFieldDividerType.BOTTOM,
                            fieldType: InputType.DATE
                        },
                        agents: {
                            index: 3,
                            label: 'Agent',
                            labelDisplay: 'block',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            labelStyles: {
                                padding: 0,
                            },
                            height: 'auto',
                            withDividers: FormFieldDividerType.BOTTOM,
                            customElement: <Fragment>
                                <SearchableList
                                    id="agents"
                                    url={searchUrlMap.get(SearchType.STAFF)}
                                    searchType={SearchType.AGENTS}
                                    selectedCriteria={filters.agents}
                                    onSearchChange={(q: string) => {
                                        setSearchByFilters({
                                            ...searchByFilters,
                                            agentsQ: q
                                        })
                                    }}
                                    onSelect={(item) => {
                                        const alreadyIncluded = filters.agents!.find(a => a.id === item.id);

                                        if (alreadyIncluded) {
                                            appointmentService.changeFilterCriteria({
                                                ...filters,
                                                agents: filters.agents!.filter(a => a.id !== item.id)
                                            })
                                        } else {
                                            appointmentService.changeFilterCriteria({
                                                ...filters,
                                                agents: [
                                                    ...filters.agents!,
                                                    item
                                                ]
                                            })
                                        }
                                    }}
                                />
                            </Fragment>
                        },
                        properties: {
                            index: 4,
                            label: 'Property',
                            labelDisplay: 'block',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            labelStyles: {
                                padding: 0,
                            },
                            height: 'auto',
                            customElement: <Fragment>
                                <SearchableList
                                    id="properties"
                                    url={searchUrlMap.get(SearchType.PROPERTIES)}
                                    searchType={SearchType.PROPERTIES}
                                    selectedCriteria={filters.properties}
                                    onSearchChange={(q: string) => {
                                        setSearchByFilters({
                                            ...searchByFilters,
                                            propertiesQ: q
                                        })
                                    }}
                                    onSelect={(item) => {
                                        const alreadyIncluded = filters.properties!.find(p => p.id === item.id);
                                        if (alreadyIncluded) {
                                            appointmentService.changeFilterCriteria({
                                                ...filters,
                                                properties: filters.properties!.filter(p => p.id !== item.id)
                                            })
                                        } else {
                                            appointmentService.changeFilterCriteria({
                                                ...filters,
                                                properties: [
                                                    ...filters.properties!,
                                                    item
                                                ]
                                            })
                                        }
                                    }}
                                />
                            </Fragment>
                        }
                    }}
                />
            </FormHolder>
        </Sidenav>
        <ExportCalendar
            open={isExportOpened}
            loading={isExporting}
            onClose={() => setIsExportOpened(false)}/>
        <CreateAppointment
            isEditing={isEditing}
            isCreateModalOpened={isCreateModalOpened}
            onCloseCreateModal={() => {
                autocompleteService.resetSearchInput();
                setIsCreateModalOpened(false);
            }}
            newAppointment={newAppointment}
            setIsCreateModalOpened={open => setIsCreateModalOpened(open)}
        />
    </ContentHolder>
}