import { QueryEntity } from "@datorama/akita";
import { adminSearchStore, AdminSearchStore, IAdminSearchState } from "./admin-search.store";


export class AdminSearchQuery extends QueryEntity<IAdminSearchState> {
    loading$ = this.selectLoading();
    filterCriteria$ = this.select(state => state.selectedCriteria);

    constructor(protected adminSearchStore: AdminSearchStore) {
        super(adminSearchStore);
    }
}

export const adminSearchQuery = new AdminSearchQuery(adminSearchStore);
