import {QueryEntity} from "@datorama/akita";
import {calendarStore, CalendarStore, ICalendarState} from "./calendar.store";

export class CalendarQuery extends QueryEntity<ICalendarState>{

    isLoading$ = this.select(state => state.loading);
    events$ = this.select(state => state.events);
    allStaff = this.select(state => {
        return state.staffList.filter(staff => !staff.isDeleted);
    });
    staffToColors = this.select(state => state.staffToColorMap);

    constructor(protected calendarStore: CalendarStore) {
        super(calendarStore);
    }
}

export const calendarQuery = new CalendarQuery(calendarStore);
