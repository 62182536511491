import React, {useMemo} from 'react';
import {IInputComponent} from "./input-component.interface";
import CssClasses from './checkbox.module.scss';
import { guid } from "@datorama/akita";

export enum CheckboxType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

interface IProps extends IInputComponent{
    checked?: boolean;
    label?: string | React.ReactNode;
    type?: CheckboxType;
    labelColor?: string;
    labelStyles?: React.CSSProperties;
    value?: string;
    withLabel?: boolean;
    disabled?: boolean;
    // onChange?: (value: string) => void;
}

export const TableCheckbox = React.forwardRef((props: IProps, ref: any) => {
    const { checked, label, labelColor, ...rest } = props;
    const id = useMemo(() => {
        if (props.id) {
            return props.id;
        }

        return guid();
    }, [props]);

    return (
        <div className={CssClasses.checkboxSecondary}>
            <input ref={ref} id={id} {...rest} className={CssClasses.secondaryInput} checked={checked} type="checkbox"/>
            <label className={CssClasses.checkbox_label} style={labelColor ? { borderColor: labelColor } : {}} htmlFor={id}></label>
        </div>
    );
});

export const CustomCheckbox: React.FC<IProps> = ({ checked, label, labelColor, labelStyles, withLabel, disabled, value, onChange, ...rest }) => {
    const id = useMemo(() => {
        if (rest.id) {
            return rest.id;
        }

        return guid();
    }, [rest.id]);

    // const handleChange = (e: ChangeEvent<any>) => {
    //     const value = e.target.value;

    //     if (typeof onChange === 'function') {
    //         onChange(value);
    //     }
    // }

    return (
        <div className={CssClasses.checkboxCustom}>
            <input 
                id={id} 
                value={value} 
                className={CssClasses.secondaryInput} 
                checked={checked} 
                type="checkbox" 
                disabled={disabled}
                onChange={onChange} />
            {
                withLabel
                    ? <label 
                        className={CssClasses.checkbox_label} 
                        style={
                            labelColor 
                                ? {
                                    ...labelStyles,
                                    borderColor: labelColor,
                                } 
                                : {
                                    ...labelStyles,
                                }
                        } 
                        htmlFor={id}>{label}</label>
                    : <label className={CssClasses.checkbox_label} style={labelColor ? { borderColor: labelColor } : {}} htmlFor={id}></label>
            }
        </div>
    );
}

export const CommonCheckbox: React.FC<IProps> = ({ checked, label, labelColor, labelStyles, withLabel, disabled, value, onChange, ...rest }) => {
    const id = useMemo(() => {
        if (rest.id) {
            return rest.id;
        }

        return guid();
    }, [rest.id]);

    // const handleChange = (e: ChangeEvent<any>) => {
    //     const value = e.target.value;

    //     if (typeof onChange === 'function') {
    //         onChange(value);
    //     }
    // }

    return (
        <div className={CssClasses.checkboxCommon}>
            <input 
                id={id} 
                value={value} 
                className={CssClasses.secondaryInput} 
                checked={checked} 
                type="checkbox" 
                disabled={disabled}
                onChange={onChange} />
            {
                withLabel
                    ? <label 
                        className={CssClasses.checkbox_label} 
                        style={
                            labelColor 
                                ? {
                                    ...labelStyles,
                                    borderColor: labelColor,
                                } 
                                : {
                                    ...labelStyles,
                                }
                        } 
                        htmlFor={id}>{label}</label>
                    : <label className={CssClasses.checkbox_label} style={labelColor ? { borderColor: labelColor } : {}} htmlFor={id}></label>
            }
        </div>
    );
}

export function Checkbox(props: IProps) {
    const { checked = true, label, labelColor, disabled, ...rest } = props;
    const id = useMemo(() => guid(), []);

    return (
        <div className={CssClasses.checkbox}>
            <input id={id} {...rest} className={CssClasses.checkbox_input} checked={checked} type="checkbox" disabled={disabled}/>
            {label && <label className={CssClasses.checkbox_label} htmlFor={id}>
                {label}
            </label>}
        </div>
    );
}
