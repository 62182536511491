export const SYSTEM_MESSAGES = {
    GROUP_CREATED: 'Tenant Group has been successfully created',
    GROUP_UPDATED: 'Tenant Group has been successfully updated',
    GROUP_DELETED: 'Tenant Group has been successfully deleted',
    IMPORT_SUCCESS: 'Tenants data was successfully imported to the app',
    IMPORT_FAILED: 'Unexpected error occurred during importing owners. Please, try again later',
};

export const EMPTY_STRING_PLACEHOLDER = '';

export const DATE_FORMAT = 'LLL';

export const SNACK_TIMEOUT = 4000;
export const DEBOUNCE = 400;