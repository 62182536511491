import { ITemplate } from "./template.interface";

export interface IRecurrence {
    id?: number | undefined;
    endsAfterOccurrence?: number | null;
    startDate?: Date | undefined;
    endsDate?: Date | null;
    frequency: number | undefined;
    timePeriod: "DAY" | "WEEK" | "MONTH" | "YEAR";
    weekDays?: string[] | null;
    monthlyRepeat?: "ON_DAY" | "ON_WEEKDAY" | null;
    endType?: string;
}

export enum RecurrenceType {
    DONT_REPEAT = 'DONT_REPEAT',
    EVERY_DAY = 'EVERY_DAY',
    EVERY_WEEK = 'EVERY_WEEK',
    EVERY_FIRST_WEEKDAY_OF_THE_MONTH = 'EVERY_FIRST_WEEKDAY_OF_THE_MONTH',
    ANNUALLY = 'ANNUALLY',
    EVERY_WORK_DAY = 'EVERY_WORK_DAY',
    CUSTOM = 'CUSTOM',
    CUSTOM_OPTION = 'CUSTOM_OPTION',
}

export interface IAutoNitificaton {
    id?: number | string;
    appointmentType: string;
    datetime: Date;
    receivers: string[];
    recurrence?: Partial<IRecurrence>;
    recurrenceId?: number;
    template?: ITemplate;
    templateId?: number;
    recurrenceType?: string;
    recurrenceName?: string;
    endType?: string;
    customType?: string;
}