import React from "react";
import Classes from './headers.module.scss';
import {IUiComponent} from "../../types/ui-component.interface";


interface IProps extends IUiComponent{
    children?: React.ReactNode | React.ReactNode[];
}

export function Header1(props: IProps) {
    return <h1 {...props} className={Classes.h1}>
        {props.children}
    </h1>
}

export function Header2(props: IProps) {
    return <h2 {...props} className={Classes.h2}>
        {props.children}
    </h2>
}

export function Header3(props: IProps) {
    return <h3 {...props} className={Classes.h3}>
        {props.children}
    </h3>
}

export function Header4(props: IProps) {
    return <h4 {...props} className={Classes.h4}>
        {props.children}
    </h4>
}

export function Header5(props: IProps) {
    return <h5 {...props} className={Classes.h5}>
        {props.children}
    </h5>
}

export function Header6(props: IProps) {
    return <h6 {...props} className={Classes.h6}>
        {props.children}
    </h6>
}
