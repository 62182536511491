import {InputType, IRenderableFormField} from './form';
import React, {Fragment, ReactNode} from 'react';
import {createStyles, FormControlLabel, Radio, RadioGroup, Theme, withStyles} from '@material-ui/core';
import {FormConfigurationError} from './formConfiguration.error';
import {Input} from "../inputs/input";
import {IntegerInput} from "../inputs/int-input";
import {guid} from "@datorama/akita";
import InputClasses from '../inputs/input.module.scss';
import {Dropdown} from "../../dropdown/dropdown";

import {
    KeyboardDatePicker,
    // KeyboardDateTimePicker,
    // KeyboardTimePicker,
} from '@material-ui/pickers';
import moment, {Moment} from "moment";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { COLORS } from 'src/components/ui/theme/variables/colors';
import { CalendarIcon } from 'src/components/ui/icons';
import styled from 'styled-components';
import { CommonCheckbox } from '../inputs/checkbox';
import { snackbarService } from 'src/store/snackbar/snackbar.service';
import { SnackbarType } from 'src/store/snackbar/snackbar.store';

const DateRangeHolder = styled.div<any>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 430px;
    width: 100%;

    @media screen and (max-width: 1200px) {
        flex-direction: ${({ mobileDirection }) => mobileDirection ? mobileDirection : 'column'};
        align-items: flex-start;
    }
`;

const TimepickerHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 216px;
    width: 100%;

    @media screen and (max-width: 1200px) {
        max-width: 175px;
    }

    & input {
        max-width: 90px;
    }
`;

export const DatePickerInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: '0',
            padding: '5px 0px 5px 10px',
            fontFamily: 'ManropeSemibold',
            width: 170,
            height: 40,
            borderRadius: '8px',
            backgroundColor: COLORS.INPUT_BG,

            [theme.breakpoints.down('sm')]: {
                margin: '0 0 10px',
                width: 161,
            },
        },
        // endAdornment: {
        //     position: 'absolute',
        //     top: '9px',
        //     left: 10,
        // },
        // popupIndicator: {
        //     fontSize: '14px !important',
        //     color: '#2B65F0 !important',
        // },
        '@global': {
            '.MuiInput-underline:before': {
                borderBottom: 'none !important'
            },
            '.MuiInputAdornment-positionEnd': {
                marginTop: '-6px',
            }
        }
    })
)(KeyboardDatePicker);

export interface IDateRange {
    date?: Moment | number;
    timeTo?: string;
    timeFrom?: string;
}

export interface IConfig {
    type: InputType;
    fieldData: IRenderableFormField;
    readonly: boolean;
    disabled?: boolean;
    isValid?: boolean;
    onChange: (fieldValue: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (field: string) => (fieldValue: React.ChangeEvent<HTMLInputElement>) => void;
    CustomElement?: React.ReactNode;
    groupStyles?: React.CSSProperties;
    inputStyles?: React.CSSProperties;
    rules?: any;
}

const dateRangeIntervals: { value: string, content: string }[] = [];
const shortRangeIntervals: { value: string, content: string }[] = [];

const shortStartTime = moment().set({ h: 6, m: 0, s: 0 });
const startTime = moment(new Date(0, 0));
const NUMBER_OF_SHORT = 15 * 4;
const NUMBER_OF_INTERVALS = 24 * 4;

for (let i = 0; i <= NUMBER_OF_SHORT; i++) {
    const time = i === 0 
        ? shortStartTime
        : shortStartTime.add(15, 'minutes')

    shortRangeIntervals.push({
        value: time.format('hh:mm A'),
        content: time.format('hh:mm A'),
    });
}

for (let i = 0; i < NUMBER_OF_INTERVALS; i++) {
    const time = i === 0 
        ? startTime
        : startTime.add(15, 'minutes')

    dateRangeIntervals.push({
        value: time.format('hh:mm A'),
        content: time.format('hh:mm A'),
    });
}

const renderCalendar = (day: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean, dayComponent: React.ReactElement) => {
    const isCurrentDate = moment().isSame(day, 'day');

    if (isCurrentDate) {
        return (
            <button type="button" className={InputClasses.calendar_currentDate}>
                <span>
                    <p>{dayComponent.props.children}</p>
                </span>
            </button>
        );
    }

    if (dayComponent.props.selected) {
        return (
            <button type="button" className={InputClasses.calendar_selectedDate}>
                <span>
                    <p>{dayComponent.props.children}</p>
                </span>
            </button>
        );
    }

    if (dayInCurrentMonth) {
        return (
            <button type="button" className={InputClasses.calendar_date}>
                <span>
                    <p>{dayComponent.props.children}</p>
                </span>
            </button>
        );
    }

    return (
        <button type="button" className={InputClasses.out_date}>
            <span>
                <p>{dayComponent.props.children}</p>
            </span>
        </button>
    );
}

export function InputTypeSelector(config: IConfig): ReactNode {
    const {
        fieldData, 
        onChange, 
        onBlur, 
        readonly = false, 
        isValid = true, 
        inputStyles = null, 
        CustomElement, rules, 
        // disabled = false,
    } = config;

    if ((readonly && !CustomElement) || fieldData.readonly) {
        return <div style={{
            fontWeight: 600, 
            color: '#363E59',
            ...fieldData.disabledStyles,
        }}>
            {fieldData.value}
        </div>

    }

    switch (config.type) {
        case InputType.DROPDOWN: {
            if (!fieldData.selectableItems || !fieldData.selectableItems.length) {
                throw new FormConfigurationError(`Expected fieldData.selectableItems to be present, got: ${fieldData.selectableItems}`);
            }

            const menuItems = fieldData.selectableItems.map(item => ({
                value: item.value,
                content: item.label,
            }))
            
            return <Dropdown
                value={fieldData.value}
                onChange={onChange}
                menuItems={menuItems}
                styles={fieldData.inputStyles ? fieldData.inputStyles : {minWidth: '182px'}}
            />
        }
        case InputType.NUMBER_BETWEEN: {
            if (fieldData.value.from === undefined || !fieldData.value.to === undefined) {
                throw new FormConfigurationError(`Expected values passed as NUMBER_BETWEEN to have the following signature: {from: <value>, to: <value>}, got: ${fieldData.value}`)
            }
            const fromValue = fieldData.value.from === null ? '' : parseInt(fieldData.value.from, 10);
            const toValue = fieldData.value.to === null ? '' : parseInt(fieldData.value.to, 10);

            return <div style={{
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center',
                maxWidth: '300px',
            }}>
                <span style={{ fontFamily: 'ManropeThin' }}>
                    From
                </span>
                <Input 
                    valid={isValid} 
                    name={`${fieldData.dataField}-from`} 
                    value={fromValue} 
                    onChange={onChange}
                    onBlur={(e) => {
                        if (parseInt(e.target.value) < 0) {
                            e.target.value = 0;
                        } else {
                            e.target.value = Math.abs(parseInt(e.target.value));
                        }
                    }}
                    disabled={readonly}
                    type="number"/>
                <span style={{ fontFamily: 'ManropeThin' }}>
                    To
                </span>
                <Input 
                    valid={isValid} 
                    name={`${fieldData.dataField}-to`} 
                    value={toValue} 
                    onChange={onChange}
                    onBlur={(e) => {
                        if (parseInt(e.target.value) < 0) {
                            e.target.value = 0;
                        } else {
                            e.target.value = Math.abs(parseInt(e.target.value));
                        }
                    }}
                    disabled={readonly}
                    type="number"/>
            </div>
        }
        case InputType.STRING:
            return <Input 
                valid={isValid} 
                rules={rules} 
                disabled={readonly} 
                style={
                    inputStyles
                        ? inputStyles
                        : { width: '100%', display: 'flex', flexDirection: 'row' }
                }
                onChange={onChange}
                placeholder={fieldData.placeholder || ''} value={fieldData.value}/>

        case InputType.NUMBER:
            // console.log('fieldData -> ', fieldData);
            return <Input valid={isValid} disabled={fieldData.disabled} style={{
                    width: '100%',
                    ...inputStyles,
                }} onChange={onChange}
                placeholder={fieldData.placeholder || ''} 
                value={fieldData.value} 
                type="number"/>

        case InputType.SINGLE_VALUE_SELECT:
            if (!fieldData.selectableItems || fieldData.selectableItems.length === 0) {
                throw new FormConfigurationError('Expected fieldData.selectableItems to be present and contain exactly one item');
            }
            const [{label, value}] = fieldData.selectableItems;
            if (typeof value !== 'boolean') {
                throw new FormConfigurationError(`Expected fieldData.selectableItems[0].value to be boolean value, got ${value}`)
            }
            if (fieldData.selectableItems.length > 1) {
                console.warn(`Passed too many selectableItems, only the one at index 0 will be used, others will be ignored`);
            }

            const id = guid();
            return <RadioGroup
                style={
                    inputStyles
                        ? inputStyles
                        : { width: '100%', display: 'flex', flexDirection: 'row' }
                }
                value={fieldData.value}
                onChange={onChange}
            >
                <FormControlLabel
                        disabled={readonly || fieldData.disabled}
                        color="primary"
                        control={
                            <input
                                checked={fieldData.value === value}
                                onChange={e => {
                                    onChange({
                                        ...e,
                                        target: {
                                            ...e.target,
                                            // @ts-ignore
                                            value: !fieldData.value,
                                        }
                                    });
                                }}
                                type="checkbox"
                                id={id}
                                className={InputClasses.input}
                            />
                        }
                        label={
                            <label
                                style={{
                                    marginLeft: '5px',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    ...fieldData.labelStyles,
                                }}
                                htmlFor={id}
                            >
                                {label}
                            </label>
                        }
                />
            </RadioGroup>
        case InputType.SELECT:
            if (!fieldData.selectableItems || !fieldData.selectableItems.length) {
                throw new FormConfigurationError(`Expected fieldData.selectableItems to be present, got: ${fieldData.selectableItems}`);
            }
            if (!Array.isArray(fieldData.value)) {
                throw new FormConfigurationError('Expected fieldData.value to be an array for SELECT type')
            }
            return <RadioGroup
                style={
                    inputStyles
                        ? inputStyles
                        : {width: '100%', display: 'flex', flexDirection: 'row'}
                }
                value={fieldData.value}
                onChange={onChange}
            >
                {fieldData.selectableItems.map(({value, label, disabled}) => {
                    const id = guid();

                    return <FormControlLabel
                        disabled={readonly || disabled}
                        color="primary"
                        value={value}
                        style={fieldData.isMobile ? {
                            marginLeft: "11px",
                            cursor: "pointer",
                        } : {
                            cursor: "pointer",
                        }}
                        control={
                            <CommonCheckbox
                                checked={fieldData.value.includes(value)}
                                onChange={onChange}
                                labelColor="#789EFF"
                                id={id}
                            />
                        }
                        label={
                            <label
                                style={{
                                    marginLeft: '5px',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    fontFamily: 'ManropeThin',
                                    cursor: "pointer",
                                }}
                                htmlFor={id}
                            >
                                {label}
                            </label>
                        }
                        key={value}
                    />
                })}
            </RadioGroup>
        case InputType.INT_INPUT: {
            const { intInputConfig, intType } = fieldData;
            return <IntegerInput {...intInputConfig} type={intType} value={fieldData.value} onChange={onChange}/>
        }
        case InputType.CUSTOM_ELEMENT:
            return CustomElement;
        case InputType.RADIO: {
            if (!fieldData.selectableItems || !fieldData.selectableItems.length) {
                throw new FormConfigurationError(`Expected fieldData.radioButtonValues to be present, got: ${fieldData.selectableItems}`);
            }
            return <RadioGroup style={{marginLeft: 20, marginTop: -5}} row value={fieldData.value} onChange={onChange}>
                {fieldData.selectableItems.map(({value, label}) => (
                    <FormControlLabel
                        disabled={readonly}
                        color="primary"
                        value={value}
                        control={<Radio/>}
                        style={{minWidth: 70}}
                        label={label}
                        key={value}
                    />
                ))}
            </RadioGroup>
        }
        case InputType.DATE: {
            return (
                <div style={fieldData.inputStyles}>
                    <DatePickerInput
                        InputProps={{ 
                            readOnly: true,
                        }}
                        // disableToolbar
                        keyboardIcon={<CalendarIcon />}
                        KeyboardButtonProps={{
                            style: {
                                color: '#363E59',
                                fontFamily: 'ManropeSemibold',
                                fontSize: '16px',
                            },
                            'aria-label': 'change date',
                        }}
                        autoOk
                        variant="inline"
                        placeholder={fieldData.placeholder || 'Select Date...'}
                        rightArrowButtonProps={{
                            style: {
                                color: '#2B65F0'
                            }
                        }}
                        leftArrowButtonProps={{
                            style: {
                                color: '#2B65F0'
                            }
                        }}
                        format={fieldData?.format ? fieldData?.format : 'MMMM Do'}
                        renderDay={renderCalendar}
                        value={fieldData.value}
                        onChange={(val) => {
                            onChange({
                                // @ts-ignore
                                target: {
                                    // moment time returned
                                    // @ts-ignore
                                    value: val
                                }
                            })
                        }}
                    />
                </div>
            )
        }
        case InputType.DATE_RANGE: {
            if (
                !fieldData.value 
                // || typeof fieldData.value.timeFrom !== 'string' 
                // || typeof fieldData.value.timeTo !== 'string' 
                || !fieldData.value.date) {
                throw new FormConfigurationError(`Value expected to be an object with 'date', 'timeTo' and 'date' fields, got ${fieldData.value}`);
            }

            const onChangeHandler = (type: 'timeTo' | 'timeFrom' | 'date') => (val: any) => {
                if (fieldData.validate) {
                    if (type === 'date') {         
                        if (
                            moment().diff(moment(val), 'days') > 0 ||
                            moment().diff(moment().set({
                                date: moment(val).date(),
                                hours: moment(fieldData.value.timeFrom, 'hh:mm A').hours(),
                                minutes: moment(fieldData.value.timeFrom, 'hh:mm A').minutes(),
                            })) > 0
                        ) {
                            snackbarService.createSnackbar({
                                text: 'You can create notification only for upcoming date and time',
                                type: SnackbarType.ERROR,
                            }, 4000);   
                        }
                    }
    
                    if (type === 'timeFrom') {
                        if (
                            moment().diff(moment(fieldData.value.date), 'days') > 0 ||
                            moment().diff(moment().set({
                                date: moment(fieldData.value.date).date(),
                                hours: moment(val, 'hh:mm A').hours(),
                                minutes: moment(val, 'hh:mm A').minutes(),
                            })) > 0
                        ) {    
                            snackbarService.createSnackbar({
                                text: 'You can create notification only for upcoming date and time',
                                type: SnackbarType.ERROR,
                            }, 4000);
                        }
                    }
                }

                onChange({
                    // @ts-ignore
                    target: {
                        // moment time returned
                        // @ts-ignore
                        value: {
                            ...fieldData.value,
                            [type]: val
                        }
                    }
                })
            }
            
            const {timeFrom, timeTo, date} = fieldData.value;

            return <DateRangeHolder mobileDirection={fieldData.mobileDirection}>
                <DatePickerInput
                    InputProps={{ readOnly: true }}
                    // disableToolbar
                    label=""
                    autoOk
                    keyboardIcon={<CalendarIcon />}
                    KeyboardButtonProps={{
                        style: {
                            color: '#363E59',
                            fontFamily: 'ManropeSemibold',
                            fontSize: '16px',
                        }
                    }}
                    placeholder="2018/10/10"
                    variant="inline"
                    value={date}
                    rightArrowButtonProps={{
                        style: {
                            color: '#2B65F0'
                        }
                    }}
                    leftArrowButtonProps={{
                        style: {
                            color: '#2B65F0'
                        }
                    }}
                    renderDay={renderCalendar}
                    onChange={onChangeHandler('date')}
                />
                <TimepickerHolder>
                    {
                        timeFrom || timeFrom === ''
                            ? (
                                <Dropdown
                                    label=""
                                    freeSolo
                                    timePicker
                                    value={timeFrom.toUpperCase()}
                                    onBlur={onBlur && onBlur('timeFrom')}
                                    onChange={e => onChangeHandler('timeFrom')(e.target.value)}
                                    menuItems={fieldData.shortRange ? shortRangeIntervals : dateRangeIntervals}
                                    styles={{minWidth: '76px', maxWidth: '104px'}}
                                />
                            ) : null
                    }
                    {
                        timeTo || timeTo === ''
                            ? (
                                <Fragment>
                                    &mdash;
                                    <Dropdown
                                        value={timeTo.toUpperCase()}
                                        label=""
                                        freeSolo
                                        timePicker
                                        onBlur={onBlur && onBlur('timeTo')}
                                        onChange={e => onChangeHandler('timeTo')(e.target.value)}
                                        menuItems={fieldData.shortRange ? shortRangeIntervals : dateRangeIntervals}
                                        styles={{minWidth: '76px', maxWidth: '104px'}}
                                    />
                                </Fragment>
                            ) : null
                    }
                </TimepickerHolder>
            </DateRangeHolder>
        }
    }
}