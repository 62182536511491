import { FetchStatus } from "src/store/data-fetcher/data-fetcher.store";

export enum ActionType {
    FETCH = 'FETCH',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    LOADING = 'LOADING',
    SET_ACTION = 'SET_ACTION',
    EMPTY = 'EMPTY',
    UPDATE_STATE = 'UPDATE_STATE',
}

export type RequestAction<TData, P extends unknown[]> =
    | { type: ActionType.FETCH, status: FetchStatus.PENDING }
    | { type: ActionType.SUCCESS, status: FetchStatus.SUCCESS, results: TData }
    | { type: ActionType.FAILURE, status: FetchStatus.FAILED, error: string }
    | { type: ActionType.EMPTY, status: FetchStatus.EMPTY }
    | { type: ActionType.SET_ACTION, refetch: () => Promise<TData | void>, initParams: P }
    | { type: ActionType.UPDATE_STATE, state: RequestState<TData, P>}

export interface State<TData = unknown, TError = unknown> {
    data: TData | undefined
    error: TError | null
    isFetching: boolean
    status: FetchStatus;
}

export type RequestState<TData, P extends unknown[]> =
    | { status: FetchStatus.EMPTY, isFetching: boolean, id: string, refetch: () => Promise<TData | void>, initParams: P, data: unknown }
    | { status: FetchStatus.IDLE, isFetching: boolean, id: string, refetch: () => Promise<TData | void>, initParams: P }
    | { status: FetchStatus.PENDING, isFetching: boolean, id: string, refetch: () => Promise<TData | void>, initParams: P }
    | { status: FetchStatus.FAILED, error: string, isFetching: boolean, id: string, refetch: () => Promise<TData | void>, initParams: P }
    | { status: FetchStatus.SUCCESS, data: TData, isFetching: boolean, id: string, refetch: () => Promise<TData | void>, initParams: P };

export type RequestReducer<S, A> = (prevState: S, action: A) => S;

export const requestReducer = <TData, P extends unknown[]>(state: RequestState<TData, P>, action: RequestAction<TData, P>): RequestState<TData, P> => {
    switch(action.type) {
        case ActionType.FETCH:
            return {
                ...state,
                status: action.status,
                isFetching: true,
            };
        case ActionType.SUCCESS:
            return {
                ...state,
                status: action.status,
                data: action.results,
                isFetching: false,
            };
        case ActionType.FAILURE:
            return {
                ...state,
                status: action.status,
                error: action.error,
                isFetching: false,
            };
        case ActionType.SET_ACTION:
            return {
                ...state,
                refetch: action.refetch,
            };
        case ActionType.EMPTY:
            return {
                ...state,
                status: action.status,
                data: [],
            };
        case ActionType.UPDATE_STATE:
            return {
                ...state,
                ...action.state,
            };
        default:
            return state;
    }
}