import styled from "styled-components";

export const SearchableListHolder = styled.div`
    min-height: 60px;
    max-height: 150px;
    max-width: 268px;
    overflow-y: auto;
    margin-top: 10px;

    .ReactVirtualized__Grid {
        outline: none !important;
    }
`;

export const SearchItem = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
`;

export const SearchLabel = styled.label`
    /* margin-left: -5px; */
    font-family: 'ManropeThin';
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
`;