import { createEntityQuery, guid } from "@datorama/akita";
import {
    ActivelyRentingState, INumberBetween, IPropertiesSelectedCriteria,
    ISearchPropertiesState,
    searchPropertiesStore,
} from "./search-properties.store";
import { INamedEntity } from "../models/named-entity.interface";

export type KeyedNamedEntity<T> = { key: T } & INamedEntity;

export const propq = createEntityQuery<ISearchPropertiesState>(searchPropertiesStore);

export const selectedCriteria$ = propq.select(state => state.selectedCriteria);
export const isLoading$ = propq.select(state => state.loading);
export const searchData$ = propq.select(state => state.searchData);

export const appliedFilters$ = propq.select(state => state.appliedFilters);

export const allSelectedFilters$ = propq.select<KeyedNamedEntity<keyof IPropertiesSelectedCriteria>[]>(state => {
    const {
        selectedCriteria: {
            // city,
            readyToLease,
            targetRent,
            numberOfBathrooms,
            numberOfBedrooms
        }
    } = state;
    const entities: KeyedNamedEntity<keyof IPropertiesSelectedCriteria>[] = [];
    const betweenFilterExists = (p: INumberBetween) => {
        return (p.from && p.from > 0) || (p.to && p.to > 0);
    }

    // city.forEach(city => entities.push({
    //     ...city,
    //     key: 'city'
    // }));

    if (readyToLease.length) {
        readyToLease.forEach(item => entities.push({
            ...readyToLease,
            id: guid(),
            name: item === ActivelyRentingState.YES ? 'Only Actively Renting' : 'Only Idle',
            key: 'readyToLease'
        }));
    }

    if (betweenFilterExists(targetRent)) {
        let name = '';

        if (targetRent.from) {
            name += `From: $${targetRent.from} `;
        }

        if (targetRent.to) {
            name += `To: $${targetRent.to}`;
        }

        entities.push({
            id: targetRent.id!,
            name: `Target Rent - ${name}`,
            key: 'targetRent'
        })
    }

    if (betweenFilterExists(numberOfBedrooms)) {
        let name = '';

        if (numberOfBedrooms.from) {
            name += `From: ${numberOfBedrooms.from} `;
        }

        if (numberOfBedrooms.to) {
            name += `To: ${numberOfBedrooms.to}`;
        }

        entities.push({
            id: numberOfBedrooms.id!,
            name: `Bedrooms - ${name}`,
            key: 'numberOfBedrooms'
        })
    }
    
    if (betweenFilterExists(numberOfBathrooms)) {
        let name = '';

        if (numberOfBathrooms.from) {
            name += `From: ${numberOfBathrooms.from} `;
        }

        if (numberOfBathrooms.to) {
            name += `To: ${numberOfBathrooms.to}`;
        }

        entities.push({
            id: numberOfBathrooms.id!,
            name: `Bathrooms - ${name}`,
            key: 'numberOfBathrooms'
        })
    }

    return entities;
})

export const searchPropertiesQuery = {
    allSelectedFilters$,
    selectedCriteria$,
    searchData$,
    isLoading$,
    appliedFilters$,
};