import { useObservable } from "@libreact/use-observable";
import { fromEvent } from "rxjs";
import { distinctUntilChanged, pluck, switchMap, debounceTime } from "rxjs/operators";
import { useEffect } from "react";
import { KeyedNamedEntity } from "src/store/properties/search/search-properties.query";
import { allSelectedFilters$, filterCriteria$ } from "../staff-search/staff-search.query";
import { IStaffSearchCriteria } from "../staff-search/staff-search.store";
import { staffService } from "../staff.service";
import { IPaging } from "src/store/common/hooks/use-paging";

export type StaffSearchHookTuple = [
    KeyedNamedEntity<keyof IStaffSearchCriteria>[], 
    IStaffSearchCriteria, 
];

export function useStaffSearch (paging: IPaging): StaffSearchHookTuple {
    const [allSelectedFilters] = useObservable(allSelectedFilters$);
    const [searchCriteria] = useObservable(filterCriteria$);

    useEffect(() => {
        const input: HTMLElement = document.getElementById('staff-search') as HTMLElement;

        const subscription = fromEvent(input, 'input').pipe(
            pluck('target', 'value'),
            distinctUntilChanged(),
            debounceTime(400),
            switchMap(search => staffService.fetchStaff(paging.activePage, paging.rowsPerPage, search as string)),
        ).subscribe();

        return () => subscription.unsubscribe();
    }, [paging]);

    return [allSelectedFilters, searchCriteria];
}