import { get, isEmpty, uniqBy } from "lodash";
// import { ApiServiceInstance } from "../api/api-service";
import { IInitializable } from "../portfolio/models/on-init.interface";
import { autocompleteStore, AutocompleteStore } from "./autocomplete.store";
import { IFetchSearchData, IGetISODate, RISODate, SearchType } from "./models/autocomplete.interface";
import moment from 'moment';
import { api } from "src/api/api.service";

//** All logic related to the autocomplete search */

const AM_PM_TIME_FORMAT = 'hh:mm A';

const getISODate = ({ date, timeFrom, timeTo }: IGetISODate): RISODate => {
    const dateFrom = moment().set({
        date: moment(date).date(),
        month: moment(date).month(),
        hour: moment(timeFrom, AM_PM_TIME_FORMAT).hour(),
        minute: moment(timeFrom, AM_PM_TIME_FORMAT).minute(),
    }).toISOString();

    const dateTo = moment().set({
        date: moment(date).date(),
        month: moment(date).month(),
        hour: moment(timeTo, AM_PM_TIME_FORMAT).hour(),
        minute: moment(timeTo, AM_PM_TIME_FORMAT).minute(),
    }).toISOString();

    return {
        dateFrom,
        dateTo,
    };
}

export class AutocompleteService implements IInitializable {
    public isInitialized = false;

    constructor(
        private autocompleteStore: AutocompleteStore,
    ) {}

        async fetchSearchData({
            id,
            url,
            page,
            size,
            search,
            searchType,
            isNextPage = false,
            dateProps,
            multiple,
        }: IFetchSearchData) {

        let urlString: string = url;
        let prepearedList: any[] = [];

        if (dateProps && !isEmpty(dateProps)) {
            const isoDate = getISODate(dateProps);

            urlString = `${url}?dateFrom=${isoDate.dateFrom}&dateTo=${isoDate.dateTo}`;
        }

        this.autocompleteStore.update(state => ({
            ...state,
            searchStatus: {
                ...state.searchStatus,
                [id]: {
                    loading: true,
                },
            },
        }));

        let response: any = undefined;

        if (search) {
            response = await api.get<any>(urlString, {
                params: {
                    page,
                    size,
                    search,
                },
            });
        } else {
            response = await api.get<any>(urlString, {
                params: {
                    page,
                    size,
                },
            });
        }

        let data: any = {
            result: [],
            total: 0,
        };

        if (
            searchType === SearchType.TENANTS ||
            searchType === SearchType.OWNERS ||
            searchType === SearchType.PROPERTIES ||
            searchType === SearchType.PROSPECTS
        ) {
            data = get(response, 'data.response', {
                result: [],
                total: 0,
            });
        } else {
            data = get(response, 'data', {
                result: [],
                total: 0,
            });
        }

        if (Array.isArray(data?.result)) {
            prepearedList = data.result.map((item: any) => ({
                id: item?.id,
                name: item.name 
                    ? item.name 
                    : searchType === SearchType.PROPERTIES 
                        ? item.unitAddress
                        : searchType === SearchType.PROPERTY_GROUPS
                            ? item.property
                            : `${item.firstName} ${item.lastName}`,
                isBusy: item.isBusy,
                isDeleted: item.isDeleted,
                ...item,
            }))
        } else if (Array.isArray(data)) {
            prepearedList = data?.map((item: any) => ({
                id: item?.id,
                name: item.name 
                    ? item.name 
                    : searchType === SearchType.PROPERTIES 
                        ? item.unitAddress
                        : searchType === SearchType.PROPERTY_GROUPS
                            ? item.property
                            : `${item.firstName} ${item.lastName}`,
                isBusy: item.isBusy,
                isDeleted: item.isDeleted,
                ...item,
            }))
        }        

        if (multiple) {
            this.autocompleteStore.update(state => ({
                ...state,
                searchData: {
                    ...state.searchData,
                    [id]: {
                        ...state.searchData[id],
                        searchTotal: data.total,
                        searchList: isNextPage ? uniqBy([
                            ...state.searchData[id].searchList,
                            ...prepearedList as any[],
                        ], 'id') : prepearedList,
                    }
                }
            }))
        } else {
            this.autocompleteStore.update(state => ({
                ...state,
                searchTotal: data.total,
                searchList: isNextPage ? uniqBy([
                    ...state.searchList,
                    ...prepearedList as any[],
                ], 'id') : prepearedList,
            }))
        }

        this.autocompleteStore.update(state => ({
            ...state,
            searchStatus: {
                ...state.searchStatus,
                [id]: {
                    loading: false,
                },
            },
        }));
    }

    resetSearchData() {
        this.autocompleteStore.update(state => ({
            ...state,
            searchList: [],
            searchTotal: 0,
        }));
    }

    resetSearchInput() {
        this.autocompleteStore.update(state => ({
            ...state,
            resetInput: true,
        }));
    }

    init() {
        this.isInitialized = true;

        this.autocompleteStore.update(state => ({
            ...state,
            resetInput: false,
        }));
    }
}

export const autocompleteService = new AutocompleteService(autocompleteStore);