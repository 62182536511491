// import { useEffect } from 'react'
import { useObservable } from '@libreact/use-observable'
import { adminSettingsQuery } from '../admin-settings.query';
// import { isEmpty } from 'lodash';
import { IFollowUp } from '../models/followup.interface';

export type FollowUpHookTuple = [Partial<IFollowUp>[], boolean, number];

export function useFollowUp(): FollowUpHookTuple {
    const [followUps] = useObservable(adminSettingsQuery.followUps$);
    const [loading] = useObservable(adminSettingsQuery.isFollowUpLoading$);
    const [totalFollowups] = useObservable(adminSettingsQuery.totalFollowups$);

    // useEffect(() => {
    //     if (isEmpty(followUps) && !loading) {
    //         // adminSettingsService.fetchFollowUps();
    //     }
    // }, []);

    return [followUps, loading, totalFollowups];
}