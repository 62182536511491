import { createEntityStore, EntityState, guid } from "@datorama/akita";
import { INumberBetween, ISort } from "../../properties/search/search-properties.store";
import moment from "moment";
import { ITenant } from "../models/tenant.interface";
import { INamedEntity, ISelectableItem } from "../../properties/models/named-entity.interface";

enum AmenityManagedBy {
    Tenant = 'Tenant',
    Owner = 'Owner'
}

export enum NotificationType {
    sms = 'SMS',
    email = 'EMAIL',
}

export interface ITenantSearchCriteria {
    property: Array<INamedEntity & {unitId: number}>;
    notificationMethod: Array<NotificationType>;
    tenantGroup?: any;
    becomeRented: INumberBetween;
    managed?: any;
    gas: AmenityManagedBy;
    heat: AmenityManagedBy;
    electricity: AmenityManagedBy;
    water: AmenityManagedBy;
    search: string;
}

export interface ISearchTenantsState extends EntityState {
    searchData: {
        propertiesList: Array<ISelectableItem & { unitId: number }>;
    };
    selectedCriteria: ITenantSearchCriteria;
    appliedFilters: ITenantSearchCriteria;
    sort: ISort<keyof ITenant>;
}

export const searchTenantInitialState: ISearchTenantsState = {
    searchData: {
        propertiesList: []
    },
    selectedCriteria: {
        property: [],
        search: '',
        notificationMethod: [],
        becomeRented: {
            id: guid(),
            from: null,
            to: moment().format('mm/dd YYYY')
        },
        gas: AmenityManagedBy.Owner,
        heat: AmenityManagedBy.Owner,
        electricity: AmenityManagedBy.Owner,
        water: AmenityManagedBy.Owner
    },
    appliedFilters: {
        property: [],
        search: '',
        notificationMethod: [],
        becomeRented: {
            id: guid(),
            from: null,
            to: moment().format('mm/dd YYYY')
        },
        gas: AmenityManagedBy.Owner,
        heat: AmenityManagedBy.Owner,
        electricity: AmenityManagedBy.Owner,
        water: AmenityManagedBy.Owner
    },
    sort: {
        field: 'lastName',
        type: 'asc'
    }
}

export const searchTenantsStore = createEntityStore<ISearchTenantsState>(searchTenantInitialState, {
    name: 'tenants_search',
});