import { guid } from "@datorama/akita";
import { useCallback, useMemo, useReducer, useState } from "react";
import { FetchStatus } from "src/store/data-fetcher/data-fetcher.store";
import { ActionType, RequestAction, RequestReducer, requestReducer, RequestState } from "../request.reducer";

export interface IFetchRequest<TData, P extends unknown[]> {
    id: string;
    fetchStatus: FetchStatus,
    error?: unknown,
    cache?: boolean;
    state?: TData | null;
    isFetching: boolean;
    fetch: (action: (...params: P) => Promise<TData | void>, initParams?: unknown[]) => void;
    refetch: () => void;
    loading: () => void;
    updateState: (updater: (state: RequestState<TData, P>) => RequestState<TData, P>) => void;
    filters: any;
    sort: string,
    setQueryFilters: <K>(qs: K) => void;
    setSortType: (sort: string) => void;
}

export interface IRequest<TData = unknown> {
    inititalState?: TData;
}

export function useRequest<T, P extends unknown[]>(requestId: string, params?: IRequest<T>): IFetchRequest<T, P> {
    const [requestState, dispatch] = useReducer<RequestReducer<RequestState<T, P>, RequestAction<T, P>>, any>(
        requestReducer, 
        { status: FetchStatus.IDLE, id: requestId, }, 
        (state) => {
            return {
                ...state,
                id: requestId,
                data: params?.inititalState,
                isFetching: false,
            };
    });

    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState<string>("");

    const setQueryFilters = useCallback(<K>(qs: K) => {
        setFilters(qs);
    }, [])

    const setSortType = useCallback((sort: string) => {
        setSort(sort);
    }, []);

    const loading = useCallback(() => {
        dispatch({
            type: ActionType.FETCH,
            status: FetchStatus.PENDING,
        });
    }, []);

    const fetch = useCallback(async (action, initParams) => {
        dispatch({
            type: ActionType.SET_ACTION,
            initParams,
            refetch: () => action(...initParams),
        });

        try {
            let results: T;
            if (Array.isArray(initParams) && initParams.length) {
                results = await action(...initParams) as T;
            } else {
                results = await action() as T;
            }

            if (Array.isArray(results) && !results.length) {
                dispatch({
                    type: ActionType.EMPTY,
                    status: FetchStatus.EMPTY,
                });
            } else {
                dispatch({
                    type: ActionType.SUCCESS,
                    status: FetchStatus.SUCCESS,
                    results,
                });
            }
        } catch(error) {
            console.log('error -> ', error);

            dispatch({
                type: ActionType.FAILURE,
                status: FetchStatus.FAILED,
                error: error as string,
            });
        }
    }, [])

    const data = useMemo(() => {
        if (requestState.status === FetchStatus.SUCCESS) {
            return requestState.data;
        }

        return params?.inititalState;
    }, [requestState, params]);

    const refetch = useCallback(async () => {
        const results: T = await requestState.refetch() as T;

        dispatch({
            type: ActionType.SUCCESS,
            status: FetchStatus.SUCCESS,
            results,
        });
    }, [requestState]);

    const updateState = useCallback((updater: (state: RequestState<T, P>) => RequestState<T, P>) => {
        const updatedState = updater(requestState);

        dispatch({
            type: ActionType.UPDATE_STATE,
            state: updatedState,
        });
    }, [requestState]);

    return {
        id: requestState.id,
        fetchStatus: requestState.status,
        error: null,
        cache: false,
        state: data,
        isFetching: requestState.isFetching,
        filters,
        sort,
        fetch,
        refetch,
        loading,
        updateState,
        setQueryFilters,
        setSortType,
    };
}