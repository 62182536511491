import React, { Fragment, useEffect, useState } from 'react';
import { Panel } from 'src/components/ui/components/panel';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import { Button, ButtonVariant } from 'src/components/ui/components/buttons/button';
import { useCalendarSettings } from 'src/store/admin-settings/hooks/use-calendar-settings';
import { RenderUsersColorView } from '../../../user-color-view/user-color-view';

import { ICalendarSettings, IStaffColor } from 'src/store/admin-settings/models/calendar.interface';
import { adminSettingsService } from 'src/store/admin-settings/admin-settings.service';

import { SaveIcon, SettingsOutlinedIcon } from 'src/components/ui/icons';

import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { panelMobileStyles } from 'src/components/ui/main-layout/layout';
import { useObservable } from '@libreact/use-observable';
import { adminSettingsQuery } from 'src/store/admin-settings/admin-settings.query';

interface IAppointmentsSettingsProps {
    // notificationList: any[];
    loading?: boolean;
    isMobile?: boolean;
}

export const AppointmentsSettings: React.FC<IAppointmentsSettingsProps> = ({ isMobile }) => {
    const [calendarSettings] = useCalendarSettings();
    const [staffColors, setStaffColors] = useState<IStaffColor[]>();
    const [staff] = useObservable(adminSettingsQuery.staff$);

    useEffect(() => {
        adminSettingsService.fetchStaff();
    }, []);

    const handleSaveColors = (staffColors: IStaffColor[]) => {
        setStaffColors(staffColors);
    }

    const saveCalendarSettings = () => {
        if (staffColors?.length) {
            const calendarDto: ICalendarSettings = {
                // defaultTime,
                staffColors,
            };
    
            adminSettingsService.updateCalendarSettings(calendarDto);
        }
    }

    return (
        <Fragment>
            <Panel additionalStyles={
                isMobile ? {
                    ...panelMobileStyles,
                    margin: '-22px 0 30px',
                    width: '100%',
                    borderRadius: '0 0 0 10px',
                } : { margin: '-5px 0 30px', borderRadius: '0 0 10px 10px' }
            }>
                <FormHolder
                    title="Appointment & Calendar"
                    rightConnerButton={<Button
                        icon={<SaveIcon />}
                        btnVariant={ButtonVariant.PRIMARY}
                        onClick={saveCalendarSettings}
                        text="Save"
                    />}
                    icon={<SettingsOutlinedIcon />}>
                    <Form<Partial<any>>
                        type={FormType.DEFAULT}
                        data={{
                            defaultTime: '(205) 555-0100',
                            usersColorView: [],
                        }}
                        readOnly
                        fields={{
                            // defaultTime: {
                            //     index: 0,
                            //     label: 'Default appointment time',
                            //     labelDisplay: 'block',
                            //     fieldType: InputType.CUSTOM_ELEMENT,
                            //     height: 'auto',
                            //     customElement: <div>
                            //         <Dropdown
                            //             onChange={handleSelectChange}
                            //             value={defaultTime}
                            //             menuItems={[
                            //                 {
                            //                     value: 60,
                            //                     content: <div>60 Mins</div>
                            //                 },
                            //                 {
                            //                     value: 45,
                            //                     content: <div>45 Mins</div>
                            //                 },
                            //                 {
                            //                     value: 30,
                            //                     content: <div>30 Mins</div>
                            //                 },
                            //                 {
                            //                     value: 15,
                            //                     content: <div>15 Mins</div>
                            //                 },
                            //             ]} />
                            //     </div>
                            // },
                            usersColorView: {
                                index: 1,
                                label: 'Users’ colour view',
                                labelDisplay: 'block',
                                fieldType: InputType.CUSTOM_ELEMENT,
                                height: 'auto',
                                customElement: <RenderUsersColorView 
                                    usersList={staff}
                                    onSave={handleSaveColors}
                                    calendarSettings={calendarSettings} />
                            }
                        }}
                    />
                </FormHolder>
            </Panel>
        </Fragment>
    );
}