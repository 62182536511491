import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { SearchType } from "./models/autocomplete.interface";

export const searchUrlMap = new Map();

searchUrlMap.set(SearchType.TENANTS, '/tenants');
searchUrlMap.set(SearchType.PROPERTIES, '/properties');
searchUrlMap.set(SearchType.PROPERTY_GROUPS, '/properties/groups');
searchUrlMap.set(SearchType.TENANTS_GROUPS, '/groups');
searchUrlMap.set(SearchType.PROSPECTS, '/prospects');
searchUrlMap.set(SearchType.OWNERS, '/owners');
searchUrlMap.set(SearchType.AGENTS, '/user/agents');
searchUrlMap.set(SearchType.STAFFS, '/user/staffs');
searchUrlMap.set(SearchType.STAFF, '/user');
searchUrlMap.set(SearchType.PORTFOLIOS, '/portfolios');
searchUrlMap.set(SearchType.CITIES, '/cities');
searchUrlMap.set(SearchType.NEIGHBORHOODS, '/neighborhoods');

export interface IAutocompleteState extends EntityState {
    searchList: any[];
    searchTotal: number;
    searchType: SearchType;
    searchData: {
        [k: string]: {
            searchList: any[];
            searchTotal: number;
            searchType: SearchType;
        }
    },
    searchStatus: {
        [k: string]: {
            loading: boolean;
        };
    };
    resetInput: boolean;
}

export const initialState: IAutocompleteState = {
    //** Search */
    searchList: [],
    searchTotal: 0,
    searchType: SearchType.TENANTS,
    searchStatus: {},
    searchData: {},
    loading: false,
    resetInput: false,
}

@StoreConfig({ name: 'autocomplete' })
export class AutocompleteStore extends EntityStore<IAutocompleteState> {
    constructor() {
        super(initialState);
    }
}

export const autocompleteStore = new AutocompleteStore();