import React, {ChangeEvent, useRef} from "react";
import CssClasses from './int-input.module.scss';
import {Add, Remove} from "@material-ui/icons";
import {IUiComponent} from "../../../types/ui-component.interface";
import { ArrowDownIcon, ArrowUpIcon } from "src/components/ui/icons";

const ZERO = 0;
export interface IIntegerInputProps extends IUiComponent {
    value: number;
    type?: 'circle' | 'square';
    onChange: (e: React.ChangeEvent<any>) => void;
    isPositive?: boolean;
    allowZero?: boolean;
    style?: React.CSSProperties;
}

export function IntegerInput(props: IIntegerInputProps) {
    const {
        onChange,
        value,
        // isPositive = false,
        // allowZero = true,
    } = props;

    const inputRef = useRef<any>();

    // const intValue = useMemo(() => {
    //     return parseInt(value as any);
    // }, [value]);

    // const onChangeHandler = useCallback((increment: boolean) => {
    //     const newValue = intValue + (increment ? 1 : -1);

    //     if (
    //         (newValue === 0)
    //     ) {
    //         return;
    //     }

    //     onChange({
    //         target: {value: newValue}
    //     } as React.ChangeEvent<any>)

    // }, [onChange, intValue, isPositive, allowZero]);

    const handleChange = (e: ChangeEvent<any>) => (increment: boolean) => {
        if (increment) {
            inputRef.current?.stepUp();
            onChange({
                ...e,
                target: {
                    value: inputRef.current?.value
                }
            });
        } else {
            if (Number(inputRef.current?.value) === ZERO) {
                return false;
            }

            inputRef.current?.stepDown();
            onChange({
                ...e,
                target: {
                    value: inputRef.current?.value < 0
                        ? Math.abs(0)
                        : inputRef.current?.value
                }
            });
        }
    }

    const handleInputChange = (e: ChangeEvent<any>) => {
        e.persist();

        if (Math.sign(inputRef.current?.value) === -1) {
            return false;
        }

        onChange({
            ...e,
            target: {
                value: inputRef.current?.value < 0
                    ? Math.abs(0)
                    : inputRef.current?.value
            }
        });
    }

    if (props.type === 'square') {
        return (
            <div className={CssClasses.input_square_wrapper} style={props?.style}>
                <div onClick={(e) => handleChange(e)(true)} className={CssClasses.input_square_up_icon}>
                    <ArrowUpIcon />
                </div>
                {/* <div className={CssClasses.input_square_value}>
                    {intValue}
                </div> */}
                <input 
                    ref={inputRef} 
                    className={CssClasses.input_square_value} 
                    type="number" 
                    value={value} 
                    onChange={handleInputChange} />
                <div onClick={(e) => handleChange(e)(false)} className={CssClasses.input_square_down_icon}>
                    <ArrowDownIcon />
                </div>
            </div>
        );
    }

    return (
        <div className={CssClasses.input_wrapper}>
            <div onClick={(e) => handleChange(e)(false)} className={CssClasses.input_icon}>
                <Remove/>
            </div>
            {/* <div className={CssClasses.input_value}>
                {intValue}
            </div> */}
            <input 
                    ref={inputRef} 
                    className={CssClasses.input_square_value} 
                    type="number" 
                    value={value}
                    onChange={handleInputChange} />
            <div onClick={(e) => handleChange(e)(true)} className={CssClasses.input_icon}>
                <Add/>
            </div>
        </div>
    );
}