import React, { useMemo } from 'react';
import { isEqual } from 'lodash';
import { ColorItem } from './color-item';
import { IStaffColor } from 'src/store/admin-settings/models/calendar.interface';

import styles from '../admin.module.scss';
import { guid } from '@datorama/akita';
import { IColor } from './user-color-view';

export interface IModalContent {
    colorList: IColor[];
    selectedColor: IStaffColor;
    onColorChange: (staffId: string | number, color: string) => void;
}

export const ModalContent = React.memo(({ colorList, selectedColor, onColorChange }: IModalContent) => {
    const handleChange = (color: string) => {
        if (typeof onColorChange === 'function') {
            onColorChange(selectedColor.staffId, color);
        }
    }

    const badgeColor = useMemo(() => {
        const currentColor = colorList.filter(item => item.color === selectedColor.color)[0];
        if (currentColor) {
            return colorList.filter(item => item.color === selectedColor.color)[0].bgColor;
        }
        
        return '';
    }, [selectedColor]);

    return (
        <div className={styles.modalContent}>
            <div className={styles.colorsHolder}>
                { colorList.map(item => (
                    <ColorItem key={guid()} color={item.color} selectedColor={selectedColor.color} onColorChange={handleChange} />
                )) }
            </div>
            <div className={styles.badge} style={{ background: badgeColor }}>
                <div className={styles.badgeName} style={{ color: selectedColor.color }}>Meeting name</div>
                <div className={styles.badgeContent}>11:00 AM - 01:00 PM</div>
            </div>
        </div>
    );
}, (prevProps: IModalContent, nextProps: IModalContent) => isEqual(prevProps, nextProps));