import { useObservable } from "@libreact/use-observable";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ILocationState } from "src/store/common/models/common.interface";
import { IOwner } from "../models/owner.interface";
import { ownersQuery } from "../owners.query";
import { ownersService } from "../owners.service";

export type OwnersHookTuple = [IOwner[], boolean];

export function useOwners (): OwnersHookTuple {
    const location = useLocation<ILocationState>();
    const [owners] = useObservable(ownersQuery.owners$);
    const [fo] = useObservable(ownersQuery.selectFilteredOwners$);
    const [loading] = useObservable(ownersQuery.isLoading$);

    useEffect(() => {
        if (!owners.length) {
            ownersService.fetchOwners(0, 9999);
        } else {
            // ownersService.fetchOwners(
            //     get(location, `state[${PagingType.OWNERS}].activePage`, START_PAGE),
            //     get(location, `state[${PagingType.OWNERS}].rowsPerPage`, ROWS_PER_PAGE),
            // );
        }
    }, [location.state]);

    return [fo, loading];
}