import {IUiComponent} from "../../types/ui-component.interface";
import React, { Fragment } from 'react';
import styles from './button.module.scss';
import classnames from 'classnames';
import {
    GetApp,
} from "@material-ui/icons";
import styled from "styled-components";
// import { useObservable } from "@libreact/use-observable";
// import { uiQuery } from "src/store/ui-storage/ui.query";

export enum ButtonVariant {
    PRIMARY,
    OUTLINE
}

const StyledButton = styled.button`
    outline: none !important;
`;

interface IProps extends IUiComponent {
    btnVariant: ButtonVariant;
    text?: string;
    icon?: React.ReactNode;
    disabled?: boolean;
    isLoading?: boolean;
    loadingText?: string;
    btnStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
}

export const Button = (props: IProps) => {
    // const [deviceType] = useObservable(uiQuery.deviceType$);

    const {btnVariant, loadingText = 'Loading...', text, isLoading = false, disabled = false, icon = null, children, btnStyle, iconStyle, ...restProps} = props;
    const classname = classnames({
        [styles.button_primary]: btnVariant === ButtonVariant.PRIMARY,
        [styles.button_outline]: btnVariant === ButtonVariant.OUTLINE && !iconStyle,
        [styles.button_disabled]: disabled,
        [styles.button_loading]: isLoading
    });
    return <StyledButton style={btnStyle} disabled={disabled || isLoading} {...restProps} className={classname}>
        {isLoading ?
            <div className={styles.loading_wrapper}>
                <GetApp className={styles.loading_icon_class} />
                <span style={{marginLeft: 10}}>
                    {loadingText}
                </span>
            </div> :
            <Fragment>
                <span style={iconStyle} className={styles.button_icon}>{icon}</span>
                {
                    text ? <span style={{ width: '100%', textAlign: 'center' }}>{text}</span> : null
                }
            </Fragment>}
    </StyledButton>
}
