import { useEffect } from 'react'
import { useObservable } from '@libreact/use-observable'
import { adminSettingsQuery } from '../admin-settings.query';
import { adminSettingsService } from '../admin-settings.service';
import { IEmailNotification } from '../models/email-notification.interface';
import { ISMSNotification } from '../models/sms-notification.interface';

export type NotificationHookTuple = [Partial<IEmailNotification>, Partial<ISMSNotification>, boolean];

export function useNotification(): NotificationHookTuple {
    const [emailNotification] = useObservable(adminSettingsQuery.emailNotification$);
    const [smsNotification] = useObservable(adminSettingsQuery.smsNotification$);
    const [loading] = useObservable(adminSettingsQuery.loading$);

    useEffect(() => {
        adminSettingsService.fetchEmailNotifications();

        adminSettingsService.fetchSMSNotifications();
    }, []);

    return [emailNotification, smsNotification, loading];
}