import { Close } from '@material-ui/icons';
import React, { Fragment, useMemo } from 'react';
import classnames from 'classnames';
import { ITenant } from 'src/store/tenants/models/tenant.interface';

import styles from './send-message.module.scss';
import { uniqBy } from 'lodash';
import { CustomScrollbars } from 'src/components/ui/components/custom-scrollbar/custom-scrollbar';

interface IRenderSelectedProps<T> {
    data: T[];
    title?: string;
    icon?: React.ReactNode;
    key?: keyof T;
    withDivider?: boolean;
    uniqField?: keyof T;
    onRemove: (arg: Partial<T>) => void;
    renderItem: (item: T) => React.ReactNode;
    checkAttached?: any[];
    withScrollbar?: boolean;
}

interface ItemProps {
    id?: string | number | null;
    firstName?: string;
    lastName?: string;
    email?: string;
    fullname?: string;
    unitAddress?: string;
    name?: string;
    tenants?: ITenant[];
}

export const RenderSelected = <T extends ItemProps>({ data = [], title, icon, uniqField = 'id', withDivider = false, withScrollbar = false, renderItem, onRemove, checkAttached }: IRenderSelectedProps<T>) => {
    const uniqData = useMemo(() => {
        return uniqBy(data, uniqField);
    }, [data, uniqField]);

    const listClasses = classnames({
        [styles.selected_list]: true,
        [styles.selected_divider]: withDivider,
    })

    if (data.length) {
        return (
            <div className={listClasses}>
                {
                    title
                        ? (
                            <div className={styles.selected_header}>
                                <div className={styles.selected_icon}>{ icon }</div>
                                <div className={styles.selected_title}>{ title }</div>
                            </div>
                        )
                        : null
                }
                <div className={styles.selected_table}>
                    {
                        withScrollbar
                            ? (
                                <CustomScrollbars style={{ width: 500 }}>
                                    {uniqData.map(item => <div key={item.id} className={styles.selected_row}>
                                        { renderItem(item) }
                                        {
                                            checkAttached && checkAttached.length
                                                ? checkAttached.some(check => check.agentAttachedId === item.id)
                                                    ? null
                                                    : (
                                                        <div onClick={() => onRemove(item)} className={styles.selected_remove}>
                                                            <Close fontSize="small" />
                                                        </div>
                                                    )
                                                : (
                                                    <div onClick={() => onRemove(item)} className={styles.selected_remove}>
                                                        <Close fontSize="small" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )}
                                </CustomScrollbars>
                            )
                            : (
                                <Fragment>
                                    {uniqData.map(item => <div key={item.id} className={styles.selected_row}>
                                        { renderItem(item) }
                                        {
                                            checkAttached && checkAttached.length
                                                ? checkAttached.some(check => check.agentAttachedId === item.id)
                                                    ? null
                                                    : (
                                                        <div onClick={() => onRemove(item)} className={styles.selected_remove}>
                                                            <Close fontSize="small" />
                                                        </div>
                                                    )
                                                : (
                                                    <div onClick={() => onRemove(item)} className={styles.selected_remove}>
                                                        <Close fontSize="small" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )}
                                </Fragment>
                            )
                    }
                </div>
            </div>
        );  
    }

    return null;
}