import React, {useCallback} from 'react';
import Styles from './header.module.scss';
import {useObservable} from "@libreact/use-observable/dist";
// import {userQuery} from "../../../../store/user/user.query";
import { KeyboardArrowDown } from '@material-ui/icons';
// import {userService} from "../../../../store/user/user.service";
import { SimpleDropdown } from '../../components/dropdown/simple-dropdown';
import {Avatar, AvatarSize, FallbackType} from "../../components/avatar/avatar";
import { authQuery } from 'src/store/auth/auth.query';
import { authService } from 'src/store/auth/auth.service';

export function DesktopHeader() {
    const [user] = useObservable(authQuery.selectUser$);

    const signOut = useCallback(() => {
        authService.signOut();
    }, []);

    return <header className={Styles.header}>
        <div className={Styles.header_left}>
            <div className={Styles.header__search_wrapper}>
                {/*<SearchInput />*/}
            </div>
        </div>
        <div className={Styles.header__avatar_wrapper}>
            <div className={Styles.header__avatar_name}>{user?.name}</div>
            <Avatar
                src={ user?.photo }
                size={AvatarSize.tiny}
                fallbackType={FallbackType.ABBR}
                fallback={user?.name || '- -'}
            />
            <div className={Styles.dropdown_item} onClick={signOut}>
                Logout
            </div>
            {/* <SimpleDropdown placeholder={<KeyboardArrowDown style={{marginTop: 5}} />}>
                <div className={Styles.dropdown_item} onClick={signOut}>
                    Log Out
                </div>
            </SimpleDropdown> */}
        </div>
    </header>
}
