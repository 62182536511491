import React, { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react';
import { FormHolder } from 'src/components/ui/components/forms/form-holder';
import { Form, FormType, InputType } from 'src/components/ui/components/forms/formBuilder/form';
import moment from 'moment';
import { Dropdown } from 'src/components/ui/components/dropdown/dropdown';
import { IntegerInput } from 'src/components/ui/components/forms/inputs/int-input';
import classnames from 'classnames';
import { dayInMonth, daysOfWeek } from './notification-editor';

import styles from './notification-editor.module.scss';
import { guid } from '@datorama/akita';

//* "endsAfterOccurrence": 0,
//*     "endsDate": "2020-12-22T08:24:37.893Z",
//*     "frequency": 0,
//*     "timePeriod": "DAY",
//*     "weekDays": [
//*         "MONDAY"
//* ]

interface IFormState {
    frequency: number;
    weekDays: string[];
    timePeriod: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
    endsDate?: any;
    endsAfterOccurrence: number | null;
    monthlyRepeat?: string;
    monthly?: string;
    endType?: string;
}

const RenderDays: React.FC<any> = ({ selectedDays, onChange }) => {
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.name);
    }

    return (
        <div className={styles.daysHolder}>
            {days.map(day => (
                <div key={guid()} className={styles.day}>
                    <input type="checkbox" checked={selectedDays.includes(day)} name={day} id={day} onChange={handleChange} />
                    <label htmlFor={day}>{ day.charAt(0) }</label>
                </div>
            ))}
        </div>
    );
}

const RenderRepeatOn: React.FC<any> = ({ timePeriod, dayNumber, monthlyRepeat, selectedDate, initialState, onChange }) => {
    useEffect(() => {
        if (timePeriod === 'MONTH' && !monthlyRepeat) {
            onChange(timePeriod)(`on day ${moment(selectedDate.date).date()}`)
        }
    }, [timePeriod]);

    const monthValue = useMemo(() => {
        switch(monthlyRepeat) {
            case 'ON_DAY':
                return `on day ${moment(selectedDate.date).date()}`;
            case 'ON_WEEKDAY':
                return `on the ${dayInMonth.get(dayNumber)} ${daysOfWeek.get(moment(selectedDate.date).weekday())}`;
            default:
                return monthlyRepeat;
        }
    }, [monthlyRepeat]);

    switch(timePeriod.toLowerCase()) { 
        case 'week':
            return <div className={styles.days}>
                <span>Repeat On</span>
                <RenderDays selectedDays={initialState.weekDays} onChange={onChange(timePeriod)} />
            </div>
        case 'month':
            return <div className={styles.repeatOn}>
                <Form<any>
                    data={{
                        on: `Monthly on day  ${moment(selectedDate.date).date()}`,
                    }}
                    wrapperStyles={{ margin: 0 }}
                    fields={{
                        on: {
                            index: 0,
                            label: '',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <Dropdown
                                onChange={(e) => onChange(timePeriod)(e.target.value)}
                                styles={{
                                    maxWidth: '400px',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                }}
                                value={monthValue}
                                menuItems={[
                                    {
                                        content: `Monthly on day ${moment(selectedDate.date).date()}`,
                                        value: `on day ${moment(selectedDate.date).date()}`,
                                    },
                                    {
                                        content: `Monthly on the ${dayInMonth.get(dayNumber)} ${daysOfWeek.get(moment(selectedDate.date).weekday())}`,
                                        value: `on the ${dayInMonth.get(dayNumber)} ${daysOfWeek.get(moment(selectedDate.date).weekday())}`,
                                    },
                                ]} />
                        }
                    }} />
            </div>
        case 'day':
            return null;
        default:
            return null;
    }
}

const RenderEnds: React.FC<any> = ({ endsAfterOccurrence, selectedDate, initialState, onChange }) => {
    const [activeEnd, setEnd] = useState('never');
    const [formState, setFormState] = useState<any>({
        endsAfterOccurrence: 1,
    });

    useEffect(() => {
        setFormState((prevState: any) => ({
            ...prevState,
            ...initialState,
            endType: activeEnd,
            endsAfterOccurrence: initialState.endsAfterOccurrence === null ? 1 : initialState.endsAfterOccurrence,
        }));

        if (initialState.endsAfterOccurrence) {
            setEnd('after');
        } else if (initialState.endsDate) {
            setEnd('on');
        } else {
            setEnd('never');
        }        
    }, []);

    useEffect(() => {
        switch(activeEnd) {
            case 'on':
                onChange(activeEnd)({
                    ...formState,
                    endsAfterOccurrence: null,
                });
                break;
            case 'after':
                onChange(activeEnd)({
                    ...formState,
                    endsDate: null,
                });
                break;
            default:
                onChange(activeEnd)({
                    endsAfterOccurrence: null,
                    endsDate: null,
                });
        }
    }, [formState, activeEnd]);

    const handleEndChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEnd(e.target.id);
    }

    const handleEndsChange = (value: any) => {
        setFormState((prevState: any) => {
            return {
                ...prevState,
                endsAfterOccurrence: value,
            };
        });
    }

    const handleChangeDate = (value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            endsDate: new Date(value.on),
        }));
    }

    const onClasses = classnames({
        [styles.disabled]: !activeEnd?.includes('on'),
    });

    const afterClasses = classnames({
        [styles.disabled]: !activeEnd?.includes('after'),
    });

    return (
        <div className={styles.endsHolder}>
            <div>Ends</div>
            <div className={styles.ends}>
                <div className={styles.endsItem}>
                    <input type="radio" name="ends" id="never" checked={activeEnd === 'never'} onChange={handleEndChange} />
                    <label htmlFor="never">Never</label>
                </div>
                <div className={styles.endsItem}>
                    <Fragment>
                        <input type="radio" name="ends" id="on" checked={activeEnd === 'on'} onChange={handleEndChange} />
                        <label htmlFor="on">On</label>
                    </Fragment>

                    <div className={onClasses}>
                        <Form<any>
                            data={{
                                on: selectedDate,
                            }}
                            wrapperStyles={{
                                margin: '0 0 0 46px',
                            }}
                            onChange={handleChangeDate}
                            fields={{
                                on: {
                                    index: 0,
                                    label: '',
                                    fieldType: InputType.DATE,
                                }
                            }} />
                    </div>
                </div>
                <div className={styles.endsItem }>
                    <Fragment>
                        <input type="radio" name="ends" id="after" checked={activeEnd === 'after'} onChange={handleEndChange} />
                        <label htmlFor="after">After</label>
                    </Fragment>

                    <div className={afterClasses}>
                        <Form<any>
                            data={{
                                after: endsAfterOccurrence,
                            }}
                            wrapperStyles={{
                                margin: '0 0 0 30px',
                            }}
                            fields={{
                                after: {
                                    index: 0,
                                    label: '',
                                    fieldType: InputType.CUSTOM_ELEMENT,
                                    customElement: <div className={styles.whenHours}>
                                        <IntegerInput value={formState.endsAfterOccurrence} onChange={(e) => handleEndsChange(e.target.value)} type="square" />
                                        <div>Occurance</div>
                                    </div>
                                }
                            }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const initialFormState: IFormState = {
    frequency: 1,
    weekDays: [],
    timePeriod: 'DAY',
    endsAfterOccurrence: null,
    endType: 'never',
};

export const CustomRecurrence: React.FC<any> = ({ initialState, selectedDate, onChange, dayNumber }) => {
    const [formState, setFormState] = useState<IFormState>({
        ...initialFormState,
        ...initialState,
        // endsDate: new Date(),
    });

    useEffect(() => {
        setFormState((prevState: any) => ({
            ...prevState,
            ...initialState,
        }));
    }, []);

    useEffect(() => {
        onChange(formState);
    }, [formState]);

    const handleValuesChange = (data: any) => {
        setFormState((prevState) => ({
            ...prevState,
            ...data,
        }));
    }

    const handleSelectChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
        if (field === 'timePeriod') {
            switch(e.target.value) {
                case 'DAY':
                case 'YEAR':
                    setFormState((prevState: any) => ({
                        ...prevState,
                        [field]: e.target.value,
                        weekDays: [],
                        monthlyRepeat: '',
                    }));
                    break;
                case 'WEEK':
                    setFormState((prevState: any) => ({
                        ...prevState,
                        [field]: e.target.value,
                        monthlyRepeat: '',
                    }));
                    break;
                case 'MONTH':
                    setFormState((prevState: any) => ({
                        ...prevState,
                        [field]: e.target.value,
                        weekDays: [],
                    }));
                    break;
                default:

            }
        } else {
            setFormState(prevState => ({
                ...prevState,
                [field]: e.target.value,
            }));
        }
    }

    const handleRepeatOnChange = (on: string) => (value: string) => {
        switch(on.toLowerCase()) {
            case 'week':
                setFormState((prevState) => {
                    if (prevState.weekDays.includes(value)) {
                        return {
                            ...prevState,
                            monthlyRepeat: '',
                            weekDays: prevState.weekDays.filter(day => day !== value),
                        };
                    }
        
                    return {
                        ...prevState,
                        monthlyRepeat: '',
                        weekDays: [
                            ...prevState.weekDays,
                            value,
                        ],
                    };
                });
                break;
                case 'month':
                    setFormState((prevState) => ({
                        ...prevState,
                        monthlyRepeat: value,
                        weekDays: [],
                    }));
                    break;
            default:
                setFormState((prevState) => ({
                    ...prevState,
                    monthlyRepeat: '',
                    weekDays: [],
                }));
        }
    }

    const handleEndsChange = (endType: string) => (endState: any) => {
        setFormState((prevState) => {
            return {
                ...prevState,
                ...endState,
                timePeriod: prevState.timePeriod,
                weekDays: prevState.weekDays,
                frequency: prevState.frequency,
                endType,
            };
        });
    }

    return (
        <div className={styles.modalContent}>
            <FormHolder noPadding>
                <Form
                    type={FormType.DEFAULT}
                    onChange={handleValuesChange}
                    data={formState}
                    wrapperStyles={{ margin: 0 }}
                    fields={{
                        frequency: {
                            index: 0,
                            label: 'Repeat Every',
                            labelDisplay: 'block',
                            labelStyles: {
                                width: 178,
                                fontFamily: 'ManropeThin',
                                color: '#363E59',
                            },
                            height: 'auto',
                            fieldType: InputType.CUSTOM_ELEMENT,
                            customElement: <div className={styles.whenHours}>
                                <IntegerInput value={formState.frequency as number} onChange={handleSelectChange('frequency')} type="square" />
                                <Dropdown
                                    onChange={handleSelectChange('timePeriod')}
                                    value={formState.timePeriod}
                                    styles={{
                                        minWidth: 94,
                                    }}
                                    menuItems={[
                                        {
                                            value: 'DAY',
                                            content: <div>Day</div>
                                        },
                                        {
                                            value: 'WEEK',
                                            content: <div>Week</div>
                                        },
                                        {
                                            value: 'MONTH',
                                            content: <div>Month</div>
                                        },
                                        {
                                            value: 'YEAR',
                                            content: <div>Year</div>
                                        },
                                    ]} />
                            </div>
                        },
                    }}
                />
                <RenderRepeatOn 
                    timePeriod={formState.timePeriod} 
                    dayNumber={dayNumber}
                    monthlyRepeat={formState.monthlyRepeat}
                    selectedDate={selectedDate}
                    initialState={formState}
                    onChange={handleRepeatOnChange} />
                <RenderEnds 
                    endsAfterOccurrence={formState.endsAfterOccurrence}
                    selectedDate={formState.endsDate}
                    initialState={formState}
                    onChange={handleEndsChange} />
            </FormHolder>
        </div>
    );
}